import { useBillingContext } from '../context/billing.context';
import { PartnershipType } from '../types';

export const usePartnershipById = (
  id?: number | null,
): PartnershipType | null => {
  const { partnerships } = useBillingContext();

  if (!id) {
    return null;
  }
  return partnerships[id] || null;
};
