import {
  HHChip,
  HHCollapsibleTable,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import dayjs from 'dayjs';
import { useState } from 'react';
import { calculateChipColor } from '../../utils/contract-utils';
import { DiscountType, IDiscount } from '../../utils/discount-utils';
import { SimplePackageType } from '../contract/package/custom-package-types';
import LoadingComponent from '../utils/loading';
import DiscountDetailBase from './discount-type-display/discount-detail-base';
import ProgramAccessDetail from './discount-type-display/program-access';
import VolumeBasedDiscountsDetail from './discount-type-display/volume-based-discounts';
import BillingCapsUsage from './discount-usage-display/billing-caps';
import FreeBillableActivitiesUsage from './discount-usage-display/free-billable-activities';
import InnovationCreditsUsage from './discount-usage-display/innovation-credits';
import ProgramAccessUsage from './discount-usage-display/program-access';
import VolumeBasedDiscountsUsage from './discount-usage-display/volume-based-discounts';

export interface DiscountsListProps {
  discountsData?: IDiscount[];
  onEditDiscountClick: (discountDefinitionId: number) => void;
  loading?: boolean;
  availablePackages: SimplePackageType[];
  currency: string | null;
}

const getDiscountDetailsComponent = (
  discountData: IDiscount,
  availablePackages: SimplePackageType[],
  currency: string | null,
  handleEditDiscountClick: (id: number) => void,
): JSX.Element => {
  const props = {
    discountData,
    availablePackages,
    currency,
    handleEditDiscountClick,
  };
  switch (discountData.discountType) {
    case DiscountType.programAccess:
      return <ProgramAccessDetail {...props} />;
    case DiscountType.volumeBasedDiscounts:
      return <VolumeBasedDiscountsDetail {...props} />;
    /* istanbul ignore next */
    default:
      return <DiscountDetailBase {...props} />;
  }
};

const getDiscountUsageComponent = (
  discountData: IDiscount,
  currency: string | null,
): JSX.Element => {
  const props = {
    discountData,
    currency,
  };
  switch (discountData.discountType) {
    case DiscountType.billingCaps:
      return <BillingCapsUsage {...props} />;
    case DiscountType.programAccess:
      return <ProgramAccessUsage {...props} />;
    case DiscountType.innovationCredits:
      return <InnovationCreditsUsage {...props} />;
    case DiscountType.volumeBasedDiscounts:
      return <VolumeBasedDiscountsUsage {...props} />;
    case DiscountType.freeBillableActivities:
      return <FreeBillableActivitiesUsage {...props} />;
    /* istanbul ignore next */
    default:
      return <BillingCapsUsage {...props} />;
  }
};

const DiscountsList = ({
  discountsData,
  onEditDiscountClick,
  loading = true,
  availablePackages,
  currency,
}: DiscountsListProps): JSX.Element => {
  const [openRowId, setOpenRowId] = useState<number | null>(null);

  const handleOpenRow =
    (id: number): React.MouseEventHandler<HTMLButtonElement> =>
    (): void => {
      if (openRowId !== id) {
        setOpenRowId(id);
      } else {
        setOpenRowId(null);
      }
    };

  const handleEditDiscountClick = (discountDefinitionId: number): void => {
    onEditDiscountClick(discountDefinitionId);
  };

  return (
    <HHCollapsibleTable
      headers={[
        {
          align: 'left',
          headerTitle: 'Type',
        },
        {
          align: 'left',
          headerTitle: 'Details',
        },
        {
          align: 'left',
          headerTitle: 'Start Date',
        },
        {
          align: 'left',
          headerTitle: 'End Date',
        },
        {
          align: 'left',
          headerTitle: 'Status',
        },
      ]}
      emptyRowContent={
        loading ? (
          <LoadingComponent center />
        ) : (
          <HHStack direction="row" padding={4} justifyContent="center">
            <HHTypography hhVariant="muted">
              No associated discounts to display
            </HHTypography>
          </HHStack>
        )
      }
      rows={
        discountsData
          ? discountsData.map(discount => ({
              open: openRowId === discount.discountDefinitionId,
              setOpen: handleOpenRow(discount.discountDefinitionId),
              rowId: discount.discountDefinitionId,
              collapsibleRowContent: getDiscountDetailsComponent(
                discount,
                availablePackages,
                currency,
                handleEditDiscountClick,
              ),
              columnStyles: {
                textTransform: 'capitalize',
              },
              columnValues: [
                {
                  columnItem: discount.discountType.replaceAll('_', ' '),
                },
                {
                  columnItem: getDiscountUsageComponent(discount, currency),
                },
                {
                  columnItem: dayjs(discount.startDate).format('L'),
                },
                {
                  columnItem: discount.endDate
                    ? dayjs(discount.endDate).format('L')
                    : '--',
                },
                {
                  columnItem: (
                    <HHStack direction="row" spacing={1}>
                      <HHChip
                        hhVariant="variant-bypass"
                        color={calculateChipColor(discount.status)}
                        size="small"
                        label={discount.status}
                      />
                    </HHStack>
                  ),
                },
              ],
            }))
          : []
      }
    />
  );
};

export default DiscountsList;
