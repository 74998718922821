import {
  HHLoadingButton,
  HHSelect,
  HHStack,
  TextArea,
} from '@hinge-health/react-component-library';
import { useEffect, useState } from 'react';
import { CvsEligibilityResponse } from '../components/cvs-eligibility-response';
import { EligibilityApi } from '../constants/eligibility-constants';

const EligibilityHomePage = (): JSX.Element => {
  const request = {
    inquiryDate: 'Enter inquiry date (YYYY-MM-DD)',
    dateOfBirth: 'Enter DOB (YYYY-MM-DD)',
    gender: 'Enter gender (M|F|U)',
    firstName: 'Enter first name',
    lastName: 'Enter last name',
    clientCode: 'Enter client code',
    externalId: 'Enter external id',
    postalCode: 'Enter postal code',
  };

  const [selectedApi, setSelectedApi] = useState('');
  const [apiRequestValue, setApiRequestValue] = useState(
    JSON.stringify(request, null, '\t'),
  );
  const [apiRequestValueError, setApiRequestValueError] = useState(false);
  const [submitRequestValue, setSubmitRequestValue] = useState(null);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const submitDisabled = !(selectedApi && !apiRequestValueError);
  const submitRequest = (): void => {
    setSubmitRequestValue(JSON.parse(apiRequestValue));
  };

  useEffect(() => {
    try {
      JSON.parse(apiRequestValue);
      setApiRequestValueError(false);
    } catch (error) {
      setApiRequestValueError(true);
    }
  }, [apiRequestValue]);

  return (
    <HHStack direction="column" sx={{ margin: 4 }} spacing={4}>
      <HHStack direction="row" spacing={4}>
        <HHSelect
          label="Select an API"
          onChange={(e): void =>
            setSelectedApi(e.target.value as EligibilityApi)
          }
          selectOptions={[
            {
              displayName: 'CVS API',
              value: 'cvs',
            },
            {
              disabled: true,
              displayName: 'Change Health API',
              value: 'changeHealth',
            },
            {
              disabled: true,
              displayName: 'ESI API',
              value: 'esi',
            },
          ]}
        />
        <HHLoadingButton
          color="primary"
          hhVariant="contained"
          layoutStyles={{
            marginTop: '10px',
          }}
          disabled={submitDisabled}
          onClick={submitRequest}
          loading={submitButtonLoading}
        >
          Submit
        </HHLoadingButton>
      </HHStack>
      <HHStack direction="row" spacing={4} width="100%">
        <TextArea
          label="Request"
          value={apiRequestValue}
          sx={{ width: '30%' }}
          onChange={(e): void =>
            setApiRequestValue((e.target as HTMLTextAreaElement).value)
          }
          error={apiRequestValueError}
        />
        <CvsEligibilityResponse
          request={submitRequestValue}
          onLoading={setSubmitButtonLoading}
        />
      </HHStack>
    </HHStack>
  );
};

export default EligibilityHomePage;
