import { DiscountType } from '../../../utils/discount-utils';
export const DISCOUNT_CANCEL_BUTTON_TEST_ID = 'discount-cancel-button-test-id';
export const DISCOUNT_SUBMIT_BUTTON_TEST_ID = 'discount-submit-button-test-id';
export const DISCOUNT_START_DATE_PICKER_ID =
  'discount-start-date-picker-test-id';
export const DISCOUNT_END_DATE_PICKER_ID = 'discount-end-date-picker-test-id';

export interface DiscountFormValues {
  /** The client id of Discount Form */
  clientId: number;
  /** The type of discount i.e program_access */
  discountType: string | DiscountType;
  /** The start date of discount */
  startDate: Date;
  /** The end date of discount*/
  endDate: Date | null;
  /** The program associated with discount 0 = global, 1=chronic */
  program: string | null | undefined;
  /** The package associated with discount 1 = in person visit */
  packageId: string | null | undefined;
  /** The value of discount, percentage, number of users or dollar amount*/
  discount: string | number;
  /** Boolean wether 0.00 dollar charges appear on invoice or not*/
  suppressEmptyCharges: boolean;
}

export interface ParsedDiscountValues {
  clientId: number;
  discount: number;
  discountType: string;
  startDate: string;
  endDate: string | null;
  programId: number | null;
  packageId: number | null;
  suppressEmptyCharges: boolean;
}
