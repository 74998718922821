import {
  HHCheckbox,
  HHFormControlLabel,
  HHFormGroup,
  HHStack,
  HHTextField,
} from '@hinge-health/react-component-library';
import { InputAdornment } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  CONTRACT_FORM_ACUTE_PRICE_INPUT_ID,
  CONTRACT_FORM_ENABLE_ACUTE_CHECK,
} from '../../../../constants/strings/contract/form-constants';
import { getCurrencySymbol } from '../../../../utils/currency-helpers';

export interface AcuteInputsProps {
  wholeFormDisabled: boolean;
}

export const AcuteInputs = ({
  wholeFormDisabled,
}: AcuteInputsProps): JSX.Element => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { enableAcuteProgram } = getValues();
  const [watchedEnableAcute, watchedCurrency] = useWatch({
    name: ['enableAcuteProgram', 'currency'],
  });

  return (
    <HHStack>
      <HHFormGroup data-testid={CONTRACT_FORM_ENABLE_ACUTE_CHECK}>
        <HHFormControlLabel
          label="Contract includes acute program"
          control={
            <Controller
              control={control}
              name="enableAcuteProgram"
              render={({ field: { onChange, value } }): JSX.Element => (
                <HHCheckbox
                  sx={{ color: 'gray' }}
                  checked={value}
                  onChange={(): void => onChange(!enableAcuteProgram)}
                  disabled={wholeFormDisabled}
                  hhVariant="primary"
                />
              )}
            />
          }
        />
      </HHFormGroup>
      {watchedEnableAcute ? (
        <HHStack width={'fit-content'}>
          <Controller
            control={control}
            name="acuteCoreCharge"
            render={({ field: { onChange, value } }): JSX.Element => (
              <HHTextField
                data-testid={CONTRACT_FORM_ACUTE_PRICE_INPUT_ID}
                hhVariant="variant-bypass"
                label="Acute core charge"
                inputProps={{
                  inputMode: 'decimal',
                  pattern: '[0-9]*',
                  step: '0.01',
                  min: '0',
                }}
                disabled={wholeFormDisabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      disableTypography
                      sx={{ color: 'gray' }}
                    >
                      {getCurrencySymbol(watchedCurrency?.value)}
                    </InputAdornment>
                  ),
                }}
                error={Object.keys(errors?.acuteCoreCharge ?? {}).length !== 0}
                helperText={errors.acuteCoreCharge?.message}
                value={value}
                onChange={onChange}
                type="number"
                InputLabelProps={{ sx: { color: 'gray' } }}
              />
            )}
          />
        </HHStack>
      ) : null}
    </HHStack>
  );
};
