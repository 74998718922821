import dayjs, { Dayjs } from 'dayjs';
import { ContractSubscriptionType } from '../../../../types';
import { ActiveStatus } from '../../../../utils/contract-utils';
import { ContractPackageFormValues } from '../custom-package-types';

export const canEditActive = (
  newEndDate: Dayjs | null,
  submissionCount: number,
): boolean => {
  const today = dayjs();
  const isValidEndDate =
    newEndDate?.isAfter(today, 'day') || newEndDate === null;
  return submissionCount === 0 || (submissionCount > 0 && isValidEndDate);
};

export const canEditFuture = (
  newStartDate: Dayjs,
  submissionCount: number,
): boolean => {
  const engagementGoLiveDate = dayjs('2024-08-01');
  return (
    submissionCount === 0 && newStartDate.isAfter(engagementGoLiveDate, 'day')
  );
};

export const canCreateNew = (
  submissionCount: number,
  subscriptionCount: number,
): boolean => submissionCount === 0 || subscriptionCount === 0;

/**
 * if submission count is > 0 then we cannot void - false
 */
export const canVoid = (submissionCount: number): boolean =>
  submissionCount === 0;

export const sanityCheck = (
  activeStatus: ActiveStatus,
  formValues: ContractPackageFormValues,
  details: ContractSubscriptionType | undefined,
  isVoid: boolean,
): boolean => {
  const { startDate, endDate } = formValues;
  const sanitizedEndDate = dayjs(endDate).isValid() ? dayjs(endDate) : null;
  if (!details) {
    return false;
  }
  const { submissionCount, subscriptionCount } = details;
  // if active status is past, then only action available is to void...
  // if is void == true, then we are voiding the contract
  if (activeStatus === ActiveStatus.Past || isVoid) {
    return canVoid(submissionCount);
  }
  if (activeStatus === ActiveStatus.Active) {
    return canEditActive(sanitizedEndDate, submissionCount);
  }
  if (activeStatus === ActiveStatus.Future) {
    return canEditFuture(dayjs(startDate), submissionCount);
  }
  if (activeStatus === ActiveStatus.Unknown) {
    return canCreateNew(submissionCount, subscriptionCount);
  } else {
    // if none of the above,
    // then we don't have all the information to make a decision
    return false;
  }
};
