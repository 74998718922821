import { HHBox, HHTypography } from '@hinge-health/react-component-library';
import { titles } from '../constants/member-data-services-constants.constant';
import { MemberDataServicesTabs } from './member-data-services-tabs';

export const MemberDataServicesLayout = (): JSX.Element => (
  <HHBox bgcolor="#ffffff" padding="2rem" minHeight="100%">
    <HHTypography hhVariant="h1">{titles.pageTitle}</HHTypography>
    <MemberDataServicesTabs />
  </HHBox>
);
