import { HHTextField } from '@hinge-health/react-component-library';
import { Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ActiveStatus } from '../../../../utils/contract-utils';
import { IDiscount } from '../../../../utils/discount-utils';
import {
  DISCOUNT_END_DATE_PICKER_ID,
  DISCOUNT_START_DATE_PICKER_ID,
} from '../form-constants';

dayjs.extend(dayOfYear);

export interface DiscountDatePickersProps {
  discountToEdit: IDiscount | null;
}

export const DiscountDatePickers = ({
  discountToEdit,
}: DiscountDatePickersProps): JSX.Element => {
  const {
    control,
    getValues,
    trigger,
    formState: { errors },
    watch,
  } = useFormContext();
  const { startDate, endDate } = getValues();

  const watchedStartDate = watch('startDate');

  // Need to trigger validation on null end date to check for overlaps
  useEffect(() => {
    trigger('endDate');
  }, [errors, trigger]);

  const disableStartInput = discountToEdit
    ? discountToEdit.status !== ActiveStatus.Future
    : false;

  return (
    <Stack direction="row" spacing={3}>
      <Controller
        control={control}
        name="startDate"
        defaultValue={startDate}
        render={({ field: { onChange, value, ref } }): JSX.Element => (
          <DatePicker
            label="Discount start date"
            ref={ref}
            value={value}
            key="start-date"
            disablePast={
              discountToEdit
                ? discountToEdit.status === ActiveStatus.Future
                : true
            } // false if edit status is active (because it's disabled)
            defaultCalendarMonth={dayjs()}
            disabled={disableStartInput}
            onChange={(newValue): void => {
              onChange(dayjs(newValue).toDate()); //parsing to date for validation
            }}
            renderInput={(params): JSX.Element => (
              <HHTextField
                {...params}
                error={
                  params.error ||
                  Object.keys(errors?.startDate ?? {}).length !== 0
                }
                name="startDate"
                fullWidth
                data-testid={DISCOUNT_START_DATE_PICKER_ID}
                hhVariant="variant-bypass"
                helperText={errors.startDate?.message}
                InputLabelProps={{ sx: { color: 'gray' } }}
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name="endDate"
        defaultValue={endDate}
        render={({ field: { onChange, value, ref } }): JSX.Element => (
          <DatePicker
            label="Discount end date"
            ref={ref}
            defaultCalendarMonth={
              dayjs(watchedStartDate).isValid()
                ? dayjs(watchedStartDate).dayOfYear(365) // gets last day of selected start date year
                : dayjs()
            }
            value={value}
            key="end-date"
            disablePast={true}
            shouldDisableDate={(selectedDate): boolean =>
              dayjs(selectedDate).isBefore(watchedStartDate)
            }
            onChange={(newValue): void => {
              onChange(
                dayjs(newValue).isValid() ? dayjs(newValue).toDate() : null, //parsing to date for validation
              );
            }}
            renderInput={(params): JSX.Element => (
              <HHTextField
                {...params}
                error={
                  params.error ||
                  Object.keys(errors?.endDate ?? {}).length !== 0
                }
                helperText={errors.endDate?.message}
                name="endDate"
                fullWidth
                data-testid={DISCOUNT_END_DATE_PICKER_ID}
                hhVariant="variant-bypass"
                InputLabelProps={{ sx: { color: 'gray' } }}
              />
            )}
          />
        )}
      />
    </Stack>
  );
};
