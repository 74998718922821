import { HHCircularProgress } from '@hinge-health/react-component-library';
import { useWorkflowContext } from '../../contexts/workflow.context';
import { AmtFilter } from '../amt-filter/amt-filter';

export const AmtMyWorkflowsFilter = (): JSX.Element => {
  const { filterControls } = useWorkflowContext();
  if (!filterControls) {
    return <HHCircularProgress data-testid="spinner" styleAs="primary" />;
  }
  return <AmtFilter {...filterControls} />;
};
