import { useMutation, useQuery } from '@apollo/client';
import {
  HHBox,
  HHButton,
  HHTable,
  HHTableBody,
  HHTableCell,
  HHTableRow,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GetPhoneStatusForUserDocument,
  SetPhoneForUserDocument,
} from '../types';
interface PhoneStatus {
  basiliskPhone?: string;
  userServicePhone?: string;
  auth0Phones?: string[];
  inSyncUserService?: boolean;
  inSyncAuthService?: boolean;
  inSyncAcross?: boolean;
}

export const MemberPhoneFixTool = (): JSX.Element => {
  const { uuid } = useParams<{ uuid: string }>();
  const [phoneStatus, setPhoneStatus] = useState<PhoneStatus | null>({});

  const isValidUuid = uuid !== ':uuid';

  const {
    data: phoneStatusData,
    loading: phoneStatusLoading,
    error: phoneStatusError,
  } = useQuery(GetPhoneStatusForUserDocument, {
    variables: { hhUuid: uuid },
    skip: !isValidUuid, // Skip the query if uuid is not available
  });

  const [setPhoneForUser, { loading: setPhoneLoading, error: setPhoneError }] =
    useMutation(SetPhoneForUserDocument);

  useEffect(() => {
    if (phoneStatusData && isValidUuid) {
      const basiliskPhone =
        phoneStatusData.getPhoneStatusForUser.basilisk?.[0] ?? undefined;

      const userServicePhone =
        phoneStatusData.getPhoneStatusForUser.userService?.[0] ?? undefined;

      const auth0Phones = phoneStatusData.getPhoneStatusForUser.auth0;

      const inSyncUserService = basiliskPhone === userServicePhone;
      const inSyncAuthService =
        auth0Phones.length === 1 && auth0Phones?.[0] === basiliskPhone;

      setPhoneStatus({
        basiliskPhone,
        userServicePhone,
        auth0Phones: phoneStatusData.getPhoneStatusForUser.auth0 || [],
        inSyncUserService,
        inSyncAuthService,
        inSyncAcross: inSyncUserService && inSyncAuthService,
      });
    }
  }, [phoneStatusData, isValidUuid]);

  const handlePhoneSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (!phoneStatus?.basiliskPhone) {
      return;
    }
    try {
      await setPhoneForUser({
        variables: { hhUuid: uuid, phone: phoneStatus.basiliskPhone },
      });
      const inSyncAuthService = true;
      setPhoneStatus({
        ...phoneStatus,
        auth0Phones: [phoneStatus.basiliskPhone],
        inSyncAuthService,
        inSyncAcross: !!phoneStatus?.inSyncUserService && inSyncAuthService,
      });

      alert('Phone number is udpated successfully');
    } catch (err) {
      console.error(err);
      alert('Phone number was not updated successfully.');
    }
  };

  if (phoneStatusLoading) return <p>Loading .... </p>;
  if (phoneStatusError) return <p> Error: {phoneStatusError.message} </p>;

  return (
    <HHBox bgcolor="#ffffff" padding="2rem" minHeight="100%">
      <HHTypography hhVariant="h1">
        Phone Data for UUID: {isValidUuid && uuid}
      </HHTypography>

      <HHTable
        style={{
          width: '100%',
          maxWidth: '600px',
          tableLayout: 'fixed',
          fontSize: '48px',
        }}
      >
        <HHTableBody>
          <HHTableRow>
            <HHTableCell>
              <HHTypography hhVariant="body1">Basilisk Phone:</HHTypography>
            </HHTableCell>
            <HHTableCell data-testid="basilisk-phone">
              <HHTypography hhVariant="body1">
                {phoneStatus?.basiliskPhone}
              </HHTypography>
            </HHTableCell>
          </HHTableRow>
          <HHTableRow>
            <HHTableCell>
              <HHTypography hhVariant="body1">
                Sync Status User Service:
              </HHTypography>
            </HHTableCell>
            <HHTableCell data-testid="us-status">
              {!!phoneStatus?.inSyncUserService ? (
                <span style={{ color: 'green', fontSize: '24px' }}>●</span>
              ) : (
                <span style={{ color: 'red', fontSize: '24px' }}>
                  ✖
                  {phoneStatus?.userServicePhone
                    ? `(${phoneStatus?.userServicePhone?.toString()})`
                    : ''}
                </span>
              )}
            </HHTableCell>
          </HHTableRow>

          <HHTableRow>
            <HHTableCell>
              <HHTypography hhVariant="body1">
                Sync Status Auth Service:
              </HHTypography>
            </HHTableCell>
            <HHTableCell data-testid="auth-status">
              {!!phoneStatus?.inSyncAuthService ? (
                <span style={{ color: 'green', fontSize: '24px' }}>●</span>
              ) : (
                <span style={{ color: 'red', fontSize: '24px' }}>
                  ✖
                  {phoneStatus?.auth0Phones
                    ? `(${phoneStatus?.auth0Phones?.toString()})`
                    : ''}
                </span>
              )}
            </HHTableCell>
          </HHTableRow>
          {/* ACTIONS -------- */}
          <HHTableRow>
            <HHTableCell>
              <HHButton
                onClick={handlePhoneSubmit}
                hhVariant="contained"
                type="submit"
                disabled={
                  !isValidUuid ||
                  (phoneStatus && phoneStatus.inSyncAcross && isValidUuid) ||
                  setPhoneLoading
                }
                style={{ marginLeft: '10px' }}
              >
                {setPhoneLoading ? 'Updating...' : 'Update Phone'}
              </HHButton>
              {setPhoneError && <p>Error: {setPhoneError.message}</p>}
            </HHTableCell>
          </HHTableRow>
        </HHTableBody>
      </HHTable>
    </HHBox>
  );
};
