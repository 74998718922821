import {
  HHBreadcrumbs,
  HHLink,
  HHStack,
} from '@hinge-health/react-component-library';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import CalculatorResults from '../components/discount-calculator/results';
import type {
  IDiscountCalculatorState,
  IDiscountGroupState,
  IDiscountPrices,
} from '../components/discount-calculator/utils';
import VbdSlice from '../components/discount-calculator/vbd-slice';
import { routes } from '../constants/strings/routes';

const DiscountCalculator = (): JSX.Element => {
  const [chronicCoreCharge, setChronicCoreCharge] = useState(995);
  const [revSharePercent, setRevSharePercent] = useState(0);
  const [groupState, setGroupState] = useState<IDiscountGroupState>({
    R1: {
      m1: 695,
      m2: 150,
      m3: 150,
    },
    R2: {
      m1: 695,
      m2: 150,
      m3: 150,
    },
    R3: {
      m1: 695,
      m2: 150,
      m3: 150,
    },
  });
  const [calculatorState, setCalculatorState] =
    useState<IDiscountCalculatorState>({
      chronicCoreCharge,
      groupState,
      revSharePercent,
    });
  const [defaultPrices, setDefaultPrices] = useState<IDiscountPrices>({
    m1: 695,
    m2: 150,
    m3: 150,
  });

  const onCalculate = (): void => {
    setCalculatorState({
      chronicCoreCharge,
      groupState: { ...groupState },
      revSharePercent,
    });
  };

  const r1OnChange = useCallback((obj: IDiscountPrices): void => {
    setDefaultPrices({
      m1: obj.m1,
      m2: obj.m2,
      m3: obj.m3,
    });
    setGroupState(s => ({ ...s, R1: obj }));
  }, []);

  const r2OnChange = useCallback((obj: IDiscountPrices): void => {
    setGroupState(s => ({ ...s, R2: obj }));
  }, []);

  const r3OnChange = useCallback((obj: IDiscountPrices): void => {
    setGroupState(s => ({ ...s, R3: obj }));
  }, []);

  return (
    <HHStack spacing={6} padding={6} justifyItems="center">
      <HHBreadcrumbs aria-label="breadcrumb">
        <HHLink color="inherit" href={routes.billing.home} underline="hover">
          Operations Hub
        </HHLink>
        <HHLink color="inherit" href={routes.billing.home} underline="hover">
          Billing
        </HHLink>
        <HHLink
          color="inherit"
          underline="hover"
          href={`${routes.billing.home}/${routes.billing.discountCalculator}`}
          target={'_blank'}
        >
          Discount calculator
        </HHLink>
      </HHBreadcrumbs>
      <HHStack direction="row" spacing={2} justifyItems="center">
        <TextField
          label="Total Chronic core charge"
          inputProps={{
            inputMode: 'decimal',
            pattern: '[0-9]*',
            min: '0',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                disableTypography
                sx={{ color: 'gray' }}
              >
                $
              </InputAdornment>
            ),
          }}
          value={chronicCoreCharge}
          onChange={(e): void => {
            setChronicCoreCharge(parseInt(e.target.value));
          }}
          type="number"
          InputLabelProps={{ sx: { color: 'gray' } }}
        />
        <TextField
          label="Rev share %"
          inputProps={{
            inputMode: 'decimal',
            pattern: '[0-9]*',
            min: '0',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                disableTypography
                sx={{ color: 'gray' }}
              >
                %
              </InputAdornment>
            ),
          }}
          value={revSharePercent}
          onChange={(e): void => {
            setRevSharePercent(parseFloat(e.target.value));
          }}
          type="number"
          InputLabelProps={{ sx: { color: 'gray' } }}
        />
      </HHStack>
      <HHStack spacing={2}>
        <Typography variant="h6">Milestone data</Typography>
        <VbdSlice
          type="R1"
          defaultPrices={defaultPrices}
          chronicCoreCharge={chronicCoreCharge}
          onChange={r1OnChange}
        />
        <VbdSlice
          type="R2"
          defaultPrices={defaultPrices}
          chronicCoreCharge={chronicCoreCharge}
          onChange={r2OnChange}
        />
        <VbdSlice
          type="R3"
          defaultPrices={defaultPrices}
          chronicCoreCharge={chronicCoreCharge}
          onChange={r3OnChange}
        />
      </HHStack>
      <HHStack direction="row">
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={onCalculate}
        >
          Calculate
        </Button>
      </HHStack>
      <CalculatorResults defaultPrices={defaultPrices} data={calculatorState} />
    </HHStack>
  );
};

export default DiscountCalculator;
