import {
  ApolloCache,
  ApolloError,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
} from '@apollo/client';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tabRoutes } from '../../constants/hinge-connect-constants.constant';
import { getApolloErrorMessages } from '../../utils/get-apollo-error-messages';
import {
  CreateRuleInput,
  CreateRuleMutation,
  Exact,
  GetRuleDocument,
  ListRulesDocument,
  useCreateRuleMutation,
} from '../types';

interface UseCreateRuleHook {
  createRule: (
    options?:
      | MutationFunctionOptions<
          CreateRuleMutation,
          Exact<{
            createRuleInput: CreateRuleInput;
          }>,
          DefaultContext,
          ApolloCache<unknown>
        >
      | undefined,
  ) => Promise<
    FetchResult<
      CreateRuleMutation,
      Record<string, unknown>,
      Record<string, unknown>
    >
  >;
  data: unknown;
  loading: boolean;
  error: ApolloError | undefined;
  errorMessages: string;
}

export const useCreateRule = (): UseCreateRuleHook => {
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState('');

  const [createRule, { data, loading, error }] = useCreateRuleMutation({
    refetchQueries: [GetRuleDocument, ListRulesDocument],
    onError: (error: ApolloError) => {
      setErrorMessages(getApolloErrorMessages(error));
    },
    onCompleted: ({ createRule }) => {
      navigate(
        `/${tabRoutes.baseRoute}/${tabRoutes.rules}/${createRule.id}/view`,
      );
    },
  });

  return { createRule, data, loading, error, errorMessages };
};
