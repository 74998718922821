import { HHButton, HHIconButton } from '@hinge-health/react-component-library';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { generatePath, Link } from 'react-router-dom';
import { ActiveContractLabels } from '../../constants/strings/billing-label-enums';

export const CONTRACT_EDIT_BUTTON_TEST_ID = 'edit-contract-button-id';

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      textDecoration: 'none',
    },
  }),
);
export interface EditContractPropTypes {
  isText: boolean;
  contractId: number;
  route: string;
  buttonVariant?: 'outlined' | 'text' | 'contained';
}

export const EditContractButton = ({
  isText,
  contractId,
  route,
  buttonVariant = 'outlined',
}: EditContractPropTypes): JSX.Element => {
  const classes = useStyles();

  return (
    <Link
      to={generatePath(route, {
        contractId: contractId?.toString(),
      })}
      className={classes.link}
    >
      {isText ? (
        <HHIconButton
          hhVariant="variant-bypass"
          aria-label="Edit"
          color="primary"
          size="small"
          title="Edit"
          data-testid={CONTRACT_EDIT_BUTTON_TEST_ID}
        >
          <ModeEditIcon fontSize="small" />
        </HHIconButton>
      ) : (
        <HHButton
          hhVariant="variant-bypass"
          variant={buttonVariant}
          aria-label="Edit"
          size="medium"
          color="primary"
          data-testid={CONTRACT_EDIT_BUTTON_TEST_ID}
        >
          {ActiveContractLabels.EDIT_CONTRACT_LABEL}
        </HHButton>
      )}
    </Link>
  );
};
