import {
  HHBox,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useClientInsurersByClient } from '../../hooks/client-insurer-hook';
import LoadingComponent from '../utils/loading';

const ClientHeader = (): JSX.Element => {
  const params = useParams<{ id: string }>();
  const clientId = parseInt(params.id || '0', 10);
  const { data, loading, error } = useClientInsurersByClient(clientId);

  if (loading) {
    return (
      <HHBox sx={{ width: '100%' }}>
        <LoadingComponent center />
      </HHBox>
    );
  }

  if (error || !data) {
    return <HHTypography hhVariant="body">Client info not found</HHTypography>;
  }

  return (
    <HHStack
      direction="row"
      alignItems="center"
      spacing={4}
      padding={4}
      sx={(theme): SystemStyleObject<Theme> => ({
        backgroundColor: theme.palette.background.default,
        width: '100%',
      })}
    >
      <HHTypography hhVariant="section-title">{data.name}</HHTypography>
      <HHTypography hhVariant="muted" variant="overline">
        ID {data.id}
      </HHTypography>
    </HHStack>
  );
};

export default ClientHeader;
