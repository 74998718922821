import {
  HHDivider,
  HHDrawer,
  HHIconButton,
  HHStack,
} from '@hinge-health/react-component-library';
import Close from '@mui/icons-material/Close';
import { DrawerProps } from '@mui/material/Drawer';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

export interface DynamicDrawerContextType {
  setDrawerContent: (
    content: JSX.Element,
    title?: JSX.Element,
    drawerWidth?: string,
    drawerVariant?: DrawerProps['variant'],
  ) => void;
  setIsOpen: (isOpen: boolean) => void;
  setTitle: (title: JSX.Element) => void;
  onClose: () => void;
}

const initialContext: DynamicDrawerContextType = {
  setDrawerContent: () => null,
  setIsOpen: () => null,
  setTitle: () => null,
  onClose: () => null,
};

export const DynamicDrawerContext =
  createContext<DynamicDrawerContextType>(initialContext);

export const DynamicDrawerProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [title, setTitle] = useState<JSX.Element | null>(null);
  const [drawerWidth, setDrawerWidth] = useState<string>('');
  const [drawerVariant, setDrawerVariant] = useState<DrawerProps['variant']>();

  const setDrawerContent = useCallback(
    (
      content: JSX.Element,
      title?: JSX.Element,
      drawerWidth?: string,
      drawerVariant?: DrawerProps['variant'] | undefined,
    ): void => {
      setContent(content);
      if (title) {
        setTitle(title);
      }
      setIsOpen(true);
      setDrawerWidth(drawerWidth ?? '25%');
      setDrawerVariant(drawerVariant ?? 'persistent');
    },
    [setContent],
  );

  const onClose = useCallback((): void => {
    setIsOpen(false);
    setContent(null);
    setTitle(null);
  }, [setIsOpen, setContent, setTitle]);

  return (
    <DynamicDrawerContext.Provider
      value={{ setDrawerContent, setIsOpen, setTitle, onClose }}
    >
      <HHStack
        flex={1}
        height="100%"
        width={isOpen && drawerVariant !== 'temporary' ? '75%' : '100%'}
        overflow="scroll"
      >
        {children}
      </HHStack>
      <HHDrawer
        data-testid="dynamic-drawer"
        open={isOpen}
        anchor="right"
        onClose={onClose}
        variant={drawerVariant}
        role="dialog"
        PaperProps={{
          sx: {
            width: drawerWidth,
            overflow: 'scroll',
          },
        }}
      >
        <HHStack height="100%" direction="column" spacing={2} padding={2}>
          <HHStack
            direction="row"
            spacing={2}
            padding={1}
            justifyContent="space-between"
            alignItems="center"
          >
            {title ?? <div></div>}
            <HHIconButton
              hhVariant="variant-bypass"
              size="medium"
              color="default"
              aria-label="close"
              onClick={onClose}
              title="Close"
            >
              <Close />
            </HHIconButton>
          </HHStack>
          <HHDivider />
          {content}
        </HHStack>
      </HHDrawer>
    </DynamicDrawerContext.Provider>
  );
};

export const useDynamicDrawerContext = (): DynamicDrawerContextType =>
  useContext(DynamicDrawerContext);
