import { HHBox, HHGrid } from '@hinge-health/react-component-library';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ApplicationManagementToolBodyHeader } from './application-management-tool-body-header';
import { ApplicationManagementToolTabs } from './application-management-tool-tabs';

const useStyles = makeStyles(() =>
  createStyles({
    bodyBoxContainer: {
      margin: 32,
      height: '100%',
      overflow: 'hidden',
    },
    bodyContainer: {
      marginTop: 24,
      display: 'flex',
      padding: '0 42px',
    },
    cardContainer: {
      marginLeft: 32,
      padding: '0 32px',
      flex: 1,
      background: 'white',
      borderRadius: 5,
    },
    calendarContainer: {
      background: 'white',
      padding: 10,
      borderRadius: 5,
      height: '100%',
      overflow: 'hidden',
    },
    rightSide: {
      display: 'flex',
      height: '100%',
    },
    bodyGrid: {
      height: '100%',
    },
  }),
);

export const ApplicationManagementToolDashboardBody = (): JSX.Element => {
  const classes = useStyles();

  return (
    <HHBox sx={{ flexGrow: 1 }} className={classes.bodyBoxContainer}>
      <HHGrid container className={classes.bodyGrid}>
        <HHGrid item xs={12} className={classes.rightSide}>
          <HHBox className={classes.cardContainer}>
            <ApplicationManagementToolBodyHeader />
            <ApplicationManagementToolTabs />
          </HHBox>
        </HHGrid>
      </HHGrid>
    </HHBox>
  );
};
