import { HHAlert, HHTypography } from '@hinge-health/react-component-library';
import Box from '@mui/material/Box';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';
import BillingLeftPanel from '../components/billing-tool-left-panel';
import BillingMainSection from '../components/billing-tool-main-section';
import LoadingComponent from '../components/utils/loading';
import { useClientInsurersById } from '../hooks/client-insurer-hook';

const useStyles = makeStyles(() =>
  createStyles({
    insurerIdContainer: {
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
    },
  }),
);

export const CLIENT_INSURER_BY_ID_CONTAINER_TEST_ID =
  'client-insurer-by-id-container';

const ClientInsurerByIdPage = (): JSX.Element => {
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const clientInsurerId = parseInt(params.id || '0', 10);
  const { data, loading, error } = useClientInsurersById(clientInsurerId);

  if (!loading && error) {
    return (
      <HHAlert
        hhVariant="outlined"
        severity="error"
        children={
          <HHTypography hhVariant="body">
            There was an error loading the client insurer.
          </HHTypography>
        }
      ></HHAlert>
    );
  }

  if (!loading && !data) {
    return (
      <Box className={classes.insurerIdContainer}>
        <HHTypography hhVariant="body">
          Client insurer info not found
        </HHTypography>
      </Box>
    );
  }

  if (loading || !data) {
    return <LoadingComponent center />;
  }

  return (
    <Box
      className={classes.insurerIdContainer}
      data-testid={CLIENT_INSURER_BY_ID_CONTAINER_TEST_ID}
    >
      <BillingLeftPanel data={data} />
      <BillingMainSection clientInsurerData={data} />
    </Box>
  );
};

export default ClientInsurerByIdPage;
