import { HHStack, HHTypography } from '@hinge-health/react-component-library';
import { useEffect, useState } from 'react';
import { useGetSubscriptionEngagementsLazyQuery } from '../../types';
import EngagementsData from './engagements-data';

export interface EngagementsBySubscriptionIdProps {
  subscriptionId: number;
}

const EngagementsBySubscriptionId = ({
  subscriptionId,
}: EngagementsBySubscriptionIdProps): JSX.Element => {
  const [page, setPage] = useState(1);
  const [getSubscriptionEngagements, { data, loading, error }] =
    useGetSubscriptionEngagementsLazyQuery();
  const totalDataCnt = data?.getSubscriptionEngagements.total ?? 0;

  useEffect(() => {
    getSubscriptionEngagements({
      variables: { subscriptionId, page, size: 10 },
    });
  }, [page, subscriptionId, getSubscriptionEngagements]);

  return (
    <HHStack spacing={2} padding={1}>
      <HHTypography hhVariant="subtitle1">Engagements</HHTypography>
      <EngagementsData
        loading={loading}
        error={Boolean(error)}
        page={page}
        engagementsData={data?.getSubscriptionEngagements}
        setPage={setPage}
        total={totalDataCnt}
        pages={data?.getSubscriptionEngagements.pages ?? 1}
      />
    </HHStack>
  );
};

export default EngagementsBySubscriptionId;
