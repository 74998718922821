import {
  HHButton,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import TablePagination, {
  TablePaginationProps,
} from '@mui/material/TablePagination';
import { useEffect } from 'react';

import { doesClientInsurerChangeMatch } from './archive-release-components/bill-panel';
import {
  BillTypeDataList,
  GridSnackBarState,
  NonReleasableHoldReasons,
} from './bill-holds-types';

export type GridFooterProps = TablePaginationProps & {
  selectedRows: BillTypeDataList;
  releaseAction: () => void;
  archiveAction: () => void;
  setSnackBarState: React.Dispatch<React.SetStateAction<GridSnackBarState>>;
};

export const GridFooter = ({
  selectedRows,
  releaseAction,
  archiveAction,
  setSnackBarState,
  ...props
}: GridFooterProps): JSX.Element => {
  const hasNonReleasableHolds = selectedRows.some(
    row =>
      row?.holdDetails?.holdType &&
      Object.values(NonReleasableHoldReasons).includes(
        row?.holdDetails.holdType as NonReleasableHoldReasons,
      ),
  );

  const selectedCount = selectedRows.length;
  const hasSameHoldType = selectedRows.every(
    row => row.holdDetails?.holdType === selectedRows[0].holdDetails?.holdType,
  );
  const clientInsurerChangesMatch = doesClientInsurerChangeMatch(
    hasSameHoldType,
    selectedRows,
  );

  useEffect(() => {
    if (hasNonReleasableHolds) {
      setSnackBarState({
        open: true,
        message: 'Selected rows have non-releaseable holds',
        severity: 'info',
        hideTime: 5000,
      });
    }
  }, [hasNonReleasableHolds, setSnackBarState]);

  return (
    <HHStack
      spacing={5}
      paddingRight={3}
      paddingLeft={3}
      direction="row"
      justifyContent="space-between"
      boxShadow="0px -2px 4px rgba(0, 0, 0, 0.1)"
    >
      <TablePagination
        {...props}
        rowsPerPageOptions={[25]}
        sx={{ padding: 0 }}
      />
      <HHStack direction="row" spacing={2} alignItems="center">
        <HHTypography hhVariant="muted" flex={1}>
          {selectedCount > 0
            ? `${selectedCount} bills selected`
            : 'No bills selected'}
        </HHTypography>
        <HHButton
          variant="contained"
          color="primary"
          hhVariant="contained"
          onClick={releaseAction}
          disabled={
            hasNonReleasableHolds ||
            selectedCount === 0 ||
            !hasSameHoldType ||
            !clientInsurerChangesMatch
          }
        >
          Release
        </HHButton>
        <HHButton
          variant="outlined"
          color="primary"
          hhVariant="outlined"
          onClick={archiveAction}
          disabled={selectedCount === 0}
        >
          Archive
        </HHButton>
      </HHStack>
    </HHStack>
  );
};
