import { datadogRum } from '@datadog/browser-rum';
import { CtpThemeProvider } from '@hinge-health/react-component-library';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import StylesProvider from '@mui/styles/StylesProvider';
import * as Sentry from '@sentry/browser';
import { SplitFactory } from '@splitsoftware/splitio-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import WebFont from 'webfontloader';
import App from './app';
import { getBasePath, sdkConfig } from './config/index';
import { NODE_ENV } from './constants/env';
import {
  STYLE_SEED,
  STYLE_SEED_OVERRIDE,
} from './constants/strings/style-seed';

WebFont.load({
  google: {
    families: [
      'Roboto:wght@100,300,400,500,700,900',
      'Lato:wght@100,300,400,500,700',
      'sans-serif',
    ],
  },
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  debug: process.env.NODE_ENV === NODE_ENV.DEVELOPMENT,
  environment: process.env.APP_ENV,
  release: process.env.VERSION,
  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
});

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'operations-hub-app',
  env: process.env.APP_ENV,
  sessionSampleRate: 100,
  version: process.env.VERSION,
  useSecureSessionCookie: true,
  trackResources: true,
  trackUserInteractions: true,
  excludedActivityUrls: [/split\.io/, /sentry\.io/],
});

const generateClassName = createGenerateClassName({
  productionPrefix: STYLE_SEED_OVERRIDE,
  seed: STYLE_SEED,
});

const baseName = getBasePath();

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider generateClassName={generateClassName}>
      <BrowserRouter basename={baseName}>
        {process.env.SPLIT_CTT_SHELL_AUTH_KEY ? (
          <SplitFactory config={sdkConfig}>
            <CtpThemeProvider>
              <App />
            </CtpThemeProvider>
          </SplitFactory>
        ) : (
          <CtpThemeProvider>
            <App />
          </CtpThemeProvider>
        )}
      </BrowserRouter>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

export default App;
