import { HHStack, HHTypography } from '@hinge-health/react-component-library';
import dayjs from 'dayjs';
import { ContractSubscriptionType } from '../../../../types';
import { computeEffectiveRange } from '../../../../utils/date-helper';
import LoadingComponent from '../../../utils/loading';
import { TextGrouping } from '../../../utils/text-grouping';
import { PackageFormConfirmationProps } from './package-form-confirmation';

interface EditContractPackageDetailsProps {
  notificationString: string;
  formValues: PackageFormConfirmationProps['formValues'];
  isVoid?: boolean;
  loading: boolean;
  details: ContractSubscriptionType | undefined;
}

const EditContractPackageDetails = ({
  notificationString,
  formValues,
  isVoid,
  loading,
}: EditContractPackageDetailsProps): JSX.Element => {
  if (loading) {
    return <LoadingComponent />;
  }
  return (
    <HHStack spacing={3}>
      <HHTypography hhVariant="body2">{notificationString}</HHTypography>
      {dayjs(formValues.startDate).isValid() &&
      dayjs(formValues.startDate).isBefore(dayjs()) &&
      !isVoid ? (
        <TextGrouping
          label="Changes"
          text={computeEffectiveRange(formValues.startDate, formValues.endDate)}
        />
      ) : null}
    </HHStack>
  );
};

export default EditContractPackageDetails;
