import {
  HHBox,
  HHCircularProgress,
  HHGrid,
  HHLabelSection,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { HCModal } from '../../../components/hinge-connect-modal';
import { useGetHealthRecordQuery } from '../../../health-records/types';
import {
  resourceDetailLabels,
  resourceInfoMessages,
} from '../../constants/user-tags.constant';

const ResourceMetadataItem = ({
  label,
  content,
}: {
  label: string;
  content: string;
}): JSX.Element => (
  <HHGrid item xs={12} sm={6}>
    <HHLabelSection hhVariant="text" {...{ label, content }} />
  </HHGrid>
);

const ResourceDetails = ({ id }: { id: string }): JSX.Element => {
  const { data, loading, error } = useGetHealthRecordQuery({
    variables: { id },
  });

  const resourceErrorMessage = resourceInfoMessages.error(id);

  if (loading)
    return (
      <HHBox display="flex" alignItems="center" justifyContent="center">
        <HHCircularProgress />
      </HHBox>
    );
  if (error)
    return (
      <HHStack spacing={4}>
        <HHTypography hhVariant="h2">{resourceErrorMessage}</HHTypography>
        <HHTypography hhVariant="body">{error.message}</HHTypography>
      </HHStack>
    );
  if (!data)
    return (
      <HHBox display="flex" alignItems="center" justifyContent="center">
        {resourceErrorMessage}
      </HHBox>
    );
  return (
    <HHGrid container>
      <ResourceMetadataItem
        label={resourceDetailLabels.resourceId}
        content={data.getHealthRecord.resourceId}
      />
      <HHGrid item xs={12}>
        <HHTypography hhVariant="h6">{resourceDetailLabels.data}</HHTypography>
        <pre data-testid="health-record-data">
          {JSON.stringify(data.getHealthRecord.data, null, 2)}
        </pre>
      </HHGrid>
    </HHGrid>
  );
};

type ResourceDetailModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  id: string;
};

export const ResourceDetailModal = ({
  isOpen,
  handleClose,
  id,
}: ResourceDetailModalProps): JSX.Element => (
  <HCModal
    title={resourceDetailLabels.title}
    isOpen={isOpen}
    handleClose={handleClose}
    component={<ResourceDetails id={id} />}
  />
);
