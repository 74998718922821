import { HHStack } from '@hinge-health/react-component-library';
import { useMemo, useState } from 'react';
import { DateRangePickerValue } from '../../../components/date-range-picker';
import {
  ListHealthRecordsInput,
  useListHealthRecordsAtCursorLazyQuery,
  useListHealthRecordsLazyQuery,
} from '../../types';
import { HealthRecordsToolbar } from './health-records-toolbar';
import { ListHealthRecordsGrid } from './list-health-records-grid';

export const ListHealthRecordsView = (): JSX.Element => {
  const [source, setSource] = useState('');
  const [limit, setLimit] = useState(100);
  const [resourceType, setResourceType] = useState('');
  const [dateRange, setDateRange] = useState<
    [DateRangePickerValue, DateRangePickerValue]
  >([null, null]);
  const [isNewSearch, setIsNewSearch] = useState(false);

  const dto = useMemo(() => {
    if (!source) return;
    const newDto: ListHealthRecordsInput = { source };
    if (resourceType) {
      newDto.resourceType = resourceType;
    }
    if (limit && limit >= 1) {
      newDto.limit = limit;
    }
    if (dateRange[0]) {
      newDto.startDate = dateRange[0].toISOString().split('T')[0];
    }
    if (dateRange[1]) {
      newDto.endDate = dateRange[1].toISOString().split('T')[0];
    }
    return newDto;
  }, [source, limit, resourceType, dateRange]);

  const [
    listHealthRecords,
    { data: initialData, loading: initialLoading, error: initialError },
  ] = useListHealthRecordsLazyQuery();

  const [
    listHealthRecordsAtCursor,
    { data: cursorData, loading: cursorLoading, error: cursorError },
  ] = useListHealthRecordsAtCursorLazyQuery();

  const handleSearch = (): void => {
    setIsNewSearch(true);
    listHealthRecords({
      variables: { listHealthRecordsInput: dto as ListHealthRecordsInput },
    });
  };

  const handlePageTurnClick = (cursorPath: string): void => {
    setIsNewSearch(false);
    listHealthRecordsAtCursor({
      variables: {
        cursorPath,
      },
    });
  };

  return (
    <HHStack>
      <HealthRecordsToolbar
        {...{
          source,
          setSource,
          resourceType,
          setResourceType,
          dateRange,
          setDateRange,
          limit,
          setLimit,
          handleSearch,
        }}
      />
      <ListHealthRecordsGrid
        {...{
          data: isNewSearch ? initialData : cursorData,
          loading: isNewSearch ? initialLoading : cursorLoading,
          error: isNewSearch ? initialError : cursorError,
          handlePageTurnClick,
        }}
      />
    </HHStack>
  );
};
