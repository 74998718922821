import {
  ApolloCache,
  ApolloError,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
} from '@apollo/client';
import { useState } from 'react';
import { getApolloErrorMessages } from '../../utils/get-apollo-error-messages';
import {
  CreateAssessmentTaskInput,
  CreateAssessmentTaskMutation,
  Exact,
  ListAssessmentTasksDocument,
  useCreateAssessmentTaskMutation,
} from '../types';

interface UseCreateAssessmentTaskHook {
  createAssessmentTask: (
    options?:
      | MutationFunctionOptions<
          CreateAssessmentTaskMutation,
          Exact<{
            createAssessmentTaskInput: CreateAssessmentTaskInput;
          }>,
          DefaultContext,
          ApolloCache<unknown>
        >
      | undefined,
  ) => Promise<
    FetchResult<
      CreateAssessmentTaskMutation,
      Record<string, unknown>,
      Record<string, unknown>
    >
  >;
  data: unknown;
  loading: boolean;
  error: ApolloError | undefined;
  errorMessages: string;
}

export const useCreateAssessmentTask = (
  completeCallback: () => void,
): UseCreateAssessmentTaskHook => {
  const [errorMessages, setErrorMessages] = useState('');
  const [createAssessmentTask, { data, loading, error }] =
    useCreateAssessmentTaskMutation({
      refetchQueries: [ListAssessmentTasksDocument],
      onError: (error: ApolloError) => {
        setErrorMessages(getApolloErrorMessages(error));
      },
      onCompleted: () => {
        completeCallback();
      },
    });

  return { createAssessmentTask, data, loading, error, errorMessages };
};
