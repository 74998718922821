import { ContextClientInsurerCollectionType } from '../../context/billing.context';
import { PaginatedBillType } from '../../types';
import { BillTypeDataList } from './bill-holds-grid/bill-holds-types';

export const generateBillHoldsData = (
  items: PaginatedBillType[],
  ciData: ContextClientInsurerCollectionType,
): BillTypeDataList =>
  items.map(item => {
    const ci = ciData[item.clientsInsurerId] ?? {
      client: null,
      insurer: null,
      partnerships: null,
      billingType: null,
      loading: true,
      error: null,
    };

    return {
      ...item,
      client: {
        name: !ci.loading && !ci.error ? ci.client.name : null,
        id: !ci.loading && !ci.error ? ci.client.id : null,
        isErrored: Boolean(ci.error),
        loading: false,
      },
      insurer: {
        name: !ci.loading && !ci.error ? ci.insurer.name : null,
        id: !ci.loading && !ci.error ? ci.insurer.id : null,
        isErrored: Boolean(ci.error),
        loading: false,
      },
      partnership: {
        name:
          !ci.loading && !ci.error
            ? ci.partnerships.filter(p => p.partnershipType === 'billing')[0]
                ?.name
            : null,
        id:
          !ci.loading && !ci.error
            ? ci.partnerships.filter(p => p.partnershipType === 'billing')[0]
                ?.id
            : null,
        isErrored: Boolean(ci.error),
        loading: false,
      },
      billingType: {
        value:
          !ci.loading && !ci.error && ci.billingType ? ci.billingType : null,
        isErrored: Boolean(ci.error),
        loading: false,
      },
    };
  });
