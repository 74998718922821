import { ApolloError } from '@apollo/client';
import { ListUserTagsQuery, useListUserTagsQuery } from '../types';

export type UseListUserTagsHook = {
  data?: ListUserTagsQuery;
  loading: boolean;
  error?: ApolloError;
  refetch: () => void;
};

export type UseListUserTagsHookProps = {
  userId?: string;
};

export function useListUserTagsHook(
  props: UseListUserTagsHookProps,
): UseListUserTagsHook {
  const { data, loading, error, refetch } = useListUserTagsQuery({
    skip: !props.userId,
    variables: {
      listUserTagsInput: { user: props.userId },
    },
  });

  return { data, loading, error, refetch };
}
