import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';
import {
  BillingTypes,
  ClientType,
  InsurerType as InsurerTypeType,
  PartnershipType,
} from '../../types';

export type InsurerType = Omit<
  InsurerTypeType,
  'eligibilityRef' | 'memberIdPrefix' | 'memberIdRequired' | '__typename'
>;

export enum ToolRunMode {
  PREVIEW = 'preview',
  SUBMISSION = 'submission',
}

export enum EligibilitySearchDepth {
  STANDARD = 'standard',
  DEEP = 'deep',
}

export const addAllOption = <T>(data: T[]): T[] => [
  { id: -1, name: 'All', identifier: 'All' } as T,
  ...data,
];

const parseDate = (_: Dayjs, originalValue: Dayjs | null): Date | null =>
  dayjs(originalValue).isValid() ? dayjs(originalValue).toDate() : null;

export const ToolValidationSchema = yup.object({
  client: yup
    .object({
      id: yup.number().required(),
      identifier: yup.string().required(),
      name: yup.string().required(),
    })
    .required(),
  partnership: yup
    .object({
      id: yup.number().required(),
      partnershipType: yup.string().required(),
      name: yup.string().required(),
    })
    .nullable()
    .optional()
    .default(null),
  insurer: yup
    .object({
      id: yup.number().required(),
      identifier: yup.string().required(),
      name: yup.string().required(),
    })
    .nullable()
    .optional()
    .default(null),
  isLoading: yup.boolean().default(false),
  billingType: yup.string().required().oneOf(Object.values(BillingTypes)),
  excludePartnership: yup.boolean().required().default(false),
  claimsLimit: yup.number().min(0).required().default(0),
  eligibilitySearchDepth: yup
    .string()
    .required()
    .oneOf([EligibilitySearchDepth.STANDARD, EligibilitySearchDepth.DEEP])
    .default(EligibilitySearchDepth.STANDARD),
  runMode: yup
    .string()
    .required()
    .oneOf([ToolRunMode.SUBMISSION, ToolRunMode.PREVIEW])
    .default(ToolRunMode.PREVIEW),
  paymentTypes: yup
    .array()
    .of(yup.string().required())
    .nullable()
    .defined()
    .default(null),
  dateRange: yup
    .array()
    .of(yup.date().transform(parseDate).defined())
    .min(2)
    .max(2)
    .required()
    .test({
      name: 'endDateBeforeStart',
      skipAbsent: true,
      test(value, ctx) {
        if (dayjs(value[1]).isBefore(value[0], 'day')) {
          return ctx.createError({
            message: 'End date is before start date',
          });
        }
        return true;
      },
    })
    .test({
      name: 'oneYearRelativeMaximum',
      skipAbsent: true,
      test(value, ctx) {
        if (dayjs(value[1]).diff(dayjs(value[0]), 'year') >= 1) {
          return ctx.createError({
            message: 'Date range must be within 1 year',
          });
        }
        return true;
      },
    }),
  clientOptions: yup
    .array<ClientType>()
    .of(
      yup.object({
        id: yup.number().required(),
        identifier: yup.string().required(),
        name: yup.string().required(),
      }),
    )
    .optional(),
  insurerOptions: yup
    .array<InsurerType>()
    .of(
      yup.object({
        id: yup.number().required(),
        identifier: yup.string().required(),
        name: yup.string().required(),
      }),
    )
    .optional(),
  partnershipOptions: yup
    .array<PartnershipType>()
    .of(
      yup.object({
        id: yup.number().required(),
        partnershipType: yup.string().required(),
        name: yup.string().required(),
      }),
    )
    .optional(),
  billingTypeOptions: yup
    .array<BillingTypes[]>()
    .of(
      yup.object({
        value: yup.string().required(),
        displayName: yup.string().required(),
      }),
    )
    .optional(),
});
