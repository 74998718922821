import { HHGrid } from '@hinge-health/react-component-library';
import { AMT_TABLE_TITLES } from '../constants/strings/application-management-tool';
import { myWorkflowTableRowSizes } from './application-management-tool-my-workflows-table';
import { useCardStyles } from './styles/card-styles';

export const ApplicationManagementToolMyWorkflowTableHeader =
  (): JSX.Element => {
    const classes = useCardStyles();
    return (
      <HHGrid container className={classes.titleText}>
        <HHGrid item sm={myWorkflowTableRowSizes.number}>
          #
        </HHGrid>
        <HHGrid item sm={myWorkflowTableRowSizes.userUuid}>
          {AMT_TABLE_TITLES.USER_ID}
        </HHGrid>
        <HHGrid item sm={myWorkflowTableRowSizes.firstName}>
          {AMT_TABLE_TITLES.FIRST_NAME}
        </HHGrid>
        <HHGrid item sm={myWorkflowTableRowSizes.lastName}>
          {AMT_TABLE_TITLES.LAST_NAME}
        </HHGrid>
        <HHGrid item sm={myWorkflowTableRowSizes.state}>
          {AMT_TABLE_TITLES.STATE}
        </HHGrid>
        <HHGrid item sm={myWorkflowTableRowSizes.lastUpdated}>
          {AMT_TABLE_TITLES.LAST_UPDATED}
        </HHGrid>
        <HHGrid item sm={myWorkflowTableRowSizes.unassign}>
          {AMT_TABLE_TITLES.UNASSIGN}
        </HHGrid>
      </HHGrid>
    );
  };
