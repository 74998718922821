export const SECONDARY_TEXT_COLOR = 'rgba(0, 0, 0, 0.6)';
export const MENU_LABEL = '#1976D2';
export const NOTE_COLOR = '#FBFAFA';
export const TITLE_LIGHT = 'rgba(0, 0, 0, 0.38)';
export const MENU_BORDER = '#CCCCCC';
export const MEMBER_DETAILS_TEXT_COLOR = 'black';
export const NOTE_TEXT = '#111111';
export const ICON_BLUE = '#607D8B';
export const ERROR_COLOR = '#D32F2F';
export const WARNING_COLOR = '#ED6C02';
export const SIDE_BAR_SUB_MENU_TITLE_COLOR = '#78909C';
export const ACTIVE_CHIP_COLOR = '#FFFFFF';
export const DIVIDER_COLOR = '#0000001F';
export const ACCORDION_BORDER = '#C4C4C4';
export const LABEL_LIGHT = 'rgba(0, 0, 0, .6)';
export const BUTTON_BACKGROUND_COLOR = '#1976D2';
export const BUTTON_DISABLED_TEXT = '#757575';
export const BUTTON_DISABLED_GREY = '#00000012';
export const GREEN = '#4CAF50';
export const HEADER_COLOR = '#628394';
export const SIDEBAR_COLOR = '#628393';
export const SIDEBAR_CHIP_COLOR = '#EBEBEB';
export const ACTIVATED_COLOR = '#B2E6D4';
export const WARNING_YELLOW = '#FFD23F';
export const LIGHT_URGENT_RED = '#E68989';

export const AMT_THEME_COLORS = {
  ERROR: ERROR_COLOR,
  WARNING: WARNING_COLOR,
  LINK: MENU_LABEL,
};

export const AMT_BUTTON_COLORS = {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_DISABLED_TEXT,
  BUTTON_DISABLED_GREY,
  GREEN,
};

export const AMT_MENU_COLORS = {
  MENU_LABEL,
  SECONDARY_TEXT_COLOR,
  MENU_BORDER,
  TITLE_LIGHT,
  ICON_BLUE,
  DIVIDER_COLOR,
};

export const MEMBER_DETAILS_COLORS = {
  BLACK: MEMBER_DETAILS_TEXT_COLOR,
  WHITE: ACTIVE_CHIP_COLOR,
  SECONDARY_TEXT_COLOR,
  ICON_GREY: SIDE_BAR_SUB_MENU_TITLE_COLOR,
  NOTE_COLOR,
  NOTE_BORDER: ACCORDION_BORDER,
  NOTE_TEXT,
};
