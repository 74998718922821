import { HHLoadingButton } from '@hinge-health/react-component-library';
import {
  Alert,
  AlertColor,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import React from 'react';

type UserTagDeleteModalProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirmDelete: () => Promise<void>;
  loading: boolean;
  alert: { type: string; message: string };
  setAlert: React.Dispatch<
    React.SetStateAction<{ type: string; message: string }>
  >;
};

export const UserTagDeleteModal: React.FC<UserTagDeleteModalProps> = ({
  open,
  handleClose,
  handleConfirmDelete,
  loading,
  alert,
  setAlert,
}) => (
  <>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete User Tag</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this user tag?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <HHLoadingButton
          onClick={handleConfirmDelete}
          hhVariant="variant-bypass"
          variant="contained"
          type="submit"
          loading={loading}
          data-testid="delete-tag-button"
        >
          Delete
        </HHLoadingButton>
      </DialogActions>
    </Dialog>
    <Snackbar
      open={!!alert.message}
      autoHideDuration={6000}
      onClose={(): void => setAlert({ type: '', message: '' })}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={(): void => setAlert({ type: '', message: '' })}
        severity={alert.type as AlertColor}
      >
        {alert.message}
      </Alert>
    </Snackbar>
  </>
);
