import {
  HHTabs,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import {
  tabLabels,
  tabRoutes,
} from '../constants/member-data-services-constants.constant';
import { FileConfigTab } from '../file-configurations/components/file-config-tabs';
import { TargetedEnrollmentTab } from '../targeted-enrollment/components/targeted-enroll-tabs';

export const MemberDataServicesTabs = (): JSX.Element => {
  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const userHasPhiAccess =
    hingeHealthAuthState?.accessToken?.claims?.phiaccess ?? false;

  const generalUseTabs = [
    {
      tabLabel: tabLabels.fileConfigurations,
      tabContent: <FileConfigTab />,
      to: `/${tabRoutes.baseRoute}/${tabRoutes.fileConfigurations}`,
    },
    {
      tabLabel: tabLabels.targetedEnrollment,
      tabContent: <TargetedEnrollmentTab />,
      to: `/${tabRoutes.baseRoute}/${tabRoutes.targetedEnrollment}`,
    },
  ];

  const tabsData = userHasPhiAccess ? [...generalUseTabs] : generalUseTabs;

  return <HHTabs {...{ tabsData }} />;
};
