import { Navigate, Route, Routes } from 'react-router-dom';
import { tabRoutes } from '../../constants/hinge-connect-constants.constant';
import { assessmentTasksRoutes } from '../constants/assessment-tasks.constant';
import { AssessmentTaskDetailView } from './assessment-task-detail-view';
import { ListAssessmentTasksView } from './list-assessment-tasks-view';

export const AssessmentTasksTab = (): JSX.Element => (
  <Routes>
    <Route
      path={`/${tabRoutes.assessmentTasks}/:id/${assessmentTasksRoutes.view}`}
      element={<AssessmentTaskDetailView />}
    />
    <Route
      path={`/${tabRoutes.assessmentTasks}/${assessmentTasksRoutes.list}`}
      element={<ListAssessmentTasksView />}
    />
    <Route
      path="*"
      element={
        <Navigate
          to={`/${tabRoutes.baseRoute}/${tabRoutes.assessmentTasks}/${assessmentTasksRoutes.list}`}
        />
      }
    />
  </Routes>
);
