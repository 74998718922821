import { AMT_PATHWAY_TRANSITION_STATES } from '../../custom-types';
export const statesMap = new Map<string, string>([
  [AMT_PATHWAY_TRANSITION_STATES.WRONG_EMPLOYER_CHECK, 'Wrong Employer Check'],
  [AMT_PATHWAY_TRANSITION_STATES.IS_DUPLICATE_ACCOUNT, 'Duplicate Account'],
  [
    AMT_PATHWAY_TRANSITION_STATES.WAITING_FOR_ASSIGNMENT,
    'Waiting For Program Transition',
  ],
  [AMT_PATHWAY_TRANSITION_STATES.ADD_COACH_NOTE, 'Create Temporary Coverage'],
  [AMT_PATHWAY_TRANSITION_STATES.PROVISIONED, 'Provision'],
  [AMT_PATHWAY_TRANSITION_STATES.CANCELED, 'Cancel'],
  [
    AMT_PATHWAY_TRANSITION_STATES.CHECK_FOR_DUPLICATE_ACCOUNT,
    'Check for duplicate account',
  ],
  [AMT_PATHWAY_TRANSITION_STATES.CANCELED, 'Cancel'],
  [
    AMT_PATHWAY_TRANSITION_STATES.WAITING_FOR_EMAIL_INSURANCE_FORM_RESPONSE,
    'Waiting for insurance form',
  ],
  [
    AMT_PATHWAY_TRANSITION_STATES.RECEIVED_EMAIL_INSURANCE_FORM_RESPONSE,
    'Received insurance form',
  ],
  [AMT_PATHWAY_TRANSITION_STATES.INELIGIBLE_INSURANCE, 'Ineligible insurance'],
  [AMT_PATHWAY_TRANSITION_STATES.PATIENT_WITH_WRONG_EMPLOYER, 'Wrong Employer'],
]);
