import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Eligibility } from '../modules/eligibility';
import { EligibilityError } from '../modules/eligibility/constants/strings/errors';

export const EligibilityPage = (): JSX.Element => (
  <Sentry.ErrorBoundary fallback={<Box>{EligibilityError}</Box>}>
    <Eligibility />
  </Sentry.ErrorBoundary>
);
