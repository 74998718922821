import {
  HHButton,
  HHLoadingButton,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { FormEvent } from 'react';
import { HCModal } from '../../components/hinge-connect-modal';
import { buttonLabels } from '../../constants/hinge-connect-constants.constant';
import { tagsTabContent } from '../constants/tags.constant';

const BoldedConfirmMessage = ({
  tagName,
}: {
  tagName: string;
}): JSX.Element => (
  <>
    {tagsTabContent.deleteTag.bodyPrefix}
    <strong>{tagName}</strong>
    {tagsTabContent.deleteTag.bodySuffix}
  </>
);

const DeleteTagForm = ({
  tagName,
  handleClose,
  onSubmit,
  loading,
}: {
  tagName: string;
  handleClose: () => void;
  onSubmit: () => void;
  loading: boolean;
}): JSX.Element => {
  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    onSubmit();
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit}>
      <HHStack width="40vw" maxWidth="500px">
        <HHTypography hhVariant="body" color="textSecondary">
          <BoldedConfirmMessage tagName={tagName} />
        </HHTypography>
        <HHStack
          direction="row"
          spacing={4}
          justifyContent="right"
          marginTop="16px"
        >
          <HHLoadingButton
            hhVariant="variant-bypass"
            variant="contained"
            type="submit"
            loading={loading}
            data-testid="delete-tag-button"
          >
            {buttonLabels.delete}
          </HHLoadingButton>
          <HHButton
            hhVariant="text"
            type="button"
            sx={{ margin: '1rem' }}
            onClick={handleClose}
          >
            {buttonLabels.cancel}
          </HHButton>
        </HHStack>
      </HHStack>
    </form>
  );
};

export const DeleteTagModal = ({
  tagId,
  tagName,
  isOpen,
  handleClose,
  deleteTag,
  deleteTagLoading,
}: {
  tagId: string;
  tagName: string;
  isOpen: boolean;
  handleClose: () => void;
  deleteTag: ({ variables }: { variables: { id: string } }) => void;
  deleteTagLoading: boolean;
}): JSX.Element => {
  const onSubmit = (): void => {
    deleteTag({ variables: { id: tagId } });
  };

  return (
    <HCModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={tagsTabContent.deleteTag.title}
      component={
        <DeleteTagForm
          {...{ tagName, handleClose, onSubmit, loading: deleteTagLoading }}
        />
      }
    />
  );
};
