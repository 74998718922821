import { HHBox } from '@hinge-health/react-component-library';
import { Error, Event, WarningAmber } from '@mui/icons-material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs';
import { AMT_MENU_COLORS, AMT_THEME_COLORS } from '../constants/colors';
import { TIME_STRINGS } from '../constants/strings/application-management-tool';
import { DueStates } from '../custom-types';
import {
  FORMAT_DAY_AGO,
  getFormattedHourTime,
  isFormattedDayAgo,
  isFormattedTomorrow,
  reformatTime,
} from '../utils/custom-time-format';

export interface ApplicationManagementToolCardDateProps {
  dueDate: string | dayjs.Dayjs;
  dueState?: DueStates;
  dueString?: string;
  textMinWidth?: string;
}

const dateStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const useStyles = makeStyles(() =>
  createStyles({
    overDue: {
      color: AMT_THEME_COLORS.ERROR,
      ...dateStyles,
    },
    dueSoon: {
      color: AMT_THEME_COLORS.WARNING,
      ...dateStyles,
    },
    default: {
      '& svg': {
        color: AMT_MENU_COLORS.MENU_LABEL,
      },
      ...dateStyles,
    },
    dateIcon: {
      marginLeft: 4,
    },
    dateIconBox: {
      display: 'flex',
      minWidth: 30,
    },
    dueText: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);

export const DEFAULT_DATE_TEST_ID = 'default-date';
export const DUE_SOON_DATE_TEST_ID = 'due-soon-date';
export const OVERDUE_DATE_TEST_ID = 'overdue-date';

export const ApplicationManagementToolCardDate = (
  props: ApplicationManagementToolCardDateProps,
): JSX.Element => {
  const { dueState, dueDate, dueString, textMinWidth = 0 } = props;
  const classes = useStyles();

  let dateString = dueString || dayjs().to(dueDate);

  // Overrides for specific date formats
  const isUpcomingTime = ['4', '3', '2', '1'].some(timeInHours => {
    if (dateString === getFormattedHourTime(timeInHours)) {
      dateString = reformatTime(timeInHours);
      return true;
    }
  });

  if (!isUpcomingTime) {
    if (isFormattedDayAgo(dateString)) {
      dateString = FORMAT_DAY_AGO;
    } else if (isFormattedTomorrow(dateString)) {
      dateString = TIME_STRINGS.TOMORROW;
    }
  }

  if (dueState === DueStates.DueSoon) {
    return (
      <HHBox className={classes.dueSoon} data-testid={DUE_SOON_DATE_TEST_ID}>
        <HHBox className={classes.dueText} style={{ minWidth: textMinWidth }}>
          {dateString}
        </HHBox>
        <HHBox className={classes.dateIconBox}>
          <WarningAmber className={classes.dateIcon} />
        </HHBox>
      </HHBox>
    );
  }

  if (dueState === DueStates.Overdue) {
    return (
      <HHBox className={classes.overDue} data-testid={OVERDUE_DATE_TEST_ID}>
        <HHBox className={classes.dueText} style={{ minWidth: textMinWidth }}>
          {dateString}
        </HHBox>
        <HHBox className={classes.dateIconBox}>
          <Error className={classes.dateIcon} />
        </HHBox>
      </HHBox>
    );
  }

  return (
    <HHBox className={classes.default} data-testid={DEFAULT_DATE_TEST_ID}>
      <HHBox className={classes.dueText} style={{ minWidth: textMinWidth }}>
        {dateString}
      </HHBox>
      <HHBox className={classes.dateIconBox}>
        <Event className={classes.dateIcon} />
      </HHBox>
    </HHBox>
  );
};
