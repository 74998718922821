import {
  HHBox,
  HHLoadingButton,
  HHSnackbarAlert,
  HHStack,
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { HCModal } from '../../../components/hinge-connect-modal';
import {
  userTagsButtonLabels,
  userTagsInputLabels,
  userTagTitles,
} from '../../constants/user-tags.constant';
import {
  UseListUserTagsHook,
  UseListUserTagsHookProps,
} from '../../hooks/list-user-tags-hook';
import { CreateUserTagForm } from '../create/create-user-tag-form';
import { ListUserTagsGrid } from './list-user-tags-grid';

export const ListUserTagsView = (props: {
  listUserTagsQuery: (
    hookProps: UseListUserTagsHookProps,
  ) => UseListUserTagsHook;
}): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>(
    localStorage.getItem('userId') || '',
  );
  const [searchUserIdError, setSearchUserIdError] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [userId, setUserId] = useState('');
  const { data, loading, error, refetch } = props.listUserTagsQuery({
    userId: localStorage.getItem('userId') || userId || '',
  });
  const useStyles = makeStyles(() => ({
    searchUserId: {
      width: '18rem',
    },
  }));

  const classes = useStyles();

  const handleOpen = (): void => setOpen(true);
  const handleSuccess = (userId: string): void => {
    setOpen(false);
    setUserId(userId);
    setSearchValue(userId);
    localStorage.setItem('userId', userId);
    setSnackbarOpen(true);
    refetch();
  };

  function search(): void {
    setUserId(searchValue);
    localStorage.setItem('userId', searchValue);
    refetch();
  }

  useEffect(() => {
    if (!searchValue) {
      setSearchUserIdError(false);
    } else {
      setSearchUserIdError(
        !/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
          searchValue,
        ),
      );
    }
  }, [searchValue]);

  return (
    <>
      <HHSnackbarAlert
        hhVariant="standard"
        horizontalAlignment="center"
        verticalAlignment="top"
        severity="success"
        message={'Successfully created user tag'}
        open={snackbarOpen}
        autoHideTime={3000}
        onClose={(): void => setSnackbarOpen(false)}
      />
      <HHBox paddingY="1rem">
        <HHTypography hhVariant="h2">{'User Tags'}</HHTypography>
        <HHStack direction="row" justifyContent="right" spacing={'1rem'}>
          <div className={classes.searchUserId}>
            <HHTextField
              data-testid="user-tags-user-id"
              hhVariant="variant-bypass"
              label={userTagsInputLabels.searchUserId}
              error={searchUserIdError}
              placeholder={''}
              fullWidth={true}
              value={searchValue}
              onChange={(event): void => setSearchValue(event.target.value)}
            />
          </div>
          <HHLoadingButton
            data-testid="list-user-tags-search-button"
            hhVariant="variant-bypass"
            variant="contained"
            sx={{ width: 'fit-content' }}
            type="submit"
            onClick={search}
            loading={loading}
            disabled={loading || searchUserIdError}
          >
            {userTagsButtonLabels.search}
          </HHLoadingButton>
        </HHStack>
        <ListUserTagsGrid
          userTags={data?.listUserTags.userTags || []}
          loading={loading}
          error={error}
        />
      </HHBox>
      <Fab
        color="primary"
        size="large"
        aria-label="add"
        onClick={handleOpen}
        sx={{
          position: 'absolute',
          bottom: 32,
          right: 32,
        }}
      >
        <Add />
      </Fab>
      <HCModal
        isOpen={open}
        handleClose={(): void => setOpen(false)}
        title={userTagTitles.createUserTag}
        component={
          <CreateUserTagForm
            handleSuccess={handleSuccess}
            onCancel={(): void => setOpen(false)}
            providedUserId={searchValue}
          />
        }
      ></HCModal>
    </>
  );
};
