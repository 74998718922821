import {
  HHButton,
  HHCard,
  HHCardContent,
  HHCardHeader,
  HHIconButton,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { East } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { MouseEvent, PropsWithChildren } from 'react';
import {
  NonReleasableHoldReasons,
  ReleasableHoldReasons,
} from './bill-holds-types';

export interface HoldsWidgetProps {
  goToBillsWithHolds: (
    e: MouseEvent<HTMLButtonElement>,
    holdReason?: string,
  ) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const HoldItemSkeleton = ({ children }: PropsWithChildren<{}>): JSX.Element => (
  <HHStack
    sx={(theme): SystemStyleObject<Theme> => ({
      backgroundColor: theme.palette.background.default,
      borderRadius: 2,
    })}
    direction="row"
    spacing={1}
    padding={1}
    paddingLeft={2}
    justifyContent="space-between"
    alignItems="center"
  >
    {children}
  </HHStack>
);

export const HoldsWidget = ({
  goToBillsWithHolds,
}: HoldsWidgetProps): JSX.Element => (
  <HHCard
    sx={{
      border: '1px solid lightgray',
      boxShadow: 'none',
      flexGrow: 1,
    }}
  >
    <HHCardHeader
      title="Bills with active holds"
      titleTypographyProps={{ variant: 'body1', paddingRight: 8 }}
      action={
        <HHButton
          onClick={(e): void => goToBillsWithHolds(e)}
          hhVariant="outlined"
        >
          View all holds
        </HHButton>
      }
    />
    <HHCardContent>
      <HHStack spacing={1}>
        <HoldItemSkeleton>
          <HHTypography hhVariant="body">Carrier change</HHTypography>
          <HHIconButton
            aria-label="go to carrier change bills"
            hhVariant="variant-bypass"
            onClick={(e): void =>
              goToBillsWithHolds(e, ReleasableHoldReasons.CARRIER_CHANGE)
            }
          >
            <East color="primary" />
          </HHIconButton>
        </HoldItemSkeleton>
        <HoldItemSkeleton>
          <HHTypography hhVariant="body">Loss of coverage</HHTypography>
          <HHIconButton
            aria-label="go to loss of coverage bills"
            hhVariant="variant-bypass"
            onClick={(e): void =>
              goToBillsWithHolds(e, NonReleasableHoldReasons.LOSS_OF_COVERAGE)
            }
          >
            <East color="primary" />
          </HHIconButton>
        </HoldItemSkeleton>
        <HoldItemSkeleton>
          <HHTypography hhVariant="body">Client change</HHTypography>
          <HHIconButton
            aria-label="go to client change bills"
            hhVariant="variant-bypass"
            sx={{ color: 'green' }}
            onClick={(e): void =>
              goToBillsWithHolds(e, ReleasableHoldReasons.CLIENT_CHANGE)
            }
          >
            <East color="primary" />
          </HHIconButton>
        </HoldItemSkeleton>
        <HoldItemSkeleton>
          <HHTypography hhVariant="body">Manual hold</HHTypography>
          <HHIconButton
            aria-label="go to manual hold bills"
            hhVariant="variant-bypass"
            onClick={(e): void =>
              goToBillsWithHolds(e, ReleasableHoldReasons.MANUAL_HOLD)
            }
          >
            <East color="primary" />
          </HHIconButton>
        </HoldItemSkeleton>
      </HHStack>
    </HHCardContent>
  </HHCard>
);
