import {
  HHBox,
  HHLoadingButton,
  HHStack,
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

import {
  ruleAssessmentsButtonLabels,
  ruleAssessmentsInputLabels,
  ruleAssessmentsTabContent,
} from '../../constants/rule-assessments.constant';
import {
  UseListRuleAssessmentsHook,
  UseListRuleAssessmentsHookProps,
} from '../../hooks/list-rule-assessments-hook';
import { ListRuleAssessmentsGrid } from './list-rule-assessments-grid';

export const ListRuleAssessmentsView = (props: {
  listRuleAssessmentsQuery: (
    hookProps: UseListRuleAssessmentsHookProps,
  ) => UseListRuleAssessmentsHook;
}): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [resourceId, setResourceId] = useState('');
  const [searchedOnce, setSearchedOnce] = useState(false);
  const { data, loading, error } = props.listRuleAssessmentsQuery({
    resourceId,
  });
  const useStyles = makeStyles(() => ({
    searchRuleAssessments: {
      width: '18rem',
    },
  }));
  const classes = useStyles();

  function search(): void {
    setResourceId(searchValue);
    setSearchedOnce(true);
  }

  return (
    <>
      <HHBox paddingY="1rem">
        <HHStack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          paddingBottom="1rem"
        >
          <HHTypography hhVariant="h2">
            {ruleAssessmentsTabContent.listViewTitle}
          </HHTypography>
          <HHStack direction="row" spacing="1rem">
            <div className={classes.searchRuleAssessments}>
              <HHTextField
                hhVariant="variant-bypass"
                label={ruleAssessmentsInputLabels.resourceId}
                placeholder=""
                fullWidth={true}
                onKeyDown={(event): unknown =>
                  event.key === 'Enter' && search()
                }
                onChange={(event): unknown =>
                  setSearchValue(event.target.value)
                }
              />
            </div>
            <HHLoadingButton
              hhVariant="variant-bypass"
              variant="contained"
              sx={{ width: 'fit-content' }}
              type="submit"
              onClick={search}
              loading={loading}
              disabled={loading}
            >
              {ruleAssessmentsButtonLabels.search}
            </HHLoadingButton>
          </HHStack>
        </HHStack>
        <ListRuleAssessmentsGrid
          searchActive={searchedOnce && searchValue.length > 0}
          ruleAssessments={data?.listRuleAssessments.ruleAssessments || []}
          loading={loading}
          error={error}
        />
      </HHBox>
    </>
  );
};
