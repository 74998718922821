import { HHGrid } from '@hinge-health/react-component-library';
import { useUnassignedWorkflowContext } from '../contexts/unassigned-workflows.context';
import {
  ApplicationManagementToolGroupType,
  PathwayTransitionDecoratedWorkflow,
} from '../custom-types';
import { AmtInboxFilter } from './amt-inbox-filter/amt-inbox-filter';
import { ApplicationManagementToolInboxCard } from './application-management-tool-inbox-card';
import { ApplicationManagementToolInboxHeader } from './application-management-tool-inbox-header';

export interface ApplicationManagementToolTypeGroupProps {
  selectedTab: number;
  groupType: ApplicationManagementToolGroupType;
}

export const ApplicationManagementToolInbox = (
  props: ApplicationManagementToolTypeGroupProps,
): JSX.Element => {
  const { groupType } = props;
  const { workflows } = useUnassignedWorkflowContext();

  const workflowList = workflows as PathwayTransitionDecoratedWorkflow[];

  return (
    <>
      <HHGrid container>
        <AmtInboxFilter />
      </HHGrid>
      <ApplicationManagementToolInboxHeader />
      {workflowList?.map((workflow, index) => (
        <ApplicationManagementToolInboxCard
          workflow={workflow}
          rowNumber={index + 1}
          key={groupType + index}
        />
      ))}
    </>
  );
};
