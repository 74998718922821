import {
  HHDialog,
  HHDialogActions,
  HHDialogContent,
  HHDialogProps,
  HHDialogTitle,
  HHIconButton,
  HHStack,
} from '@hinge-health/react-component-library';
import Close from '@mui/icons-material/Close';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface DynamicDialogContextType {
  setDialogContent: (data: {
    content?: JSX.Element;
    title?: JSX.Element | null;
    footer?: JSX.Element | null;
    width?: HHDialogProps['maxWidth'];
    fullWidth?: HHDialogProps['fullWidth'];
  }) => void;
  closeDialog: () => void;
}

const initialContext: DynamicDialogContextType = {
  setDialogContent: () => null,
  closeDialog: () => null,
};

export const DynamicDialogContext =
  createContext<DynamicDialogContextType>(initialContext);

export const DynamicDialogProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [titleContent, setTitleContent] = useState<JSX.Element | null>(
    <div></div>,
  );
  const [footerContent, setFooterContent] = useState<JSX.Element | null>(null);
  const [maxWidth, setMaxWidth] = useState<HHDialogProps['maxWidth']>('sm');
  const [fullWidth, setFullWidth] = useState<HHDialogProps['fullWidth']>(false);

  const setDialogContent = useCallback<
    DynamicDialogContextType['setDialogContent']
  >(
    (data): void => {
      if (data.content) {
        setContent(data.content);
      }
      if (data.title !== undefined) {
        setTitleContent(data.title);
      }
      if (data.footer !== undefined) {
        setFooterContent(data.footer);
      }
      if (data.width) {
        setMaxWidth(data.width);
      }
      if (typeof data.fullWidth === 'boolean') {
        setFullWidth(data.fullWidth);
      }
      setIsOpen(true);
    },
    [setContent, setTitleContent, setFooterContent, setIsOpen, setFullWidth],
  );

  const closeDialog = useCallback((): void => {
    setIsOpen(false);
    // give some time for transition to complete
    setTimeout(() => {
      setContent(null);
      setTitleContent(<div></div>);
      setFooterContent(null);
      setMaxWidth('sm');
      setFullWidth(false);
    }, 100);
  }, []);

  const value = useMemo(
    () => ({ setDialogContent, closeDialog }),
    [setDialogContent, closeDialog],
  );

  return (
    <DynamicDialogContext.Provider value={value}>
      {children}
      <HHDialog
        open={isOpen}
        onClose={closeDialog}
        closeAfterTransition
        maxWidth={maxWidth}
        fullWidth={fullWidth}
      >
        <HHDialogTitle>
          <HHStack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {titleContent}
            <HHIconButton
              color="primary"
              onClick={closeDialog}
              aria-label="close"
              hhVariant="medium"
            >
              <Close />
            </HHIconButton>
          </HHStack>
        </HHDialogTitle>
        <HHDialogContent>{content}</HHDialogContent>
        <HHDialogActions>{footerContent}</HHDialogActions>
      </HHDialog>
    </DynamicDialogContext.Provider>
  );
};

export const useDynamicDialogContext = (): DynamicDialogContextType =>
  useContext(DynamicDialogContext);
