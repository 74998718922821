import { HHSelect } from '@hinge-health/react-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { ActiveStatus } from '../../../../utils/contract-utils';
import {
  DiscountTypeSelectOptions,
  IDiscount,
} from '../../../../utils/discount-utils';

export interface DiscountTypeSelectProps {
  discountToEdit: IDiscount | null;
}

export const DiscountTypeSelect = ({
  discountToEdit,
}: DiscountTypeSelectProps): JSX.Element => {
  const { control, getValues, resetField } = useFormContext();

  const { discountType } = getValues();

  return (
    <Controller
      control={control}
      name="discountType"
      render={({ field: { onChange } }): JSX.Element => (
        <HHSelect
          width={'100%'}
          label="Type of Discount"
          id="discount-type-select"
          disabled={
            discountToEdit
              ? discountToEdit.status !== ActiveStatus.Future
              : false
          }
          initialSelectValue={discountType}
          selectOptions={DiscountTypeSelectOptions}
          sx={{ textTransform: 'capitalize' }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .MuiMenuItem-root': {
                  textTransform: 'capitalize',
                },
              },
            },
          }}
          onChange={(e): void => {
            // resetting values when discount type changes
            onChange(e);
            resetField('startDate');
            resetField('endDate');
            resetField('program', { defaultValue: '' });
            resetField('packageId', { defaultValue: '' });
            resetField('discount', { defaultValue: '' });
            resetField('suppressEmptyCharges', { defaultValue: true });
          }}
        />
      )}
    />
  );
};
