import {
  HHButton,
  HHCircularProgress,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import FileConfigurationForm from '../components/file-configuration-form';
import { FileConfigurationFormActions } from '../constants/file-configuration-constants';
import { useGetFileConfigurationQuery } from '../types';

const EditFileConfigurationPage = (): JSX.Element => {
  const params = useParams();
  const configurationId = Number(params.id);
  const { data, loading, error, refetch } = useGetFileConfigurationQuery({
    variables: { id: configurationId },
  });

  const handleTryAgain = (e: FormEvent): void => {
    e.preventDefault();
    refetch({ id: configurationId });
  };

  return (
    <HHStack direction="column">
      {loading && !error && (
        <HHStack direction={'column'} spacing={'20px'} alignItems={'center'}>
          <HHTypography hhVariant={'h6'}>
            Loading file configuration
          </HHTypography>
          <HHCircularProgress />
        </HHStack>
      )}
      {error && !loading && (
        <HHStack direction={'column'} spacing={'20px'} alignItems={'center'}>
          <HHTypography hhVariant={'h6'}>
            We ran into an issue loading the file configuration
          </HHTypography>
          <HHButton
            hhVariant={'contained'}
            fullWidth={false}
            size="large"
            onClick={handleTryAgain}
          >
            Try Again
          </HHButton>
        </HHStack>
      )}
      {data?.getFileConfiguration && (
        <FileConfigurationForm
          pageTitle="Edit File Configuration"
          formData={data?.getFileConfiguration}
          action={FileConfigurationFormActions.UPDATE}
          fileConfigurationId={Number(params.id)}
        />
      )}
    </HHStack>
  );
};
export default EditFileConfigurationPage;
