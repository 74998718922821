import { ApolloError } from '@apollo/client';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { UUIDv4 } from 'uuid-v4-validator';
import {
  AMT_PATHWAY_TRANSITION_STATES,
  ApplicationManagementToolWorkflowTypes,
} from '../custom-types';
import {
  GetWorkflowsByFilterQueryVariables,
  useGetWorkflowsByFilterLazyQuery,
  WorkflowPayload,
} from '../types';
import type { FilterStates, Order } from './unassigned-workflows.context';

const PAGE_SIZE = 50;
export interface WorkflowContextType {
  adminUuid: string;
  children?: React.ReactNode;
  loading?: boolean;
  workflows?: WorkflowPayload[];
  error?: ApolloError;
  fetchMore?: {
    hasMore: boolean;
    fetchMoreWorkflows: () => Promise<void>;
  };
  filterControls?: FilterStates;
}

const initialContext: WorkflowContextType = {
  adminUuid: '',
  loading: false,
  workflows: [],
  error: undefined,
  fetchMore: {
    hasMore: false,
    fetchMoreWorkflows: async () => undefined,
  },
};

const excludeStates = [
  'expired',
  'provisioned',
  'rejected',
  'canceled',
  'check-for-duplicate-account',
  'wrong-employer-check',
];

export const WorkflowContext =
  createContext<WorkflowContextType>(initialContext);

type getWorkflowFilterQueryProps = {
  pageNumber: number;
  pageSize?: number;
  adminUuid?: string;
  excludeStates?: string[];
  orderBy: keyof WorkflowPayload;
  filterState?: AMT_PATHWAY_TRANSITION_STATES;
  patientUuid?: string;
  order: Order;
};

const getWorkflowFilterQueryDefaultProps: getWorkflowFilterQueryProps = {
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  adminUuid: '',
  excludeStates,
  orderBy: 'updatedAt',
  order: 'ASC',
};

const getWorkflowFilterQuery = ({
  adminUuid,
  ...props
} = getWorkflowFilterQueryDefaultProps): GetWorkflowsByFilterQueryVariables => ({
  workflowFiltersInput: {
    ...(adminUuid && { adminUuids: [adminUuid] }),
    workflowTypes: [ApplicationManagementToolWorkflowTypes.PathwayTransition],
    pageSize: PAGE_SIZE,
    ...props,
  },
});

export const WorkflowProvider = ({
  adminUuid,
  children,
}: WorkflowContextType): JSX.Element => {
  const [pageNumber, setPageNumber] = useState(1);
  const [orderBy, setOrderBy] = useState<keyof WorkflowPayload>('updatedAt');
  const [filterState, setFilterState] = useState<
    AMT_PATHWAY_TRANSITION_STATES | undefined
  >();
  const [patientUuid, setPatientUuid] = useState<string | undefined>();
  const [patientId, setPatientId] = useState<string | undefined>();
  const [order, setOrder] = useState<Order>('ASC');

  const [getWorkflowsByFilter, { data, loading, error, fetchMore }] =
    useGetWorkflowsByFilterLazyQuery();

  const filterData = (workflow: WorkflowPayload): boolean => {
    if (!workflow.adminUuids?.includes(adminUuid)) {
      return false;
    }
    if (!!patientId) {
      return (
        typeof workflow.patient?.id === 'string' &&
        workflow.patient.id.startsWith(patientId)
      );
    }
    return true;
  };

  useEffect(() => {
    if (!UUIDv4.validate(adminUuid)) {
      return;
    }
    getWorkflowsByFilter({
      variables: getWorkflowFilterQuery({
        adminUuid,
        pageNumber,
        excludeStates,
        orderBy,
        filterState,
        patientUuid,
        order,
      }),
      errorPolicy: 'all',
      returnPartialData: true,
      fetchPolicy: 'no-cache',
    });
  }, [
    getWorkflowsByFilter,
    adminUuid,
    pageNumber,
    orderBy,
    order,
    filterState,
    patientUuid,
  ]);

  return (
    <WorkflowContext.Provider
      value={{
        adminUuid,
        error,
        loading,
        workflows: (data?.getWorkflowsByFilter?.workflows.filter(filterData) ||
          []) as WorkflowPayload[],
        fetchMore: {
          hasMore: false,
          fetchMoreWorkflows: async (): Promise<void> => {
            const newPageNumber = pageNumber + 1;
            await fetchMore({
              variables: {
                ...getWorkflowFilterQuery({
                  pageNumber: newPageNumber,
                  excludeStates,
                  filterState,
                  orderBy,
                  patientUuid,
                  order,
                }),
              },
            });
            setPageNumber(newPageNumber);
          },
        },
        filterControls: {
          filterState: [filterState, setFilterState],
          orderBy: [orderBy, setOrderBy],
          patientUuid: [patientUuid, setPatientUuid],
          order: [order, setOrder],
          patientId: [patientId, setPatientId],
        },
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};

export const useWorkflowContext = (): WorkflowContextType =>
  useContext(WorkflowContext);
