import { HHStack } from '@hinge-health/react-component-library';
import { Route, Routes } from 'react-router-dom';
import EligibilityHeader from './components/eligibility-header';
import EligibilityHomePage from './pages/eligibility-home';

export const Eligibility = (): JSX.Element => (
  <HHStack
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: '#fff',
      overflow: 'hidden',
    }}
  >
    <EligibilityHeader />
    <Routes>
      <Route index element={<EligibilityHomePage />} />
    </Routes>
  </HHStack>
);
