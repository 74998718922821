import { HHAvatar } from '@hinge-health/react-component-library';
import { Admin } from '../../types';

interface AdminAvatarProps {
  adminProfile?: Admin;
}

export const AdminAvatar = ({
  adminProfile,
}: AdminAvatarProps): JSX.Element | null => {
  if (!adminProfile) return null;
  const { firstName, lastName } = adminProfile;
  return (
    <HHAvatar hhVariant="small" data-testid="member-details-assignee">
      {firstName?.[0]?.toUpperCase()}
      {lastName?.[0]?.toUpperCase()}
    </HHAvatar>
  );
};
