import { HHBox, HHTypography } from '@hinge-health/react-component-library';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAtom } from 'jotai';
import { MEMBER_DETAILS_COLORS, SIDEBAR_COLOR } from '../../constants/colors';
import { MEMBER_VIEW_HEADER_TITLE } from '../../constants/strings/application-management-tool';
import { AMT_THEME } from '../../constants/theme';
import { useGetWorkflowContext } from '../../contexts/get-workflow.context';
import { ApplicationManagementToolWorkflowTypes } from '../../custom-types';
import { selectedWorkflowType } from '../../jotai-atoms/atoms';
import { WorkflowPayload } from '../../types';
import { workflowIcons } from '../../utils/icons';

export type AmtMemberHeaderProps = {
  selectedWorkflow: WorkflowPayload;
};

const useStyles = makeStyles(() =>
  createStyles({
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    headerBarContainer: {
      position: 'fixed',
      paddingTop: 10,
      paddingLeft: 73,
      paddingRight: 72,
      paddingBottom: 8,
      height: 24,
      width: 175,
      backgroundColor: SIDEBAR_COLOR,
      color: MEMBER_DETAILS_COLORS.WHITE,
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 56,
      width: 190,
    },
    subTitleContainer: {
      paddingLeft: 20,
      marginTop: 5,
    },
    icon: {
      marginLeft: 18,
      marginRight: 10,
      color: MEMBER_DETAILS_COLORS.ICON_GREY,
    },
    dateContainer: {
      paddingTop: 55,
      paddingLeft: 24,
    },
  }),
);

const typography = {
  fontFamily: AMT_THEME.FONT_FAMILY,
  color: MEMBER_DETAILS_COLORS.BLACK,
};

const titleTypography = {
  ...typography,
  fontSize: AMT_THEME.FONT_SIZES.LARGE,
};

const subTitleTypography = {
  ...typography,
  fontSize: AMT_THEME.FONT_SIZES.SMALL,
};

export const AmtMemberHeader = (): JSX.Element => {
  const classes = useStyles();
  const [workflowType] = useAtom(selectedWorkflowType);
  const Icon =
    workflowIcons[workflowType as ApplicationManagementToolWorkflowTypes];

  const { workflow } = useGetWorkflowContext();
  return (
    <HHBox className={classes.headerContainer}>
      <HHBox>
        <HHBox className={classes.headerBarContainer} />
        <HHBox className={classes.titleContainer}>
          <Icon className={classes.icon} />
          <HHTypography hhVariant={'variant-bypass'} sx={titleTypography}>
            {
              MEMBER_VIEW_HEADER_TITLE[
                workflowType as ApplicationManagementToolWorkflowTypes
              ]
            }
          </HHTypography>
        </HHBox>
        <HHBox className={classes.subTitleContainer}>
          <HHTypography hhVariant={'variant-bypass'} sx={subTitleTypography}>
            {workflow?.state}
          </HHTypography>
        </HHBox>
      </HHBox>
    </HHBox>
  );
};
