import { HHStack, HHTypography } from '@hinge-health/react-component-library';
import { PropsWithChildren } from 'react';

interface TextGroupingProps {
  label: string;
  text: string | JSX.Element;
  variations?: TextGroupingVariationsProps;
}

interface TextGroupingVariationsProps {
  flex?: number;
}

export const TextGrouping = ({
  label,
  text,
  variations,
}: TextGroupingProps): JSX.Element => (
  <TextGroupingChildren variations={variations} label={label}>
    {text}
  </TextGroupingChildren>
);

export const TextGroupingChildren = ({
  label,
  children,
  variations,
}: PropsWithChildren<{
  label: string;
  variations?: TextGroupingVariationsProps;
}>): JSX.Element => (
  <HHStack flex={variations?.flex ? variations.flex : 'none'}>
    <HHTypography hhVariant="muted" fontSize={12}>
      {label}
    </HHTypography>
    <HHTypography
      hhVariant="variant-bypass"
      variant="body2"
      color="success"
      fontSize={12}
    >
      {children}
    </HHTypography>
  </HHStack>
);
