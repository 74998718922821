import { useListTagsQuery } from '../types';

export type Milliseconds = number;

export const useListTagNames = (props: {
  cacheValidationDuration: Milliseconds;
}): string[] => {
  const { data, refetch } = useListTagsQuery();

  if (shouldRefetch(props.cacheValidationDuration)) {
    localStorage.setItem('tagNamesFetchTime', Date.now().toString());
    refetch();
  }

  if (!data) return [];

  const tags = data.listTags.tags.map(tag => tag.name);

  return tags;
};

function shouldRefetch(cacheValidationDuration: number): boolean {
  const lastFetchTime = localStorage.getItem('tagNamesFetchTime');

  if (!lastFetchTime) return true;

  const timeSinceLastFetch = Date.now() - parseInt(lastFetchTime);

  if (timeSinceLastFetch > cacheValidationDuration) return true;

  return false;
}
