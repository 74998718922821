import { HHStack, HHTypography } from '@hinge-health/react-component-library';
import { GridCellParams } from '@mui/x-data-grid';
import { InsurersType, ReleasableHoldReasons } from './bill-holds-types';

export interface InsurerCellProps extends GridCellParams {
  insurers: InsurersType[];
}
export const InsurerCell = ({
  insurers,
  ...params
}: InsurerCellProps): JSX.Element => {
  const { holdType, insurerId } = params.row.holdDetails as Record<
    string,
    string
  >;

  if (!params.value.id && !params.value.name) {
    return <HHTypography hhVariant="caption">Loading...</HHTypography>;
  }

  return (
    <HHStack>
      {params.value.id &&
      insurerId &&
      !params.value.loading &&
      insurers.length >= 1 &&
      holdType === ReleasableHoldReasons.CARRIER_CHANGE ? (
        <HHStack>
          <HHTypography
            hhVariant="variant-bypass"
            variant="caption"
            sx={{ textDecoration: 'line-through', color: 'gray' }}
          >
            {params.value.name}
          </HHTypography>
          <HHTypography hhVariant="caption">
            {
              insurers.filter(insurer => insurer.id === parseInt(insurerId))[0]
                .name
            }
          </HHTypography>
        </HHStack>
      ) : (
        <HHTypography hhVariant="caption">{params.value.name}</HHTypography>
      )}
    </HHStack>
  );
};
