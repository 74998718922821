import { Navigate, Route, Routes } from 'react-router-dom';
import { tabRoutes } from '../../constants/hinge-connect-constants.constant';
import { useListUserTagsHook } from '../hooks/list-user-tags-hook';
import { ListUserTagsView } from './list/list-user-tags-view';

export const UserTagsTab = (): JSX.Element => (
  <Routes>
    <Route
      path={`/${tabRoutes.userTags}/${'list'}`}
      element={<ListUserTagsView listUserTagsQuery={useListUserTagsHook} />}
    />
    <Route
      path="*"
      element={
        <Navigate
          to={`/${tabRoutes.baseRoute}/${tabRoutes.userTags}/${'list'}`}
        />
      }
    />
  </Routes>
);
