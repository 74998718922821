import { HHBox, HHTypography } from '@hinge-health/react-component-library';
import { titles } from '../constants/hinge-connect-constants.constant';
import { HingeConnectTabs } from './hinge-connect-tabs';

export const HingeConnectLayout = (): JSX.Element => (
  <HHBox bgcolor="#ffffff" padding="2rem" minHeight="100%">
    <HHTypography hhVariant="h1">{titles.pageTitle}</HHTypography>
    <HingeConnectTabs />
  </HHBox>
);
