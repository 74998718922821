/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  HHSelect,
  HHSelectChangeEvent,
  HHStack,
  HHTypography,
  TextArea,
} from '@hinge-health/react-component-library';
import { has } from 'lodash';
import { useEffect, useState } from 'react';
import { useGetColumnMappingsQuery } from '../types';
import LoadingInput from './loading-input';

interface ColumnMappingsSectionProps {
  onChange: (data: { [key: string]: unknown }) => void;
  formData: { [key: string]: unknown };
}

const ColumnMappingsSection = (
  props: ColumnMappingsSectionProps,
): JSX.Element => {
  const { data, loading, error } = useGetColumnMappingsQuery();

  // select options
  const DEFAULT_SELECTED_MAPPING = {
    value: '',
    displayName: 'Select a mapping',
  };
  const [columnMappingOptions, setColumnMappingOptions] = useState<
    { value: string; displayName: string }[]
  >([DEFAULT_SELECTED_MAPPING]);
  const [columnMappingValues, setColumnMappingValues] = useState<{
    [key: string]: unknown;
  }>({});
  const [selectedMapping, setSelectedMapping] = useState<string>('');

  const [columnMappingConfig, setColumnMappingConfig] = useState<string>(
    JSON.stringify(props.formData.columnMappings || {}, null, '\t'),
  );
  const [columnMappingConfigError, setColumnMappingConfigError] =
    useState(false);

  const buildConfig = (mapping: string): { [key: string]: unknown } => {
    const result: { [key: string]: unknown } = {};

    if (columnMappingValues[mapping]) {
      (
        columnMappingValues[mapping] as {
          [key: string]: any;
        }[]
      ).forEach((param: { [key: string]: unknown }) => {
        result[param.name as string] = param.default;
      });
    }

    return result;
  };

  const validateFields = (
    config: { [key: string]: unknown },
    mapping: string,
  ): void => {
    let requiredFieldsErrorState = false;

    if (columnMappingValues[mapping]) {
      (columnMappingValues[mapping] as { [key: string]: unknown }[]).forEach(
        (param: { [key: string]: unknown }) => {
          if (param.required && !has(config, param.name as string)) {
            requiredFieldsErrorState = true;
          }
        },
      );
    }

    setColumnMappingConfigError(requiredFieldsErrorState);
  };

  useEffect(() => {
    try {
      const config = JSON.parse(columnMappingConfig) || {};
      setColumnMappingConfigError(false);
      validateFields(config, selectedMapping);
      // set form data
      const formdata = {
        columnMappings: config,
      };
      props.onChange(formdata);
    } catch (error) {
      setColumnMappingConfigError(true);
    }
  }, [columnMappingConfig]);

  useEffect(() => {
    if (data) {
      setColumnMappingOptions([
        ...columnMappingOptions,
        ...data.getColumnMappings.options,
      ]);
      setColumnMappingValues(data.getColumnMappings.values);
    }
  }, [data]);

  const handleMappingChange = (e: HHSelectChangeEvent): void => {
    if (!e.target.value) {
      setColumnMappingConfig('{}');
    }

    const selectedMapping = e.target.value as string;
    setSelectedMapping(selectedMapping);
    setColumnMappingConfig(
      JSON.stringify(buildConfig(selectedMapping), null, '\t'),
    );
  };

  return (
    <HHStack direction="column" spacing={'20px'}>
      <HHTypography hhVariant={'h2'}>Column Mappings</HHTypography>
      <LoadingInput
        loading={loading}
        error={error}
        loadingMessage={'Loading Column Mappings'}
        errorLabel={'Column Mapping Loading Error'}
        errorContent={
          'Error loading column mappings. Please refresh and try again.'
        }
      >
        <HHStack direction="column" spacing={'20px'}>
          <HHSelect
            label="Select base configuration"
            initialSelectValue={selectedMapping}
            selectOptions={columnMappingOptions}
            onChange={handleMappingChange}
            inputProps={{ 'data-testid': 'mapping-select' }}
          />
          <TextArea
            label={'Column Configuration'}
            style={{ labelColor: 'black', helpTextColor: 'red' }}
            value={columnMappingConfig}
            onChange={(e): void => setColumnMappingConfig(e.target.value)}
            error={columnMappingConfigError}
          />
        </HHStack>
      </LoadingInput>
    </HHStack>
  );
};
export default ColumnMappingsSection;
