import { IDiscount } from '../../../utils/discount-utils';
import { SimplePackageType } from '../../contract/package/custom-package-types';
import { TextGrouping } from '../../utils/text-grouping';
import DiscountDetailBase, {
  DiscountDetailBaseProps,
} from './discount-detail-base';

export interface ProgramAccessProps extends DiscountDetailBaseProps {
  discountData: IDiscount;
  availablePackages: SimplePackageType[];
}

const ProgramAccessDetail = ({
  discountData,
  availablePackages,
  handleEditDiscountClick,
}: ProgramAccessProps): JSX.Element => (
  <DiscountDetailBase
    handleEditDiscountClick={handleEditDiscountClick}
    discountData={discountData}
    availablePackages={availablePackages}
  >
    <TextGrouping
      label="$0 charges included on invoices"
      text={discountData.suppressEmptyCharges ? 'No' : 'Yes'}
    />
  </DiscountDetailBase>
);

export default ProgramAccessDetail;
