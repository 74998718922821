import { CreateUserTagAction } from './create-user-tag-action';

export function selectAction(
  action: string,
  onChange: (data: object) => void,
  onIsValid: (isValid: boolean) => void,
): JSX.Element {
  const actionMap = new Map([
    [
      'CREATE_USER_TAG',
      {
        value: 'CREATE_USER_TAG',
        displayName: 'Create User Tag',
        description: 'Create a user tag based on the selected criteria',
        component: (
          <CreateUserTagAction onChange={onChange} onIsValid={onIsValid} />
        ),
      },
    ],
  ]);
  return actionMap.get(action)?.component ?? <></>;
}

export const actionSelectOptions = [
  {
    value: 'CREATE_USER_TAG',
    displayName: 'Create User Tag',
  },
];
