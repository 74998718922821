import { dataSources } from '../../constants/hinge-connect-constants.constant';

export const healthRecordsRoutes = {
  list: 'list',
};

export const healthRecordsFilterLabels = {
  source: 'Source',
  dateRange: 'Date Range',
  resourceType: 'Resource Type',
  limit: 'Results per Page',
  clear: 'Clear Filters',
  search: 'Search',
};

export const healthRecordsGridColumnHeaders = {
  createdAt: 'Created At',
  id: 'ID',
  source: 'Source',
  resourceType: 'Resource Type',
  origin: 'Origin',
  userId: 'User ID',
};

export const healthRecordsDetailLabels = {
  ...healthRecordsGridColumnHeaders,
  resourceId: 'Resource ID',
  data: 'Data',
  title: 'Health Record Details',
  na: 'N/A',
};

export const healthRecordsPagination = {
  next: 'Next Page',
  previous: 'Previous Page',
};

export const healthRecordsInfoMessages = {
  noHealthRecords: 'No Health Records',
  oops: 'Oops! Something Went Wrong',
  trySearchingAgain: 'Try searching again later.',
  trySource: 'Try selecting a source in the box above.',
  recordNotFound: 'Record not found',
};

export const healthRecordsDataSources = {
  ...dataSources,
  BCI: 'BCI',
  SUPPLEMENTAL: 'SUPPLEMENTAL',
  BCBSMA: 'BCBSMA',
  HCSC: 'HCSC',
  BCBSKC: 'BCBSKC',
  COLLECTIVE_HEALTH: 'COLLECTIVE_HEALTH',
};

export const healthRecordsSourceAutoCompleteOptions = Object.values(
  healthRecordsDataSources,
).map(item => ({
  label: item,
  value: item,
}));
