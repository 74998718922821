import { HHStack } from '@hinge-health/react-component-library';
import { SubscriptionType } from '../../types';
import BillsBySubscriptionId from '../bills/bills-subscription';
import EngagementsBySubscriptionId from '../engagement/subscription-engagements';

export interface SubscriptionDataProps {
  subscriptionData: SubscriptionType;
}

const SubscriptionData = ({
  subscriptionData,
}: SubscriptionDataProps): JSX.Element => (
  <HHStack
    spacing={6}
    direction="column"
    padding={3}
    bgcolor={(theme): string => theme.palette.background.default}
    sx={{ borderRadius: 2, marginTop: 1 }}
  >
    <BillsBySubscriptionId subscriptionData={subscriptionData} />
    <EngagementsBySubscriptionId subscriptionId={subscriptionData.id} />
  </HHStack>
);

export default SubscriptionData;
