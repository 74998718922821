import {
  HHAlert,
  HHBreadcrumbs,
  HHLink,
  HHStack,
  HHTabs,
  HHToggleButton,
  HHToggleButtonGroup,
  HHTypography,
} from '@hinge-health/react-component-library';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserEngagements from '../components/engagement/user-engagements';
import UserSubscriptions from '../components/subscriptions/user-subscriptions';
import UserSearch from '../components/user/user-search';
import LoadingComponent from '../components/utils/loading';
import { routes } from '../constants/strings/routes';
import { useBillingSetting } from '../context/billing.context';
import { DynamicDrawerProvider } from '../context/dynamic-drawer.context';
import { useGetUserUuidByIdQuery } from '../types';

const SuccessLoadContent = ({
  userId,
  userUuid,
}: {
  userId: number;
  userUuid: string;
}): JSX.Element => (
  <HHTabs
    layoutStyles={{
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      overflow: 'auto',
    }}
    variant="fullWidth"
    tabsData={[
      {
        tabContent: <UserSubscriptions userId={userId} />,
        tabLabel: 'Subscriptions',
        to: `${routes.billing.home}/${routes.billing.userPath}/${userId}/subscriptions`,
      },
      {
        tabContent: <UserEngagements userUuid={userUuid} />,
        tabLabel: 'Engagements',
        to: `${routes.billing.home}/${routes.billing.userPath}/${userId}/engagements`,
      },
    ]}
  />
);

const UserIdPageSkeleton = ({
  children, // eslint-disable-next-line @typescript-eslint/ban-types
}: PropsWithChildren<{}>): JSX.Element => {
  const { value, setBillingAppSetting } = useBillingSetting(
    'displayDateTimeLocal',
  );

  return (
    <DynamicDrawerProvider>
      <HHStack flex={1} spacing={4} direction="column" padding={6}>
        <HHBreadcrumbs sx={{ paddingBottom: 6 }} aria-label="breadcrumb">
          <HHLink color="inherit" href={routes.billing.home} underline="hover">
            Operations Hub
          </HHLink>
          <HHLink color="inherit" href={routes.billing.home} underline="hover">
            Billing
          </HHLink>
          <HHTypography color="text.primary" hhVariant="variant-bypass">
            User
          </HHTypography>
        </HHBreadcrumbs>
        <HHStack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <HHTypography hhVariant="section-title">
            User billing data
          </HHTypography>
          <HHToggleButtonGroup size="small">
            <HHToggleButton
              hhVariant="variant-bypass"
              selected={!value}
              value="UTC"
              onClick={(): void => {
                setBillingAppSetting(false);
              }}
              sx={{ borderRadius: '4px 0 0 4px' }}
            >
              UTC
            </HHToggleButton>
            <HHToggleButton
              hhVariant="variant-bypass"
              selected={value}
              value="Local"
              onClick={(): void => {
                setBillingAppSetting(true);
              }}
              sx={{ borderRadius: '0 4px 4px 0' }}
            >
              Local
            </HHToggleButton>
          </HHToggleButtonGroup>
        </HHStack>
        {children}
      </HHStack>
    </DynamicDrawerProvider>
  );
};

const UserByIdPage = (): JSX.Element => {
  const params = useParams<{ id: string }>();
  const userId = parseInt(params.id || '0', 10);
  const [userUuid, setUserUuid] = useState<string | null>(null);
  const {
    data: userData,
    loading,
    error,
  } = useGetUserUuidByIdQuery({
    variables: { id: userId },
  });

  useEffect(() => {
    if (userData && userData.getUserUuidById.uuid) {
      setUserUuid(userData.getUserUuidById.uuid);
    }
  }, [userData, setUserUuid]);

  if (loading) {
    return (
      <UserIdPageSkeleton>
        <LoadingComponent center />;
      </UserIdPageSkeleton>
    );
  }

  if ((!loading && !userUuid) || error) {
    return (
      <UserIdPageSkeleton>
        <HHAlert hhVariant="outlined" severity="error">
          User with id:{userId} not found in the system. Please try again.
        </HHAlert>
        <UserSearch />
      </UserIdPageSkeleton>
    );
  }

  return (
    <UserIdPageSkeleton>
      <SuccessLoadContent userId={userId} userUuid={userUuid as string} />
    </UserIdPageSkeleton>
  );
};

export default UserByIdPage;
