import {
  HHSelect,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { GetSplitTreatmentOnOrOff } from '../../../../../../components/splits';
import { migrateBillingModelsEnabled } from '../../../../constants/strings/split';
import { ActiveStatus } from '../../../../utils/contract-utils';
import {
  IDiscount,
  InvalidPackagesForDiscounts,
  InvalidProgramDiscounts,
  PackageSelectionOptions,
} from '../../../../utils/discount-utils';
import { SimplePackageType } from '../../../contract/package/custom-package-types';

export interface PackageSelectProps {
  discountToEdit: IDiscount | null;
  availablePackages: SimplePackageType[];
}

export const PackageSelect = ({
  discountToEdit,
  availablePackages,
}: PackageSelectProps): JSX.Element => {
  const { control, watch, trigger, resetField } = useFormContext();
  const watchedPackageId = watch('packageId');
  const watchedDiscountType = watch('discountType');

  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const adminId = hingeHealthAuthState?.accessToken?.claims.uid;

  const migrateBillingModelsSplitEnabled = GetSplitTreatmentOnOrOff(
    migrateBillingModelsEnabled,
    adminId,
  );

  const disablePackageSelect = migrateBillingModelsSplitEnabled
    ? discountToEdit && discountToEdit?.status !== ActiveStatus.Future
    : !Object.keys(InvalidProgramDiscounts).includes(watchedDiscountType) ||
      (discountToEdit && discountToEdit?.status !== ActiveStatus.Future);

  const validPackagesForDiscount = availablePackages.filter(
    ap =>
      !Object.values(InvalidPackagesForDiscounts).includes(
        ap.name as keyof typeof InvalidPackagesForDiscounts,
      ),
  );

  return (
    <Controller
      control={control}
      name="packageId"
      defaultValue={watchedPackageId}
      render={({ field: { onChange } }): JSX.Element => (
        <HHSelect
          width={'100%'}
          label="Package Discounted"
          id="package-select"
          data-testid="package-select"
          disabled={disablePackageSelect ?? false}
          initialSelectValue={watchedPackageId}
          selectOptions={PackageSelectionOptions(validPackagesForDiscount)}
          inputProps={{ 'aria-required': true }}
          fullWidth
          onChange={(e): void => {
            onChange(e);
            resetField('program', { defaultValue: '' });
            trigger('startDate');
            trigger('endDate'); //trigger validation on start and end date if program changes
          }}
        />
      )}
    />
  );
};
