import { HHStack, HHTypography } from '@hinge-health/react-component-library';
import { Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export interface VoidToggleProps {
  submissionCount: number;
  hideVoid: boolean;
}

export const VoidToggle = ({
  submissionCount,
  hideVoid,
}: VoidToggleProps): JSX.Element | null => {
  const { control, getValues } = useFormContext();
  const { enableInPersonVisit } = getValues();

  if (hideVoid) {
    return null;
  }
  return (
    <HHStack direction="column" data-testid="void" flex="auto">
      <HHTypography hhVariant="body1">Void ?</HHTypography>
      <Controller
        control={control}
        name="isVoid"
        render={({ field: { onChange, value } }): JSX.Element => (
          <Switch
            inputProps={{ role: 'switch' }}
            disabled={submissionCount >= 1 || enableInPersonVisit}
            checked={value}
            onChange={(): void => {
              onChange(!value);
            }}
            color="error"
          />
        )}
      />
    </HHStack>
  );
};
