import { routes } from '../constants';
import {
  applicationManagementToolRoutes,
  billingToolRoutes,
  eligibilityRoutes,
  hingeConnectRoutes,
  memberAuthToolRoutes,
  memberDataRoutes,
  memberDataServicesRoutes,
  otherRoutes,
  RouteDetails,
  tiRoutes,
} from '../routes';
import { OktaRole } from './okta-roles';

interface RoleDetails {
  showMenu: boolean;
  routes: RouteDetails[];
  postLoginRoute: string;
}

export const roleProperties: Partial<Record<string, RoleDetails>> = {
  [OktaRole.TARGETED_INTERVENTIONS]: {
    routes: tiRoutes,
    showMenu: true,
    postLoginRoute: routes.targeted_interventions,
  },
  [OktaRole.DELIVERY_OPS]: {
    routes: [...billingToolRoutes, ...applicationManagementToolRoutes],
    showMenu: true,
    postLoginRoute: routes.billing_tool,
  },
  [OktaRole.REVENUE_CYCLE_MANAGER]: {
    routes: [...billingToolRoutes, ...memberAuthToolRoutes],
    showMenu: true,
    postLoginRoute: routes.billing_tool,
  },
  [OktaRole.PHI_MONITORING]: {
    routes: [
      ...billingToolRoutes,
      ...hingeConnectRoutes,
      ...eligibilityRoutes,
      ...memberDataRoutes,
      ...memberDataServicesRoutes,
      ...memberAuthToolRoutes,
    ],
    showMenu: true,
    postLoginRoute: routes.billing_tool,
  },
  [OktaRole.PHI_PRODUCT_MANAGER]: {
    routes: [...billingToolRoutes, ...hingeConnectRoutes],
    showMenu: true,
    postLoginRoute: routes.billing_tool,
  },
  [OktaRole.ACCOUNTING]: {
    routes: [...billingToolRoutes],
    showMenu: true,
    postLoginRoute: routes.billing_tool,
  },
  [OktaRole.HEALTH_ECONOMIST]: {
    routes: [...hingeConnectRoutes],
    showMenu: true,
    postLoginRoute: routes.hinge_connect_menu,
  },
  [OktaRole.ELIGIBILITY_SELF_SERVICE]: {
    routes: [...eligibilityRoutes],
    showMenu: true,
    postLoginRoute: routes.eligibility,
  },
  [OktaRole.TECHNICAL_SUPPORT]: {
    routes: [...billingToolRoutes, ...memberAuthToolRoutes],
    showMenu: true,
    postLoginRoute: routes.billing_tool,
  },
  [OktaRole.MEMBER_SUPPORT]: {
    routes: [...billingToolRoutes, ...memberAuthToolRoutes],
    showMenu: true,
    postLoginRoute: routes.billing_tool,
  },
};

export function getRoleProperties(roles: string[] | undefined): RoleDetails {
  const roleResult: RoleDetails = {
    routes: otherRoutes,
    showMenu: false,
    postLoginRoute: routes.other,
  };
  Object.keys(roleProperties).forEach(roleString => {
    const role = roleString as OktaRole;
    const roleDetails = roleProperties[role];
    if (roles?.includes(role) && roleDetails) {
      roleResult.routes = [
        ...new Set([...roleDetails.routes, ...roleResult.routes]),
      ];
      if (roleDetails.showMenu) {
        roleResult.showMenu = true;
      }
      roleResult.postLoginRoute = roleDetails.postLoginRoute;
    }
  });
  return roleResult;
}
