import { HHStack, HHTypography } from '@hinge-health/react-component-library';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { ContractPackagesType } from '../../types';

import { AllContractsRowLabels } from '../../constants/strings/billing-label-enums';
import { formatCurrency } from '../../utils/currency-helpers';

export interface ContractGridDetails {
  contractType: string;
  contractPackages: { key: string; contractPackage: ContractPackagesType }[];
  templateName?: string | null;
  chronicPrice?: number | null;
  acutePrice?: number | null;
  inPersonVisitPrice?: number | null;
  currency: string;
}

export interface LegacyContractGridDetails {
  templateName?: string | null;
  chronicPrice?: number | null;
  acutePrice?: number | null;
  inPersonVisitPrice?: number | null;
  currency: string;
}

export interface ProductPackageContractGridDetails {
  contractPackages: { key: string; contractPackage: ContractPackagesType }[];
  currency: string;
}

const DisplayLegacyContractDetails = ({
  inPersonVisitPrice,
  chronicPrice,
  acutePrice,
  currency,
}: LegacyContractGridDetails): JSX.Element => (
  <HHStack direction="column">
    {chronicPrice ? (
      <HHTypography hhVariant="caption">
        Chronic: {formatCurrency(chronicPrice, currency)}
      </HHTypography>
    ) : null}
    {acutePrice ? (
      <HHTypography hhVariant="caption">
        Acute: {formatCurrency(acutePrice, currency)}
      </HHTypography>
    ) : null}
    {inPersonVisitPrice ? (
      <HHTypography hhVariant="caption">
        In Person Visit: {formatCurrency(inPersonVisitPrice, currency)}
      </HHTypography>
    ) : null}
  </HHStack>
);

const DisplayProductPackageContractDetails = ({
  contractPackages,
  currency,
}: ProductPackageContractGridDetails): JSX.Element => (
  <HHStack direction="column" textTransform="capitalize">
    {contractPackages.map(({ key, contractPackage }) => (
      <HHTypography key={key} hhVariant="caption">
        {key}: {formatCurrency(contractPackage.price, currency)}
      </HHTypography>
    ))}
  </HHStack>
);

const DisplayContractDetails = ({
  contractType,
  templateName,
  inPersonVisitPrice,
  chronicPrice,
  acutePrice,
  currency,
  contractPackages,
}: ContractGridDetails): JSX.Element => {
  if (contractType !== AllContractsRowLabels.PRODUCT_PACKAGE_CONTRACT) {
    return (
      <DisplayLegacyContractDetails
        templateName={templateName}
        inPersonVisitPrice={inPersonVisitPrice}
        chronicPrice={chronicPrice}
        acutePrice={acutePrice}
        currency={currency}
      />
    );
  } else {
    return (
      <DisplayProductPackageContractDetails
        contractPackages={contractPackages}
        currency={currency}
      />
    );
  }
};

export default DisplayContractDetails;
