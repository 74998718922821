import { HHInfoGrid, HHStack } from '@hinge-health/react-component-library';
import { useBillingSetting } from '../../context/billing.context';
import { SubscriptionHoldType } from '../../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../utils/bills-utils';
import { displayDateTime } from '../../utils/date-helper';
import { cleanTypeNameGraphql } from '../../utils/graphql-utils';
import { TextGroupingChildren } from '../utils/text-grouping';

export interface SubscriptionHoldDetailsProps {
  holdData: SubscriptionHoldType;
}

const SubscriptionHoldDetails = ({
  holdData,
}: SubscriptionHoldDetailsProps): JSX.Element => {
  const { value: displayLocal } = useBillingSetting('displayDateTimeLocal');
  return (
    <HHStack spacing={4} padding={1}>
      <HHInfoGrid
        data={[
          {
            content: String(holdData.id),
            heading: 'Id',
            copyable: true,
          },
          {
            content: capitalizeFirstLetterAndSpaceSnakeString(holdData.reason),
            heading: 'Reason',
            copyable: true,
          },
          {
            content: displayDateTime(holdData.firstSeenAt, {
              displayLocal,
            }),
            heading: 'First Seen',
            copyable: true,
          },
          {
            content: displayDateTime(holdData.lastSeenAt, {
              displayLocal,
            }),
            heading: 'Last Seen',
            copyable: true,
          },
          {
            content: String(holdData.hitCount),
            heading: 'Hit Count',
            copyable: false,
          },
        ]}
        divided={false}
        justifyBetween={true}
      />
      <TextGroupingChildren label="Extra details">
        <pre>
          {JSON.stringify(
            cleanTypeNameGraphql(holdData.details || {}),
            null,
            2,
          )}
        </pre>
      </TextGroupingChildren>
    </HHStack>
  );
};

export default SubscriptionHoldDetails;
