import { HHDrawer, HHStack } from '@hinge-health/react-component-library';
import { PropsWithChildren } from 'react';

export enum DiscountDrawerMode {
  New,
  Edit,
  None,
}

interface DiscountDrawerProps {
  onClose: () => void;
  drawerMode: DiscountDrawerMode;
}

const DiscountDrawer = ({
  onClose,
  children,
  drawerMode = DiscountDrawerMode.None,
}: PropsWithChildren<DiscountDrawerProps>): JSX.Element => (
  <HHDrawer
    open={drawerMode !== DiscountDrawerMode.None}
    anchor="right"
    onClose={onClose}
    variant="temporary"
    PaperProps={{
      sx: {
        width: '50%',
      },
    }}
  >
    <HHStack direction="column" spacing={4} padding={4}>
      {children}
    </HHStack>
  </HHDrawer>
);

export default DiscountDrawer;
