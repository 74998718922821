import { HHStack, HHTypography } from '@hinge-health/react-component-library';
import {
  BillTypeData,
  InsurersType,
  ReleasableHoldReasons,
} from '../bill-holds-types';

export interface InsurerCellProps {
  insurers: InsurersType[];
  details: BillTypeData;
}
export const SimpleInsurerCell = ({
  insurers,
  details,
}: InsurerCellProps): JSX.Element => {
  const { holdType, insurerId } = details?.holdDetails ?? {};

  return (
    <HHStack>
      {details.insurer.id &&
      insurerId &&
      !details.insurer.loading &&
      insurers.length >= 1 &&
      holdType === ReleasableHoldReasons.CARRIER_CHANGE ? (
        <HHStack>
          <HHTypography
            hhVariant="variant-bypass"
            variant="caption"
            sx={{ textDecoration: 'line-through', color: 'gray' }}
          >
            {details.insurer.name}
          </HHTypography>
          <HHTypography hhVariant="caption">
            {insurers.filter(insurer => insurer.id === insurerId)[0].name}
          </HHTypography>
        </HHStack>
      ) : holdType !== ReleasableHoldReasons.CARRIER_CHANGE || !insurerId ? (
        <HHTypography hhVariant="caption">{details.insurer.name}</HHTypography>
      ) : (
        <div>Loading...</div>
      )}
    </HHStack>
  );
};
