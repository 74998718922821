import { Navigate, Route, Routes } from 'react-router-dom';
import { tabRoutes } from '../../constants/hinge-connect-constants.constant';
import { healthRecordsRoutes } from '../constants/health-records.constant';
import { ListHealthRecordsView } from './list/list-health-records-view';

export const HealthRecordsTab = (): JSX.Element => (
  <Routes>
    <Route
      path={`/${tabRoutes.healthRecords}/${healthRecordsRoutes.list}`}
      element={<ListHealthRecordsView />}
    />
    <Route
      path="*"
      element={
        <Navigate
          to={`/${tabRoutes.baseRoute}/${tabRoutes.healthRecords}/${healthRecordsRoutes.list}`}
        />
      }
    />
  </Routes>
);
