import {
  HHButton,
  HHGrid,
  HHLoadingButton,
  HHTextField,
} from '@hinge-health/react-component-library';
import React, { useEffect, useState } from 'react';
import { HingeConnectAutocomplete } from '../../../hinge-connect/components/hinge-connect-autocomplete';
import {
  buttonLabels,
  getCriteriaOperatorsCompleteOptions,
  getCriteriaOperatorValueCompleteOptions,
} from '../../../hinge-connect/constants/hinge-connect-constants.constant';
import { criterionTextFields } from '../../../hinge-connect/rules/constants/rules.constant';
import { handleInputChange } from '../../../hinge-connect/utils/input-change-handler';

export const EditCriterionForm = (props: {
  criterion: {
    id: string;
    name: string;
    operator: string;
    key: string;
    value: string;
  };
  onCancel: () => void;
  onSubmit: (criterion: {
    id: string;
    name: string;
    operator: string;
    key: string;
    value: string;
  }) => void;
}): JSX.Element => {
  const [name, setName] = useState(props.criterion.name);
  const [operator, setOperator] = useState(props.criterion.operator);
  const [key, setKey] = useState(props.criterion.key);
  const [searchValue, setSearchValue] = useState(props.criterion.value);
  const [formInvalid, setFormInvalid] = useState(false);

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    props.onSubmit({
      id: props.criterion.id,
      name,
      operator,
      key,
      value: searchValue,
    });
  };

  useEffect(() => {
    if (name && operator && key && searchValue) {
      setFormInvalid(false);
    } else {
      setFormInvalid(true);
    }
  }, [name, operator, key, searchValue]);

  return (
    <form onSubmit={handleSubmit}>
      <HHGrid container spacing={2} marginTop={'1rem'}>
        <HHGrid item xs={3}>
          <HHTextField
            fullWidth
            hhVariant="variant-bypass"
            label={criterionTextFields.name.label}
            inputProps={{
              'aria-label': 'Name',
              'data-testid': 'name-input',
            }}
            value={name}
            size="medium"
            onChange={(e): void => handleInputChange(e, setName)}
            helperText={criterionTextFields.name.helperText}
          />
        </HHGrid>
        <HHGrid item xs={3}>
          <HHTextField
            fullWidth
            hhVariant="variant-bypass"
            label={criterionTextFields.key.label}
            inputProps={{
              'aria-label': 'Key',
              'data-testid': 'key-input',
            }}
            value={key}
            size="medium"
            onChange={(e): void => handleInputChange(e, setKey)}
            helperText={criterionTextFields.key.helperText}
          />
        </HHGrid>
        <HHGrid item xs={3}>
          <HingeConnectAutocomplete
            label={criterionTextFields.operator.label}
            value={operator}
            clearOnBlur
            dataTestId="operator-select"
            dropdownOptions={getCriteriaOperatorsCompleteOptions() ?? []}
            size="medium"
            onChange={(value): void => setOperator(value ?? '')}
            helperText={criterionTextFields.operator.helperText}
          />
        </HHGrid>
        <HHGrid item xs={3}>
          <HingeConnectAutocomplete
            label={criterionTextFields.value.label}
            value={searchValue}
            dataTestId="value-select"
            dropdownOptions={
              getCriteriaOperatorValueCompleteOptions(operator) ?? []
            }
            size="medium"
            onChange={(value): void => setSearchValue(value ?? '')}
            helperText={criterionTextFields.value.helperText}
          />
        </HHGrid>
      </HHGrid>
      <HHGrid
        container
        display={'flex'}
        spacing={2}
        justifyContent={'end'}
        marginTop={'1rem'}
      >
        <HHGrid item xs={8} />
        <HHGrid item xs={0}>
          <HHButton
            data-testid="cancel-criterion-button"
            hhVariant="text"
            sx={{ width: 'fit-content' }}
            onClick={props.onCancel}
          >
            {buttonLabels.cancel}
          </HHButton>
        </HHGrid>
        <HHGrid item xs={0}>
          <HHLoadingButton
            hhVariant="variant-bypass"
            variant="contained"
            sx={{ width: 'fit-content' }}
            type="submit"
            loading={false}
            disabled={formInvalid}
            data-testid="submit-button"
          >
            Save
          </HHLoadingButton>
        </HHGrid>
      </HHGrid>
    </form>
  );
};
