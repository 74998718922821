import {
  ApolloCache,
  ApolloError,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
} from '@apollo/client';
import { useState } from 'react';
import { getApolloErrorMessages } from '../../utils/get-apollo-error-messages';
import {
  DeleteTagMutation,
  Exact,
  ListTagsDocument,
  useDeleteTagMutation,
} from '../types';

interface UseDeleteTagHook {
  deleteTag: (
    options?:
      | MutationFunctionOptions<
          DeleteTagMutation,
          Exact<{
            id: string;
          }>,
          DefaultContext,
          ApolloCache<unknown>
        >
      | undefined,
  ) => Promise<
    FetchResult<
      DeleteTagMutation,
      Record<string, unknown>,
      Record<string, unknown>
    >
  >;
  data: unknown;
  loading: boolean;
  error: ApolloError | undefined;
  errorMessages: string;
}

export const useDeleteTag = (
  completeCallback: () => void,
): UseDeleteTagHook => {
  const [errorMessages, setErrorMessages] = useState('');

  const [deleteTag, { data, loading, error }] = useDeleteTagMutation({
    refetchQueries: [ListTagsDocument],
    onError: (error: ApolloError) => {
      setErrorMessages(getApolloErrorMessages(error));
    },
    onCompleted: () => {
      completeCallback();
    },
  });

  return { deleteTag, data, loading, error, errorMessages };
};
