/**
 * @param string i.e carrier_change
 * @returns i.e Carrier change
 */
export const capitalizeFirstLetterAndSpaceSnakeString = (
  string = '',
): string => {
  const withSpaces = string.replace(/_/g, ' ');
  const capitalized = withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);
  return capitalized;
};

/**
 * @param string i.e billType
 * @returns i.e Bill type
 */
export const capitalizeFirstLetterAndSpaceCamelCaseString = (
  string = '',
): string => {
  const formatted = string.replace(/([A-Z])/g, ' $1').toLowerCase();
  return formatted.charAt(0).toUpperCase() + formatted.slice(1);
};

/**
 * @param input string
 * i.e 'a.c123.2024-01\na.c123.5342.2023-01'
 * i.e '12323232\n12323232'
 * @returns string[]
 *  i.e ['a.c123.2024-01', 'a.c123.5342.2023-01']
 * i.e [12323232, 12323232]
 */
export const formatLineBreakOrCsvInput = (input: string): string[] => {
  let outputString = '';
  const hasLineBreaks = /\n/.test(input);
  const hasCommas = /,/.test(input);

  if (hasLineBreaks) {
    outputString = input.replace(/\n/g, ',');
  }

  if (hasLineBreaks && hasCommas) {
    outputString = input.replace(/\n/g, '').replace(/,/g, ',');
  }

  return outputString.length ? outputString.split(',') : input.split(',');
};

/**
 *turns string array into numer array and filters out NaN
 * @param input string[]
 * i.e ['12323232', '12323232', ]
 * @returns number[]
 * (interrim would be ['12323232', '12323232', NaN ] before filtering)
 * i.e [12323232, 12323232]
 */
export const convertStringArrayToNumberArray = (input: string[]): number[] =>
  input.map(id => parseInt(id)).filter(id => !isNaN(id));

export const selectSorterFunction = (
  selectA: string,
  selectB: string,
): number => {
  const normalizedA = selectA.toLowerCase().replace('_', '');
  const normalizedB = selectB.toLowerCase().replace('_', '');
  if (normalizedA < normalizedB) {
    return -1;
  }
  if (normalizedA > normalizedB) {
    return 1;
  }
  return 0;
};
