import { HHLink, HHStack } from '@hinge-health/react-component-library';
import { routes } from '../../../../constants/strings/routes';
import { ClientType } from '../../../../types';
import { BillTypeData, ReleasableHoldReasons } from '../bill-holds-types';

export interface ClientCellProps {
  clients: ClientType[];
  details: BillTypeData;
}
export const SimpleClientCell = ({
  clients,
  details,
}: ClientCellProps): JSX.Element => {
  const { holdType, clientId } = details?.holdDetails ?? {};

  return (
    <HHStack>
      {details.client.id &&
      clientId &&
      !details.client.loading &&
      clients.length >= 1 &&
      holdType === ReleasableHoldReasons.CLIENT_CHANGE ? (
        <>
          <HHLink
            href={`${routes.billing.home}/${routes.billing.clientPath}/${details.client.id}`}
            target="_blank"
            sx={{ textDecoration: 'line-through', color: 'gray' }}
          >
            {details.client.name}
          </HHLink>
          <HHLink
            color="primary"
            href={`${routes.billing.home}/${routes.billing.clientPath}/${clientId}`}
            target="_blank"
            sx={{ textDecoration: 'none' }}
          >
            {clients.filter(client => client.id === clientId)[0].name}
          </HHLink>
        </>
      ) : holdType !== ReleasableHoldReasons.CLIENT_CHANGE || !clientId ? (
        <HHLink
          color="primary"
          href={`${routes.billing.home}/${routes.billing.clientPath}/${details.client.id}`}
          target="_blank"
          sx={{ textDecoration: 'none' }}
        >
          {details.client.name}
        </HHLink>
      ) : (
        <div>Loading...</div>
      )}
    </HHStack>
  );
};
