import { useEffect, useMemo, useState } from 'react';
import { DateRangePickerValue } from '../../components/date-range-picker';
import { CreateAssessmentTaskInput } from '../types';

export interface UseAssessmentTaskFormHook {
  source: string;
  setSource: React.Dispatch<React.SetStateAction<string>>;
  resourceType: string;
  setResourceType: React.Dispatch<React.SetStateAction<string>>;
  ruleId: string;
  setRuleId: React.Dispatch<React.SetStateAction<string>>;
  assessmentWindow: [DateRangePickerValue, DateRangePickerValue];
  setAssessmentWindow: React.Dispatch<
    React.SetStateAction<[DateRangePickerValue, DateRangePickerValue]>
  >;
  limit: string;
  setLimit: React.Dispatch<React.SetStateAction<string>>;
  validateLimit: boolean;
  submitEnabled: boolean;
  handleSubmit: () => void;
}

export const useAssessmentTaskForm = (
  onSubmit: (createAssessmentTaskInput: CreateAssessmentTaskInput) => void,
): UseAssessmentTaskFormHook => {
  const [source, setSource] = useState('');
  const [resourceType, setResourceType] = useState('');
  const [ruleId, setRuleId] = useState('');
  const [assessmentWindow, setAssessmentWindow] = useState<
    [DateRangePickerValue, DateRangePickerValue]
  >([null, null]);
  const [limit, setLimit] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const validateSource = useMemo(() => source.trim().length > 0, [source]);

  const validateLimit = useMemo(() => {
    if (limit) {
      const limitNumber = Number(limit);
      return limitNumber >= 1;
    }
    return true;
  }, [limit]);

  useEffect(() => {
    const readyToSubmit = validateSource && validateLimit;
    setSubmitEnabled(readyToSubmit);
  }, [validateSource, validateLimit]);

  const handleSubmit = (): void => {
    const dto: CreateAssessmentTaskInput = { source };

    if (resourceType) {
      dto.resourceType = resourceType;
    }

    if (ruleId) {
      dto.ruleId = ruleId;
    }

    if (assessmentWindow[0]) {
      const startDate = assessmentWindow[0].toISOString().split('T')[0];
      dto.startDate = startDate;
    }

    if (assessmentWindow[1]) {
      const endDate = assessmentWindow[1].toISOString().split('T')[0];
      dto.endDate = endDate;
    }

    if (limit) {
      dto.limit = Number(limit);
    }

    onSubmit(dto);
  };

  return {
    source,
    setSource,
    resourceType,
    setResourceType,
    ruleId,
    setRuleId,
    assessmentWindow,
    setAssessmentWindow,
    limit,
    setLimit,
    validateLimit,
    submitEnabled,
    handleSubmit,
  };
};
