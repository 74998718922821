import {
  Atoms,
  HHBox,
  HHCircularProgress,
  HHSelect,
  HHSelectChangeEvent,
  HHSelectOption,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Box, Button } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { targetedInterventionsEnrollment } from '../../../constants/strings/targeted-interventions';
import {
  enrollmentText,
  enrollmentTextReplaceToken,
  useEnrollmentStyles,
  workflowTypeOptions,
} from '../constants';
import { useEnrollmentForm } from '../hooks/use-enrollment-form';
import { TargetedInterventionsPage } from './ti-page';

export const dropDownOptions: HHSelectOption[] = workflowTypeOptions.map(
  option => ({
    displayName: option,
    value: option,
  }),
);

export const TargetedInterventionsEnrollment = (): JSX.Element => {
  const classes = useEnrollmentStyles();
  const form = useEnrollmentForm();
  const [helpText, setHelpText] = useState<{ testId?: string; text: string }[]>(
    [],
  );

  /** Maintain list of help messages, based on form state. May include a mix of success and error messages. */
  useEffect(() => {
    const help = [];

    if (form.success || form.error) {
      if (form.success) {
        help.push({
          testId: 'success-uuids-message',
          text: enrollmentText.form.submit.success.replace(
            enrollmentTextReplaceToken,
            form.success.count.toString(),
          ),
        });
      }

      if (form.error?.failedUuids && form.error.failedUuids.length) {
        help.push({
          testId: 'failed-uuids-message',
          text: enrollmentText.form.submit.error.replace(
            enrollmentTextReplaceToken,
            form.error?.failedUuids.join(', '),
          ),
        });
      }

      if (form.error?.mutationError) {
        help.push({
          testId: 'api-error-message',
          text: enrollmentText.form.submit.apiError.replace(
            enrollmentTextReplaceToken,
            form.error.mutationError,
          ),
        });
      }
    }

    if (form.isValid === false) {
      help.push({
        text: enrollmentText.form.uuid.error,
      });
    } else {
      help.push({ text: enrollmentText.form.uuid.help });
    }

    setHelpText(help);
  }, [form.error, form.isValid, form.success]);

  useEffect(() => {
    form.setValue(dropDownOptions[0].value.toString(), 'workflowType');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TargetedInterventionsPage title={targetedInterventionsEnrollment.text}>
      <Box className={classes.formContainer}>
        <form
          noValidate
          onSubmit={async (event): Promise<void> => {
            event.preventDefault();

            if (form.validate()) {
              try {
                await form.submit();
              } catch (anyError) {
                console.error(
                  'unexpected error when submitting form: ' +
                    (anyError as Error).message,
                );
              }
            }
          }}
        >
          <HHBox
            sx={{
              marginBottom: 8,
              '#hh-select-helper-label': {
                color: '#000',
              },
              '.css-ozb2ig-MuiFormHelperText-root': {
                color: '#000',
              },
            }}
          >
            <HHSelect
              onChange={(event: HHSelectChangeEvent): void => {
                form.setValue(event.target.value.toString(), 'workflowType');
              }}
              initialSelectValue={form.value.workflowType}
              selectOptions={dropDownOptions}
              label={enrollmentText.form.intervention.label}
              helperText={enrollmentText.form.intervention.help}
              size="medium"
              disabled={form.loading}
              data-testid="workflow-type-select"
            />
          </HHBox>

          <Atoms.TextArea
            required
            minRows={5}
            maxRows={5}
            value={form.value.patientUuids}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              form.setValue(event.target.value, 'patientUuids');
            }}
            onBlur={(): void => {
              form.validate();
            }}
            name="user_ids"
            label={enrollmentText.form.uuid.label}
            error={form.isValid === false}
            helperText={
              form.loading ? (
                <HHCircularProgress styleAs="primary" />
              ) : (
                helpText.map(({ testId, text }, key) => (
                  <div key={key}>
                    <HHTypography
                      data-testid={testId || undefined}
                      hhVariant="variant-bypass"
                      variant="body2"
                      variantMapping={{ body2: 'span' }}
                    >
                      {text}
                    </HHTypography>
                  </div>
                ))
              )
            }
            classes={{ root: classes.field }}
            disabled={form.loading}
            InputProps={{ classes: { root: classes.input } }}
          />

          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={form.loading || form.isValid === false}
            classes={{ root: classes.formSubmit }}
            children={enrollmentText.form.submit.label}
          />
        </form>
      </Box>
    </TargetedInterventionsPage>
  );
};
