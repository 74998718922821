import {
  AssignmentInd,
  AttachMoney,
  FactCheck,
  HowToVote,
  ListAltSharp,
  UploadFile,
} from '@mui/icons-material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import RuleIcon from '@mui/icons-material/Rule';
import { SvgIconProps } from '@mui/material';
import React from 'react';
import AuthRedirectCallback from './components/auth-redirect-callback';
import { routes, text } from './constants';
import { Login, TargetedInterventions } from './pages';
import { ApplicationManagementTool } from './pages/application-management-tool';
import { BillingToolPage } from './pages/billing-tool-page';
import { EligibilityPage } from './pages/eligibility';
import { HingeConnectPage } from './pages/hinge-connect-page';
import { MemberAuthToolPage } from './pages/member-auth-tool.page';
import { MemberDataPage } from './pages/member-data-page';
import { MemberDataServicesPage } from './pages/member-data-services-page';
import { OtherRoles } from './pages/other-roles';
import { TargetedInterventionsEnrollment } from './pages/targeted-interventions-enrollment';

export interface RouteDetails {
  path: string;
  menuPath?: string;
  label?: string;
  icon?: {
    svg?: React.ComponentType<SvgIconProps>;
    png?: string;
  };
  component: () => JSX.Element;
}

export const authRoutes: RouteDetails[] = [
  {
    path: routes.auth_redirect_callback,
    component: AuthRedirectCallback,
  },
  {
    path: routes.login,
    component: Login,
  },
];

export const applicationManagementToolRoutes: RouteDetails[] = [
  {
    path: routes.application_management_tool,
    component: ApplicationManagementTool,
  },
];

export const tiRoutes: RouteDetails[] = [
  {
    path: routes.targeted_interventions,
    label: text.targetedInterventions.menuLabel,
    icon: {
      svg: AssignmentInd,
    },
    component: TargetedInterventions,
  },
  {
    path: routes.targeted_interventions_enrollment,
    label: text.targetedInterventionsEnrollment.menuLabel,
    icon: { svg: HowToVote },
    component: TargetedInterventionsEnrollment,
  },
];

export const billingToolRoutes: RouteDetails[] = [
  {
    path: routes.billing_tool,
    label: text.billing.menuLabel,
    icon: { svg: AttachMoney },
    component: BillingToolPage,
  },
];

export const hingeConnectRoutes: RouteDetails[] = [
  {
    path: routes.hinge_connect,
    menuPath: routes.hinge_connect_menu,
    label: text.hingeConnect.menuLabel,
    icon: { svg: ListAltSharp },
    component: HingeConnectPage,
  },
];

export const eligibilityRoutes: RouteDetails[] = [
  {
    path: routes.eligibility,
    label: text.eligibility.menuLabel,
    icon: { svg: FactCheck },
    component: EligibilityPage,
  },
];

export const memberDataRoutes: RouteDetails[] = [
  {
    path: routes.member_data,
    label: text.memberData.menuLabel,
    icon: { svg: UploadFile },
    component: MemberDataPage,
  },
];

export const memberDataServicesRoutes: RouteDetails[] = [
  {
    path: routes.member_data_services,
    label: text.memberDataServices.menuLabel,
    icon: { svg: RuleIcon },
    component: MemberDataServicesPage,
  },
];

export const memberAuthToolRoutes: RouteDetails[] = [
  {
    path: routes.member_auth,
    label: 'Member Auth Tool',
    icon: { svg: ManageAccountsIcon },
    component: MemberAuthToolPage,
  },
];

export const otherRoutes = [
  {
    path: routes.other,
    component: OtherRoles,
  },
];
