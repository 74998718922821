import { HHButton, HHGrid } from '@hinge-health/react-component-library';
import { createStyles, makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import {
  ACTIVATED_COLOR,
  LIGHT_URGENT_RED,
  WARNING_YELLOW,
} from '../constants/colors';
import { DATE_FORMAT } from '../constants/dates';
import { AMT_STRINGS } from '../constants/strings/application-management-tool';
import {
  GetWorkflowsByFilterDocument,
  useUpdateWorkflowMutation,
  WorkflowPayload,
} from '../types';
import { statesMap } from './amt-sidebar/states';
import { ApplicationManagementToolCardSpacer } from './application-management-tool-card-spacer';
import { myWorkflowTableRowSizes } from './application-management-tool-my-workflows-table';
import { useCardStyles } from './styles/card-styles';

const myWorkflowStyles = makeStyles(() =>
  createStyles({
    tableRowHover: {
      '&:hover': {
        background: ACTIVATED_COLOR,
        cursor: 'pointer',
      },
    },
    warning: {
      background: WARNING_YELLOW,
    },
    urgent: {
      background: LIGHT_URGENT_RED,
    },
  }),
);

const isUrgent = (workflow: WorkflowPayload): boolean => {
  const overWarningLimit = dayjs().subtract(16, 'hour');
  const lastUpdated = dayjs(workflow.updatedAt);
  return lastUpdated.isBefore(overWarningLimit);
};

const isWarning = (workflow: WorkflowPayload): boolean => {
  const overUrgentLimit = dayjs().subtract(1, 'day');
  const lastUpdated = dayjs(workflow.updatedAt);
  return workflow.isPastDue || lastUpdated.isBefore(overUrgentLimit);
};

const getRowStyle = (workflow: WorkflowPayload): string => {
  const styles = myWorkflowStyles();

  if (isUrgent(workflow)) {
    return styles.urgent;
  } else if (isWarning(workflow)) {
    return styles.warning;
  } else {
    return '';
  }
};

type ApplicationManagementToolMyWorkflowsTableRowProps = {
  i: number;
  workflow: WorkflowPayload;
  onClick?: (workflow: WorkflowPayload) => void;
};

export const ApplicationManagementToolMyWorkflowsTableRow = ({
  i,
  workflow,
  onClick,
}: ApplicationManagementToolMyWorkflowsTableRowProps): JSX.Element => {
  const classes = useCardStyles();
  const workflowStyles = myWorkflowStyles();
  const [updateWorkflowMutation] = useUpdateWorkflowMutation();
  return (
    <HHGrid
      data-testid={workflow.id}
      container
      className={`${classes.cardRow} ${
        workflowStyles.tableRowHover
      } ${getRowStyle(workflow)}`}
      onClick={(): void => onClick?.(workflow)}
    >
      <HHGrid
        item
        xs={myWorkflowTableRowSizes.firstSpacer}
        className={classes.spacer}
      />
      <HHGrid
        item
        xs={myWorkflowTableRowSizes.number}
        className={classes.cardGridItem}
      >
        {i}
      </HHGrid>
      <HHGrid
        item
        sm={myWorkflowTableRowSizes.userUuid}
        className={`${classes.cardGridItem} ${classes.bold}`}
      >
        {workflow?.patient?.id}
      </HHGrid>
      <HHGrid
        item
        sm={myWorkflowTableRowSizes.firstName}
        className={`${classes.cardGridItem} ${classes.bold}`}
      >
        {workflow?.patient?.firstName}
      </HHGrid>
      <HHGrid
        item
        sm={myWorkflowTableRowSizes.lastName}
        className={`${classes.cardGridItem} ${classes.bold}`}
      >
        {workflow?.patient?.lastName}
      </HHGrid>
      <HHGrid
        item
        sm={myWorkflowTableRowSizes.state}
        className={`${classes.bold} ${classes.centerVertically}`}
      >
        {statesMap.get(workflow.state) || workflow.state}
      </HHGrid>
      <HHGrid
        item
        className={`${classes.bold} ${classes.centerVertically}`}
        sm={myWorkflowTableRowSizes.lastUpdated}
      >
        {dayjs(workflow.updatedAt).format(DATE_FORMAT)}
      </HHGrid>
      <HHGrid item sm={myWorkflowTableRowSizes.unassign}>
        <HHButton
          hhVariant="contained"
          onClick={(e): void => {
            e.stopPropagation();
            updateWorkflowMutation({
              variables: {
                updateWorkflowInputDto: {
                  workflowId: workflow.id,
                  adminUuids: null,
                },
                workflowType: workflow.type,
              },
              refetchQueries: [GetWorkflowsByFilterDocument],
            });
          }}
        >
          {AMT_STRINGS.UNASSIGN_BUTTON}
        </HHButton>
      </HHGrid>
      <ApplicationManagementToolCardSpacer />
    </HHGrid>
  );
};
