import { Navigate, Route, Routes } from 'react-router-dom';
import { tabRoutes } from '../../constants/hinge-connect-constants.constant';
import { rulesRoutes } from '../constants/rules.constant';
import { CreateRuleView } from './create-rule-view';
import { ListRulesView } from './list-rules-view';
import { RuleDetailView } from './rule-detail-view';
import { UpdateRuleView } from './update-rule-view';

export const RulesTab = (): JSX.Element => (
  <Routes>
    <Route
      path={`/${tabRoutes.rules}/${rulesRoutes.new}`}
      element={<CreateRuleView />}
    />
    <Route
      path={`/${tabRoutes.rules}/:id/${rulesRoutes.view}`}
      element={<RuleDetailView />}
    />
    <Route
      path={`/${tabRoutes.rules}/:id/${rulesRoutes.edit}`}
      element={<UpdateRuleView />}
    />
    <Route
      path={`/${tabRoutes.rules}/${rulesRoutes.list}`}
      element={<ListRulesView />}
    />
    <Route
      path="*"
      element={
        <Navigate
          to={`/${tabRoutes.baseRoute}/${tabRoutes.rules}/${rulesRoutes.list}`}
        />
      }
    />
  </Routes>
);
