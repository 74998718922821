import {
  ApolloCache,
  ApolloError,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
} from '@apollo/client';
import { useState } from 'react';
import { getApolloErrorMessages } from '../../utils/get-apollo-error-messages';
import {
  DeleteRuleMutation,
  Exact,
  ListRulesDocument,
  useDeleteRuleMutation,
} from '../types';

interface UseDeleteRuleHook {
  deleteRule: (
    options?:
      | MutationFunctionOptions<
          DeleteRuleMutation,
          Exact<{
            id: string;
          }>,
          DefaultContext,
          ApolloCache<unknown>
        >
      | undefined,
  ) => Promise<
    FetchResult<
      DeleteRuleMutation,
      Record<string, unknown>,
      Record<string, unknown>
    >
  >;
  data: unknown;
  loading: boolean;
  error: ApolloError | undefined;
  errorMessages: string;
}

export const useDeleteRule = (
  completeCallback: () => void,
): UseDeleteRuleHook => {
  const [errorMessages, setErrorMessages] = useState('');

  const [deleteRule, { data, loading, error }] = useDeleteRuleMutation({
    refetchQueries: [ListRulesDocument],
    onError: (error: ApolloError) => {
      setErrorMessages(getApolloErrorMessages(error));
    },
    onCompleted: () => {
      completeCallback();
    },
  });

  return { deleteRule, data, loading, error, errorMessages };
};
