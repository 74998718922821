import {
  HHLink,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { GridCellParams } from '@mui/x-data-grid';
import { routes } from '../../../constants/strings/routes';
import { ClientType } from '../../../types';
import { ReleasableHoldReasons } from './bill-holds-types';

export interface ClientCellProps extends GridCellParams {
  clients: ClientType[];
}
export const ClientCell = ({
  clients,
  ...params
}: ClientCellProps): JSX.Element => {
  const { holdType, clientId } = params.row.holdDetails as Record<
    string,
    string
  >;

  if (!params.value.id && !params.value.name) {
    return <HHTypography hhVariant="caption">Loading...</HHTypography>;
  }

  return (
    <HHStack>
      {params.value.id &&
      clientId &&
      !params.value.loading &&
      clients.length >= 1 &&
      holdType === ReleasableHoldReasons.CLIENT_CHANGE ? (
        <HHStack>
          <HHLink
            href={`${routes.billing.home}/${routes.billing.clientPath}/${params.value.id}`}
            target="_blank"
            sx={{ textDecoration: 'line-through', color: 'gray' }}
          >
            {params.value.name}
          </HHLink>
          <HHLink
            color="primary"
            href={`${routes.billing.home}/${routes.billing.clientPath}/${clientId}`}
            target="_blank"
            sx={{ textDecoration: 'none' }}
          >
            {clients.filter(client => client.id === parseInt(clientId))[0].name}
          </HHLink>
        </HHStack>
      ) : (
        <HHLink
          color="primary"
          href={`${routes.billing.home}/${routes.billing.clientPath}/${params.value.id}`}
          target="_blank"
          sx={{ textDecoration: 'none' }}
        >
          {params.value.name}
        </HHLink>
      )}
    </HHStack>
  );
};
