import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AMT_MENU_COLORS } from '../../constants/colors';
import { AMT_THEME } from '../../constants/theme';

export const useCardStyles = makeStyles(() =>
  createStyles({
    cardRow: {
      cursor: 'pointer',
      fontSize: 14,
      lineHeight: 1,
    },
    bold: {
      fontWeight: 700,
    },
    centeredColumn: {
      textAlign: 'center',
      padding: '0 5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    centerVertically: {
      padding: '0 5px',
      display: 'flex',
      alignItems: 'center',
    },
    spacer: {
      flex: '12px 0',
    },
    titleText: {
      margin: '20px 10px 5px',
      color: AMT_MENU_COLORS.TITLE_LIGHT,
      fontSize: 12,
    },
    dividerBox: {
      flexDirection: 'column',
      display: 'flex',
      width: '100%',
    },
    cardGridItem: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 32,
      padding: '8px 0',
      '& p': {
        lineHeight: 1,
      },
    },
    centeredDateText: {
      height: '100%',
      display: 'flex',
      minWidth: 70,
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: AMT_THEME.FONT_FAMILY,
      fontSize: AMT_THEME.FONT_SIZES.SMALL,
    },
  }),
);
