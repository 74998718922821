import { Link } from '@mui/material';
import { useMemo } from 'react';
import { SplitResultLink, splitText } from './utils';

type MarkdownTextLinkProps = {
  markdownString: string;
};

export const MarkdownTextLink = ({
  markdownString,
}: MarkdownTextLinkProps): JSX.Element => {
  const parsedStringData = useMemo(
    () => splitText(markdownString),
    [markdownString],
  );

  return (
    <>
      {parsedStringData.map(value => {
        if (typeof value === 'string') {
          return <>{value}</>;
        } else if (value instanceof SplitResultLink) {
          return (
            <Link href={value.link} target="_blank" rel="noopener">
              {value.text}
            </Link>
          );
        }
      })}
    </>
  );
};
