import { Alert, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColumns, GridRowModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { targetedInterventions } from '../../../constants/strings/targeted-interventions';
import { colors, fontStyles, workflowsText } from '../constants';
import { useGetWorkflowsByFilterQuery, WorkflowPayload } from '../types';
import {
  getOutreachTaskResolutionReason,
  getTaskState,
  getUserFullName,
  getUserId,
  getWorkflowType,
} from '../utils/workflow-grid-utils';
import { DataGridTooltipCell } from './data-grid/data-grid-tooltip-cell';
import { TargetedInterventionsPage } from './ti-page';

const useStyles = makeStyles(
  createStyles({
    textBlurb: {
      backgroundColor: colors.background,
      color: colors.text,
      minHeight: '100vh',
      padding: '24px',
      ...fontStyles,
    },
    root: {
      padding: '0 24px 24px 24px',
      margin: 0,
      position: 'fixed',
      top: '4.25rem',
      bottom: 0,
      overflowY: 'scroll',
      maxHeight: '100%',
    },
    workflowContainer: {
      padding: '24px 0 0 0',
      margin: 0,
      backgroundColor: 'white',
      ...fontStyles,
      '& h2': {
        padding: '0px 10px 0px 16px',
        margin: '0px 0px 20px',
      },
    },
  }),
);

export const WorkflowGrid = (): JSX.Element => {
  const classes = useStyles();

  enum ColumnHeaders {
    fullName = 'fullName',
    patientUuid = 'patientUuid',
    tasksText = 'tasksText',
    taskState = 'taskState',
    notes = 'notes',
  }

  const WorkflowColumns: GridColumns = [
    {
      field: ColumnHeaders.fullName,
      headerName: workflowsText.phaseGrid.headers.fullName,
      width: 410,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getUserFullName,
    },
    {
      field: ColumnHeaders.patientUuid,
      headerName: workflowsText.phaseGrid.headers.patientUuid,
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getUserId,
    },
    {
      field: ColumnHeaders.tasksText,
      headerName: workflowsText.phaseGrid.headers.taskDesc,
      width: 230,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getWorkflowType,
    },
    {
      field: ColumnHeaders.taskState,
      headerName: workflowsText.phaseGrid.headers.taskState,
      width: 220,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getTaskState,
    },
    {
      field: ColumnHeaders.notes,
      headerName: workflowsText.phaseGrid.headers.notes,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getOutreachTaskResolutionReason,
      renderCell: DataGridTooltipCell,
    },
  ];

  const { data, loading } = useGetWorkflowsByFilterQuery({
    variables: {
      workflowFiltersInput: {
        pageSize: 50,
        pageNumber: 1,
        order: 'DESC',
        orderBy: 'createdAt',
      },
      workflowType: 'insight-surgery-outreach',
    },
  });
  const workflowData = data?.getWorkflowsByFilter
    .workflows as WorkflowPayload[];

  const [loadedWorkflowData, setLoadedWorkflowData] = useState<
    WorkflowPayload[] | undefined
  >(workflowData);

  useEffect(() => {
    setLoadedWorkflowData(workflowData);
  }, [workflowData]);

  const [displayRows, setDisplayRows] = useState<GridRowModel[]>([]);

  useEffect(() => {
    if (loadedWorkflowData) {
      const workflows =
        loadedWorkflowData?.map(workflow => ({
          ...(workflow as WorkflowPayload),
        })) || [];
      setDisplayRows(workflows);
    }
  }, [loadedWorkflowData]);

  if (data) {
    return (
      <TargetedInterventionsPage title={targetedInterventions.text}>
        <DataGrid
          columnBuffer={10}
          autoHeight
          hideFooter
          columns={WorkflowColumns}
          rows={displayRows}
          isRowSelectable={(): boolean => false}
        />
      </TargetedInterventionsPage>
    );
  } else if (loading) {
    return <Box className={classes.textBlurb}>Loading...</Box>;
  } else {
    return (
      <Box className={classes.textBlurb}>
        <Alert severity="error">There was an error</Alert>
      </Box>
    );
  }
};

export default WorkflowGrid;
