import {
  HHBox,
  HHDateRangePicker,
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import { datePicker } from '../constants/hinge-connect-constants.constant';

export type DateRangePickerValue = Date | null;

interface DateRangePickerProps {
  label: string;
  helperText: string;
  dateRange: [DateRangePickerValue, DateRangePickerValue];
  setDateRange: React.Dispatch<
    React.SetStateAction<[DateRangePickerValue, DateRangePickerValue]>
  >;
}

export const DateRangePicker = ({
  label,
  helperText,
  dateRange,
  setDateRange,
}: DateRangePickerProps): JSX.Element => (
  <HHBox marginY="1rem">
    <HHTypography hhVariant="input-label">{label}</HHTypography>
    <HHDateRangePicker
      localeText={{
        start: datePicker.startDate,
        end: datePicker.endDate,
      }}
      onChange={(data): void => {
        setDateRange(data);
      }}
      value={dateRange}
      renderInput={(startProps, endProps): JSX.Element => (
        <>
          <HHTextField
            hhVariant="variant-bypass"
            margin="normal"
            {...startProps}
          />
          <HHBox marginX="1rem" marginTop="1rem">
            <HHTypography hhVariant="body">{datePicker.to}</HHTypography>
          </HHBox>
          <HHTextField
            hhVariant="variant-bypass"
            margin="normal"
            {...endProps}
          />
        </>
      )}
    />
    <HHTypography hhVariant="input-label">{helperText}</HHTypography>
  </HHBox>
);
