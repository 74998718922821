import { Alert, Typography } from '@mui/material';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/ext-error_marker';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
import { useState } from 'react';
import AceEditor from 'react-ace';
import { getJsonErrorDetails } from '../components/json-utils';

interface AceEditorComponentProps {
  label: string;
  value: string;
  helperText: string;
  onChange: (value: string) => void;
  dataTestId: string;
}

export const AceEditorComponent = ({
  label,
  value,
  helperText,
  onChange,
  dataTestId,
}: AceEditorComponentProps): JSX.Element => {
  const [error, setError] = useState<string | null>(null);

  const handleAceChange = (value: string): void => {
    onChange(value);
    const { isValid, errorDetails } = getJsonErrorDetails(value);
    setError(isValid ? null : errorDetails);
  };

  const editorStyle = {
    borderRadius: '8px',
    border: '1px solid #ddd',
    height: '50vh',
  };

  return (
    <div>
      <Typography variant="h6">{label}</Typography>
      <br />
      <AceEditor
        mode="json"
        theme="tomorrow_night"
        name={`${dataTestId}-editor`}
        width="100%"
        height="300px"
        value={value}
        onChange={handleAceChange}
        editorProps={{ $blockScrolling: true }}
        setOptions={{ useWorker: true }}
        aria-label={label}
        data-testid={dataTestId}
        style={editorStyle}
      />
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
      <br />
      <Typography variant="body1">{helperText}</Typography>
      <br />
    </div>
  );
};
