import {
  HHButton,
  HHLoadingButton,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { FormEvent } from 'react';
import { buttonLabels } from '../../../constants/hinge-connect-constants.constant';
import { rulesTabContent } from '../../constants/rules.constant';

const BoldedCopyMessage = ({ ruleName }: { ruleName: string }): JSX.Element => (
  <>
    {rulesTabContent.copyRule.bodyPrefix}
    <strong>{ruleName}</strong>
    {rulesTabContent.copyRule.bodySuffix}
  </>
);

const CopyRuleForm = ({
  ruleName,
  handleClose,
  onSubmit,
  loading,
}: {
  ruleName: string;
  handleClose: () => void;
  onSubmit: () => void;
  loading: boolean;
}): JSX.Element => {
  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    onSubmit();
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit}>
      <HHStack width="40vw" maxWidth="500px">
        <HHTypography hhVariant="body" color="textSecondary">
          <BoldedCopyMessage ruleName={ruleName} />
        </HHTypography>
        <HHStack
          direction="row"
          spacing={4}
          justifyContent="right"
          marginTop="16px"
        >
          <HHButton
            hhVariant="text"
            type="button"
            sx={{ margin: '1rem' }}
            onClick={handleClose}
          >
            {buttonLabels.cancel}
          </HHButton>
          <HHLoadingButton
            hhVariant="variant-bypass"
            variant="contained"
            type="submit"
            loading={loading}
            data-testid="copy-rule-button"
          >
            {buttonLabels.copy}
          </HHLoadingButton>
        </HHStack>
      </HHStack>
    </form>
  );
};

export default CopyRuleForm;
