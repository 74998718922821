import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  IDiscountCalculatorState,
  IDiscountGroupState,
  IDiscountPrices,
  calculateSubmission,
  calculateVbdDiscount,
  calculateWeightsByDiscounts,
} from './utils';

type ProsResultSlice = {
  data: IDiscountCalculatorState;
  mType: keyof IDiscountGroupState;
  defaultPrices: IDiscountPrices;
};

type ResultsType = {
  weights: IDiscountPrices;
  vbdDiscount: IDiscountPrices;
  submission: IDiscountPrices;
};

const calculateResults = (
  data: IDiscountCalculatorState,
  mType: keyof IDiscountGroupState,
  defaultPrices: IDiscountPrices,
): ResultsType => {
  const vbdDiscount = calculateVbdDiscount(
    data.groupState[mType],
    defaultPrices,
  );
  const weights = calculateWeightsByDiscounts(
    data.chronicCoreCharge,
    data.groupState[mType],
    vbdDiscount,
  );

  const submission = calculateSubmission(
    data.groupState[mType],
    data.revSharePercent,
  );
  return {
    weights,
    vbdDiscount,
    submission,
  };
};

const ResultSlice = ({
  data,
  mType,
  defaultPrices,
}: ProsResultSlice): JSX.Element => {
  const [results, setResults] = useState<ResultsType>({
    weights: {
      m1: 0,
      m2: 0,
      m3: 0,
    },
    vbdDiscount: {
      m1: 0,
      m2: 0,
      m3: 0,
    },
    submission: {
      m1: 0,
      m2: 0,
      m3: 0,
    },
  });

  useEffect(() => {
    setResults(calculateResults(data, mType, defaultPrices));
  }, [data, mType, defaultPrices]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{mType} Result</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              label="M1 %"
              disabled
              value={results.weights.m1}
              type="number"
            />
            <TextField
              label="M2 %"
              disabled
              value={results.weights.m2}
              type="number"
            />
            <TextField
              label="M3 %"
              disabled
              value={results.weights.m3}
              type="number"
            />
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              label="M1 discount"
              disabled
              value={results.vbdDiscount.m1.toFixed(2)}
              type="number"
            />
            <TextField
              label="M2 discount"
              disabled
              value={results.vbdDiscount.m2.toFixed(2)}
              type="number"
            />
            <TextField
              label="M3 discount"
              disabled
              value={results.vbdDiscount.m3.toFixed(2)}
              type="number"
            />
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              label="M1 Submission"
              disabled
              value={results.submission.m1}
              type="number"
            />
            <TextField
              label="M2 Submission"
              disabled
              value={results.submission.m2}
              type="number"
            />
            <TextField
              label="M3 Submission"
              disabled
              value={results.submission.m3}
              type="number"
            />
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default ResultSlice;
