import { ApolloError } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { DataGrid, GridColumns, GridValueGetterParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { tabRoutes } from '../../../constants/hinge-connect-constants.constant';
import {
  ruleAssessmentsGridFieldNames,
  ruleAssessmentsTextFields,
} from '../../constants/rule-assessments.constant';
import { ListRuleAssessmentsQuery } from '../../types';
import { NoRuleAssessmentsMessage } from './no-rule-assessments';

const getter =
  (field: string) =>
  (params: GridValueGetterParams): string =>
    params.row[field] || '';

type ListGridParams = {
  searchActive: boolean;
  ruleAssessments: ListRuleAssessmentsQuery['listRuleAssessments']['ruleAssessments'];
  loading?: boolean;
  error?: ApolloError;
};

export const ListRuleAssessmentsGrid = (
  gridParams: ListGridParams,
): JSX.Element => {
  const navigate = useNavigate();
  const { ruleAssessments, loading, error, searchActive } = gridParams;
  const ListRuleAssessmentsColumns: GridColumns = [
    {
      field: ruleAssessmentsGridFieldNames.resourceId,
      headerName: ruleAssessmentsTextFields.resourceId,
      flex: 1,
      sortable: false,
      valueGetter: getter(ruleAssessmentsGridFieldNames.resourceId),
    },
    {
      field: ruleAssessmentsGridFieldNames.userId,
      headerName: ruleAssessmentsTextFields.userId,
      flex: 1,
      sortable: false,
      valueGetter: getter(ruleAssessmentsGridFieldNames.userId),
    },
    {
      field: ruleAssessmentsGridFieldNames.source,
      headerName: ruleAssessmentsTextFields.source,
      flex: 1,
      sortable: false,
      valueGetter: getter(ruleAssessmentsGridFieldNames.source),
    },
    {
      field: ruleAssessmentsGridFieldNames.resourceType,
      headerName: ruleAssessmentsTextFields.resourceType,
      flex: 1,
      sortable: false,
      valueGetter: getter(ruleAssessmentsGridFieldNames.resourceType),
    },
    {
      field: ruleAssessmentsGridFieldNames.createdAt,
      headerName: ruleAssessmentsTextFields.createdAt,
      flex: 1,
      valueGetter: getter(ruleAssessmentsGridFieldNames.createdAt),
      renderCell: (params): string => {
        const date = new Date(params.value as string);
        return date.toLocaleString();
      },
    },
  ];

  return (
    <DataGrid
      components={{
        NoRowsOverlay: NoRuleAssessmentsMessage,
        LoadingOverlay: LinearProgress,
      }}
      componentsProps={{
        noRowsOverlay: { error, searchActive },
      }}
      sx={{
        '& .MuiDataGrid-cell:hover': {
          cursor: 'default',
        },
      }}
      columnBuffer={10}
      disableColumnMenu
      autoHeight
      hideFooter
      columns={ListRuleAssessmentsColumns}
      rows={ruleAssessments}
      isRowSelectable={(): boolean => false}
      onRowClick={(params): void => {
        const { id } = params.row;
        navigate(
          `/${tabRoutes.baseRoute}/${tabRoutes.ruleAssessments}/${id}/view`,
        );
      }}
      loading={loading}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'createdAt',
              sort: 'desc',
            },
          ],
        },
      }}
    />
  );
};
