import { HHTextField } from '@hinge-health/react-component-library';
import { InputAdornment } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getCurrencySymbol } from '../../../../utils/currency-helpers';
import { PackageFormData } from '../custom-package-types';
import { fieldArrayName } from '../form';

export interface PriceProps {
  wholeFormDisabled: boolean | undefined;
  priceType: 'price' | 'initialFee' | 'activityFee';
  label: string;
  index: number;
}

export const PriceInput = ({
  wholeFormDisabled,
  priceType,
  label,
  index,
}: PriceProps): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext<PackageFormData>();
  const [watchedPrice, watchedCurrency] = useWatch({
    name: [`${fieldArrayName}.${index}.${priceType}`, 'currency'],
  });
  return (
    <Controller
      control={control}
      name={`${fieldArrayName}.${index}.${priceType}`}
      render={({ field: { onChange, value } }): JSX.Element => (
        <HHTextField
          hhVariant="variant-bypass"
          label={label}
          disabled={wholeFormDisabled}
          fullWidth
          inputProps={{
            inputMode: 'decimal',
            pattern: '[0-9]*',
            step: '0.01',
            min: '0',
          }}
          InputProps={{
            role: 'textbox',
            startAdornment: (
              <InputAdornment
                position="start"
                disableTypography
                sx={{ color: 'gray' }}
              >
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {getCurrencySymbol(watchedCurrency?.value)}
              </InputAdornment>
            ),
          }}
          error={
            Object.keys(errors?.selectedPackages?.[index]?.[priceType] ?? {})
              .length !== 0
          }
          helperText={errors?.selectedPackages?.[index]?.[priceType]?.message}
          defaultValue={watchedPrice} // fix this with  https://hingehealth.atlassian.net/browse/MINT-8629 (causing errors - can't have default and value with input)
          value={value}
          onChange={onChange}
          type="number"
          InputLabelProps={{ sx: { color: 'gray' } }}
          key={priceType}
        />
      )}
    />
  );
};
