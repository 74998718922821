import {
  HHButton,
  HHDialog,
  HHDialogActions,
  HHDialogContent,
  HHDialogTitle,
} from '@hinge-health/react-component-library';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetContractSubscriptionsLazyQuery } from '../../../../types';
import {
  ActiveStatus,
  generateContractSubscriptionsVariables,
} from '../../../../utils/contract-utils';
import { getContractNotificationMessage } from '../../../utils/contract-notification-utils';
import { WorkRequestNotification } from '../../form/components/work-request-notification';
import {
  CleanAvailablePackages,
  UsableContractType,
} from '../../form/custom-types';
import {
  ContractPackageFormValues,
  SimpleBillingModelType,
} from '../custom-package-types';
import EditContractPackageDetails from './edit-contract-package-details';
import NewContractPackageDetails from './new-contract-package-details';
import { sanityCheck } from './sanity-checks';

export interface PackageFormConfirmationProps {
  formValues: ContractPackageFormValues;
  billingModels: SimpleBillingModelType[];
  availablePackages: CleanAvailablePackages[];
  onAction: (action: 'cancel' | 'submit' | 'void' | 'edit') => void;
  editId?: number;
  contractToEdit?: UsableContractType;
  open: boolean;
  isVoid?: boolean;
  title: string;
  activeStatus: ActiveStatus;
  confirmText?: string;
}

export const PackageFormConfirmation = ({
  formValues,
  availablePackages,
  billingModels,
  onAction,
  editId,
  contractToEdit,
  open,
  isVoid = false,
  title,
  activeStatus,
  confirmText = 'Confirm',
}: PackageFormConfirmationProps): JSX.Element => {
  const params = useParams<{ id: string; contractId: string }>();

  const clientsInsurerId = parseInt(params.id ?? '0', 10);

  const [getContractSubscriptionData, { data, error, loading }] =
    useGetContractSubscriptionsLazyQuery();
  const details = data?.getContractSubscriptions;
  const formattedEndDate = dayjs(formValues.endDate).isValid()
    ? dayjs(formValues.endDate)
    : null;

  useEffect(() => {
    if (open) {
      const variables = generateContractSubscriptionsVariables({
        clientsInsurerId,
        contractId: editId ?? null,
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        contractPackageIds:
          contractToEdit?.contractPackages.map(cp => cp.id) ?? [],
      });
      getContractSubscriptionData({
        variables,
      });
    }
  }, [
    clientsInsurerId,
    contractToEdit?.contractPackages,
    editId,
    formValues.endDate,
    formValues.startDate,
    getContractSubscriptionData,
    open,
  ]);

  const notificationString = error
    ? 'Error getting associated data'
    : getContractNotificationMessage(
        editId ?? null,
        isVoid,
        details,
        formattedEndDate,
      );

  const isSafeToSubmit = sanityCheck(activeStatus, formValues, details, isVoid);

  return (
    <HHDialog
      open={open}
      onClose={(): void => onAction('cancel')}
      fullWidth
      maxWidth="sm"
      keepMounted={false}
    >
      <HHDialogTitle>{title}</HHDialogTitle>
      <HHDialogContent>
        {!isSafeToSubmit && details && details.submissionCount >= 1 ? (
          <WorkRequestNotification
            submissionCount={details?.submissionCount ?? 0}
          />
        ) : null}
        {isVoid || (editId && dayjs(formValues.startDate).isBefore(dayjs())) ? (
          <EditContractPackageDetails
            notificationString={notificationString}
            details={details}
            formValues={formValues}
            isVoid={isVoid}
            loading={loading}
          />
        ) : (
          <NewContractPackageDetails
            notificationString={
              dayjs(formValues.startDate).isBefore(dayjs(), 'day') && !editId
                ? 'Selected start date is a retroactive change and will trigger subscription generation'
                : ''
            }
            formValues={formValues}
            availablePackages={availablePackages}
            billingModels={billingModels}
          />
        )}
      </HHDialogContent>
      <HHDialogActions>
        <HHButton hhVariant="outlined" onClick={(): void => onAction('cancel')}>
          Cancel
        </HHButton>
        <HHButton
          hhVariant="contained"
          color={isVoid ? 'error' : 'primary'}
          onClick={(): void => onAction(isVoid ? 'void' : 'submit')}
          disabled={loading || !isSafeToSubmit}
        >
          {loading ? 'Checking if safe...' : confirmText}
        </HHButton>
      </HHDialogActions>
    </HHDialog>
  );
};
