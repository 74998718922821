import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Provider } from 'jotai';
import { UserHeaderMenu } from '../components/user-header-menu';
import { applicationManagementTool } from '../constants/strings/application-management-tool';
import { ApplicationManagementToolLayout } from '../modules/application-management-tool/components/application-management-tool-layout';
import { ShellProvider } from '../modules/application-management-tool/contexts/shell.context';
import { Admin, useCurrentAdminQuery } from '../types';
import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';

export const ApplicationManagementTool = (): JSX.Element => {
  const { data } = useCurrentAdminQuery();
  const { hingeHealthAuthState } = useHingeHealthSecurityContext();

  let currentAdmin: Partial<Admin> | undefined;
  const loading = hingeHealthAuthState?.isAuthenticated ? false : true;

  const token = hingeHealthAuthState?.accessToken?.accessToken;

  if (hingeHealthAuthState?.accessToken?.claims) {
    const { email, uid: uuid } = hingeHealthAuthState?.accessToken?.claims;
    currentAdmin = { email, uuid, firstName: '', lastName: '' };
  }

  if (data?.admin) {
    currentAdmin = { ...currentAdmin, ...data.admin };
  }

  const shellProps = {
    loading,
    token,
    UserHeaderMenu,
    adminProfile: currentAdmin as Admin,
  };

  return (
    <Provider>
      <Sentry.ErrorBoundary
        fallback={<Box>{applicationManagementTool.error}</Box>}
      >
        <ShellProvider {...shellProps}>
          <ApplicationManagementToolLayout loading={loading} token={token} />
        </ShellProvider>
      </Sentry.ErrorBoundary>
    </Provider>
  );
};
