import {
  HHAccordion,
  HHAccordionDetails,
  HHAccordionSummary,
  HHButton,
  HHCheckbox,
  HHFormControlLabel,
  HHStack,
  HHSwitch,
  HHTypography,
} from '@hinge-health/react-component-library';
import { CheckBoxOutlineBlank, ExpandMore } from '@mui/icons-material';
import { useCallback, useEffect } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useDynamicDialogContext } from '../../../context/dynamic-dialog.context';
import { useSnackbarContext } from '../../../context/snackbar.context';
import {
  ContractType,
  GetContractsByClientInsurerDocument,
  useGetAllProgramIndicationsQuery,
  useUpdateContractPackageMutation,
  useUpdateDisabledProgramIndicationMutation,
} from '../../../types';
import LoadingComponent from '../../utils/loading';
import {
  ContractSettingsFormValues,
  GetDefaultValues,
} from './get-default-values';

const icon = <CheckBoxOutlineBlank fontSize="small" />;

export const DISABLE_ALL_INDICATIONS_TEST_ID = 'disable-all-indications-toggle';
export const PROGRAM_INDICATIONS_FORM_TEST_ID =
  'program-indications-form-test-id';

export interface ContractSettingsFormProps {
  contract: ContractType;
}

export const ContractSettingsForm = ({
  contract,
}: ContractSettingsFormProps): JSX.Element => {
  const { setSnackbarState } = useSnackbarContext();
  const { setDialogContent, closeDialog } = useDynamicDialogContext();
  const { data, loading, error } = useGetAllProgramIndicationsQuery();
  const apiData = data ? data.getProgramIndicationsById : [];

  const {
    id: contractId,
    clientsInsurerId,
    contractPackages,
    packageEnabled,
    disabledProgramIndication: disabledProgramIndications,
  } = contract;

  const isInPersonLegacyPackage =
    !packageEnabled && contractPackages.length >= 1;

  const { formState, handleSubmit, getValues, control, watch, setValue } =
    useForm({
      mode: 'onChange',
      values: GetDefaultValues(
        apiData,
        disabledProgramIndications,
        isInPersonLegacyPackage,
        contractPackages,
      ),
      criteriaMode: 'all',
    });

  const { fields } = useFieldArray({
    control,
    name: 'contractPackages',
  });

  const {
    chronicIndications,
    acuteIndications,
    perisurgicalIndications,
    disableAllIndications,
    enableInPersonVisit,
  } = getValues();

  const watchedChronicIndications = watch('chronicIndications');
  const watchedAcuteIndications = watch('acuteIndications');
  const watchedPerisurgicalIndications = watch('perisurgicalIndications');
  const watchedPackages = watch('contractPackages');

  const setAllIndicationsDisabledHandler = (
    enableDisableBool: boolean,
  ): void => {
    if (packageEnabled) {
      fields.forEach((field, index) => {
        setValue(`contractPackages.${index}.allDisabled`, enableDisableBool);
        setAllDisabledForPackageHandler(enableDisableBool, index);
      });
      return;
    }
    ['Acute', 'Chronic', 'Perisurgical'].forEach(programName => {
      setValue(
        `disableAll${programName}` as keyof ContractSettingsFormValues,
        enableDisableBool,
      );
      setAllDisabledForProgramHandler(enableDisableBool, programName);
    });
  };

  const setAllDisabledForPackageHandler = (
    enableDisableBool: boolean,
    index: number,
  ): void => {
    const packageIndications = fields[index].indications;
    packageIndications?.forEach((indication, ind_index) => {
      setValue(
        `contractPackages.${index}.indications.${ind_index}.enabled`,
        !enableDisableBool,
      );
    });
    checkAllDisabled();
  };

  const setAllDisabledForProgramHandler = (
    enableDisableBool: boolean,
    programName: string,
  ): void => {
    switch (programName) {
      case 'Chronic':
        chronicIndications.forEach((indication, index) => {
          setValue(`chronicIndications.${index}.enabled`, !enableDisableBool);
        });
        break;
      case 'Acute':
        acuteIndications.forEach((indication, index) => {
          setValue(`acuteIndications.${index}.enabled`, !enableDisableBool);
        });
        break;
      case 'Perisurgical':
        perisurgicalIndications.forEach((indication, index) => {
          setValue(
            `perisurgicalIndications.${index}.enabled`,
            !enableDisableBool,
          );
        });
        break;
    }
    checkAllDisabled();
  };

  const checkPackageDisabled = (index: number): void => {
    const packageIndications = watchedPackages?.[index].indications;
    const allDisabled = packageIndications?.every(
      indication => !indication.enabled,
    );
    setValue(`contractPackages.${index}.allDisabled`, allDisabled ?? false);
    checkAllDisabled();
  };

  const checkProgramDisabled = (programName: string): void => {
    switch (programName) {
      case 'Chronic':
        const allDisabled = watchedChronicIndications?.every(
          indication => !indication.enabled,
        );
        setValue('disableAllChronic', allDisabled ?? false);
        break;
      case 'Acute':
        const allDisabledAcute = watchedAcuteIndications?.every(
          indication => !indication.enabled,
        );
        setValue('disableAllAcute', allDisabledAcute ?? false);
        break;
      case 'Perisurgical':
        const allDisabledPerisurgical = watchedPerisurgicalIndications?.every(
          indication => !indication.enabled,
        );
        setValue('disableAllPerisurgical', allDisabledPerisurgical ?? false);
        break;
    }
    checkAllDisabled();
  };

  const checkAllDisabled = (): void => {
    const allDisabledChronic = watchedChronicIndications.every(
      indication => !indication.enabled,
    );
    const allDisabledAcute = watchedAcuteIndications.every(
      indication => !indication.enabled,
    );
    const allDisabledPerisurgical = watchedPerisurgicalIndications.every(
      indication => !indication.enabled,
    );
    const allPackagesDisabled = watchedPackages?.every(p => p.allDisabled);

    const allDisabled = packageEnabled
      ? allPackagesDisabled
      : allDisabledChronic && allDisabledAcute && allDisabledPerisurgical;

    setValue('disableAllIndications', allDisabled ?? false);
  };

  const [updateDisabledProgramIndicationsMutation] =
    useUpdateDisabledProgramIndicationMutation();

  const updateDisabledProgramIndications = useCallback(
    async (updateInput: {
      programIndicationIdentifiers: string[];
      contractPackageId?: number;
      contractId?: number;
    }): Promise<void> => {
      try {
        await updateDisabledProgramIndicationsMutation({
          variables: {
            disabledProgramIndicationInput: updateInput,
          },
          refetchQueries: [
            {
              query: GetContractsByClientInsurerDocument,
              variables: { id: clientsInsurerId, includeVoided: true },
            },
          ],
        });
      } catch (error) {
        throw new Error(
          `Failed to update disabled program indications: ${error}`,
        );
      }
    },
    [clientsInsurerId, updateDisabledProgramIndicationsMutation],
  );

  const [updateContractPackageMutation] = useUpdateContractPackageMutation();

  const updateContractPackage = useCallback(
    async (contractPackageId, updateInput) => {
      try {
        await updateContractPackageMutation({
          variables: {
            contractPackageId,
            contractPackageInput: updateInput,
          },
          refetchQueries: [
            {
              query: GetContractsByClientInsurerDocument,
              variables: { id: clientsInsurerId, includeVoided: true },
            },
          ],
        });
      } catch (error) {
        throw new Error(`Failed to update contract package: ${error}`);
      }
    },
    [updateContractPackageMutation, clientsInsurerId],
  );

  const onFormSubmit: SubmitHandler<ContractSettingsFormValues> = useCallback(
    async (data: ContractSettingsFormValues): Promise<void> => {
      try {
        const updatePromises: Promise<void>[] = [];

        if (packageEnabled) {
          data.contractPackages?.forEach(p => {
            const disabledIndications =
              p.indications
                ?.filter(i => !i.enabled)
                .map(i => i.programIndication) ?? [];
            const updateInput = {
              programIndicationIdentifiers: disabledIndications,
              contractPackageId: p.id,
            };
            updatePromises.push(updateDisabledProgramIndications(updateInput));
          });
        } else {
          const disabledPI = data.chronicIndications
            .filter(c => !c.enabled)
            .map(c => c.programIndication)
            .concat(
              data.acuteIndications
                .filter(a => !a.enabled)
                .map(a => a.programIndication),
              data.perisurgicalIndications
                .filter(p => !p.enabled)
                .map(p => p.programIndication),
            );

          const updateInput = {
            programIndicationIdentifiers: disabledPI,
            contractId,
          };

          updatePromises.push(updateDisabledProgramIndications(updateInput));
        }

        if (isInPersonLegacyPackage) {
          const updateInput = {
            id: contractPackages[0].id,
            billingEnabled: data.enableInPersonVisit,
            packageId: contractPackages[0].package.id,
            billingModelId: contractPackages[0].billingModel.id,
          };
          updatePromises.push(
            updateContractPackage(contractPackages[0].id, updateInput),
          );
        }

        await Promise.all(updatePromises);
        setSnackbarState('Contract settings updated successfully', 'success');
      } catch (error) {
        setSnackbarState('Failed to update contract settings', 'error');
      }
    },
    [
      isInPersonLegacyPackage,
      contractId,
      packageEnabled,
      contractPackages,
      setSnackbarState,
      updateContractPackage,
      updateDisabledProgramIndications,
    ],
  );

  useEffect(() => {
    const { isValid, isDirty, isSubmitting } = formState;
    const footer = (
      <HHStack direction="row" spacing={2}>
        <HHButton hhVariant="text" onClick={closeDialog}>
          Cancel
        </HHButton>
        <HHButton
          hhVariant="contained"
          disabled={!isValid || !isDirty || isSubmitting}
          onClick={handleSubmit(onFormSubmit)}
        >
          Save
        </HHButton>
      </HHStack>
    );

    setDialogContent({ footer });
  }, [closeDialog, formState, handleSubmit, onFormSubmit, setDialogContent]);

  useEffect(() => {
    const { isSubmitSuccessful } = formState;
    if (isSubmitSuccessful) {
      closeDialog();
    }
  }, [closeDialog, formState]);

  if (error) {
    return (
      <HHStack>
        <HHTypography hhVariant="body">
          Unable to load billing settings
        </HHTypography>
      </HHStack>
    );
  }

  return (
    <HHStack>
      {loading ? (
        <LoadingComponent center />
      ) : (
        <HHStack
          spacing={3}
          sx={{ maxWidth: 500 }}
          data-testid={PROGRAM_INDICATIONS_FORM_TEST_ID}
          flex={1}
        >
          <HHFormControlLabel
            data-testid={DISABLE_ALL_INDICATIONS_TEST_ID}
            control={
              <Controller
                control={control}
                name="disableAllIndications"
                render={({ field: { onChange, value } }): JSX.Element => (
                  <HHCheckbox
                    hhVariant="primary"
                    icon={icon}
                    onChange={(): void => {
                      onChange(!disableAllIndications);
                      setAllIndicationsDisabledHandler(!disableAllIndications);
                    }}
                    style={{ marginRight: 8 }}
                    checked={value}
                  />
                )}
              />
            }
            label="Disable all indications"
          />
          {packageEnabled ? (
            <>
              {fields.map((field, index) => (
                <HHAccordion
                  key={field.id}
                  sx={{ border: 'none', boxShadow: 'none' }}
                  defaultExpanded
                >
                  <HHAccordionSummary expandIcon={<ExpandMore />}>
                    <HHTypography hhVariant="h2">
                      {field.name} package
                    </HHTypography>
                  </HHAccordionSummary>
                  <HHAccordionDetails>
                    <HHStack direction="column" spacing={2}>
                      <HHFormControlLabel
                        control={
                          <Controller
                            control={control}
                            name={`contractPackages.${index}.allDisabled`}
                            render={({
                              field: { onChange, value },
                            }): JSX.Element => (
                              <HHCheckbox
                                hhVariant="primary"
                                icon={icon}
                                onChange={(): void => {
                                  onChange(!value);
                                  setAllDisabledForPackageHandler(
                                    !value,
                                    index,
                                  );
                                }}
                                style={{ marginRight: 8 }}
                                checked={value}
                              />
                            )}
                          />
                        }
                        label={`Disable all indications for ${field.name}`}
                      />
                      <HHStack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <HHTypography hhVariant="disabled">
                          Indication
                        </HHTypography>
                        <HHTypography hhVariant="disabled">
                          Billing enabled
                        </HHTypography>
                      </HHStack>
                      {field.indications?.map((indication, ind_index) => (
                        <HHStack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          key={indication.programIndication}
                        >
                          <HHTypography hhVariant="body">
                            {indication.name}
                          </HHTypography>
                          <Controller
                            control={control}
                            name={`contractPackages.${index}.indications.${ind_index}.enabled`}
                            render={({
                              field: { onChange, value },
                            }): JSX.Element => (
                              <HHSwitch
                                hhVariant="primary"
                                icon={icon}
                                onChange={(): void => {
                                  onChange(!value);
                                  checkPackageDisabled(index);
                                }}
                                style={{ marginRight: 8 }}
                                checked={value}
                              />
                            )}
                          />
                        </HHStack>
                      ))}
                    </HHStack>
                  </HHAccordionDetails>
                </HHAccordion>
              ))}
            </>
          ) : (
            <>
              <HHAccordion
                sx={{ border: 'none', boxShadow: 'none' }}
                defaultExpanded
              >
                <HHAccordionSummary expandIcon={<ExpandMore />}>
                  <HHTypography hhVariant="h2">Acute</HHTypography>
                </HHAccordionSummary>
                <HHAccordionDetails>
                  <HHStack direction="column" spacing={2}>
                    <HHFormControlLabel
                      control={
                        <Controller
                          control={control}
                          name="disableAllAcute"
                          render={({
                            field: { onChange, value },
                          }): JSX.Element => (
                            <HHCheckbox
                              hhVariant="primary"
                              icon={icon}
                              onChange={(): void => {
                                onChange(!value);
                                setAllDisabledForProgramHandler(
                                  !value,
                                  'Acute',
                                );
                              }}
                              style={{ marginRight: 8 }}
                              checked={value}
                            />
                          )}
                        />
                      }
                      label="Disable all indications for Acute"
                    />
                    <HHStack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <HHTypography hhVariant="disabled">
                        Indication
                      </HHTypography>
                      <HHTypography hhVariant="disabled">
                        Billing enabled
                      </HHTypography>
                    </HHStack>
                    {acuteIndications?.map((indication, ind_index) => (
                      <HHStack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        key={indication.programIndication}
                      >
                        <HHTypography hhVariant="body">
                          {indication.name}
                        </HHTypography>
                        <Controller
                          control={control}
                          name={`acuteIndications.${ind_index}.enabled`}
                          render={({
                            field: { onChange, value },
                          }): JSX.Element => (
                            <HHSwitch
                              hhVariant="primary"
                              icon={icon}
                              onChange={(): void => {
                                onChange(!value);
                                checkProgramDisabled('Acute');
                              }}
                              style={{ marginRight: 8 }}
                              checked={value}
                            />
                          )}
                        />
                      </HHStack>
                    ))}
                  </HHStack>
                </HHAccordionDetails>
              </HHAccordion>
              <HHAccordion
                sx={{ border: 'none', boxShadow: 'none' }}
                defaultExpanded
              >
                <HHAccordionSummary expandIcon={<ExpandMore />}>
                  <HHTypography hhVariant="h2">Chronic</HHTypography>
                </HHAccordionSummary>
                <HHAccordionDetails>
                  <HHStack direction="column" spacing={2}>
                    <HHFormControlLabel
                      control={
                        <Controller
                          control={control}
                          name="disableAllChronic"
                          render={({
                            field: { onChange, value },
                          }): JSX.Element => (
                            <HHCheckbox
                              hhVariant="primary"
                              icon={icon}
                              onChange={(): void => {
                                onChange(!value);
                                setAllDisabledForProgramHandler(
                                  !value,
                                  'Chronic',
                                );
                              }}
                              style={{ marginRight: 8 }}
                              checked={value}
                            />
                          )}
                        />
                      }
                      label="Disable all indications for Chronic"
                    />
                    <HHStack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <HHTypography hhVariant="disabled">
                        Indication
                      </HHTypography>
                      <HHTypography hhVariant="disabled">
                        Billing enabled
                      </HHTypography>
                    </HHStack>
                    {chronicIndications?.map((indication, ind_index) => (
                      <HHStack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        key={indication.programIndication}
                      >
                        <HHTypography hhVariant="body">
                          {indication.name}
                        </HHTypography>
                        <Controller
                          control={control}
                          name={`chronicIndications.${ind_index}.enabled`}
                          render={({
                            field: { onChange, value },
                          }): JSX.Element => (
                            <HHSwitch
                              hhVariant="primary"
                              icon={icon}
                              onChange={(): void => {
                                onChange(!value);
                                checkProgramDisabled('Chronic');
                              }}
                              style={{ marginRight: 8 }}
                              checked={value}
                            />
                          )}
                        />
                      </HHStack>
                    ))}
                  </HHStack>
                </HHAccordionDetails>
              </HHAccordion>
              <HHAccordion
                sx={{ border: 'none', boxShadow: 'none' }}
                defaultExpanded
              >
                <HHAccordionSummary expandIcon={<ExpandMore />}>
                  <HHTypography hhVariant="h2">Perisurgical</HHTypography>
                </HHAccordionSummary>
                <HHAccordionDetails>
                  <HHStack direction="column" spacing={2}>
                    <HHFormControlLabel
                      control={
                        <Controller
                          control={control}
                          name="disableAllPerisurgical"
                          render={({
                            field: { onChange, value },
                          }): JSX.Element => (
                            <HHCheckbox
                              hhVariant="primary"
                              icon={icon}
                              onChange={(): void => {
                                onChange(!value);
                                setAllDisabledForProgramHandler(
                                  !value,
                                  'Perisurgical',
                                );
                              }}
                              style={{ marginRight: 8 }}
                              checked={value}
                            />
                          )}
                        />
                      }
                      label="Disable all indications for Perisurgical"
                    />
                    <HHStack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <HHTypography hhVariant="disabled">
                        Indication
                      </HHTypography>
                      <HHTypography hhVariant="disabled">
                        Billing enabled
                      </HHTypography>
                    </HHStack>
                    {perisurgicalIndications?.map((indication, ind_index) => (
                      <HHStack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        key={indication.programIndication}
                      >
                        <HHTypography hhVariant="body">
                          {indication.name}
                        </HHTypography>
                        <Controller
                          control={control}
                          name={`perisurgicalIndications.${ind_index}.enabled`}
                          render={({
                            field: { onChange, value },
                          }): JSX.Element => (
                            <HHSwitch
                              hhVariant="primary"
                              icon={icon}
                              onChange={(): void => {
                                onChange(!value);
                                checkProgramDisabled('Perisurgical');
                              }}
                              style={{ marginRight: 8 }}
                              checked={value}
                            />
                          )}
                        />
                      </HHStack>
                    ))}
                  </HHStack>
                </HHAccordionDetails>
              </HHAccordion>
            </>
          )}
          {isInPersonLegacyPackage && (
            <HHAccordion
              sx={{ border: 'none', boxShadow: 'none' }}
              defaultExpanded
            >
              <HHAccordionSummary expandIcon={<ExpandMore />}>
                <HHTypography hhVariant="h2">Fee for service</HHTypography>
              </HHAccordionSummary>
              <HHAccordionDetails>
                <HHStack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <HHTypography hhVariant="disabled">Product</HHTypography>
                  <HHTypography hhVariant="disabled">
                    Billing enabled
                  </HHTypography>
                </HHStack>
                <HHStack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <HHTypography hhVariant="body">In person visit</HHTypography>
                  <Controller
                    control={control}
                    name="enableInPersonVisit"
                    render={({ field: { onChange, value } }): JSX.Element => (
                      <HHSwitch
                        hhVariant="primary"
                        icon={icon}
                        onChange={(): void => onChange(!enableInPersonVisit)}
                        style={{ marginRight: 8 }}
                        checked={value}
                      />
                    )}
                  />
                </HHStack>
              </HHAccordionDetails>
            </HHAccordion>
          )}
        </HHStack>
      )}
    </HHStack>
  );
};
