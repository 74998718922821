import {
  HHBaseTable,
  HHTypography,
} from '@hinge-health/react-component-library';
import { billableActivitiesMap } from '../../constants/strings/contract/form-constants';
import { useBillingSetting } from '../../context/billing.context';
import { useDynamicDrawerContext } from '../../context/dynamic-drawer.context';
import { EngagementsPaginationType } from '../../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../utils/bills-utils';
import { displayDateTime } from '../../utils/date-helper';
import MoreDetailsButton from '../utils/more-details-button';
import EngagementDetails from './engagement-details';

export interface EngagementListProps {
  engagementsData: EngagementsPaginationType;
}

const EngagementList = ({
  engagementsData,
}: EngagementListProps): JSX.Element => {
  const { value: displayLocal } = useBillingSetting('displayDateTimeLocal');
  const { setDrawerContent } = useDynamicDrawerContext();

  return engagementsData.items.length === 0 ? (
    <HHTypography hhVariant="muted">No Engagements</HHTypography>
  ) : (
    <HHBaseTable
      headerRowBottomBorder="1px solid rgba(0, 0, 0, 0.12)"
      bodyRowBorder={{
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}
      bodyTypographyVariant="inherit"
      tableHeaders={[
        { value: 'Event Type', align: 'left' },
        { value: 'Occurred At', align: 'left' },
        { value: 'Program Indication', align: 'left' },
        { value: 'Billable Count', align: 'left' },
        { value: '', align: 'left' },
      ]}
      bodyContent={engagementsData.items.map(engagement => ({
        eventType: {
          value:
            billableActivitiesMap[engagement.eventType] ?? engagement.eventType,
        },
        occurredAt: {
          value: displayDateTime(engagement.occurredAt, {
            displayLocal,
          }),
        },
        programIndication: {
          value: capitalizeFirstLetterAndSpaceSnakeString(
            engagement.programIndication,
          ),
        },
        billableCount: {
          value: engagement.billableCount,
        },
        actions: {
          value: (
            <MoreDetailsButton
              onClick={(): void =>
                setDrawerContent(
                  <EngagementDetails engagement={engagement} />,
                  <HHTypography hhVariant="section-title">
                    {billableActivitiesMap[engagement.eventType] ??
                      engagement.eventType}
                  </HHTypography>,
                )
              }
            />
          ),
        },
      }))}
    />
  );
};

export default EngagementList;
