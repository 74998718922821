import { HHBox, TabsList } from '@hinge-health/react-component-library';
import { TabData } from '@hinge-health/react-component-library/dist/types';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { AMT_MENU_COLORS } from '../constants/colors';
import { AMT_TABS } from '../constants/strings/application-management-tool';
import { useShellContext } from '../contexts/shell.context';
import { UnassignedWorkflowProvider } from '../contexts/unassigned-workflows.context';
import { WorkflowProvider } from '../contexts/workflow.context';
import { ApplicationManagementToolGroupType } from '../custom-types';
import { ApplicationManagementToolInbox } from './application-management-tool-inbox';
import { ApplicationManagementToolMyWorkflows } from './application-management-tool-my-workflows';
import { ApplicationManagementToolTabContainer } from './application-management-tool-tab-container';

const tabStyles = {
  tab: {
    color: AMT_MENU_COLORS.SECONDARY_TEXT_COLOR,
    padding: 0,
    minHeight: 48,
    '&:active,&:focus,&:hover': {
      color: AMT_MENU_COLORS.MENU_LABEL,
    },
  },
  indicator: {
    backgroundColor: AMT_MENU_COLORS.MENU_LABEL,
  },
};

const useStyles = makeStyles(() =>
  createStyles({
    flexContainer: {
      borderBottom: `1px solid ${AMT_MENU_COLORS.MENU_BORDER}`,
      height: 48,
    },
    titleContainer: {
      marginBottom: 20,
      marginTop: 30,
    },
    tabContainer: {
      height: 'calc(100vh - 305px)',
      overflow: 'hidden',
    },
    tabPanel: {
      height: 'calc(100% - 49px)',
      overflow: 'scroll',
    },
  }),
);

export interface AmtTabContentProps {
  tabs: TabData[];
  selectedTab: number;
}

const TitleLookup = [
  'My Assigned Applications', // 0
  'Unassigned Pathway Transitions', // 1
];

const AmtTabContent = ({
  tabs,
  selectedTab,
}: AmtTabContentProps): JSX.Element => {
  const { adminProfile } = useShellContext();
  if (selectedTab === 0) {
    return (
      <WorkflowProvider adminUuid={adminProfile?.uuid || ''}>
        <ApplicationManagementToolMyWorkflows />
      </WorkflowProvider>
    );
  } else if (selectedTab === 1) {
    return (
      <UnassignedWorkflowProvider>
        <ApplicationManagementToolInbox
          groupType={ApplicationManagementToolGroupType.Inbox}
          selectedTab={selectedTab}
        />
      </UnassignedWorkflowProvider>
    );
  } else {
    return <>Hello World! Current tab is {tabs[selectedTab].label}</>;
  }
};

export const ApplicationManagementToolTabs = (): JSX.Element => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs: TabData[] = Object.values(AMT_TABS).map(amtTab => ({
    label: amtTab,
  }));

  return (
    <HHBox className={classes.tabContainer}>
      <TabsList
        tabs={tabs}
        selectedTab={selectedTab}
        className={classes.flexContainer}
        changeHandler={setSelectedTab}
        centered={true}
        styles={tabStyles}
        variant={'fullWidth'}
      />
      <HHBox className={classes.tabPanel}>
        <ApplicationManagementToolTabContainer
          selectedTab={selectedTab}
          title={TitleLookup[selectedTab]}
        >
          <AmtTabContent tabs={tabs} selectedTab={selectedTab} />
        </ApplicationManagementToolTabContainer>
      </HHBox>
    </HHBox>
  );
};
