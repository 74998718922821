import {
  HHBox,
  HHButton,
  HHButtonGroup,
  HHCard,
  HHCircularProgress,
  HHGrid,
  HHLabelSection,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useParams } from 'react-router-dom';
import { CopyToClipboard } from '../../components/copy-to-clipboard';
import {
  buttonLabels,
  notFoundErrorMarker,
  tabRoutes,
} from '../../constants/hinge-connect-constants.constant';
import { omitTypename } from '../../utils/omit-typename';
import { printDateAndTime } from '../../utils/print-date-and-time';
import {
  rulesRoutes,
  rulesTabContent,
  ruleTextFields,
} from '../constants/rules.constant';
import { GetRuleQuery, useGetRuleQuery } from '../types';
import { RuleLoadError } from './rule-load-error';
import { RuleNotFound } from './rule-not-found';

interface RuleDetailsProps {
  id: string;
  rule: GetRuleQuery['getRule'];
}

interface RuleDetailItemProps {
  label: string;
  content: string;
}

const RuleDetailItem = ({
  label,
  content,
}: RuleDetailItemProps): JSX.Element => (
  <HHGrid item xs={12} sm={6}>
    <HHLabelSection hhVariant="text" label={label} content={content} />
  </HHGrid>
);

const RuleDetails = ({ id, rule }: RuleDetailsProps): JSX.Element => (
  <HHBox width="fit-content">
    <HHGrid container spacing={2}>
      <HHGrid item xs={12} sm={6}>
        <HHTypography hhVariant="h1">
          {rulesTabContent.viewRule.title}
        </HHTypography>
      </HHGrid>
      <HHGrid
        item
        xs={12}
        sm={6}
        flexDirection={'row-reverse'}
        display={'flex'}
      >
        <HHButtonGroup hhVariant="contained">
          <HHButton
            hhVariant="variant-bypass"
            href={`/${tabRoutes.baseRoute}/${tabRoutes.rules}/${id}/${rulesRoutes.edit}`}
          >
            {buttonLabels.update}
          </HHButton>
          <HHButton
            hhVariant="variant-bypass"
            href={`/${tabRoutes.baseRoute}/${tabRoutes.rules}/${rulesRoutes.list}`}
          >
            {buttonLabels.backToList}
          </HHButton>
        </HHButtonGroup>
      </HHGrid>
    </HHGrid>

    <HHGrid container spacing={2}>
      <HHGrid item xs={12} sm={6}>
        <HHGrid container spacing={2}>
          <HHGrid item alignSelf="center">
            <CopyToClipboard data={id} />
          </HHGrid>
          <RuleDetailItem label={ruleTextFields.id.label} content={id} />
        </HHGrid>
      </HHGrid>
      <RuleDetailItem
        label={ruleTextFields.ruleName.label}
        content={rule.definition.name}
      />
      <RuleDetailItem
        label={ruleTextFields.source.label}
        content={rule.source}
      />
      <RuleDetailItem
        label={ruleTextFields.resourceTypes.label}
        content={rule.resourceTypes ? rule.resourceTypes?.join(', ') : ''}
      />
      <RuleDetailItem
        label={ruleTextFields.status.label}
        content={rule.status}
      />
      <RuleDetailItem
        label={ruleTextFields.createdAt.label}
        content={printDateAndTime(rule.createdAt)}
      />
      <RuleDetailItem
        label={ruleTextFields.updatedAt.label}
        content={printDateAndTime(rule.updatedAt)}
      />
      <HHGrid container xs={12} spacing={2}>
        <HHGrid item xs={6}>
          <HHTypography hhVariant="input-label">
            {ruleTextFields.definition.label}
          </HHTypography>
          <HHCard
            sx={{
              overflowY: 'scroll',
              minHeight: '40vh',
              maxHeight: '40vh',
              margin: '1rem 0',
              paddingLeft: '2rem',
            }}
          >
            {JSON.stringify(omitTypename(rule.definition), null, 2)
              .split('\n')
              .map((line, index) => {
                const lineNumber = `line-${index + 1}`;
                return <pre key={lineNumber}>{line}</pre>;
              })}
          </HHCard>
          <HHTypography hhVariant="caption">
            {ruleTextFields.definition.helperText}
          </HHTypography>
        </HHGrid>
        <HHGrid item xs={6}>
          <HHTypography hhVariant="input-label">
            {ruleTextFields.actions.label}
          </HHTypography>
          <HHCard
            sx={{
              overflowY: 'scroll',
              maxHeight: '40vh',
              minHeight: '40vh',
              margin: '1rem 0',
              paddingLeft: '2rem',
            }}
          >
            {JSON.stringify(rule.actions?.map(omitTypename), null, 2)
              .split('\n')
              .map((line, index) => {
                const lineNumber = `line-${index + 1}`;
                return <pre key={lineNumber}>{line}</pre>;
              })}
          </HHCard>
          <HHTypography hhVariant="caption">
            {ruleTextFields.actions.helperText}
          </HHTypography>
        </HHGrid>
      </HHGrid>
    </HHGrid>
  </HHBox>
);

const RuleDetailViewContainer = (): JSX.Element => {
  const params = useParams();
  const { data, loading, error, refetch } = useGetRuleQuery({
    variables: { id: params.id as string },
  });

  if (loading) return <HHCircularProgress />;
  if (data) return <RuleDetails rule={data.getRule} id={params.id as string} />;
  if (error?.graphQLErrors[0]?.message.includes(notFoundErrorMarker))
    return <RuleNotFound />;
  return <RuleLoadError handleRetry={refetch} />;
};

export const RuleDetailView = (): JSX.Element => (
  <HHBox paddingY="1rem" display="flex" justifyContent="center">
    <RuleDetailViewContainer />
  </HHBox>
);
