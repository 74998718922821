import {
  HHAlert,
  HHBreadcrumbs,
  HHLink,
  HHStack,
  HHTypography,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { GetSplitTreatmentOnOrOff } from '../../../components/splits';
import { ActiveContractLabels } from '../constants/strings/billing-label-enums';
import { routes } from '../constants/strings/routes';
import { contractPartnershipIdFlag } from '../constants/strings/split';
import {
  ClientInsurerType,
  useGetContractsByClientInsurerQuery,
  useGetContractTemplatesQuery,
} from '../types';
import {
  checkContractsForGaps,
  findActiveContract,
  sortContracts,
} from '../utils/contract-utils';
import { cleanTypeNameGraphql } from '../utils/graphql-utils';
import ClientDiscountsList from './clients/client-discounts';
import ActiveContractDetails from './contract/billing-tool-active-contract-details';
import ContractGrid from './contract/billing-tool-contract-grid';
import { ContractLoadingError } from './contract/contract-loading-error';
import { ContractSettingsButton } from './contract/contract-settings/contract-settings-button';
import { UsableContractType } from './contract/form/custom-types';
import ContractBillingModelLaunch from './main/contract-billing-model-launch';
import LoadingComponent from './utils/loading';

export const MAIN_SECTION_TEST_ID = 'billing-tool-main-section';

export interface BillingMainSectionProps {
  clientInsurerData: ClientInsurerType;
}

const BillingMainSection = ({
  clientInsurerData,
}: BillingMainSectionProps): JSX.Element => {
  const contractsByClientInsurerQuery = useGetContractsByClientInsurerQuery({
    variables: { id: clientInsurerData.id, includeVoided: true },
  });
  const contractTemplatesQuery = useGetContractTemplatesQuery();

  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const uid = hingeHealthAuthState?.accessToken?.claims?.uid.toString();
  const partnershipContractEnabled = GetSplitTreatmentOnOrOff(
    contractPartnershipIdFlag,
    uid,
  );

  if (
    (!contractTemplatesQuery.loading &&
      (contractTemplatesQuery.error || !contractTemplatesQuery.data)) ||
    (!contractsByClientInsurerQuery.loading &&
      (contractsByClientInsurerQuery.error ||
        !contractsByClientInsurerQuery.data))
  ) {
    return <ContractLoadingError />;
  }

  const contracts = sortContracts(
    (contractsByClientInsurerQuery.data
      ?.getContracts as UsableContractType[]) ?? [],
  );
  const activeContract = findActiveContract(
    cleanTypeNameGraphql(contracts),
  ) as UsableContractType;
  const contractTemplates =
    contractTemplatesQuery.data?.getContractTemplates ?? [];

  const gapExists = checkContractsForGaps(cleanTypeNameGraphql(contracts));

  let partnershipIds: number[] | undefined;
  if (partnershipContractEnabled) {
    partnershipIds =
      contracts
        .map(c => c.partnershipId)
        .filter((id): id is number => id !== null && id !== undefined) ??
      undefined;
  } else {
    partnershipIds =
      clientInsurerData.partnerships
        .filter(p => p.partnershipType === 'billing')
        .map(p => p.id) ?? undefined;
  }

  return (
    <HHStack
      direction="column"
      data-testid={MAIN_SECTION_TEST_ID}
      flex={1}
      padding={6}
      sx={{
        overflowY: 'auto',
      }}
    >
      <HHBreadcrumbs sx={{ paddingBottom: 6 }} aria-label="breadcrumb">
        <HHLink color="inherit" href={routes.billing.home} underline="hover">
          Operations Hub
        </HHLink>
        <HHLink color="inherit" href={routes.billing.home} underline="hover">
          Billing
        </HHLink>
        <HHTypography color="text.primary" hhVariant="variant-bypass">
          Clients Insurer {clientInsurerData.id}
        </HHTypography>
      </HHBreadcrumbs>
      {contractsByClientInsurerQuery.loading ||
      contractTemplatesQuery.loading ? (
        <LoadingComponent center />
      ) : (
        <HHStack direction="column" spacing={6}>
          {gapExists && (
            <HHAlert severity="warning" hhVariant="outlined">
              <HHTypography hhVariant="body">
                There is a gap between contracts
              </HHTypography>
            </HHAlert>
          )}
          <HHStack direction="column" spacing={2}>
            <HHStack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={6}
            >
              <HHTypography hhVariant="section-title">
                {ActiveContractLabels.ACTIVE_CONTRACT_LABEL}
              </HHTypography>
              <HHStack direction="row" spacing={2}>
                {activeContract && (
                  <ContractSettingsButton contract={activeContract} />
                )}
                <ContractBillingModelLaunch activeContract={activeContract} />
              </HHStack>
            </HHStack>
            <ActiveContractDetails
              data={activeContract}
              clientInsurerName={clientInsurerData.client?.name}
              templates={contractTemplates}
            />
          </HHStack>
          <HHStack direction="column" spacing={12}>
            <ContractGrid data={contracts} templates={contractTemplates} />
            <ClientDiscountsList
              clientId={clientInsurerData.client.id}
              partnershipIds={partnershipIds}
              disableAddNew
            />
          </HHStack>
        </HHStack>
      )}
    </HHStack>
  );
};

export default BillingMainSection;
