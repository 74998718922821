import { HHStack, HHTypography } from '@hinge-health/react-component-library';

import { capitalizeFirstLetterAndSpaceSnakeString } from '../../../../utils/bills-utils';

export const SelectedHoldTypeCell = ({
  holdReason,
  claimId,
}: {
  holdReason?: string;
  claimId: string;
}): JSX.Element => (
  <HHStack>
    <HHTypography
      color="text.primary"
      hhVariant="variant-bypass"
      fontSize={12}
      fontWeight="bold"
      sx={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {!holdReason
        ? 'No hold present'
        : capitalizeFirstLetterAndSpaceSnakeString(holdReason)}
    </HHTypography>
    <HHTypography color="text.primary" hhVariant="body2">
      {claimId}
    </HHTypography>
  </HHStack>
);
