import { HHButton, HHStack } from '@hinge-health/react-component-library';
import { PropsWithChildren } from 'react';
import { ActiveStatus } from '../../../utils/contract-utils';
import { DiscountType, IDiscount } from '../../../utils/discount-utils';
import { SimplePackageType } from '../../contract/package/custom-package-types';
import { ProgramPackageDisplay } from './program-package-display';

export interface DiscountDetailBaseProps {
  handleEditDiscountClick?: (discountDefinitionId: number) => void;
}

interface DiscountDetailBasePrivateProps extends DiscountDetailBaseProps {
  discountData: IDiscount;
  availablePackages: SimplePackageType[];
}

const showEditButton = (
  status: ActiveStatus,
  discountType: DiscountType,
): boolean =>
  status !== ActiveStatus.Past &&
  discountType !== DiscountType.volumeBasedDiscounts;

export const DISCOUNT_DETAIL_TEST_ID = 'discount-row-test-id';

const DiscountDetailBase = ({
  children,
  handleEditDiscountClick,
  discountData,
  availablePackages,
}: PropsWithChildren<DiscountDetailBasePrivateProps>): JSX.Element => (
  <HHStack
    direction="column"
    spacing={4}
    padding={4}
    sx={{
      textTransform: 'capitalize',
    }}
    data-testid={DISCOUNT_DETAIL_TEST_ID}
  >
    <ProgramPackageDisplay
      discountData={discountData}
      availablePackages={availablePackages}
    />
    {children}
    {handleEditDiscountClick &&
    showEditButton(
      discountData.status,
      discountData.discountType as DiscountType,
    ) ? (
      <HHStack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="end"
      >
        <HHButton
          hhVariant="variant-bypass"
          variant="outlined"
          size="medium"
          color="primary"
          onClick={(): void => {
            handleEditDiscountClick(discountData.discountDefinitionId);
          }}
        >
          Edit
        </HHButton>
      </HHStack>
    ) : null}
  </HHStack>
);

export default DiscountDetailBase;
