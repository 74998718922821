import {
  HHButton,
  HHDateRangePicker,
  HHSelect,
  HHSelectChangeEvent,
  HHStack,
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Dispatch, SetStateAction } from 'react';
import { DateRangePickerValue } from '../../components/date-range-picker';
import { datePicker } from '../../constants/hinge-connect-constants.constant';
import {
  assessmentTasksTabContent,
  AssessmentTaskStatus,
} from '../constants/assessment-tasks.constant';

interface AssessmentTasksToolbarProps {
  dateRange: [DateRangePickerValue, DateRangePickerValue];
  setDateRange: Dispatch<
    SetStateAction<[DateRangePickerValue, DateRangePickerValue]>
  >;
  selectedStatus: AssessmentTaskStatus | '';
  setSelectedStatus: Dispatch<SetStateAction<AssessmentTaskStatus | ''>>;
}

export const AssessmentTasksToolbar = ({
  dateRange,
  setDateRange,
  selectedStatus,
  setSelectedStatus,
}: AssessmentTasksToolbarProps): JSX.Element => {
  const statusOptions = Object.values(AssessmentTaskStatus).map(status => ({
    value: status,
    displayName: status,
  }));

  const handleStatusChange = (e: HHSelectChangeEvent): void => {
    setSelectedStatus(e.target.value as AssessmentTaskStatus);
  };

  return (
    <HHStack direction="row" spacing={6} alignItems="center">
      <HHTypography hhVariant="h3">
        {assessmentTasksTabContent.filters.label}
      </HHTypography>
      <HHSelect
        data-testid="status-select"
        label="Status"
        selectOptions={[{ value: '', displayName: '' }, ...statusOptions]}
        onChange={handleStatusChange}
        initialSelectValue={selectedStatus}
      />
      <HHDateRangePicker
        localeText={{
          start: datePicker.earliest,
          end: datePicker.latest,
        }}
        onChange={(data): void => {
          setDateRange(data);
        }}
        value={dateRange}
        renderInput={(startProps, endProps): JSX.Element => (
          <HHStack direction="row" spacing={6}>
            <HHTextField
              data-testid="earliest-filter"
              hhVariant="variant-bypass"
              {...startProps}
            />
            <HHTextField
              data-testid="latest-filter"
              hhVariant="variant-bypass"
              {...endProps}
            />
          </HHStack>
        )}
      />
      <HHButton
        data-testid="clear-button"
        hhVariant="variant-bypass"
        disabled={!selectedStatus && !dateRange[0] && !dateRange[1]}
        onClick={(): void => {
          setSelectedStatus('');
          setDateRange([null, null]);
        }}
      >
        {assessmentTasksTabContent.filters.clear}
      </HHButton>
    </HHStack>
  );
};
