import {
  HHAccordion,
  HHAccordionDetails,
  HHAccordionSummary,
  HHAlert,
  HHBox,
  HHButton,
  HHRadio,
  HHRadioGroup,
  HHTypography,
} from '@hinge-health/react-component-library';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  AMT_BUTTON_COLORS,
  ERROR_COLOR,
  NOTE_COLOR,
} from '../../constants/colors';
import { useGetWorkflowContext } from '../../contexts/get-workflow.context';
import { useShellContext } from '../../contexts/shell.context';
import {
  AMT_PATHWAY_TRANSITION_STATES,
  PathwayTransitionDecoratedWorkflow,
} from '../../custom-types';
import {
  GetWorkflowsByFilterDocument,
  useCreateTemporaryCoverageMutation,
  useCreateTransitionMutation,
} from '../../types';
import { statesMap } from './states';

export const ERROR_STRINGS = {
  REF_IS_NOT_DEFINED:
    'An error occured, please refresh the page and try again.',
  WORKFLOW_NOT_DEFINED:
    'A workflow was not selected. select one and try again.',
  TRANSITION_NOT_SELECTED: 'Please select a Transition.',
  CREATE_TEMPORARY_COVERAGE:
    'There was an error creating your temporary coverage. Please create it manually',
  CREATE_TRANSITION:
    'Moving the workflow to the next state failed. Please try again',
};

type AmtMemberTransitionAccordionProps = {
  onSubmit?: () => void;
  onComplete?: () => void;
};

export const AmtMemberTransitionAccordion = ({
  onSubmit,
}: AmtMemberTransitionAccordionProps): JSX.Element | null => {
  const formRef = useRef<HTMLFormElement>(null);
  const { workflow } = useGetWorkflowContext();
  const { adminProfile } = useShellContext();
  const availableTransitions = workflow?.availableTransitions || [];
  const [createTransition, { error: createTransitionError }] =
    useCreateTransitionMutation();
  const [createTemporaryCoverage, { error: createTemporaryCoverageError }] =
    useCreateTemporaryCoverageMutation();
  const [error, setError] = useState('');

  useEffect(() => {
    if (createTransitionError) {
      setError(
        `${ERROR_STRINGS.CREATE_TRANSITION}: ${createTransitionError?.message}`,
      );
    }
  }, [createTransitionError]);

  useEffect(() => {
    if (createTemporaryCoverageError) {
      setError(
        `${ERROR_STRINGS.CREATE_TEMPORARY_COVERAGE}: ${createTemporaryCoverageError?.message}`,
      );
    }
  }, [createTemporaryCoverageError]);

  const updateViewedState = useCallback(
    async (
      workflow: PathwayTransitionDecoratedWorkflow,
      toState: string,
    ): Promise<void> => {
      let errorOccured = false;

      if (!workflow.viewed) {
        try {
          if (
            adminProfile &&
            toState === AMT_PATHWAY_TRANSITION_STATES.ADD_COACH_NOTE
          ) {
            await createTemporaryCoverage({
              variables: {
                userUuid: workflow.patientUuid,
                adminUuid: adminProfile.uuid,
              },
            });
          }
        } catch {
          errorOccured = true;
        }

        try {
          await createTransition({
            variables: {
              createTransitionInputDto: {
                id: workflow.id,
                toState,
              },
              workflowType: workflow.type,
            },
            refetchQueries: [GetWorkflowsByFilterDocument],
          });
        } catch {
          errorOccured = true;
        }
      }
      if (!errorOccured) setError('');
    },
    [createTransition, createTemporaryCoverage, adminProfile],
  );

  const handleClick = (e: FormEvent): void => {
    e.preventDefault();

    if (!formRef?.current || !workflow) {
      setError(
        workflow
          ? ERROR_STRINGS.REF_IS_NOT_DEFINED
          : ERROR_STRINGS.WORKFLOW_NOT_DEFINED,
      );
      return;
    }

    const formData = new FormData(formRef?.current);
    const state = formData.get('desiredAction')?.toString();

    if (state) {
      updateViewedState(workflow, state);
      onSubmit?.();
    } else {
      setError(ERROR_STRINGS.TRANSITION_NOT_SELECTED);
    }
  };

  return availableTransitions?.length ? (
    <HHAccordion defaultExpanded>
      <HHAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <HHTypography hhVariant="h2">Actions</HHTypography>
      </HHAccordionSummary>
      <HHAccordionDetails>
        <form ref={formRef}>
          {error && (
            <HHAlert
              hhVariant="variant-bypass"
              icon={
                (): null => null //done so I don't have an icon
              }
              sx={{ color: NOTE_COLOR, background: ERROR_COLOR }}
            >
              {error}
            </HHAlert>
          )}
          <HHRadioGroup>
            {(workflow?.availableTransitions || []).map(
              (transition): JSX.Element => (
                <HHBox key={transition.to}>
                  <label>
                    <HHRadio
                      name="desiredAction"
                      hhVariant="variant-bypass"
                      sx={{
                        color: AMT_BUTTON_COLORS.BUTTON_BACKGROUND_COLOR,
                      }}
                      value={transition.to}
                      required
                    />
                    {statesMap.get(transition.to) || transition.to}
                  </label>
                </HHBox>
              ),
            )}
          </HHRadioGroup>
          <HHButton hhVariant="contained" onClick={handleClick}>
            Submit
          </HHButton>
        </form>
      </HHAccordionDetails>
    </HHAccordion>
  ) : null;
};
