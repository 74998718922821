import {
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Autocomplete } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useGetCurrencyCodesQuery } from '../../../../types';
import { getCurrencySymbol } from '../../../../utils/currency-helpers';
import { DEFAULT_FORM_CURRENCY } from '../../package/custom-package-types';
import { ContractPackageFormLabelText } from '../../package/form';

export interface CurrencySelectProps {
  wholeFormDisabled: boolean | undefined;
  isPackageForm?: boolean;
  index?: number;
}

export const CURRENCY_SELECT_ID = 'currency-select';

export type CurrencyOption = {
  label: string;
  value: string;
};

export const CurrencySelect = ({
  wholeFormDisabled,
}: CurrencySelectProps): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { data, error } = useGetCurrencyCodesQuery();

  if (error) {
    return (
      <HHTypography hhVariant="body1" color="error">
        Currency unavailable
      </HHTypography>
    );
  }

  const formatCurrencyOptions = data?.getCurrencyCodes.map(currency => ({
    label: `${currency.value} ${getCurrencySymbol(currency.value)} `,
    value: currency.value,
  })) ?? [DEFAULT_FORM_CURRENCY];

  return (
    <Controller
      control={control}
      name="currency"
      render={({ field: { onChange, value, ref } }): JSX.Element => (
        <Autocomplete
          ref={ref}
          key="currency"
          fullWidth
          data-testid={CURRENCY_SELECT_ID}
          disabled={wholeFormDisabled}
          options={formatCurrencyOptions}
          disableClearable
          onChange={(_, newValue): void => onChange(newValue)}
          value={value}
          renderInput={(params): JSX.Element => (
            <HHTextField
              {...params}
              label={ContractPackageFormLabelText.CURRENCY}
              hhVariant="variant-bypass"
              fullWidth
              variant="outlined"
              error={Object.keys(errors?.currency ?? {}).length !== 0}
              name="currency"
              helperText={errors.currency?.message}
              sx={{ '& .MuiInputLabel-root': { color: 'gray' } }}
            />
          )}
        />
      )}
    />
  );
};
