import {
  HHButton,
  HHLoadingButton,
  HHSnackbarAlert,
  HHStack,
  HHTextField,
} from '@hinge-health/react-component-library';
import { FormEvent, useEffect, useState } from 'react';
import { HCModal } from '../../components/hinge-connect-modal';
import { buttonLabels } from '../../constants/hinge-connect-constants.constant';
import { useSnackbar } from '../../hooks/use-snackbar';
import {
  tagSnackbars,
  tagsTabContent,
  tagTextFields,
} from '../constants/tags.constant';
import { useCreateTag } from '../hooks/use-create-tag';
import { CreateTagInput } from '../types';

interface CreateTagFormProps {
  handleClose: () => void;
  onSubmit: (createTagInput: CreateTagInput) => void;
  loading: boolean;
}

const CreateTagForm = ({
  handleClose,
  onSubmit,
  loading,
}: CreateTagFormProps): JSX.Element => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    onSubmit({
      name,
      description,
    });
  };

  useEffect(() => {
    setSubmitEnabled(!!name && /^[a-zA-Z0-9-_]+$/.test(name) && !!description);
  }, [name, description]);

  return (
    <form onSubmit={handleSubmit}>
      <HHStack width="40vw" maxWidth="500px">
        <HHTextField
          hhVariant="variant-bypass"
          margin="normal"
          label={tagTextFields.tagName.label}
          inputProps={{ 'aria-label': tagTextFields.tagName.label }}
          value={name}
          helperText={tagTextFields.tagName.helperText}
          error={!!name && !/^[a-zA-Z0-9-_]+$/.test(name)}
          onChange={(e): void => setName(e.target.value)}
          required={true}
        />
        <HHTextField
          hhVariant="variant-bypass"
          margin="normal"
          label={tagTextFields.description.label}
          inputProps={{ 'aria-label': tagTextFields.description.label }}
          value={description}
          multiline={true}
          minRows={3}
          onChange={(e): void => setDescription(e.target.value)}
        />

        <HHStack direction="row" spacing={4} justifyContent="right">
          <HHButton
            hhVariant="text"
            type="button"
            sx={{ margin: '1rem' }}
            onClick={handleClose}
          >
            {buttonLabels.cancel}
          </HHButton>
          <HHLoadingButton
            hhVariant="variant-bypass"
            variant="contained"
            type="submit"
            disabled={!submitEnabled}
            loading={loading}
            data-testid="submit-button"
          >
            {buttonLabels.create}
          </HHLoadingButton>
        </HHStack>
      </HHStack>
    </form>
  );
};

interface CreateTagModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const CreateTagModal = ({
  isOpen,
  handleClose,
}: CreateTagModalProps): JSX.Element => {
  const { createTag, data, loading, error, errorMessages } =
    useCreateTag(handleClose);
  const { snackbar, handleClose: handleSnackbarClose } = useSnackbar({
    data,
    error,
    errorMessages,
    successMessage: tagSnackbars.create,
  });

  const onSubmit = (createTagInput: CreateTagInput): void => {
    createTag({ variables: { createTagInput } });
  };

  return (
    <>
      <HHSnackbarAlert
        message={snackbar.message}
        severity={snackbar.severity}
        verticalAlignment="top"
        horizontalAlignment="center"
        hhVariant="standard"
        open={snackbar.open}
        onClose={handleSnackbarClose}
        autoHideTime={snackbar.severity === 'success' ? 3000 : undefined}
      />
      <HCModal
        title={tagsTabContent.createTag.title}
        isOpen={isOpen}
        handleClose={handleClose}
        component={<CreateTagForm {...{ handleClose, onSubmit, loading }} />}
      />
    </>
  );
};
