import { HHSelectOption } from '@hinge-health/react-component-library';
import {
  AutoCompleteOption,
  AutoCompleteOptionWithValidation,
  CriterionOperator,
} from '../rules/constants/types';
import { RuleStatus } from '../rules/types';

export const buttonLabels = {
  new: 'New',
  create: 'Create',
  update: 'Update',
  delete: 'Delete',
  tryAgain: 'Try Again',
  viewAll: 'View All',
  backToList: 'Back to List',
  cancel: 'Cancel',
  confirm: 'Confirm',
  copy: 'Copy',
};

export const tabLabels = {
  rules: 'Rules',
  tags: 'Tags',
  assessmentTasks: 'Assessment Tasks',
  userTags: 'User Tags',
  ruleAssessments: 'Rule Assessments',
  healthRecords: 'Health Records',
};

export const tabRoutes = {
  baseRoute: 'hinge-connect',
  rules: 'rules',
  tags: 'tags',
  assessmentTasks: 'assessment-tasks',
  userTags: 'user-tags',
  ruleAssessments: 'rule-assessments',
  healthRecords: 'health-records',
};

export const titles = {
  pageTitle: 'Hinge Connect',
};

export const genericError = 'An error occurred';

export const notFoundErrorMarker = 'Not Found';

export const datePicker = {
  startDate: 'Start Date',
  endDate: 'End Date',
  to: 'to',
  earliest: 'Earliest',
  latest: 'Latest',
};

export const dataSources = {
  HEALTH_GORILLA: 'HEALTH_GORILLA',
  BCBS_TN: 'BCBS_TN',
  AQ: 'AQ',
  MEMBER_PREDICTIONS: 'MEMBER_PREDICTIONS',
  USER_DEMOGRAPHIC: 'USER_DEMOGRAPHIC',
  BCBS_AL: 'BCBS_AL',
  INSIGHTS: 'INSIGHTS',
  UHC: 'UHC',
  QUANTUMHEALTH: 'QUANTUMHEALTH',
};

export const ruleOperators = {
  ALL: 'all',
  ANY: 'any',
};

export const criteriaOperatorsMap: Map<string, CriterionOperator> = new Map([
  [
    'equals',
    {
      label: 'Equals',
      description:
        'Looks for a value that is strictly equal to the provided value',
    },
  ],
  [
    'contains',
    {
      label: 'Contains',
      values: [
        {
          label: 'Any String',
          value: '#string',
          description: 'Looks for any string to be present',
        },
        {
          label: 'Any Number',
          value: '#number',
          description: 'Looks for any number to be present',
        },
        {
          label: 'Number Greater Than',
          value: '#number>',
          validation: /^#number>\d+$/,
          description: 'Looks for a number greater than the provided value',
        },
        {
          label: 'Number Less Than',
          value: '#number<',
          validation: /^#number<\d+$/,
          description: 'Looks for a number less than the provided value',
        },
        {
          label: 'Number Grater Than or Equal To',
          value: '#number>=',
          validation: /^#number>=\d+$/,
          description:
            'Looks for a number greater than or equal to the provided value',
        },
        {
          label: 'Number Less Than or Equal To',
          value: '#number<=',
          validation: /^#number<=\d+$/,
          description:
            'Looks for a number less than or equal to the provided value',
        },
        {
          label: 'In Range',
          value: '#range(x,y)',
          validation: /^#range\((\d+),(\d+)\)$/,
          description:
            'Looks for a number within the provided range (inclusive). Example: (start, end)',
        },
      ],
      description: 'Looks for a value that contains the provided value',
    },
  ],
]);

export const sourceSelectOptions = Object.values(dataSources).map(item => ({
  displayName: item,
  value: item,
}));

export const sourceAutoCompleteOptions = Object.values(dataSources).map(
  item => ({
    label: item,
    value: item,
  }),
);
export const operatorSelectOptions = Object.values(ruleOperators).map(item => ({
  displayName: item,
  value: item,
}));

export const statusOptions: HHSelectOption[] = Object.values(RuleStatus)
  .filter(status => status !== RuleStatus.Deleted)
  .map(item => ({
    displayName: item,
    value: item,
  }));

export const getCriteriaOperatorValueCompleteOptions = (
  label: string,
): AutoCompleteOptionWithValidation[] => {
  if (criteriaOperatorsMap.has(label)) {
    return (
      criteriaOperatorsMap.get(label)?.values?.map(item => ({
        label: item.label,
        value: item.value,
        validation: item.validation,
      })) ?? ([] as AutoCompleteOptionWithValidation[])
    );
  }
  return [];
};

export const getCriteriaOperatorsCompleteOptions = (): AutoCompleteOption[] =>
  Array.from(criteriaOperatorsMap.keys()).map(item => ({
    label: item,
    value: item,
  })) ?? [];
