import { ClosingActions } from '../../modules/application-management-tool/custom-types';

export const applicationManagementTool = {
  menuLabel: 'Application Management Tool',
  text: 'Application Management Tool',
  error: 'Unable to load Application Management Tool',
};

export const CLOSING_ACTIONS_TEXT: Record<ClosingActions, string> = {
  [ClosingActions.AcceptNewPathway]: 'Member Accepts',
  [ClosingActions.RejectNewPathway]: 'Member Rejects',
};

export const CLOSING_ACTIONS_VALUE: Record<ClosingActions, string> = {
  [ClosingActions.AcceptNewPathway]: 'accept-new-pathway',
  [ClosingActions.RejectNewPathway]: 'reject-new-pathway',
};

export const CLOSING_ACTIONS_HEADER = 'Closing actions';
