import { HHStack } from '@hinge-health/react-component-library';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Navigate, Route, Routes } from 'react-router-dom';
import BillingToolHeader from './components/headers/billing-tool-header';
import { routes } from './constants/strings/routes';
import { BillingProvider } from './context/billing.context';
import { DynamicDialogProvider } from './context/dynamic-dialog.context';
import { SnackbarProvider } from './context/snackbar.context';
import BillsWithHolds from './pages/bill-holds';
import BillToolRunPage from './pages/bill-tool-run';
import BillingToolHomePage from './pages/billing-tool-home';
import ClientByIdPage from './pages/client-id';
import ClientInsurerBatchContractCreate from './pages/client-insurer-batch-create';
import ClientInsurerBatchContractCreateStatus from './pages/client-insurer-batch-create-status';
import ClientInsurerByIdPage from './pages/client-insurer-id';
import ClientInsurerEditContract from './pages/client-insurer-id-edit-contract';
import ClientsInsurerIdEditContractPackage from './pages/client-insurer-id-edit-contract-package';
import ClientInsurerNewContract from './pages/client-insurer-id-new-contract';
import ClientInsurerNewContractPackage from './pages/client-insurer-id-new-contract-package';
import DiscountCalculator from './pages/discount-calculator';
import UserByIdPage from './pages/user-id';

export const BillingTool = (): JSX.Element => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <BillingProvider>
      <SnackbarProvider>
        <DynamicDialogProvider>
          <HHStack
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
            sx={{ backgroundColor: '#fff' }}
          >
            <BillingToolHeader />
            <Routes>
              <Route index element={<BillingToolHomePage />} />
              {/* /client-insurer */}
              <Route path={routes.billing.clientInsurerPath}>
                <Route
                  index
                  element={<Navigate to={routes.billing.home} replace />}
                />
                <Route
                  path={routes.billing.clientInsurerId}
                  element={<ClientInsurerByIdPage />}
                />
                <Route
                  path={routes.billing.fullNewContractPath}
                  element={<ClientInsurerNewContract />}
                />
                <Route
                  path={routes.billing.fullNewContractPackagePath}
                  element={<ClientInsurerNewContractPackage />}
                />
                <Route
                  path={routes.billing.fullEditContractPackagePath}
                  element={<ClientsInsurerIdEditContractPackage />}
                />
                <Route
                  path={routes.billing.fullEditContractPath}
                  element={<ClientInsurerEditContract />}
                />
                <Route
                  path={routes.billing.fullBatchContractCreateStatus}
                  element={<ClientInsurerBatchContractCreateStatus />}
                />
                <Route
                  path={routes.billing.batchContractCreate}
                  element={<ClientInsurerBatchContractCreate />}
                />
              </Route>
              {/* /client */}
              <Route path={routes.billing.clientPath}>
                <Route
                  index
                  element={<Navigate to={routes.billing.home} replace />}
                />
                <Route
                  path={routes.billing.clientId}
                  element={<ClientByIdPage />}
                />
              </Route>
              {/* /user */}
              <Route path={routes.billing.userPath}>
                <Route
                  index
                  element={<Navigate to={routes.billing.home} replace />}
                />
                <Route path={routes.billing.userId}>
                  <Route
                    index
                    element={<Navigate to={'subscriptions'} replace />}
                  />
                  <Route path="subscriptions" element={<UserByIdPage />} />
                  <Route path="engagements" element={<UserByIdPage />} />
                </Route>
              </Route>
              {/* /bills */}
              <Route path={routes.billing.billsPath}>
                <Route
                  index
                  element={<Navigate to={routes.billing.home} replace />}
                />
                <Route
                  path={routes.billing.billsHolds}
                  element={<BillsWithHolds />}
                />
              </Route>
              <Route
                path={routes.billing.discountCalculator}
                element={<DiscountCalculator />}
              />
              <Route path="tool" element={<BillToolRunPage />} />
              <Route path="*" element={<Navigate to={routes.billing.home} />} />
            </Routes>
          </HHStack>
        </DynamicDialogProvider>
      </SnackbarProvider>
    </BillingProvider>
  </LocalizationProvider>
);
