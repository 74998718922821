/* eslint-disable react-hooks/exhaustive-deps */
import {
  HHStack,
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useEffect, useState } from 'react';

interface ClientInformationSectionProps {
  onChange: (data: { [key: string]: unknown }) => void;
  formData?: { [key: string]: unknown };
}

const ClientInformationSection = (
  props: ClientInformationSectionProps,
): JSX.Element => {
  const entityTags =
    (props.formData?.entityTags as {
      client: string;
      insurer: string;
      partnership: string;
    }) || undefined;
  const [client, setClient] = useState<string>(entityTags?.client || '');
  const [insurer, setInsurer] = useState<string>(entityTags?.insurer || '');
  const [partnership, setPartnership] = useState<string>(
    entityTags?.partnership || '',
  );

  useEffect(() => {
    const formdata = {
      entityTags: {
        client: client || '',
        insurer: insurer || '',
        partnership: partnership || '',
      },
    };
    props.onChange(formdata);
  }, [client, insurer, partnership]);

  return (
    <HHStack direction="column" spacing={'20px'}>
      <HHTypography hhVariant={'h2'}>Client Information</HHTypography>
      <HHTextField
        hhVariant="outlined"
        value={client}
        label={'Client'}
        onChange={(e): void => setClient(e.target.value)}
      />
      <HHTextField
        hhVariant="outlined"
        value={insurer}
        label={'Insurer'}
        onChange={(e): void => setInsurer(e.target.value)}
      />
      <HHTextField
        hhVariant="outlined"
        value={partnership}
        label={'Partnership'}
        onChange={(e): void => setPartnership(e.target.value)}
      />
    </HHStack>
  );
};
export default ClientInformationSection;
