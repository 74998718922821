import { ApolloError, gql, useMutation } from '@apollo/client';

export const DELETE_USER_TAG = gql`
  mutation DeleteUserTag($id: String!) {
    deleteUserTag(id: $id) {
      id
      userId
      tagId
      tagName
      source
      resourceId
      occurredAt
      createdAt
    }
  }
`;

export const useDeleteUserTag = (
  onCompleted?: () => void,
): {
  deleteUserTag: (id: string) => Promise<void>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [deleteUserTagMutation, { loading, error }] = useMutation(
    DELETE_USER_TAG,
    {
      onCompleted,
    },
  );

  const deleteUserTag = async (id: string): Promise<void> => {
    try {
      const response = await deleteUserTagMutation({ variables: { id } });
      if (!response.data?.deleteUserTag) {
        throw new Error('Failed to delete user tag');
      }
    } catch (err) {
      throw new Error('Failed to delete user tag');
    }
  };

  return { deleteUserTag, loading, error };
};
