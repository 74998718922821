import { HHAlert, HHTypography } from '@hinge-health/react-component-library';
import { BillPanelMode } from './bill-panel';

export interface PanelAlertProps {
  drawerMode: BillPanelMode;
  hasSameHoldType: boolean;
  clientInsurerChangesMatch: boolean;
}

export const PanelAlert = ({
  drawerMode,
  hasSameHoldType,
  clientInsurerChangesMatch,
}: PanelAlertProps): JSX.Element =>
  drawerMode === BillPanelMode.Archive ? (
    <HHAlert severity="warning" title="Archive Bill" hhVariant="outlined">
      <HHTypography variant="h4" hhVariant="h4">
        Archiving bills
      </HHTypography>
      Review carefully, archived bills will be set as non-billable
    </HHAlert>
  ) : (
    (!hasSameHoldType && (
      <HHAlert severity="error" title="Hold Types" hhVariant="filled">
        <HHTypography variant="h4" hhVariant="h4">
          Hold types do not match
        </HHTypography>
        You can only release bills with the same hold type
      </HHAlert>
    )) ||
    (!clientInsurerChangesMatch && (
      <HHAlert
        severity="error"
        title="Client/Insurer Change"
        hhVariant="filled"
      >
        <HHTypography variant="h4" hhVariant="h4">
          Unable to release
        </HHTypography>
        Client Insurer change does not match
      </HHAlert>
    )) || (
      <HHAlert severity="warning" title="Release Bill" hhVariant="outlined">
        <HHTypography variant="h4" hhVariant="h4">
          Review bills to be released
        </HHTypography>
        When released the bills will be submitted in the next round of
        submissions, if client or carrier change, please select a client insurer
        to release the bill
      </HHAlert>
    )
  );
