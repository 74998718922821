export const isValidJson = (json: string): boolean => {
  try {
    JSON.parse(json);
    return true;
  } catch (error) {
    return false;
  }
};

export const getJsonErrorDetails = (
  json: string,
): { isValid: boolean; errorDetails: string | null } => {
  try {
    JSON.parse(json);
    return { isValid: true, errorDetails: null };
  } catch (error: unknown) {
    return { isValid: false, errorDetails: (error as Error).message };
  }
};
