export const titles = {
  pageTitle: 'Member Data Services',
};

export const tabLabels = {
  fileConfigurations: 'File Configurations',
  targetedEnrollment: 'Targeted Enrollment',
};

export const tabRoutes = {
  baseRoute: 'member-data-services',
  fileConfigurations: 'file-configurations',
  targetedEnrollment: 'targeted-enrollment',
};
