import {
  HHAlert,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useSnackbarContext } from '../../context/snackbar.context';
import {
  SubscriptionType,
  useGetBillsBySubscriptionIdQuery,
  useGetContractsByClientInsurerQuery,
} from '../../types';
import LoadingComponent from '../utils/loading';
import BillList from './bill-list';

export interface BillsBySubscriptionIdProps {
  subscriptionData: SubscriptionType;
}

const BillsBySubscriptionId = ({
  subscriptionData,
}: BillsBySubscriptionIdProps): JSX.Element => {
  const { setSnackbarState } = useSnackbarContext();
  const { data, loading, error } = useGetBillsBySubscriptionIdQuery({
    variables: { subscriptionId: subscriptionData.id },
  });
  const contractsByClientInsurerQuery = useGetContractsByClientInsurerQuery({
    variables: { id: subscriptionData.clientsInsurerId, includeVoided: true },
  });

  const contractCurrency =
    contractsByClientInsurerQuery.data?.getContracts.find(
      ({ id }) => id === subscriptionData.contractId,
    )?.currency;

  if (loading) {
    return <LoadingComponent center />;
  }

  if (error) {
    return (
      <HHAlert
        hhVariant="outlined"
        severity="error"
        children={
          <HHTypography hhVariant="body">
            There was an error loading subscription bills
          </HHTypography>
        }
      ></HHAlert>
    );
  }

  if (!data || !data.getBillsBySubscriptionId) {
    return (
      <HHStack spacing={2} overflow="hidden">
        <HHTypography hhVariant="body">
          Subscription bills not found
        </HHTypography>
      </HHStack>
    );
  }

  if (!contractCurrency && !contractsByClientInsurerQuery.loading) {
    setSnackbarState('Unable to load currency information', 'info', 3000);
  }

  return (
    <BillList
      billsData={data.getBillsBySubscriptionId}
      currency={contractCurrency || null}
    />
  );
};

export default BillsBySubscriptionId;
