import {
  HHAlert,
  HHBox,
  HHButton,
  HHGrid,
  HHTextField,
  HHAccordion,
  HHAccordionSummary,
  HHAccordionDetails,
  HHTypography,
} from '@hinge-health/react-component-library';
import { createStyles, makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { useState } from 'react';
import {
  BUTTON_DISABLED_TEXT,
  MEMBER_DETAILS_COLORS,
} from '../../constants/colors';
import { AMT_THEME } from '../../constants/theme';
import { useGetWorkflowContext } from '../../contexts/get-workflow.context';
import { useShellContext } from '../../contexts/shell.context';
import { GetWorkflowDocument, useUpdateWorkflowMutation } from '../../types';
import { fullNameToFirstNameAndLastInitial } from '../../utils/full-name';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const AmtMemberTransitionNotesAccordion = (): JSX.Element | null => {
  const { workflow } = useGetWorkflowContext();
  const { adminProfile } = useShellContext();
  const [updateWorkflowMutation] = useUpdateWorkflowMutation();
  const notes: Note[] = workflow?.customFields?.notes || [];

  const handleSubmit = async (comment: string): Promise<void> => {
    if (!workflow || !comment) {
      return;
    }

    const newData = {
      name: `${adminProfile?.firstName} ${adminProfile?.lastName}`,
      comment,
      timestamp: new Date().toISOString(),
    };
    await updateWorkflowMutation({
      variables: {
        updateWorkflowInputDto: {
          workflowId: workflow.id,
          customFields: {
            applicationUuid: workflow.customFields.applicationUuid,
            notes: notes.concat([newData]),
          },
        },
        workflowType: workflow.type,
      },
      refetchQueries: [GetWorkflowDocument],
    });
  };

  return (
    <HHAccordion>
      <HHAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <HHTypography hhVariant="h2">Notes</HHTypography>
      </HHAccordionSummary>
      <HHAccordionDetails>
        <HHBox>
          <SubmitForm onSubmit={handleSubmit} />
          <hr />
          {Array.isArray(notes) && notes.length > 0 && <Notes notes={notes} />}
        </HHBox>
      </HHAccordionDetails>
    </HHAccordion>
  );
};

export const ERROR_STRINGS = {
  EMPTY_COMMENT: 'the text area must be filled',
};

const useStyles = makeStyles(() =>
  createStyles({
    noteContainer: {
      margin: '.5em 0 .5em 0',
    },
    paddingSM: {
      margin: '.25em',
    },
    flex: {
      display: 'flex',
    },
    centerY: {
      alignContent: 'center',
      alignSelf: 'center',
    },
    nameText: {
      color: MEMBER_DETAILS_COLORS.BLACK,
      fontSize: AMT_THEME.FONT_SIZES.SMALL,
    },
    dateText: {
      color: BUTTON_DISABLED_TEXT,
      fontSize: AMT_THEME.FONT_SIZES.X_SMALL,
    },
    errorContainer: {
      margin: '1em 0 1em 0',
    },
  }),
);

const joinStyles = (styles: string[] = []): string =>
  styles.reduce((prev, str) => `${prev} ${str}`, '');

const SubmitForm = ({
  defaultValue = '',
  onSubmit,
}: SubmitFormProps): JSX.Element => {
  const [comment, setComment] = useState(defaultValue);
  const [error, setError] = useState<string | null>(null);
  const styles = useStyles();
  const handleSubmit = (): void => {
    if (!comment) {
      setError(ERROR_STRINGS.EMPTY_COMMENT);
    } else {
      setError(null);
    }
    onSubmit(comment);
    setComment('');
  };
  return (
    <>
      <HHGrid container>
        <HHGrid sm={8} item>
          <HHTextField
            error={!!error}
            placeholder="comment"
            hhVariant="standard"
            onChange={(e): void => {
              setError(null);
              setComment(e.target.value);
            }}
            value={comment}
          />
        </HHGrid>
        <HHGrid sm={2} item>
          <HHButton type="submit" hhVariant="contained" onClick={handleSubmit}>
            Submit
          </HHButton>
        </HHGrid>
      </HHGrid>

      {error && (
        <HHGrid container>
          <HHAlert
            className={styles.errorContainer}
            hhVariant="variant-bypass"
            variant="outlined"
            severity="error"
          >
            {error}
          </HHAlert>
        </HHGrid>
      )}
    </>
  );
};

const Notes = ({ notes }: { notes: Note[] }): JSX.Element => (
  <HHGrid container>
    {notes
      .filter(
        note => 'name' in note && 'timestamp' in note && 'comment' in note,
      )
      .map(note => (
        <Note key={`${note.timestamp}-${note.name}`} {...note} />
      ))}
  </HHGrid>
);

const Note = ({ timestamp, name, comment }: Note): JSX.Element => {
  const styles = useStyles();
  return (
    <HHGrid
      className={joinStyles([styles.noteContainer, styles.paddingSM])}
      container
    >
      <HHGrid sm={4} className={styles.flex} item>
        <HHGrid className={joinStyles([styles.flex, styles.centerY])} container>
          <HHGrid className={styles.nameText}>
            {fullNameToFirstNameAndLastInitial(name)}
          </HHGrid>
          <HHGrid className={styles.dateText}>
            {format(new Date(timestamp), 'MM/dd/yy')}
          </HHGrid>
        </HHGrid>
      </HHGrid>
      <HHGrid sm={8} className={joinStyles([styles.flex, styles.centerY])} item>
        {comment}
      </HHGrid>
    </HHGrid>
  );
};

type Note = {
  timestamp: string;
  name: string;
  comment: string;
};

type SubmitFormProps = {
  defaultValue?: string;
  onSubmit: (comment: string) => Promise<void>;
};
