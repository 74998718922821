import {
  HHButton,
  HHStack,
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import Search from '@mui/icons-material/Search';
import { type Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { FormEvent, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/strings/routes';

const UserSearch = (): JSX.Element => {
  const navigation = useNavigate();
  const [userId, setUserId] = useState('');
  const isUserDisabled = !userId || parseInt(userId, 10) === 0;

  /* istanbul ignore next */
  const goToUserPage = (
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault();
    if (!isUserDisabled) {
      navigation(`${routes.billing.home}/${routes.billing.userPath}/${userId}`);
    }
  };

  return (
    <HHStack
      border="1px solid lightgray"
      borderRadius={1}
      spacing={2}
      padding={4}
      sx={(theme): SystemStyleObject<Theme> => ({
        backgroundColor: theme.palette.background.default,
      })}
    >
      <HHTypography hhVariant="body">User Journey</HHTypography>
      <form onSubmit={goToUserPage}>
        <HHStack direction="row" spacing={2}>
          <HHTextField
            hhVariant="variant-bypass"
            placeholder="Search by User ID"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              min: '0',
            }}
            value={userId}
            onChange={(e): void => setUserId(e.target.value)}
            type="number"
            InputLabelProps={{ sx: { color: 'gray' } }}
            sx={{ minWidth: 150 }}
            InputProps={{
              startAdornment: <Search sx={{ color: 'gray' }} />,
            }}
            fullWidth
          />
          <HHButton
            hhVariant="contained"
            aria-label="submit"
            type="submit"
            disabled={isUserDisabled}
            onClick={goToUserPage}
          >
            Search
          </HHButton>
        </HHStack>
      </form>
    </HHStack>
  );
};

export default UserSearch;
