import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ConversationList: any;
  CreatedMessage: any;
  DateTime: Date;
  DeleteMessage: any;
  JSON: any;
  JSONObject: any;
  MessageList: any;
  StringArrayOrNull: any;
  StringOrInt: any;
  UpdatedMessage: any;
};

/** All the accepted roles allowed for the Care Team Tools provided by JWT */
export enum AcceptedRoles {
  BillingOps = 'BILLING_OPS',
  C10RUser = 'C10R_USER',
  CareCoordinator = 'CARE_COORDINATOR',
  Coach = 'COACH',
  DeliveryOps = 'DELIVERY_OPS',
  MyUser = 'MY_USER',
  NursePractitioner = 'NURSE_PRACTITIONER',
  PartnershipManager = 'PARTNERSHIP_MANAGER',
  PhxUser = 'PHX_USER',
  PhysicalTherapist = 'PHYSICAL_THERAPIST',
  Super = 'SUPER'
}

export type ActionDefinition = {
  __typename?: 'ActionDefinition';
  actions?: Maybe<Array<Scalars['JSONObject']>>;
  metadata?: Maybe<Scalars['JSONObject']>;
  type: Scalars['String'];
};

export type ActionDefinitionInput = {
  actions?: Maybe<Array<Scalars['JSONObject']>>;
  metadata?: Maybe<Scalars['JSONObject']>;
  type: Scalars['String'];
};

export type ActionPlan = {
  __typename?: 'ActionPlan';
  /** The barriers a member has to completing their ET sessions */
  barriers?: Maybe<Scalars['String']>;
  /** The reason that the action plan is closed */
  closeReason?: Maybe<Scalars['String']>;
  /** The date that the members action plan is created in the format of a timestamp */
  createdAt: Scalars['String'];
  /** The uuid of the admin or member who created the action plan */
  createdBy: Scalars['String'];
  /** The number of ET sessions the member has set as a goal to complete each week */
  etGoalPerWeek?: Maybe<Scalars['Int']>;
  /** The goal the member hopes to reach by completing their ET sessions */
  goals?: Maybe<Scalars['String']>;
  /** The habit that the ET session will be paired with */
  habitStack?: Maybe<Scalars['String']>;
  /** A list of action plan fields that were changed and their values at the time */
  history: Array<ActionPlanHistory>;
  /** The id of the action plan */
  id: Scalars['ID'];
  /** Notes on the details of the action plan */
  note?: Maybe<Scalars['String']>;
  /** The duration in weeks of this action plan */
  planDuration: Scalars['String'];
  /** The action plan number */
  planNumber?: Maybe<Scalars['Int']>;
  /** The date that the members action plan is reviewed in the format of a timestamp */
  planReviewDate: Scalars['String'];
  /** The type of action plan */
  planType: Scalars['String'];
  /** Whether or not the member is getting ET session reminders */
  remindersActive: Scalars['Boolean'];
  /** The number of days the member is reminded of the action plan */
  remindersDays: Array<Scalars['Int']>;
  /** The method in which the member is getting action plan reminders */
  remindersMethod: Scalars['String'];
  /** The time that the member is getting action plan reminders in military time */
  remindersTime: Scalars['String'];
  /** The status of the action plan */
  status: Scalars['String'];
  /** The date that the members action plan is updated in the format of a timestamp */
  updatedAt: Scalars['String'];
  /** The uuid of the admin or member who last updated the action plan */
  updatedBy: Scalars['String'];
  /** The uuid of the member for this action plan */
  userUuid: Scalars['String'];
};

export type ActionPlanHistory = {
  __typename?: 'ActionPlanHistory';
  /** The barriers a member has to completing their ET sessions */
  barriers?: Maybe<Scalars['String']>;
  /** The reason that the action plan is closed */
  closeReason?: Maybe<Scalars['String']>;
  /** The date that the members action plan is created in the format of a timestamp */
  createdAt?: Maybe<Scalars['String']>;
  /** The uuid of the admin or member who created the action plan */
  createdBy?: Maybe<Scalars['String']>;
  /** The number of ET sessions the member has set as a goal to complete each week */
  etGoalPerWeek?: Maybe<Scalars['Int']>;
  /** The goal the member hopes to reach by completing their ET sessions */
  goals?: Maybe<Scalars['String']>;
  /** The habit that the ET session will be paired with */
  habitStack?: Maybe<Scalars['String']>;
  /** The id of the action plan */
  id?: Maybe<Scalars['ID']>;
  /** Notes on the details of the action plan */
  note?: Maybe<Scalars['String']>;
  /** The duration in weeks of this action plan */
  planDuration?: Maybe<Scalars['String']>;
  /** The action plan number */
  planNumber?: Maybe<Scalars['Int']>;
  /** The date that the members action plan is reviewed in the format of a timestamp */
  planReviewDate?: Maybe<Scalars['String']>;
  /** The type of action plan */
  planType?: Maybe<Scalars['String']>;
  /** Whether or not the member is getting ET session reminders */
  remindersActive?: Maybe<Scalars['Boolean']>;
  /** The number of days the member is reminded of the action plan */
  remindersDays?: Maybe<Array<Scalars['Int']>>;
  /** The method in which the member is getting action plan reminders */
  remindersMethod?: Maybe<Scalars['String']>;
  /** The time that the member is getting action plan reminders in military time */
  remindersTime?: Maybe<Scalars['String']>;
  /** The status of the action plan */
  status?: Maybe<Scalars['String']>;
  /** The date that the members action plan is updated in the format of a timestamp */
  updatedAt?: Maybe<Scalars['String']>;
  /** The uuid of the admin or member who last updated the action plan */
  updatedBy?: Maybe<Scalars['String']>;
  /** The uuid of the member for this action plan */
  userUuid?: Maybe<Scalars['String']>;
};

export type ActionPlanInsights = {
  __typename?: 'ActionPlanInsights';
  actionPlanProgress: Array<ActionPlanWeeklyProgress>;
  currentActionPlanInfo: CurrentActionPlan;
};

export type ActionPlanWeek = {
  __typename?: 'ActionPlanWeek';
  isBeyondPlanReviewDate: Scalars['Boolean'];
  isWeekZeroCreatedDate: Scalars['Boolean'];
  weekNumber: Scalars['Float'];
};

export type ActionPlanWeeklyProgress = {
  __typename?: 'ActionPlanWeeklyProgress';
  etSessionFilteredCount: Scalars['Float'];
  week: WeekInfo;
};

export type ActionResult = {
  __typename?: 'ActionResult';
  log?: Maybe<Scalars['String']>;
  output?: Maybe<Scalars['JSON']>;
  status: ActionStatus;
  type: Scalars['String'];
};

export enum ActionStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export type ActivityPlan = {
  __typename?: 'ActivityPlan';
  createdAt: Scalars['String'];
  data?: Maybe<ActivityPlanMetaData>;
  draft?: Maybe<ActivityPlanDraft>;
  isBeta?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  numProgressions: Scalars['Float'];
  progressions: Array<Progression>;
  startedAt?: Maybe<Scalars['String']>;
  status: ActivityPlanStatus;
  updatedAt: Scalars['String'];
  uri: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type ActivityPlanDraft = {
  __typename?: 'ActivityPlanDraft';
  activityPlanUuid: Scalars['String'];
  createdAt: Scalars['String'];
  data?: Maybe<ActivityPlanMetaData>;
  /** Contextual - indicates whether the admin retrieving the draft owns the draft */
  isOwnedByCurrentAdmin: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  ownerUuid: Scalars['String'];
  progressions: Array<Progression>;
  updatedAt: Scalars['String'];
  uri: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type ActivityPlanMetaData = {
  __typename?: 'ActivityPlanMetaData';
  bodyRegion?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  originalTemplateUuid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export enum ActivityPlanStatus {
  Assigned = 'ASSIGNED',
  Deactivated = 'DEACTIVATED',
  Published = 'PUBLISHED'
}

export type Admin = {
  __typename?: 'Admin';
  /** The url for the admin's avatar */
  avatarUrl: Scalars['String'];
  /** Care plans that the admin owns, paginated */
  carePlanData: CarePlanData;
  /** The coverage type of admin */
  coverageTypes: Array<Scalars['String']>;
  /** The email address for the admin */
  email: Scalars['String'];
  /** The first name of the admin */
  firstName: Scalars['String'];
  /** The id of the admin */
  id: Scalars['ID'];
  /** The last name of the admin */
  lastName: Scalars['String'];
  /** The role of the admin */
  role: Scalars['String'];
  /** The uuid of the admin */
  uuid: Scalars['ID'];
};


export type AdminCarePlanDataArgs = {
  includeExpirations?: Maybe<Scalars['Boolean']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  program?: Maybe<Scalars['String']>;
};

export type ApplicationPathway = {
  __typename?: 'ApplicationPathway';
  /** The application pathway indication (e.g. chronic) */
  indication?: Maybe<Scalars['String']>;
  /** The application pathway program (e.g. neck) */
  program?: Maybe<Scalars['String']>;
  /** The associated screening ID of an application */
  screeningId?: Maybe<Scalars['Float']>;
  /** The total number of points */
  totalPoints?: Maybe<Scalars['Float']>;
};

export type AssessedRule = {
  __typename?: 'AssessedRule';
  actionLog?: Maybe<Array<ActionResult>>;
  assertionLog?: Maybe<Scalars['JSON']>;
  status: AssessmentStatus;
};

export type AssessmentResult = {
  __typename?: 'AssessmentResult';
  actionsExecuted: Scalars['Float'];
  assertionPassed: Scalars['Float'];
  completed: Scalars['Float'];
  createdAt: Scalars['String'];
  errored: Scalars['Float'];
  id: Scalars['String'];
  ruleAssessmentResultId?: Maybe<Scalars['String']>;
};

export enum AssessmentStatus {
  ActionsExecuted = 'ACTIONS_EXECUTED',
  AssertionPassed = 'ASSERTION_PASSED',
  Completed = 'COMPLETED',
  Errored = 'ERRORED',
  TagCreated = 'TAG_CREATED'
}

export type AssessmentTask = {
  __typename?: 'AssessmentTask';
  actionsExecuted: Scalars['Float'];
  arguments: Scalars['JSON'];
  assertionPassed: Scalars['Float'];
  assessmentResults: Array<AssessmentResult>;
  completed: Scalars['Float'];
  createdAt: Scalars['String'];
  errored: Scalars['Float'];
  id: Scalars['ID'];
  remaining: Scalars['Float'];
  status: Scalars['String'];
  totalAssessments: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export type AssessmentTaskList = {
  __typename?: 'AssessmentTaskList';
  assessmentTasks: Array<AssessmentTaskListItem>;
  limit: Scalars['Float'];
  total: Scalars['Float'];
};

export type AssessmentTaskListItem = {
  __typename?: 'AssessmentTaskListItem';
  actionsExecuted: Scalars['Float'];
  arguments: Scalars['JSON'];
  assertionPassed: Scalars['Float'];
  completed: Scalars['Float'];
  createdAt: Scalars['String'];
  errored: Scalars['Float'];
  id: Scalars['ID'];
  remaining: Scalars['Float'];
  status: Scalars['String'];
  totalAssessments: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export type Asset = {
  __typename?: 'Asset';
  contentType: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  url: Scalars['String'];
};

export type Association = {
  __typename?: 'Association';
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
  typeId: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type AudioChapter = {
  __typename?: 'AudioChapter';
  slug: Scalars['String'];
  text: Scalars['String'];
  type: Scalars['String'];
  variant: Scalars['Int'];
};

export type AudioStories = {
  __typename?: 'AudioStories';
  audioChapters: Array<AudioChapter>;
  chapterLeadingGap: Scalars['Int'];
  chapterTrailingGap: Scalars['Int'];
  exerciseLeadingGap: Scalars['Int'];
  exerciseTrailingGap: Scalars['Int'];
};

export type AvailableTransitions = {
  __typename?: 'AvailableTransitions';
  /** Next transition state */
  to: Scalars['String'];
};

export type BaseUser = {
  __typename?: 'BaseUser';
  /** The user's pathway program indication */
  applicationPathway?: Maybe<ApplicationPathway>;
  /** The member's bio */
  bio?: Maybe<Scalars['String']>;
  /** The name of the client the member is associated with */
  clientName?: Maybe<Scalars['String']>;
  /** Program indications from the member's recruitments */
  clientRecruitmentProgramIndications?: Maybe<Array<Scalars['String']>>;
  /** Preferred contact method for member */
  contactMethod?: Maybe<Scalars['String']>;
  /** The email address for the member */
  email: Scalars['String'];
  /** The first name of the member */
  firstName: Scalars['String'];
  /** The ID of the member */
  id: Scalars['ID'];
  /** The last name of the member */
  lastName: Scalars['String'];
  /** Program indications from the pathway */
  pathwayProgramIndications?: Maybe<Array<Scalars['String']>>;
  /** The phone number for the member */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The postal code of the member */
  postalCode?: Maybe<Scalars['String']>;
  /** The state of residence for the member */
  stateOfResidence?: Maybe<Scalars['String']>;
  /** Tags associated with a user */
  tagList?: Maybe<Array<Scalars['String']>>;
  /** (Deprecated, use `tagList`) Tags associated with a user */
  tags?: Maybe<Array<Scalars['String']>>;
  /** The member's team ID */
  teamId?: Maybe<Scalars['String']>;
  /** Time zone for the member */
  timezone?: Maybe<Scalars['String']>;
  /** The UUID of the member */
  uuid: Scalars['ID'];
};

export type BatchClientInsurerType = {
  __typename?: 'BatchClientInsurerType';
  billingType?: Maybe<Scalars['String']>;
  client: ClientType;
  id: Scalars['Int'];
  insurer: BatchInsurerType;
  isActive: Scalars['Boolean'];
  partnerships: Array<PartnershipType>;
  termDate?: Maybe<Scalars['String']>;
};

export type BatchContractClientInsurerInput = {
  /** Id of the client */
  clientId: Scalars['Float'];
  /** Id of the client insurer */
  id: Scalars['Float'];
  /** Id of the insurer */
  insurerId: Scalars['Float'];
};

export type BatchContractResponse = {
  __typename?: 'BatchContractResponse';
  /** Id of the client insurer */
  clientInsurerId: Scalars['Int'];
  /** Error that happened, if no error will be empty string */
  error: Scalars['String'];
  /** New Contract details */
  newContract?: Maybe<ContractType>;
  /** Old Contract details */
  oldContract?: Maybe<ContractType>;
  /** boolean if request was sucess or not */
  success: Scalars['Boolean'];
};

export type BatchInsurerType = {
  __typename?: 'BatchInsurerType';
  eligibilityRef: Scalars['String'];
  /** The id of the InsurerType item */
  id: Scalars['Int'];
  /** The identifier of the InsurerType item */
  identifier: Scalars['String'];
  /** The name of the InsurerType item */
  name: Scalars['String'];
};

export type BillSubmissionsType = {
  __typename?: 'BillSubmissionsType';
  baseCharge: Scalars['Float'];
  billAmount: Scalars['Float'];
  billingMonth: Scalars['String'];
  claimIdentifier: Scalars['String'];
  clientsInsurerId: Scalars['Float'];
  coverageId?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  fromDate: Scalars['String'];
  id: Scalars['Float'];
  internalHolds: Array<InternalHoldBaseType>;
  pathwayId: Scalars['Float'];
  paymentType: Scalars['String'];
  source: Scalars['String'];
  sourceId: Scalars['Float'];
  submissions: Array<SubmissionRemitsType>;
  subscriptionId: Scalars['Float'];
  toDate: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type BillableActivityType = {
  __typename?: 'BillableActivityType';
  /** The created date time value of the EngagementType item */
  createdAt: Scalars['String'];
  /** The id of the EngagementType item */
  id: Scalars['Int'];
  /** Active status of the item */
  isActive: Scalars['Boolean'];
  /** The name of the EngagementType item */
  name: Scalars['String'];
  /** The updated date time value of the EngagementType item */
  updatedAt: Scalars['String'];
};

export type BillingModelType = {
  __typename?: 'BillingModelType';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type BillsPaginatedType = {
  __typename?: 'BillsPaginatedType';
  items: Array<PaginatedBillType>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  size: Scalars['Float'];
  total: Scalars['Float'];
};

export type BulkCreateTransitionInputDto = {
  /** Determines whether all workflows were selected */
  allWorkflowsChecked: Scalars['Boolean'];
  /** Submission id for the bulk transition request */
  submissionId: Scalars['String'];
  /** State to which the workflow is to be transitioned */
  toState: Scalars['String'];
  /** All workflow filter inputs that are used to filter data to show on specific dashboard tab */
  workflowListFilterInputDto?: Maybe<WorkflowListFiltersInputDto>;
  /** Type of workflow to be transitiond */
  workflowType: Scalars['String'];
  /** workflows to be excluded */
  workflowsToExclude?: Maybe<Array<Scalars['Float']>>;
  /** workflows to be transitioned */
  workflowsToTransition?: Maybe<Array<CreateTransitionArgs>>;
};

export type BulkWorkflowTransitionModel = {
  __typename?: 'BulkWorkflowTransitionModel';
  /** Workflows failed to submit */
  failedWorkflowsIds: Array<Scalars['Float']>;
  /** Error message from server */
  serverErrorMessage?: Maybe<Scalars['String']>;
  /** Bulk workflow transition submission id */
  submissionId: Scalars['String'];
  /** Workflows successfully submitted */
  successfullySubmittedWorkflowIds: Array<Scalars['Float']>;
  /** Transition submission message */
  transitionSubmissionMessage: Scalars['String'];
  /** Determines whether bulk workflow transition submission was successful or not */
  transitionSubmissionStatus: Scalars['Boolean'];
  /** List of workflow errors */
  workflowErrors?: Maybe<Array<WorkflowError>>;
};

export type Calibration = {
  __typename?: 'Calibration';
  angle: Scalars['Int'];
  createdAt: Scalars['String'];
  eulerKey: EulerKey;
  failureInstructions: Scalars['String'];
  imageId: Scalars['String'];
  imageSlug?: Maybe<Scalars['String']>;
  instructions: Scalars['String'];
  limb: SensorPlacement;
  tolerance: Scalars['Int'];
  updatedAt: Scalars['String'];
  uuid: Scalars['String'];
};

export type CareCoordinationResponse = {
  __typename?: 'CareCoordinationResponse';
  response: Scalars['String'];
};

export type CarePlan = {
  __typename?: 'CarePlan';
  /** The admin uuid of the care plan */
  adminId: Scalars['ID'];
  /** The closed at date of the care plan */
  closedAt?: Maybe<Scalars['String']>;
  /** The discharge reason of the care plan */
  dischargeReason?: Maybe<Scalars['String']>;
  /** The expected discharge at date of the care plan */
  expectedDischargeAt: Scalars['String'];
  /** The expiration date of the care plan */
  expires?: Maybe<Scalars['String']>;
  /** The current completed video visits of the workflow */
  followups?: Maybe<Scalars['Float']>;
  /** The list of icd 10s associated with the care plan */
  icd10: Array<Icd10>;
  /** The id of the care plan */
  id: Scalars['ID'];
  /** The care plan indication */
  indication: Scalars['String'];
  /** The items associated with the care plan formatted for the UI */
  items: Array<CarePlanItem>;
  /** The opened at date of the care plan */
  openedAt: Scalars['String'];
  /** The limit in days of the care plan */
  planLimit?: Maybe<Scalars['String']>;
  /** The care plan program */
  program: Scalars['String'];
  /** The current state of residence of the member */
  stateOfResidence?: Maybe<Scalars['String']>;
  /** The status of the care plan */
  status: CarePlanStatus;
  /** The title of the care plan */
  title: Scalars['String'];
  /** The user uuid of the care plan */
  userId: Scalars['ID'];
};

export type CarePlanConfig = {
  __typename?: 'CarePlanConfig';
  /** The list of icd 10s for the care plan config */
  icd10: Array<Icd10>;
};

export type CarePlanData = {
  __typename?: 'CarePlanData';
  /** A paginated list of care plans sorted most recent first */
  carePlans: Array<CarePlan>;
  /** Whether there are more items to request */
  hasNextPage: Scalars['Boolean'];
  /** The items requested per page */
  itemsPerPage: Scalars['Int'];
  /** The page number requested (Eg: page 4 of 30) */
  page: Scalars['Int'];
};

export type CarePlanItem = {
  __typename?: 'CarePlanItem';
  /** The current value of the care plan item formatted for the UI */
  currentValue: Scalars['String'];
  /** The id of the care plan item */
  id: Scalars['ID'];
  /** The name of the care plan item formatted for the UI */
  key: Scalars['String'];
};

export enum CarePlanStatus {
  Closed = 'Closed',
  Open = 'Open'
}

export type CareTeamCoverageInfo = {
  __typename?: 'CareTeamCoverageInfo';
  /** status of Care team member */
  active: Scalars['Boolean'];
  /** admin email */
  adminEmail: Scalars['String'];
  /** admin full name */
  adminName: Scalars['String'];
  /** admin Role */
  adminRole: Scalars['String'];
  /** admin uuid */
  adminUuid: Scalars['String'];
  /** coverage dates */
  coverageDates: Array<CoverageDates>;
  /** coverage type */
  type: CoachCoverageType;
};

export type Chain = {
  __typename?: 'Chain';
  exercises?: Maybe<Array<ExerciseConfig>>;
  name: Scalars['String'];
  urn: Scalars['String'];
  version: Scalars['String'];
};

export type Classification = {
  __typename?: 'Classification';
  name: Scalars['String'];
  path: Scalars['String'];
  uuid: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  /** Client ID */
  id?: Maybe<Scalars['Int']>;
  /** Client Logo */
  logo?: Maybe<Scalars['String']>;
  /** Client Name */
  name?: Maybe<Scalars['String']>;
};

export type ClientInsurerByClientType = {
  __typename?: 'ClientInsurerByClientType';
  clientsInsurers: Array<ClientInsurerDetailsType>;
  /** The id of the ClientType item */
  id: Scalars['Int'];
  /** The identifier of the ClientType item */
  identifier: Scalars['String'];
  /** The name of the ClientType item */
  name: Scalars['String'];
};

export type ClientInsurerDetailsType = {
  __typename?: 'ClientInsurerDetailsType';
  billingType?: Maybe<Scalars['String']>;
  client: ClientType;
  id: Scalars['Int'];
  insurer: InsurerBasicType;
  isActive: Scalars['Boolean'];
  partnerships: Array<PartnershipType>;
  termDate?: Maybe<Scalars['String']>;
};

export type ClientInsurerType = {
  __typename?: 'ClientInsurerType';
  acuteBasePrice: Scalars['Float'];
  acuteStartDate?: Maybe<Scalars['String']>;
  apiCode?: Maybe<Scalars['String']>;
  apiOn: Scalars['Boolean'];
  apiRequired: Scalars['Boolean'];
  athena: Scalars['Boolean'];
  autoEligibilityEnabled: Scalars['Boolean'];
  billingType?: Maybe<Scalars['String']>;
  chronicBasePrice: Scalars['Float'];
  client: ClientType;
  coreContractRule: Scalars['String'];
  disableEligibilityAlerts?: Maybe<Scalars['Boolean']>;
  efileRequired: Scalars['Boolean'];
  groupNumbers: Array<GroupNumberType>;
  id: Scalars['Int'];
  ignorePrimary: Scalars['Boolean'];
  insurer: InsurerType;
  isActive: Scalars['Boolean'];
  partnerships: Array<PartnershipType>;
  primaryRequired: Scalars['Boolean'];
  programExceptions: Array<ProgramExceptionsType>;
  termDate?: Maybe<Scalars['String']>;
  validGroupNumbers: Array<ValidGroupNumbersType>;
};

export type ClientType = {
  __typename?: 'ClientType';
  /** The id of the ClientType item */
  id: Scalars['Int'];
  /** The identifier of the ClientType item */
  identifier: Scalars['String'];
  /** The name of the ClientType item */
  name: Scalars['String'];
};

export type CoachAssistantInputDto = {
  /** Member ID */
  memberId: Scalars['String'];
};

export type CoachAssistantReplyModel = {
  __typename?: 'CoachAssistantReplyModel';
  /** The related error if chat bot service fails */
  error: Scalars['String'];
  /** The prompt used to generate the reply */
  prompt?: Maybe<Scalars['String']>;
  /** The reply from the chat bot */
  reply: Scalars['String'];
  /** The generated message response uuid */
  uuid?: Maybe<Scalars['String']>;
};

export enum CoachCoverageType {
  Admin = 'Admin',
  Clinical = 'Clinical',
  Primary = 'Primary',
  Temporary = 'Temporary'
}

export type ConsultResultType = {
  __typename?: 'ConsultResultType';
  consultMessage?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  hhUuid: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type ContractPackagesInput = {
  billingEnabled: Scalars['Boolean'];
  billingModelId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  packageId: Scalars['Float'];
  price?: Maybe<Scalars['Float']>;
  startDate: Scalars['String'];
  void: Scalars['Boolean'];
};

export type ContractPackagesType = {
  __typename?: 'ContractPackagesType';
  billingEnabled: Scalars['Boolean'];
  billingModel: BillingModelType;
  billingModelId: Scalars['Int'];
  contractId: Scalars['Int'];
  createdAt: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastModifiedBy: Scalars['String'];
  package: PackageType;
  packageId: Scalars['Int'];
  price: Scalars['Int'];
  startDate: Scalars['String'];
  updatedAt: Scalars['String'];
  void: Scalars['Boolean'];
};

export type ContractSubscriptionType = {
  __typename?: 'ContractSubscriptionType';
  /** bills of the ContractSubscriptionType item */
  billCount: Scalars['Float'];
  /** fee for service event count of the ContractSubscriptionType item */
  feeForServiceEventCount: Scalars['Float'];
  /** submissions of the ContractSubscriptionType item */
  submissionCount: Scalars['Float'];
  /** subscription count of the ContractSubscriptionType item */
  subscriptionCount: Scalars['Float'];
  /** subscription discounts of the ContractSubscriptionType item */
  subscriptionDiscountCount: Scalars['Float'];
  /** subscription discounts of the ContractSubscriptionType item */
  voidCount: Scalars['Float'];
};

export type ContractTemplateType = {
  __typename?: 'ContractTemplateType';
  /** The contract value of the ContractType item */
  contract: Array<ContractTypeUnion>;
  contractType?: Maybe<Scalars['String']>;
  /** The created date time value of the EngagementType item */
  createdAt: Scalars['String'];
  /** The id of the ContractTemplateType item */
  id: Scalars['Int'];
  /** Active status of the ContractType item */
  isActive: Scalars['Boolean'];
  /** Name of the ContractType item */
  name: Scalars['String'];
  /** Partnership id of the ContractType item */
  partnershipId?: Maybe<Scalars['Float']>;
  /** The updated date time value of the EngagementType item */
  updatedAt: Scalars['String'];
};

export type ContractType = {
  __typename?: 'ContractType';
  /** The acute price of the ContractType item */
  acutePrice: Scalars['Float'];
  /** The collection of billable activity types for the ContractType item */
  billableActivityTypes: Array<BillableActivityType>;
  /** The chronic price of the ContractType item */
  chronicPrice: Scalars['Float'];
  /** The client id of the ContractType item */
  clientId: Scalars['Float'];
  /** The client insurer id of the ContractType item */
  clientsInsurerId: Scalars['Float'];
  /** The contract value of the ContractType item */
  contract: Array<ContractTypeUnion>;
  contractPackages: Array<ContractPackagesType>;
  /** The contract template id of the ContractType item */
  contractTemplateId: Scalars['Float'];
  contractType: Scalars['String'];
  /** The created date time value of the EngagementType item */
  createdAt: Scalars['String'];
  /** The collection of disabled program indications for the ContractType item */
  disabledProgramIndication: Array<DisabledProgramIndication>;
  /** The updated date time value of the ContractType item */
  endDate?: Maybe<Scalars['String']>;
  /** The id of the ContractType item */
  id: Scalars['Int'];
  /** The insurer id of the ContractType item */
  insurerId: Scalars['Float'];
  ironcladId?: Maybe<Scalars['String']>;
  /** The collection of procedure codes for the ContractType item */
  procedureCodes: Array<ProcedureCodesType>;
  /** The start date of the ContractType item */
  startDate: Scalars['String'];
  /** The updated date time value of the EngagementType item */
  updatedAt: Scalars['String'];
  /** The void value of the ContractType item */
  void: Scalars['Boolean'];
};

export type ContractTypeUnion = EngagementContractType | IndividualMilestonesType | MilestonesType;

export type CountWorkflowFiltersInputDto = {
  /** UUIDs of administrators to filter by */
  adminUuids?: Maybe<Array<Scalars['ID']>>;
  /** Workflow states to exclude */
  excludeStates?: Maybe<Array<Scalars['String']>>;
  /** UUIDs of patients to filter by */
  patientUuids?: Maybe<Array<Scalars['ID']>>;
  /** Whether to use Basilisk coverage to filter results */
  useCoverage?: Maybe<Scalars['Boolean']>;
};

export type CountWorkflowsFiltersInputDto = {
  /** UUIDs of administrators to filter by */
  adminUuids?: Maybe<Array<Scalars['ID']>>;
  /** Count of workflows which are completed after specified date */
  completedAfterDate?: Maybe<Scalars['String']>;
  /** Count of workflows which are completed before specified date */
  completedBeforeDate?: Maybe<Scalars['String']>;
  /** Name of completed state to filter by */
  completedState?: Maybe<Scalars['String']>;
  /** List of completed states to include while counting completed workflows */
  completedStates?: Maybe<Array<Scalars['String']>>;
  /** Specifies whether to fetch the workflow count by priority */
  countByPriority?: Maybe<Scalars['Boolean']>;
  /** The end date to filter latestTaskDueDate */
  dueTaskEndDate?: Maybe<Scalars['String']>;
  /** The start date to filter latestTaskDueDate */
  dueTaskStartDate?: Maybe<Scalars['String']>;
  /** Workflow states to exclude */
  excludeStates?: Maybe<Array<Scalars['String']>>;
  /** UUIDs of patients to filter by */
  patientUuids?: Maybe<Array<Scalars['ID']>>;
  /** Whether to use Basilisk coverage to filter results */
  useCoverage?: Maybe<Scalars['Boolean']>;
  /** Workflow types to filter by */
  workflowTypes: Array<Scalars['String']>;
};

export type CoverageDates = {
  __typename?: 'CoverageDates';
  /** The end date of the coverage */
  endDate?: Maybe<Scalars['String']>;
  /** The start date of the coverage */
  startsAt: Scalars['String'];
};

export type CptCodes = {
  __typename?: 'CptCodes';
  /** The list of Acute CPT codes for a member client insurer */
  acuteCptCodes?: Maybe<Array<Scalars['String']>>;
};

export type CreateAssessmentTaskInput = {
  /** The end date used when selecting resources to run for this task */
  endDate?: Maybe<Scalars['String']>;
  /** The limit of assessment tasks to run. */
  limit?: Maybe<Scalars['Float']>;
  /** Resource type of resources to assess */
  resourceType?: Maybe<Scalars['String']>;
  /** Specific rule to be evaluated */
  ruleId?: Maybe<Scalars['String']>;
  /** Source of resources to assess */
  source: Scalars['String'];
  /** The start date used when selecting resources to run for this task */
  startDate?: Maybe<Scalars['String']>;
};

export type CreateAssociationInput = {
  /** The association type */
  type: Scalars['String'];
  /** The association type ID */
  typeId: Scalars['String'];
};

export type CreateBatchContractInput = {
  /** The acute price of the ContractType item */
  acutePrice: Scalars['Float'];
  /** The billable activity types of the MilestonesType item */
  billableActivityTypes: Array<Scalars['Int']>;
  /** The chronic price of the ContractType item */
  chronicPrice: Scalars['Float'];
  /** List of client insurers for batch contract create */
  clientInsurers: Array<BatchContractClientInsurerInput>;
  /** Contract packages to be saved */
  contractPackages?: Maybe<Array<ContractPackagesInput>>;
  /** The contract template id of the ContractType item */
  contractTemplateId: Scalars['Float'];
  /** disabled program indications as string to be set as disabled program indications */
  disabledProgramIndication?: Maybe<Array<Scalars['String']>>;
  /** The updated date time value of the ContractType item */
  endDate?: Maybe<Scalars['String']>;
  /** The start date of the ContractType item */
  startDate: Scalars['String'];
};

export type CreateContractInput = {
  /** The acute price of the ContractType item */
  acutePrice: Scalars['Float'];
  /** Engagement type ids to be set as billable activities */
  billableActivityTypes: Array<Scalars['Int']>;
  /** The chronic price of the ContractType item */
  chronicPrice: Scalars['Float'];
  /** The client id of the ContractType item */
  clientId: Scalars['Float'];
  /** The client insurer id of the ContractType item */
  clientsInsurerId: Scalars['Float'];
  /** The contract value of the ContractType item */
  contract: Array<Scalars['JSONObject']>;
  /** Contract packages to be saved */
  contractPackages?: Maybe<Array<ContractPackagesInput>>;
  /** The contract template id of the ContractType item */
  contractTemplateId: Scalars['Float'];
  /** disabled program indications as string to be set as disabled program indications */
  disabledProgramIndication?: Maybe<Array<Scalars['String']>>;
  /** The updated date time value of the ContractType item */
  endDate?: Maybe<Scalars['String']>;
  /** The insurer id of the ContractType item */
  insurerId: Scalars['Float'];
  ironcladId?: Maybe<Scalars['String']>;
  /** Procedure codes to be saved */
  procedureCodes: Array<ProcedureCodeInput>;
  /** The start date of the ContractType item */
  startDate: Scalars['String'];
};

export type CreateDiscountInput = {
  clientId: Scalars['Float'];
  discount: Scalars['Float'];
  discountType: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['Float']>;
  programId?: Maybe<Scalars['Float']>;
  startDate: Scalars['String'];
  suppressEmptyCharges: Scalars['Boolean'];
};

export type CreateFacetInput = {
  /** Facet associations */
  associations?: Maybe<Array<CreateAssociationInput>>;
  /** The facet description */
  description: Scalars['String'];
  /** The facet name */
  name: Scalars['String'];
  /** The facet value */
  value: Scalars['String'];
};

export type CreateInAppMessageArgs = {
  /** The conversation ID */
  conversationId: Scalars['String'];
  /** The in app message */
  message: InAppMessageArgs;
};

export type CreateInAppMessagingConversationArgs = {
  /** The member that is part of the conversation between logged user and member */
  member: InAppMessagingMember;
};

export type CreateMemberAlertInput = {
  /** Admin identifier for the member alert */
  adminUuid: Scalars['String'];
  /** User Status Category */
  category: Scalars['String'];
  /** description of the member alert */
  description?: Maybe<Scalars['String']>;
  /** The ISO8601 timestamp the alert ends at */
  endAt?: Maybe<Scalars['String']>;
  /** if member alert is paused */
  paused?: Maybe<Scalars['Boolean']>;
  /** The ISO8601 timestamp coach reminder date */
  remindAt?: Maybe<Scalars['String']>;
  /** The ISO8601 timestamp the member alert starts at */
  startAt?: Maybe<Scalars['String']>;
  /** User identifier for the member alert */
  userUuid: Scalars['String'];
};

export type CreateMessageFeedbackResponse = {
  __typename?: 'CreateMessageFeedbackResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CreateRecommendationInput = {
  /** Create contract recommendation with doc id */
  docId: Scalars['String'];
};

export type CreateRuleAssessmentInput = {
  resource: Scalars['String'];
  resourceId: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  ruleIds?: Maybe<Array<Scalars['String']>>;
  source: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateRuleInput = {
  /** List of rule actions */
  actions?: Maybe<Array<ActionDefinitionInput>>;
  /** The rule definition */
  definition: RuleDefinitionInput;
  /** Supported resource types for rule */
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  /** Source of resources to be evaluated by this rule */
  source: Scalars['String'];
};

export type CreateTagInput = {
  /** Description of the tag */
  description: Scalars['String'];
  /** Name for the tag in kebab case slug syntax. Must be all lowercase and dash separated. */
  name: Scalars['String'];
};

export type CreateTaskDto = {
  /** The uuid of the admin assigned to the task */
  adminUuid: Scalars['ID'];
  /** Due date of the task that needs to be created */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** Name of the task */
  name: Scalars['String'];
  /** ID of the workflow associated to the task that needs to be created */
  workflowId: Scalars['Float'];
};

export type CreateTemporaryCoverageResponse = {
  __typename?: 'CreateTemporaryCoverageResponse';
  /** returns true if the temporary coverage is created */
  success: Scalars['Boolean'];
};

export type CreateTransitionArgs = {
  createTransitionInputDto: CreateTransitionInputDto;
  /** Type of the workflow */
  workflowType?: Maybe<Scalars['String']>;
};

export type CreateTransitionInputDto = {
  id: Scalars['Float'];
  toState: Scalars['String'];
};

export type CreateUserTagInput = {
  /** ISO 8601 timestamp */
  occurredAt: Scalars['String'];
  /** Resource associated with this userTag */
  resourceId: Scalars['String'];
  /** Source of resource */
  source: Scalars['String'];
  /** Name of the tag being applied to the user */
  tagName: Scalars['String'];
  /** Unique identifier of the user */
  userId: Scalars['String'];
};

export type CreateVideoCallInput = {
  /** adminUuid */
  adminUuid?: Maybe<Scalars['String']>;
  /** basiliskPathwayId */
  basiliskPathwayId?: Maybe<Scalars['Float']>;
  /** identifier */
  identifier?: Maybe<Scalars['String']>;
  /** scheduledAt */
  scheduledAt?: Maybe<Scalars['String']>;
  /** userUuid */
  userUuid?: Maybe<Scalars['String']>;
};

export type CreateWorkflowInputDto = {
  /** UUIDs of administrators associated with the workflow */
  adminUuids?: Maybe<Array<Scalars['ID']>>;
  /** The uuid of the admin who created the workflow */
  createdByAdminUuid?: Maybe<Scalars['ID']>;
  /** Custom fields associated with the workflow */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** The uuid of the patient */
  patientUuid: Scalars['ID'];
};

export type CreatedWorkflowsModel = {
  __typename?: 'CreatedWorkflowsModel';
  errors: Array<Array<WorkflowError>>;
  results: Array<WorkflowPayload>;
};

export type CurrentActionPlan = {
  __typename?: 'CurrentActionPlan';
  actionPlanWeek: ActionPlanWeek;
  etGoalPerWeek: Scalars['Float'];
  planNumber: Scalars['Float'];
};

export type CvsResponseType = {
  __typename?: 'CvsResponseType';
  errors?: Maybe<Scalars['JSONObject']>;
  isEligible: Scalars['Boolean'];
  upstreamResponseContent: Scalars['JSONObject'];
};

export type DeleteExclusionOutput = {
  __typename?: 'DeleteExclusionOutput';
  deactivatedAt: Scalars['String'];
  uuid: Scalars['String'];
};

export type DeleteMassMessageResponse = {
  __typename?: 'DeleteMassMessageResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DeleteMemberAlertInput = {
  /** The id of the member alerts */
  id: Scalars['ID'];
  /** The user uuids of the member alert */
  userUuid: Scalars['ID'];
};

export type DeleteScheduledIamMessageResponse = {
  __typename?: 'DeleteScheduledIamMessageResponse';
  errorCode?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DeleteScheduledInAppMessageInputDto = {
  /** Conversation ID of the message to be deleted */
  conversationId: Scalars['String'];
  /** Message ID of the message to be deleted */
  scheduledMessageId: Scalars['StringOrInt'];
  /** The UUID of the user deleting the message */
  userUuid: Scalars['String'];
};

export type DeleteSubstitutionOutput = {
  __typename?: 'DeleteSubstitutionOutput';
  deactivatedAt: Scalars['String'];
  uuid: Scalars['String'];
};

export type Difficulty = {
  __typename?: 'Difficulty';
  difficulty: Scalars['Int'];
  modified_at: Scalars['String'];
  region: Scalars['String'];
  type: Scalars['String'];
};

export type DisabledProgramIndication = {
  __typename?: 'DisabledProgramIndication';
  /** The id of the DisabledProgramIndication item */
  id: Scalars['Int'];
  /** The identifier name of the DisabledProgramIndication item */
  programIndicationIdentifier: Scalars['String'];
};

export type DiscountDetail = {
  __typename?: 'DiscountDetail';
  clientId?: Maybe<Scalars['Int']>;
  discount: Scalars['Int'];
  discountDefinitionId: Scalars['Int'];
  discountDetailId: Scalars['Int'];
  discountType: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  insurerId?: Maybe<Scalars['Int']>;
  milestoneDiscount?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['Float']>;
  partnershipId?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['Float']>;
  startDate: Scalars['String'];
  subscriptionTier: Array<Maybe<Scalars['Int']>>;
  suppressEmptyCharges: Scalars['Boolean'];
  tier: Scalars['Int'];
};

export type DiscountUsage = {
  __typename?: 'DiscountUsage';
  discountDetailId?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
  discountSum?: Maybe<Scalars['Float']>;
  subscriptionsCount: Scalars['Float'];
};

export type Dosage = {
  __typename?: 'Dosage';
  flexTime?: Maybe<Scalars['Int']>;
  holdTime?: Maybe<Scalars['Int']>;
  reps: Scalars['Int'];
  restTime?: Maybe<Scalars['Int']>;
  switchLimbTime?: Maybe<Scalars['Int']>;
  uuid: Scalars['String'];
};

export type EducationArticle = {
  __typename?: 'EducationArticle';
  /** Article last closed by the member */
  closedAt?: Maybe<Scalars['String']>;
  /** Article url */
  contentUrl?: Maybe<Scalars['String']>;
  /** Article id */
  id: Scalars['String'];
  /** Article name */
  name: Scalars['String'];
  /** Article unique slug to identify */
  slug?: Maybe<Scalars['String']>;
};

export type EmoAdmin = {
  __typename?: 'EmoAdmin';
  /** The email address for the admin */
  email: Scalars['String'];
  /** The first name of the admin */
  firstName: Scalars['String'];
  /** The last name of the admin */
  lastName: Scalars['String'];
  /** The uuid of the admin */
  uuid: Scalars['ID'];
};

export type EmoEligibility = {
  __typename?: 'EmoEligibility';
  /** The user's pathway program indication */
  applicationPathway?: Maybe<ApplicationPathway>;
  /** The member's bio */
  bio?: Maybe<Scalars['String']>;
  /** The name of the client the member is associated with */
  clientName?: Maybe<Scalars['String']>;
  /** Program indications from the member's recruitments */
  clientRecruitmentProgramIndications?: Maybe<Array<Scalars['String']>>;
  /** The assigned clinician */
  clinician?: Maybe<EmoAdmin>;
  /** The preferred contact method for the user */
  contactMethod?: Maybe<Scalars['String']>;
  /** The email address for the member */
  email: Scalars['String'];
  /** The Expert Medical Opinion eligibility status */
  emoEligible: Scalars['Boolean'];
  /** The first name of the member */
  firstName: Scalars['String'];
  /** The ID of the member */
  id: Scalars['ID'];
  /** The last name of the member */
  lastName: Scalars['String'];
  /** Program indications from the pathway */
  pathwayProgramIndications?: Maybe<Array<Scalars['String']>>;
  /** The phone number for the member */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The postal code of the member */
  postalCode?: Maybe<Scalars['String']>;
  /** The reasons for a false eligibility status */
  reasons?: Maybe<Array<Scalars['String']>>;
  /** Current state of the workflow */
  state?: Maybe<Scalars['String']>;
  /** The state of residence for the member */
  stateOfResidence?: Maybe<Scalars['String']>;
  /** Tags associated with a user */
  tagList?: Maybe<Array<Scalars['String']>>;
  /** (Deprecated, use `tagList`) Tags associated with a user */
  tags?: Maybe<Array<Scalars['String']>>;
  /** The member's team ID */
  teamId?: Maybe<Scalars['String']>;
  /** Time zone for the member */
  timezone?: Maybe<Scalars['String']>;
  /** Date and time when the workflow was updated */
  updatedAt?: Maybe<Scalars['String']>;
  /** The UUID of the member */
  uuid: Scalars['ID'];
};

export type EmoEligibilityError = {
  __typename?: 'EmoEligibilityError';
  /** Error message related to the patient ID */
  errorMessage: Scalars['String'];
  /** ID of patient related to the eligibility status */
  patientId: Scalars['String'];
};

export type EmoEligibilityList = {
  __typename?: 'EmoEligibilityList';
  /** Error details related to the eligibility request */
  errors: Array<EmoEligibilityError>;
  /** All the eligibility results */
  results: Array<EmoEligibility>;
};

export type EngagementBaseType = {
  __typename?: 'EngagementBaseType';
  billableCount: Scalars['Float'];
  createdAt: Scalars['String'];
  details: Scalars['JSONObject'];
  eventId?: Maybe<Scalars['Float']>;
  eventType: Scalars['String'];
  eventUuid?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  occurredAt: Scalars['String'];
  programId: Scalars['Float'];
  programIndication: Scalars['String'];
  queuedAt: Scalars['String'];
  sourceType: Scalars['String'];
  userUuid: Scalars['String'];
};

export type EngagementContractType = {
  __typename?: 'EngagementContractType';
  /** The activity types of the EngagementContractType item */
  activityTypes: Array<Scalars['String']>;
  /** The educational types of the EngagementContractType item */
  educationalTypes: Array<Scalars['String']>;
  /** The different engagement rules based on EngagementConditionType of the EngagementContractType item */
  engagementRules: Array<Scalars['JSONObject']>;
  /** The match type of the EngagementContractType item */
  matchType: Scalars['String'];
};

export type EngagementType = {
  __typename?: 'EngagementType';
  /** The created date time value of the EngagementType item */
  createdAt: Scalars['String'];
  /** The id of the EngagementType item */
  id: Scalars['Int'];
  /** The name of the EngagementType item */
  name: Scalars['String'];
  /** The updated date time value of the EngagementType item */
  updatedAt: Scalars['String'];
};

export type EngagementsPaginationType = {
  __typename?: 'EngagementsPaginationType';
  items: Array<EngagementBaseType>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  size: Scalars['Float'];
  total: Scalars['Float'];
};

export type EnrollUsersInput = {
  /** List of user uuids to enroll in a targeted interventions workflow */
  patientUuids: Array<Scalars['ID']>;
  /** Type of workflow */
  workflowType: Scalars['String'];
};

export type EnrollUsersModel = {
  __typename?: 'EnrollUsersModel';
  /** The list of user uuids that have been enrolled */
  enrolledUuids: Array<Scalars['String']>;
  /** The list of user uuids that could not be enrolled due to invalidity or an error */
  failedUuids: Array<Scalars['String']>;
};

export type EnsoTwoWeeksUsageResponse = {
  __typename?: 'EnsoTwoWeeksUsageResponse';
  /** The user`s most recent Enso session date */
  lastSessionDate?: Maybe<Scalars['String']>;
  /** The number of Enso sessions for a user last week */
  sessionsLastWeek: Scalars['Int'];
  /** The number of Enso sessions for a user this week */
  sessionsThisWeek: Scalars['Int'];
};

export type Equipment = {
  __typename?: 'Equipment';
  imageSlug: Scalars['String'];
  name: Scalars['String'];
  uuid: Scalars['String'];
};

export type ErrorDefinition = {
  __typename?: 'ErrorDefinition';
  /** Error description */
  error?: Maybe<Scalars['String']>;
  /** Name of the workflow definition */
  name: Scalars['String'];
  /** Path of the error */
  paths?: Maybe<Array<Scalars['String']>>;
  /** Value of the error */
  value?: Maybe<Scalars['String']>;
};

export type EtSessionActivity = {
  __typename?: 'EtSessionActivity';
  createdAt: Scalars['String'];
  data?: Maybe<EtSessionActivityMetadata>;
  kind: Scalars['String'];
  progression?: Maybe<Progression>;
  updatedAt: Scalars['String'];
  uuid: Scalars['String'];
};

export type EtSessionActivityMetadata = {
  __typename?: 'EtSessionActivityMetadata';
  exercises: Array<ExerciseConfig>;
  userSubstitutions?: Maybe<Array<UserSubstitution>>;
};

export enum EulerKey {
  Pitch = 'PITCH',
  Roll = 'ROLL'
}

export type EventTriggerDefinition = {
  __typename?: 'EventTriggerDefinition';
  /** A list of conditions from the user workflow service condition library to add additional gating logic to trigger execution. */
  conditions?: Maybe<Array<Scalars['String']>>;
  /** Name of event */
  event: Scalars['String'];
  /** Event trigger definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** A list of modifiers from the user workflow service modifier library to add additional workflow modification logic to trigger execution. */
  modifiers?: Maybe<Array<Scalars['String']>>;
  /** Prevent executing this event trigger if a workflow is not in the specified state list. */
  preventDuplicatesExceptStates?: Maybe<Scalars['String']>;
  /** Splits that prevent the event trigger from running if not ON */
  splits?: Maybe<Array<Scalars['String']>>;
  /** Task type */
  taskType?: Maybe<Scalars['String']>;
  /** An optional list of other delayed event triggers that this event cancels or starts in the given sequence */
  timers?: Maybe<Array<TimerDefinition>>;
  /** Name of the transition that would trigger the event */
  transitionName?: Maybe<Scalars['String']>;
  /** Trigger type */
  triggerType?: Maybe<Scalars['String']>;
};

export type EventTriggerDefinitionInput = {
  /** A list of conditions from the user workflow service condition library to add additional gating logic to trigger execution. */
  conditions?: Maybe<Array<Scalars['String']>>;
  /** Name of event */
  event: Scalars['String'];
  /** Event trigger definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** A list of modifiers from the user workflow service modifier library to add additional workflow modification logic to trigger execution. */
  modifiers?: Maybe<Array<Scalars['String']>>;
  /** Prevent executing this event trigger if a workflow is not in the specified state list. */
  preventDuplicatesExceptStates?: Maybe<Scalars['String']>;
  /** Splits that prevent the event trigger from running if not ON */
  splits?: Maybe<Array<Scalars['String']>>;
  /** Task type */
  taskType?: Maybe<Scalars['String']>;
  /** An optional list of other delayed event triggers that this event cancels or starts in the given sequence */
  timers?: Maybe<Array<TimerDefinitionInput>>;
  /** Name of the transition that would trigger the event */
  transitionName?: Maybe<Scalars['String']>;
  /** Trigger type */
  triggerType?: Maybe<Scalars['String']>;
};

export type ExclusionModel = {
  __typename?: 'ExclusionModel';
  adminUuid: Scalars['String'];
  createReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  deactivatedAt?: Maybe<Scalars['String']>;
  endReason?: Maybe<Scalars['String']>;
  levelEnd: Scalars['Int'];
  levelStart: Scalars['Int'];
  memberUuid: Scalars['String'];
  type: Scalars['String'];
  urn: Scalars['String'];
  uuid: Scalars['String'];
};

export type ExclusionRuleWithRuleType = {
  __typename?: 'ExclusionRuleWithRuleType';
  adminUuid: Scalars['String'];
  createReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  deactivatedAt?: Maybe<Scalars['String']>;
  endReason?: Maybe<Scalars['String']>;
  levelEnd: Scalars['Int'];
  levelStart: Scalars['Int'];
  memberUuid: Scalars['String'];
  modifiedAt?: Maybe<Scalars['String']>;
  modifiedByUuid?: Maybe<Scalars['String']>;
  ruleType: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  urn: Scalars['String'];
  uuid: Scalars['String'];
};

export type ExerciseConfig = {
  __typename?: 'ExerciseConfig';
  anatomicalName?: Maybe<Scalars['String']>;
  chains: Array<Chain>;
  classifications: Array<Classification>;
  definitionData?: Maybe<ExerciseDefinitionData>;
  difficulty?: Maybe<Scalars['Int']>;
  dosage?: Maybe<Dosage>;
  equipment: Array<Equipment>;
  name: Scalars['String'];
  repType: RepType;
  slug: Scalars['String'];
  status: ExerciseDefinitionStatus;
  thumbnail?: Maybe<Asset>;
  uuid: Scalars['String'];
};

export type ExerciseDefinition = {
  __typename?: 'ExerciseDefinition';
  audioStories?: Maybe<AudioStories>;
  calibrations?: Maybe<Array<Calibration>>;
  preview: Preview;
  stepDefinitions: Array<StepDefinition>;
};

export type ExerciseDefinitionData = {
  __typename?: 'ExerciseDefinitionData';
  definition: ExerciseDefinition;
  exercise: Scalars['String'];
  exerciseUuid: Scalars['String'];
  revision: Scalars['Int'];
  schema: Scalars['String'];
};

export enum ExerciseDefinitionStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type ExerciseRepetitionData = {
  __typename?: 'ExerciseRepetitionData';
  numRepetitions: Scalars['Int'];
};

export type ExerciseRule = ExclusionRuleWithRuleType | SubstitutionRuleWithRuleType;

export type Facet = {
  __typename?: 'Facet';
  associations?: Maybe<Array<Association>>;
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
  value: Scalars['String'];
};

export type FacetsList = {
  __typename?: 'FacetsList';
  count: Scalars['Float'];
  facets: Array<Facet>;
};

export type FollowupSurgeries = {
  __typename?: 'FollowupSurgeries';
  /** Future Surgery */
  futureSurgery?: Maybe<Scalars['StringOrInt']>;
  /** Upcoming Surgeries */
  upcomingSurgeries?: Maybe<Scalars['StringOrInt']>;
};

export type FunctionalActivityDto = {
  __typename?: 'FunctionalActivityDTO';
  activityName: Scalars['String'];
  difficultyScore: Scalars['Int'];
  indication: Scalars['String'];
  isFocus: Scalars['Boolean'];
  modifiedAt: Scalars['String'];
  program: Scalars['String'];
  scoreDifference?: Maybe<Scalars['Int']>;
  urn: Scalars['String'];
};

export type GetCvsEligibilityInput = {
  /** The code of the client */
  clientCode: Scalars['String'];
  /** The date of birth of the member */
  dateOfBirth: Scalars['String'];
  /** The external id of the member */
  externalId?: Maybe<Scalars['String']>;
  /** The first name of the member */
  firstName: Scalars['String'];
  /** The gender of the member */
  gender?: Maybe<Scalars['String']>;
  /** The date of inquiry of the member */
  inquiryDate?: Maybe<Scalars['String']>;
  /** The last name of the member */
  lastName: Scalars['String'];
  /** The postal code of the member */
  postalCode?: Maybe<Scalars['String']>;
  /** The user id of the member */
  userId?: Maybe<Scalars['Float']>;
};

export type GetDistinctAdminUuidsInput = {
  /** The user uuid to search by */
  userUuid: Scalars['ID'];
};

export type GetEnsoTwoWeeksUsageInput = {
  /** The user uuid to query by */
  userUuid: Scalars['ID'];
};

export type GetInsightsInputDto = {
  /** The member Id */
  memberId: Scalars['Float'];
};

export type GetInsightsResponse = {
  __typename?: 'GetInsightsResponse';
  createdAt?: Maybe<Scalars['String']>;
  insights: Array<Insight>;
  uuid?: Maybe<Scalars['String']>;
};

export type GetMassMessagesInput = {
  /** In App Messaging Specific - the type of care provider leading the conversation (e.g. health_coaching, physical_therapy) */
  conversationType?: Maybe<MassMessageConversationType>;
  /** How the message will be delivered to the member (e.g. in_app or sms) */
  deliveryChannel?: Maybe<MassMessageDeliveryChannel>;
  /** The member to filter with */
  memberId?: Maybe<Scalars['Float']>;
  /** Page number of the result set */
  pageNumber: Scalars['Int'];
  /** The numbers of items to return for each page defaults to 25 */
  pageSize?: Maybe<Scalars['Int']>;
  /** The care team provider to filter with */
  providerUuid?: Maybe<Scalars['String']>;
  /** The property to sort by */
  sortBy?: Maybe<Scalars['String']>;
  /** The direction to sort: asc or desc */
  sortOrder?: Maybe<MassMessageSortOrder>;
  /** The status of the mass message (e.g. scheduled, delivered) */
  status?: Maybe<MassMessageStatus>;
};

export type GetMemberAlertInput = {
  /** The admin uuids of the member alert to filter on */
  adminUuids?: Maybe<Array<Scalars['ID']>>;
  /** The category of the member alert */
  categories?: Maybe<Array<MemberAlertCategory>>;
  /** The number of items per page to fetch, defaults to 25 */
  itemsPerPage?: Maybe<Scalars['Int']>;
  /** The page number to fetch, defaults to 0 */
  page?: Maybe<Scalars['Int']>;
  /** The status (dates) of the member alerts to filter on (Upcoming, Active, Expired) */
  status?: Maybe<Array<MemberAlertStatusEnum>>;
  /** The user uuids of the member alert */
  userUuids?: Maybe<Array<Scalars['ID']>>;
  /** The uuids of the member alerts */
  uuids?: Maybe<Array<Scalars['ID']>>;
};

export type GroupNumberType = {
  __typename?: 'GroupNumberType';
  /** The name of the GroupNumbersType item */
  eligible: Scalars['Boolean'];
  /** The identifier of the GroupNumbersType item */
  flagType: Scalars['String'];
  /** The id of the GroupNumbersType item */
  groupNumber: Scalars['String'];
};

export type HealthLog = {
  __typename?: 'HealthLog';
  /** The list of activities in the health log */
  activities: Array<Scalars['String']>;
  /** The id of the health log */
  id: Scalars['Int'];
  /** The user-submitted note with the health log */
  note?: Maybe<Scalars['String']>;
  /** The health log occurrence date time */
  occurredAt: Scalars['String'];
  /** The pain details relevant to this health log */
  painDetails?: Maybe<PainDetails>;
  /** The uuid of the associated user */
  userUuid: Scalars['String'];
  /** The uuid of the health log */
  uuid: Scalars['ID'];
};

export type HoldDetailsType = {
  __typename?: 'HoldDetailsType';
  clientId?: Maybe<Scalars['Float']>;
  holdType: Scalars['String'];
  insurerId?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
};

export type Icd10 = {
  __typename?: 'Icd10';
  /** The diagnosis code */
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type InAppMessage = {
  __typename?: 'InAppMessage';
  /** Message id */
  id: Scalars['String'];
  /** Message text */
  text: Scalars['String'];
  /** Message type */
  type: Scalars['String'];
};

export type InAppMessageArgs = {
  /** List of attachments, either provided by the user or generated from a command or as a result of URL scraping */
  attachments?: Maybe<Array<Scalars['JSONObject']>>;
  /** The member id from the participant sending the message */
  fromId: Scalars['String'];
  /** The member used for message interpolation */
  memberUuid: Scalars['String'];
  /** Text message */
  text: Scalars['String'];
};

export type InAppMessagingConversation = {
  __typename?: 'InAppMessagingConversation';
  /** Conversation creator */
  createdBy: InAppMessagingConversationCreator;
  /** Conversation id */
  id?: Maybe<Scalars['String']>;
  /** Conversation member count */
  memberCount: Scalars['Int'];
  /** Data that will be used for automation */
  metadata?: Maybe<Scalars['JSONObject']>;
  /** Conversation name */
  name: Scalars['String'];
  /** Conversation capabilities */
  ownCapabilities: Array<Scalars['String']>;
};

export type InAppMessagingConversationCreator = {
  __typename?: 'InAppMessagingConversationCreator';
  /** Conversation creator created at */
  createdAt: Scalars['String'];
  /** Conversation creator id */
  id: Scalars['String'];
  /** Conversation creator image */
  image?: Maybe<Scalars['String']>;
  /** Conversation creator last active */
  lastActive: Scalars['String'];
  /** Conversation creator name */
  name?: Maybe<Scalars['String']>;
  /** Conversation creator role */
  role: Scalars['String'];
  /** Conversation creator updated at */
  updatedAt: Scalars['String'];
};

export type InAppMessagingMember = {
  /** Member name */
  name: Scalars['String'];
  /** Member uuid */
  uuid?: Maybe<Scalars['String']>;
};

export type InAppMessagingUser = {
  __typename?: 'InAppMessagingUser';
  /** The user name for in-app messaging */
  name: Scalars['String'];
  /** The user token for in-app messaging */
  token?: Maybe<Scalars['String']>;
};

export type InAppScheduledMessage = {
  __typename?: 'InAppScheduledMessage';
  /** The body of the scheduled message */
  body: Scalars['String'];
  /** Conversation ID of message */
  conversationId: Scalars['String'];
  /** Timestamp of message creation date */
  createdAt: Scalars['String'];
  /** Scheduled message sent from */
  fromUuid: Scalars['String'];
  /** The id of the scheduled message */
  id: Scalars['String'];
  /** The UUID of the member */
  memberUuid: Scalars['String'];
  /** Timestamp of scheduled message to be sent */
  scheduledAt: Scalars['String'];
  /** The UUID of the member that scheduled a message */
  scheduledBy: Scalars['String'];
};

export type IndividualMilestonesType = {
  __typename?: 'IndividualMilestonesType';
  /** The billable activity types of the IndividualMilestonesType item */
  billableActivityTypes: Array<Scalars['String']>;
  /** The different milestone conditions of the IndividualMilestonesType item */
  conditions: Array<MilestoneConditionType>;
  /** The name of the IndividualMilestonesType item */
  name: Scalars['String'];
  /** The id of the IndividualMilestonesType item */
  payment: Scalars['Float'];
};

export type Insight = {
  __typename?: 'Insight';
  text: Scalars['String'];
};

export type InsurerBasicType = {
  __typename?: 'InsurerBasicType';
  /** The name of the InsurerType item */
  eligibilityRef?: Scalars['String'];
  /** The id of the InsurerType item */
  id: Scalars['Int'];
  /** The identifier of the InsurerType item */
  identifier: Scalars['String'];
  /** The name of the InsurerType item */
  name: Scalars['String'];
};

export type InsurerType = {
  __typename?: 'InsurerType';
  /** The name of the InsurerType item */
  eligibilityRef: Scalars['String'];
  /** The id of the InsurerType item */
  id: Scalars['Int'];
  /** The identifier of the InsurerType item */
  identifier: Scalars['String'];
  /** The name of the InsurerType item */
  memberIdPrefix?: Maybe<Scalars['String']>;
  /** The name of the InsurerType item */
  memberIdRequired: Scalars['Boolean'];
  /** The name of the InsurerType item */
  name: Scalars['String'];
};

export type InternalHoldBaseType = {
  __typename?: 'InternalHoldBaseType';
  billId: Scalars['Float'];
  clearedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  holdType: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type IronCladFields = {
  __typename?: 'IronCladFields';
  acuteCharge?: Maybe<Scalars['Float']>;
  billableActivityTypes: Array<Scalars['String']>;
  chronicCoreCharge?: Maybe<Scalars['Float']>;
  contractType: Scalars['String'];
  counterpartyName: Scalars['String'];
  paymentStructure: Scalars['String'];
  salesforceAccountId: Scalars['String'];
  salesforceOpportunityId: Scalars['String'];
  sowEffectiveDate?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type IroncladPdf = {
  __typename?: 'IroncladPdf';
  docId: Scalars['String'];
  signedPdf?: Maybe<Scalars['String']>;
};

export type LlmResponse = {
  __typename?: 'LLMResponse';
  acutePrice?: Maybe<Scalars['Float']>;
  billableActivityTypes: Array<Scalars['String']>;
  chronicPrice?: Maybe<Scalars['Float']>;
  contract?: Maybe<Array<MilestonesType>>;
  contractTemplateId?: Maybe<Scalars['Float']>;
};

export type LegacyToken = {
  __typename?: 'LegacyToken';
  /** Basilisk Devise access token */
  accessToken: Scalars['String'];
  /** Basilisk client ID */
  client: Scalars['String'];
  /** Basilisk UID, typically the admin email address */
  uid: Scalars['String'];
};

export type ListAssessmentTasksInput = {
  /** The end date used when selecting resources to run for this task */
  endDate?: Maybe<Scalars['String']>;
  /** The limit of assessment tasks to run. */
  limit?: Maybe<Scalars['Float']>;
  /** The start date used when selecting resources to run for this task */
  startDate?: Maybe<Scalars['String']>;
};

export type ListFacetsInput = {
  /** The facet name */
  name?: Maybe<Scalars['String']>;
  /** The type of association to filter by */
  type?: Maybe<Scalars['String']>;
  /** The ID of the associated record to filter by */
  typeId?: Maybe<Scalars['String']>;
};

export type ListRuleAssessmentsInput = {
  limit?: Maybe<Scalars['Float']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type ListRulesInput = {
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<RuleStatus>;
};

export type ListScheduledIamMessagesResponse = {
  __typename?: 'ListScheduledIamMessagesResponse';
  /** List of scheduled messages */
  scheduledMessages: Array<InAppScheduledMessage>;
  /** Returned list length */
  totalCount: Scalars['Float'];
};

export type ListScheduledInAppMessagesInputDto = {
  /** Conversation ID of message */
  conversationId: Scalars['String'];
};

export type ListTagsInput = {
  deleted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ListUserTagsInput = {
  /** Facet name */
  facetName?: Maybe<Scalars['String']>;
  /** ISO 8601 date string */
  occurredFrom?: Maybe<Scalars['String']>;
  /** ISO 8601 date string */
  occurredTo?: Maybe<Scalars['String']>;
  /** Resource ID */
  resourceId?: Maybe<Scalars['String']>;
  /** User ID */
  user?: Maybe<Scalars['String']>;
};

export type ListWorkflowsModel = {
  __typename?: 'ListWorkflowsModel';
  errors: Array<WorkflowError>;
  results: Array<WorkflowPayload>;
};

export type LogoutModel = {
  __typename?: 'LogoutModel';
  success: Scalars['Boolean'];
};

export type MassMessage = {
  __typename?: 'MassMessage';
  /** Mass message body */
  body: Scalars['String'];
  /** In App Messaging Specific - the type of care provider leading the conversation (e.g. health_coaching, physical_therapy) */
  conversationType?: Maybe<MassMessageConversationType>;
  /** Timestamp of message creation date */
  createdAt: Scalars['String'];
  /** How the message will be delivered to the member (e.g. in_app or sms) */
  deliveryChannel: MassMessageDeliveryChannel;
  /** Scheduled Delivery Times in UTC */
  deliveryTimesUTC?: Maybe<Array<Scalars['String']>>;
  /** Care team provider UUID */
  from: Scalars['String'];
  /** Id of the Mass Message */
  id: Scalars['String'];
  /** List of Member Ids */
  memberIds: Array<Scalars['Float']>;
  /** The scheduled date time for the message to be sent as per member timezone */
  scheduledTimeForMember: Scalars['String'];
  /** Status of Mass Message e.g. Scheduled, Delivered etc */
  status: MassMessageStatus;
};

export type MassMessageBulkDeleteError = {
  __typename?: 'MassMessageBulkDeleteError';
  message: Scalars['String'];
  uuid: Scalars['String'];
};

export type MassMessageBulkDeleteInput = {
  /** Mass messsage ids for bulk deletion */
  uuids: Array<Scalars['String']>;
};

export type MassMessageBulkDeleteResponse = {
  __typename?: 'MassMessageBulkDeleteResponse';
  /** Mass messsage ids deleted as part bulk deletion request */
  deleted: Array<Scalars['String']>;
  /** Errors related to each mass message uuid */
  errors?: Maybe<Array<MassMessageBulkDeleteError>>;
};

/** Conversation type for Mass Messages */
export enum MassMessageConversationType {
  GroupMessaging = 'GROUP_MESSAGING',
  GroupThreadFromCoach = 'GROUP_THREAD_FROM_COACH',
  GroupThreadFromPt = 'GROUP_THREAD_FROM_PT',
  HealthCoaching = 'HEALTH_COACHING',
  PhysicalTherapy = 'PHYSICAL_THERAPY',
  Sms = 'SMS'
}

export type MassMessageCount = {
  __typename?: 'MassMessageCount';
  /** Admin UUID for which count needs to be retreived */
  adminUuid: Scalars['String'];
  /** InApp scheduled Mass Messages count */
  scheduledMessagesCountInApp: Scalars['Float'];
  /** Sms scheduled Mass Messages count */
  scheduledMessagesCountInSms: Scalars['Float'];
  /** InApp delivered Mass Messages count */
  sentMessagesCountInApp: Scalars['Float'];
  /** Sms delivered Mass Messages count */
  sentMessagesCountInSms: Scalars['Float'];
};

/** Delivery channel of Mass Messages */
export enum MassMessageDeliveryChannel {
  InApp = 'IN_APP',
  Sms = 'SMS'
}

export type MassMessageRemoveMemberInput = {
  /** MassMessage UUID for which the members are to be removed */
  massMessageId: Scalars['String'];
  /** List Of Member Ids to be removed */
  memberIds: Array<Scalars['Float']>;
};

/** Sort order of mass messages */
export enum MassMessageSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Status of the Mass Message */
export enum MassMessageStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Preparing = 'PREPARING',
  Scheduled = 'SCHEDULED'
}

export type MassMessages = {
  __typename?: 'MassMessages';
  /** The identifier of this list request, for pagination */
  id: Scalars['String'];
  /** Array containing list of Mass Messages */
  massMessages: Array<MassMessage>;
  /** Page number */
  pageNumber: Scalars['Float'];
  /** Page size */
  pageSize: Scalars['Float'];
  /** Total number of Mass Messages */
  totalCount: Scalars['Float'];
};

export type MemberAlert = {
  __typename?: 'MemberAlert';
  /** The admin uuid of the member alert */
  adminUuid: Scalars['ID'];
  /** The category of the member alert */
  category: MemberAlertCategory;
  /** The date that the member alert is created in the format of a timestamp */
  createdAt: Scalars['String'];
  /** The description of the member alert */
  description?: Maybe<Scalars['String']>;
  /** The date that the member alert ends in the format of a timestamp */
  endAt: Scalars['String'];
  /** UUID (PK) of the member alert */
  id: Scalars['ID'];
  /** if member alert is paused */
  paused?: Maybe<Scalars['Boolean']>;
  /** The date that the coach is reminded of a member alert in the format of a timestamp */
  remindAt?: Maybe<Scalars['String']>;
  /** The date that the member alert starts in the format of a timestamp */
  startAt: Scalars['String'];
  /** The date that the member alert is updated in the format of a timestamp */
  updatedAt: Scalars['String'];
  /** The uuid of the admin or member who last updated the alert */
  updatedByUuid?: Maybe<Scalars['ID']>;
  /** The user uuid of the member alert */
  userUuid: Scalars['ID'];
};

export type MemberAlertAdminRecord = {
  __typename?: 'MemberAlertAdminRecord';
  /** Full name of the admin */
  name: Scalars['String'];
  /** UUID of the admin */
  uuid: Scalars['ID'];
};

export type MemberAlertAdminsResponse = {
  __typename?: 'MemberAlertAdminsResponse';
  /** An array of admins containing uuid and full name */
  admins: Array<MemberAlertAdminRecord>;
};

/** Possible categories of member alerts */
export enum MemberAlertCategory {
  ClinicalIssues = 'ClinicalIssues',
  FamilyEmergency = 'FamilyEmergency',
  Other = 'Other',
  Sick = 'Sick',
  TechIssues = 'TechIssues',
  Vacation = 'Vacation'
}

/** Filter member alert start and end dates by status */
export enum MemberAlertStatusEnum {
  Active = 'Active',
  Expired = 'Expired',
  Upcoming = 'Upcoming'
}

export type MemberAlertsData = {
  __typename?: 'MemberAlertsData';
  /** count of total records */
  count: Scalars['Int'];
  /** Whether there are more items to request */
  hasNextPage: Scalars['Boolean'];
  /** The items requested per page */
  itemsPerPage: Scalars['Int'];
  /** A paginated list of member alerts */
  memberAlerts: Array<MemberAlert>;
  /** The page number requested (Eg: page 4 of 30) */
  page: Scalars['Int'];
};

export type MemberCareCondition = {
  __typename?: 'MemberCareCondition';
  applicationUrn: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
  /** Indications of a member */
  indications: Array<MemberCareIndication>;
  /** The kind of the condition */
  kind: Scalars['String'];
  /** Basilisk user UUID */
  memberId: Scalars['String'];
  /** Unique identifier for this revision */
  revision: Scalars['Float'];
  updatedAt?: Maybe<Scalars['String']>;
  /** The urn of the condition */
  urn: Scalars['String'];
};

export type MemberCareIndication = {
  __typename?: 'MemberCareIndication';
  /** Can be acute,chronic etc */
  acuity: Scalars['String'];
  bodyPart: Scalars['String'];
  createdAt: Scalars['String'];
  isActive: Scalars['String'];
  /** The kind of indication, body part, whole body, etc */
  kind: Scalars['String'];
  memberFunctionalActivities?: Maybe<Array<MemberFunctionalActivity>>;
  pregnancy?: Maybe<PregnancyDetails>;
  /** Priority from 0 where 0 is the highest priority. If 2 element are the same priority they can have the same value */
  priority: Scalars['Float'];
  updatedAt: Scalars['String'];
  urn: Scalars['String'];
};

export type MemberDifficulties = {
  __typename?: 'MemberDifficulties';
  difficulties: Array<Difficulty>;
  memberUuid: Scalars['String'];
};

export type MemberFunctionalActivity = {
  __typename?: 'MemberFunctionalActivity';
  createdAt?: Maybe<Scalars['String']>;
  /** description: 'That unique activity urn -> urn:functional-activity:kneeling', */
  functionalActivityUrn: Scalars['String'];
  /** A boolean to indicate whether the member was to focus on this functional activity or not */
  isFocus: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['String']>;
  urn: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  /** The message body */
  body?: Maybe<Scalars['String']>;
};

export type MessageBasiliskType = {
  /** The message body */
  body: Scalars['String'];
  /** The message direction */
  direction?: Maybe<Scalars['String']>;
  /** The ID of the message */
  id?: Maybe<Scalars['ID']>;
  /** The scheduled date time for the message to be sent */
  scheduledFor?: Maybe<Scalars['String']>;
  /** The date time when the message was seen */
  seenAt?: Maybe<Scalars['String']>;
};

export type MessageFeedbackInputDto = {
  /** The feedback for message response */
  feedback: Scalars['String'];
  /** The UUID of the message response to which this feedback is related */
  messageResponseUuid: Scalars['String'];
};

export type MilestoneConditionType = {
  __typename?: 'MilestoneConditionType';
  /** The function inside the DAG to be called of the MilestoneConditionType item */
  function: Scalars['String'];
  /** The threshold to be passed to the function as an argument of the MilestoneConditionType item */
  threshold?: Maybe<Scalars['Float']>;
};

export type MilestonesType = {
  __typename?: 'MilestonesType';
  /** The billable activity types of the MilestonesType item */
  billableActivityTypes: Array<Scalars['String']>;
  /** The different milestone conditions of the MilestonesType item */
  conditions: Array<MilestoneConditionType>;
  /** The date of service offset in days of the MilestonesType item */
  dateOfServiceOffset: Scalars['Float'];
  /** The day range of the MilestonesType item */
  dayRange: Array<Scalars['Float']>;
  /** The name of the MilestonesType item */
  name: Scalars['String'];
  /** The payment of the MilestonesType item */
  payment: Scalars['Float'];
};

export type MotionDefinitionData = {
  __typename?: 'MotionDefinitionData';
  imageSlug: Scalars['String'];
  motionRanges?: Maybe<Array<MotionRange>>;
  motionViews?: Maybe<Array<MotionViewData>>;
  order: Scalars['Int'];
  shouldMirror: Scalars['Boolean'];
  text: Scalars['String'];
  thumbnail?: Maybe<Asset>;
  type: MotionDefinitionType;
  uuid: Scalars['String'];
  verbalCues: Array<VerbalCue>;
};

export enum MotionDefinitionType {
  Flex = 'FLEX',
  Hold = 'HOLD',
  Rest = 'REST'
}

export type MotionRange = {
  __typename?: 'MotionRange';
  createdAt: Scalars['String'];
  eulerPlane: EulerKey;
  limb: SensorPlacement;
  max: Scalars['Int'];
  min: Scalars['Int'];
  priority: Scalars['Int'];
  updatedAt: Scalars['String'];
  uuid: Scalars['String'];
};

export type MotionViewData = {
  __typename?: 'MotionViewData';
  data: SensorlessMotionData;
  priority: Scalars['Int'];
  sensorset: Scalars['String'];
  type: MotionViewType;
  uuid: Scalars['String'];
};

export enum MotionViewType {
  Curve = 'CURVE',
  Macro = 'MACRO',
  OneHinge = 'ONE_HINGE',
  Sensorless = 'SENSORLESS',
  TwoHinge = 'TWO_HINGE'
}

export type MultiIndicationHealthLog = {
  __typename?: 'MultiIndicationHealthLog';
  /** Health log id */
  id?: Maybe<Scalars['Int']>;
  /** The list of indications for the member */
  indications?: Maybe<Array<Scalars['String']>>;
  /** Boolean for if user is MultiShim */
  isMultiShim?: Maybe<Scalars['Boolean']>;
  /** The user-submitted note with the health log */
  note?: Maybe<Scalars['String']>;
  /** The health log occurrence date time */
  occurred_at?: Maybe<Scalars['String']>;
  /** The pain details relevant to this health log */
  pain_reports?: Maybe<Array<MultiIndicationPainScores>>;
  /** The uuid of the associated user */
  user_uuid?: Maybe<Scalars['String']>;
  /** The health log uuid */
  uuid?: Maybe<Scalars['String']>;
};


export type MultiIndicationHealthLogIndicationsArgs = {
  memberUuid: Scalars['ID'];
};

export type MultiIndicationPainScores = {
  __typename?: 'MultiIndicationPainScores';
  /** Baseline Pain score */
  baselineScore?: Maybe<Scalars['Int']>;
  /** The body part name of the above pain score from MCDS/Basilisk */
  body_part?: Maybe<Scalars['String']>;
  /** Change in Pain Score */
  change?: Maybe<Scalars['Int']>;
  /** The pain report date time */
  date?: Maybe<Scalars['String']>;
  /** Pain Report id */
  id?: Maybe<Scalars['Int']>;
  /** The pain indication_urn of the above pain score */
  indication_urn?: Maybe<Scalars['String']>;
  /** If the pathway is active or not */
  isPathwayActive?: Maybe<Scalars['Boolean']>;
  /** The priority of the body part from MCDS */
  priority?: Maybe<Scalars['Float']>;
  /** The program name in which member is enrolled */
  programName?: Maybe<Scalars['String']>;
  /** Pain score */
  score?: Maybe<Scalars['Int']>;
  /** The uuid of the pain report */
  uuid?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkCreateUserWorkflowTransition: BulkWorkflowTransitionModel;
  bulkDeleteMassMessages: MassMessageBulkDeleteResponse;
  closeActionPlan: ActionPlan;
  coachAssistantReply: CoachAssistantReplyModel;
  createActionPlan: ActionPlan;
  createAndTransitionCareCoordinationWorkflows: CareCoordinationResponse;
  createAssessmentTask: AssessmentTask;
  createBatchContract: Array<BatchContractResponse>;
  createCarePlan: CarePlan;
  createCarePlanItem: CarePlan;
  createContract: ContractType;
  createDiscount: Array<DiscountDetail>;
  createExclusion: ExclusionModel;
  createFacet: Facet;
  createInAppMessage: InAppMessage;
  createInAppMessagingConversation: InAppMessagingConversation;
  createMemberAlert: MemberAlert;
  createMessageFeedback: CreateMessageFeedbackResponse;
  createRecommendation: Recommendation;
  createRule: Rule;
  createRuleAssessment: RuleAssessment;
  createSubstitution: SubstitutionModel;
  createTag: Tag;
  createTask: Task;
  createTemporaryCoverage: CreateTemporaryCoverageResponse;
  createTransition: WorkflowPayload;
  createUserTag: UserTag;
  createVideoCall: VideoCall;
  createVideoVisitToken: VideoVisitTokenResponse;
  createWorkflowDefinition: WorkflowDefinitionResponse;
  createWorkflows: CreatedWorkflowsModel;
  deleteExclusion: DeleteExclusionOutput;
  deleteMassMessage: DeleteMassMessageResponse;
  deleteMemberAlert?: Maybe<MemberAlert>;
  deleteMessage?: Maybe<Scalars['DeleteMessage']>;
  deleteRule: Rule;
  deleteScheduledInAppMessage: DeleteScheduledIamMessageResponse;
  deleteSubstitution: DeleteSubstitutionOutput;
  deleteTag: Tag;
  enrollUsers: EnrollUsersModel;
  joinAndScheduleMessage: InAppScheduledMessage;
  joinAndSendMessage: InAppMessage;
  logout: LogoutModel;
  markAsRead: Scalars['UpdatedMessage'];
  modifyExclusion: ExclusionModel;
  modifySubstitution: SubstitutionModel;
  postMessage: Scalars['CreatedMessage'];
  refreshJwt: TokenPayload;
  removeMembersFromMassMessage: MassMessage;
  scheduleInAppMessage: InAppScheduledMessage;
  scheduleMassMessage: MassMessage;
  updateActionPlan: ActionPlan;
  updateAssessmentTask: AssessmentTask;
  updateCarePlan: CarePlan;
  updateCarePlanItem: CarePlan;
  updateContract: ContractType;
  updateDiscount: Array<DiscountDetail>;
  updateMassMessage: MassMessage;
  updateMemberAlert: MemberAlert;
  updateMessage: Scalars['UpdatedMessage'];
  updateRecommendation: Recommendation;
  updateRule: Rule;
  updateScheduledInAppMessage: InAppScheduledMessage;
  updateTasks: Array<Task>;
  updateWorkflow: WorkflowPayload;
  updateWorkflowDefinition: WorkflowDefinitionResponse;
  updateWorkflowList: Array<WorkflowPayload>;
  upsertWorkflowDefinitions: WorkflowDefinitionsResponse;
  verifyGoogleToken: TokenPayload;
};


export type MutationBulkCreateUserWorkflowTransitionArgs = {
  bulkCreateTransitionInputDto: BulkCreateTransitionInputDto;
};


export type MutationBulkDeleteMassMessagesArgs = {
  massMessageBulkDeleteInput: MassMessageBulkDeleteInput;
};


export type MutationCloseActionPlanArgs = {
  closeReason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updatedBy: Scalars['String'];
};


export type MutationCoachAssistantReplyArgs = {
  input: CoachAssistantInputDto;
};


export type MutationCreateActionPlanArgs = {
  barriers?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  etGoalPerWeek: Scalars['Int'];
  goals: Scalars['String'];
  habitStack?: Maybe<Scalars['String']>;
  memberTimezone?: Maybe<Scalars['String']>;
  note: Scalars['String'];
  planDuration: Scalars['String'];
  planType: Scalars['String'];
  remindersActive: Scalars['Boolean'];
  remindersDays: Array<Scalars['Int']>;
  remindersMethod: Scalars['String'];
  remindersTime: Scalars['String'];
  userUuid: Scalars['String'];
};


export type MutationCreateAndTransitionCareCoordinationWorkflowsArgs = {
  createWorkflowInputDto: Array<CreateWorkflowInputDto>;
  workflowType?: Maybe<Scalars['String']>;
};


export type MutationCreateAssessmentTaskArgs = {
  createAssessmentTaskInput: CreateAssessmentTaskInput;
};


export type MutationCreateBatchContractArgs = {
  batchContractInput: CreateBatchContractInput;
};


export type MutationCreateCarePlanArgs = {
  adminId: Scalars['String'];
  closedAt?: Maybe<Scalars['String']>;
  dischargeReason?: Maybe<Scalars['String']>;
  expectedDischargeAt: Scalars['String'];
  icd10: Array<Scalars['String']>;
  indication: Scalars['String'];
  locale: Scalars['String'];
  openedAt: Scalars['String'];
  pathwayId: Scalars['String'];
  prescription: Scalars['String'];
  program: Scalars['String'];
  status: Scalars['String'];
  title: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationCreateCarePlanItemArgs = {
  adminId: Scalars['String'];
  carePlanId: Scalars['ID'];
  type: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreateContractArgs = {
  contractInput: CreateContractInput;
};


export type MutationCreateDiscountArgs = {
  discountInput: CreateDiscountInput;
};


export type MutationCreateExclusionArgs = {
  adminUuid: Scalars['String'];
  levelEnd: Scalars['Int'];
  levelStart: Scalars['Int'];
  memberUuid: Scalars['String'];
  reasonCreated?: Maybe<Scalars['String']>;
  targetId: Scalars['String'];
};


export type MutationCreateFacetArgs = {
  createFacetInput: CreateFacetInput;
};


export type MutationCreateInAppMessageArgs = {
  createMessageDto: CreateInAppMessageArgs;
};


export type MutationCreateInAppMessagingConversationArgs = {
  createConversationDto: CreateInAppMessagingConversationArgs;
};


export type MutationCreateMemberAlertArgs = {
  createMemberAlertInput: CreateMemberAlertInput;
};


export type MutationCreateMessageFeedbackArgs = {
  input: MessageFeedbackInputDto;
};


export type MutationCreateRecommendationArgs = {
  createRecommendationInput: CreateRecommendationInput;
};


export type MutationCreateRuleArgs = {
  createRuleInput: CreateRuleInput;
};


export type MutationCreateRuleAssessmentArgs = {
  createRuleAssessmentInput: CreateRuleAssessmentInput;
};


export type MutationCreateSubstitutionArgs = {
  adminUuid: Scalars['String'];
  levelEnd: Scalars['Int'];
  levelStart: Scalars['Int'];
  memberUuid: Scalars['String'];
  reasonCreated?: Maybe<Scalars['String']>;
  substituteExerciseSlug: Scalars['String'];
  targetExerciseSlug: Scalars['String'];
};


export type MutationCreateTagArgs = {
  createTagInput: CreateTagInput;
};


export type MutationCreateTaskArgs = {
  createTaskDto: CreateTaskDto;
};


export type MutationCreateTemporaryCoverageArgs = {
  adminUuid: Scalars['String'];
  userUuid: Scalars['String'];
};


export type MutationCreateTransitionArgs = {
  createTransitionInputDto: CreateTransitionInputDto;
  workflowType?: Maybe<Scalars['String']>;
};


export type MutationCreateUserTagArgs = {
  createUserTagInput: CreateUserTagInput;
};


export type MutationCreateVideoCallArgs = {
  createVideoCallInput: CreateVideoCallInput;
};


export type MutationCreateVideoVisitTokenArgs = {
  videoVisitTokenRequest: VideoVisitTokenRequest;
};


export type MutationCreateWorkflowDefinitionArgs = {
  workflowDefinition: WorkflowDefinitionInput;
};


export type MutationCreateWorkflowsArgs = {
  createWorkflowInputDto: Array<CreateWorkflowInputDto>;
  workflowType?: Maybe<Scalars['String']>;
};


export type MutationDeleteExclusionArgs = {
  reasonDeactivated?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};


export type MutationDeleteMassMessageArgs = {
  messageId: Scalars['String'];
};


export type MutationDeleteMemberAlertArgs = {
  deleteMemberAlertInput: DeleteMemberAlertInput;
};


export type MutationDeleteMessageArgs = {
  conversationId: Scalars['StringOrInt'];
  messageId?: Maybe<Scalars['String']>;
};


export type MutationDeleteRuleArgs = {
  id: Scalars['String'];
};


export type MutationDeleteScheduledInAppMessageArgs = {
  deleteScheduledInAppMessageInputDto: DeleteScheduledInAppMessageInputDto;
};


export type MutationDeleteSubstitutionArgs = {
  reasonDeactivated?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['String'];
};


export type MutationEnrollUsersArgs = {
  input: EnrollUsersInput;
};


export type MutationJoinAndScheduleMessageArgs = {
  scheduleInAppMessageInputDto: ScheduleInAppMessageInputDto;
};


export type MutationJoinAndSendMessageArgs = {
  createMessageDto: CreateInAppMessageArgs;
};


export type MutationMarkAsReadArgs = {
  adminUuid?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['StringOrInt']>;
  message: MessageBasiliskType;
  messageId?: Maybe<Scalars['StringOrInt']>;
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationModifyExclusionArgs = {
  adminUuid: Scalars['String'];
  levelEnd: Scalars['Int'];
  levelStart: Scalars['Int'];
  memberUuid: Scalars['String'];
  reasonCreated?: Maybe<Scalars['String']>;
  targetId: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationModifySubstitutionArgs = {
  adminUuid: Scalars['String'];
  levelEnd: Scalars['Int'];
  levelStart: Scalars['Int'];
  memberUuid: Scalars['String'];
  reasonCreated?: Maybe<Scalars['String']>;
  substituteExerciseSlug: Scalars['String'];
  targetExerciseSlug: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationPostMessageArgs = {
  conversationId: Scalars['StringOrInt'];
  message: NewMessageType;
};


export type MutationRemoveMembersFromMassMessageArgs = {
  massMessageRemoveMemberInput: MassMessageRemoveMemberInput;
};


export type MutationScheduleInAppMessageArgs = {
  scheduleInAppMessageInputDto: ScheduleInAppMessageInputDto;
};


export type MutationScheduleMassMessageArgs = {
  scheduleMassMessageInput: ScheduleMassMessageInput;
};


export type MutationUpdateActionPlanArgs = {
  barriers?: Maybe<Scalars['String']>;
  etGoalPerWeek?: Maybe<Scalars['Int']>;
  goals?: Maybe<Scalars['String']>;
  habitStack?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  remindersActive: Scalars['Boolean'];
  remindersDays: Array<Scalars['Int']>;
  remindersMethod: Scalars['String'];
  remindersTime: Scalars['String'];
  updatedBy: Scalars['String'];
};


export type MutationUpdateAssessmentTaskArgs = {
  id: Scalars['String'];
  updateAssessmentTaskInput: UpdateAssessmentTaskInput;
};


export type MutationUpdateCarePlanArgs = {
  carePlanId: Scalars['ID'];
  closedAt?: Maybe<Scalars['String']>;
  dischargeReason?: Maybe<Scalars['String']>;
  expectedDischargeAt?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationUpdateCarePlanItemArgs = {
  carePlanId: Scalars['ID'];
  id: Scalars['ID'];
  status: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUpdateContractArgs = {
  contractId: Scalars['Float'];
  contractInput: UpdateContractInput;
};


export type MutationUpdateDiscountArgs = {
  discountInput: UpdateDiscountInput;
};


export type MutationUpdateMassMessageArgs = {
  updateMassMessage: UpdateMassMessageInput;
};


export type MutationUpdateMemberAlertArgs = {
  updateMemberAlertInput: UpdateMemberAlertInput;
};


export type MutationUpdateMessageArgs = {
  adminUuid?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['StringOrInt']>;
  message: MessageBasiliskType;
  messageId?: Maybe<Scalars['StringOrInt']>;
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationUpdateRecommendationArgs = {
  updateRecommendationInput: UpdateRecommendationInput;
};


export type MutationUpdateRuleArgs = {
  id: Scalars['String'];
  updateRuleInput: UpdateRuleInput;
};


export type MutationUpdateScheduledInAppMessageArgs = {
  updateScheduledInAppMessageInputDto: UpdateScheduledInAppMessageInputDto;
};


export type MutationUpdateTasksArgs = {
  updateTasksDto: UpdateTasksDto;
};


export type MutationUpdateWorkflowArgs = {
  updateWorkflowInputDto: UpdateWorkflowInputDto;
  workflowType?: Maybe<Scalars['String']>;
};


export type MutationUpdateWorkflowDefinitionArgs = {
  workflowDefinition: WorkflowDefinitionInput;
};


export type MutationUpdateWorkflowListArgs = {
  updateWorkflowListInputDto: UpdateWorkflowListInputDto;
  workflowListFiltersInputDto?: Maybe<WorkflowListFiltersInputDto>;
};


export type MutationUpsertWorkflowDefinitionsArgs = {
  workflowDefinitions: Array<WorkflowDefinitionInput>;
};


export type MutationVerifyGoogleTokenArgs = {
  verifyInput: VerifyInputDto;
};

export type NewMessageType = {
  /** The coach uuid */
  adminUuid: Scalars['String'];
  /** The message body */
  body: Scalars['String'];
  /** The message schedule date */
  scheduledFor?: Maybe<Scalars['String']>;
  /** The member phone number */
  userPhoneNumber: Scalars['String'];
  /** The message timezone */
  userTimezone?: Maybe<Scalars['String']>;
  /** The user uuid */
  userUuid: Scalars['String'];
};

export type OriginalExerciseData = {
  __typename?: 'OriginalExerciseData';
  name: Scalars['String'];
  order: Scalars['Int'];
  originalActivityUuid: Scalars['String'];
  uuid: Scalars['String'];
};

export type PackageType = {
  __typename?: 'PackageType';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  lastModifiedBy: Scalars['String'];
  name: Scalars['String'];
  packageBillableActivities: Array<EngagementType>;
  updatedAt: Scalars['String'];
};

export type PaginatedBillType = {
  __typename?: 'PaginatedBillType';
  billAmount: Scalars['Float'];
  billId: Scalars['Float'];
  claimId: Scalars['String'];
  clientsInsurerId: Scalars['Float'];
  dateOfService: Scalars['String'];
  holdDetails: HoldDetailsType;
  subscriptionId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type PainDetails = {
  __typename?: 'PainDetails';
  /** The baseline pain score */
  baselineScore: Scalars['Int'];
  /** The current pain history point */
  current: PainHistoryPoint;
  /** The last reported pain history point */
  lastReported?: Maybe<PainHistoryPoint>;
  /** The last uptick pain history point */
  lastUptick?: Maybe<PainHistoryPoint>;
};

export type PainHistoryPoint = {
  __typename?: 'PainHistoryPoint';
  /** The change in pain score */
  change: Scalars['Int'];
  /** The date-time as an ISO8601 string */
  date: Scalars['String'];
  /** The pain score */
  score: Scalars['Int'];
  /** The 1-indexed week number */
  weekNum: Scalars['Int'];
};

export type Participant = {
  __typename?: 'Participant';
  /** createdAt */
  createdAt?: Maybe<Scalars['String']>;
  /** id */
  id?: Maybe<Scalars['String']>;
  /** participantType */
  participantType?: Maybe<ParticipantType>;
  /** participantUuid */
  participantUuid?: Maybe<Scalars['String']>;
  /** pathwayUuid */
  pathwayUuid?: Maybe<Scalars['String']>;
  /** updatedAt */
  updatedAt?: Maybe<Scalars['String']>;
  /** videoCallId */
  videoCallId?: Maybe<Scalars['String']>;
};

/** Type of participant */
export enum ParticipantType {
  Admin = 'Admin',
  User = 'User'
}

export type PartnershipType = {
  __typename?: 'PartnershipType';
  /** The allowCohortExpansion of the Partnership item */
  allowCohortExpansion: Scalars['Boolean'];
  /** The id of the Partnership item */
  id: Scalars['Int'];
  /** The name of the Partnership item */
  name: Scalars['String'];
  /** The Partnership type */
  partnershipType: Scalars['String'];
  /** The requiresStateCredentials of the Partnership item */
  requiresStateCredentials: Scalars['Boolean'];
};

export type Pathway = {
  __typename?: 'Pathway';
  /** The name of the pathway indication, e.g. knee */
  indication: Scalars['String'];
  /** The name of the pathway program, e.g. acute */
  program: Scalars['String'];
  /** The screening of the pathway */
  screening: Screening;
  /** The total points completed in the pathway */
  totalPoints: Scalars['Float'];
};

export type PaymentType = {
  __typename?: 'PaymentType';
  /** The code of the PaymentType item */
  code: Scalars['String'];
  /** The created date time value of the PaymentType item */
  createdAt: Scalars['String'];
  /** The id of the PaymentType item */
  id: Scalars['Int'];
  /** The name of the PaymentType item */
  name: Scalars['String'];
  /** The updated date time value of the PaymentType item */
  updatedAt: Scalars['String'];
};

export type PeriSurgicalDetails = {
  __typename?: 'PeriSurgicalDetails';
  /** Surgery Date */
  surgeryDate?: Maybe<Scalars['String']>;
  /** Surgery Type */
  surgeryType?: Maybe<Scalars['String']>;
};

export type PregnancyDetails = {
  __typename?: 'PregnancyDetails';
  createdAt: Scalars['String'];
  /** The users reported due date */
  dueDate: Scalars['String'];
  /** The users reported end date */
  endDate?: Maybe<Scalars['String']>;
  /** The reason attached to the users reported end date */
  endReason?: Maybe<PregnancyEndReasonType>;
  /** The calculated current week of the pregnancy, before birth */
  gestationWeek: Scalars['Int'];
  id: Scalars['ID'];
  /** The calculated current week of the overall pregnancy, gestationWeek + postPartumWeek */
  maternityWeek: Scalars['Int'];
  pathwayUuid: Scalars['String'];
  /** The calculated current week of the pregnancy, after birth */
  postpartumWeek?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['String'];
  userUuid: Scalars['String'];
};

export type PregnancyDetailsGetInput = {
  pathwayUuid: Scalars['ID'];
};

/** The possible end reasons for a pregnancy. */
export enum PregnancyEndReasonType {
  Birth = 'birth',
  Loss = 'loss'
}

export type Preview = {
  __typename?: 'Preview';
  imageSlug: Scalars['String'];
  message: Scalars['String'];
  thumbnail?: Maybe<Asset>;
  verbalCue: VerbalCue;
  videos: Array<PreviewVideoData>;
};

export type PreviewVideoData = {
  __typename?: 'PreviewVideoData';
  slug: Scalars['String'];
  videoType: VideoType;
};

export type PrimaryCoveringAdmins = {
  __typename?: 'PrimaryCoveringAdmins';
  /** primary health coach */
  healthCoach?: Maybe<UserCoveringAdmin>;
  /** primary physical therapist */
  physicalTherapist?: Maybe<UserCoveringAdmin>;
};

export type PrimaryUserCoverages = {
  __typename?: 'PrimaryUserCoverages';
  /** Member primary coverages object */
  coverages: PrimaryCoveringAdmins;
};

export type ProcedureCodeInput = {
  /** The contract id of the ProcedureCodesType item */
  contractId?: Maybe<Scalars['Float']>;
  /** The CPT code of the ProcedureCodesType item */
  cptCode: Scalars['String'];
  /** The modifier of the ProcedureCodesType item */
  modifier1?: Maybe<Scalars['String']>;
  /** The modifier of the ProcedureCodesType item */
  modifier2?: Maybe<Scalars['String']>;
  /** The payment type id of the ProcedureCodesType item */
  paymentTypeId: Scalars['Float'];
};

export type ProcedureCodesType = {
  __typename?: 'ProcedureCodesType';
  /** The contract id of the ProcedureCodesType item */
  contractId: Scalars['Float'];
  /** The CPT code of the ProcedureCodesType item */
  cptCode: Scalars['String'];
  /** The created date time value of the ProcedureCodesType item */
  createdAt: Scalars['String'];
  /** The id of the ProcedureCodesType item */
  id: Scalars['Int'];
  /** The modifier of the ProcedureCodesType item */
  modifier1?: Maybe<Scalars['String']>;
  /** The modifier of the ProcedureCodesType item */
  modifier2?: Maybe<Scalars['String']>;
  /** The payment type id of the ProcedureCodesType item */
  paymentTypeId: Scalars['Float'];
  /** The payment type of the ProcedureCodesType item */
  paymentTypes: PaymentType;
  /** The updated date time value of the ProcedureCodesType item */
  updatedAt: Scalars['String'];
};

export type ProgramExceptionsType = {
  __typename?: 'ProgramExceptionsType';
  /** The identifier of the ProgramExceptionsType item */
  programName: Scalars['String'];
};

export type ProgramIndicationType = {
  __typename?: 'ProgramIndicationType';
  /** The id of ProgramIndicationType item */
  id: Scalars['Int'];
  /** The identifier of ProgramIndicationType item */
  identifier: Scalars['String'];
  /** The indicationName of ProgramIndicationType item */
  indicationName: Scalars['String'];
  /** The programId of the ProgramIndicationType item */
  programId: Scalars['Int'];
  /** The programName of ProgramIndicationType item */
  programName: Scalars['String'];
};

export type Progression = {
  __typename?: 'Progression';
  activities?: Maybe<Array<EtSessionActivity>>;
  createdAt: Scalars['String'];
  current?: Maybe<Scalars['Boolean']>;
  /** Program specific metadata */
  data: ProgressionMetaData;
  etpeVersion?: Maybe<Scalars['String']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  updatedAt: Scalars['String'];
  uuid: Scalars['String'];
  wasModifiedByAdmin: Scalars['Boolean'];
};

export type ProgressionMetaData = ProgressionMetaDataAcute | ProgressionMetaDataChronic | ProgressionMetaDataPerisurgical;

export type ProgressionMetaDataAcute = {
  __typename?: 'ProgressionMetaDataAcute';
  frequency: Scalars['Int'];
};

export type ProgressionMetaDataChronic = {
  __typename?: 'ProgressionMetaDataChronic';
  etSessionPoints: Scalars['Int'];
  numEtSessions: Scalars['Int'];
  totalLevelPoints: Scalars['Int'];
};

export type ProgressionMetaDataPerisurgical = {
  __typename?: 'ProgressionMetaDataPerisurgical';
  frequency: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  activityPlansGetByUser: Array<ActivityPlan>;
  admin: Admin;
  carePlanConfig: CarePlanConfig;
  careTeam: Array<CareTeamCoverageInfo>;
  chainsGetAll: Array<Chain>;
  conversations: Scalars['ConversationList'];
  countWorkflows: WorkflowCountModel;
  countWorkflowsCompletedToday: WorkflowsCompletedTodayCountModel;
  countWorkflowsWithWorkflowTypes: WorkflowCountModel;
  customizationsByUserGetAll: Array<ExerciseRule>;
  distinctAdminUuids: MemberAlertAdminsResponse;
  emoAdmins: Array<EmoAdmin>;
  emoEligibility: EmoEligibilityList;
  exercisesGetAll: Array<ExerciseConfig>;
  findAdmins: Array<Admin>;
  findAllMemberAlerts: MemberAlertsData;
  getAllBodyParts: Array<Scalars['String']>;
  getAllEngagementTypes: Array<EngagementType>;
  getAllMemberConditions: Array<MemberCareCondition>;
  getAllPackages: Array<PackageType>;
  getAssessmentTask: AssessmentTask;
  getBills: BillsPaginatedType;
  getBillsBySubscriptionId: Array<BillSubmissionsType>;
  getClientInsurerById: ClientInsurerType;
  getClientInsurersByClient: ClientInsurerByClientType;
  getClientInsurersByIds: Array<BatchClientInsurerType>;
  getConsultResultById: ConsultResultType;
  getContractSubscriptions: ContractSubscriptionType;
  getContractTemplates: Array<ContractTemplateType>;
  getContracts: Array<ContractType>;
  getCvsEligibility: CvsResponseType;
  getDiscountUsage: Array<DiscountUsage>;
  getDiscounts: Array<DiscountDetail>;
  getEnsoTwoWeeksUsage: EnsoTwoWeeksUsageResponse;
  getFacet: Facet;
  getFunctionalActivities: Array<FunctionalActivityDto>;
  getInAppMessagingUser: InAppMessagingUser;
  getInsights: GetInsightsResponse;
  getInsightsForActionPlan: ActionPlanInsights;
  getIroncladPdf: IroncladPdf;
  getMassMessage: MassMessage;
  getMassMessageCount: MassMessageCount;
  getMassMessages: MassMessages;
  getMemberDifficulties: MemberDifficulties;
  getMessages: Scalars['MessageList'];
  getMostRecentByPatientUuids: ListWorkflowsModel;
  getProgramIndicationsById: Array<ProgramIndicationType>;
  getRecommendation: Recommendation;
  getRule: Rule;
  getRuleAssessment: RuleAssessment;
  getScheduledInAppMessages: ListScheduledIamMessagesResponse;
  getScheduledMessages: Scalars['MessageList'];
  getSubscriptionEngagements: EngagementsPaginationType;
  getSubscriptions: SubscriptionsPaginationType;
  getUserCoverages: PrimaryUserCoverages;
  getUserTag: UserTag;
  getVideoCalls: Array<VideoCall>;
  getWorkflow: WorkflowPayload;
  getWorkflowsByFilter: WorkflowListPayload;
  healthLog: HealthLog;
  healthLogV2: MultiIndicationHealthLog;
  healthLogWithPainScores: MultiIndicationHealthLog;
  listAssessmentTasks: AssessmentTaskList;
  listFacets: FacetsList;
  listRuleAssessments: RuleAssessmentsList;
  listRules: RulesList;
  listTags: TagsList;
  listUserTags: UserTagsList;
  pregnancyDetailsGet: PregnancyDetails;
  primaryAdmin: Admin;
  searchUsers: SearchUsers;
  user: User;
  userAQInfo: UserAqData;
  userEduContext: UserEducation;
  userEngagementStreak: UserEngagementStreak;
};


export type QueryActivityPlansGetByUserArgs = {
  userUuid: Scalars['String'];
};


export type QueryCareTeamArgs = {
  userUuid: Scalars['String'];
};


export type QueryChainsGetAllArgs = {
  version?: Maybe<Scalars['String']>;
};


export type QueryConversationsArgs = {
  adminUuid?: Maybe<Scalars['String']>;
  itemsPerPage: Scalars['Int'];
  page: Scalars['Int'];
  types: Array<Scalars['String']>;
  userIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  userUuids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryCountWorkflowsArgs = {
  countWorkflowFiltersInputDto: CountWorkflowFiltersInputDto;
  workflowType?: Maybe<Scalars['String']>;
};


export type QueryCountWorkflowsCompletedTodayArgs = {
  countWorkflowsFiltersInputDto: CountWorkflowsFiltersInputDto;
};


export type QueryCountWorkflowsWithWorkflowTypesArgs = {
  countWorkflowsFiltersInputDto: CountWorkflowsFiltersInputDto;
};


export type QueryCustomizationsByUserGetAllArgs = {
  currentLevel?: Maybe<Scalars['Int']>;
  memberUuid: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};


export type QueryDistinctAdminUuidsArgs = {
  input: GetDistinctAdminUuidsInput;
};


export type QueryEmoEligibilityArgs = {
  userIds: Array<Scalars['Int']>;
};


export type QueryExercisesGetAllArgs = {
  status?: Maybe<Scalars['String']>;
};


export type QueryFindAdminsArgs = {
  itemsPerPage?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Float']>;
  roles: Array<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
};


export type QueryFindAllMemberAlertsArgs = {
  getMemberAlertInput: GetMemberAlertInput;
};


export type QueryGetAllMemberConditionsArgs = {
  kind: Scalars['String'];
  memberId: Scalars['String'];
};


export type QueryGetAssessmentTaskArgs = {
  id: Scalars['String'];
};


export type QueryGetBillsArgs = {
  claimIds?: Maybe<Array<Scalars['String']>>;
  clientInsurerIds?: Maybe<Array<Scalars['Float']>>;
  endDate?: Maybe<Scalars['String']>;
  internalHoldTypes?: Maybe<Array<Scalars['String']>>;
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  subscriptionIds?: Maybe<Array<Scalars['Float']>>;
  userIds?: Maybe<Array<Scalars['Float']>>;
};


export type QueryGetBillsBySubscriptionIdArgs = {
  subscriptionId: Scalars['Float'];
};


export type QueryGetClientInsurerByIdArgs = {
  id: Scalars['Float'];
};


export type QueryGetClientInsurersByClientArgs = {
  id: Scalars['String'];
};


export type QueryGetClientInsurersByIdsArgs = {
  ids: Array<Scalars['Int']>;
};


export type QueryGetConsultResultByIdArgs = {
  consultId: Scalars['String'];
};


export type QueryGetContractSubscriptionsArgs = {
  clientsInsurerId?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['Float']>;
  contractPackageIds?: Maybe<Array<Scalars['Int']>>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};


export type QueryGetContractTemplatesArgs = {
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetContractsArgs = {
  billableActivityDisabled?: Maybe<Scalars['Boolean']>;
  clientId?: Maybe<Scalars['Float']>;
  clientsInsurerId?: Maybe<Scalars['Float']>;
  includeVoided?: Maybe<Scalars['Boolean']>;
  insurerId?: Maybe<Scalars['Float']>;
};


export type QueryGetCvsEligibilityArgs = {
  getCvsEligibilityInput: GetCvsEligibilityInput;
};


export type QueryGetDiscountUsageArgs = {
  clientId?: Maybe<Scalars['Float']>;
  discountDetailIds?: Maybe<Array<Scalars['Int']>>;
  partnershipId?: Maybe<Scalars['Float']>;
};


export type QueryGetDiscountsArgs = {
  clientId?: Maybe<Scalars['Float']>;
  discountDefinitionId?: Maybe<Scalars['Float']>;
  discountDetailId?: Maybe<Scalars['Float']>;
  discountType?: Maybe<Scalars['String']>;
  inquiryDate?: Maybe<Scalars['String']>;
  insurerId?: Maybe<Scalars['Float']>;
  packageId?: Maybe<Scalars['Float']>;
  partnershipId?: Maybe<Scalars['Float']>;
  partnershipIds?: Maybe<Array<Scalars['Int']>>;
  programId?: Maybe<Scalars['Float']>;
  scopeType?: Maybe<ScopeType>;
};


export type QueryGetEnsoTwoWeeksUsageArgs = {
  GetEnsoTwoWeeksUsageInput: GetEnsoTwoWeeksUsageInput;
};


export type QueryGetFacetArgs = {
  id: Scalars['String'];
};


export type QueryGetFunctionalActivitiesArgs = {
  member_id: Scalars['String'];
};


export type QueryGetInsightsArgs = {
  getInsightsInputDto: GetInsightsInputDto;
};


export type QueryGetInsightsForActionPlanArgs = {
  userUuid: Scalars['String'];
};


export type QueryGetIroncladPdfArgs = {
  docId: Scalars['String'];
};


export type QueryGetMassMessageArgs = {
  messageId: Scalars['String'];
};


export type QueryGetMassMessageCountArgs = {
  adminUUID: Scalars['String'];
};


export type QueryGetMassMessagesArgs = {
  getMassMessageInput: GetMassMessagesInput;
};


export type QueryGetMemberDifficultiesArgs = {
  memberId: Scalars['ID'];
};


export type QueryGetMessagesArgs = {
  coachId?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['StringOrInt']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryGetMostRecentByPatientUuidsArgs = {
  adminUuids?: Maybe<Array<Scalars['ID']>>;
  patientUuids?: Maybe<Array<Scalars['ID']>>;
  workflowType?: Maybe<Scalars['String']>;
};


export type QueryGetProgramIndicationsByIdArgs = {
  programIds?: Maybe<Array<Scalars['Int']>>;
};


export type QueryGetRecommendationArgs = {
  id: Scalars['Float'];
};


export type QueryGetRuleArgs = {
  id: Scalars['String'];
};


export type QueryGetRuleAssessmentArgs = {
  id: Scalars['String'];
};


export type QueryGetScheduledInAppMessagesArgs = {
  listScheduledInAppMessagesInputDto: ListScheduledInAppMessagesInputDto;
};


export type QueryGetScheduledMessagesArgs = {
  coachId?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['StringOrInt']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  scheduled?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryGetSubscriptionEngagementsArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  subscriptionId: Scalars['Float'];
};


export type QueryGetSubscriptionsArgs = {
  includeVoid?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  userId: Scalars['Float'];
};


export type QueryGetUserCoveragesArgs = {
  userUuid: Scalars['ID'];
};


export type QueryGetUserTagArgs = {
  id: Scalars['String'];
};


export type QueryGetVideoCallsArgs = {
  userUuid: Scalars['String'];
};


export type QueryGetWorkflowArgs = {
  id: Scalars['Int'];
  workflowType?: Maybe<Scalars['String']>;
};


export type QueryGetWorkflowsByFilterArgs = {
  workflowFiltersInputDto: WorkflowListFiltersInputDto;
  workflowType?: Maybe<Scalars['String']>;
};


export type QueryHealthLogArgs = {
  uuid: Scalars['ID'];
};


export type QueryHealthLogV2Args = {
  uuid: Scalars['ID'];
};


export type QueryHealthLogWithPainScoresArgs = {
  date?: Maybe<Scalars['String']>;
  memberId: Scalars['ID'];
};


export type QueryListAssessmentTasksArgs = {
  listAssessmentTasksInput?: Maybe<ListAssessmentTasksInput>;
};


export type QueryListFacetsArgs = {
  listFacetsInput?: Maybe<ListFacetsInput>;
};


export type QueryListRuleAssessmentsArgs = {
  listRuleAssessmentsInput?: Maybe<ListRuleAssessmentsInput>;
};


export type QueryListRulesArgs = {
  listRulesInput?: Maybe<ListRulesInput>;
};


export type QueryListTagsArgs = {
  listTagsInput?: Maybe<ListTagsInput>;
};


export type QueryListUserTagsArgs = {
  listUserTagsInput: ListUserTagsInput;
};


export type QueryPregnancyDetailsGetArgs = {
  pregnancyDetailsGetInput: PregnancyDetailsGetInput;
};


export type QueryPrimaryAdminArgs = {
  uuid: Scalars['ID'];
};


export type QuerySearchUsersArgs = {
  endCursor?: Maybe<Scalars['String']>;
  filters?: Maybe<SearchUsersFilterObject>;
  perPage?: Maybe<Scalars['String']>;
  startCursor?: Maybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  uuid?: Maybe<Scalars['ID']>;
};


export type QueryUserAqInfoArgs = {
  id?: Maybe<Scalars['ID']>;
  uuid?: Maybe<Scalars['ID']>;
};


export type QueryUserEduContextArgs = {
  id?: Maybe<Scalars['ID']>;
  uuid?: Maybe<Scalars['ID']>;
};


export type QueryUserEngagementStreakArgs = {
  id?: Maybe<Scalars['ID']>;
  uuid?: Maybe<Scalars['ID']>;
};

export type Recommendation = {
  __typename?: 'Recommendation';
  id: Scalars['Float'];
  ironcladFields?: Maybe<IronCladFields>;
  ironcladId?: Maybe<Scalars['String']>;
  llmResponse?: Maybe<LlmResponse>;
  sfdcFields?: Maybe<SfdcFields>;
  source?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type RemitBaseType = {
  __typename?: 'RemitBaseType';
  createdAt: Scalars['String'];
  icn?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  patientPaymentAmount: Scalars['Float'];
  payer: Scalars['String'];
  paymentAmount: Scalars['Float'];
  paymentDate?: Maybe<Scalars['String']>;
  paymentNumber?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  responseDate: Scalars['String'];
  sourceOfRecord: Scalars['String'];
};

export enum RepType {
  Alternating = 'ALTERNATING',
  Bilateral = 'BILATERAL',
  MultiMotion = 'MULTI_MOTION',
  SwitchSides = 'SWITCH_SIDES',
  Unilateral = 'UNILATERAL'
}

export type Rule = {
  __typename?: 'Rule';
  actions?: Maybe<Array<ActionDefinition>>;
  createdAt: Scalars['String'];
  definition: RuleDefinition;
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  source: Scalars['String'];
  status: RuleStatus;
  updatedAt: Scalars['String'];
  version: Scalars['Float'];
};

export type RuleAssessment = {
  __typename?: 'RuleAssessment';
  assessedRules: Array<AssessedRule>;
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  resourceId: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  updatedAt: Scalars['String'];
  userId: Scalars['String'];
};

export type RuleAssessmentsList = {
  __typename?: 'RuleAssessmentsList';
  ruleAssessments: Array<RuleAssessment>;
  total: Scalars['Float'];
};

export type RuleDefinition = {
  __typename?: 'RuleDefinition';
  criteria: Array<Scalars['JSONObject']>;
  name: Scalars['String'];
  operator: Scalars['String'];
};

export type RuleDefinitionInput = {
  criteria: Array<Scalars['JSONObject']>;
  name: Scalars['String'];
  operator: Scalars['String'];
};

export enum RuleStatus {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type RulesList = {
  __typename?: 'RulesList';
  rules: Array<Rule>;
  total: Scalars['Float'];
};

export type SfdcFields = {
  __typename?: 'SFDCFields';
  acutePrice?: Maybe<Scalars['Float']>;
  billableActivityTypes: Array<Scalars['String']>;
  chronicPrice?: Maybe<Scalars['Float']>;
  contract?: Maybe<Array<MilestonesType>>;
  contractTemplateId?: Maybe<Scalars['Float']>;
};

export type ScheduleInAppMessageInputDto = {
  /** The body of the scheduled message */
  body: Scalars['String'];
  /** Conversation ID of message */
  conversationId: Scalars['String'];
  /** Scheduled message sent from */
  fromUuid: Scalars['String'];
  /** The UUID of the member */
  memberUuid: Scalars['String'];
  /** Timestamp of scheduled message to be sent */
  scheduledAt: Scalars['String'];
  /** The UUID of the member that scheduled a message */
  scheduledBy: Scalars['String'];
};

export type ScheduleMassMessageInput = {
  /** List Of Member Ids needs to be added to mass message */
  addMemberIds?: Maybe<Array<Scalars['Int']>>;
  /** Data (text) for the message. Supports interpolation */
  body: Scalars['String'];
  /** In App Messaging Specific - the type of care provider leading the conversation (e.g. health_coaching, physical_therapy) */
  conversationType?: Maybe<MassMessageConversationType>;
  /** How the message will be delivered to the member (e.g. in_app or sms) */
  deliveryChannel?: Maybe<MassMessageDeliveryChannel>;
  /** Care Team Provider UUID */
  from?: Maybe<Scalars['String']>;
  /** List Of Member Ids */
  memberIds: Array<Scalars['Float']>;
  /** The time a member should receive the message in UTC - ISO 8601. If you want all the members in this group to receive the message at 9am, you would submit: '2023-07-27T09:00:00Z'. The service will handle sending out the messages at 9 am for the given members timezone */
  scheduledTimeForMember: Scalars['String'];
};

export enum ScopeType {
  Exclusive = 'EXCLUSIVE',
  Inclusive = 'INCLUSIVE'
}

export type Screening = {
  __typename?: 'Screening';
  /** The screening ID */
  id: Scalars['String'];
};

export type ScreeningInfo = {
  __typename?: 'ScreeningInfo';
  /** Article id */
  painDetails?: Maybe<UserPainInfo>;
  /** Surgery Details */
  surgeryDetails?: Maybe<SurgeryDetails>;
};

export type SearchUsers = {
  __typename?: 'SearchUsers';
  /** Users List Data */
  data?: Maybe<SearchUsersResult>;
  /** Meta data of the search users result */
  meta?: Maybe<SearchUsersMeta>;
};

export type SearchUsersFilterObject = {
  /** Client Name */
  clientName?: Maybe<Scalars['String']>;
  /** within | outside | all */
  coverage?: Maybe<Scalars['String']>;
  /** Emails */
  emails?: Maybe<Array<Scalars['String']>>;
  /** User Name */
  name: Scalars['String'];
  /** Phone Numbers */
  phoneNumbers?: Maybe<Array<Scalars['String']>>;
  /** Tags */
  tags?: Maybe<Array<Scalars['String']>>;
  /** User Ids */
  userIds?: Maybe<Array<Scalars['String']>>;
  /** User UUIDs */
  userUUIDs?: Maybe<Array<Scalars['String']>>;
};

export type SearchUsersMeta = {
  __typename?: 'SearchUsersMeta';
  /** Pagination Data of the search results of the users */
  pagination?: Maybe<SearchUsersPaginationData>;
};

export type SearchUsersPaginationData = {
  __typename?: 'SearchUsersPaginationData';
  /** Ending cursor */
  endCursor?: Maybe<Scalars['String']>;
  /** Has next page? */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** Has previous page? */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** Max data to be load per page */
  maxPerPage?: Maybe<Scalars['Float']>;
  /** Data to be load per page */
  perPage?: Maybe<Scalars['Float']>;
  /** Starting cursor */
  startCursor?: Maybe<Scalars['String']>;
};

export type SearchUsersResult = {
  __typename?: 'SearchUsersResult';
  /** List of Users */
  users?: Maybe<Array<UserSearchModel>>;
};

export enum SensorPlacement {
  Bicep = 'BICEP',
  Chest = 'CHEST',
  Forearm = 'FOREARM',
  HqAssets = 'HQ_ASSETS',
  LowBack = 'LOW_BACK',
  Shin = 'SHIN',
  Thigh = 'THIGH'
}

export type SensorlessMotionData = {
  __typename?: 'SensorlessMotionData';
  duration: Scalars['Int'];
  type: Scalars['String'];
};

export type StateDefinition = {
  __typename?: 'StateDefinition';
  /** Flag to indicate if custom tasks are allowed */
  allowCustomTasks?: Maybe<Scalars['Boolean']>;
  /** Flag to indicate if this state completes the workflow */
  complete?: Maybe<Scalars['Boolean']>;
  /** Task mapping definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** Name of the state */
  name: Scalars['String'];
  /** Tasks associated with the state */
  tasks?: Maybe<Array<Scalars['String']>>;
};

export type StateDefinitionInput = {
  /** Flag to indicate if custom tasks are allowed */
  allowCustomTasks?: Maybe<Scalars['Boolean']>;
  /** Flag to indicate if this state completes the workflow */
  complete?: Maybe<Scalars['Boolean']>;
  /** Task mapping definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** Name of the state */
  name: Scalars['String'];
  /** Tasks associated with the state */
  tasks?: Maybe<Array<Scalars['String']>>;
};

export type StepDefinition = {
  __typename?: 'StepDefinition';
  data?: Maybe<StepDefinitionData>;
  instructions?: Maybe<Array<Scalars['String']>>;
  motionDefinitions: Array<MotionDefinitionData>;
  order?: Maybe<Scalars['Int']>;
  type: StepDefinitionType;
  uuid?: Maybe<Scalars['String']>;
  verbalCue?: Maybe<VerbalCue>;
};

export type StepDefinitionData = ExerciseRepetitionData | SwitchLimbData;

export enum StepDefinitionType {
  Repetition = 'REPETITION',
  SwitchLimb = 'SWITCH_LIMB'
}

export type SubmissionRemitsType = {
  __typename?: 'SubmissionRemitsType';
  billId: Scalars['Float'];
  coverageId?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  id: Scalars['Float'];
  netsuiteId?: Maybe<Scalars['String']>;
  remits: Array<RemitBaseType>;
  status: Scalars['String'];
  submissionAmount: Scalars['Float'];
  submissionDate: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type SubscriptionType = {
  __typename?: 'SubscriptionType';
  /** client id of the SubscriptionType item */
  clientId: Scalars['Float'];
  /** clients insurer id of the SubscriptionType item */
  clientsInsurerId: Scalars['Float'];
  /** contract id of the SubscriptionType item */
  contractId: Scalars['Float'];
  /** created at of the SubscriptionType item */
  createdAt: Scalars['String'];
  /** ends at of the SubscriptionType item */
  endsAt: Scalars['String'];
  /** id of the SubscriptionType item */
  id: Scalars['Float'];
  /** pathway id of the SubscriptionType item */
  pathwayId: Scalars['Float'];
  /** pathway uuid of the SubscriptionType item */
  pathwayUuid: Scalars['String'];
  /** program id of the SubscriptionType item */
  programId?: Maybe<Scalars['Float']>;
  /** starts at of the SubscriptionType item */
  startsAt: Scalars['String'];
  /** term date of the SubscriptionType item */
  termDate?: Maybe<Scalars['String']>;
  /**  user id of the SubscriptionType item */
  userId: Scalars['Float'];
  /** user uuid of the SubscriptionType item */
  userUuid: Scalars['String'];
  /** void value of the SubscriptionType item */
  void: Scalars['Boolean'];
  /** year count of the SubscriptionType item */
  yearCount: Scalars['Float'];
};

export type SubscriptionsPaginationType = {
  __typename?: 'SubscriptionsPaginationType';
  items: Array<SubscriptionType>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  size: Scalars['Float'];
  total: Scalars['Float'];
};

export type SubstitutionModel = {
  __typename?: 'SubstitutionModel';
  adminUuid: Scalars['String'];
  createReason?: Maybe<Scalars['String']>;
  endReason?: Maybe<Scalars['String']>;
  levelEnd: Scalars['Int'];
  levelStart: Scalars['Int'];
  memberUuid: Scalars['String'];
  substituteExerciseSlug: Scalars['String'];
  targetExerciseSlug: Scalars['String'];
  uuid: Scalars['String'];
};

export type SubstitutionRuleWithRuleType = {
  __typename?: 'SubstitutionRuleWithRuleType';
  adminUuid: Scalars['String'];
  createReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  endReason?: Maybe<Scalars['String']>;
  levelEnd: Scalars['Int'];
  levelStart: Scalars['Int'];
  memberUuid: Scalars['String'];
  modifiedAt?: Maybe<Scalars['String']>;
  modifiedByUuid?: Maybe<Scalars['String']>;
  ruleType: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  substituteExerciseSlug: Scalars['String'];
  targetExerciseSlug: Scalars['String'];
  uuid: Scalars['String'];
};

export type SurgeryDetails = {
  __typename?: 'SurgeryDetails';
  /** Followup Surgeries */
  followUps?: Maybe<FollowupSurgeries>;
  /** Surgery Impact */
  impact?: Maybe<SurgeryImpact>;
  /** Is Peri Surgical Member? */
  isPerisurgicalMember?: Maybe<Scalars['Boolean']>;
  /** Surgery Name */
  name?: Maybe<Scalars['String']>;
  /** Peri Surgical Details */
  perisurgicalDetails?: Maybe<PeriSurgicalDetails>;
};

export type SurgeryImpact = {
  __typename?: 'SurgeryImpact';
  /** Future Surgery */
  futureSurgery?: Maybe<Scalars['StringOrInt']>;
  /** Chance of Surgery in the very next year */
  surgeryChanceNextYear?: Maybe<Scalars['StringOrInt']>;
  /** Upcoming Surgeries */
  upcomingSurgeries?: Maybe<Scalars['StringOrInt']>;
};

export type SwitchLimbData = {
  __typename?: 'SwitchLimbData';
  duration?: Maybe<Scalars['Int']>;
  imageSlug?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type TagsList = {
  __typename?: 'TagsList';
  count: Scalars['Float'];
  tags: Array<Tag>;
};

export type Task = {
  __typename?: 'Task';
  /** Administrator identification number */
  adminUuid?: Maybe<Scalars['ID']>;
  /** Flag to know if task is archived */
  archived?: Maybe<Scalars['Boolean']>;
  /** Date and time when the task was completed */
  completedAt?: Maybe<Scalars['String']>;
  /** Date and time when the task was created */
  createdAt: Scalars['String'];
  /** Date when task is due */
  dueDate?: Maybe<Scalars['String']>;
  /** Id of the task */
  id: Scalars['Float'];
  /** Name of the task */
  name?: Maybe<Scalars['String']>;
  /** Selection data for multiple outcome tasks */
  selection?: Maybe<Scalars['String']>;
  /** The workflow state associated with the task */
  state: Scalars['String'];
  /** Type of the task */
  type: Scalars['String'];
  /** Date and time when the task was updated */
  updatedAt: Scalars['String'];
};

export type TaskMappingDefinition = {
  __typename?: 'TaskMappingDefinition';
  /** Task mapping definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** All task triggers associated with the task mapping definition */
  taskTriggers: Array<TaskTriggerDefinition>;
  /** Name of the task or transition associated with the mapping */
  triggerName: Scalars['String'];
};

export type TaskMappingDefinitionInput = {
  /** Task mapping definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** All task triggers associated with the task mapping definition */
  taskTriggers: Array<TaskTriggerDefinitionInput>;
  /** Name of the task or transition associated with the mapping */
  triggerName: Scalars['String'];
};

export type TaskTriggerDefinition = {
  __typename?: 'TaskTriggerDefinition';
  /** Whether due date should respect business days only */
  businessDays?: Maybe<Scalars['Boolean']>;
  /** custom fields initiating trigger */
  fromCustomFields?: Maybe<Array<Scalars['String']>>;
  /** Task Trigger definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** Due time */
  msUntilDueDate?: Maybe<Scalars['Float']>;
  /** Name of the task associated with the trigger */
  taskName: Scalars['String'];
  /** Type of trigger */
  triggerType: Scalars['String'];
  /** Trigger values associated with the trigger */
  triggerValues?: Maybe<Array<Scalars['String']>>;
};

export type TaskTriggerDefinitionInput = {
  /** Whether due date should respect business days only */
  businessDays?: Maybe<Scalars['Boolean']>;
  /** custom fields initiating trigger */
  fromCustomFields?: Maybe<Array<Scalars['String']>>;
  /** Task Trigger definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** Due time */
  msUntilDueDate?: Maybe<Scalars['Float']>;
  /** Name of the task associated with the trigger */
  taskName: Scalars['String'];
  /** Type of trigger */
  triggerType: Scalars['String'];
  /** Trigger values associated with the trigger */
  triggerValues?: Maybe<Array<Scalars['String']>>;
};

export type TimerDefinition = {
  __typename?: 'TimerDefinition';
  /** Action performed for the specified event */
  action: Scalars['String'];
  /** The event from the predefined trigger library that is instigating this event. */
  event: Scalars['String'];
  /** Timer definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** Milliseconds after the initial event that this trigger should execute. */
  msUntilTrigger?: Maybe<Scalars['Float']>;
};

export type TimerDefinitionInput = {
  /** Action performed for the specified event */
  action: Scalars['String'];
  /** The event from the predefined trigger library that is instigating this event. */
  event: Scalars['String'];
  /** Timer definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** Milliseconds after the initial event that this trigger should execute. */
  msUntilTrigger?: Maybe<Scalars['Float']>;
};

export type TokenPayload = {
  __typename?: 'TokenPayload';
  /** Access token expiration in milliseconds */
  accessExpiry: Scalars['Float'];
  accessToken: Scalars['String'];
  /** The email address for the admin */
  email: Scalars['String'];
  /** The Basilisk devise auth token */
  legacyAuthTokens: LegacyToken;
  /** Refresh token expiration in milliseconds */
  refreshExpiry: Scalars['Float'];
  refreshToken: Scalars['String'];
  roles: Array<AcceptedRoles>;
  /** The uuid of the admin */
  uuid: Scalars['ID'];
};

export type Transition = {
  __typename?: 'Transition';
  /** Caller Identification number */
  callerUuid: Scalars['String'];
  /** Date and time when the transition was created */
  createdAt: Scalars['String'];
  /** Transition would be happening from this state */
  fromState: Scalars['String'];
  /** Transition identification number */
  id: Scalars['Float'];
  /** Name of the transition */
  name: Scalars['String'];
  /** Transition would be happening to this state */
  toState: Scalars['String'];
  /** Date and time when the transition was updated */
  updatedAt: Scalars['String'];
};

export type TransitionDefinition = {
  __typename?: 'TransitionDefinition';
  /** Transition from state names */
  from: Array<Scalars['String']>;
  /** Transition definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** Transition definition name */
  name: Scalars['String'];
  /** Transition reversible flag */
  reversible?: Maybe<Scalars['Boolean']>;
  /** Transition to state name */
  to: Scalars['String'];
};

export type TransitionDefinitionInput = {
  /** Transition from state names */
  from: Array<Scalars['String']>;
  /** Transition definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** Transition definition name */
  name: Scalars['String'];
  /** Transition reversible flag */
  reversible?: Maybe<Scalars['Boolean']>;
  /** Transition to state name */
  to: Scalars['String'];
};

export type UpdateAssessmentTaskInput = {
  /** Asssessment task status */
  status: Scalars['String'];
};

export type UpdateContractInput = {
  /** The acute price of the ContractType item */
  acutePrice?: Maybe<Scalars['Float']>;
  /** Engagement type ids to be set as billable activities */
  billableActivityTypes?: Maybe<Array<Scalars['Int']>>;
  /** The chronic price of the ContractType item */
  chronicPrice?: Maybe<Scalars['Float']>;
  /** The client id of the ContractType item */
  clientId?: Maybe<Scalars['Float']>;
  /** The client insurer id of the ContractType item */
  clientsInsurerId?: Maybe<Scalars['Float']>;
  /** The contract value of the ContractType item */
  contract?: Maybe<Array<Scalars['JSONObject']>>;
  /** Contract packages to be saved */
  contractPackages?: Maybe<Array<ContractPackagesInput>>;
  /** The contract template id of the ContractType item */
  contractTemplateId?: Maybe<Scalars['Float']>;
  /** disabled program indications as string to be set as disabled program indications */
  disabledProgramIndication?: Maybe<Array<Scalars['String']>>;
  /** The updated date time value of the ContractType item */
  endDate?: Maybe<Scalars['String']>;
  /** The insurer id of the ContractType item */
  insurerId?: Maybe<Scalars['Float']>;
  ironcladId?: Maybe<Scalars['String']>;
  /** Procedure codes to be saved */
  procedureCodes?: Maybe<Array<ProcedureCodeInput>>;
  /** The start date of the ContractType item */
  startDate?: Maybe<Scalars['String']>;
  /** The void value of the ContractType item */
  void?: Maybe<Scalars['Boolean']>;
};

export type UpdateDiscountInput = {
  clientId?: Maybe<Scalars['Float']>;
  discount: Scalars['Float'];
  discountDefinitionId: Scalars['Float'];
  discountType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['Float']>;
  programId?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  suppressEmptyCharges?: Maybe<Scalars['Boolean']>;
};

export type UpdateMassMessageInput = {
  /** List Of Member Ids needs to be added to mass message */
  addMemberIds?: Maybe<Array<Scalars['Int']>>;
  /** Data (text) for the message. Supports interpolation */
  body: Scalars['String'];
  /** In App Messaging Specific - the type of care provider leading the conversation (e.g. health_coaching, physical_therapy) */
  conversationType?: Maybe<MassMessageConversationType>;
  /** How the message will be delivered to the member (e.g. in_app or sms) */
  deliveryChannel?: Maybe<MassMessageDeliveryChannel>;
  /** Existing list of member ids in the mass message */
  existingMemberIds?: Maybe<Array<Scalars['Int']>>;
  /** Care Team Provider UUID */
  from?: Maybe<Scalars['String']>;
  /** Id of Mass Message to be updated */
  id: Scalars['String'];
  /** List Of Member Ids */
  memberIds: Array<Scalars['Float']>;
  /** The time a member should receive the message in UTC - ISO 8601. If you want all the members in this group to receive the message at 9am, you would submit: '2023-07-27T09:00:00Z'. The service will handle sending out the messages at 9 am for the given members timezone */
  scheduledTimeForMember: Scalars['String'];
};

export type UpdateMemberAlertInput = {
  /** User Status Category */
  category?: Maybe<Scalars['String']>;
  /** description of the member alert */
  description?: Maybe<Scalars['String']>;
  /** The ISO8601 timestamp the alert ends at */
  endAt?: Maybe<Scalars['String']>;
  /** The uuid of the member alert */
  id: Scalars['ID'];
  /** if member alert is paused */
  paused?: Maybe<Scalars['Boolean']>;
  /** The ISO8601 timestamp coach reminder date */
  remindAt?: Maybe<Scalars['String']>;
  /** The ISO8601 timestamp the member alert starts at */
  startAt?: Maybe<Scalars['String']>;
  /** The uuid of the admin updating the member alert */
  updatedByUuid: Scalars['String'];
  /** User identifier for the member alert */
  userUuid?: Maybe<Scalars['String']>;
};

export type UpdateRecommendationInput = {
  id: Scalars['Float'];
  status: Scalars['String'];
};

export type UpdateRuleInput = {
  /** List of rule actions */
  actions?: Maybe<Array<ActionDefinitionInput>>;
  /** The rule definition */
  definition?: Maybe<RuleDefinitionInput>;
  /** Supported resource types for rule */
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  /** Source of resources to be evaluated by this rule */
  source?: Maybe<Scalars['String']>;
  /** Rule status */
  status?: Maybe<RuleStatus>;
};

export type UpdateScheduledInAppMessageInputDto = {
  /** The body of the scheduled message */
  body?: Maybe<Scalars['String']>;
  /** Conversation ID of message */
  conversationId: Scalars['String'];
  /** ID of schedule in app message */
  id: Scalars['Float'];
  /** Timestamp of scheduled message to be sent */
  scheduledAt?: Maybe<Scalars['String']>;
};

export type UpdateTasksDto = {
  /** DueDate of the tasks that needs to be updated */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** Name of the task */
  name?: Maybe<Scalars['String']>;
  /** Selection made by the user for the tasks that needs to be updated */
  selection?: Maybe<Scalars['String']>;
  /** Array of all the taskIDs that needs to be updated */
  taskIDs: Array<Scalars['Int']>;
};

export type UpdateWorkflowInputDto = {
  /** UUIDs of administrators associated with the workflow */
  adminUuids?: Maybe<Array<Scalars['ID']>>;
  /** Custom fields associated with the workflow */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** Flag to indicate if the workflow is viewed */
  viewed?: Maybe<Scalars['Boolean']>;
  /** The workflow ID to update */
  workflowId: Scalars['Int'];
};

export type UpdateWorkflowListInputDto = {
  /** UUIDs of administrators associated with the workflow */
  adminUuids?: Maybe<Array<Scalars['ID']>>;
  /** Indicates whether all workflows needs to be updated */
  allWorkflowsChecked?: Maybe<Scalars['Boolean']>;
  /** Custom fields associated with the workflow */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** Flag to indicate if the workflow is viewed */
  viewed?: Maybe<Scalars['Boolean']>;
  /** The workflow IDs to update */
  workflowIds: Array<Scalars['Int']>;
  /** The workflow IDs to exclude in update list */
  workflowIdsToExclude?: Maybe<Array<Scalars['Int']>>;
};

export type User = {
  __typename?: 'User';
  /** Action plans for a member */
  actionPlans: Array<ActionPlan>;
  /** Address line one of residence for the member */
  addressOne?: Maybe<Scalars['String']>;
  /** Address line two of residence for the member */
  addressTwo?: Maybe<Scalars['String']>;
  /** The user's pathway program indication */
  applicationPathway?: Maybe<ApplicationPathway>;
  /** The member's bio */
  bio?: Maybe<Scalars['String']>;
  /** Care plans for a member, paginated */
  carePlans: Array<CarePlan>;
  /** The member's city of residence */
  city?: Maybe<Scalars['String']>;
  /** The name of the client the member is associated with */
  clientName?: Maybe<Scalars['String']>;
  /** Program indications from the member's recruitments */
  clientRecruitmentProgramIndications?: Maybe<Array<Scalars['String']>>;
  /** Preferred contact method for member */
  contactMethod?: Maybe<Scalars['String']>;
  /** The member's date of birth */
  dateOfBirth: Scalars['String'];
  /** The email address for the member */
  email: Scalars['String'];
  /** The first name of the member */
  firstName: Scalars['String'];
  /** The ID of the member */
  id: Scalars['ID'];
  /** most recent ET session date */
  lastEtSessionAt?: Maybe<Scalars['String']>;
  /** The last name of the member */
  lastName: Scalars['String'];
  /** Program indications from the pathway */
  pathwayProgramIndications?: Maybe<Array<Scalars['String']>>;
  pathways: Array<UserPathway>;
  /** The phone number for the member */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The postal code of the member */
  postalCode?: Maybe<Scalars['String']>;
  /** The state of residence for the member */
  stateOfResidence?: Maybe<Scalars['String']>;
  /** Tags associated with a user */
  tagList?: Maybe<Array<Scalars['String']>>;
  /** (Deprecated, use `tagList`) Tags associated with a user */
  tags?: Maybe<Array<Scalars['String']>>;
  /** The member's team ID */
  teamId?: Maybe<Scalars['String']>;
  /** Time zone for the member */
  timezone?: Maybe<Scalars['String']>;
  /** The UUID of the member */
  uuid: Scalars['ID'];
};


export type UserCarePlansArgs = {
  itemsPerPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type UserAqData = {
  __typename?: 'UserAQData';
  /** Address line one of residence for the member */
  addressOne?: Maybe<Scalars['String']>;
  /** Address line two of residence for the member */
  addressTwo?: Maybe<Scalars['String']>;
  /** Member Bio */
  bio?: Maybe<Scalars['String']>;
  /** BMI */
  bmi?: Maybe<Scalars['Float']>;
  /** The member's city of residence */
  city?: Maybe<Scalars['String']>;
  /** Client */
  client?: Maybe<Client>;
  /** Coaching Questions */
  coachingQuestions?: Maybe<UserCoachingQuestions>;
  /** The member's date of birth */
  dateOfBirth: Scalars['String'];
  /** The email address for the member */
  email: Scalars['String'];
  /** First ET Session Attempted */
  firstEverEtSessionAttempted?: Maybe<Scalars['String']>;
  /** The first name of the member */
  firstName: Scalars['String'];
  /** Gender */
  gender?: Maybe<Scalars['String']>;
  /** Height */
  height?: Maybe<Scalars['Float']>;
  /** The ID of the member */
  id: Scalars['ID'];
  /** The last name of the member */
  lastName: Scalars['String'];
  /** Occupation */
  occupation?: Maybe<Scalars['String']>;
  /** The phone number for the member */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The postal code of the member */
  postalCode?: Maybe<Scalars['String']>;
  /** Screening Info */
  screener?: Maybe<ScreeningInfo>;
  /** The state of residence for the member */
  stateOfResidence?: Maybe<Scalars['String']>;
  /** Tags associated with a user */
  tagList?: Maybe<Array<Scalars['String']>>;
  /** Time zone for the member */
  timezone?: Maybe<Scalars['String']>;
  /** The UUID of the member */
  uuid: Scalars['ID'];
  /** Weight */
  weight?: Maybe<Scalars['Float']>;
};

export type UserCoachingQuestions = {
  __typename?: 'UserCoachingQuestions';
  /** Foresee Barriers */
  foreseeBarriers?: Maybe<Scalars['StringArrayOrNull']>;
  /** Imagine Free */
  imagineFree?: Maybe<Scalars['StringArrayOrNull']>;
  /** Key Reasons */
  keyReasons?: Maybe<Scalars['StringArrayOrNull']>;
};

export type UserCoveringAdmin = {
  __typename?: 'UserCoveringAdmin';
  name: Scalars['String'];
  role: Scalars['String'];
  uuid: Scalars['String'];
};

export type UserEducation = {
  __typename?: 'UserEducation';
  /** Last closed article */
  lastClosedAtArticle?: Maybe<EducationArticle>;
  /** Next article in the queue */
  nextArticle?: Maybe<EducationArticle>;
};

export type UserEngagementStreak = {
  __typename?: 'UserEngagementStreak';
  /** Member current streak */
  currentStreak?: Maybe<Scalars['Float']>;
};

export type UserPainInfo = {
  __typename?: 'UserPainInfo';
  /** Is Avoiding Exercise? */
  avoidExercise?: Maybe<Scalars['String']>;
  /** Avoid Exercises */
  avoidedExercises?: Maybe<Scalars['String']>;
  /** Pain Description */
  description?: Maybe<Scalars['String']>;
  /** Pain Name */
  name?: Maybe<Scalars['String']>;
};

export type UserPathway = {
  __typename?: 'UserPathway';
  /** The date that the member was accepted into the program */
  acceptedAt?: Maybe<Scalars['String']>;
  /** The id of the pathway */
  id: Scalars['ID'];
  /** The indication associated with the pathway. eg: knee */
  indication: Scalars['String'];
  /** Whether or not the pathway is active */
  isActive: Scalars['Boolean'];
  level?: Maybe<Scalars['Int']>;
  pregnancy?: Maybe<PregnancyDetails>;
  /** The number of weeks a member has been in the program */
  programWeek: Scalars['Int'];
  /** the date that the member's team starts */
  startsAt?: Maybe<Scalars['String']>;
  /** The name of the program that is associated with the pathway. eg: chronic */
  type: Scalars['String'];
  /** The uuid of a user */
  userUuid: Scalars['String'];
  /** The uuid of the pathway */
  uuid: Scalars['ID'];
};

export type UserSearchModel = {
  __typename?: 'UserSearchModel';
  /** Client name */
  clientName?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** First name */
  firstName?: Maybe<Scalars['String']>;
  /** User id */
  id?: Maybe<Scalars['String']>;
  /** Is covered under current admin? */
  isCoveredUnderCurrentAdmin?: Maybe<Scalars['Boolean']>;
  /** Last name */
  lastName?: Maybe<Scalars['String']>;
  /** Phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Tags */
  tags?: Maybe<Array<Scalars['String']>>;
  /** User uuid */
  uuid?: Maybe<Scalars['String']>;
};

export type UserSubstitution = {
  __typename?: 'UserSubstitution';
  newExercise: UserSubstitutionMetadata;
  originalExercise: OriginalExerciseData;
};

export type UserSubstitutionMetadata = {
  __typename?: 'UserSubstitutionMetadata';
  name: Scalars['String'];
  uuid: Scalars['String'];
};

export type UserTag = {
  __typename?: 'UserTag';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  occurredAt: Scalars['String'];
  resourceId: Scalars['String'];
  source: Scalars['String'];
  tagId: Scalars['Float'];
  tagName: Scalars['String'];
  userId: Scalars['String'];
};

export type UserTagsList = {
  __typename?: 'UserTagsList';
  count: Scalars['Float'];
  userTags: Array<UserTag>;
};

export type ValidGroupNumbersType = {
  __typename?: 'ValidGroupNumbersType';
  /** The groupNumber of the ValidGroupNumbersType item */
  groupNumber: Scalars['String'];
};

export type VerbalCue = {
  __typename?: 'VerbalCue';
  contentType: Scalars['String'];
  contentfulAudioId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ordinal?: Maybe<Scalars['Float']>;
  slug: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  uuid: Scalars['String'];
};

export type VerifyInputDto = {
  email: Scalars['String'];
  googleIdToken: Scalars['String'];
};

export type VideoCall = {
  __typename?: 'VideoCall';
  /** adminUuid */
  adminUuid?: Maybe<Scalars['String']>;
  /** basiliskPathwayId */
  basiliskPathwayId?: Maybe<Scalars['Float']>;
  /** configuration */
  configuration?: Maybe<Scalars['String']>;
  /** createdAt */
  createdAt?: Maybe<Scalars['String']>;
  /** endedAt */
  endedAt?: Maybe<Scalars['String']>;
  /** id */
  id?: Maybe<Scalars['String']>;
  /** identifier */
  identifier?: Maybe<Scalars['String']>;
  /** Array of Participants */
  participants?: Maybe<Array<Participant>>;
  /** scheduledAt */
  scheduledAt?: Maybe<Scalars['String']>;
  /** updatedAt */
  updatedAt?: Maybe<Scalars['String']>;
  /** userUuid */
  userUuid?: Maybe<Scalars['String']>;
};

/** Type of preview videos */
export enum VideoType {
  Instructional = 'INSTRUCTIONAL',
  Modifications = 'MODIFICATIONS'
}

export type VideoVisitTokenRequest = {
  /** identifier */
  identifier?: Maybe<Scalars['String']>;
  /** uuid */
  uuid?: Maybe<Scalars['String']>;
};

export type VideoVisitTokenResponse = {
  __typename?: 'VideoVisitTokenResponse';
  /** token */
  token?: Maybe<Scalars['String']>;
};

export type WeekDateRange = {
  __typename?: 'WeekDateRange';
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type WeekInfo = {
  __typename?: 'WeekInfo';
  dateRange: WeekDateRange;
  weekNumber: Scalars['Float'];
};

export type WorkflowCount = {
  __typename?: 'WorkflowCount';
  /** The number of workflows in a state */
  count: Scalars['Float'];
  /** The workflow state being counted */
  state: Scalars['String'];
  /** The workflow type in the count */
  workflowType: Scalars['String'];
};

export type WorkflowCountByPriority = {
  __typename?: 'WorkflowCountByPriority';
  /** Count of workflows with high priority */
  high: Scalars['Float'];
  /** Count of workflows with low priority */
  low: Scalars['Float'];
  /** Count of workflows with unknown priority */
  unknown: Scalars['Float'];
};

export type WorkflowCountModel = {
  __typename?: 'WorkflowCountModel';
  errors: Array<WorkflowError>;
  results: Array<WorkflowCount>;
  /** Subtotals for each workflow type */
  subtotals?: Maybe<Array<WorkflowSubtotals>>;
  total?: Maybe<Scalars['Float']>;
};

export type WorkflowDefinition = {
  __typename?: 'WorkflowDefinition';
  /** Custom fields associated with the workflow definition */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** All event trigger definitions associated with the workflow definition */
  eventTriggers?: Maybe<Array<EventTriggerDefinition>>;
  /** Workflow definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** Initial state */
  initialState: Scalars['String'];
  /** Initial transition */
  initialTransition?: Maybe<Scalars['String']>;
  /** Workflow definition name */
  name: Scalars['String'];
  /** All state definitions associated with the workflow definition */
  states: Array<StateDefinition>;
  /** All task mapping definitions associated with the workflow definition */
  taskMapping?: Maybe<Array<TaskMappingDefinition>>;
  /** All transition definitions associated with the workflow definition */
  transitions: Array<TransitionDefinition>;
};

export type WorkflowDefinitionInput = {
  /** Custom fields associated with the workflow definition */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** All event trigger definitions associated with the workflow definition */
  eventTriggers?: Maybe<Array<EventTriggerDefinitionInput>>;
  /** Workflow definition identification number */
  id?: Maybe<Scalars['Float']>;
  /** Initial state */
  initialState: Scalars['String'];
  /** Initial transition */
  initialTransition?: Maybe<Scalars['String']>;
  /** Workflow definition name */
  name: Scalars['String'];
  /** All state definitions associated with the workflow definition */
  states: Array<StateDefinitionInput>;
  /** All task mapping definitions associated with the workflow definition */
  taskMapping?: Maybe<Array<TaskMappingDefinitionInput>>;
  /** All transition definitions associated with the workflow definition */
  transitions: Array<TransitionDefinitionInput>;
};

export type WorkflowDefinitionResponse = {
  __typename?: 'WorkflowDefinitionResponse';
  /** Workflow definition object */
  data?: Maybe<WorkflowDefinition>;
  /** Errors related to workflow definition object */
  errors: Array<ErrorDefinition>;
};

export type WorkflowDefinitionsResponse = {
  __typename?: 'WorkflowDefinitionsResponse';
  /** Array of workflow definition objects */
  data?: Maybe<Array<WorkflowDefinition>>;
  /** Errors related to the array of workflow definition objects */
  errors: Array<ErrorDefinition>;
};

export type WorkflowError = {
  __typename?: 'WorkflowError';
  /** The error related to the entered workflow value */
  error: Scalars['String'];
  /** The name of the bad field input */
  fieldName: Scalars['String'];
  /** The incorrectly entered workflow value */
  value?: Maybe<Scalars['String']>;
};

export type WorkflowListFiltersInputDto = {
  /** UUIDs of administrators to filter by */
  adminUuids?: Maybe<Array<Scalars['ID']>>;
  /** List workflows which are completed after specified date */
  completedAfterDate?: Maybe<Scalars['String']>;
  /** List workflows which are completed before specified date */
  completedBeforeDate?: Maybe<Scalars['String']>;
  /** List of completed states to include while listing completed workflows */
  completedStates?: Maybe<Array<Scalars['String']>>;
  /** The end date to filter latestTaskDueDate */
  dueTaskEndDate?: Maybe<Scalars['String']>;
  /** The start date to filter latestTaskDueDate */
  dueTaskStartDate?: Maybe<Scalars['String']>;
  /** Workflow states to exclude */
  excludeStates?: Maybe<Array<Scalars['String']>>;
  /** Boolean to control if Apollo cache should merge the paginated next result */
  mergePaginatedResult?: Maybe<Scalars['Boolean']>;
  /** Order of sorting 'DESC' or 'ASC' (defaults to 'DESC' ) */
  order?: Maybe<Scalars['String']>;
  /** Column to order workflows by */
  orderBy?: Maybe<Scalars['String']>;
  /** Page number (defaults to 1) */
  pageNumber?: Maybe<Scalars['Float']>;
  /** Number of workflow payloads this page (defaults to 10) */
  pageSize?: Maybe<Scalars['Float']>;
  /** UUIDs of patients to filter by */
  patientUuids?: Maybe<Array<Scalars['ID']>>;
  /** List workflows based on their priorites */
  priority?: Maybe<WorkflowPriority>;
  /** State to filter by */
  state?: Maybe<Scalars['String']>;
  /** Whether to use Basilisk coverage to filter results */
  useCoverage?: Maybe<Scalars['Boolean']>;
  /** Workflow types to filter by */
  workflowTypes?: Maybe<Array<Scalars['String']>>;
};

export type WorkflowListPayload = {
  __typename?: 'WorkflowListPayload';
  errors: Array<WorkflowError>;
  /** The identifier of this list request, for pagination */
  id: Scalars['String'];
  /** Page number */
  pageNumber: Scalars['Float'];
  /** Page size */
  pageSize: Scalars['Float'];
  workflows: Array<WorkflowPayload>;
};

export type WorkflowPayload = {
  __typename?: 'WorkflowPayload';
  /** Action plans for a member */
  actionPlans?: Maybe<Array<ActionPlan>>;
  /** UUIDs of administrators associated with the workflow */
  adminUuids?: Maybe<Array<Scalars['ID']>>;
  availableTransitions: Array<AvailableTransitions>;
  /** Care plans for a patient, paginated */
  carePlans?: Maybe<Array<CarePlan>>;
  /** Administrators who have coverage of the workflow */
  coveringAdmins?: Maybe<Array<Admin>>;
  /** User insurer CPT codes */
  cptCodes?: Maybe<CptCodes>;
  /** Date and time when the workflow was created */
  createdAt: Scalars['String'];
  /** Custom fields associated with the workflow */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** Message body */
  healthLog?: Maybe<MultiIndicationHealthLog>;
  /** Workflow identification number */
  id: Scalars['Float'];
  /** workflow has past due tasks */
  isPastDue?: Maybe<Scalars['Boolean']>;
  /** complete workflow date */
  lastCompletedAt?: Maybe<Scalars['String']>;
  /** latest task timestamp with time zone */
  latestTaskDate?: Maybe<Scalars['String']>;
  /** latest task ID */
  latestTaskId?: Maybe<Scalars['Float']>;
  /** Member alerts for a member */
  memberAlerts?: Maybe<Array<MemberAlert>>;
  /** Message body */
  message?: Maybe<Message>;
  /** The most recent action plan for a member */
  mostRecentActionPlan?: Maybe<ActionPlan>;
  /** Application pathway details */
  pathway?: Maybe<Pathway>;
  /** User associated with the workflow  */
  patient?: Maybe<BaseUser>;
  /** Patient identification number */
  patientUuid: Scalars['String'];
  /** Priority of workflow */
  priority?: Maybe<Scalars['Int']>;
  /** an array with number of completed tasks at index zero and total task count at index one */
  progress?: Maybe<Array<Scalars['Float']>>;
  /** The ID of the event associated with this workflow */
  referenceEventId?: Maybe<Scalars['String']>;
  /** Current state of the workflow */
  state: Scalars['String'];
  tasks?: Maybe<Array<Task>>;
  transitions?: Maybe<Array<Transition>>;
  /** Type of workflow */
  type: Scalars['String'];
  /** Date and time when the workflow was updated */
  updatedAt: Scalars['String'];
  /** Flag to indicate if the workflow is viewed */
  viewed: Scalars['Boolean'];
};


export type WorkflowPayloadCarePlansArgs = {
  includeExpirations?: Maybe<Scalars['Boolean']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  program?: Maybe<Scalars['String']>;
};

/** Priority of the workflow i.e. LOW, HIGH, UNKNOWN */
export enum WorkflowPriority {
  High = 'HIGH',
  Low = 'LOW',
  Unknown = 'UNKNOWN'
}

export type WorkflowSubtotals = {
  __typename?: 'WorkflowSubtotals';
  /** Count of workflows for this workflow type */
  count?: Maybe<Scalars['Float']>;
  /** Count of workflows by priority for this workflow type */
  countByPriority?: Maybe<WorkflowCountByPriority>;
  /** Workflow type in subtotal */
  workflowType?: Maybe<Scalars['String']>;
};

export type WorkflowsCompletedTodayCountModel = {
  __typename?: 'WorkflowsCompletedTodayCountModel';
  /** The number of workflows completed today */
  count: Scalars['Float'];
  errors: Array<WorkflowError>;
};

export type CurrentAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentAdminQuery = { __typename?: 'Query', admin: { __typename?: 'Admin', firstName: string, lastName: string, uuid: string, role: string } };


export const CurrentAdminDocument = gql`
    query currentAdmin {
  admin {
    firstName
    lastName
    uuid
    role
  }
}
    `;

/**
 * __useCurrentAdminQuery__
 *
 * To run a query within a React component, call `useCurrentAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentAdminQuery(baseOptions?: Apollo.QueryHookOptions<CurrentAdminQuery, CurrentAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentAdminQuery, CurrentAdminQueryVariables>(CurrentAdminDocument, options);
      }
export function useCurrentAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentAdminQuery, CurrentAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentAdminQuery, CurrentAdminQueryVariables>(CurrentAdminDocument, options);
        }
export type CurrentAdminQueryHookResult = ReturnType<typeof useCurrentAdminQuery>;
export type CurrentAdminLazyQueryHookResult = ReturnType<typeof useCurrentAdminLazyQuery>;
export type CurrentAdminQueryResult = Apollo.QueryResult<CurrentAdminQuery, CurrentAdminQueryVariables>;