import { ApolloError } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import { LinearProgress, Tooltip } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useModal } from '../../../hooks/use-modal';
import {
  userTagsGridColumnHeaders,
  userTagsGridFieldNames,
} from '../../constants/user-tags.constant';
import { useListUserTagsHook } from '../../hooks/list-user-tags-hook';
import { useDeleteUserTag } from '../../hooks/use-delete-user-tag';
import { UserTag } from '../../types';
import { ResourceDetailModal } from '../detail/resource-detail-modal';
import { NoUserTagsMessage } from './no-user-tags';
import { UserTagDeleteModal } from './user-tag-delete-modal';

const getter =
  (field: string) =>
  (params: GridValueGetterParams): string =>
    params.row[field] || '';

type ListGridParams = {
  userTags: UserTag[];
  loading?: boolean;
  error?: ApolloError;
};

export const ListUserTagsGrid = (gridParams: ListGridParams): JSX.Element => {
  const [selectedResource, setSelectedResource] = useState<string>('');
  const { isOpen, handleOpen, handleClose } = useModal();
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { refetch } = useListUserTagsHook({
    userId: localStorage.getItem('userId') || '',
  });
  const { userTags, loading = false, error } = gridParams;
  const { deleteUserTag } = useDeleteUserTag(() => {
    refetch();
  });

  const handleDelete = (id: string): void => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleCloseModal = (): void => {
    setOpen(false);
    setSelectedId(null);
  };

  const handleConfirmDelete = async (): Promise<void> => {
    if (selectedId) {
      try {
        await deleteUserTag(selectedId);
        setAlert({ type: 'success', message: 'User Tag Successfully Deleted' });
      } catch (err) {
        setAlert({ type: 'error', message: 'Unable to delete user tag' });
      } finally {
        setOpen(false);
      }
    }
  };

  const ListUserTagsColumns: GridColumns = [
    {
      field: userTagsGridFieldNames.id,
      headerName: userTagsGridColumnHeaders.id,
      flex: 1,
      valueGetter: getter(userTagsGridFieldNames.id),
    },
    {
      field: userTagsGridFieldNames.userId,
      headerName: userTagsGridColumnHeaders.userId,
      flex: 1,
      sortable: false,
      valueGetter: getter(userTagsGridFieldNames.userId),
    },
    {
      field: userTagsGridFieldNames.createdAt,
      headerName: userTagsGridColumnHeaders.createdAt,
      flex: 1,
      valueGetter: getter(userTagsGridFieldNames.createdAt),
      renderCell: (params): string => {
        const date = new Date(params.value as string);
        return date.toLocaleString();
      },
    },
    {
      field: userTagsGridFieldNames.tagId,
      headerName: userTagsGridColumnHeaders.tagId,
      flex: 1,
      sortable: false,
      valueGetter: getter(userTagsGridFieldNames.tagId),
    },
    {
      field: userTagsGridFieldNames.tagName,
      headerName: userTagsGridColumnHeaders.tagName,
      flex: 1,
      sortable: false,
      valueGetter: getter(userTagsGridFieldNames.tagName),
    },
    {
      field: userTagsGridFieldNames.source,
      headerName: userTagsGridColumnHeaders.source,
      flex: 1,
      sortable: false,
      valueGetter: getter(userTagsGridFieldNames.source),
    },
    {
      field: userTagsGridFieldNames.resourceId,
      headerName: userTagsGridColumnHeaders.resourceId,
      flex: 1,
      sortable: false,
      valueGetter: getter(userTagsGridFieldNames.resourceId),
    },
    {
      field: userTagsGridFieldNames.occurredAt,
      headerName: userTagsGridColumnHeaders.occurredAt,
      flex: 1,
      sortable: false,
      valueGetter: getter(userTagsGridFieldNames.occurredAt),
      renderCell: (params): string => {
        const date = new Date(params.value as string);
        return date.toLocaleString();
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params): React.ReactNode => (
        <Tooltip title="Delete User Tag">
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            label="Delete"
            aria-label="Delete"
            onClick={(): void => handleDelete(params.id.toString())}
            showInMenu={false}
            sx={{}}
            onResize={(): Promise<void> => Promise.resolve()}
            nonce=""
            onResizeCapture={(): Promise<void> => Promise.resolve()}
          />
        </Tooltip>
      ),
    },
  ];

  const handleOnCellClick: GridEventListener<'cellClick'> = (params): void => {
    const { field } = params;

    if (field === userTagsGridFieldNames.resourceId) {
      setSelectedResource(params.value as string);
      handleOpen();
    }
  };

  return (
    <>
      <ResourceDetailModal id={selectedResource} {...{ isOpen, handleClose }} />
      <DataGrid
        components={{
          NoRowsOverlay: NoUserTagsMessage,
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          noRowsOverlay: { error },
        }}
        sx={{
          '& .MuiDataGrid-cell:hover': {
            cursor: 'default',
          },
        }}
        columnBuffer={10}
        disableColumnMenu
        autoHeight
        hideFooter
        columns={ListUserTagsColumns}
        rows={userTags}
        isRowSelectable={(): boolean => false}
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'createdAt',
                sort: 'desc',
              },
            ],
          },
        }}
        onCellClick={handleOnCellClick}
      />
      <UserTagDeleteModal
        open={open}
        handleClose={handleCloseModal}
        handleConfirmDelete={handleConfirmDelete}
        loading={loading}
        alert={alert}
        setAlert={setAlert}
      />
    </>
  );
};
