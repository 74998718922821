import { WorkflowPayload } from '../types';
import { ApplicationManagementToolMyWorkflowsTableRow } from './application-management-tool-my-workflows-table-row';

type ApplicationManagementToolMyWorkflowTableBodyProps = {
  data?: WorkflowPayload[];
  onClick?: (workflow: WorkflowPayload) => void;
};

export const ApplicationManagementToolMyWorkflowTableBody = ({
  data = [],
  onClick,
}: ApplicationManagementToolMyWorkflowTableBodyProps): JSX.Element => (
  <>
    {data.map((workflow, i) => (
      <ApplicationManagementToolMyWorkflowsTableRow
        key={workflow.id}
        workflow={workflow}
        onClick={onClick}
        i={i + 1}
      />
    ))}
  </>
);
