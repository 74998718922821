import dayjs from 'dayjs';
import { ApplicationManagementToolWorkflowTypes } from '../../custom-types';
import { FULL_MONTH_DAY_YEAR } from './dayjs-date-formats';

export const AMT_STRINGS = {
  ASSIGN_TO_ME_BUTTON: 'Assign To Me',
  UNASSIGN_BUTTON: 'Unassign',
  BACK: 'Back',
  DATE: 'Date',
  DUE: 'Due',
  MEMBER: 'Member',
  TYPE: 'Type',
  EVENT_TIME: 'Event time',
  UNKNOWN_MEMBER: 'Unknown member',
  JOIN_BUTTON: 'Join',
  MARK_COMPLETE_BUTTON: 'Mark complete',
  COMPLETE: 'Complete',
};

export const CHIP_STRINGS = {
  TOTAL: 'Total',
  OVERDUE: 'Overdue',
  DUE_SOON: 'Due soon',
};

export const MEMBER_VIEW_HEADER_TITLE: Record<
  ApplicationManagementToolWorkflowTypes,
  string
> = {
  [ApplicationManagementToolWorkflowTypes.PathwayTransition]:
    'Pathway Transition',
};

export const AMT_SIDEBAR_HEADER = {
  HEADER: {
    titleCompleted: (completedCount: number, totalCount: number): string =>
      `${completedCount} of ${totalCount} tasks completed`,
  },
};

export const RESOLVE_ACCORDION_HEADING = 'Resolve';

export const AMT_TABS: Record<string, string> = {
  myWorkflows: 'My Workflows',
  inbox: 'Inbox',
};

export const AMT_HEADER = {
  HEADER: {
    TITLE: 'Your tasks',
    subTitleCompleted: (completedCount: number, totalCount: number): string =>
      `${completedCount} of ${totalCount} completed`,
    subTitleDue: (overDueCount: number, dueSoonCount: number): string =>
      `${overDueCount} overdue, ${dueSoonCount} due soon`,
  },
};

export const dashBoardTitle = (adminFirstName: string): string =>
  `Delivery Ops ${adminFirstName}'s Dashboard`;

export const calendarTitle = (): string => dayjs().format(FULL_MONTH_DAY_YEAR);

export const TIME_STRINGS = {
  IN: 'in',
  TOMORROW: 'Tomorrow',
  DAYS: 'days',
};

export const MEMBER_DETAILS_TABLE = {
  MEMBER: 'Member',
  EMAIL: 'Email',
  ID: 'ID',
  ASSIGNEE: 'Assignee',
  TIMEZONE: 'Time zone',
  STATE_OF_RESIDENCE: 'State',
  INDICATION: 'Indication',
  PHONE: 'Phone',
};

export const AMT_LINKS = {
  CTT_PROFILE: 'CTT profile',
  ADMIN_PROFILE: 'Admin profile',
  APPLICATION_QUESTIONNAIRE: 'Application Q',
  MEDICAL_HISTORY: 'Medical history',
  EVALUATION_TEMPLATE: 'Eval template',
  EVALUATION_NOTE: 'Eval note',
  FOLLOW_UP_TEMPLATE: 'Follow up template',
  EMAIL: 'Email',
  CANCEL: 'Cancel',
  RESCHEDULE: 'Reschedule',
  BOOKING: 'Booking',
  CALENDAR: 'Calendar',
  JOIN_ROOM: 'Join room',
  PHONE: 'Phone',
  OPTIONAL: '(opt)',
  RESPOND: 'Respond',
  PAIN_HISTORY: 'Pain history',
  EXERCISE_PLAN: 'Exercise plan',
  GURU_ARTICLES: 'Guru articles',
  ET_PDFS: 'ET PDFs',
  TIMEZONE: 'Time zone',
  REQUEST_EQUIPMENT: 'Request equipment',
  CARE_PLAN: 'care plan',
  PHI_DRIVE: 'PHI drive',
};

export const AMT_TABLE_TITLES = {
  USER_ID: 'User Id',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  STATE: 'State',
  LAST_UPDATED: 'Last Updated',
  UNASSIGN: 'Unassign',
};
