import {
  HHAlert,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import Box from '@mui/material/Box';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BillingLeftPanel from '../components/billing-tool-left-panel';
import {
  ContractFormValues,
  UsableContractType,
} from '../components/contract/form/custom-types';
import { ContractForm } from '../components/contract/form/form';
import LoadingComponent from '../components/utils/loading';
import { routes } from '../constants/strings/routes';
import { useClientInsurersById } from '../hooks/client-insurer-hook';
import {
  GetContractsByClientInsurerDocument,
  useGetContractsByClientInsurerQuery,
  useGetContractSubscriptionsQuery,
  useUpdateContractMutation,
} from '../types';
import {
  ActiveStatus,
  computeActiveStatus,
  getClientsInsurerPartnership,
} from '../utils/contract-utils';

const useStyles = makeStyles(() =>
  createStyles({
    clientInsurerEditContract: {
      display: 'flex',
      width: ' 100%',
      height: '100%',
      overflowY: 'auto',
    },
  }),
);

export const CLIENT_INSURER_EDIT_CONTRACT_CONTAINER_TEST_ID =
  'client-insurer-edit-contract-container';

const ClientInsurerEditContract = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams<{ id: string; contractId: string }>();
  const clientInsurerId = parseInt(params.id || '0', 10);
  const contractId = parseInt(params.contractId ?? '0', 10);

  const {
    data,
    loading,
    error: contractsError,
  } = useGetContractsByClientInsurerQuery({
    variables: {
      id: clientInsurerId,
      includeVoided: true,
    },
  });
  const {
    data: ciData,
    loading: ciLoading,
    error: ciError,
  } = useClientInsurersById(clientInsurerId);

  const clientsInsurerPartnership = getClientsInsurerPartnership(ciData);

  const currentContracts = data?.getContracts ?? [];

  const currentContract = data?.getContracts?.find(
    ({ id }) => id === contractId,
  );

  let currentActiveStatus = ActiveStatus.Unknown;
  if (currentContract) {
    currentActiveStatus = computeActiveStatus(
      currentContract.void,
      currentContract.startDate,
      currentContract.endDate,
    );
  }

  const {
    data: contractRelatedData,
    error: contractRelatedError,
    loading: contractRelatedLoading,
  } = useGetContractSubscriptionsQuery({
    variables: {
      clientsInsurerId: clientInsurerId,
      contractId,
      contractPackageIds:
        currentContract?.contractPackages.map(cp => cp.id) ?? [],
    },
  });

  const [updateContractMutation, { data: updateData, error: updateError }] =
    useUpdateContractMutation();

  const updateContract = useCallback(
    async (contractId, updateInput) => {
      await updateContractMutation({
        variables: {
          contractId,
          contractInput: updateInput,
        },
        refetchQueries: [
          {
            query: GetContractsByClientInsurerDocument,
            variables: { id: clientInsurerId, includeVoided: true },
          },
        ],
      });
    },
    [updateContractMutation, clientInsurerId],
  );

  useEffect(() => {
    if (updateData) {
      setTimeout(() => {
        navigate(`${routes.billing.main}/${clientInsurerId}`);
      }, 1000);
    }
  }, [updateData, clientInsurerId, navigate]);

  if (!ciLoading && (ciError || !ciData)) {
    return (
      <HHAlert
        hhVariant="outlined"
        severity="error"
        children={
          <HHTypography hhVariant="body">
            There was an error loading the client insurer.
          </HHTypography>
        }
      ></HHAlert>
    );
  }

  if (!loading && (contractsError || !data)) {
    return (
      <HHAlert hhVariant="outlined" severity="error">
        <HHTypography hhVariant="body">
          There was an error loading the current contract.
        </HHTypography>
      </HHAlert>
    );
  }

  if (
    !contractRelatedLoading &&
    (contractRelatedError || !contractRelatedData)
  ) {
    return (
      <HHAlert hhVariant="outlined" severity="error">
        <HHTypography hhVariant="body">
          There was an error loading the current contract subscription data.
        </HHTypography>
      </HHAlert>
    );
  }

  const submissionCount =
    contractRelatedData?.getContractSubscriptions?.submissionCount ?? 0;

  const handleCancel = (): void => {
    navigate(`${routes.billing.main}/${clientInsurerId}`);
  };

  const formSubmit = (values: ContractFormValues): void => {
    const { ...rest } = values;
    const additional = {
      clientsInsurerId: clientInsurerId,
      clientId: ciData?.client.id,
      insurerId: ciData?.insurer.id,
      procedureCodes: [],
    };
    const contractInput = { ...additional, ...rest };

    updateContract(contractId, contractInput);
  };

  /**
   *Scroll to top of form if contract created or errors
   * */
  const scrollRef = (element: HTMLDivElement | null): void => {
    if (element && (updateData || updateError)) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Box
      className={classes.clientInsurerEditContract}
      data-testid={CLIENT_INSURER_EDIT_CONTRACT_CONTAINER_TEST_ID}
    >
      {ciData && <BillingLeftPanel data={ciData} />}

      {loading || contractRelatedLoading ? (
        <LoadingComponent center />
      ) : (
        <HHStack padding={4} ref={scrollRef} width={'100%'}>
          {updateError ? (
            <HHAlert hhVariant="outlined" severity="error">
              <HHTypography hhVariant="body">
                Contract not edited successfully, please check inputs
              </HHTypography>
            </HHAlert>
          ) : null}
          {updateData ? (
            <HHAlert hhVariant="outlined" severity="success">
              <HHTypography hhVariant="body">
                Contract Edited Successfully!
              </HHTypography>
            </HHAlert>
          ) : null}
          <ContractForm
            currentContracts={currentContracts as UsableContractType[]}
            formTitle="Edit Contract"
            onSubmitCallback={formSubmit}
            onCancel={handleCancel}
            submissionCount={submissionCount}
            partnershipIds={
              clientsInsurerPartnership
                ? [clientsInsurerPartnership?.id]
                : undefined
            }
            disableSubmit={!!contractsError}
            wholeFormDisabled={currentActiveStatus !== ActiveStatus.Future}
            activeStatus={currentActiveStatus}
            feeForServiceFormDisabled={
              currentActiveStatus === ActiveStatus.Past ||
              (currentContract?.contractPackages.some(Boolean) &&
                currentActiveStatus !== ActiveStatus.Future)
            }
            contractPackageIds={
              currentContract?.contractPackages.map(cp => cp.id) ?? []
            }
            contractEditId={contractId}
          />
        </HHStack>
      )}
    </Box>
  );
};

export default ClientInsurerEditContract;
