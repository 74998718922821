import {
  HHButton,
  HHChip,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Link } from 'react-router-dom';
import {
  leftPanelClient,
  leftPanelInsurer,
  leftPanelPartner,
} from '../constants/strings/left-panel-labels';
import { routes } from '../constants/strings/routes';
import { ClientInsurerType } from '../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../utils/bills-utils';
import { TextGrouping } from './utils/text-grouping';

export interface BillingToolPanelProps {
  data: ClientInsurerType;
}

const BillingLeftPanel = ({ data }: BillingToolPanelProps): JSX.Element => {
  const {
    client: { name: clientName, id: clientId },
    insurer: { name: insurerName },
    isActive,
    billingType,
    partnerships,
  } = data;
  const billingPartnership = partnerships.filter(
    p => p.partnershipType === 'billing',
  )[0];
  const partnerName = billingPartnership ? billingPartnership.name : null;
  const hasBillingType = billingType !== '';
  const billingTypeLabel = hasBillingType
    ? capitalizeFirstLetterAndSpaceSnakeString(billingType ?? '')
    : 'No billing type';

  return (
    <HHStack
      direction="column"
      spacing={4}
      paddingTop={6}
      paddingLeft={4}
      paddingRight={4}
      sx={(theme): SystemStyleObject<Theme> => ({
        width: 'calc(18% - 56px)',
        backgroundColor: theme.palette.background.default,
      })}
    >
      <HHTypography
        hhVariant="section-title"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        layoutStyles={{ wordBreak: 'break-word' }}
      >
        {capitalizeFirstLetterAndSpaceSnakeString(clientName)}
      </HHTypography>
      <HHStack
        direction={{ sm: 'column', md: 'column', lg: 'row' }}
        sx={{ gap: 1 }}
        flexWrap="wrap"
      >
        <HHChip
          hhVariant="variant-bypass"
          color="primary"
          size="small"
          disabled={!isActive}
          label={isActive ? 'Active' : 'Inactive'}
        />
        <HHChip
          hhVariant="variant-bypass"
          color={hasBillingType ? 'info' : 'error'}
          size="small"
          label={billingTypeLabel}
          title={billingTypeLabel}
        />
      </HHStack>
      <TextGrouping
        label={leftPanelClient}
        text={
          <Link
            to={`${routes.billing.home}/${routes.billing.clientPath}/${clientId}`}
          >
            <HHButton
              variant="text"
              color="primary"
              size="small"
              hhVariant="variant-bypass"
              sx={{ padding: 0, textAlign: 'left', minWidth: '0' }}
            >
              {capitalizeFirstLetterAndSpaceSnakeString(clientName)}
            </HHButton>
          </Link>
        }
      />
      <TextGrouping label={leftPanelInsurer} text={insurerName} />
      {partnerName ? (
        <TextGrouping label={leftPanelPartner} text={partnerName} />
      ) : null}
    </HHStack>
  );
};

export default BillingLeftPanel;
