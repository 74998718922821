import { AMT_PATHWAY_TRANSITION_STATES } from '../../../custom-types';

const emailInsuranceForm = 'Please email the patient the insurance forms';
const deprovisionProgram =
  'Please refer to the Duplicate Account SOP steps [here](https://app.getguru.com/card/iajrey9T/SOP-Actioning-Duplicate-Accounts).'; // eslint-disable-line quotes
const cancelRelvantAq =
  "Use [Admin Panel](https://app.hingehealth.com/admin/users) to search for this member's ID and follow the above SOP steps."; // eslint-disable-line quotes
const addNoteToAdminPanel =
  "Please add note for the patient's coach in the admin panel"; // eslint-disable-line quotes
const emailMember = 'Once those steps are complete, provision the member. ';
const verifyInsurance = "Please verify the patient's insurance"; // eslint-disable-line quotes
const navigateToMessaging =
  'In [CTT](https://coach.hingehealth.com/messaging), click on the "Messaging" tab on the left. Click the team name under "Teams" and click the member’s information.';
const addNote =
  'Set a status for the member by following [these steps](https://app.getguru.com/card/TpAkzd5c/Program-Transitions-How-to-Add-Temporary-Coverage-of-a-Member-in-CTT-Add-CTT-Status-and-Verify-Temporary-Coverage-has-ended).';
const verifyTempCoverageEnded = 'Once the note is saved, provision the member.';
const verifyUserProvisioned =
  'Verify that the member was provisioned by refreshing CTT.';
const temporaryCoverage =
  'Click Create Temporary Coverage under Actions to automatically assign yourself temporary coverage to this member.';

export const appendLocalTask = (tasks: string[], state?: string): string[] => {
  switch (state) {
    case AMT_PATHWAY_TRANSITION_STATES.ADD_COACH_NOTE:
      return tasks.concat(['verify-user-provisioned']);
    case AMT_PATHWAY_TRANSITION_STATES.WAITING_FOR_ASSIGNMENT:
      return tasks.concat(['temporaryCoverage']);
    default:
      return tasks;
  }
};

export const taskMapping = new Map([
  ['email-insurance-form', emailInsuranceForm],
  ['verify-insurance', verifyInsurance],
  ['deprovision-program', deprovisionProgram],
  ['cancel-relvant-aq', cancelRelvantAq],
  ['add-note-to-admin-panel', addNoteToAdminPanel],
  ['email-member', emailMember],
  ['create-temporary-coverage', navigateToMessaging],
  ['add-note', addNote],
  ['verify-temporary-coverage-ended', verifyTempCoverageEnded],
  ['verify-user-provisioned', verifyUserProvisioned],
  ['temporaryCoverage', temporaryCoverage],
]);
