import dayjs from 'dayjs';
import * as yup from 'yup';
import {
  DiscountType,
  selectedDiscountOverlaps,
} from '../../../utils/discount-utils';

export const DiscountValidationSchema = yup.object({
  clientId: yup.number().required(),
  discountType: yup
    .string()
    .required()
    .oneOf([
      DiscountType.billingCaps,
      DiscountType.programAccess,
      DiscountType.innovationCredits,
      DiscountType.freeBillableActivities,
      '',
    ]),
  startDate: yup
    .date()
    .typeError('Invalid Date')
    .defined()
    .required('Start date required')
    .test({
      name: 'startDateInvalid',
      skipAbsent: true,
      test(value) {
        return dayjs(value).isValid();
      },
    })
    .test({
      name: 'startDatePastDisabled',
      skipAbsent: true,
      test(value, ctx) {
        const { discountToEdit } = ctx?.options?.context ?? {};
        if (
          dayjs(value).isBefore(dayjs(), 'day') === true &&
          (discountToEdit
            ? dayjs(discountToEdit.startDate).isAfter(dayjs(), 'day')
            : true)
        ) {
          return ctx.createError({
            message: 'Start date is disabled before today',
          });
        } else return true;
      },
    })
    .when(['discountType', 'program', 'packageId'], (dt, schema) => {
      const [currentDiscountType, currentProgram, currentPackageId] = dt;
      if (
        (currentDiscountType && currentProgram) ||
        (currentDiscountType && currentPackageId)
      ) {
        return schema.test({
          name: 'startDateOverlaps',
          skipAbsent: true,
          test(value, ctx) {
            const { currentDiscounts, discountToEdit } =
              ctx?.options?.context ?? {};
            const { discountType, program, packageId } = ctx.parent;
            if (currentDiscounts.length >= 1) {
              const startDateOverlapCheck = selectedDiscountOverlaps(
                dayjs(value).format(),
                discountType,
                program ? parseInt(program) : null,
                packageId ? parseInt(packageId) : null,
                discountToEdit ? discountToEdit.discountDefinitionId : null,
                currentDiscounts,
              );
              if (startDateOverlapCheck.isOverlap) {
                return ctx.createError({
                  path: 'startDate',
                  message: 'Start date overlaps previous discount',
                });
              }
            }
            return true;
          },
        });
      } else {
        return schema.required('start Date required');
      }
    }),
  endDate: yup
    .date()
    .typeError('Invalid Date')
    .defined()
    .when('discountType', (dt, schema) => {
      const [currentDiscountType] = dt;
      if (
        currentDiscountType == '' ||
        currentDiscountType === DiscountType.innovationCredits // nullable if innovation credits
      ) {
        return schema.nullable('true').optional();
      } else {
        return schema.required('End date required');
      }
    })
    .test({
      name: 'endDateBeforeStart',
      skipAbsent: true,
      test(value, ctx) {
        const { startDate } = ctx.parent;
        if (dayjs(value).isBefore(startDate, 'day')) {
          return ctx.createError({
            message: 'End date is before start date',
          });
        } else return true;
      },
    })
    .test({
      name: 'endDatePastDisabled',
      skipAbsent: true,
      test(value, ctx) {
        if (dayjs(value).isBefore(dayjs(), 'day') === true) {
          return ctx.createError({
            message: 'End date is disabled before today',
          });
        } else return true;
      },
    })
    .when(['discountType', 'program', 'packageId'], (dt, schema) => {
      const [currentDiscountType, currentProgram, currentPackageId] = dt;
      if (
        (currentDiscountType && currentProgram) ||
        (currentDiscountType && currentPackageId)
      ) {
        return schema.test({
          name: 'endDateOverlaps',
          skipAbsent: true,
          test(value, ctx) {
            const { currentDiscounts, discountToEdit } =
              ctx?.options?.context ?? {};
            const { discountType, program, packageId } = ctx.parent;
            if (currentDiscounts.length >= 1) {
              const endDateOverlaps = selectedDiscountOverlaps(
                dayjs(value).format(),
                discountType,
                program ? parseInt(program) : null,
                packageId ? parseInt(packageId) : null,
                discountToEdit ? discountToEdit.discountDefinitionId : null,
                currentDiscounts,
              );
              if (endDateOverlaps.isOverlap) {
                return ctx.createError({
                  path: 'endDate',
                  message: 'End date overlaps future discount',
                });
              }
            }
            return true;
          },
        });
      } else {
        return schema;
      }
    }),
  program: yup
    .string()
    .nullable()
    .test({
      name: 'programRequiredIfNoPackage',
      skipAbsent: true,
      test(value, ctx) {
        const { packageId } = ctx.parent;
        if (value || packageId) {
          return true;
        } else {
          return false;
        }
      },
    }),
  packageId: yup
    .string()
    .nullable()
    .test({
      name: 'packageRequiredIfNoProgram',
      skipAbsent: true,
      test(value, ctx) {
        const { program } = ctx.parent;
        if (value || program) {
          return true;
        } else {
          return false;
        }
      },
    }),
  discount: yup.lazy(value =>
    value === ''
      ? yup.string().typeError('Invalid Discount').min(1).required()
      : yup
          .number()
          .typeError('Invalid Discount')
          .positive()
          .integer()
          .min(1)
          .required(),
  ),
  suppressEmptyCharges: yup.boolean().defined(),
});
