import { HHCheckbox } from '@hinge-health/react-component-library';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller, useFormContext } from 'react-hook-form';
import { DiscountType } from '../../../../utils/discount-utils';

export const SuppressEmptyChargesCheckbox = (): JSX.Element => {
  const { control, watch } = useFormContext();

  const watchedDiscountType = watch('discountType');

  const watchedSuppressEmptyCharges = watch('suppressEmptyCharges');

  return (
    <Controller
      control={control}
      name="suppressEmptyCharges"
      render={({ field: { onChange, value } }): JSX.Element => (
        <FormControlLabel
          sx={{ width: '100%' }}
          control={
            <HHCheckbox
              hhVariant="primary"
              checked={value}
              onChange={(): void => onChange(!watchedSuppressEmptyCharges)}
              disabled={
                ![
                  DiscountType.programAccess,
                  DiscountType.freeBillableActivities,
                ].includes(watchedDiscountType)
              }
            />
          }
          label="Hide $0.00 charges on invoice"
        />
      )}
    />
  );
};
