import {
  HHButton,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../constants';
import { OktaLocalStorageKeys } from '../constants/okta';

const useStyles = makeStyles(
  createStyles({
    otherRoles: {
      backgroundColor: '#263744',
      color: 'white',
      minHeight: '100vh',
      padding: '24px',
      fontFamily: 'Lato',
      fontSize: '28px',
      fontWeight: 300,
      letterSpacing: '0.21px',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);
export const OtherRoles = (): JSX.Element => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { hingeHealthAuthClient } = useHingeHealthSecurityContext();
  const allOktaKeys = Object.values(OktaLocalStorageKeys);

  const handleClick = (): void => {
    navigate(routes.login, {
      state: { referrer: `${location.pathname}${location.search}` },
    });
  };

  const handleLogout = async (): Promise<void> => {
    try {
      await hingeHealthAuthClient.signOut();
    } finally {
      allOktaKeys.forEach((oktaKey: string) =>
        localStorage.removeItem(oktaKey),
      );
    }
  };
  return (
    <Box className={classes.otherRoles}>
      Page not found
      <HHButton
        hhVariant="text"
        style={{ color: 'white', fontSize: 16 }}
        onClick={handleClick}
      >
        Back to login page
      </HHButton>
      <HHButton hhVariant="text" onClick={(): Promise<void> => handleLogout()}>
        <LogoutIcon titleAccess="Log Out" sx={{ color: 'white' }} />
      </HHButton>
    </Box>
  );
};
