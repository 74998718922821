import { HHBox, HHTypography } from '@hinge-health/react-component-library';
import { LinearProgress } from '@mui/material';

interface AssessmentTaskProgressBarProps {
  totalAssessments: number;
  remaining: number;
}

export const AssessmentTaskProgressBar = ({
  totalAssessments,
  remaining,
}: AssessmentTaskProgressBarProps): JSX.Element => {
  const progressValue = totalAssessments
    ? ((totalAssessments - remaining) / totalAssessments) * 100
    : 0;

  // returns a progress bar with a percentage label to the right
  return (
    <HHBox display="flex" alignItems="center">
      <HHBox width="100%" mr={1}>
        <LinearProgress variant="determinate" value={progressValue} />
      </HHBox>
      <HHBox minWidth={35}>
        <HHTypography hhVariant="body2" color="textSecondary">
          {`${Math.round(progressValue)}%`}
        </HHTypography>
      </HHBox>
    </HHBox>
  );
};
