import {
  HHBox,
  HHButton,
  HHCircularProgress,
  HHGrid,
  HHLabelSection,
  HHTable,
  HHTableBody,
  HHTableCell,
  HHTableContainer,
  HHTableHead,
  HHTableRow,
  HHTypography,
} from '@hinge-health/react-component-library';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  buttonLabels,
  notFoundErrorMarker,
  tabRoutes,
} from '../../../constants/hinge-connect-constants.constant';
import { JsonValue, omitTypename } from '../../../utils/omit-typename';
import { printDateAndTime } from '../../../utils/print-date-and-time';
import {
  ruleAssessmentsTabContent,
  ruleAssessmentsTextFields,
} from '../../constants/rule-assessments.constant';
import {
  AssessmentStatus,
  GetRuleAssessmentQuery,
  useGetRuleAssessmentQuery,
} from '../../types';
import { RuleAssessmentLoadError } from './rule-assessments-load-error';
import { RuleAssessmentNotFound } from './rule-assessments-not-found';

type RuleAssessmentResponse = GetRuleAssessmentQuery['getRuleAssessment'];

interface RuleAssessmentDetailsProps {
  id: string;
  ruleAssessment: RuleAssessmentResponse;
}

interface RuleAssessmentDetailItemProps {
  label: string;
  content: string;
}

const RuleAssessmentDetailItem = ({
  label,
  content,
}: RuleAssessmentDetailItemProps): JSX.Element => (
  <HHGrid item xs={12} sm={6}>
    <HHLabelSection hhVariant="text" label={label} content={content} />
  </HHGrid>
);

const RuleAssessmentDetailItemSmall = ({
  label,
  content,
}: RuleAssessmentDetailItemProps): JSX.Element => (
  <HHGrid item xs={6}>
    <HHLabelSection hhVariant="text" label={label} content={content} />
  </HHGrid>
);

type StatusCounts = Record<AssessmentStatus, number>;

const getStatusCounts = (
  assessedRules: RuleAssessmentResponse['assessedRules'],
): StatusCounts =>
  assessedRules.reduce((totals, assessedRule) => {
    totals[assessedRule.status] = (totals[assessedRule.status] ?? 0) + 1;
    return totals;
  }, {} as StatusCounts);

const RuleAssessmentDetails = ({
  ruleAssessment,
}: RuleAssessmentDetailsProps): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });

  const statusCounts = getStatusCounts(ruleAssessment.assessedRules);

  const viewJson = (title: string, jsonObject: JsonValue): void => {
    const content = JSON.stringify(omitTypename(jsonObject), null, 2);
    setModalContent({ title, content });
    setModalOpen(true);
  };

  const closeModal = (): void => setModalOpen(false);

  return (
    <HHBox maxWidth="50rem">
      <Dialog
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{modalContent.title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            padding="0.5rem"
            id="alert-dialog-description"
            sx={{ backgroundColor: '#f2f2f2' }}
          >
            <pre style={{ whiteSpace: 'pre-wrap', minWidth: 300 }}>
              {modalContent.content}
            </pre>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Close</Button>
        </DialogActions>
      </Dialog>

      <HHGrid container spacing={2}>
        <HHGrid item xs={12} sm={6}>
          <HHTypography hhVariant="h1">
            {ruleAssessmentsTabContent.detailsTitle}
          </HHTypography>
        </HHGrid>
        <HHGrid item xs={12} sm={6} margin="auto" textAlign="right">
          <HHButton
            hhVariant="variant-bypass"
            variant="contained"
            href={`/${tabRoutes.baseRoute}/${tabRoutes.ruleAssessments}/list`}
          >
            {buttonLabels.backToList}
          </HHButton>
        </HHGrid>
      </HHGrid>

      <HHGrid container spacing={2} marginTop="1rem">
        <RuleAssessmentDetailItem
          label={ruleAssessmentsTextFields.id}
          content={ruleAssessment.id}
        />
        <RuleAssessmentDetailItem
          label={ruleAssessmentsTextFields.resourceId}
          content={ruleAssessment.resourceId}
        />
        <RuleAssessmentDetailItem
          label={ruleAssessmentsTextFields.source}
          content={ruleAssessment.source}
        />
        <RuleAssessmentDetailItem
          label={ruleAssessmentsTextFields.resourceType}
          content={ruleAssessment.resourceType ?? ''}
        />
        <RuleAssessmentDetailItem
          label={ruleAssessmentsTextFields.userId}
          content={ruleAssessment.userId ?? ''}
        />
        <RuleAssessmentDetailItem
          label={ruleAssessmentsTextFields.createdAt}
          content={printDateAndTime(ruleAssessment.createdAt)}
        />
        <RuleAssessmentDetailItem
          label={ruleAssessmentsTextFields.updatedAt}
          content={printDateAndTime(ruleAssessment.updatedAt)}
        />
        <HHGrid item xs={12} md={6}>
          <HHGrid container spacing={2}>
            <RuleAssessmentDetailItemSmall
              label={ruleAssessmentsTextFields.completed}
              content={
                statusCounts[AssessmentStatus.Completed]?.toString() ?? '0'
              }
            />
            <RuleAssessmentDetailItemSmall
              label={ruleAssessmentsTextFields.errored}
              content={
                statusCounts[AssessmentStatus.Errored]?.toString() ?? '0'
              }
            />
            <RuleAssessmentDetailItemSmall
              label={ruleAssessmentsTextFields.actionsExecuted}
              content={
                statusCounts[AssessmentStatus.ActionsExecuted]?.toString() ??
                '0'
              }
            />
            <RuleAssessmentDetailItemSmall
              label={ruleAssessmentsTextFields.assertionsPassed}
              content={
                statusCounts[AssessmentStatus.AssertionPassed]?.toString() ??
                '0'
              }
            />
          </HHGrid>
        </HHGrid>
        <HHGrid container spacing={2} marginTop="3rem">
          <HHTypography hhVariant="h2">
            {ruleAssessmentsTextFields.assessedRules}
          </HHTypography>
        </HHGrid>
        <HHGrid container spacing={2} paddingTop={'1rem'}>
          <HHTableContainer>
            <HHTable>
              <HHTableHead>
                <HHTableRow>
                  <HHTableCell>{ruleAssessmentsTextFields.status}</HHTableCell>
                  <HHTableCell>
                    {ruleAssessmentsTextFields.assertionLog}
                  </HHTableCell>
                  <HHTableCell>
                    {ruleAssessmentsTextFields.actionLog}
                  </HHTableCell>
                </HHTableRow>
              </HHTableHead>
              <HHTableBody>
                {ruleAssessment.assessedRules.map((assessedRule, index) => (
                  <HHTableRow key={index}>
                    <HHTableCell>{assessedRule.status}</HHTableCell>
                    <HHTableCell>
                      <HHButton
                        data-testid={`view-${assessedRule.status}-assertion-log-button`}
                        hhVariant="variant-bypass"
                        variant="text"
                        size="small"
                        onClick={(): void =>
                          viewJson(
                            'Assertion log',
                            assessedRule.assertionLog ?? [],
                          )
                        }
                      >
                        View
                      </HHButton>
                    </HHTableCell>
                    <HHTableCell>
                      <HHButton
                        data-testid={`view-${assessedRule.status}-action-log-button`}
                        hhVariant="variant-bypass"
                        variant="text"
                        size="small"
                        onClick={(): void =>
                          viewJson('Action log', assessedRule.actionLog ?? [])
                        }
                      >
                        View
                      </HHButton>
                    </HHTableCell>
                  </HHTableRow>
                ))}
              </HHTableBody>
            </HHTable>
          </HHTableContainer>
        </HHGrid>
      </HHGrid>
    </HHBox>
  );
};

const RuleAssessmentDetailViewContainer = (): JSX.Element => {
  const params = useParams();

  const { data, loading, error, refetch } = useGetRuleAssessmentQuery({
    variables: { id: params.id as string },
  });

  if (loading) return <HHCircularProgress />;
  if (data) {
    return (
      <RuleAssessmentDetails
        id={params.id as string}
        ruleAssessment={data.getRuleAssessment}
      />
    );
  }
  if (error?.graphQLErrors[0]?.message.includes(notFoundErrorMarker)) {
    return <RuleAssessmentNotFound />;
  }
  return <RuleAssessmentLoadError handleRetry={refetch} />;
};

export const RuleAssessmentDetailView = (): JSX.Element => (
  <HHBox paddingY="1rem" display="flex" justifyContent="center">
    <RuleAssessmentDetailViewContainer />
  </HHBox>
);
