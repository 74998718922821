import { ApolloError } from '@apollo/client';

export const cleanTypeNameGraphql = <T>(obj: T): T =>
  JSON.parse(JSON.stringify(obj), (key, value) =>
    key === '__typename' ? undefined : value,
  );

export interface GqlNetworkStatus {
  loading: boolean;
  error?: ApolloError;
}
