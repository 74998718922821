import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { HCModal } from '../../../components/hinge-connect-modal';
import { rulesTabContent } from '../../constants/rules.constant';
import {
  CreateRuleDocument,
  GetRuleDocument,
  ListRulesDocument,
} from '../../types';
import CopyRuleForm from './copy-rule-form';

export const CopyRuleModal = ({
  ruleId,
  isOpen,
  handleClose,
  refetchListRules = undefined,
  copyRuleLoading,
}: {
  ruleId: string;
  isOpen: boolean;
  handleClose: () => void;
  refetchListRules?: () => void;
  copyRuleLoading: boolean;
}): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const { data } = useQuery(GetRuleDocument, {
    variables: { id: ruleId },
    skip: !isOpen,
    onCompleted: () => setLoading(false),
  });

  const [createRule] = useMutation(CreateRuleDocument, {
    refetchQueries: [{ query: ListRulesDocument }],
    onCompleted: () => {
      if (refetchListRules) {
        refetchListRules();
      }
      handleClose();
    },
  });

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
    }
  }, [isOpen]);

  const onSubmit = async (): Promise<void> => {
    if (data !== null && data !== undefined) {
      const originalRule = data.getRule;
      const newRuleData = {
        source: originalRule.source,
        resourceTypes: originalRule.resourceTypes,
        actions: originalRule.actions.map(
          (action: { type: string; metadata: unknown }) => ({
            type: action.type,
            metadata: action.metadata,
          }),
        ),
        definition: {
          name: `Copy - ${originalRule.definition.name}`,
          operator: originalRule.definition.operator,
          criteria: originalRule.definition.criteria,
        },
      };
      await createRule({ variables: { createRuleInput: newRuleData } });
    }
  };

  const ruleName = data?.getRule?.definition?.name || '';

  return (
    <HCModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={rulesTabContent.copyRule.title}
      component={
        <CopyRuleForm
          {...{
            ruleName,
            handleClose,
            onSubmit,
            loading: loading || copyRuleLoading,
          }}
        />
      }
    />
  );
};
