import {
  HHBox,
  HHCircularProgress,
} from '@hinge-health/react-component-library';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAtom } from 'jotai';
import { AMT_THEME } from '../constants/theme';
import { GetWorkflowProvider } from '../contexts/get-workflow.context';
import { showAmtSidebarAtom } from '../jotai-atoms/atoms';
import { Admin } from '../types';
import { AmtSidebarLayout } from './amt-sidebar/amt-sidebar-layout';
import { ApplicationManagementToolDashboardBody } from './application-management-tool-dashboard-body';
import { ApplicationManagementToolDashboardHeader } from './application-management-tool-dashboard-header';

export const LOADER_ID = 'loader';

export interface ApplicationManagementToolProps {
  loading: boolean;
  token?: string;
  UserHeaderMenu?: () => JSX.Element;
  adminProfile?: Admin;
}

const useStyles = makeStyles(() =>
  createStyles({
    acuteDashboardContainer: () => ({
      display: 'flex',
      flexDirection: 'column',
      fontFamily: AMT_THEME.FONT_FAMILY,
      height: '100%',
    }),
  }),
);

export const ApplicationManagementToolLayout = (
  props: ApplicationManagementToolProps,
): JSX.Element => {
  const { loading } = props;
  const classes = useStyles();
  const [showSidebarView] = useAtom(showAmtSidebarAtom);

  if (loading) return <HHCircularProgress data-testid={LOADER_ID} />;

  return (
    <HHBox id="dashboard-view" className={classes.acuteDashboardContainer}>
      <ApplicationManagementToolDashboardHeader />
      <ApplicationManagementToolDashboardBody />
      {showSidebarView && (
        <GetWorkflowProvider>
          <AmtSidebarLayout />
        </GetWorkflowProvider>
      )}
    </HHBox>
  );
};
