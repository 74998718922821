import { ApolloError } from '@apollo/client';
import { useAtom } from 'jotai';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { PathwayTransitionDecoratedWorkflow } from '../custom-types';
import { selectedWorkflowId, selectedWorkflowType } from '../jotai-atoms/atoms';
import { useGetWorkflowLazyQuery, WorkflowPayload } from '../types';
import { appendDueState } from '../utils/due-state';

export interface GetWorkflowContextProps {
  children: React.ReactNode;
}

export interface GetWorkflowContextType {
  workflow?: PathwayTransitionDecoratedWorkflow;
  headerDueDate?: string;
  loading?: boolean;
  error?: ApolloError | undefined;
  reload: () => void;
}

export const GetWorkflowContext = createContext<GetWorkflowContextType>({
  reload: () => undefined,
});

export const GetWorkflowProvider = ({
  children,
}: GetWorkflowContextProps): JSX.Element => {
  const [currentSelectedWorkflowId] = useAtom(selectedWorkflowId);
  const [currentSelectedWorkflowType] = useAtom(selectedWorkflowType);
  const [getWorkflow, { data, loading, error }] = useGetWorkflowLazyQuery();
  const [workflowWithDue, setWorkflowWithDue] =
    useState<PathwayTransitionDecoratedWorkflow>();

  const [headerDueDate] = useState<string>('');

  useEffect(() => {
    if (currentSelectedWorkflowId !== null && currentSelectedWorkflowType) {
      getWorkflow({
        variables: {
          id: currentSelectedWorkflowId,
          workflowType: currentSelectedWorkflowType,
        },
      });
    }
  }, [currentSelectedWorkflowId, currentSelectedWorkflowType, getWorkflow]);

  useEffect(() => {
    const workflow = data?.getWorkflow as WorkflowPayload;

    if (workflow) {
      setWorkflowWithDue(appendDueState(workflow));
    }
  }, [data]);

  return (
    <GetWorkflowContext.Provider
      value={{
        workflow: workflowWithDue,
        loading,
        error,
        headerDueDate,
        reload: async (): Promise<void> => {
          if (currentSelectedWorkflowId === null) {
            return;
          }
          await getWorkflow({
            variables: {
              id: currentSelectedWorkflowId,
              workflowType: currentSelectedWorkflowType,
            },
          });
        },
      }}
    >
      {children}
    </GetWorkflowContext.Provider>
  );
};

export const useGetWorkflowContext = (): GetWorkflowContextType =>
  useContext(GetWorkflowContext);
