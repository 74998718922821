import {
  HHTabs,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { AssessmentTasksTab } from '../assessment-tasks/components/assessment-tasks-tab';
import {
  tabLabels,
  tabRoutes,
} from '../constants/hinge-connect-constants.constant';
import { HealthRecordsTab } from '../health-records/components/health-records-tab';
import { RuleAssessmentsTab } from '../rule-assessments/components/rule-assessments-tab';
import { RulesTab } from '../rules/components/rules-tab';
import { TagsTab } from '../tags/components/tags-tab';
import { UserTagsTab } from '../user-tags/components/user-tags-tab';

export const HingeConnectTabs = (): JSX.Element => {
  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const userHasPhiAccess =
    hingeHealthAuthState?.accessToken?.claims?.phiaccess ?? false;

  const generalUseTabs = [
    {
      tabLabel: tabLabels.rules,
      tabContent: <RulesTab />,
      to: `/${tabRoutes.baseRoute}/${tabRoutes.rules}`,
    },
    {
      tabLabel: tabLabels.tags,
      tabContent: <TagsTab />,
      to: `/${tabRoutes.baseRoute}/${tabRoutes.tags}`,
    },
    {
      tabLabel: tabLabels.assessmentTasks,
      tabContent: <AssessmentTasksTab />,
      to: `/${tabRoutes.baseRoute}/${tabRoutes.assessmentTasks}`,
    },
    {
      tabLabel: tabLabels.userTags,
      tabContent: <UserTagsTab />,
      to: `/${tabRoutes.baseRoute}/${tabRoutes.userTags}`,
    },
    {
      tabLabel: tabLabels.ruleAssessments,
      tabContent: <RuleAssessmentsTab />,
      to: `/${tabRoutes.baseRoute}/${tabRoutes.ruleAssessments}`,
    },
  ];

  const phiTabs = [
    {
      tabLabel: tabLabels.healthRecords,
      tabContent: <HealthRecordsTab />,
      to: `/${tabRoutes.baseRoute}/${tabRoutes.healthRecords}`,
    },
  ];

  const tabsData = userHasPhiAccess
    ? [...generalUseTabs, ...phiTabs]
    : generalUseTabs;

  return <HHTabs {...{ tabsData }} />;
};
