import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import {
  HHButton,
  HHSnackbarAlert,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { HCModal } from '../../components/hinge-connect-modal';
import { buttonLabels } from '../../constants/hinge-connect-constants.constant';
import { useSnackbar } from '../../hooks/use-snackbar';
import {
  assessmentTasksSnackbars,
  assessmentTasksTabContent,
} from '../constants/assessment-tasks.constant';
import { useCancelAssessmentTask } from '../hooks/use-cancel-assessment-task';
import { CancelAssessmentTaskMutation, Exact } from '../types';

interface CancelAssessmentTaskFormProps {
  id: string;
  handleClose: () => void;
  cancelAssessmentTask: (
    options?: MutationFunctionOptions<
      CancelAssessmentTaskMutation,
      Exact<{ id: string }>
    >,
  ) => Promise<FetchResult>;
}

const CancelAssessmentTaskForm = ({
  id,
  handleClose,
  cancelAssessmentTask,
}: CancelAssessmentTaskFormProps): JSX.Element => (
  <HHStack width="40vw" maxWidth="500px">
    <HHTypography hhVariant="body">
      {assessmentTasksTabContent.cancelTask.message}
    </HHTypography>
    <HHStack
      direction="row"
      spacing={4}
      justifyContent="right"
      marginTop="16px"
    >
      <HHButton hhVariant="text" onClick={handleClose}>
        {assessmentTasksTabContent.cancelTask.returnButton}
      </HHButton>
      <HHButton
        hhVariant="contained"
        onClick={(): Promise<FetchResult> =>
          cancelAssessmentTask({ variables: { id } })
        }
      >
        {assessmentTasksTabContent.cancelTask.confirmButton}
      </HHButton>
    </HHStack>
  </HHStack>
);

interface CancelAssessmentTaskModalProps {
  id: string;
  isOpen: boolean;
  handleClose: () => void;
}

export const CancelAssessmentTaskModal = ({
  id,
  isOpen,
  handleClose,
}: CancelAssessmentTaskModalProps): JSX.Element => {
  const { cancelAssessmentTask, data, error, errorMessages } =
    useCancelAssessmentTask(handleClose);

  const { snackbar, handleClose: handleSnackbarClose } = useSnackbar({
    data,
    error,
    errorMessages,
    successMessage: assessmentTasksSnackbars.cancel,
  });

  return (
    <>
      <HHSnackbarAlert
        message={snackbar.message}
        severity={snackbar.severity}
        verticalAlignment="top"
        horizontalAlignment="center"
        hhVariant="standard"
        open={snackbar.open}
        onClose={handleSnackbarClose}
        autoHideTime={snackbar.severity === 'success' ? 3000 : undefined}
      />
      <HCModal
        isOpen={isOpen}
        handleClose={handleClose}
        title={buttonLabels.confirm}
        component={
          <CancelAssessmentTaskForm
            {...{ id, handleClose, cancelAssessmentTask }}
          />
        }
      />
    </>
  );
};
