import {
  HHBox,
  HHDivider,
  HHGrid,
  HHTypography,
  TextCopier,
} from '@hinge-health/react-component-library';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { LABEL_LIGHT, MEMBER_DETAILS_COLORS } from '../../constants/colors';
import { MEMBER_DETAILS_TABLE } from '../../constants/strings/application-management-tool';
import { AMT_THEME } from '../../constants/theme';
import { useGetWorkflowContext } from '../../contexts/get-workflow.context';
import { useShellContext } from '../../contexts/shell.context';
import {
  adminFirstNameLastInitial,
  createFullName,
} from '../../utils/full-name';
import { AdminAvatar } from './admin-avatar';

const useStyles = makeStyles(() =>
  createStyles({
    detailsContainer: {
      marginTop: 20,
    },
    detailsTableTitle: {
      color: MEMBER_DETAILS_COLORS.SECONDARY_TEXT_COLOR,
      fontSize: AMT_THEME.FONT_SIZES.X_SMALL,
    },
    memberDetails: {
      color: MEMBER_DETAILS_COLORS.BLACK,
      fontSize: AMT_THEME.FONT_SIZES.X_SMALL,
    },
    divider: {
      margin: '0px 16px 0 16px',
    },
    gridItem: {
      paddingLeft: 16,
      display: 'flex',
      minHeight: 32,
      alignItems: 'center',
    },
    detailBox: {
      padding: '5px 0 5px 0',
    },
  }),
);

export const titleTypography = {
  fontFamily: AMT_THEME.FONT_FAMILY,
  color: MEMBER_DETAILS_COLORS.SECONDARY_TEXT_COLOR,
  fontSize: AMT_THEME.FONT_SIZES.X_SMALL,
};

export const detailsTypography = {
  fontFamily: AMT_THEME.FONT_FAMILY,
  color: MEMBER_DETAILS_COLORS.BLACK,
  fontSize: AMT_THEME.FONT_SIZES.SMALL,
};

const adminTypography = {
  ...detailsTypography,
  paddingLeft: 2,
  color: LABEL_LIGHT,
};

export const detailsBox = {
  margin: '16px 16px 8px 16px',
};

export const AmtMemberDetails = (): JSX.Element => {
  const { workflow, loading } = useGetWorkflowContext();
  const { adminProfile } = useShellContext();
  const classes = useStyles();

  if (loading) {
    return <HHBox className={classes.detailsContainer}>...loading</HHBox>;
  }

  const patient = workflow?.patient;

  return (
    <HHBox
      className={classes.detailsContainer}
      data-testid="amt-member-details"
    >
      <HHDivider className={classes.divider} />
      <HHGrid className={classes.detailBox} container>
        <HHGrid item xs={3} className={classes.gridItem}>
          <HHTypography hhVariant={'variant-bypass'} sx={titleTypography}>
            {MEMBER_DETAILS_TABLE.ID}
          </HHTypography>
        </HHGrid>
        <HHGrid item xs={9} className={classes.gridItem}>
          <TextCopier
            text={patient?.id || ''}
            styles={{ textStyle: detailsTypography }}
          />
        </HHGrid>
        <HHGrid item xs={3} className={classes.gridItem}>
          <HHTypography hhVariant={'variant-bypass'} sx={titleTypography}>
            {MEMBER_DETAILS_TABLE.MEMBER}
          </HHTypography>
        </HHGrid>
        <HHGrid item xs={9} className={classes.gridItem}>
          <TextCopier
            text={createFullName(patient)}
            styles={{ textStyle: detailsTypography }}
          />
        </HHGrid>
        <HHGrid item xs={3} className={classes.gridItem}>
          <HHTypography hhVariant={'variant-bypass'} sx={titleTypography}>
            {MEMBER_DETAILS_TABLE.EMAIL}
          </HHTypography>
        </HHGrid>
        <HHGrid item xs={9} className={classes.gridItem}>
          <TextCopier
            text={patient?.email || ''}
            styles={{ textStyle: detailsTypography }}
          />
        </HHGrid>
        <HHGrid item xs={3} className={classes.gridItem}>
          <HHTypography hhVariant={'variant-bypass'} sx={titleTypography}>
            {MEMBER_DETAILS_TABLE.STATE_OF_RESIDENCE}
          </HHTypography>
        </HHGrid>
        <HHGrid item xs={9} className={classes.gridItem}>
          <TextCopier
            text={patient?.stateOfResidence || ''}
            styles={{ textStyle: detailsTypography }}
          />
        </HHGrid>
        <HHGrid item xs={3} className={classes.gridItem}>
          <HHTypography hhVariant={'variant-bypass'} sx={titleTypography}>
            {MEMBER_DETAILS_TABLE.PHONE}
          </HHTypography>
        </HHGrid>
        <HHGrid item xs={9} className={classes.gridItem}>
          <TextCopier
            text={patient?.phoneNumber || ''}
            styles={{ textStyle: detailsTypography }}
          />
        </HHGrid>
      </HHGrid>
      <HHDivider className={classes.divider} />
      <HHGrid container>
        <HHGrid item xs={3} className={classes.gridItem}>
          <HHTypography hhVariant={'variant-bypass'} sx={titleTypography}>
            {MEMBER_DETAILS_TABLE.ASSIGNEE}
          </HHTypography>
        </HHGrid>
        <HHGrid item xs={9} className={classes.gridItem}>
          <AdminAvatar />
          <HHTypography hhVariant={'variant-bypass'} sx={adminTypography}>
            {adminProfile && adminFirstNameLastInitial(adminProfile)}
          </HHTypography>
        </HHGrid>
      </HHGrid>
    </HHBox>
  );
};
