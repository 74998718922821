import { ApolloError } from '@apollo/client';
import { HHStack, HHTypography } from '@hinge-health/react-component-library';
import { ruleAssessmentsTabContent } from '../../constants/rule-assessments.constant';

export const NoRuleAssessmentsMessage = (props?: {
  error: ApolloError;
  searchActive: boolean;
}): JSX.Element => {
  if (props?.error) {
    return (
      <HHStack
        direction="column"
        textAlign="center"
        paddingTop="3rem"
        spacing=".5rem"
      >
        <HHTypography hhVariant="h3">
          {ruleAssessmentsTabContent.oops}
        </HHTypography>
        <HHTypography hhVariant="body" paddingTop="1rem">
          {props.error.message +
            ' ' +
            ruleAssessmentsTabContent.trySearchingAgain}
        </HHTypography>
      </HHStack>
    );
  }
  if (props?.searchActive) {
    return (
      <HHStack
        direction="column"
        textAlign="center"
        paddingTop="3rem"
        spacing=".5rem"
      >
        <HHTypography hhVariant="h3">
          {ruleAssessmentsTabContent.noResultsTitle}
        </HHTypography>
        <HHTypography hhVariant="body" paddingTop="1rem">
          {ruleAssessmentsTabContent.noResultsBody}
        </HHTypography>
      </HHStack>
    );
  }
  return (
    <HHStack
      direction="column"
      textAlign="center"
      paddingTop="3rem"
      spacing=".5rem"
    >
      <HHTypography hhVariant="h3">
        {ruleAssessmentsTabContent.startSearchTitle}
      </HHTypography>
      <HHTypography hhVariant="body">
        {ruleAssessmentsTabContent.startSearchBody}
      </HHTypography>
    </HHStack>
  );
};
