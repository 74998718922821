import {
  useHingeHealthSecurityContext,
  UserMenu,
} from '@hinge-health/react-component-library';
import { OktaLocalStorageKeys } from '../constants/okta';
import { menuText } from '../constants/strings/user-header-menu';
import { Admin, useCurrentAdminQuery } from '../types';

export const UserHeaderMenu = (): JSX.Element => {
  let currentAdmin: Partial<Admin> | undefined;
  const { hingeHealthAuthState, hingeHealthAuthClient } =
    useHingeHealthSecurityContext();
  const allOktaKeys = Object.values(OktaLocalStorageKeys);

  const { data } = useCurrentAdminQuery();

  /* istanbul ignore next */
  const handleLogout = async (): Promise<void> => {
    try {
      await hingeHealthAuthClient.signOut();
    } finally {
      allOktaKeys.forEach((oktaKey: string) =>
        localStorage.removeItem(oktaKey),
      );
    }
  };

  if (hingeHealthAuthState?.accessToken?.claims) {
    const { email, uid: uuid } = hingeHealthAuthState?.accessToken?.claims;
    currentAdmin = { email, uuid, firstName: '', lastName: '' };
  }

  if (data?.admin) {
    currentAdmin = { ...currentAdmin, ...data.admin };
  }

  const menuItems = [
    {
      text: menuText.logOut,
      onClick: handleLogout,
    },
  ];
  return (
    <UserMenu
      menuItems={menuItems}
      styles={{
        select: {
          marginTop: -46,
          marginLeft: -8,
          backgroundColor: '#5f7d93',
        },
      }}
      userProfile={currentAdmin as Admin}
    />
  );
};
