import {
  HHDivider,
  HHStack,
  HHTypography,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { Maybe } from 'yup';
import { GetSplitTreatmentOnOrOff } from '../../../../components/splits';
import { ActiveContractLabels } from '../../constants/strings/billing-label-enums';
import { IN_PERSON_NAME_KEY } from '../../constants/strings/contract/form-constants';
import { newBillingActivitiesAndSubTypesEnabled } from '../../constants/strings/split';
import {
  BillableActivityType,
  ContractPackagesType,
  useGetAllPackagesQuery,
} from '../../types';
import { capitalizeFirstLetterAndSpaceCamelCaseString } from '../../utils/bills-utils';
import { formatCurrency } from '../../utils/currency-helpers';
import { computeEffectiveRange } from '../../utils/date-helper';
import { cleanTypeNameGraphql } from '../../utils/graphql-utils';
import { getBillableActivityDisplayString } from '../../utils/package-utils';
import { TextGrouping } from '../utils/text-grouping';
import {
  CleanBillableActivities,
  UsableContractType,
} from './form/custom-types';
import { BillableActivityDisplay } from './milestones';
import { SelectedPackageIndications } from './package/components/form-right-panel';
import {
  PackageWithOfferings,
  ValidBillingModel,
} from './package/custom-package-types';

interface ProductPackageContractViewProps {
  data: UsableContractType;
  productPackages: { key: string; contractPackage: ContractPackagesType }[];
}

interface ProductPackageDetailViewProps {
  productPackage: { key: string; contractPackage: ContractPackagesType };
  availablePackages?: PackageWithOfferings[];
  currency?: string | Maybe<string>;
}

interface PackageBillableActivityDisplayProps {
  packageBillableActivities:
    | string[]
    | BillableActivityType[]
    | CleanBillableActivities[];
}

export const PackageBillableActivityDisplay = ({
  packageBillableActivities,
}: PackageBillableActivityDisplayProps): JSX.Element => {
  // TODO remove with feature MINT-8505
  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const adminId = hingeHealthAuthState?.accessToken?.claims.uid.toString();

  const newBillableActivitiesEnabled = GetSplitTreatmentOnOrOff(
    newBillingActivitiesAndSubTypesEnabled,
    adminId,
  );

  const billableActivities = packageBillableActivities.map(ba =>
    getBillableActivityDisplayString(ba, newBillableActivitiesEnabled),
  );

  return <BillableActivityDisplay billableActivities={billableActivities} />;
};

export const EngagementProductPackageView = ({
  productPackage,
  availablePackages,
  currency,
}: ProductPackageDetailViewProps): JSX.Element => (
  <HHStack>
    <HHTypography hhVariant="variant-bypass">
      {`${
        productPackage.key == IN_PERSON_NAME_KEY
          ? capitalizeFirstLetterAndSpaceCamelCaseString(productPackage.key)
          : productPackage.key
      } package`}
    </HHTypography>

    <HHStack direction="row" spacing={4}>
      <HHStack direction="column" spacing={4} flex={1}>
        <TextGrouping
          label="Price"
          text={formatCurrency(productPackage.contractPackage.price, currency)}
        />
        <HHStack direction="column" spacing={4}>
          <PackageBillableActivityDisplay
            packageBillableActivities={
              productPackage.contractPackage.billableActivityTypes ?? []
            }
          />
        </HHStack>
      </HHStack>
      <HHStack direction="column" spacing={4} flex={1}>
        {productPackage.contractPackage.billingModel.name ===
          ValidBillingModel.ENGAGEMENT && (
          <HHStack direction="column" spacing={4}>
            <TextGrouping
              label={ActiveContractLabels.ENGAGEMENT_INITIAL_FEE}
              text={formatCurrency(
                productPackage.contractPackage.rules?.activationFee ?? 0,
                currency,
              )}
            />
            <TextGrouping
              label={ActiveContractLabels.ENGAGEMENT_ACTIVITY_FEE}
              text={formatCurrency(
                productPackage.contractPackage.rules?.activityFee ?? 0,
                currency,
              )}
            />
          </HHStack>
        )}
        <SelectedPackageIndications
          selectedPackage={productPackage.contractPackage.package}
          availablePackages={availablePackages ?? []}
        />
      </HHStack>
    </HHStack>
  </HHStack>
);

const ProductPackageViewMapper = ({
  productPackage,
  currency,
}: ProductPackageDetailViewProps): JSX.Element => {
  const { data: availablePackages } = useGetAllPackagesQuery();
  const cleanPackages: PackageWithOfferings[] = availablePackages
    ? cleanTypeNameGraphql(availablePackages?.getAllPackages)
    : [];

  if (
    Object.values(ValidBillingModel).includes(
      productPackage.contractPackage.billingModel.name as ValidBillingModel,
    )
  ) {
    return (
      <EngagementProductPackageView
        productPackage={productPackage}
        availablePackages={cleanPackages}
        currency={currency}
      />
    );
  } else {
    return (
      <HHTypography hhVariant="muted">
        {`Invalid billing model for package: ${productPackage.contractPackage.billingModel.name}`}
      </HHTypography>
    );
  }
};

const ProductPackageContractView = ({
  data,
  productPackages,
}: ProductPackageContractViewProps): JSX.Element => (
  <HHStack
    direction="column"
    spacing={4}
    padding={4}
    sx={{ backgroundColor: 'background.default' }}
  >
    <HHStack direction="row" spacing={4}>
      <HHStack flex={1}>
        <TextGrouping
          label={ActiveContractLabels.EFFECT_DATE_LABEL}
          text={computeEffectiveRange(data.startDate, data.endDate)}
        />
        <TextGrouping
          label={ActiveContractLabels.CURRENCY}
          text={data.currency ?? 'Currency not found'}
        />
      </HHStack>
      <HHStack flex={1}>
        <TextGrouping
          label={ActiveContractLabels.USER_ANNUAL_CAP}
          text={
            data.userAnnualCap
              ? formatCurrency(data.userAnnualCap, data.currency)
              : 'No user annual spending cap found'
          }
        />
      </HHStack>
    </HHStack>
    {productPackages.map(pp => (
      <HHStack spacing={2} key={pp.key}>
        <HHDivider />
        <ProductPackageViewMapper
          key={pp.key}
          productPackage={pp}
          currency={data.currency}
        />
      </HHStack>
    ))}
  </HHStack>
);

export default ProductPackageContractView;
