import { Box, Container } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  HHButton,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { useLocation } from 'react-router-dom';
import { SignInOptions } from '@hinge-health/react-component-library/dist/components/auth/hinge-health-security-context';
import { login } from '../constants/strings/login';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100vh',
      backgroundColor: '#ffffff',
    },
    buttonBar: {
      marginTop: '38px',
      height: '48px',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

interface LocationState {
  referrer?: string;
}

export const Login = (): JSX.Element => {
  const classes = useStyles();
  const { hingeHealthAuthClient } = useHingeHealthSecurityContext();
  const location = useLocation();

  const signInWithRedirect = (): void => {
    const signInOptions = {
      originalUri: (location.state as LocationState)?.referrer || '/',
    } as SignInOptions;

    hingeHealthAuthClient.signInWithRedirect(signInOptions);
  };

  return (
    <Container maxWidth={false} className={classes.root}>
      <Box className={classes.buttonBar}>
        <HHButton
          hhVariant="contained"
          onClick={signInWithRedirect}
          layoutStyles={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginLeft: '-40px',
            marginTop: '-18px',
          }}
        >
          {login.oktaLogin}
        </HHButton>
      </Box>
    </Container>
  );
};
