import { HHStack } from '@hinge-health/react-component-library';
import { Navigate, Route, Routes } from 'react-router-dom';
import MemberDataHeader from './components/member-data-header';
import { routes } from './constants/strings/routes';
import EditFileConfigurationPage from './pages/edit-file-configuration';
import MemberDataHomePage from './pages/member-data-home';
import NewFileConfigurationPage from './pages/new-file-configuration';

export const MemberData = (): JSX.Element => (
  <HHStack
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: '#fff',
      overflow: 'scroll',
    }}
  >
    <MemberDataHeader />
    <Routes>
      <Route index element={<MemberDataHomePage />} />
      <Route
        path={routes.memberData.newFileConfiguration}
        element={<NewFileConfigurationPage />}
      />
      <Route
        path={routes.memberData.editFileConfiguration}
        element={<EditFileConfigurationPage />}
      />
      <Route path="*" element={<Navigate to={routes.memberData.home} />} />
    </Routes>
  </HHStack>
);
