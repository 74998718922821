import { GridValueGetterParams } from '@mui/x-data-grid';

export const getUserFullName = function (
  params: GridValueGetterParams,
): string {
  const patient = params?.row?.patient;

  if (!patient || (!patient.firstName.length && !patient.lastName.length)) {
    return fallbackMessage(params?.row?.patientUuid);
  }

  return `${patient.firstName} ${patient.lastName}`;
};

export const getUserId = (params: GridValueGetterParams): string =>
  params?.row?.patient?.id || '';

export const getWorkflowType = (params: GridValueGetterParams): string =>
  titleize(params?.row?.type || '');

export const getTaskState = (params: GridValueGetterParams): string =>
  titleize(params?.row?.state || '');

export const getOutreachTaskResolutionReason = (
  params: GridValueGetterParams,
): string => params?.row?.customFields?.outreachTaskResolutionReason || '';

export const titleize = (str: string): string =>
  str
    .split('-')
    .map(str => str.charAt(0).toUpperCase() + str.slice(1))
    .join(' ');

const fallbackMessage = function (patientUuid: string | undefined): string {
  const fallbackMsg = 'Data unavailable';
  return patientUuid ? `${fallbackMsg} - ${patientUuid}` : fallbackMsg;
};
