import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

export const computeEffectiveRange = (
  startDate: string,
  endDate?: string | null,
): string =>
  `${dayjs(startDate).format('L')} - ${
    endDate ? dayjs(endDate).format('L') : 'Indefinite'
  }`;

interface DisplayOptions {
  displayLocal?: boolean;
  addTimezone?: boolean;
  format?: string;
}

export const displayDateTime = (
  utcDateTime: string,
  options?: DisplayOptions,
): string => {
  const { displayLocal, addTimezone, format } = {
    displayLocal: false,
    addTimezone: true,
    format: 'L LT',
    ...(options || {}),
  };
  const finalFormat = addTimezone ? `${format} z` : format;
  let dateTime = dayjs.utc(utcDateTime).tz('UTC');

  if (displayLocal) {
    dateTime = dateTime.local();
  }

  return dateTime.format(finalFormat);
};
