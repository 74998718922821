import { IN_PERSON_NAME_KEY } from '../../constants/strings/contract/form-constants';
import { ContractTemplateType } from '../../types';
import { extractProductPackages } from '../../utils/contract-utils';
import { UsableContractType } from './form/custom-types';
import LegacyContractDetailView from './legacy-contract-detail-view';
import ProductPackageContractView from './product-package-detail-view';

interface ContractDetailViewProps {
  data: UsableContractType;
  templates: ContractTemplateType[];
  showJson?: boolean;
  slimDisplay?: boolean;
}
const ContractDetailView = ({
  data,
  templates,
  slimDisplay = false,
}: ContractDetailViewProps): JSX.Element => {
  const productPackageData = extractProductPackages(data.contractPackages);
  const inPersonVisitContractPackage = productPackageData.find(
    productPackageData => productPackageData.key === IN_PERSON_NAME_KEY,
  );
  const isProductPackageContract =
    data.packageEnabled && productPackageData.length > 0;

  return isProductPackageContract ? (
    <ProductPackageContractView
      data={data}
      productPackages={productPackageData}
    />
  ) : (
    <LegacyContractDetailView
      data={data}
      templates={templates}
      inPersonVisitContractPackage={
        inPersonVisitContractPackage?.contractPackage
      }
      slimDisplay={slimDisplay}
    />
  );
};

export default ContractDetailView;
