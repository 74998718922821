import { ProgramIndicationType } from '../../../../../types';
import {
  PROGRAM_ID_ACUTE,
  PROGRAM_ID_CHRONIC,
  PROGRAM_ID_PERISURGICAL,
} from '../../../constants/numbers/form-constants';
import {
  ContractPackagesType,
  DisabledProgramIndication,
} from '../../../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../../utils/bills-utils';
import { filterProgramIndicationsById } from '../../../utils/contract-utils';

const IPV_ENGAGEMENT_NAME = 'in_person_visit';

export interface IndicationSettings {
  name: string;
  enabled: boolean;
  programIndication: string;
}

export interface ContractPackageSettings {
  id: number;
  name: string;
  billingEnabled?: boolean;
  indications: IndicationSettings[] | undefined;
  allDisabled: boolean;
}

export interface ContractSettingsFormValues {
  disableAllChronic: boolean;
  disableAllAcute: boolean;
  disableAllPerisurgical: boolean;
  chronicIndications: IndicationSettings[];
  acuteIndications: IndicationSettings[];
  perisurgicalIndications: IndicationSettings[];
  disableAllIndications: boolean;
  enableInPersonVisit: boolean;
  contractPackages?: ContractPackageSettings[];
}

export function GetDefaultValues(
  allProgramIndications: ProgramIndicationType[],
  disabledProgramIndications: DisabledProgramIndication[],
  isInPersonPackage: boolean,
  contractPackages: ContractPackagesType[],
): ContractSettingsFormValues {
  const allChronicIndications = filterProgramIndicationsById(
    PROGRAM_ID_CHRONIC,
    allProgramIndications,
  );
  const allAcuteIndications = filterProgramIndicationsById(
    PROGRAM_ID_ACUTE,
    allProgramIndications,
  );
  const allPerisurgicalIndications = filterProgramIndicationsById(
    PROGRAM_ID_PERISURGICAL,
    allProgramIndications,
  );

  const disabledChronicIndications = disabledProgramIndications.filter(d =>
    d.programIndicationIdentifier.includes('chronic'),
  );

  const disabledAcuteIndications = disabledProgramIndications.filter(d =>
    d.programIndicationIdentifier.includes('acute'),
  );

  const disabledPerisurgicalIndications = disabledProgramIndications.filter(d =>
    d.programIndicationIdentifier.includes('perisurgical'),
  );

  const areAllIndicationsDisabledInPackage = (
    cp: ContractPackagesType,
  ): boolean =>
    cp.package.packageOfferings
      .filter(po => po.packageOffering === 'program_indication')
      .every(po =>
        cp.disabledProgramIndications?.some(
          d => d.programIndicationIdentifier === po.programIndication,
        ),
      );

  const areAllContractPackagesDisabled = (): boolean =>
    contractPackages.some(p =>
      p.billableActivityTypes.some(b => b.name !== IPV_ENGAGEMENT_NAME),
    )
      ? contractPackages.every(p => areAllIndicationsDisabledInPackage(p))
      : false;

  const areAllIndicationsDisabled = (): boolean => {
    if (
      contractPackages.some(p =>
        p.billableActivityTypes.some(b => b.name !== IPV_ENGAGEMENT_NAME),
      )
    ) {
      return areAllContractPackagesDisabled();
    }
    return (
      disabledChronicIndications.length === allChronicIndications.length &&
      disabledAcuteIndications.length === allAcuteIndications.length &&
      disabledPerisurgicalIndications.length ===
        allPerisurgicalIndications.length
    );
  };

  return {
    disableAllChronic:
      disabledChronicIndications.length === allChronicIndications.length,
    disableAllAcute:
      disabledAcuteIndications.length === allAcuteIndications.length,
    disableAllPerisurgical:
      disabledPerisurgicalIndications.length ===
      allPerisurgicalIndications.length,
    disableAllIndications: areAllIndicationsDisabled(),
    chronicIndications: allChronicIndications.map(i => ({
      name: capitalizeFirstLetterAndSpaceSnakeString(
        i.programIndicationIdentifier,
      ),
      enabled: !disabledProgramIndications.some(
        d => d.programIndicationIdentifier === i.programIndicationIdentifier,
      ),
      programIndication: i.programIndicationIdentifier,
    })),
    acuteIndications: allAcuteIndications.map(i => ({
      name: capitalizeFirstLetterAndSpaceSnakeString(
        i.programIndicationIdentifier,
      ),
      enabled: !disabledProgramIndications.some(
        d => d.programIndicationIdentifier === i.programIndicationIdentifier,
      ),
      programIndication: i.programIndicationIdentifier,
    })),
    perisurgicalIndications: allPerisurgicalIndications.map(i => ({
      name: capitalizeFirstLetterAndSpaceSnakeString(
        i.programIndicationIdentifier,
      ),
      enabled: !disabledProgramIndications.some(
        d => d.programIndicationIdentifier === i.programIndicationIdentifier,
      ),
      programIndication: i.programIndicationIdentifier,
    })),
    enableInPersonVisit: isInPersonPackage
      ? contractPackages[0].billingEnabled
      : false,
    contractPackages: contractPackages.map(p => ({
      id: p.id,
      name: p.package.name,
      billingEnabled: p.billingEnabled,
      allDisabled: areAllIndicationsDisabledInPackage(p),
      indications: p.package.packageOfferings.map(po => ({
        name: capitalizeFirstLetterAndSpaceSnakeString(
          po.programIndication ?? '',
        ),
        enabled: !p.disabledProgramIndications?.some(
          d => d.programIndicationIdentifier === po.programIndication,
        ),
        programIndication: po.programIndication ?? '',
      })),
    })),
  };
}
