import { HHBox, HHTypography } from '@hinge-health/react-component-library';
import { tagsTabContent } from '../constants/tags.constant';

export const NoFilteredTagsMessage = (): JSX.Element => (
  <HHBox textAlign="center">
    <HHTypography hhVariant="h3">
      {tagsTabContent.noFilteredTags.title}
    </HHTypography>
    <HHTypography hhVariant="body">
      {tagsTabContent.noFilteredTags.body}
    </HHTypography>
  </HHBox>
);
