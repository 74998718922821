import {
  HHSnackbarAlert,
  HHSnackbarAlertProps,
} from '@hinge-health/react-component-library';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface SnackbarContextType {
  setSnackbarState: (
    message: string,
    severity: HHSnackbarAlertProps['severity'],
    autoHideTime?: number,
  ) => void;
}

const initialContext: SnackbarContextType = {
  setSnackbarState: () => null,
};

export const SnackbarContext =
  createContext<SnackbarContextType>(initialContext);

export const SnackbarProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [snackBarState, setSnackBarState] = useState<
    Pick<HHSnackbarAlertProps, 'message' | 'severity' | 'autoHideTime'>
  >({
    message: '',
    severity: 'info',
    autoHideTime: 1000,
  });

  const setSnackbar = useCallback(
    (
      message: string,
      severity: HHSnackbarAlertProps['severity'],
      autoHideTime?: number,
    ): void => {
      setSnackBarState({
        message,
        severity,
        autoHideTime: autoHideTime ?? 1000,
      });
      setIsOpen(true);
    },
    [setSnackBarState, setIsOpen],
  );

  const closeSnackbar = useCallback((): void => {
    setIsOpen(false);
  }, [setIsOpen]);

  const value = useMemo(
    () => ({ setSnackbarState: setSnackbar, closeSnackbar }),
    [setSnackbar, closeSnackbar],
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <HHSnackbarAlert
        verticalAlignment="top"
        horizontalAlignment="center"
        hhVariant="filled"
        open={isOpen}
        onClose={closeSnackbar}
        {...snackBarState}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = (): SnackbarContextType =>
  useContext(SnackbarContext);
