import {
  HHAlert,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { formatCurrency } from '../../../../utils/currency-helpers';
import { computeEffectiveRange } from '../../../../utils/date-helper';
import { TextGrouping } from '../../../utils/text-grouping';
import { ValidBillingModel } from '../custom-package-types';
import { PackageFormConfirmationProps } from './package-form-confirmation';

export interface NewContractPackageDetailsProps
  extends Pick<
    PackageFormConfirmationProps,
    'formValues' | 'availablePackages' | 'billingModels'
  > {
  notificationString: string;
}

const NewContractPackageDetails = ({
  notificationString,
  formValues,
  availablePackages,
  billingModels,
}: NewContractPackageDetailsProps): JSX.Element => {
  const engagementModel = billingModels.find(
    model => model.name === ValidBillingModel.ENGAGEMENT,
  );

  return (
    <HHStack spacing={3}>
      {notificationString && (
        <HHAlert hhVariant="outlined" severity="info">
          <i>{notificationString}</i>
        </HHAlert>
      )}
      <TextGrouping
        label="Start date"
        text={computeEffectiveRange(formValues.startDate, formValues.endDate)}
      />
      <TextGrouping
        label="Annual Cap"
        text={formatCurrency(formValues.userAnnualCap, formValues.currency)}
      />
      <TextGrouping label="Currency" text={formValues.currency} />
      {formValues.contractPackages.map(contractPackage => (
        <HHStack key={contractPackage.packageId} spacing={2}>
          <HHTypography
            hhVariant="subtitle1"
            layoutStyles={{
              textTransform: 'capitalize',
            }}
          >
            {
              availablePackages.find(
                ({ id }) => id === contractPackage.packageId,
              )?.name
            }{' '}
            Package
          </HHTypography>
          <HHStack direction="column" spacing={4}>
            <HHStack direction="row" spacing={2} flex={2}>
              <HHStack flex={1}>
                <TextGrouping
                  label="Billing Model"
                  text={
                    billingModels.find(
                      model => model.id === contractPackage.billingModelId,
                    )?.name ?? '--'
                  }
                />
              </HHStack>
              <HHStack flex={2}>
                <TextGrouping
                  label="Price"
                  text={formatCurrency(
                    contractPackage.price,
                    formValues.currency,
                  )}
                />
              </HHStack>
            </HHStack>
            {contractPackage.billingModelId === engagementModel?.id && (
              <HHStack direction="row" spacing={2} flex={2}>
                <HHStack flex={1}>
                  <TextGrouping
                    label="Activation Fee"
                    text={formatCurrency(
                      contractPackage.rules?.activationFee ?? 0,
                      formValues.currency,
                    )}
                  />
                </HHStack>
                <HHStack flex={2}>
                  <TextGrouping
                    label="Activity Fee"
                    text={formatCurrency(
                      contractPackage.rules?.activityFee ?? 0,
                      formValues.currency,
                    )}
                  />
                </HHStack>
              </HHStack>
            )}
          </HHStack>
        </HHStack>
      ))}
    </HHStack>
  );
};

export default NewContractPackageDetails;
