export const menuTheme = {
  colors: {
    menuFont: 'white',
    menuIcon: 'white',
    menuSelected: 'black',
    menuSelectedHover: 'black',
    menuHover: 'black',
    menu: 'rgb(0, 43, 12)',
  },
  font: {
    menuSize: '16px',
    menuFamily: 'Lato',
  },
};
