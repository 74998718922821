export const colors = {
  background: '#263744',
  text: 'white',
};

export const fontStyles = {
  fontFamily: 'Lato',
  fontSize: '20px',
  fontWeight: 300,
  letterSpacing: '0.21px',
};

export * from './ti-enrollment';
export * from './ti-page';
