import { ApolloError } from '@apollo/client';
import { useEffect } from 'react';
import {
  DiscountDetail as DiscountDetailTypeRaw,
  DiscountUsage,
  ScopeType,
  useGetDiscountsQuery,
  useGetDiscountUsageLazyQuery,
} from '../types';
import {
  computeActiveStatus,
  dateSorterFunction,
} from '../utils/contract-utils';
import { IDiscount } from '../utils/discount-utils';

export interface DiscountDetailType // just to fix a issue with type auto generated by graphql-codegen
  extends Omit<DiscountDetailTypeRaw, 'subscriptionTier'> {
  subscriptionTier: (number | null | undefined)[];
}

export const cleanseDiscountsData = (
  discounts: DiscountDetailType[],
  discountUsage: {
    data?: DiscountUsage[];
    loading: boolean;
    error?: ApolloError;
  },
): IDiscount[] => {
  const cleanData = discounts.reduce<{ [key: string]: IDiscount }>(
    (coll, discount) => {
      if (!coll[String(discount.discountDefinitionId)]) {
        coll[String(discount.discountDefinitionId)] = {
          clientId: discount.clientId,
          discountDefinitionId: discount.discountDefinitionId,
          discountType: discount.discountType,
          endDate: discount.endDate,
          insurerId: discount.insurerId,
          name: discount.name,
          partnershipId: discount.partnershipId,
          programId: discount.programId,
          packageId: discount.packageId,
          startDate: discount.startDate,
          discountDetails: [],
          suppressEmptyCharges: discount.suppressEmptyCharges,
          status: computeActiveStatus(
            false,
            discount.startDate,
            discount.endDate,
          ),
        };
      }
      coll[discount.discountDefinitionId].discountDetails.push({
        discount: discount.discount,
        discountDetailId: discount.discountDetailId,
        milestoneDiscount: discount.milestoneDiscount,
        subscriptionTier: discount.subscriptionTier,
        tier: discount.tier,
        discountUsage: {
          ...discountUsage.data?.find(
            d => d.discountDetailId === discount.discountDetailId,
          ),
          loading: discountUsage.loading,
          error: discountUsage.error,
        },
      });
      coll[discount.discountDefinitionId].discountDetails.sort(
        (a, b): number => a.tier - b.tier,
      );
      return coll;
    },
    {},
  );

  return Object.values(cleanData).sort((a, b) =>
    dateSorterFunction(a.startDate, b.startDate),
  );
};

export const useGetDiscounts = (
  clientId: number,
  partnershipIds?: number[],
): {
  data?: IDiscount[];
  loading: boolean;
  error?: ApolloError;
  usageStatus: {
    loading: boolean;
    error?: ApolloError;
  };
} => {
  const {
    data: discountsData,
    loading,
    error,
  } = useGetDiscountsQuery({
    variables: {
      clientId,
      partnershipIds,
      scopeType: ScopeType.Inclusive,
    },
  });

  const [
    getDiscountUsage,
    { data: usageData, loading: usageLoading, error: usageError },
  ] = useGetDiscountUsageLazyQuery();

  useEffect(() => {
    if (discountsData?.getDiscounts && discountsData?.getDiscounts.length) {
      getDiscountUsage({
        variables: {
          clientId,
          discountDetailIds: discountsData?.getDiscounts.map(
            d => d.discountDetailId,
          ),
        },
      });
    }
  }, [discountsData, clientId, getDiscountUsage]);

  return {
    data:
      discountsData?.getDiscounts &&
      cleanseDiscountsData(discountsData.getDiscounts, {
        data: usageData?.getDiscountUsage,
        loading: usageLoading,
        error: usageError,
      }),
    loading,
    error,
    usageStatus: {
      loading: usageLoading,
      error: usageError,
    },
  };
};
