import { HHFormGroup } from '@hinge-health/react-component-library';
import {
  CONTRACT_FORM_BILLABLE_ACTIVITIES_GROUP_ID,
  IN_PERSON_NAME_KEY,
} from '../../../../constants/strings/contract/form-constants';
import { BillableActivitiesProps } from './billable-activities';
import BillableActivityCheckBox from './billable-activity-checkbox';
import { BillableActivityWithRules } from './billable-activity-rules';

export interface LegacyBillableActivitiesProps extends BillableActivitiesProps {
  billableActivities: BillableActivityWithRules[];
}
/**
 * After go live of new billable activities, this component will be removed
 */
const LegacyBillableActivities = ({
  wholeFormDisabled,
  billableActivities,
  direction,
  isPackageForm = false,
  index = 0,
}: LegacyBillableActivitiesProps): JSX.Element => (
  <HHFormGroup
    data-testid={CONTRACT_FORM_BILLABLE_ACTIVITIES_GROUP_ID}
    sx={{
      alignItems: 'flex-start',
      flexDirection: direction ?? 'column',
    }}
  >
    {billableActivities
      ?.filter(
        billActivity => ![IN_PERSON_NAME_KEY].includes(billActivity.name),
      )
      .map(billActivity => (
        <BillableActivityCheckBox
          key={billActivity.id}
          billActivity={billActivity}
          wholeFormDisabled={wholeFormDisabled}
          index={index}
          isPackageForm={isPackageForm}
        />
      ))}
  </HHFormGroup>
);

export default LegacyBillableActivities;
