import {
  HHCheckbox,
  HHFormControlLabel,
  HHSelect,
  HHStack,
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Autocomplete, FormHelperText } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  BillingTypes,
  ClientType,
  PartnershipType,
  PaymentType,
  ScopeType,
  useGetClientInsurersByIdsLazyQuery,
} from '../../types';
import {
  addAllOption,
  EligibilitySearchDepth,
  InsurerType,
  ToolRunMode,
} from './tool-validation-schema';

interface BillToolFormProps {
  clientsData: ClientType[];
  insurersData: InsurerType[];
  partnershipsData: PartnershipType[];
  paymentTypesData: PaymentType[];
}

const BillToolForm = ({
  clientsData,
  insurersData,
  paymentTypesData,
}: BillToolFormProps): JSX.Element => {
  const {
    control,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext();

  const [getClientInsurersByIds, { loading }] =
    useGetClientInsurersByIdsLazyQuery();

  const paymentTypeOptions = paymentTypesData
    .map(pt => ({
      label: pt.code,
      value: pt.code,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const client = watch('client');
  const insurer = watch('insurer');
  const partnership = watch('partnership');
  const isLoading = watch('isLoading');
  const clientOptions = watch('clientOptions');
  const insurerOptions = watch('insurerOptions');
  const partnershipOptions = watch('partnershipOptions');
  const billingTypeOptions = watch('billingTypeOptions');

  const clientId = client?.id;
  const insurerId = insurer?.id;
  const partnershipId = partnership?.id;

  useEffect(() => {
    setValue('isLoading', loading);
  }, [loading, setValue]);

  useEffect(() => {
    const isAllClientSet = clientId === -1;
    const clientIsSet = clientId && !isAllClientSet;
    if (clientIsSet || insurerId || partnershipId) {
      getClientInsurersByIds({
        variables: {
          clientIds: clientIsSet ? [clientId] : [],
          insurerIds: insurerId ? [insurerId] : [],
          partnershipIds: partnershipId ? [partnershipId] : [],
          scope: ScopeType.Exclusive,
        },
      }).then(({ data }) => {
        if (data?.getClientInsurersByIds) {
          const { clientOptions, insurerOptions, billingTypeOptions } =
            data.getClientInsurersByIds.reduce<{
              clientOptions: { [key: number]: ClientType };
              insurerOptions: { [key: number]: InsurerType };
              billingTypeOptions: {
                [key: string]: { value: string; displayName: string };
              };
            }>(
              (coll, { client, insurer, billingType }) => ({
                clientOptions: client
                  ? { ...coll.clientOptions, [client.id]: client }
                  : coll.clientOptions,
                insurerOptions: insurer
                  ? { ...coll.insurerOptions, [insurer.id]: insurer }
                  : coll.insurerOptions,
                billingTypeOptions: billingType
                  ? {
                      ...coll.billingTypeOptions,
                      [billingType.toUpperCase()]: {
                        value: billingType.toUpperCase(),
                        displayName: billingType.toUpperCase(),
                      },
                    }
                  : { ...coll.billingTypeOptions },
              }),
              {
                clientOptions: {},
                insurerOptions: {},
                billingTypeOptions: {},
              },
            );
          if (!clientId) {
            setValue(
              'clientOptions',
              addAllOption(
                Object.values(clientOptions).sort((a, b) =>
                  a.name.localeCompare(b.name),
                ),
              ),
            );
          }
          if (!insurerId) {
            setValue(
              'insurerOptions',
              Object.values(insurerOptions).sort((a, b) =>
                a.name.localeCompare(b.name),
              ),
            );
          }
          setValue(
            'billingTypeOptions',
            Object.values(billingTypeOptions).sort((a, b) =>
              a.displayName.localeCompare(b.displayName),
            ),
          );
          setValue('billingType', null, { shouldValidate: true });
        }
      });
    } else {
      setValue('clientOptions', addAllOption(clientsData));
      setValue('insurerOptions', insurersData);
      setValue(
        'billingTypeOptions',
        Object.values(BillingTypes)
          .map(value => ({
            value,
            displayName: value,
          }))
          .sort((a, b) => a.displayName.localeCompare(b.displayName)),
      );
      setValue('billingType', null, { shouldValidate: true });
      if (!isAllClientSet) {
        setValue('client', null, { shouldValidate: true });
      }
      setValue('insurer', null, { shouldValidate: true });
      setValue('partnership', null, { shouldValidate: true });
    }
  }, [
    clientId,
    insurerId,
    partnershipId,
    setValue,
    clientsData,
    insurersData,
    getClientInsurersByIds,
  ]);

  return (
    <HHStack spacing={6} direction="column">
      <HHStack spacing={6} direction={{ xs: 'column', md: 'row' }}>
        <HHStack spacing={6} direction="column" flex={1}>
          <Controller
            control={control}
            name="runMode"
            render={({ field: { onChange, value } }): JSX.Element => (
              <HHSelect
                label="Run Mode"
                initialSelectValue={value}
                selectOptions={Object.values(ToolRunMode).map(value => ({
                  value,
                  displayName: value,
                }))}
                sx={{ textTransform: 'capitalize' }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .MuiMenuItem-root': {
                        textTransform: 'capitalize',
                      },
                    },
                  },
                }}
                onChange={(e): void => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="partnership"
            render={({ field: { onChange, value } }): JSX.Element => (
              <Autocomplete
                value={value || null}
                options={partnershipOptions}
                getOptionLabel={(option: PartnershipType): string =>
                  option.name
                }
                isOptionEqualToValue={(
                  option: PartnershipType,
                  value: PartnershipType,
                ): boolean =>
                  option.id === value.id || option.name === value.name
                }
                filterOptions={(options, params): PartnershipType[] =>
                  options.filter(
                    option =>
                      option.name
                        .toLowerCase()
                        .indexOf(params.inputValue.toLowerCase()) !== -1 ||
                      option.id.toString().indexOf(params.inputValue) !== -1,
                  )
                }
                size="small"
                onChange={(_, newValue: PartnershipType | null): void => {
                  resetField('client', { defaultValue: null });
                  resetField('insurer', { defaultValue: null });
                  if (!newValue) {
                    setValue('clientOptions', addAllOption(clientsData));
                    setValue('insurerOptions', insurersData);
                  }
                  onChange(newValue);
                }}
                fullWidth
                disabled={isLoading}
                sx={{ minWidth: 230 }}
                renderInput={(params): JSX.Element => (
                  <HHTextField
                    {...params}
                    hhVariant="variant-bypass"
                    variant="outlined"
                    label={'Search by partnership name or id'}
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="client"
            render={({ field: { onChange, value } }): JSX.Element => (
              <Autocomplete
                value={value || null}
                options={clientOptions}
                getOptionLabel={(option: ClientType): string => option.name}
                disabled={isLoading}
                isOptionEqualToValue={(
                  option: ClientType,
                  value: ClientType,
                ): boolean =>
                  option.id === value.id || option.name === value.name
                }
                filterOptions={(options, params): ClientType[] =>
                  options.filter(
                    option =>
                      option.name
                        .toLowerCase()
                        .indexOf(params.inputValue.toLowerCase()) !== -1 ||
                      option.id.toString().indexOf(params.inputValue) !== -1,
                  )
                }
                size="small"
                onChange={(_, newValue: ClientType | null): void => {
                  resetField('insurer', { defaultValue: null });
                  onChange(newValue);
                }}
                fullWidth
                sx={{ minWidth: 230 }}
                renderInput={(params): JSX.Element => (
                  <HHTextField
                    {...params}
                    hhVariant="variant-bypass"
                    variant="outlined"
                    label={'Search by Client name or id'}
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="insurer"
            render={({ field: { onChange, value } }): JSX.Element => (
              <Autocomplete
                value={value || null}
                options={insurerOptions}
                getOptionLabel={(option: InsurerType): string => option.name}
                isOptionEqualToValue={(
                  option: InsurerType,
                  value: InsurerType,
                ): boolean =>
                  option.id === value.id || option.name === value.name
                }
                filterOptions={(options, params): InsurerType[] =>
                  options.filter(
                    option =>
                      option.name
                        .toLowerCase()
                        .indexOf(params.inputValue.toLowerCase()) !== -1 ||
                      option.id.toString().indexOf(params.inputValue) !== -1,
                  )
                }
                size="small"
                disabled={isLoading}
                onChange={(_, newValue: InsurerType | null): void =>
                  onChange(newValue)
                }
                fullWidth
                sx={{ minWidth: 230 }}
                renderInput={(params): JSX.Element => (
                  <HHTextField
                    {...params}
                    hhVariant="variant-bypass"
                    variant="outlined"
                    label={'Search by insurer name or id'}
                  />
                )}
              />
            )}
          />
        </HHStack>
        <HHStack spacing={6} direction="column" flex={1}>
          <Controller
            control={control}
            name="paymentTypes"
            render={({ field: { onChange, value } }): JSX.Element => (
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={paymentTypeOptions}
                value={paymentTypeOptions.filter(option =>
                  value?.includes(option.value),
                )}
                onChange={(_, newValue): void => {
                  onChange(
                    newValue.length > 0
                      ? newValue.map(option => option.value)
                      : null,
                  );
                }}
                renderOption={(props, option, { selected }): JSX.Element => (
                  <li {...props}>
                    <HHCheckbox
                      checked={selected}
                      color="primary"
                      hhVariant="variant-bypass"
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params): JSX.Element => (
                  <HHTextField
                    {...params}
                    hhVariant="variant-bypass"
                    variant="outlined"
                    label="Payment Types"
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="claimsLimit"
            render={({ field: { onChange, value } }): JSX.Element => (
              <HHTextField
                hhVariant="variant-bypass"
                label="Claims Limit"
                value={value}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  min: 0,
                }}
                fullWidth
                onChange={(e): void => {
                  onChange(e.target.value);
                }}
                type="number"
              />
            )}
          />
          <Controller
            control={control}
            name="eligibilitySearchDepth"
            render={({ field: { onChange, value } }): JSX.Element => (
              <HHSelect
                label="Eligibility Search Depth"
                initialSelectValue={value}
                selectOptions={Object.values(EligibilitySearchDepth).map(
                  value => ({
                    value,
                    displayName: value,
                  }),
                )}
                sx={{ textTransform: 'capitalize' }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .MuiMenuItem-root': {
                        textTransform: 'capitalize',
                      },
                    },
                  },
                }}
                onChange={(e): void => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="billingType"
            render={({ field: { onChange, value } }): JSX.Element => (
              <HHSelect
                label="Billing Type"
                id="billing-type-select"
                initialSelectValue={value || null}
                selectOptions={billingTypeOptions}
                sx={{ textTransform: 'capitalize' }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .MuiMenuItem-root': {
                        textTransform: 'capitalize',
                      },
                    },
                  },
                }}
                onChange={(e): void => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </HHStack>
      </HHStack>
      <HHStack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        sx={{
          gap: 4,
        }}
      >
        <Controller
          control={control}
          name="dateRange"
          render={({ field: { onChange, value } }): JSX.Element => (
            <DateRangePicker
              disableFuture
              value={value || ([null, null] as [Date | null, Date | null])}
              onChange={(newValue): void => onChange(newValue)}
              renderInput={(startProps, endProps): JSX.Element => (
                <HHStack direction="row" spacing={2} alignItems="flex-end">
                  <HHTextField
                    hhVariant="variant-bypass"
                    variant="standard"
                    type="string"
                    InputLabelProps={{ sx: { color: 'gray' } }}
                    {...startProps}
                    label="Start Date"
                    error={Object.keys(errors?.dateRange ?? {}).length !== 0}
                  />
                  <HHTypography hhVariant="body1"> to </HHTypography>
                  <HHTextField
                    hhVariant="variant-bypass"
                    variant="standard"
                    type="string"
                    InputLabelProps={{ sx: { color: 'gray' } }}
                    {...endProps}
                    label="End Date"
                    error={Object.keys(errors?.dateRange ?? {}).length !== 0}
                  />
                  {errors?.dateRange?.message ? (
                    <FormHelperText>{errors.dateRange?.message}</FormHelperText>
                  ) : null}
                </HHStack>
              )}
            />
          )}
        />
        <HHFormControlLabel
          label="Exclude Partnership"
          control={
            <Controller
              control={control}
              name="excludePartnership"
              render={({ field: { onChange, value } }): JSX.Element => (
                <HHCheckbox
                  sx={{ color: 'gray' }}
                  checked={value}
                  onChange={(e): void => onChange(e)}
                  hhVariant="primary"
                />
              )}
            />
          }
        />
      </HHStack>
    </HHStack>
  );
};

export default BillToolForm;
