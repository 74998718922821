import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { SecurityContext } from '@hinge-health/react-component-library/dist/components/auth/hinge-health-security-context';
import * as Sentry from '@sentry/browser';
import { withScalars } from 'apollo-link-scalars';
import { buildClientSchema, IntrospectionQuery } from 'graphql';
import { ReactNode, useMemo } from 'react';
import { AvailableTransitions, Task } from '../types';
import introspectionResult from '../utils/introspection.json';

const schema = buildClientSchema(
  introspectionResult as unknown as IntrospectionQuery,
);
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BFF_URL}`,
  credentials: 'include',
});

const scalarsLink = withScalars({
  schema,
  typesMap: {
    DateTime: {
      serialize: (parsed: Date | null): string | null =>
        parsed && parsed.toISOString(),
      parseValue: (raw: number | string | null): Date | null | 0 | '' =>
        raw && new Date(raw),
    },
  },
});

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (
        (path?.toString() === 'refreshJwt' && message === 'Unauthorized') ||
        (path?.toString() === 'user' &&
          (message.includes('404') || message.includes('401')))
      ) {
        return;
      }
      Sentry.captureMessage(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}`,
      );
    });

  if (networkError) Sentry.captureMessage(`[Network error]: ${networkError}`);
});

const apolloLink = ApolloLink.from([errorLink, scalarsLink, httpLink]);

export const apolloContextLink = (
  client: SecurityContext['hingeHealthAuthClient'],
): ApolloLink =>
  setContext(async (_request, { headers }) => {
    const accessToken = await client.getOrRenewAccessToken();

    if (!accessToken) {
      console.error('No access token found while making a request');
      return { headers };
    }

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    };
  });

interface ApolloProps {
  children: ReactNode;
}
export const apolloCacheOptions = {
  typePolicies: {
    WorkflowPayload: {
      fields: {
        availableTransitions: {
          merge(
            existing: AvailableTransitions,
            incoming: AvailableTransitions,
          ): AvailableTransitions {
            return { ...existing, ...incoming };
          },
        },
        tasks: {
          merge(existing: Task, incoming: Task): Task {
            return { ...existing, ...incoming };
          },
        },
      },
    },
  },
};
const cache = new InMemoryCache(apolloCacheOptions);
const Apollo = ({ children }: ApolloProps): JSX.Element => {
  const { hingeHealthAuthClient } = useHingeHealthSecurityContext();

  const client = useMemo((): ApolloClient<NormalizedCacheObject> => {
    const contextLink = apolloContextLink(hingeHealthAuthClient);

    return new ApolloClient({ cache, link: from([contextLink, apolloLink]) });
  }, [hingeHealthAuthClient]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;
