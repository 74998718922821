import { HHTextField } from '@hinge-health/react-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { ActiveStatus } from '../../../../utils/contract-utils';
import { IDiscount } from '../../../../utils/discount-utils';

export const placeHolderTexts: Record<string, string> = {
  program_access: 'Discount amount %',
  innovation_credits: '# of free subscriptions',
  billing_caps: '$ amount capped',
  free_billable_activities: '# of free activities',
  placeholder: 'Discount Amount',
};

export interface DiscountInputProps {
  discountToEdit: IDiscount | null;
}

export const DiscountInput = ({
  discountToEdit,
}: DiscountInputProps): JSX.Element => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const watchedDiscountType = watch('discountType');

  return (
    <Controller
      control={control}
      name="discount"
      render={({ field: { onChange, value } }): JSX.Element => (
        <HHTextField
          fullWidth
          disabled={
            discountToEdit
              ? discountToEdit.status !== ActiveStatus.Future
              : false
          }
          error={Object.keys(errors?.discount ?? {}).length !== 0}
          helperText={errors.discount?.message}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]',
            min: '0',
          }}
          type="number"
          label={
            placeHolderTexts[watchedDiscountType] ??
            placeHolderTexts['placeholder']
          }
          hhVariant="variant-bypass"
          onChange={onChange}
          value={value}
          key="discount-input"
        />
      )}
    />
  );
};
