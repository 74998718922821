import {
  HHBox,
  HHIconButton,
  HHTooltip,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Cancel } from '@mui/icons-material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { dateSortComparator } from '../../../../utils/datagrid-utils';
import { tabRoutes } from '../../constants/hinge-connect-constants.constant';
import {
  assessmentTasksRoutes,
  assessmentTasksTabContent,
  AssessmentTaskStatus,
  listAssessmentTasksGridContent,
} from '../constants/assessment-tasks.constant';
import { AssessmentTaskListItem } from '../types';
import {
  getAssessmentTaskActionsExecuted,
  getAssessmentTaskAssertionsPassed,
  getAssessmentTaskCompleted,
  getAssessmentTaskCreatedAt,
  getAssessmentTaskErrored,
  getAssessmentTaskId,
  getAssessmentTaskSource,
  getAssessmentTaskStatus,
  getAssessmentTaskTotal,
} from '../utils/list-assessment-tasks-grid-utils';
import { AssessmentTaskProgressBar } from './assessment-task-progress-bar';

interface ListAssessmentTasksGridProps {
  assessmentTasks: AssessmentTaskListItem[];
  openCancellationModal: (id: string) => void;
}

export const ListAssessmentTasksGrid = ({
  assessmentTasks,
  openCancellationModal,
}: ListAssessmentTasksGridProps): JSX.Element => {
  const navigate = useNavigate();

  enum ColumnHeaders {
    createdAt = 'createdAt',
    status = 'status',
    source = 'source',
    total = 'total',
    completed = 'completed',
    errored = 'errored',
    actionsExecuted = 'actionsExecuted',
    assertionsPassed = 'assertionsPassed',
    id = 'id',
  }

  const getStatusColor = (status: AssessmentTaskStatus): string => {
    switch (status) {
      case AssessmentTaskStatus.COMPLETED:
        return 'primary';
      case AssessmentTaskStatus.FAILED:
        return 'error';
      case AssessmentTaskStatus.CANCELLED:
        return '#AB8C61';
      default:
        return 'grey';
    }
  };

  const ListAssessmentTasksColumns: GridColumns = [
    {
      field: ColumnHeaders.createdAt,
      headerName: listAssessmentTasksGridContent.createdAt,
      type: 'date',
      width: 175,
      valueGetter: getAssessmentTaskCreatedAt,
      renderCell: (params): string => {
        const date = new Date(params.value as string);
        return date.toLocaleString();
      },
      sortComparator: dateSortComparator,
    },
    {
      field: ColumnHeaders.status,
      headerName: listAssessmentTasksGridContent.status,
      width: 125,
      valueGetter: getAssessmentTaskStatus,
      renderCell: (params): JSX.Element => (
        <HHTypography hhVariant="body" color={getStatusColor(params.value)}>
          {params.value}
        </HHTypography>
      ),
    },
    {
      field: ColumnHeaders.source,
      headerName: listAssessmentTasksGridContent.source,
      width: 125,
      valueGetter: getAssessmentTaskSource,
    },
    {
      field: 'progress',
      headerName: listAssessmentTasksGridContent.progress,
      width: 250,
      sortable: false,
      renderCell: (params): JSX.Element => (
        <HHBox width="100%">
          <AssessmentTaskProgressBar
            totalAssessments={params.row.totalAssessments}
            remaining={params.row.remaining}
          />
        </HHBox>
      ),
    },
    {
      field: ColumnHeaders.total,
      headerName: listAssessmentTasksGridContent.total,
      width: 125,
      valueGetter: getAssessmentTaskTotal,
    },
    {
      field: ColumnHeaders.completed,
      headerName: listAssessmentTasksGridContent.completed,
      width: 125,
      valueGetter: getAssessmentTaskCompleted,
    },
    {
      field: ColumnHeaders.errored,
      headerName: listAssessmentTasksGridContent.errored,
      width: 125,
      valueGetter: getAssessmentTaskErrored,
    },
    {
      field: ColumnHeaders.actionsExecuted,
      headerName: listAssessmentTasksGridContent.actionsExecuted,
      width: 125,
      valueGetter: getAssessmentTaskActionsExecuted,
    },
    {
      field: ColumnHeaders.assertionsPassed,
      headerName: listAssessmentTasksGridContent.assertionsPassed,
      width: 125,
      valueGetter: getAssessmentTaskAssertionsPassed,
    },
    {
      field: ColumnHeaders.id,
      headerName: 'Actions',
      type: 'actions',
      sortable: false,
      width: 75,
      valueGetter: getAssessmentTaskId,
      getActions: (params): JSX.Element[] => {
        if (
          params?.row?.status === 'IN_PROGRESS' ||
          params?.row?.status === 'QUEUED'
        ) {
          return [
            <HHTooltip
              title={assessmentTasksTabContent.cancelTask.toolTip}
              hhVariant="bottom"
            >
              <div>
                <HHIconButton hhVariant="medium">
                  <Cancel />
                </HHIconButton>
              </div>
            </HHTooltip>,
          ];
        }
        return [];
      },
    },
  ];

  const handleNavigate = (href: string): void => {
    navigate(href);
  };

  return (
    <DataGrid
      sx={{
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
      }}
      columnBuffer={10}
      disableColumnMenu
      autoHeight
      hideFooter
      columns={ListAssessmentTasksColumns}
      rows={assessmentTasks}
      isRowSelectable={(): boolean => true}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: ColumnHeaders.createdAt,
              sort: 'desc',
            },
          ],
        },
      }}
      onRowClick={(params): void => {
        const { id } = params.row;
        handleNavigate(
          `/${tabRoutes.baseRoute}/${tabRoutes.assessmentTasks}/${id}/${assessmentTasksRoutes.view}`,
        );
      }}
      onCellClick={(params, event): void => {
        if (params.field === ColumnHeaders.id) {
          event.stopPropagation();
          openCancellationModal(params.value);
        }
      }}
    />
  );
};
