import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { IDiscountPrices, totalDiscount } from './utils';

type PropsVbdSlice = {
  type: string;
  onChange: (data: IDiscountPrices) => void;
  defaultPrices?: IDiscountPrices;
  chronicCoreCharge: number;
};

const VbdSlice = ({
  type,
  onChange,
  defaultPrices,
  chronicCoreCharge,
}: PropsVbdSlice): JSX.Element => {
  const [m1Price, setM1Price] = useState(defaultPrices?.m1 || 0);
  const [m2Price, setM2Price] = useState(defaultPrices?.m2 || 0);
  const [m3Price, setM3Price] = useState(defaultPrices?.m3 || 0);

  useEffect(() => {
    onChange({
      m1: m1Price,
      m2: m2Price,
      m3: m3Price,
    });
  }, [m1Price, m2Price, m3Price, onChange]);

  const isError = m1Price + m2Price + m3Price > chronicCoreCharge;

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="h6">{type}</Typography>
      <TextField
        label="M1 Price"
        inputProps={{
          inputMode: 'decimal',
          pattern: '[0-9]*',
          min: '0',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              disableTypography
              sx={{ color: 'gray' }}
            >
              $
            </InputAdornment>
          ),
        }}
        value={m1Price}
        onChange={(e): void => {
          setM1Price(parseFloat(e.target.value));
        }}
        type="number"
        InputLabelProps={{ sx: { color: 'gray' } }}
      />
      <TextField
        label="M2 Price"
        inputProps={{
          inputMode: 'decimal',
          pattern: '[0-9]*',
          min: '0',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              disableTypography
              sx={{ color: 'gray' }}
            >
              $
            </InputAdornment>
          ),
        }}
        value={m2Price}
        onChange={(e): void => {
          setM2Price(parseFloat(e.target.value));
        }}
        type="number"
        InputLabelProps={{ sx: { color: 'gray' } }}
      />
      <TextField
        label="M3 Price"
        inputProps={{
          inputMode: 'decimal',
          pattern: '[0-9]*',
          min: '0',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              disableTypography
              sx={{ color: 'gray' }}
            >
              $
            </InputAdornment>
          ),
        }}
        value={m3Price}
        onChange={(e): void => {
          setM3Price(parseFloat(e.target.value));
        }}
        type="number"
        InputLabelProps={{ sx: { color: 'gray' } }}
      />
      <TextField
        label="Calculated Discount"
        value={totalDiscount(chronicCoreCharge, {
          m1: m1Price,
          m2: m2Price,
          m3: m3Price,
        })}
        type="number"
        disabled
        error={isError}
      />
    </Stack>
  );
};

export default VbdSlice;
