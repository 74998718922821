/* eslint-disable react-hooks/exhaustive-deps */
import {
  HHSelect,
  HHSelectChangeEvent,
  HHSelectOption,
  HHStack,
  HHTypography,
  TextArea,
} from '@hinge-health/react-component-library';
import { has } from 'lodash';
import { useEffect, useState } from 'react';
import { ModuleType, useGetParserModulesQuery } from '../types';
import LoadingInput from './loading-input';

interface ParserSectionProps {
  onChange: (data: { [key: string]: unknown }) => void;
  formData?: { [key: string]: unknown };
}

const ParserSection = (props: ParserSectionProps): JSX.Element => {
  const DEFAULT_SELECTED_PARSER = { value: '', displayName: 'Select a parser' };
  const { data, loading, error } = useGetParserModulesQuery();
  const { name, ...loadedConfig } =
    (props.formData?.parser as { [key: string]: unknown }) || {};

  const [parserOptions, setParserOptions] = useState<HHSelectOption[]>([
    DEFAULT_SELECTED_PARSER,
  ]);
  const [selectedParser, setSelectedParser] = useState<string>(
    (name as string) || '',
  );
  const [parserConfig, setParserConfig] = useState<string>(
    JSON.stringify(loadedConfig || {}, null, '\t'),
  );
  const [parserConfigError, setParserConfigError] = useState(false);

  const buildParserConfigSample = (
    selectedParserConfig: ModuleType,
  ): { [key: string]: unknown } => {
    const schemaParameters = selectedParserConfig.schema.parameters;

    const data: { [key: string]: unknown } = {}; // Add type annotation for 'data' object

    schemaParameters.forEach((param: { [key: string]: unknown }) => {
      data[param.name as string] = param.default;
    });

    return data;
  };

  const findParserAndSetConfig = (parserName: string): void => {
    const selectedParserConfig = data?.getParserModules.items.find(
      parser => parser.identifier === parserName,
    );
    if (selectedParserConfig) {
      setParserConfig(
        JSON.stringify(
          buildParserConfigSample(selectedParserConfig as ModuleType),
          null,
          '\t',
        ),
      );
    }
  };

  const handleParserChange = (e: HHSelectChangeEvent): void => {
    if (!e.target.value) {
      setParserConfig('{}');
    }

    const selectedParser = e.target.value as string;
    setSelectedParser(selectedParser);
    findParserAndSetConfig(selectedParser);
  };

  useEffect(() => {
    if (data) {
      const options = data.getParserModules.items.map(parser => ({
        displayName: parser.name,
        value: parser.identifier,
      }));
      setParserOptions([...parserOptions, ...options]);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (parserConfig === '{}') {
        findParserAndSetConfig(selectedParser);
      }
    }
  }, [selectedParser, data]);

  const validateFields = (config: { [key: string]: unknown }): void => {
    let requiredFieldsErrorState = false;

    if (config && selectedParser && data) {
      const required = data.getParserModules.items
        .find(parser => parser.identifier === selectedParser)
        ?.schema.parameters.filter(
          (param: { [key: string]: unknown }) => param.required,
        );

      for (const requiredField of required) {
        if (!has(config, requiredField.name)) {
          requiredFieldsErrorState = true;
          break;
        }
      }
    }
    setParserConfigError(requiredFieldsErrorState);
  };

  useEffect(() => {
    try {
      const config: { [key: string]: unknown } = JSON.parse(parserConfig);
      setParserConfigError(false);
      validateFields(config);
    } catch (error) {
      setParserConfigError(true);
    }
  }, [parserConfig]);

  useEffect(() => {
    try {
      const config = JSON.parse(parserConfig) || {};
      const formdata = {
        parser: {
          name: selectedParser || '',
          ...config,
        },
      };
      props.onChange(formdata);
    } catch (error) {}
  }, [selectedParser, parserConfig]);

  return (
    <HHStack direction="column" spacing={'20px'}>
      <HHTypography hhVariant={'h2'}>Parser Information</HHTypography>
      <LoadingInput
        loading={loading}
        error={error}
        loadingMessage={'Loading Parser Modules'}
        errorLabel={'Parser Module Loading Error'}
        errorContent={
          'Error loading parser modules. Please refresh and try again.'
        }
      >
        <HHStack direction="column" spacing={'20px'}>
          <HHSelect
            label="File Parser"
            initialSelectValue={selectedParser}
            selectOptions={parserOptions}
            onChange={handleParserChange}
            inputProps={{ 'data-testid': 'parser-select' }}
          />
          <TextArea
            label={'Parser Configuration'}
            style={{ labelColor: 'black', helpTextColor: 'red' }}
            value={parserConfig}
            onChange={(e): void =>
              setParserConfig((e.target as HTMLTextAreaElement).value)
            }
            error={parserConfigError}
          />
        </HHStack>
      </LoadingInput>
    </HHStack>
  );
};
export default ParserSection;
