import { HHTypography } from '@hinge-health/react-component-library';
import { FunctionComponent } from 'react';
import { usePartnershipById } from '../../../hooks/partnership-hook';
import { IDiscount } from '../../../utils/discount-utils';

export interface VolumeBasedDiscountsUsageProps {
  discountData: IDiscount;
  currency: string | null;
}

const VolumeBasedDiscountsUsage: FunctionComponent<
  VolumeBasedDiscountsUsageProps
> = ({ discountData }): JSX.Element => {
  const partnership = usePartnershipById(discountData.partnershipId);

  let nameToUse = discountData.clientId
    ? 'Client level'
    : `From ${partnership?.name || 'Unknown'} Partnership`;

  if (discountData.clientId && discountData.partnershipId) {
    // special case
    nameToUse = `Mixed VBD (${partnership?.name || 'Unknown'} Partnership)`;
  }

  return <HHTypography hhVariant="inherit">{nameToUse}</HHTypography>;
};

export default VolumeBasedDiscountsUsage;
