import {
  HHButton,
  HHIconButton,
  HHStack,
  HHTooltip,
  HHTypography,
} from '@hinge-health/react-component-library';
import { FilterList } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useClientInsurersByIdsLazy } from '../../hooks/client-insurer-hook';
import {
  BillingTypes,
  ScopeType,
  useGetAllClientsLazyQuery,
  useGetClientInsurersByIdsLazyQuery,
  useGetUnbilledLazyQuery,
} from '../../types';
import { BILL_DATE_FORMAT } from '../bills/bill-holds-grid/bill-holds-types';
import { UnbilledFilters } from './unbilled-filters';
import { UnbilledGrid } from './unbilled-grid';
import { generateUnbilledData, UnbilledTypeDataList } from './utils';

export interface UnbilledFilterModelType {
  endDate?: string;
  startDate: string;
  clientIds: number[];
  billingType: string;
}

export const default90DayLookbackStart = dayjs().subtract(90, 'days').toDate();
export const today = dayjs().toDate();

export const UnbilledWidget = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [gridRows, setGridRows] = useState<UnbilledTypeDataList>([]);
  const [filterParams, setFilterParams] = useState<UnbilledFilterModelType>({
    endDate: dayjs(today).format(BILL_DATE_FORMAT),
    startDate: dayjs(default90DayLookbackStart).format(BILL_DATE_FORMAT),
    clientIds: [],
    billingType: '',
  });
  const [showGrid, setShowGrid] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const [
    getAllClients,
    { data: clientsData, error: clientsError, loading: clientsLoading },
  ] = useGetAllClientsLazyQuery();
  const [
    getClientInsurersByIds,
    { loading: filterLoading, error: filterError },
  ] = useGetClientInsurersByIdsLazyQuery();
  const { data: ciData, searchClientInsurerIds } = useClientInsurersByIdsLazy();
  const [
    getUnbilled,
    { data: unbilledData, loading: unbilledLoading, error: unbilledError },
  ] = useGetUnbilledLazyQuery();

  const hasError = !!unbilledError || !!clientsError || !!filterError;

  //  initial unbilled || start date and clear fetch
  useEffect(() => {
    if (
      showGrid &&
      (filterParams.clientIds.length === 0 || filterParams.billingType === '')
    ) {
      getAllClients();
      getUnbilled({
        variables: {
          page,
          size: 8,
          startDate: filterParams.startDate,
          endDate: filterParams.endDate,
        },
      });
    }
  }, [getUnbilled, showGrid, getAllClients, page, filterParams]);

  useEffect(() => {
    if (filterParams.clientIds.length >= 1 || filterParams.billingType !== '') {
      getClientInsurersByIds({
        variables: {
          clientIds: filterParams.clientIds,
          billingTypes:
            filterParams.billingType === ''
              ? []
              : [
                  BillingTypes[
                    filterParams.billingType as keyof typeof BillingTypes
                  ],
                ],
          scope: ScopeType.Inclusive,
        },
      }).then(data => {
        if (
          data.data &&
          data.loading === false &&
          data.error === undefined &&
          data.data.getClientInsurersByIds.length >= 1
        ) {
          getUnbilled({
            variables: {
              page,
              size: 8,
              startDate: filterParams.startDate,
              endDate: filterParams.endDate,
              clientsInsurerIds: data.data.getClientInsurersByIds.map(
                ci => ci.id,
              ),
            },
          });
        } else if (
          data.data?.getClientInsurersByIds.length === 0 &&
          !data.error
        ) {
          setGridRows([]);
        }
      });
    }
  }, [
    getClientInsurersByIds,
    filterParams.clientIds,
    filterParams.billingType,
    filterParams.startDate,
    filterParams.endDate,
    getUnbilled,
    page,
  ]);

  useEffect(() => {
    if (unbilledData?.getUnbilledBills) {
      const rows = generateUnbilledData(
        unbilledData.getUnbilledBills.items,
        ciData,
      );
      searchClientInsurerIds(new Set(rows.map(row => row.clientsInsurerId)));
      setGridRows(rows);
    }
  }, [
    unbilledData,
    getUnbilled,
    setGridRows,
    ciData,
    searchClientInsurerIds,
    filterLoading,
  ]);

  return (
    <HHStack
      border={'1px solid lightgray'}
      borderRadius={1}
      spacing={2}
      padding={4}
    >
      {showGrid ? (
        <HHStack spacing={2}>
          <HHStack direction="row" spacing={2} alignItems="center">
            <HHTypography hhVariant="body">Unsubmitted Bills</HHTypography>
            <HHIconButton
              hhVariant="variant-bypass"
              onClick={(): void => setFiltersOpen(!filtersOpen)}
              aria-label="unbilled filters"
            >
              <FilterList />
            </HHIconButton>
            <HHIconButton
              sx={{ alignSelf: 'flex-end' }}
              hhVariant="variant-bypass"
              onClick={(): void => setShowGrid(!showGrid)}
            >
              <HHTooltip title="Close report" hhVariant="bottom">
                <CloseIcon />
              </HHTooltip>
            </HHIconButton>
          </HHStack>
          {filtersOpen ? (
            <UnbilledFilters
              clientsError={clientsError}
              clientsData={clientsData?.getAllClients ?? []}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              default90DayLookbackStart={default90DayLookbackStart}
              today={today}
              setPage={setPage}
            />
          ) : null}
          <UnbilledGrid
            key={gridRows.length}
            paginationData={unbilledData?.getUnbilledBills}
            page={unbilledData?.getUnbilledBills.page ?? page}
            setPage={setPage}
            rows={gridRows}
            loading={unbilledLoading || filterLoading || clientsLoading}
            hasError={hasError}
          />
        </HHStack>
      ) : (
        <HHButton
          hhVariant="outlined"
          onClick={(): void => setShowGrid(true)}
          size="small"
        >
          Get unbilled report
        </HHButton>
      )}
    </HHStack>
  );
};
