import { HHStack, HHTypography } from '@hinge-health/react-component-library';
import { GridCellParams } from '@mui/x-data-grid';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../../utils/bills-utils';
import { ReleasableHoldReasons } from './bill-holds-types';

export const HoldTypeCell = (params: GridCellParams): JSX.Element => {
  const { holdType, note } = params.row.holdDetails as Record<string, string>;
  return (
    <HHStack>
      {!holdType
        ? 'No hold present'
        : capitalizeFirstLetterAndSpaceSnakeString(holdType)}
      {holdType === ReleasableHoldReasons.MANUAL_HOLD ? (
        <HHTypography color="text.primary" hhVariant="input-label">
          {note}
        </HHTypography>
      ) : null}
    </HHStack>
  );
};
