import { HHStack, HHTextField } from '@hinge-health/react-component-library';
import { InputAdornment } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  CONTRACT_FORM_IN_PERSON_VISIT_CHARGE_ID,
  CONTRACT_FORM_IN_PERSON_VISIT_EFFECTIVE_DATE_PICKER_ID,
} from '../../../../constants/strings/contract/form-constants';
import { getCurrencySymbol } from '../../../../utils/currency-helpers';
import {
  CleanAvailablePackages,
  CleanBillableActivities,
  CleanBillingModel,
} from '../custom-types';

export interface FeeForServiceProps {
  index: number;
  availablePackages: CleanAvailablePackages[];
  billableActivities: CleanBillableActivities[];
  billingModels: CleanBillingModel[];
  feeForServiceFormDisabled: boolean | undefined;
}

interface FFSFormValues {
  contractPackages: {
    price: number;
    startDate: Date | null;
    packageId: number | undefined;
    billingModelId: number | undefined;
    billableActivities: (number | undefined)[];
  }[];
}

export const FeeForServiceConfig = ({
  index,
  availablePackages,
  billableActivities,
  billingModels,
  feeForServiceFormDisabled,
}: FeeForServiceProps): JSX.Element => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<FFSFormValues>();
  const watchedStartDate = useWatch({
    name: `contractPackages.${index}.startDate`,
  });
  const watchedCurrency = useWatch({
    name: 'currency',
  });
  useEffect(() => {
    setValue(
      `contractPackages.${index}.packageId`,
      availablePackages.find(p => p.name === 'in_person_visit')?.id,
    );

    setValue(
      `contractPackages.${index}.billingModelId`,
      billingModels.find(b => b.name === 'fee_for_service')?.id,
    );

    setValue(`contractPackages.${index}.billableActivities`, [
      billableActivities.find(b => b.name === 'in_person_visit')?.id,
    ]);
  }, [availablePackages, billableActivities, billingModels, index, setValue]);

  return (
    <HHStack flexDirection="row">
      <Controller
        control={control}
        name={`contractPackages.${index}.price`}
        render={({ field: { onChange, value } }): JSX.Element => (
          <HHTextField
            data-testid={CONTRACT_FORM_IN_PERSON_VISIT_CHARGE_ID}
            hhVariant="variant-bypass"
            label="Per visit charge"
            sx={{ width: '25%' }}
            inputProps={{
              inputMode: 'decimal',
              pattern: '[0-9]*',
              min: '0',
            }}
            disabled={feeForServiceFormDisabled}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  disableTypography
                  sx={{ color: 'gray' }}
                >
                  {getCurrencySymbol(watchedCurrency?.value)}
                </InputAdornment>
              ),
            }}
            value={value}
            onChange={(e): void => onChange(parseInt(e.target.value))}
            type="number"
            InputLabelProps={{ sx: { color: 'gray' } }}
            error={
              Object.keys(errors?.contractPackages?.[index]?.price ?? {})
                .length !== 0
            }
            helperText={errors?.contractPackages?.[index]?.price?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={`contractPackages.${index}.startDate`}
        render={({ field: { onChange, value, ref } }): JSX.Element => (
          <DatePicker
            label="Effective date"
            ref={ref}
            value={value}
            shouldDisableDate={(selectedDate): boolean =>
              dayjs(selectedDate).isBefore(watchedStartDate)
            }
            key={`contractPackages.${index}.startDate`}
            defaultCalendarMonth={
              watchedStartDate ? dayjs(watchedStartDate) : dayjs()
            }
            disabled={feeForServiceFormDisabled}
            onChange={(newValue): void => {
              onChange(newValue ? dayjs(newValue).toDate() : null); //parsing to date for validation;
            }}
            renderInput={(params): JSX.Element => (
              <HHTextField
                data-testid={
                  CONTRACT_FORM_IN_PERSON_VISIT_EFFECTIVE_DATE_PICKER_ID
                }
                {...params}
                error={
                  params.error ||
                  Object.keys(
                    errors?.contractPackages?.[index]?.startDate ?? {},
                  ).length !== 0
                }
                name={`contractPackages.${index}.startDate`}
                hhVariant="variant-bypass"
                helperText={
                  errors?.contractPackages?.[index]?.startDate?.message
                }
                InputLabelProps={{ sx: { color: 'gray' } }}
              />
            )}
          />
        )}
      />
    </HHStack>
  );
};
