import { HHStack, HHTextField } from '@hinge-health/react-component-library';
import { Search } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { listTagsToolbarContent } from '../constants/tags.constant';
import { useListTagsContext } from '../contexts/list-tags.context';
import { ListTagsToolbarFiltersMenu } from './list-tags-toolbar-filters-menu';

export const ListTagsToolbarContainer = ({
  tagNameSearch,
  setTagNameSearch,
  showDeletedTags,
  setShowDeletedTags,
}: {
  tagNameSearch?: string;
  setTagNameSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
  showDeletedTags: boolean;
  setShowDeletedTags: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const [localTagNameSearch, setLocalTagNameSearch] = useState<
    string | undefined
  >(tagNameSearch);

  useEffect(() => {
    setLocalTagNameSearch(tagNameSearch);
  }, [tagNameSearch]);

  return (
    <HHStack
      data-testid="list-tags-toolbar"
      direction="row"
      justifyContent="space-between"
      marginBottom={8}
      marginTop={4}
    >
      <ListTagsToolbarFiltersMenu
        showDeletedTags={showDeletedTags}
        setShowDeletedTags={setShowDeletedTags}
      />
      <HHTextField
        hhVariant="standard"
        label=" "
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search data-testid="tags-search-input-icon" />
            </InputAdornment>
          ),
          sx: { paddingBottom: '4px' },
        }}
        placeholder={listTagsToolbarContent.searchPlaceholder}
        value={localTagNameSearch}
        onChange={(e): void => setTagNameSearch(e.target.value)}
      />
    </HHStack>
  );
};

export const ListTagsToolbar = (): JSX.Element => {
  const {
    tagNameSearch,
    setTagNameSearch,
    showDeletedTags,
    setShowDeletedTags,
  } = useListTagsContext();

  return (
    <ListTagsToolbarContainer
      tagNameSearch={tagNameSearch}
      setTagNameSearch={setTagNameSearch}
      showDeletedTags={showDeletedTags}
      setShowDeletedTags={setShowDeletedTags}
    />
  );
};
