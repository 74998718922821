import {
  AuthRedirectCallback as OktaAuthRedirectCallback,
  HHCircularProgress,
} from '@hinge-health/react-component-library';

const errorComponent: React.FunctionComponent<{ error: Error }> = _props => (
  <p>Error while logging in</p>
);

const loadingComponent: JSX.Element = (
  <HHCircularProgress data-testid="LoopIcon" styleAs="primary" />
);

const AuthRedirectCallback = (): JSX.Element => (
  <OktaAuthRedirectCallback
    loadingComponent={loadingComponent}
    errorComponent={errorComponent}
  />
);

export default AuthRedirectCallback;
