import {
  HHBreadcrumbs,
  HHButton,
  HHLink,
  HHStack,
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Search } from '@mui/icons-material';
import { Autocomplete, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { FormEvent, MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HoldsWidget } from '../components/bills/bill-holds-grid/bill-holds-widget';
import { UnbilledWidget } from '../components/unbilled/unbilled-widget';
import UserSearch from '../components/user/user-search';
import { routes } from '../constants/strings/routes';
import { ClientType, useGetAllClientsQuery } from '../types';
import { selectSorterFunction } from '../utils/bills-utils';

const BillingToolHomePage = (): JSX.Element => {
  const navigation = useNavigate();
  const [clientInsurerId, setClientInsurerId] = useState('');
  const [clientId, setClientId] = useState('');
  const isDisabled = !clientInsurerId || parseInt(clientInsurerId, 10) === 0;
  const isClientDisabled = !clientId || parseInt(clientId, 10) === 0;
  const [sortedClients, setSortedClients] = useState<ClientType[]>([]);

  const {
    data: clientsData,
    error: clientsError,
    loading: clientsLoading,
  } = useGetAllClientsQuery();

  /* istanbul ignore next */
  const goToInsurerPage = (
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault();
    if (!isDisabled) {
      navigation(`${routes.billing.main}/${clientInsurerId}`);
    }
  };

  /* istanbul ignore next */
  const goToClientPage = (
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault();
    if (!isClientDisabled) {
      navigation(
        `${routes.billing.home}/${routes.billing.clientPath}/${clientId}`,
      );
    }
  };

  const goToBillsWithHolds = (
    e: MouseEvent<HTMLButtonElement>,
    holdReason?: string,
  ): void => {
    e.preventDefault();
    const url = holdReason
      ? `${routes.billing.home}${routes.billing.billsHolds}?holdReason=${holdReason}`
      : `${routes.billing.home}${routes.billing.billsHolds}`;
    navigation(url);
  };

  useEffect(() => {
    if (clientsData && !clientsLoading) {
      setSortedClients(
        [...clientsData?.getAllClients].sort((a, b) =>
          selectSorterFunction(a.identifier, b.identifier),
        ),
      );
    }
  }, [clientsData, clientsLoading]);

  return (
    <HHStack direction="column" sx={{ margin: 4 }} spacing={4} padding={4}>
      <HHBreadcrumbs aria-label="breadcrumb">
        <HHLink color="inherit" href={routes.billing.home} underline="hover">
          Operations Hub
        </HHLink>
        <HHLink color="inherit" href={routes.billing.home} underline="hover">
          Billing
        </HHLink>
      </HHBreadcrumbs>
      <HHStack direction="row" justifyContent="space-between">
        <HHTypography hhVariant="section-title">Billing</HHTypography>
        <HHStack direction="row" spacing={2}>
          <HHLink
            color="inherit"
            underline="hover"
            href={`${routes.billing.home}/${routes.billing.discountCalculator}`}
            target="_blank"
          >
            <HHButton hhVariant="outlined">Discount calculator</HHButton>
          </HHLink>
          <HHLink
            color="inherit"
            underline="hover"
            href={`${routes.billing.home}/tool`}
          >
            <HHButton hhVariant="contained">Billing tool</HHButton>
          </HHLink>
        </HHStack>
      </HHStack>
      <HHStack spacing={2}>
        <HHStack
          spacing={2}
          direction={{ sm: 'column', md: 'row' }}
          flexWrap="wrap"
        >
          <HHStack
            spacing={2}
            flexGrow={2}
            sx={{
              '> *': {
                flexGrow: 1,
              },
            }}
          >
            <HHStack
              border="1px solid lightgray"
              borderRadius={1}
              spacing={2}
              padding={4}
              sx={(theme): SystemStyleObject<Theme> => ({
                backgroundColor: theme.palette.background.default,
              })}
            >
              <HHTypography hhVariant="body">Clients</HHTypography>
              <form onSubmit={goToClientPage}>
                <HHStack direction="row" spacing={2}>
                  {clientsError && !clientsLoading ? (
                    <HHTextField
                      hhVariant="variant-bypass"
                      placeholder="Search by Client ID"
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        min: '0',
                      }}
                      value={clientId}
                      onChange={(e): void => setClientId(e.target.value)}
                      type="number"
                      InputLabelProps={{ sx: { color: 'gray' } }}
                      sx={{ minWidth: 150 }}
                      InputProps={{
                        startAdornment: <Search sx={{ color: 'gray' }} />,
                      }}
                      fullWidth
                    />
                  ) : (
                    <Autocomplete
                      id="tags-standard"
                      options={!clientsError ? sortedClients : []}
                      getOptionLabel={(option: ClientType): string =>
                        option.name
                      }
                      isOptionEqualToValue={(
                        option: ClientType,
                        value: ClientType,
                      ): boolean =>
                        option.id === value.id || option.name === value.name
                      }
                      filterOptions={(options, params): ClientType[] =>
                        options.filter(
                          option =>
                            option.name
                              .toLowerCase()
                              .indexOf(params.inputValue.toLowerCase()) !==
                              -1 ||
                            option.id.toString().indexOf(params.inputValue) !==
                              -1,
                        )
                      }
                      size="small"
                      onChange={(_, newValue: ClientType | null): void =>
                        setClientId(newValue?.id.toString() ?? '')
                      }
                      fullWidth
                      sx={{ minWidth: 230 }}
                      renderInput={(params): JSX.Element => (
                        <HHTextField
                          {...params}
                          hhVariant="variant-bypass"
                          variant="outlined"
                          label={'Search by Client name'}
                          error={!!clientsError}
                          helperText={clientsError ? 'clients unavailable' : ''}
                        />
                      )}
                    />
                  )}

                  <HHButton
                    hhVariant="contained"
                    aria-label="submit"
                    type="submit"
                    disabled={isClientDisabled}
                    onClick={goToClientPage}
                  >
                    Search
                  </HHButton>
                </HHStack>
              </form>
            </HHStack>
            <HHStack
              border="1px solid lightgray"
              borderRadius={1}
              spacing={2}
              padding={4}
              sx={(theme): SystemStyleObject<Theme> => ({
                backgroundColor: theme.palette.background.default,
              })}
            >
              <HHTypography hhVariant="body">Client Insurers</HHTypography>
              <form onSubmit={goToInsurerPage}>
                <HHStack direction="row" spacing={2}>
                  <HHTextField
                    hhVariant="variant-bypass"
                    placeholder="Search by Client Insurer ID"
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      min: '0',
                    }}
                    value={clientInsurerId}
                    onChange={(e): void => setClientInsurerId(e.target.value)}
                    type="number"
                    InputLabelProps={{ sx: { color: 'gray' } }}
                    sx={{ minWidth: 150 }}
                    InputProps={{
                      startAdornment: <Search sx={{ color: 'gray' }} />,
                    }}
                    fullWidth
                  />
                  <HHButton
                    hhVariant="contained"
                    aria-label="submit"
                    type="submit"
                    disabled={isDisabled}
                    onClick={goToInsurerPage}
                  >
                    Search
                  </HHButton>
                </HHStack>
              </form>
            </HHStack>
            <UserSearch />
          </HHStack>
          <HoldsWidget goToBillsWithHolds={goToBillsWithHolds} />
        </HHStack>
        <UnbilledWidget />
      </HHStack>
    </HHStack>
  );
};

export default BillingToolHomePage;
