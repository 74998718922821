import {
  HHBox,
  HHCircularProgress,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { SignInOptions } from '@hinge-health/react-component-library/dist/components/auth/hinge-health-security-context';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getBasePath } from '../config';
import NavMenu from './menu';

interface AuthenticatedPageProps {
  children: React.ReactNode;
}

const styles = {
  containerStyle: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  spinnerStyle: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 24,
    height: 24,
  },
  root: { display: 'flex', width: '100%', height: '100%' },
  pageContainer: { flex: 1 },
};

const AuthenticatedPage = ({
  children,
}: AuthenticatedPageProps): JSX.Element => {
  const { hingeHealthAuthState, hingeHealthAuthClient } =
    useHingeHealthSecurityContext();
  const location = useLocation();

  useEffect(() => {
    if (!hingeHealthAuthState) {
      return;
    }
    if (!hingeHealthAuthState?.isAuthenticated) {
      const signInOptions = {
        originalUri: `${location.pathname}?redirected=${getBasePath()}`,
      } as SignInOptions;

      hingeHealthAuthClient.signInWithRedirect(signInOptions);
    }
  }, [hingeHealthAuthClient, hingeHealthAuthState, location]);

  return (
    <HHBox sx={styles.containerStyle}>
      {!hingeHealthAuthState?.isAuthenticated ? (
        <HHCircularProgress
          data-testid="LoopIcon"
          sx={styles.spinnerStyle}
          styleAs="primary"
        />
      ) : (
        <HHBox sx={styles.root}>
          <NavMenu />
          <HHBox sx={styles.pageContainer}>{children}</HHBox>
        </HHBox>
      )}
    </HHBox>
  );
};

export default AuthenticatedPage;
