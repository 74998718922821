import {
  HHBaseTable,
  HHCard,
  HHIconButton,
  HHIconButtonMenu,
  HHTypography,
} from '@hinge-health/react-component-library';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { formatCurrency } from '../../../../utils/currency-helpers';
import { cleanTypeNameGraphql } from '../../../../utils/graphql-utils';
import { BillTypeDataList, GridColumnHeaderNames } from '../bill-holds-types';
import { BillPanelProps } from './bill-panel';
import { SelectedHoldTypeCell } from './selected-hold-types-cell';
import { SimpleClientCell } from './simple-client-cell';
import { SimpleInsurerCell } from './simple-insurer-cell';

export interface SelectedBillsGridProps {
  selectedRows: BillPanelProps['selectedRows'];
  setPanelSelectedRows: (selectedRows: BillTypeDataList) => void;
  clients: BillPanelProps['clients'];
  insurers: BillPanelProps['insurers'];
}

export const SelectedBillsGrid = ({
  selectedRows,
  setPanelSelectedRows,
  clients,
  insurers,
}: SelectedBillsGridProps): JSX.Element => {
  const handleDeleteRow = (rowId: number): void => {
    const rowsWithoutDeletedRow = selectedRows.filter(
      row => row.billId !== rowId,
    );
    setPanelSelectedRows(rowsWithoutDeletedRow);
  };

  return (
    <HHCard>
      {/* Adding copy icon to make it easier for RCM to qa release changes */}
      <HHIconButton
        hhVariant="medium"
        onClick={(): void => {
          navigator.clipboard.writeText(
            JSON.stringify(
              cleanTypeNameGraphql(
                selectedRows.map(row => ({
                  claimId: row.claimId,
                  clientId: row.client.id,
                  clientsInsurerId: row.clientsInsurerId,
                  subscription: row.subscriptionId,
                  insurer: row.insurer.id,
                  billAmount: row.billAmount,
                  holdType: row.holdDetails?.holdType,
                  dateOfService: row.dateOfService,
                  holdDetails: row.holdDetails,
                })),
              ),
              null,
              2,
            ),
          );
        }}
      >
        <CopyAllIcon />
        <HHTypography hhVariant="muted">Copy rows</HHTypography>
      </HHIconButton>
      <HHBaseTable
        headerRowBottomBorder="1px solid rgba(0, 0, 0, 0.12)"
        bodyRowBorder={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
        bodyTypographyVariant="body2"
        tableHeaders={[
          {
            value: GridColumnHeaderNames.HOLD_REASON,
            align: 'left',
            enableSort: false,
          },
          { value: GridColumnHeaderNames.AMOUNT, align: 'left' },
          { value: 'User', align: 'left' },
          { value: 'Date', align: 'left' },
          { value: GridColumnHeaderNames.CLIENT, align: 'left' },
          { value: GridColumnHeaderNames.INSURER, align: 'left' },
          { value: '', align: 'left' },
        ]}
        bodyContent={selectedRows.map(row => ({
          holdReason: {
            value: (
              <SelectedHoldTypeCell
                holdReason={row.holdDetails?.holdType}
                claimId={row.claimId}
              />
            ),
          },
          amount: {
            value: formatCurrency(parseInt(row.billAmount.toString())),
          },
          userId: {
            value: row.userId,
          },
          dateOfService: {
            value: row.dateOfService,
          },
          client: {
            value: <SimpleClientCell clients={clients} details={row} />,
          },
          insurer: {
            value: <SimpleInsurerCell insurers={insurers} details={row} />,
          },
          actions: {
            value:
              selectedRows.length <= 1 ? (
                ''
              ) : (
                <HHIconButtonMenu
                  aria-label="Grid action row menu"
                  role="menuitem"
                  menuItems={[
                    {
                      label: 'Remove from list',
                      onClick: () => handleDeleteRow(row.billId),
                    },
                  ]}
                />
              ),
          },
        }))}
      />
    </HHCard>
  );
};
