export enum NODE_ENV {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  SCALING = 'scaling',
  PRODUCTION = 'production',
}

export enum APP_ENV {
  DEV = 'dev',
  DEV_PREVIEW = 'dev-preview',
  DEVELOPMENT = 'development',
  STAGE = 'stage',
  STAGING = 'staging',
  SCALING = 'scaling',
  PRODUCTION = 'production',
}
