import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { BillingTool } from '../modules/billing-tool';
import { billingToolLoadError } from '../modules/billing-tool/constants/strings/errors';

export const BillingToolPage = (): JSX.Element => (
  <Sentry.ErrorBoundary fallback={<Box>{billingToolLoadError}</Box>}>
    <BillingTool />
  </Sentry.ErrorBoundary>
);
