/* eslint-disable react-hooks/exhaustive-deps */
import {
  HHButton,
  HHCircularProgress,
  HHIconButtonMenu,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../constants/strings/routes';
import { useGetFileConfigurationsQuery } from '../types';

interface FileConfigurationTableProps {
  navigate: ReturnType<typeof useNavigate>;
}

const FileConfigurationTable = (
  props: FileConfigurationTableProps,
): JSX.Element => {
  const PAGE_SIZE = 20;
  const { data, loading, error, refetch } = useGetFileConfigurationsQuery({
    variables: { size: PAGE_SIZE },
  });
  const [page, setPage] = useState(0);

  const handleEditClick = (e: React.SyntheticEvent, id: number): void => {
    e.preventDefault();
    props.navigate(
      `${routes.memberData.home}/${routes.memberData.fileConfigurationsPath}/${id}/edit`,
    );
  };
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1, sortable: false },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      sortable: false,
    },
    {
      field: 'created_at',
      headerName: 'Date Created',
      flex: 1,
      sortable: false,
    },
    {
      field: 'updated_at',
      headerName: 'Last Updated',
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: params => (
        <HHIconButtonMenu
          menuItems={[
            {
              label: 'Edit',
              onClick: (event: React.SyntheticEvent): void => {
                handleEditClick(event, params.row.id as number);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTryAgain = (e: FormEvent): void => {
    e.preventDefault();
    refetch({ page: page + 1 });
  };

  useEffect(() => {
    refetch({ page: page + 1 });
  }, [page]);

  return (
    <HHStack>
      {loading && !error && (
        <HHStack direction={'column'} spacing={'20px'} alignItems={'center'}>
          <HHTypography hhVariant={'h6'}>
            Loading file configurations
          </HHTypography>
          <HHCircularProgress />
        </HHStack>
      )}
      {error && !loading && (
        <HHStack direction={'column'} spacing={'20px'} alignItems={'center'}>
          <HHTypography hhVariant={'h6'}>
            We ran into an issue loading file configurations
          </HHTypography>
          <HHButton
            hhVariant={'contained'}
            fullWidth={false}
            size="large"
            onClick={handleTryAgain}
          >
            Try Again
          </HHButton>
        </HHStack>
      )}
      {data && !data.getFileConfigurations.items.length && (
        <HHStack direction={'column'} spacing={'20px'} alignItems={'center'}>
          <HHTypography hhVariant={'h6'}>
            No file configurations found
          </HHTypography>
          <HHTypography hhVariant={'default'}>
            Use the "New File Configuration" button to create a new file
            configuration
          </HHTypography>
        </HHStack>
      )}
      {data && data.getFileConfigurations.items.length !== 0 && (
        <HHStack>
          <div
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <DataGrid
              onPageChange={(newPage): void => setPage(newPage)}
              rows={data.getFileConfigurations.items}
              columns={columns}
              pagination
              pageSize={data.getFileConfigurations.size}
              rowCount={data.getFileConfigurations.total}
              paginationMode="server"
              autoHeight
              disableSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
            />
          </div>
        </HHStack>
      )}
    </HHStack>
  );
};
export default FileConfigurationTable;
