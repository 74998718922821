export type JsonValue =
  | string
  | number
  | boolean
  | null
  | { [x: string]: JsonValue }
  | Array<JsonValue>;

export const omitTypename = (value: JsonValue): JsonValue => {
  if (Array.isArray(value)) return value.map(omitTypename);
  if (typeof value === 'object' && value !== null) {
    const copy = { ...value };
    delete copy['__typename'];
    for (const [key, value] of Object.entries(copy)) {
      copy[key] = omitTypename(value);
    }
    return copy;
  }
  return value;
};
