import { ApolloError, GraphQLErrors } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';

const combineErrorMessages = (errors: GraphQLErrors): string =>
  errors
    .reduce(
      (acc: string, current: GraphQLError) => acc + ' ' + current.message,
      '',
    )
    .trim();

export const getApolloErrorMessages = (error: ApolloError): string => {
  let errorMessages = '';
  if (error.graphQLErrors.length > 0) {
    errorMessages = combineErrorMessages(error.graphQLErrors);
  } else if (error.networkError) {
    if ('result' in error.networkError) {
      if (error.networkError.result.errors) {
        errorMessages = combineErrorMessages(error.networkError.result.errors);
      }
    }
  }
  return errorMessages;
};
