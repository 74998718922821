import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { targetedInterventionsEnrollment } from '../constants/strings/targeted-interventions';
import { TargetedInterventionsEnrollment as UserEnrollment } from '../modules/targeted-interventions';

export const TargetedInterventionsEnrollment = (): JSX.Element => (
  <Sentry.ErrorBoundary
    fallback={<Box>{targetedInterventionsEnrollment.error}</Box>}
  >
    <UserEnrollment />
  </Sentry.ErrorBoundary>
);
