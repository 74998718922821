export const ruleAssessmentsTabContent = {
  listViewTitle: 'Rule Assessments',
  startSearchTitle: 'Enter Resource ID',
  startSearchBody:
    'Enter a resource ID to start searching for rule assessments',
  noResultsTitle: 'No Results',
  oops: 'Oops',
  noResultsBody:
    'Could not find any rule assessments matching your search criteria',
  errorMessage: 'We ran into an issue loading rule assessments',
  trySearchingAgain: 'Try searching again later',
  notFoundTitle: 'Not found',
  notFoundBody: 'We could not find that rule assessment',
  loadErrorTitle: 'Error',
  loadErrorBody:
    'We ran into an issue loading that rule assessment. If you continue to see this error, please confirm you are using a valid rule assessment ID in the URL.',
  detailsTitle: 'Rule Assessment Details',
};

export const ruleAssessmentsTextFields = {
  id: 'ID',
  resourceId: 'Resource ID',
  source: 'Source',
  resourceType: 'Resource Type',
  userId: 'User ID',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
  completed: 'Completed',
  errored: 'Errored',
  actionsExecuted: 'Actions executed',
  assertionsPassed: 'Assertions passed',
  assessedRules: 'Assessed Rules',
  status: 'Status',
  assertionLog: 'Assertion log',
  actionLog: 'Action log',
};

export const ruleAssessmentsGridFieldNames = {
  resourceId: 'resourceId',
  source: 'source',
  resourceType: 'resourceType',
  userId: 'userId',
  createdAt: 'createdAt',
};

export const ruleAssessmentsButtonLabels = {
  search: 'Search',
};

export const ruleAssessmentsInputLabels = {
  resourceId: 'Search by Resource ID',
};
