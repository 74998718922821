export const printDateAndTime = (input: string): string => {
  const date = new Date(input);
  const day = date.toLocaleString([], {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  const time = date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${day} ${time}`;
};
