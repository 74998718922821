export const workflowsText = {
  phaseGrid: {
    errors: {
      fetchingWorkflows: 'Error fetching workflows',
    },
    headers: {
      coach: 'Coach',
      fullName: 'Member Name',
      patientUuid: 'ID #',
      taskDesc: 'Task',
      taskState: 'Task State',
      notes: 'Notes',
    },
    links: {
      view: 'View',
    },
  },
  phaseLoadMoreLabel: 'Load more',
};
