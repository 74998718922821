import { Maybe } from 'graphql/jsutils/Maybe';

export const formatCurrency = (
  value: number,
  currency?: string | Maybe<string>,
): string =>
  currency
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency ?? 'USD',
        trailingZeroDisplay: 'stripIfInteger',
      }).format(value)
    : new Intl.NumberFormat().format(value);

export const getCurrencySymbol = (currency?: string | Maybe<string>): string =>
  (0)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: currency ?? 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '');
