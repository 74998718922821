import { ApolloError } from '@apollo/client';
import {
  HHButton,
  HHStack,
  HHTextField,
} from '@hinge-health/react-component-library';
import { Autocomplete } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { BaseSyntheticEvent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BillingTypes, ClientType } from '../../types';
import {
  capitalizeFirstLetterAndSpaceSnakeString,
  selectSorterFunction,
} from '../../utils/bills-utils';
import {
  AutocompleteSelectOption,
  BILL_DATE_FORMAT,
} from '../bills/bill-holds-grid/bill-holds-types';
import { UnbilledFilterModelType } from './unbilled-widget';

export const initialBillingTypeSelect = { label: '', value: '' };

const billTypesSelectOptions = Object.entries(BillingTypes)
  .map(([key, value]) => ({
    value: key,
    label: capitalizeFirstLetterAndSpaceSnakeString(value.toLowerCase()),
  }))
  .sort((a, b) => selectSorterFunction(a.label, b.label));

export enum UnbilledFilterLabelText {
  CLIENTS = 'Clients',
  BILLING_TYPE = 'Billing Type',
  START_DATE = 'Lookback Start Date',
  END_DATE = 'Lookback End Date',
}

export interface UnbilledFiltersProps {
  clientsError: ApolloError | undefined;
  clientsData: ClientType[];
  filterParams: UnbilledFilterModelType;
  setFilterParams: React.Dispatch<
    React.SetStateAction<UnbilledFilterModelType>
  >;
  default90DayLookbackStart: Date;
  today: Date;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

interface RawUnbilleFilterValues {
  startDate: Date;
  endDate: Date;
  clients: ClientType[];
  billingType: AutocompleteSelectOption;
}

export const UnbilledFilters = ({
  clientsError,
  clientsData,
  filterParams,
  setFilterParams,
  default90DayLookbackStart,
  today,
  setPage,
}: UnbilledFiltersProps): JSX.Element => {
  const defaultValues: RawUnbilleFilterValues = {
    endDate: dayjs(filterParams.endDate).toDate(),
    startDate: dayjs(filterParams.startDate).toDate(),
    clients: clientsData.filter(client =>
      filterParams.clientIds.includes(client.id),
    ),
    billingType:
      billTypesSelectOptions.find(
        select => filterParams.billingType === select.value,
      ) ?? initialBillingTypeSelect,
  };

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    criteriaMode: 'all',
  });

  const sortedClients = [...clientsData].sort((a, b) =>
    selectSorterFunction(a.identifier, b.identifier),
  );

  const parseFormValues = (data: RawUnbilleFilterValues): void => {
    const { startDate, endDate, clients, billingType } = data;

    const formatted = {
      startDate: dayjs(startDate).format(BILL_DATE_FORMAT),
      endDate: dayjs(endDate).format(BILL_DATE_FORMAT),
      clientIds: clients.map(client => client.id),
      billingType: billingType.value,
    };

    setPage(1);
    setFilterParams(formatted);
  };

  const onFormSubmit = (e: BaseSyntheticEvent<object> | undefined): void => {
    handleSubmit(parseFormValues)(e);
  };

  const handleClear = (): void => {
    reset({
      startDate: default90DayLookbackStart,
      endDate: today,
      clients: [],
      billingType: initialBillingTypeSelect,
    });
    setPage(1);
    setFilterParams({
      startDate: dayjs(default90DayLookbackStart).format(BILL_DATE_FORMAT),
      endDate: dayjs(today).format(BILL_DATE_FORMAT),
      clientIds: [],
      billingType: initialBillingTypeSelect.value,
    });
  };

  return (
    <form>
      <HHStack spacing={2} direction="row" alignItems="center" flexWrap="wrap">
        <Controller //clients filter
          control={control}
          name="clients"
          render={({ field: { onChange, value } }): JSX.Element => (
            <Autocomplete
              multiple
              id="tags-standard"
              options={!clientsError ? sortedClients : []}
              getOptionLabel={(option): string => option.name}
              isOptionEqualToValue={(option, value): boolean =>
                option.id === value.id
              }
              size="small"
              sx={{ minWidth: 230, maxWidth: 230 }}
              limitTags={1}
              value={value}
              onChange={(_, newValue): void => onChange(newValue)}
              renderInput={(params): JSX.Element => (
                <HHTextField
                  {...params}
                  hhVariant="variant-bypass"
                  variant="outlined"
                  label={UnbilledFilterLabelText.CLIENTS}
                  error={!!clientsError}
                  helperText={clientsError ? 'clients unavailable' : ''}
                />
              )}
            />
          )}
        />
        <Controller //billing type
          control={control}
          name="billingType"
          render={({ field: { onChange, value } }): JSX.Element => (
            <Autocomplete
              id="tags-standard"
              options={billTypesSelectOptions}
              size="small"
              sx={{ minWidth: 230, maxWidth: 230 }}
              value={value}
              onChange={(_, newValue): void =>
                onChange(newValue ?? initialBillingTypeSelect)
              }
              getOptionLabel={(option): string => option.label}
              isOptionEqualToValue={(option, value): boolean =>
                option.value === value.value
              }
              renderInput={(params): JSX.Element => (
                <HHTextField
                  {...params}
                  hhVariant="variant-bypass"
                  variant="outlined"
                  label={UnbilledFilterLabelText.BILLING_TYPE}
                />
              )}
            />
          )}
        />
        <Controller //start date
          control={control}
          name="startDate"
          render={({ field: { onChange, value } }): JSX.Element => (
            <DatePicker
              label={UnbilledFilterLabelText.START_DATE}
              key={'startDate'}
              onChange={(newValue): void => {
                onChange(dayjs(newValue).toDate()); //parsing to date for validation
              }}
              value={value}
              renderInput={(params): JSX.Element => (
                <HHTextField
                  {...params}
                  sx={{ minWidth: 230, maxWidth: 230 }}
                  error={params.error}
                  hhVariant="variant-bypass"
                  variant="outlined"
                  InputLabelProps={{ sx: { color: 'gray' } }}
                />
              )}
            />
          )}
        />
        <Controller //end date
          control={control}
          name="endDate"
          render={({ field: { onChange, value } }): JSX.Element => (
            <DatePicker
              label={UnbilledFilterLabelText.END_DATE}
              key={'endDate'}
              onChange={(newValue): void => {
                onChange(dayjs(newValue).toDate()); //parsing to date for validation
              }}
              value={value}
              renderInput={(params): JSX.Element => (
                <HHTextField
                  {...params}
                  sx={{ minWidth: 230, maxWidth: 230 }}
                  error={params.error}
                  hhVariant="variant-bypass"
                  variant="outlined"
                  InputLabelProps={{ sx: { color: 'gray' } }}
                />
              )}
            />
          )}
        />
        <HHButton
          size="small"
          type="submit"
          hhVariant="contained"
          onClick={onFormSubmit}
        >
          Apply
        </HHButton>
        <HHButton size="small" hhVariant="text" onClick={handleClear}>
          Clear
        </HHButton>
      </HHStack>
    </form>
  );
};
