import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { hingeConnect } from '../constants/strings/hinge-connect/hinge-connect';
import { HingeConnectLayout } from '../modules/hinge-connect/components/hinge-connect-layout';

export const HingeConnectPage = (): JSX.Element => (
  <Sentry.ErrorBoundary fallback={<Box>{hingeConnect.error}</Box>}>
    <HingeConnectLayout />
  </Sentry.ErrorBoundary>
);
