import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useEnrollmentStyles = makeStyles(
  createStyles({
    formContainer: { maxWidth: '30rem' },
    input: { display: 'block' },
    field: { display: 'block', marginBottom: '1em' },
    formSubmit: {
      marginLeft: 'auto',
      marginRight: 0,
      display: 'block',
    },
    formHelpText: {
      fontFamily: 'Lato',
      color: '#000',
    },
  }),
);
