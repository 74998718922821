import {
  HHBox,
  HHButton,
  HHButtonProps,
  HHTypography,
} from '@hinge-health/react-component-library';
import {
  buttonLabels,
  tabRoutes,
} from '../../constants/hinge-connect-constants.constant';
import { assessmentTasksRoutes } from '../constants/assessment-tasks.constant';

interface AssessmentTaskMessageProps {
  title: string;
  body: string;
  buttonText?: string;
  buttonProps?: HHButtonProps;
}

export const AssessmentTaskMessage = ({
  title,
  body,
  buttonText,
  buttonProps,
}: AssessmentTaskMessageProps): JSX.Element => (
  <HHBox textAlign="center">
    <HHTypography hhVariant="h2">{title}</HHTypography>
    <HHTypography hhVariant="body">{body}</HHTypography>
    {buttonText && (
      <HHButton
        hhVariant="variant-bypass"
        variant="contained"
        sx={{ margin: '1rem' }}
        {...buttonProps}
      >
        {buttonText}
      </HHButton>
    )}
    <HHButton
      hhVariant="variant-bypass"
      variant="contained"
      sx={{ margin: '1rem' }}
      href={`/${tabRoutes.baseRoute}/${tabRoutes.assessmentTasks}/${assessmentTasksRoutes.list}`}
    >
      {buttonLabels.viewAll}
    </HHButton>
  </HHBox>
);
