import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { FormEvent, MouseEvent } from 'react';
import { useParams } from 'react-router-dom';
import { CONTRACT_MODAL_SUBMIT_BUTTON } from '../../../../constants/strings/contract/form-constants';
import { useGetContractSubscriptionsQuery } from '../../../../types';
import { generateContractSubscriptionsVariables } from '../../../../utils/contract-utils';
import { getContractNotificationMessage } from '../../../utils/contract-notification-utils';
import ContractFormAlert from './alert-banner';
import { WorkRequestNotification } from './work-request-notification';

export interface ContractNotificationModalProps {
  open: boolean;
  onConfirm: (e: MouseEvent<Element, MouseEvent> | FormEvent<Element>) => void;
  onCancel: () => void;
  onClose: () => void;
  title?: string | null;
  confirmText: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  disableSubmit?: boolean;
  isVoid: boolean;
  contractPackageIds?: number[];
}

export const CONTRACT_NOTIFICATION_DIALOG_TEST_ID =
  'contract-notification-dialog';

export const ContractNotificationModal = ({
  open,
  onConfirm,
  onCancel,
  onClose,
  title,
  confirmText = 'Submit',
  startDate,
  endDate,
  disableSubmit = false,
  isVoid = false,
  contractPackageIds = [],
}: ContractNotificationModalProps): JSX.Element => {
  const params = useParams<{ id: string; contractId: string }>();

  const clientInsurerId = parseInt(params.id ?? '0', 10);
  const contractId = parseInt(params.contractId ?? '0', 10) || null;

  const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
  const formattedEndDate = dayjs(endDate).isValid()
    ? dayjs(endDate).format('YYYY-MM-DD')
    : null;

  const variables = generateContractSubscriptionsVariables({
    clientsInsurerId: clientInsurerId,
    contractId,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    contractPackageIds,
  });

  const { data, error, loading } = useGetContractSubscriptionsQuery({
    variables,
  });
  const details = data?.getContractSubscriptions;
  const notificationString = error
    ? 'Error getting associated data'
    : getContractNotificationMessage(contractId, isVoid, details, endDate);

  const disableNewSubmit =
    // submissions present, and is a new contract
    contractId === null &&
    details &&
    (details?.submissionCount >= 1 || details?.subscriptionCount > 0);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid={CONTRACT_NOTIFICATION_DIALOG_TEST_ID}
    >
      {title ? (
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      ) : null}
      <DialogContent>
        {details?.submissionCount ? (
          <ContractFormAlert
            message={
              <WorkRequestNotification
                submissionCount={details?.submissionCount}
              />
            }
          />
        ) : null}
        {dayjs(formattedStartDate).isBefore(dayjs(), 'day') &&
        contractId === null ? (
          <ContractFormAlert
            message={
              'Selected start date is a retroactive change and will trigger subscription generation!'
            }
          />
        ) : null}
        <DialogContentText
          id="alert-dialog-description"
          sx={{ color: '#232E2E' }}
        >
          {notificationString}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          data-testid={CONTRACT_MODAL_SUBMIT_BUTTON}
          disabled={disableSubmit || disableNewSubmit || !!error || loading}
          onClick={onConfirm}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
