import {
  HHGrid,
  HHSnackbarAlert,
  HHTypography,
} from '@hinge-health/react-component-library';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { buttonLabels } from '../../constants/hinge-connect-constants.constant';
import { useSnackbar } from '../../hooks/use-snackbar';
import { ruleSnackbars, rulesTabContent } from '../constants/rules.constant';
import { useCreateRule } from '../hooks/use-create-rule';
import { CreateRuleInput } from '../types';
import { NewRuleForm } from './new-rule-form';
import { RuleForm } from './rule-form';

export const CreateRuleView = (): JSX.Element => {
  const { createRule, data, loading, error, errorMessages } = useCreateRule();
  const { snackbar, handleClose } = useSnackbar({
    data,
    error,
    errorMessages,
    successMessage: ruleSnackbars.create,
  });
  const [jsonForm, setJsonForm] = useState(
    localStorage.getItem('jsonForm') === 'false' ? false : true,
  );

  const onSubmit = (createRuleInput: CreateRuleInput): void => {
    createRule({ variables: { createRuleInput } });
  };

  useEffect(() => {
    localStorage.setItem('jsonForm', jsonForm.toString());
  }, [jsonForm]);

  return (
    <HHGrid container marginTop="1rem">
      <HHSnackbarAlert
        message={snackbar.message}
        severity={snackbar.severity}
        verticalAlignment="top"
        horizontalAlignment="center"
        hhVariant="standard"
        open={snackbar.open}
        onClose={handleClose}
      />
      <HHGrid item xs={10}>
        <HHTypography hhVariant="h2">
          {rulesTabContent.createRule.title}
        </HHTypography>
      </HHGrid>
      <HHGrid item xs={2} justifyContent={'end'} display={'flex'}>
        <FormGroup sx={{ maxWidth: 'fit-content' }}>
          <FormControlLabel
            control={
              <Switch
                onChange={(_e): void => setJsonForm(!jsonForm)}
                checked={jsonForm}
                data-testid="json-form-switch"
              />
            }
            label="JSON Form"
            sx={{ color: 'grey' }}
            labelPlacement="start"
          />
        </FormGroup>
      </HHGrid>

      <HHGrid item xs={12}>
        {jsonForm ? (
          <RuleForm
            title={rulesTabContent.createRule.title}
            loading={loading}
            buttonText={buttonLabels.create}
            onSubmit={onSubmit}
          />
        ) : (
          <NewRuleForm
            title={rulesTabContent.createRule.title}
            loading={loading}
            buttonText={buttonLabels.create}
            onSubmit={onSubmit}
          />
        )}
      </HHGrid>
    </HHGrid>
  );
};
