import { Box, Container } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTargetedInterventionsPageStyles } from '../constants';

export function TargetedInterventionsPage({
  title,
  children,
}: PropsWithChildren<{ title: string }>): JSX.Element {
  const classes = useTargetedInterventionsPageStyles();

  return (
    <Container className={classes.containerOuter} maxWidth={false}>
      <Box className={classes.containerInner}>
        <h3 className={classes.pageTitle}>{title}</h3>
        {children}
      </Box>
    </Container>
  );
}
