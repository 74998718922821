import { HHCircularProgress } from '@hinge-health/react-component-library';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { useWorkflowContext } from '../contexts/workflow.context';
import {
  ApplicationManagementToolWorkflowTypes,
  PathwayTransitionDecoratedWorkflow,
} from '../custom-types';
import {
  selectedWorkflowId,
  selectedWorkflowType,
  showAmtSidebarAtom,
  showWorkflowComplete,
} from '../jotai-atoms/atoms';
import {
  GetWorkflowsByFilterDocument,
  useUpdateWorkflowMutation,
} from '../types';
import { AmtMyWorkflowsFilter } from './amt-my-workflows-filter/amt-my-workflows-filter';
import { ApplicationManagementToolMyWorkflowTable } from './application-management-tool-my-workflows-table';

const LOADER_ID = 'loader';

export const ApplicationManagementToolMyWorkflows = (): JSX.Element => {
  const { workflows, loading } = useWorkflowContext();

  const [, setShowAmtSidebar] = useAtom(showAmtSidebarAtom);
  const [, setSelectedWorkflowId] = useAtom(selectedWorkflowId);
  const [, setSelectedWorkflowType] = useAtom(selectedWorkflowType);
  const [, setWorkflowComplete] = useAtom(showWorkflowComplete);

  const [updateWorkflowMutation] = useUpdateWorkflowMutation();

  const updateViewedState = useCallback(
    async (workflow: PathwayTransitionDecoratedWorkflow) => {
      if (!workflow.viewed) {
        await updateWorkflowMutation({
          variables: {
            updateWorkflowInputDto: {
              workflowId: workflow.id,
              viewed: true,
            },
            workflowType: workflow.type,
          },
          refetchQueries: [GetWorkflowsByFilterDocument],
        });
      }
    },
    [updateWorkflowMutation],
  );

  const clickHandler = (
    workflow: PathwayTransitionDecoratedWorkflow,
  ): Promise<void> => {
    setShowAmtSidebar(true);
    setSelectedWorkflowId(workflow.id);
    setSelectedWorkflowType(
      workflow.type as ApplicationManagementToolWorkflowTypes,
    );
    setWorkflowComplete(false);
    return updateViewedState(workflow);
  };

  if (loading) {
    return <HHCircularProgress data-testid={LOADER_ID} />;
  }

  return (
    <>
      <AmtMyWorkflowsFilter />
      <ApplicationManagementToolMyWorkflowTable
        data={workflows}
        onClick={clickHandler}
      />
    </>
  );
};
