import { Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';

/**
 * Adds a tooltip when `value` is wider than its container.
 */
export function DataGridTooltipCell({
  value,
}: GridRenderCellParams): JSX.Element {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement | null>(null);
  function compareSize(): void {
    setIsOverflow(
      textElementRef.current
        ? textElementRef.current.scrollWidth >
            textElementRef.current.clientWidth
        : false,
    );
  }

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return (): void => {
      window.removeEventListener('resize', compareSize);
    };
  }, []);

  return (
    <Tooltip title={value} disableHoverListener={!isOverflowed}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </div>
    </Tooltip>
  );
}
