import { HHBox, HHTypography } from '@hinge-health/react-component-library';

export const NoRowsMessage = (props: {
  title: string;
  body: string;
  paddingTop?: string;
  paddingBottom?: string;
}): JSX.Element => (
  <HHBox
    textAlign="center"
    paddingTop={props.paddingTop ?? '1rem'}
    paddingBottom={props.paddingBottom ?? '1rem'}
  >
    <HHTypography hhVariant="h3">{props.title}</HHTypography>
    <HHTypography hhVariant="body">{props.body}</HHTypography>
  </HHBox>
);
