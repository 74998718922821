import { ApolloError } from '@apollo/client';
import { HHLabelSection, HHStack } from '@hinge-health/react-component-library';

interface LoadingInputProps {
  error?: ApolloError;
  loading: boolean;
  loadingMessage: string;
  errorLabel: string;
  errorContent: string;
  children: JSX.Element | JSX.Element[];
}

const LoadingInput = (props: LoadingInputProps): JSX.Element => (
  <HHStack direction="column" spacing={'20px'}>
    {props.loading && (
      <HHLabelSection
        label={props.loadingMessage}
        loading={true}
        hhVariant={'text'}
      />
    )}
    {props.error && (
      <HHLabelSection
        content={props.errorContent}
        label={props.errorLabel}
        hhVariant={'error'}
      />
    )}
    {!props.loading && !props.error && props.children}
  </HHStack>
);
export default LoadingInput;
