import { HHSelect } from '@hinge-health/react-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { ActiveStatus } from '../../../../utils/contract-utils';
import {
  IDiscount,
  InvalidProgramDiscounts,
  ProgramSelectionOptions,
} from '../../../../utils/discount-utils';

export interface ProgramSelectProps {
  discountToEdit: IDiscount | null;
}

export const ProgramSelect = ({
  discountToEdit,
}: ProgramSelectProps): JSX.Element => {
  const { control, watch, trigger, resetField } = useFormContext();

  const watchedProgram = watch('program');
  const watchedDiscountType = watch('discountType');

  const disableProgramSelect =
    Object.keys(InvalidProgramDiscounts).includes(watchedDiscountType) ||
    (discountToEdit && discountToEdit?.status !== ActiveStatus.Future);

  return (
    <Controller
      control={control}
      name="program"
      defaultValue={watchedProgram}
      render={({ field: { onChange } }): JSX.Element => (
        <HHSelect
          width={'100%'}
          label="Program Discounted"
          id="program-select"
          data-testid="program-select"
          disabled={disableProgramSelect ?? false}
          initialSelectValue={watchedProgram}
          selectOptions={ProgramSelectionOptions}
          fullWidth
          onChange={(e): void => {
            onChange(e);
            resetField('packageId', { defaultValue: '' });
            trigger('startDate');
            trigger('endDate'); //trigger validation on start and end date if program changes
          }}
        />
      )}
    />
  );
};
