import {
  HHButton,
  HHChip,
  HHDivider,
  HHGrid,
  HHLoadingButton,
  HHSelect,
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Add, DataSaverOnRounded, RuleRounded } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RuleDefinition, RuleStatus } from '../../../../types';
import { HingeConnectAutocomplete } from '../../components/hinge-connect-autocomplete';
import { HCModal } from '../../components/hinge-connect-modal';
import { NoRowsMessage } from '../../components/no-rows';
import {
  buttonLabels,
  operatorSelectOptions,
  sourceAutoCompleteOptions,
  statusOptions,
  tabRoutes,
} from '../../constants/hinge-connect-constants.constant';
import { handleInputChange } from '../../utils/input-change-handler';
import {
  newRuleFormContent,
  ruleLocalStorageKeys,
  rulesRoutes,
  rulesTabContent,
  ruleTextFields,
} from '../constants/rules.constant';
import { RuleFormProps } from '../constants/types';
import { CreateActionForm } from './create-action-form';
import { CreateCriterionForm } from './create-criterion-form';

import { csvToArray } from '../../utils/csv-to-array';

export const NewRuleForm = (props: RuleFormProps): JSX.Element => {
  const navigate = useNavigate();
  const [source, setSource] = useState(props.rule?.source ?? '');
  const [status, setStatus] = useState<RuleStatus | undefined>(
    props.rule?.status,
  );
  const [name, setName] = useState(props.rule?.definition.name ?? '');
  const [resourceTypes, setResourceTypes] = useState(
    props.rule?.resourceTypes?.join('') ?? '',
  );
  const [operator, setOperator] = useState(
    props.rule?.definition.operator ?? '',
  );
  const [criteria, setCriteria] = useState(
    props.rule?.definition?.criteria ?? undefined,
  );

  const [actions, setActions] = useState(props.rule?.actions ?? undefined);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [criteriaMenuOpen, setCriteriaMenuOpen] = useState(false);
  const [criterionFormOpen, setCriterionFormOpen] = useState(false);
  const [actionFormOpen, setActionFormOpen] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (!props.rule) {
      const localRule = JSON.parse(localStorage.getItem('rule') ?? '{}');
      setSource(localRule.source);
      setStatus(localRule.status);
      setName(localRule.definition?.name);
      setResourceTypes(localRule.resourceTypes);
      if (localRule.definition) {
        setOperator(localRule.definition?.operator);
        setCriteria(localRule.definition?.criteria ?? undefined);
      }
      if (localRule?.actions && Array.isArray(localRule.actions)) {
        setActions(localRule.actions);
      }
    }
  }, [props.rule]);

  useEffect(() => {
    localStorage.setItem(
      ruleLocalStorageKeys.rule,
      JSON.stringify({
        source,
        status,
        resourceTypes,
        actions,
        definition:
          !name && !operator && !criteria
            ? undefined
            : { name, operator, criteria },
      }),
    );
    setIsFormValid(!!source && !!name && !!operator && !!criteria && !!actions);
  }, [source, status, name, resourceTypes, operator, actions, criteria]);

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const ruleDto = {
      source,
      resourceTypes:
        !resourceTypes || resourceTypes.trim() === ''
          ? []
          : csvToArray(resourceTypes),
      actions,
      definition: { name, operator, criteria } as RuleDefinition,
      status:
        props.title === rulesTabContent.createRule.title ? undefined : status,
    };

    props.onSubmit(ruleDto);
  };

  const handleCancelClick = (): void => {
    localStorage.removeItem(ruleLocalStorageKeys.rule);
    navigate(`/${tabRoutes.baseRoute}/${tabRoutes.rules}/${rulesRoutes.list}`);
  };

  const handleAddCriteria = (e: React.MouseEvent<HTMLElement>): void => {
    setCriteriaMenuOpen(true);
    setAnchorEl(e.currentTarget);
  };

  const handleAddAction = (): void => {
    setActionFormOpen(true);
  };

  const handleSelectCriterion = (_: React.MouseEvent<HTMLElement>): void => {
    setCriteriaMenuOpen(false);
    setCriterionFormOpen(true);
  };

  const handleAddCriteriaSubmit = (criterion: {
    name: string;
    operator: string;
    key: string;
    value: string;
  }): void => {
    setCriterionFormOpen(false);
    setCriteria(criteria?.concat(criterion) ?? [criterion]);
  };

  const handleAddActionSubmit = (action: {
    type: string;
    metadata: object;
  }): void => {
    setActions(actions?.concat(action) ?? [action]);
    setActionFormOpen(false);
  };

  const getCriterionType = (operator: string): string => {
    if (operator === 'all' || operator === 'any') {
      return 'Rule';
    }
    return 'Criterion';
  };

  const criteriaColumns: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      renderCell: (params): JSX.Element => (
        <HHTypography hhVariant="input-label">{params.value}</HHTypography>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      valueGetter: (params): string => getCriterionType(params.row.operator),
      renderCell: (params): JSX.Element => (
        <HHChip
          hhVariant="filled"
          color="primary"
          label={params.value}
          size="small"
        />
      ),
    },
    {
      field: 'operator',
      headerName: 'Operator',
      width: 200,
      renderCell: (params): JSX.Element => (
        <HHTypography hhVariant="input-label">{params.value}</HHTypography>
      ),
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 200,
      renderCell: (params): JSX.Element => (
        <HHTypography hhVariant="input-label">
          {params.value ?? ''}
        </HHTypography>
      ),
    },
  ];

  const actionColumns: GridColumns = [
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      renderCell: (params): JSX.Element => (
        <HHTypography hhVariant="input-label">
          {params.value ?? ''}
        </HHTypography>
      ),
    },
  ];

  return (
    <HHGrid container>
      <HCModal
        isOpen={criterionFormOpen}
        handleClose={(): void => setCriterionFormOpen(false)}
        title={'Add Criterion'}
        maxWidth="lg"
        fullWidth
        component={
          <CreateCriterionForm
            onCancel={(): void => setCriterionFormOpen(false)}
            onSubmit={handleAddCriteriaSubmit}
          />
        }
      />
      <HCModal
        isOpen={actionFormOpen}
        handleClose={(): void => setActionFormOpen(false)}
        title={'Add Action'}
        maxWidth="md"
        fullWidth
        component={
          <CreateActionForm
            onSubmit={handleAddActionSubmit}
            onCancel={(): void => setActionFormOpen(false)}
          />
        }
      />

      <HHGrid item xs={12}>
        <form data-testid="new-rule-form" onSubmit={handleOnSubmit}>
          <HHGrid container spacing={2}>
            <HHGrid item xs={2}>
              <HHTextField
                fullWidth
                hhVariant="variant-bypass"
                label="Name"
                inputProps={{
                  'aria-label': 'Name',
                  'data-testid': 'rule-name',
                }}
                value={name}
                size="medium"
                onChange={(e): void => handleInputChange(e, setName)}
              />
            </HHGrid>
            <HHGrid item xs={2}>
              <HingeConnectAutocomplete
                label={ruleTextFields.source.label}
                value={source}
                dataTestId="source-select"
                dropdownOptions={sourceAutoCompleteOptions}
                size="medium"
                onChange={(value): void => setSource(value ?? '')}
              />
            </HHGrid>
            <HHGrid item xs={2}>
              <HHTextField
                hhVariant="variant-bypass"
                fullWidth
                label="Resource Types"
                inputProps={{
                  'aria-label': 'Resource Types',
                }}
                size="medium"
                value={resourceTypes}
                onChange={(e): void => handleInputChange(e, setResourceTypes)}
              />
            </HHGrid>
            <HHGrid item xs={2}>
              <HHSelect
                width={'100%'}
                disabled={!status}
                initialSelectValue={status || 'DRAFT'}
                selectOptions={statusOptions}
                label={ruleTextFields.status.label}
                onChange={(e): void => setStatus(e.target.value as RuleStatus)}
                data-testid="status-select"
                size="medium"
              />
            </HHGrid>
            <HHGrid item xs={2}>
              <HHSelect
                width={'100%'}
                selectOptions={operatorSelectOptions}
                initialSelectValue={operator}
                label={ruleTextFields.operator.label}
                onChange={(e): void => setOperator(e.target.value as string)}
                data-testid="operator-select"
                size="medium"
              />
            </HHGrid>
            <HHGrid item xs={2} />
          </HHGrid>
          <HHGrid item xs={12}>
            <HHDivider sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
          </HHGrid>
          <HHGrid container>
            <HHGrid container>
              <HHGrid
                item
                xs={0}
                alignContent={'center'}
                display={'flex'}
                flexWrap={'inherit'}
              >
                <HHTypography hhVariant="h2">Criteria</HHTypography>
              </HHGrid>
              <HHGrid item xs={2}>
                <Menu
                  id="criteria-menu"
                  anchorEl={anchorEl}
                  data-testid="criteria-menu"
                  open={criteriaMenuOpen}
                  onClose={(): void => setCriteriaMenuOpen(false)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  sx={{
                    '& .MuiMenuItem-root:hover': {
                      backgroundColor: '#D1F0DC',
                    },
                  }}
                >
                  <MenuItem onClick={handleSelectCriterion}>
                    <DataSaverOnRounded
                      color="primary"
                      sx={{
                        paddingRight: '0.5rem',
                      }}
                    />
                    Criterion
                  </MenuItem>
                  <MenuItem
                    onClick={(): void => setCriterionFormOpen(false)}
                    disabled={true}
                  >
                    <RuleRounded sx={{ paddingRight: '0.5rem' }} /> Rule
                  </MenuItem>
                </Menu>
                <IconButton
                  color={'primary'}
                  onClick={handleAddCriteria}
                  data-testId="add-criteria-button"
                >
                  <Add />
                </IconButton>
              </HHGrid>
              <HHGrid item xs={8} />
            </HHGrid>
            <HHGrid item xs={12} minHeight={'25vh'}>
              <DataGrid
                columns={criteriaColumns}
                data-testid="criteria-grid"
                rows={criteria ?? []}
                hideFooter
                getRowId={(row): string => row.name}
                sx={{
                  height: '100%',
                  '& .MuiDataGrid-row:hover': { backgroundColor: '#D1F0DC' },
                  '& .MuiDataGrid-cell:hover': {
                    cursor: 'default',
                  },
                }}
                headerHeight={0}
                components={{
                  NoRowsOverlay: NoRowsMessage,
                }}
                disableSelectionOnClick
                componentsProps={{
                  noRowsOverlay: {
                    title: newRuleFormContent.noCriteria.title,
                    body: newRuleFormContent.noCriteria.body,
                    paddingTop: '7rem',
                  },
                }}
              />
            </HHGrid>
          </HHGrid>

          <HHGrid container>
            <HHGrid container>
              <HHGrid
                item
                xs={0}
                alignContent={'center'}
                display={'flex'}
                flexWrap={'inherit'}
              >
                <HHTypography hhVariant="h2">Actions</HHTypography>
              </HHGrid>
              <HHGrid item xs={2}>
                <IconButton
                  color={'primary'}
                  onClick={handleAddAction}
                  data-testId="add-action-button"
                >
                  <Add />
                </IconButton>
              </HHGrid>
            </HHGrid>
            <HHGrid item xs={8} />
            <HHGrid item xs={12} minHeight={'25vh'}>
              <DataGrid
                columns={actionColumns}
                getRowId={(row): string => row.type}
                data-testid="actions-grid"
                rows={actions ?? []}
                hideFooter
                sx={{
                  height: '100%',
                  '& .MuiDataGrid-row:hover': { backgroundColor: '#D1F0DC' },
                  '& .MuiDataGrid-cell:hover': {
                    cursor: 'default',
                  },
                }}
                headerHeight={0}
                components={{
                  NoRowsOverlay: NoRowsMessage,
                }}
                disableSelectionOnClick
                componentsProps={{
                  noRowsOverlay: {
                    title: newRuleFormContent.noActions.title,
                    body: newRuleFormContent.noActions.body,
                    paddingTop: '7rem',
                  },
                }}
              />
            </HHGrid>
          </HHGrid>
          <HHGrid
            container
            display={'flex'}
            spacing={2}
            justifyContent={'end'}
            marginTop={'1rem'}
          >
            <HHGrid item xs={8} />
            <HHGrid item xs={0}>
              <HHButton
                data-testid="cancel-button"
                hhVariant="text"
                sx={{ width: 'fit-content' }}
                onClick={handleCancelClick}
              >
                {buttonLabels.cancel}
              </HHButton>
            </HHGrid>
            <HHGrid item xs={0}>
              <HHLoadingButton
                hhVariant="variant-bypass"
                variant="contained"
                sx={{ width: 'fit-content' }}
                type="submit"
                loading={false}
                disabled={!isFormValid}
                data-testid="submit-button"
              >
                Create
              </HHLoadingButton>
            </HHGrid>
          </HHGrid>
        </form>
      </HHGrid>
    </HHGrid>
  );
};
