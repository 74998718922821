import { HHIconButton } from '@hinge-health/react-component-library';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

const MoreDetailsButton = ({
  onClick,
}: {
  onClick: () => void;
}): JSX.Element => (
  <HHIconButton
    hhVariant="variant-bypass"
    size="small"
    color="primary"
    onClick={onClick}
    title="Click for more details"
  >
    <ReadMoreIcon />
  </HHIconButton>
);

export default MoreDetailsButton;
