export type EnrollmentText = {
  form: {
    uuid: { label: string; help: string; error: string };
    intervention: { label: string; help: string; error: string };
    submit: { label: string; error: string; success: string; apiError: string };
  };
};

/** Strings for targeted interventions user enrollment features */
export const enrollmentText: EnrollmentText = {
  form: {
    uuid: {
      label: 'User IDs',
      help: 'Please enter one User UUID per line.',
      error: 'Invalid UUIDs were found. Please review the data and try again.',
    },
    intervention: {
      label: 'Intervention',
      help: 'Please select the Intervention',
      error: 'Unable to select the intervention',
    },
    submit: {
      label: 'Submit Users',
      success: 'Successfully enrolled $1 user(s)!',
      error: 'Unable to enroll the following users: $1',
      apiError: 'Unable to enroll users due to API error: $1',
    },
  },
};

export const workflowTypeOptions = ['insight-surgery-outreach', 'ti-emo-card'];
export const defaultWorkflowType = workflowTypeOptions[0];

export const enrollmentTextReplaceToken = '$1';
