import {
  HHInfoGrid,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useBillingSetting } from '../../context/billing.context';
import { EngagementBaseType } from '../../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../utils/bills-utils';
import { displayDateTime } from '../../utils/date-helper';
import { cleanTypeNameGraphql } from '../../utils/graphql-utils';
import { TextGroupingChildren } from '../utils/text-grouping';

interface EngagementDetailsProps {
  engagement: EngagementBaseType;
}

const EngagementDetails = ({
  engagement,
}: EngagementDetailsProps): JSX.Element => {
  const { value: displayLocal } = useBillingSetting('displayDateTimeLocal');
  return (
    <HHStack spacing={4}>
      <HHInfoGrid
        data={[
          {
            content: String(engagement.id),
            heading: 'Id',
            copyable: true,
          },
          {
            content: String(engagement.eventId ?? '--'),
            heading: 'Event Id',
            copyable: true,
          },
          {
            content: capitalizeFirstLetterAndSpaceSnakeString(
              engagement.eventType,
            ),
            heading: 'Event Type',
            copyable: false,
          },
          {
            content: capitalizeFirstLetterAndSpaceSnakeString(
              engagement.programIndication,
            ),
            heading: 'Program indication',
            copyable: false,
          },
          {
            content: (
              <HHStack
                sx={{ overflow: 'hidden' }}
                onClick={(): void => {
                  navigator.clipboard.writeText(engagement.eventUuid ?? '--');
                }}
              >
                <HHTypography
                  hhVariant="variant-bypass"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  title="click to copy"
                >
                  {engagement.eventUuid ?? '--'}
                </HHTypography>
              </HHStack>
            ),
            heading: 'Event UUID',
            copyable: true,
          },
          {
            content: (
              <HHStack
                sx={{ overflow: 'hidden' }}
                onClick={(): void => {
                  navigator.clipboard.writeText(engagement.userUuid);
                }}
              >
                <HHTypography
                  hhVariant="variant-bypass"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  title="click to copy"
                >
                  {engagement.userUuid}
                </HHTypography>
              </HHStack>
            ),
            heading: 'User UUID',
            copyable: true,
          },
          {
            content: displayDateTime(engagement.occurredAt, {
              displayLocal,
            }),
            heading: 'Occurred At',
            copyable: false,
          },
          {
            content: displayDateTime(engagement.createdAt, {
              displayLocal,
            }),
            heading: 'Created At',
            copyable: false,
          },
          {
            content: capitalizeFirstLetterAndSpaceSnakeString(
              engagement.sourceType,
            ),
            heading: 'Source Type',
            copyable: false,
          },
        ]}
        divided={false}
        justifyBetween={true}
      />
      <TextGroupingChildren label="Extra details">
        <pre>
          {JSON.stringify(cleanTypeNameGraphql(engagement.details), null, 2)}
        </pre>
      </TextGroupingChildren>
    </HHStack>
  );
};

export default EngagementDetails;
