import {
  HHCheckbox,
  HHFormControlLabel,
} from '@hinge-health/react-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import {
  billableActivitiesMap,
  BILLABLE_ACTIVITY_TEST_ID,
} from '../../../../constants/strings/contract/form-constants';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../../../utils/bills-utils';
import { PackageFormData } from '../../package/custom-package-types';
import { BillableActivitiesProps } from './billable-activities';
import { BillableActivityWithRules, RuleType } from './billable-activity-rules';

export interface BillableActivityCheckBoxProps
  extends Pick<BillableActivitiesProps, 'wholeFormDisabled' | 'isPackageForm'> {
  billActivity: BillableActivityWithRules;
  index: number;
}

const BillableActivityCheckBox = ({
  billActivity,
  isPackageForm,
  wholeFormDisabled,
  index,
}: BillableActivityCheckBoxProps): JSX.Element => {
  const { control } = useFormContext<PackageFormData>();
  const billActivityDefaultSubType = billActivity.subTypeOptions?.[
    RuleType[billActivity.name as keyof typeof RuleType]
  ]?.find(subType => subType.defaultSelected);

  return (
    <HHFormControlLabel
      key={billActivity.id}
      data-testid={BILLABLE_ACTIVITY_TEST_ID}
      label={
        billableActivitiesMap[billActivity.name] ??
        capitalizeFirstLetterAndSpaceSnakeString(billActivity.name)
      }
      control={
        <Controller
          control={control}
          name={
            isPackageForm
              ? `selectedPackages.${index}.selectedBillableActivities`
              : 'selectedBillableActivities'
          }
          render={({ field: { onChange, value } }): JSX.Element => (
            <HHCheckbox
              hhVariant="primary"
              disabled={wholeFormDisabled || billActivity?.disabled}
              sx={{ color: 'gray' }}
              checked={billActivity.id in value}
              onChange={(): void => {
                const newValue = { ...value };
                if (billActivity.id in newValue) {
                  delete newValue[billActivity.id];
                } else {
                  newValue[billActivity.id] = billActivityDefaultSubType
                    ? {
                        [RuleType[billActivity.name as keyof typeof RuleType]]:
                          billActivityDefaultSubType.value,
                      }
                    : null;
                }
                onChange(newValue);
              }}
            />
          )}
        />
      }
    />
  );
};

export default BillableActivityCheckBox;
