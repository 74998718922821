export class SplitResultLink {
  constructor(
    public text: string,
    public link: string,
  ) {}
}
type SplitTextResult = string | SplitResultLink;
export function splitText(str: string): SplitTextResult[] {
  const result: SplitTextResult[] = [];
  const linkTextStartChar = '[';
  const linkTextEndChar = ']';
  const linkURLStartChar = '(';
  const linkURLEndChar = ')';
  let start = 0;
  let linkStart = 0;
  let endOfText = -1;
  for (let i = 0; i < str.length; i++) {
    const indexChar = str[i];
    if (indexChar === linkTextStartChar) {
      linkStart = i;
    } else if (
      str[i] === linkURLStartChar &&
      str[i - 1] === linkTextEndChar &&
      str[linkStart] === linkTextStartChar &&
      linkStart >= start
    ) {
      endOfText = i - 1;
    } else if (str[i] === linkURLEndChar && endOfText > 0) {
      if (start !== linkStart) {
        result.push(str.slice(start, linkStart));
      }
      result.push(
        new SplitResultLink(
          str.slice(linkStart + 1, endOfText),
          str.slice(endOfText + 2, i),
        ),
      );
      start = i + 1;
      endOfText = -1;
    }
  }
  if (start < str.length - 1) {
    result.push(str.slice(start));
  }
  return result;
}
