import {
  HHButton,
  HHMenu,
  HHMenuItem,
} from '@hinge-health/react-component-library';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  FilterList,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { listTagsToolbarContent } from '../constants/tags.constant';

const useStyles = makeStyles(() => ({
  listTagsFilterMenuItemIcon: {
    marginRight: '8px',
  },
}));

export const ListTagsToolbarFiltersMenu = ({
  showDeletedTags,
  setShowDeletedTags,
}: {
  showDeletedTags: boolean;
  setShowDeletedTags: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleFilterOptionClick = (option: string): void => {
    if (option === listTagsToolbarContent.filtersMenu.options.deleted) {
      setShowDeletedTags(!showDeletedTags);
    }
  };

  const deletedLabel = listTagsToolbarContent.filtersMenu.options.deleted;

  return (
    <>
      <HHButton
        data-testid="tags-filters-button"
        hhVariant="variant-bypass"
        aria-label="filters"
        startIcon={<FilterList />}
        id="filters-button"
        aria-controls={open ? 'filters-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {listTagsToolbarContent.filtersMenu.label}
      </HHButton>
      <HHMenu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <HHMenuItem
          onClick={(): void => {
            handleFilterOptionClick(deletedLabel);
            handleClose();
          }}
        >
          {showDeletedTags ? (
            <CheckBox
              className={classes.listTagsFilterMenuItemIcon}
              data-testid="list-tags-deleted-filter-checked"
            />
          ) : (
            <CheckBoxOutlineBlank
              className={classes.listTagsFilterMenuItemIcon}
              data-testid="list-tags-deleted-filter-unchecked"
            />
          )}
          {deletedLabel}
        </HHMenuItem>
      </HHMenu>
    </>
  );
};
