import { Navigate, Route, Routes } from 'react-router-dom';
import { tabRoutes } from '../../constants/hinge-connect-constants.constant';
import { tagsRoutes } from '../constants/tags.constant';
import { ListTagsContextProvider } from '../contexts/list-tags.context';
import { ListTagsView } from './list-tags-view';

export const TagsTab = (): JSX.Element => (
  <Routes>
    <Route
      path={`/${tabRoutes.tags}/${tagsRoutes.list}`}
      element={<ListTagsView ContextProvider={ListTagsContextProvider} />}
    />
    <Route
      path="*"
      element={
        <Navigate
          to={`/${tabRoutes.baseRoute}/${tabRoutes.tags}/${tagsRoutes.list}`}
        />
      }
    />
  </Routes>
);
