import { HHTypography } from '@hinge-health/react-component-library';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { dateSortComparator } from '../../../../utils/datagrid-utils';
import { listTagsGridContent } from '../constants/tags.constant';
import { Tag } from '../types';
import {
  getTagCreatedAt,
  getTagDeletedAt,
  getTagDescription,
  getTagName,
  getTagUpdatedAt,
} from '../utils/list-tags-grid-utils';
import { ListTagsGridDeleteButton } from './list-tags-grid-delete-button';

export const ListTagsGrid = ({
  tags,
  deleteTag,
  deleteTagLoading,
}: {
  tags: Tag[];
  deleteTag: ({ variables }: { variables: { id: string } }) => void;
  deleteTagLoading: boolean;
}): JSX.Element => {
  enum ColumnHeaders {
    name = 'name',
    description = 'description',
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
    deletedAt = 'deletedAt',
    id = 'id',
  }

  const ListTagsColumns: GridColumns = [
    {
      field: ColumnHeaders.name,
      headerName: listTagsGridContent.name,
      width: listTagsGridContent.columnWidth,
      valueGetter: getTagName,
    },
    {
      field: ColumnHeaders.description,
      headerName: listTagsGridContent.description,
      width: listTagsGridContent.columnWidth,
      valueGetter: getTagDescription,
    },
    {
      field: ColumnHeaders.createdAt,
      headerName: listTagsGridContent.createdAt,
      type: 'date',
      width: listTagsGridContent.columnWidth,
      valueGetter: getTagCreatedAt,
      renderCell: (params): string => {
        const date = new Date(params.value as string);
        return date.toLocaleString();
      },
      sortComparator: dateSortComparator,
    },
    {
      field: ColumnHeaders.updatedAt,
      headerName: listTagsGridContent.updatedAt,
      type: 'date',
      width: listTagsGridContent.columnWidth,
      valueGetter: getTagUpdatedAt,
      renderCell: (params): string => {
        const date = new Date(params.value as string);
        return date.toLocaleString();
      },
      sortComparator: dateSortComparator,
    },
    {
      field: ColumnHeaders.deletedAt,
      headerName: '',
      flex: 1,
      sortable: false,
      valueGetter: getTagDeletedAt,
      renderCell: (params): JSX.Element => {
        if (params.value) {
          return (
            <HHTypography hhVariant="body" color="textSecondary">
              {listTagsGridContent.deleted}
            </HHTypography>
          );
        }

        return (
          <ListTagsGridDeleteButton
            id={params.row.id}
            tagName={params.row.name}
            deleteTag={deleteTag}
            deleteTagLoading={deleteTagLoading}
          />
        );
      },
    },
  ];

  return (
    <DataGrid
      columnBuffer={10}
      disableColumnMenu
      autoHeight
      hideFooter
      columns={ListTagsColumns}
      rows={tags}
      isRowSelectable={(): boolean => false}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: ColumnHeaders.updatedAt,
              sort: 'desc',
            },
          ],
        },
      }}
    />
  );
};
