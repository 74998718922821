import { Navigate, Route, Routes } from 'react-router-dom';
import { tabRoutes } from '../../constants/member-data-services-constants.constant';
import { targetedEnrollmentRoutes } from '../constants/targeted-enrollment.constants';
import { NewTaggingRuleForm } from './new-tagging-rule-form';
import { TargetedEnrollmentListView } from './targeted-enrollment-list-view';

export const TargetedEnrollmentTab = (): JSX.Element => (
  <Routes>
    <Route
      path={`/${tabRoutes.targetedEnrollment}/${targetedEnrollmentRoutes.list}`}
      element={<TargetedEnrollmentListView />}
    />
    <Route
      path={`/${tabRoutes.targetedEnrollment}/${targetedEnrollmentRoutes.new}`}
      element={
        <NewTaggingRuleForm
          title={''}
          loading={false}
          buttonText={''}
          onSubmit={function (): void {
            throw new Error('Function Yet To Be Implemented.');
          }}
        />
      }
    />
    <Route
      path="*"
      element={
        <Navigate
          to={`/${tabRoutes.baseRoute}/${tabRoutes.targetedEnrollment}/${targetedEnrollmentRoutes.list}`}
        />
      }
    />
  </Routes>
);
