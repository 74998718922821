import { HHTextField } from '@hinge-health/react-component-library';
import { InputAdornment } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { CONTRACT_FORM_CHRONIC_PRICE_INPUT_ID } from '../../../../constants/strings/contract/form-constants';
import { getCurrencySymbol } from '../../../../utils/currency-helpers';

export interface ChronicCoreChargeInputProps {
  wholeFormDisabled: boolean | undefined;
}

export const ChronicCoreChargeInput = ({
  wholeFormDisabled,
}: ChronicCoreChargeInputProps): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const watchedCurrency = useWatch({ name: 'currency' });
  return (
    <Controller
      control={control}
      name="chronicCoreCharge"
      render={({ field: { onChange, value } }): JSX.Element => (
        <HHTextField
          data-testid={CONTRACT_FORM_CHRONIC_PRICE_INPUT_ID}
          hhVariant="variant-bypass"
          label="Chronic core charge"
          disabled={wholeFormDisabled}
          inputProps={{
            inputMode: 'decimal',
            pattern: '[0-9]*',
            step: '0.01',
            min: '0',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                disableTypography
                sx={{ color: 'gray' }}
              >
                {getCurrencySymbol(watchedCurrency?.value)}
              </InputAdornment>
            ),
          }}
          error={Object.keys(errors?.chronicCoreCharge ?? {}).length !== 0}
          helperText={errors.chronicCoreCharge?.message}
          value={value}
          onChange={(e): void => onChange(parseFloat(e.target.value))}
          type="number"
          InputLabelProps={{ sx: { color: 'gray' } }}
        />
      )}
    />
  );
};
