export const tagsTabContent = {
  listView: {
    title: 'Tags',
  },
  noTags: {
    title: 'No Tags',
    body: 'There aren\'t any tags yet. Create one using the "New" button.',
  },
  noFilteredTags: {
    title: 'No Tags',
    body: 'There aren\'t any tags that match your search. Try a different search term or create one using the "New" button.',
  },
  createTag: {
    title: 'New Tag',
  },
  deleteTag: {
    title: 'Confirm',
    bodyPrefix: 'Are you sure you want to delete the ',
    bodySuffix: ' tag?',
  },
  tagLoadError: {
    title: 'Error',
    body: 'We ran into an issue loading that tag. If you continue to see this error, please confirm you are using a valid tag ID in the URL.',
  },
  tagsLoadError: {
    title: 'Woops',
    body: 'We ran into an issue loading tags.',
    button: 'Try Again',
  },
  tagNotFound: {
    title: 'Tag not found',
    body: 'The tag does not exist, or it might have been deleted.',
  },
  viewTag: {
    title: 'Tag',
  },
};

export const tagsRoutes = {
  list: 'list',
};

export const tagTextFields = {
  tagName: {
    label: 'Name',
    helperText: 'Must be alphanumeric "_" or "-"',
  },
  description: {
    label: 'Description',
  },
};

export const tagSnackbars = {
  create: 'Successfully created tag',
  update: 'Successfully updated tag',
  delete: 'Successfully deleted tag',
};

export const listTagsToolbarContent = {
  filtersMenu: {
    label: 'FILTERS',
    options: {
      deleted: 'Deleted',
    },
  },
  searchPlaceholder: 'Search by name',
};

export const listTagsGridContent = {
  name: 'Name',
  description: 'Description',
  createdAt: 'Created',
  updatedAt: 'Last updated',
  deleted: 'Deleted',
  columnWidth: 300,
};
