/* eslint-disable react-hooks/exhaustive-deps */
import {
  HHCheckbox,
  HHFormControlLabel,
  HHFormGroup,
  HHStack,
  HHTextField,
} from '@hinge-health/react-component-library';
import { useEffect, useState } from 'react';

interface FileInformationSectionProps {
  onChange: (data: { [key: string]: unknown }) => void;
  formData?: { [key: string]: unknown };
}

const FileInformationSection = (
  props: FileInformationSectionProps,
): JSX.Element => {
  const [fileConfigurationName, setFileConfigurationName] = useState<string>(
    (props.formData?.name as string) || '',
  );
  const [fileConfigurationDescription, setFileConfigurationDescription] =
    useState<string>((props.formData?.description as string) || '');
  const [bucket, setBucket] = useState<string>(
    (props.formData?.bucket as string) || 'hingehealth-sftp',
  );
  const [provider, setProvider] = useState<string>(
    (props.formData?.provider as string) || '',
  );
  const [regex, setRegex] = useState<string>(
    (props.formData?.regex as string) || '',
  );
  const encodingDetection = true;
  // const [pgpEncrypted, setPgpEncrypted] = useState<boolean>(
  //   (props.formData?.pgpEncrypted as boolean) || false,
  // );

  useEffect(() => {
    const formdata = {
      name: fileConfigurationName || '',
      description: fileConfigurationDescription || '',
      bucket: bucket || '',
      provider: provider || '',
      regex: regex || '',
      encodingDetection,
      // pgpEncrypted: pgpEncrypted || false,
    };
    props.onChange(formdata);
  }, [
    fileConfigurationName,
    bucket,
    provider,
    regex,
    fileConfigurationDescription,
  ]);

  return (
    <HHStack direction="column" spacing={'20px'}>
      <HHTextField
        hhVariant="outlined"
        value={fileConfigurationName}
        onChange={(e): void => setFileConfigurationName(e.target.value)}
        label={'File Configuration Name'}
      />
      <HHTextField
        hhVariant="outlined"
        value={fileConfigurationDescription}
        onChange={(e): void => setFileConfigurationDescription(e.target.value)}
        label={'File Configuration Description'}
      />
      <HHTextField
        hhVariant="outlined"
        label={'SFTP Bucket'}
        value={bucket}
        onChange={(e): void => setBucket(e.target.value)}
      />
      <HHTextField
        hhVariant="outlined"
        value={provider}
        onChange={(e): void => setProvider(e.target.value)}
        label={'File Provider'}
      />
      <HHTextField
        hhVariant="outlined"
        value={regex}
        onChange={(e): void => setRegex(e.target.value)}
        label={'File Pattern'}
      />
      <HHFormGroup sx={{ marginTop: '10px' }}>
        <HHFormControlLabel
          control={
            <HHCheckbox
              hhVariant="primary"
              checked={encodingDetection}
              disabled
            />
          }
          label="Encoding Detection"
        />
        {/* <HHFormControlLabel
          control={
            <HHCheckbox
              hhVariant="primary"
              checked={pgpEncrypted}
              onChange={(): void => setPgpEncrypted(!pgpEncrypted)}
            />
          }
          label="PGP Encrypted"
        /> */}
      </HHFormGroup>
    </HHStack>
  );
};
export default FileInformationSection;
