import {
  HHButton,
  HHSelect,
  HHSelectChangeEvent,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { listRulesToolbarContent } from '../constants/rules.constant';
import { useListRulesContext } from '../contexts/list-rules.context';
import { ListRulesQuery, RuleStatus } from '../types';

const useStyles = makeStyles(() =>
  createStyles({
    filtersContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '8px',
    },
    hhXButtonContainer: {
      width: '32px',
      marginLeft: '4px',
      marginRight: '8px',
    },
    clearFiltersButtonContainer: {
      marginLeft: '164px',
    },
  }),
);

export const ListRulesToolbarContainer = ({
  rules,
  selectedSource,
  setSelectedSource,
  selectedStatus,
  setSelectedStatus,
  selectedResourceTypes,
  setSelectedResourceTypes,
}: {
  rules: ListRulesQuery['listRules']['rules'];
  selectedSource?: string;
  setSelectedSource: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedStatus?: RuleStatus;
  setSelectedStatus: React.Dispatch<
    React.SetStateAction<RuleStatus | undefined>
  >;
  selectedResourceTypes?: string[];
  setSelectedResourceTypes: React.Dispatch<
    React.SetStateAction<string[] | undefined>
  >;
}): JSX.Element => {
  const classes = useStyles();

  const [localSelectedSource, setLocalSelectedSource] = useState<
    string | undefined
  >(selectedSource || listRulesToolbarContent.defaultOptionLabel);
  const [localSelectedStatus, setLocalSelectedStatus] = useState<
    RuleStatus | string | undefined
  >(selectedStatus || listRulesToolbarContent.defaultOptionLabel);
  const [localSelectedResourceTypes, setLocalSelectedResourceTypes] = useState<
    string[] | undefined
  >(selectedResourceTypes);

  useEffect(() => {
    setLocalSelectedSource(selectedSource);
  }, [selectedSource]);

  useEffect(() => {
    setLocalSelectedStatus(selectedStatus);
  }, [selectedStatus]);

  useEffect(() => {
    setLocalSelectedResourceTypes(selectedResourceTypes);
  }, [selectedResourceTypes]);

  const { uniqueSources, uniqueStatuses, uniqueResourceTypes } = rules.reduce(
    (acc, rule) => {
      acc.uniqueSources.add(rule.source);
      acc.uniqueStatuses.add(rule.status);
      rule.resourceTypes?.forEach(type => acc.uniqueResourceTypes.add(type));
      return acc;
    },
    {
      uniqueSources: new Set<string>(),
      uniqueStatuses: new Set<RuleStatus>(),
      uniqueResourceTypes: new Set<string>(),
    },
  );

  const sortedSources = [...uniqueSources].sort();
  const sortedStatuses = [...uniqueStatuses].sort();
  const sortedResourceTypes = [...uniqueResourceTypes].sort();

  const getResourceTypesLabel = (): string => {
    if (localSelectedResourceTypes && localSelectedResourceTypes.length > 0) {
      return listRulesToolbarContent.resourceTypesSelected(
        localSelectedResourceTypes.length,
      );
    } else {
      return listRulesToolbarContent.resourceTypesLabelDefault;
    }
  };

  const resourceTypesLabel = getResourceTypesLabel();

  return (
    <HHStack
      data-testid="list-rules-toolbar"
      direction="row"
      marginBottom={'1rem'}
      marginTop={'1rem'}
      paddingLeft={'1rem'}
    >
      <div className={classes.filtersContainer}>
        <HHTypography hhVariant="h3">
          {listRulesToolbarContent.filtersLabel}
        </HHTypography>
      </div>
      <HHSelect
        label={listRulesToolbarContent.sourceLabel}
        data-testid="source-select"
        selectOptions={[
          {
            displayName: listRulesToolbarContent.defaultOptionLabel,
            value: listRulesToolbarContent.defaultOptionLabel,
          },
          ...sortedSources.map(source => ({
            displayName: source,
            value: source,
          })),
        ]}
        onChange={(e: HHSelectChangeEvent): void => {
          if (e.target.value === listRulesToolbarContent.defaultOptionLabel) {
            setSelectedSource(undefined);
          } else {
            setSelectedSource(e.target.value as string);
          }
        }}
        initialSelectValue={localSelectedSource}
        disabled={sortedSources.length === 0}
      />
      <div className={classes.hhXButtonContainer}>
        {localSelectedSource && (
          <IconButton
            size="small"
            onClick={(): void => {
              setSelectedSource(undefined);
            }}
          >
            <Close />
          </IconButton>
        )}
      </div>
      <HHSelect
        label={listRulesToolbarContent.statusLabel}
        data-testid="status-select"
        selectOptions={[
          {
            displayName: listRulesToolbarContent.defaultOptionLabel,
            value: listRulesToolbarContent.defaultOptionLabel,
          },
          ...sortedStatuses.map(status => ({
            displayName: status,
            value: status,
          })),
        ]}
        onChange={(e: HHSelectChangeEvent): void => {
          if (e.target.value === listRulesToolbarContent.defaultOptionLabel) {
            setSelectedStatus(undefined);
          } else {
            setSelectedStatus(e.target.value as RuleStatus);
          }
        }}
        initialSelectValue={localSelectedStatus}
        disabled={sortedStatuses.length === 0}
      />
      <div className={classes.hhXButtonContainer}>
        {localSelectedStatus && (
          <IconButton
            size="small"
            onClick={(): void => {
              setSelectedStatus(undefined);
            }}
          >
            <Close />
          </IconButton>
        )}
      </div>
      <HHSelect
        label={
          localSelectedResourceTypes && localSelectedResourceTypes.length > 0
            ? resourceTypesLabel
            : listRulesToolbarContent.resourceTypesLabelDefault
        }
        data-testid="resource-types-select"
        selectOptions={sortedResourceTypes.map(resourceType => ({
          displayName: localSelectedResourceTypes?.includes(resourceType)
            ? `${resourceType} ${listRulesToolbarContent.checkmark}`
            : resourceType,
          value: resourceType,
        }))}
        onChange={(e: HHSelectChangeEvent): void => {
          setSelectedResourceTypes(
            (prevSelectedTypes: string[] | undefined) => {
              if (prevSelectedTypes?.includes(e.target.value as string)) {
                return prevSelectedTypes.filter(
                  type => type !== e.target.value,
                );
              } else {
                return [...(prevSelectedTypes ?? []), e.target.value as string];
              }
            },
          );
        }}
        renderValue={(): string => resourceTypesLabel}
        disabled={sortedResourceTypes.length === 0}
      />
      <div className={classes.hhXButtonContainer}>
        {localSelectedResourceTypes &&
          localSelectedResourceTypes.length > 0 && (
            <IconButton
              size="small"
              onClick={(): void => {
                setSelectedResourceTypes(undefined);
              }}
            >
              <Close />
            </IconButton>
          )}
      </div>
      <div className={classes.clearFiltersButtonContainer}>
        <HHButton
          hhVariant="outlined"
          disabled={
            !selectedSource && !selectedStatus && !selectedResourceTypes
          }
          onClick={(): void => {
            setSelectedSource(undefined);
            setSelectedStatus(undefined);
            setSelectedResourceTypes(undefined);
          }}
        >
          {listRulesToolbarContent.clearFilters}
        </HHButton>
      </div>
    </HHStack>
  );
};

export const ListRulesToolbar = ({
  rules,
}: {
  rules: ListRulesQuery['listRules']['rules'];
}): JSX.Element => {
  const {
    selectedSource,
    setSelectedSource,
    selectedStatus,
    setSelectedStatus,
    selectedResourceTypes,
    setSelectedResourceTypes,
  } = useListRulesContext();

  return (
    <ListRulesToolbarContainer
      rules={rules}
      selectedSource={selectedSource}
      setSelectedSource={setSelectedSource}
      selectedStatus={selectedStatus}
      setSelectedStatus={setSelectedStatus}
      selectedResourceTypes={selectedResourceTypes}
      setSelectedResourceTypes={setSelectedResourceTypes}
    />
  );
};
