import {
  HHLink,
  HHSnackbarAlert,
  HHStack,
} from '@hinge-health/react-component-library';
import ArrowCircleRight from '@mui/icons-material/ArrowCircleRight';
import Inventory from '@mui/icons-material/Inventory';
import { GridActionsCellItem } from '@mui/x-data-grid';
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { ScopeType } from '../../../../../types';
import { BillStatusEnum } from '../../../../targeted-interventions/types';
import { routes } from '../../../constants/strings/routes';
import { useDynamicDrawerContext } from '../../../context/dynamic-drawer.context';
import { useClientInsurersByIdsLazy } from '../../../hooks/client-insurer-hook';
import {
  BillingTypes,
  useGetAllClientsQuery,
  useGetAllInsurersQuery,
  useGetBillsLazyQuery,
  useGetClientInsurersByIdsLazyQuery,
  useGetPartnershipsQuery,
} from '../../../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../../utils/bills-utils';
import { formatCurrency } from '../../../utils/currency-helpers';
import { generateBillHoldsData } from '../utils';
import {
  BillPanel,
  BillPanelMode,
} from './archive-release-components/bill-panel';
import { BillPanelTitle } from './archive-release-components/bill-panel-title';
import {
  allHoldTypeVals,
  BillHoldsGridFilterModelType,
  BillTypeDataList,
  GridColumnFields,
  GridColumnHeaderNames,
  GridSnackBarState,
  NonReleasableHoldReasons,
  ReleasableHoldReasons,
} from './bill-holds-types';
import { ClientCell } from './client-cell';
import HoldsFilters from './filters-toolbar';
import { GridFooter } from './grid-footer';
import { HoldTypeCell } from './hold-types-cell';
import { InsurerCell } from './insurer-cell';
import { NoRowsOverlay } from './no-rows-or-error-overlay';

export const defaultBillHoldsGridFilterModel: BillHoldsGridFilterModelType = {
  claimIds: [],
  holdReasons: allHoldTypeVals(),
  userIds: [],
  subscriptionIds: [],
  startDate: null,
  endDate: null,
  clientIds: [],
  insurerIds: [],
  partnershipIds: [],
  billingTypes: [],
};

const asyncGridValueDisplayGenerator = ({
  value,
}: GridValueFormatterParams): string => {
  if (value.name) {
    return value.name;
  }
  if (value.value) {
    return value.value;
  }
  if (value.error) {
    return 'Error';
  }
  return 'Loading...';
};

export type HoldReasons = ReleasableHoldReasons | NonReleasableHoldReasons;

export interface BillHoldsGridProps {
  preSelectedHoldReason?: string | null;
}

const BillsWithHoldsGrid = ({
  preSelectedHoldReason,
}: BillHoldsGridProps): JSX.Element => {
  const { setDrawerContent } = useDynamicDrawerContext();

  const [filterParams, setFilterParams] =
    useState<BillHoldsGridFilterModelType>(
      preSelectedHoldReason
        ? {
            ...defaultBillHoldsGridFilterModel,
            holdReasons: [preSelectedHoldReason],
          }
        : defaultBillHoldsGridFilterModel,
    );
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [selectedRowModels, setSelectedRowModels] = useState<BillTypeDataList>(
    [],
  );
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0, //need to translate to 0 based index for grid
    pageSize: 25,
  });
  const [snackBarState, setSnackBarState] = useState<GridSnackBarState>({
    open: false,
    message: '',
    severity: 'info',
    hideTime: 1000,
  });

  const { data: ciData, searchClientInsurerIds } = useClientInsurersByIdsLazy();

  const [
    getClientInsurersByIds,
    { loading: filterLoading, error: filterError },
  ] = useGetClientInsurersByIdsLazyQuery();

  const {
    data: clientsData,
    error: clientsError,
    loading: clientsLoading,
  } = useGetAllClientsQuery();

  const {
    data: insurersData,
    error: insurersError,
    loading: insurersLoading,
  } = useGetAllInsurersQuery();

  const {
    data: partnershipsData,
    error: partnershipsError,
    loading: partnershipsLoading,
  } = useGetPartnershipsQuery({ variables: { type: 'billing' } });

  const [gridRows, setGridRows] = useState<BillTypeDataList>([]);

  const [
    getBills,
    { data: billsData, loading: billsLoading, error: billsError },
  ] = useGetBillsLazyQuery();

  const [rowCountState, setRowCountState] = useState<number>(
    billsData?.getBills.total ?? 0,
  );
  const hasError = !!billsError || !!filterError;

  useEffect(() => {
    if (
      filterParams.clientIds.length === 0 &&
      filterParams.insurerIds.length === 0 &&
      filterParams.partnershipIds.length === 0 &&
      filterParams.billingTypes.length === 0
    ) {
      getBills({
        variables: {
          page: paginationModel.page + 1,
          size: paginationModel.pageSize,
          claimIds: filterParams.claimIds,
          internalHoldTypes: filterParams.holdReasons,
          userIds: filterParams.userIds,
          subscriptionIds: filterParams.subscriptionIds,
          clientInsurerIds: [],
          endDate: filterParams.endDate,
          startDate: filterParams.startDate,
          billStatuses: [BillStatusEnum.Submittable],
        },
      });
    }
  }, [filterParams, getBills, paginationModel]);

  useEffect(() => {
    if (
      filterParams.clientIds.length >= 1 ||
      filterParams.insurerIds.length >= 1 ||
      filterParams.billingTypes.length >= 1 ||
      filterParams.partnershipIds.length >= 1
    ) {
      getClientInsurersByIds({
        variables: {
          clientIds: filterParams.clientIds,
          insurerIds: filterParams.insurerIds,
          partnershipIds: filterParams.partnershipIds,
          billingTypes: filterParams.billingTypes.map(
            type => BillingTypes[type as keyof typeof BillingTypes],
          ),
          scope: ScopeType.Exclusive,
        },
      }).then(data => {
        if (
          data.data &&
          data.loading === false &&
          data.error === undefined &&
          data.data.getClientInsurersByIds.length >= 1
        ) {
          getBills({
            variables: {
              page: paginationModel.page + 1,
              size: paginationModel.pageSize,
              claimIds: filterParams.claimIds,
              internalHoldTypes: filterParams.holdReasons,
              userIds: filterParams.userIds,
              subscriptionIds: filterParams.subscriptionIds,
              clientInsurerIds: data?.data.getClientInsurersByIds.map(
                ci => ci.id,
              ),
              endDate: filterParams.endDate,
              startDate: filterParams.startDate,
              billStatuses: [BillStatusEnum.Submittable],
            },
          });
        } else if (
          data.data?.getClientInsurersByIds.length === 0 &&
          !data.error
        ) {
          setSnackBarState({
            open: true,
            message: 'No client insurers found per filter criteria',
            severity: 'info',
            hideTime: 4000,
          });
          setFilterParams({
            ...filterParams,
            clientIds: [],
            insurerIds: [],
            billingTypes: [],
          });
        } else if (data.error) {
          setSnackBarState({
            open: true,
            message: 'Error fetching client insurers per filter criteria',
            severity: 'error',
            hideTime: 4000,
          });
          setFilterParams({
            ...filterParams,
            clientIds: [],
            insurerIds: [],
            billingTypes: [],
          });
        }
      });
    }
  }, [filterParams, getClientInsurersByIds, paginationModel, getBills]);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  useEffect(() => {
    if (!billsLoading && !filterLoading) {
      setRowCountState(prevRowCountState =>
        billsData?.getBills?.total !== undefined
          ? billsData.getBills?.total
          : prevRowCountState,
      );
    }
  }, [setRowCountState, billsData, billsLoading, filterLoading]);

  useEffect(() => {
    setSelectedRowModels(
      selectedRowIds
        .map(id => gridRows.find(item => item.billId === id))
        .filter(item => item !== undefined) as BillTypeDataList,
    );
  }, [selectedRowIds, gridRows]);

  const archiveBill = useCallback(
    (id?: GridRowId) => () => {
      const rows =
        typeof id == 'number'
          ? (gridRows.filter(data => data.billId === id) ?? [])
          : selectedRowModels;

      setDrawerContent(
        <BillPanel
          selectedRows={rows}
          drawerMode={BillPanelMode.Archive}
          setSnackBarState={setSnackBarState}
          clients={clientsData?.getAllClients ?? []}
          insurers={insurersData?.getAllInsurers ?? []}
        />,
        <BillPanelTitle
          message={`Archive ${rows.length} ${
            rows.length > 1 ? 'bills' : 'bill'
          }`}
        />,
        '50%',
        'temporary',
      );
    },
    [
      gridRows,
      selectedRowModels,
      setDrawerContent,
      clientsData?.getAllClients,
      insurersData?.getAllInsurers,
    ],
  );

  const releaseBill = useCallback(
    (id?: GridRowId) => () => {
      const rows =
        typeof id == 'number'
          ? (gridRows.filter(data => data.billId === id) ?? [])
          : selectedRowModels;

      setDrawerContent(
        <BillPanel
          selectedRows={rows}
          drawerMode={BillPanelMode.Release}
          setSnackBarState={setSnackBarState}
          clients={clientsData?.getAllClients ?? []}
          insurers={insurersData?.getAllInsurers ?? []}
        />,
        <BillPanelTitle
          message={`Release ${rows.length} ${
            rows.length > 1 ? 'bills' : 'bill'
          }`}
        />,
        '50%',
        'temporary',
      );
    },
    [
      gridRows,
      selectedRowModels,
      setDrawerContent,
      clientsData?.getAllClients,
      insurersData?.getAllInsurers,
    ],
  );

  const billHoldsColumns: GridColDef[] = [
    {
      field: GridColumnFields.CLAIM_ID,
      headerName: GridColumnHeaderNames.CLAIM_ID,
      type: 'string',
      width: 150,
      sortable: false,
      editable: false,
      resizable: false,
      disableColumnMenu: true,
    },
    {
      field: GridColumnFields.HOLD_REASON,
      headerName: GridColumnHeaderNames.HOLD_REASON,
      editable: false,
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renderCell: params => <HoldTypeCell {...params} />,
    },
    {
      field: GridColumnFields.AMOUNT,
      headerName: GridColumnHeaderNames.AMOUNT,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      editable: false,
      resizable: false,
      valueFormatter: (params): string => formatCurrency(params.value),
    },
    {
      field: GridColumnFields.USER_ID,
      headerName: GridColumnHeaderNames.USER_ID,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      resizable: false,
      editable: false,
      renderCell: params => (
        <HHLink
          color="primary"
          href={`${routes.billing.home}/${routes.billing.userPath}/${params.value}`}
          target="_blank"
          sx={{ textDecoration: 'none' }}
        >
          {params.value}
        </HHLink>
      ),
    },
    {
      field: GridColumnFields.SUBSCRIPTION_ID,
      headerName: GridColumnHeaderNames.SUBSCRIPTION_ID,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      editable: false,
    },
    {
      field: GridColumnFields.DATE_OF_SERVICE,
      headerName: GridColumnHeaderNames.DATE_OF_SERVICE,
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      editable: false,
    },
    {
      field: GridColumnFields.CLIENT,
      headerName: GridColumnHeaderNames.CLIENT,
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      editable: false,
      resizable: true,
      renderCell: params => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <ClientCell clients={clientsData?.getAllClients ?? []} {...params} />
      ),
    },
    {
      field: GridColumnFields.INSURER,
      headerName: GridColumnHeaderNames.INSURER,
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      editable: false,
      resizable: true,
      renderCell: params => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <InsurerCell
          insurers={insurersData?.getAllInsurers ?? []}
          {...params}
        />
      ),
    },
    {
      field: GridColumnFields.PARTNERSHIP,
      headerName: GridColumnHeaderNames.PARTNERSHIP,
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      editable: false,
      resizable: true,
      valueFormatter: (params): string =>
        capitalizeFirstLetterAndSpaceSnakeString(
          asyncGridValueDisplayGenerator(params),
        ),
    },
    {
      field: GridColumnFields.BILLING_TYPE,
      headerName: GridColumnHeaderNames.BILL_TYPE,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      editable: false,
      valueFormatter: (params): string =>
        params === null
          ? '-'
          : capitalizeFirstLetterAndSpaceSnakeString(
              asyncGridValueDisplayGenerator(params),
            ),
    },
    {
      field: GridColumnFields.ACTIONS,
      type: 'actions',
      align: 'right',
      width: 100,
      getActions: params =>
        Object.values(ReleasableHoldReasons).includes(
          params.row.holdDetails.holdType,
        )
          ? [
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <GridActionsCellItem
                key={params.id}
                label="Release"
                icon={<ArrowCircleRight />}
                onClick={releaseBill(params.id)}
                showInMenu={false}
              />,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <GridActionsCellItem
                key={`${params.id}-archive`}
                label="Archive"
                icon={<Inventory />}
                onClick={archiveBill(params.id)}
                showInMenu={false}
              />,
            ]
          : [
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <GridActionsCellItem
                key={params.id}
                label="Archive"
                icon={<Inventory />}
                onClick={archiveBill(params.id)}
                showInMenu={false}
              />,
            ],
    },
  ];

  useEffect(() => {
    if (billsData?.getBills) {
      const rows = generateBillHoldsData(billsData.getBills.items, ciData);
      searchClientInsurerIds(new Set(rows.map(row => row.clientsInsurerId)));
      setGridRows(rows);
    }
  }, [billsData, setGridRows, ciData, searchClientInsurerIds]);

  const handlePaginationChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => {
    setPaginationModel({ ...paginationModel, page });
  };

  return (
    <HHStack
      flex={1}
      spacing={6}
      direction="column"
      sx={{ overflow: 'scroll' }}
    >
      <HHSnackbarAlert
        hhVariant="filled"
        horizontalAlignment="center"
        message={snackBarState.message}
        open={snackBarState.open}
        autoHideTime={snackBarState.hideTime ?? 1000}
        severity={snackBarState.severity}
        verticalAlignment="top"
        onClose={(): void => {
          setSnackBarState({ ...snackBarState, open: false });
        }}
      />
      <HoldsFilters
        clients={clientsData?.getAllClients ?? []}
        clientsError={clientsError}
        clientsLoading={clientsLoading}
        insurers={insurersData?.getAllInsurers ?? []}
        insurersError={insurersError}
        insurersLoading={insurersLoading}
        partnerships={partnershipsData?.getPartnerships ?? []}
        partnershipsError={partnershipsError}
        partnershipsLoading={partnershipsLoading}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
      <DataGridPro
        sx={{ flex: 1, paddingLeft: 6, width: '100%' }}
        columns={billHoldsColumns}
        rows={gridRows.map(row => ({ id: row.billId, ...row }))}
        loading={billsLoading || filterLoading}
        disableVirtualization
        //  pagination
        rowCount={rowCountState}
        disableRowSelectionOnClick
        pagination
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        // filtering
        filterMode="server"
        disableMultipleRowSelection={false}
        checkboxSelection
        onRowSelectionModelChange={setSelectedRowIds}
        hideFooter
        slots={{
          noResultsOverlay: NoRowsOverlay,
          noRowsOverlay: NoRowsOverlay,
        }}
        slotProps={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          noResultsOverlay: { error: hasError },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          noRowsOverlay: { error: hasError },
        }}
      />
      <GridFooter
        // pagination
        page={paginationModel.page}
        rowsPerPage={paginationModel.pageSize}
        count={rowCountState}
        onPageChange={handlePaginationChange}
        // selection
        selectedRows={selectedRowModels}
        releaseAction={releaseBill()}
        archiveAction={archiveBill()}
        setSnackBarState={setSnackBarState}
      />
    </HHStack>
  );
};

export default BillsWithHoldsGrid;
