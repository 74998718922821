export const assessmentTasksTabContent = {
  listView: {
    title: 'Assessment Tasks',
  },
  createView: {
    title: 'New Assessment Task',
  },
  noTasks: {
    title: 'No Assessment Tasks',
    body: 'There aren\'t any assessment tasks yet. Create one using the "New" button.',
  },
  taskLoadError: {
    title: 'Error',
    body: 'We ran into an issue loading that assessment task. If you continue to see this error, please confirm you are using a valid assessment task ID in the URL.',
  },
  tasksLoadError: {
    title: 'Woops',
    body: 'We ran into an issue loading assessment tasks.',
    button: 'Try Again',
  },
  taskNotFound: {
    title: 'Assessment task not found',
    body: 'The assessment task does not exist, or it might have been deleted.',
  },
  cancelTask: {
    message: 'Are you sure you want to cancel this assessment task?',
    confirmButton: 'Cancel Task',
    returnButton: 'Return',
    toolTip: 'Cancel this assessment task',
  },
  filters: {
    label: 'Filters',
    clear: 'Clear Filters',
  },
  viewTask: {
    title: 'Assessment Task Details',
  },
};

export const assessmentTasksRoutes = {
  edit: 'edit',
  list: 'list',
  view: 'view',
};

export const assessmentTasksTextFields = {
  id: {
    label: 'ID',
  },
  status: {
    label: 'Status',
  },
  source: {
    label: 'Source',
    helperText: 'Source of resources to assess',
  },
  resourceType: {
    label: 'Resource Type',
    helperText: 'Resource type of resources to assess',
  },
  ruleId: {
    label: 'Rule ID',
    helperText: 'Specific rule to be evaluated',
  },
  assessmentWindow: {
    label: 'Assessment Window',
    helperText: 'The date range of resources to include',
    startDate: 'Start Date',
    to: 'to',
    endDate: 'End Date',
  },
  limit: {
    label: 'Limit',
    helperText:
      'Limit of assessment tasks to run. Leave blank to run all resources.',
  },
  arguments: {
    label: 'Arguments',
  },
  progress: {
    label: 'Progress',
  },
  completed: {
    label: 'Completed',
  },
  errored: {
    label: 'Errored',
  },
  actionsExecuted: {
    label: 'Actions Executed',
  },
  assertionsPassed: {
    label: 'Assertions Passed',
  },
  createdAt: {
    label: 'Created At',
  },
  updatedAt: {
    label: 'Updated At',
  },
  assessmentResults: {
    label: 'Assessment Results',
    ruleAssessmentId: {
      label: 'Rule Assessment ID',
    },
    results: {
      label: 'Results',
    },
  },
};

export const assessmentTasksSnackbars = {
  create: 'Successfully created assessment task',
  cancel: 'Successfully canceled assessment task',
};

export const listAssessmentTasksGridContent = {
  createdAt: 'Created At',
  status: 'Status',
  source: 'Source',
  total: 'Total',
  completed: 'Completed',
  errored: 'Errored',
  actionsExecuted: 'Actions Executed',
  assertionsPassed: 'Assertions Passed',
  progress: 'Progress',
  columnWidth: 200,
};

export enum AssessmentTaskStatus {
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}
