import { Navigate, Route, Routes } from 'react-router-dom';
import { tabRoutes } from '../../constants/hinge-connect-constants.constant';
import { useListRuleAssessmentsHook } from '../hooks/list-rule-assessments-hook';
import { RuleAssessmentDetailView } from './detail/rule-assessments-detail-view';
import { ListRuleAssessmentsView } from './list/list-rule-assessments-view';

export const RuleAssessmentsTab = (): JSX.Element => (
  <Routes>
    <Route
      path={`/${tabRoutes.ruleAssessments}/:id/view`}
      element={<RuleAssessmentDetailView />}
    />
    <Route
      path={`/${tabRoutes.ruleAssessments}/list`}
      element={
        <ListRuleAssessmentsView
          listRuleAssessmentsQuery={useListRuleAssessmentsHook}
        />
      }
    />
    <Route
      path="*"
      element={
        <Navigate
          to={`/${tabRoutes.baseRoute}/${tabRoutes.ruleAssessments}/list`}
        />
      }
    />
  </Routes>
);
