import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ResultSlice from './results-slice';
import { IDiscountCalculatorState, IDiscountPrices } from './utils';

type ProsCalculatorResults = {
  data: IDiscountCalculatorState;
  defaultPrices: IDiscountPrices;
};

const CalculatorResults = ({
  data,
  defaultPrices,
}: ProsCalculatorResults): JSX.Element => (
  <Stack direction="column" spacing={0}>
    <Typography variant="h6">Results</Typography>
    <ResultSlice data={data} defaultPrices={defaultPrices} mType="R1" />
    <ResultSlice data={data} defaultPrices={defaultPrices} mType="R2" />
    <ResultSlice data={data} defaultPrices={defaultPrices} mType="R3" />
  </Stack>
);

export default CalculatorResults;
