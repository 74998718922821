import dayjs, { Dayjs } from 'dayjs';

import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GetSplitTreatmentOnOrOff } from '../../../../../components/splits';
import { newBillingActivitiesAndSubTypesEnabled } from '../../../constants/strings/split';
import {
  ContractType,
  useGetAllEngagementTypesQuery,
  useGetAllPackagesQuery,
  useGetBillingModelsQuery,
  useGetContractTemplatesQuery,
} from '../../../types';
import {
  computeStartDateDefault,
  filterTemplatesByPartnerships,
} from '../../../utils/contract-utils';
import { getCurrencySymbol } from '../../../utils/currency-helpers';
import { cleanTypeNameGraphql } from '../../../utils/graphql-utils';
import { CurrentBillingModels } from '../../main/contract-billing-model-launch';
import {
  BillableActivitiesWithDetails,
  DEFAULT_FORM_CURRENCY,
} from '../package/custom-package-types';
import {
  BillableActivityWithRules,
  mapBillableActivitiesToRules,
} from './components/billable-activity-rules';
import {
  CleanAvailablePackages,
  CleanBillableActivities,
  CleanBillingModel,
  ContractTemplateOption,
  RawContractPackage,
  RawFormValues,
  UsableContractType,
} from './custom-types';

interface UseGetDefaultReturn {
  templates: ContractTemplateOption[];
  defaultStartDateCalenderDate: Dayjs;
  billableActivities: CleanBillableActivities[] | BillableActivityWithRules[];
  availablePackages: CleanAvailablePackages[];
  networkErrors: Record<string, string | undefined>;
  defaultValues: RawFormValues;
  billingModels: CleanBillingModel[];
  contractPackageValues: RawContractPackage;
}

export function useGetDefaultValues(
  currentContracts: ContractType[],
  partnershipIds?: number[] | undefined,
  contractEditId?: number | null,
): UseGetDefaultReturn {
  const location = useLocation();
  const { data, error } = useGetContractTemplatesQuery();
  const { data: billableActivities, error: billableActivityError } =
    useGetAllEngagementTypesQuery();

  const { data: availablePackages } = useGetAllPackagesQuery();
  const { data: billingModels } = useGetBillingModelsQuery();

  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const adminId = hingeHealthAuthState?.accessToken?.claims.uid.toString();

  const newBillableActivitiesEnabled = GetSplitTreatmentOnOrOff(
    newBillingActivitiesAndSubTypesEnabled,
    adminId,
  );

  const networkErrors = {
    templateError: error?.message,
    billableActivityError: billableActivityError?.message,
  };

  /**
   * Form initial values
   */
  const noDataTemplate = { name: '', contractTemplateId: -1, contract: [{}] };

  const [initialTemplate, setInitialTemplate] =
    useState<ContractTemplateOption>(noDataTemplate);
  const preSelectedBillingModel = location.state
    ?.billingModel as keyof typeof CurrentBillingModels;

  /*
   * Abstracting raw gql response into easy template options for dropdown
   * filtering by partnershipId
   */
  const templates: ContractTemplateOption[] = data
    ? filterTemplatesByPartnerships(
        partnershipIds,
        data.getContractTemplates,
      ).map(temp => ({
        name: temp.name,
        contractTemplateId: temp.id,
        contract: temp.contract,
      }))
    : [noDataTemplate];

  /**
   * Calculate default start date
   */
  const defaultStartDateCalenderDate = computeStartDateDefault(
    currentContracts as UsableContractType[],
  );

  useEffect(() => {
    let template = null;
    if (data && contractEditId) {
      template = data.getContractTemplates.find(
        t =>
          t.id ===
          currentContracts.find(c => c.id === contractEditId)
            ?.contractTemplateId,
      );
    } else if (
      data &&
      preSelectedBillingModel &&
      CurrentBillingModels[preSelectedBillingModel] ===
        CurrentBillingModels.standard
    ) {
      template = data.getContractTemplates.find(t => t.name === 'Standard');
    }

    if (template) {
      setInitialTemplate({
        name: template.name,
        contractTemplateId: template.id,
        contract: cleanTypeNameGraphql(template.contract),
      });
    }
  }, [data, contractEditId, currentContracts, preSelectedBillingModel]);

  const contractToEdit = currentContracts.find(c => c.id === contractEditId);

  // TODO remove with https://hingehealth.atlassian.net/browse/MINT-8511
  const cleanBillableActivities = billableActivities
    ? cleanTypeNameGraphql(billableActivities?.getAllEngagementTypes)
    : [];

  const billableActivitiesWithRules = newBillableActivitiesEnabled
    ? mapBillableActivitiesToRules('legacy', cleanBillableActivities)
    : [];

  const finalizedBillableActivities = newBillableActivitiesEnabled
    ? billableActivitiesWithRules
    : cleanBillableActivities;

  const initialSelectedBillableActivities = newBillableActivitiesEnabled
    ? billableActivitiesWithRules
        .filter(bar => bar?.defaultSelected)
        .reduce((acc: BillableActivitiesWithDetails, activity) => {
          acc[activity.id] = activity?.details ? activity.details : null;
          return acc;
        }, {})
    : {};

  return {
    templates: templates.toSorted((a, b) => a.name.localeCompare(b.name)),
    defaultStartDateCalenderDate,
    billableActivities: finalizedBillableActivities,
    availablePackages: availablePackages
      ? cleanTypeNameGraphql(availablePackages?.getAllPackages)
      : [],
    billingModels: billingModels
      ? cleanTypeNameGraphql(billingModels.getBillingModels)
      : [],
    networkErrors,
    defaultValues: {
      currentTemplate: initialTemplate,
      startDate: contractToEdit
        ? dayjs(contractToEdit.startDate).toDate()
        : null,
      endDate:
        contractToEdit && contractToEdit.endDate
          ? dayjs(contractToEdit.endDate).toDate()
          : null,
      currency:
        contractToEdit && contractToEdit.currency
          ? {
              label: `${contractToEdit.currency} ${getCurrencySymbol(
                contractToEdit?.currency,
              )} `,
              value: contractToEdit.currency,
            }
          : DEFAULT_FORM_CURRENCY,
      chronicCoreCharge: contractToEdit?.chronicPrice ?? 995.0,
      isVoid: contractToEdit?.void ?? false,
      selectedBillableActivities:
        contractToEdit?.billableActivityTypes?.reduce(
          (acc: BillableActivitiesWithDetails, activity) => {
            acc[activity.id] = activity?.details ? activity.details : null;
            return acc;
          },
          {},
        ) ?? initialSelectedBillableActivities,
      contract: initialTemplate.contract,
      enableAcuteProgram: !!contractToEdit?.acutePrice,
      acuteCoreCharge: contractToEdit?.acutePrice ?? 250.0,
      enableInPersonVisit:
        contractToEdit?.contractPackages.some(Boolean) ?? false,
      contractPackages:
        contractToEdit?.contractPackages.map(p => ({
          id: p.id ?? null,
          packageId: p.packageId,
          billingModelId: p.billingModelId,
          price: p.price,
          startDate: dayjs(p.startDate).toDate() ?? null,
          billableActivities: {},
        })) ?? [],
      partnershipId: contractEditId
        ? (currentContracts.find(c => c.id === contractEditId)?.partnershipId ??
          null)
        : (partnershipIds?.[0] ?? null),
    },
    contractPackageValues: {
      id: null,
      packageId: null,
      billingModelId: null,
      price: 150.0,
      startDate: null,
      billableActivities: {},
    },
  };
}
