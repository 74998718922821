export const CONTRACT_FORM_TEST_ID = 'contract-edit-and-new-form-test-id';
export const CONTRACT_FORM_TEMPLATE_SELECT_ID =
  'contract-template-select-test-id';
export const CONTRACT_FORM_START_DATE_PICKER_ID = 'contract-start-date-pick-id';
export const CONTRACT_FORM_END_DATE_PICKER_ID = 'contract-end-date-pick-id';
export const CONTRACT_FORM_DATE_PICKERS_ID = 'contract-v2-date-pickers';
export const CONTRACT_FORM_CHRONIC_PRICE_INPUT_ID =
  'contract-chronic-price-input-id';
export const CONTRACT_FORM_BILLABLE_ACTIVITIES_GROUP_ID =
  'contract-billable-activities-group-id';
export const CONTRACT_FORM_IN_PERSON_VISIT_CHECKBOX_ID =
  'contract-in-person-visit-check-id';
export const CONTRACT_FORM_IN_PERSON_VISIT_CHARGE_ID =
  'contract-in-person-visit-charge-id';
export const CONTRACT_FORM_IN_PERSON_VISIT_EFFECTIVE_DATE_PICKER_ID =
  'contract-in-person-visit-effective-date-picker-id';
export const CONTRACT_FORM_MILESTONE_JSON_ID = 'contract-milestone-json-id';
export const CONTRACT_FORM_ENABLE_ACUTE_CHECK =
  'contract-enable-acute-check-id';
export const CONTRACT_FORM_ACUTE_PRICE_INPUT_ID =
  'contract-acute-price-input-id';
export const CONTRACT_CANCEL_BUTTON_TEST_ID = 'contract-cancel-button-test-id';
export const CONTRACT_SUBMIT_BUTTON_TEST_ID = 'contract-submit-button-test-id';
export const CONTRACT_MODAL_SUBMIT_BUTTON =
  'contract-modal-submit-button-test-id';
export const CONTRACT_MILESTONE_DISPLAY_TEST_ID =
  'contract-formv2-milestone-display';
export const BILLABLE_ACTIVITY_TEST_ID = 'billable-activity-test-id';
export const billableActivitiesMap: Record<string, string> = {
  enso_session: 'Enso session',
  et_session: 'Exercise therapy session',
  article_read: 'Article read',
  video_visit: 'Video visit',
  in_person_visit: 'In Person Visit',
  configurable: 'Configurable',
  care_team_communication: 'Care team communication',
};

export const billableActivitiesMapv2: Record<string, string> = {
  enso_session: 'Enso session: Daily cap 3',
  et_session: 'Exercise therapy session',
  article_read: 'Article read',
  video_visit: 'Video visit',
  in_person_visit: 'In Person Visit',
  configurable: 'Configurable',
  care_team_communication: 'Care team communication',
};
export const availablePackagesMap: Record<string, string> = {
  in_person_visit: 'In Person Visit',
};
export const IN_PERSON_NAME_KEY = 'in_person_visit';
export const CARE_TEAM_COMMUNICATION_KEY = 'care_team_communication';
