import {
  HHButton,
  HHGrid,
  HHLoadingButton,
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import { ExpandMoreSharp } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { HingeConnectAutocomplete } from '../../components/hinge-connect-autocomplete';
import {
  buttonLabels,
  criteriaOperatorsMap,
  getCriteriaOperatorsCompleteOptions,
  getCriteriaOperatorValueCompleteOptions,
} from '../../constants/hinge-connect-constants.constant';
import { handleInputChange } from '../../utils/input-change-handler';
import { criterionTextFields } from '../constants/rules.constant';

export const CreateCriterionForm = (props: {
  onCancel: () => void;
  onSubmit: (criterion: {
    name: string;
    operator: string;
    key: string;
    value: string;
  }) => void;
}): JSX.Element => {
  const [name, setName] = useState('');
  const [operator, setOperator] = useState('');
  const [key, setKey] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [formInvalid, setFormInvalid] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    props.onSubmit({ name, operator, key, value: searchValue });
  };

  useEffect(() => {
    if (name && operator && key && searchValue) {
      setFormInvalid(false);
    } else {
      setFormInvalid(true);
    }
  }, [name, operator, key, searchValue]);

  return (
    <form onSubmit={handleSubmit}>
      <HHGrid container spacing={2} marginTop={'1rem'}>
        <HHGrid item xs={3}>
          <HHTextField
            fullWidth
            hhVariant="variant-bypass"
            label={criterionTextFields.name.label}
            inputProps={{
              'aria-label': 'Name',
              'data-testid': 'rule-name',
            }}
            value={name}
            size="medium"
            onChange={(e): void => handleInputChange(e, setName)}
            helperText={criterionTextFields.name.helperText}
          />
        </HHGrid>
        <HHGrid item xs={3}>
          <HHTextField
            fullWidth
            hhVariant="variant-bypass"
            label={criterionTextFields.key.label}
            inputProps={{
              'aria-label': 'Key',
            }}
            value={key}
            size="medium"
            onChange={(e): void => handleInputChange(e, setKey)}
            helperText={criterionTextFields.key.helperText}
          />
        </HHGrid>
        <HHGrid item xs={3}>
          <HingeConnectAutocomplete
            label={criterionTextFields.operator.label}
            value={operator}
            clearOnBlur
            dataTestId="operator-select"
            dropdownOptions={getCriteriaOperatorsCompleteOptions() ?? []}
            size="medium"
            onChange={(value): void => setOperator(value ?? '')}
            helperText={criterionTextFields.operator.helperText}
          />
        </HHGrid>
        <HHGrid item xs={3}>
          <HingeConnectAutocomplete
            label={criterionTextFields.value.label}
            value={searchValue}
            dataTestId="value-select"
            dropdownOptions={
              getCriteriaOperatorValueCompleteOptions(operator) ?? []
            }
            size="medium"
            onChange={(value): void => setSearchValue(value ?? '')}
            helperText={criterionTextFields.value.helperText}
          />
        </HHGrid>
        <HHGrid container display={'flex'} spacing={2} marginTop={'1rem'}>
          <HHGrid item xs={12}>
            {operator && (
              <Grow
                in={operator !== ''}
                style={{ transformOrigin: '0 0 0' }}
                timeout={1000}
              >
                <Accordion
                  variant="elevation"
                  expanded={expanded}
                  elevation={0}
                  sx={{
                    marginTop: '10px',
                    position: 'relative',
                    '&:before': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <AccordionSummary
                    onClick={(_e): void => setExpanded(!expanded)}
                    expandIcon={<ExpandMoreSharp />}
                    sx={{ flexDirection: 'row-reverse' }}
                  >
                    <HHGrid container>
                      <HHGrid item xs={12} borderBottom="1px lightgray solid">
                        <HHTypography hhVariant="input-label">
                          Operator Information
                        </HHTypography>
                      </HHGrid>
                    </HHGrid>
                  </AccordionSummary>

                  <AccordionDetails>
                    <HHGrid container spacing={4}>
                      <HHGrid item xs={12}>
                        <HHTypography hhVariant="variant-bypass" padding="1rem">
                          {criteriaOperatorsMap.get(operator)?.description}
                        </HHTypography>
                      </HHGrid>
                    </HHGrid>
                  </AccordionDetails>
                </Accordion>
              </Grow>
            )}
          </HHGrid>
        </HHGrid>
        <HHGrid
          container
          display={'flex'}
          spacing={2}
          justifyContent={'end'}
          marginTop={'1rem'}
        >
          <HHGrid item xs={8} />
          <HHGrid item xs={0}>
            <HHButton
              data-testid="cancel-criterion-button"
              hhVariant="text"
              sx={{ width: 'fit-content' }}
              onClick={props.onCancel}
            >
              {buttonLabels.cancel}
            </HHButton>
          </HHGrid>
          <HHGrid item xs={0}>
            <HHLoadingButton
              hhVariant="variant-bypass"
              variant="contained"
              sx={{ width: 'fit-content' }}
              type="submit"
              loading={false}
              disabled={formInvalid}
              data-testid="submit-button"
            >
              Add
            </HHLoadingButton>
          </HHGrid>
        </HHGrid>
      </HHGrid>
    </form>
  );
};
