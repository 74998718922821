export const routes = {
  login: '/login',
  auth_redirect_callback: '/okta/callback',
  targeted_interventions: '/ti',
  targeted_interventions_enrollment: '/ti/enrollment',
  acute_physical_therapist: '/acute',
  application_management_tool: '/application-management-tool',
  billing_tool: '/billing-tool/*',
  hinge_connect: '/hinge-connect/*',
  hinge_connect_menu: '/hinge-connect/rules/list',
  eligibility: '/eligibility',
  member_auth: '/member-auth/:uuid',
  member_data: '/member-data/*',
  member_data_services: '/member-data-services/*',
  other: '/not-found',
};
