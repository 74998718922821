import {
  HHAlert,
  HHButton,
  HHIconButton,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useSnackbarContext } from '../../context/snackbar.context';
import { useClientInsurersByClient } from '../../hooks/client-insurer-hook';
import {
  GetDiscountsDocument,
  GetDiscountUsageDocument,
  ScopeType,
  useCreateDiscountMutation,
  useGetAllPackagesQuery,
  useGetContractsByClientIdLazyQuery,
  useUpdateDiscountMutation,
} from '../../types';
import { IDiscount } from '../../utils/discount-utils';
import {
  cleanTypeNameGraphql,
  GqlNetworkStatus,
} from '../../utils/graphql-utils';
import { SimplePackageType } from '../contract/package/custom-package-types';
import DiscountDrawer, { DiscountDrawerMode } from './discount-drawer';
import DiscountsList from './discounts-list';
import { DiscountForm } from './form/form';
import { ParsedDiscountValues } from './form/form-constants';

export interface DiscountComponentProps {
  discountsData?: IDiscount[];
  loading?: boolean;
  clientId: number;
  partnershipIds?: number[];
  usageStatus: GqlNetworkStatus;
  disableAddNew?: boolean;
}

const DiscountComponent = ({
  discountsData,
  loading = true,
  partnershipIds,
  clientId,
  usageStatus,
  disableAddNew = false,
}: DiscountComponentProps): JSX.Element => {
  const [drawerMode, setDrawerMode] = useState<DiscountDrawerMode>(
    DiscountDrawerMode.None,
  );
  const [discountToEdit, setDiscountToEdit] = useState<IDiscount | null>(null);
  const { data: clientData } = useClientInsurersByClient(clientId);
  const [createDiscountMutation, { data }] = useCreateDiscountMutation();
  const { setSnackbarState } = useSnackbarContext();
  const [updateDiscountMutation, { data: updateData }] =
    useUpdateDiscountMutation();
  const [
    getContractsByClientId,
    { data: contractsData, loading: contractsLoading, error: contractsError },
  ] = useGetContractsByClientIdLazyQuery();
  const { data: availablePackages } = useGetAllPackagesQuery();

  const createMessage = `Discount ${
    data?.createDiscount ? 'created' : 'not created'
  } for ${clientData?.name} client`;
  const updateMessage = `Discount ${
    updateData?.updateDiscount ? 'updated' : 'not updated'
  } for ${clientData?.name} client`;

  useEffect(() => {
    if (clientData && clientData.clientsInsurers.length > 0) {
      getContractsByClientId({
        variables: {
          id: clientData?.clientsInsurers[0]?.client.id,
          includeVoided: false,
        },
      });
    }
  }, [clientData, getContractsByClientId]);

  useEffect(() => {
    if (data || updateData) {
      setSnackbarState(
        data?.createDiscount ? createMessage : updateMessage,
        'success',
        3000,
      );
    }
  }, [data, updateData, createMessage, updateMessage, setSnackbarState]);

  useEffect(() => {
    if (contractsError && !contractsLoading) {
      setSnackbarState('Unable to load currency information', 'info', 3000);
    }
  }, [contractsError, contractsLoading, setSnackbarState]);

  const cleanPackages: SimplePackageType[] = availablePackages
    ? cleanTypeNameGraphql(availablePackages?.getAllPackages)
    : [];

  const handleClose = (): void => {
    setDiscountToEdit(null);
    setDrawerMode(DiscountDrawerMode.None);
  };

  const handleNewDiscountClick = (): void => {
    setDiscountToEdit(null);
    setDrawerMode(DiscountDrawerMode.New);
  };

  const sendFormData = async (values: ParsedDiscountValues): Promise<void> => {
    try {
      if (drawerMode === DiscountDrawerMode.New) {
        await createDiscountMutation({
          variables: {
            discountInput: values,
          },
          refetchQueries: [
            {
              query: GetDiscountsDocument,
              variables: {
                clientId,
                partnershipIds,
                scopeType: ScopeType.Inclusive,
              },
            },
            {
              query: GetDiscountUsageDocument,
              variables: {
                clientId,
              },
            },
          ],
        });
      }
      if (drawerMode === DiscountDrawerMode.Edit && discountToEdit) {
        await updateDiscountMutation({
          variables: {
            discountInput: {
              ...values,
              discountDefinitionId: discountToEdit.discountDefinitionId,
            },
          },
          refetchQueries: [
            {
              query: GetDiscountsDocument,
              variables: {
                clientId,
                partnershipIds,
                scopeType: ScopeType.Inclusive,
              },
            },
            {
              query: GetDiscountUsageDocument,
              variables: {
                clientId,
                discountDetailIds: discountToEdit.discountDetails.map(
                  det => det.discountDetailId,
                ),
              },
            },
          ],
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      handleClose();
    }
  };

  const handleEditDiscountClick = (discountDefinitionId: number): void => {
    const foundDiscount =
      discountsData?.find(
        d => d.discountDefinitionId === discountDefinitionId,
      ) ?? null;
    setDiscountToEdit(foundDiscount);
    setDrawerMode(DiscountDrawerMode.Edit);
  };

  return (
    <HHStack direction="column" spacing={4}>
      <DiscountDrawer drawerMode={drawerMode} onClose={handleClose}>
        <HHStack alignSelf="flex-end">
          <HHIconButton hhVariant="medium" onClick={handleClose}>
            <CloseIcon />
          </HHIconButton>
        </HHStack>
        <Stack padding={1}>
          <DiscountForm
            clientId={clientId}
            formTitle={`${DiscountDrawerMode[drawerMode]} Discount`}
            submitTitle="Submit Discount"
            onSubmitCallback={sendFormData}
            onCancelCallback={(): void => handleClose()}
            discountToEdit={discountToEdit}
            currentDiscounts={discountsData}
            availablePackages={cleanPackages}
          />
        </Stack>
      </DiscountDrawer>
      <HHStack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <HHTypography hhVariant="section-title">Discounts</HHTypography>
        {disableAddNew ? null : (
          <HHButton
            hhVariant="variant-bypass"
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleNewDiscountClick}
            endIcon={<span>+</span>}
          >
            Add New
          </HHButton>
        )}
      </HHStack>
      {usageStatus.error && (
        <HHAlert
          hhVariant="outlined"
          severity="error"
          children={
            <HHTypography hhVariant="body">
              There was an error loading discount usage information
            </HHTypography>
          }
        />
      )}
      <DiscountsList
        discountsData={discountsData}
        onEditDiscountClick={handleEditDiscountClick}
        loading={loading}
        availablePackages={cleanPackages}
        currency={contractsData?.getContracts[0]?.currency || null}
      />
    </HHStack>
  );
};

export default DiscountComponent;
