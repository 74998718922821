import { HHIconButton } from '@hinge-health/react-component-library';
import { HHTypography } from '@hinge-health/react-component-library/dist/components/atoms/hh-typography';
import SettingsIcon from '@mui/icons-material/Settings';
import { useDynamicDialogContext } from '../../../context/dynamic-dialog.context';
import { ContractType } from '../../../types';
import { ContractSettingsForm } from './contract-settings-form';

export interface ContractSettingsButtonProps {
  contract: ContractType;
}

export const ContractSettingsButton = ({
  contract,
}: ContractSettingsButtonProps): JSX.Element => {
  const { setDialogContent } = useDynamicDialogContext();

  return (
    <HHIconButton
      size="small"
      hhVariant="variant-bypass"
      color="primary"
      title="Settings"
      onClick={(): void =>
        setDialogContent({
          title: (
            <HHTypography hhVariant="section-title">
              Submission settings
            </HHTypography>
          ),
          content: <ContractSettingsForm contract={contract} />,
        })
      }
    >
      <SettingsIcon fontSize="small" />
    </HHIconButton>
  );
};
