import {
  ApolloCache,
  ApolloError,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
} from '@apollo/client';
import { useState } from 'react';
import { getApolloErrorMessages } from '../../utils/get-apollo-error-messages';
import {
  CreateUserTagInput,
  CreateUserTagMutation,
  Exact,
  useCreateUserTagMutation,
} from '../types';

type UseCreateUserTagHook = {
  createUserTag: (
    options?:
      | MutationFunctionOptions<
          CreateUserTagMutation,
          Exact<{
            createUserTagInput: CreateUserTagInput;
          }>,
          DefaultContext,
          ApolloCache<unknown>
        >
      | undefined,
  ) => Promise<
    FetchResult<
      CreateUserTagMutation,
      Record<string, unknown>,
      Record<string, unknown>
    >
  >;
  data: unknown;
  loading: boolean;
  error: ApolloError | undefined;
  errorMessages: string;
};

export const useCreateUserTag = (
  completeCallback: () => void,
): UseCreateUserTagHook => {
  const [errorMessages, setErrorMessages] = useState('');

  const [createUserTag, { data, loading, error }] = useCreateUserTagMutation({
    onError: (error: ApolloError) => {
      setErrorMessages(getApolloErrorMessages(error));
    },
    onCompleted: () => {
      completeCallback();
    },
  });

  return { createUserTag, data, loading, error, errorMessages };
};
