import { debounce } from 'lodash';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ListTagsQuery, Tag, useListTagsQuery } from '../types';

export type ListTagsContextType = {
  tagNameSearch: string | undefined;
  setTagNameSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
  showDeletedTags: boolean;
  setShowDeletedTags: React.Dispatch<React.SetStateAction<boolean>>;
  totalTagCount: number;
  listTagsData: ListTagsQuery | undefined;
  filteredTags: Tag[] | undefined;
  listTagsLoading: boolean;
  refetchListTags: () => void;
};

const ListTagsContext = createContext<ListTagsContextType>({
  tagNameSearch: undefined,
  setTagNameSearch: () => {
    // do nothing
  },
  showDeletedTags: false,
  setShowDeletedTags: () => {
    // do nothing
  },
  totalTagCount: 0,
  listTagsData: undefined,
  filteredTags: undefined,
  listTagsLoading: true,
  refetchListTags: () => {
    // do nothing
  },
});

export const useListTagsContext = (): ListTagsContextType =>
  useContext(ListTagsContext);

export type ListTagsContextProviderProps = {
  children: React.ReactNode;
};

export const ListTagsContextProvider = ({
  children,
}: ListTagsContextProviderProps): JSX.Element => {
  const [tagNameSearch, setTagNameSearch] = useState<string | undefined>(
    undefined,
  );
  const [showDeletedTags, setShowDeletedTags] = useState<boolean>(false);
  const [filteredTags, setFilteredTags] = useState<Tag[] | undefined>(
    undefined,
  );

  const { data, loading, refetch } = useListTagsQuery({
    variables: {
      listTagsInput: {
        deleted: showDeletedTags.toString(),
      },
    },
  });

  const debouncedTagFilter = useMemo(
    () =>
      debounce(search => {
        if (!search) {
          setFilteredTags(data?.listTags.tags);
        } else {
          setFilteredTags(
            data?.listTags.tags.filter(tag => tag.name.includes(search)),
          );
        }
      }, 300), // adjust the delay as needed
    [data],
  );

  useEffect(() => {
    debouncedTagFilter(tagNameSearch);
  }, [tagNameSearch, debouncedTagFilter]);

  const providerValue = useMemo(
    () => ({
      tagNameSearch,
      setTagNameSearch,
      showDeletedTags,
      setShowDeletedTags,
      totalTagCount: data?.listTags.count || 0,
      listTagsData: data,
      filteredTags,
      listTagsLoading: loading,
      refetchListTags: refetch,
    }),
    [tagNameSearch, showDeletedTags, filteredTags, data, loading, refetch],
  );

  return (
    <ListTagsContext.Provider value={providerValue}>
      {children}
    </ListTagsContext.Provider>
  );
};
