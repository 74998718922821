import { HHBox } from '@hinge-health/react-component-library';
import { useEffect } from 'react';
import { useGetWorkflowContext } from '../../contexts/get-workflow.context';
import { AMT_PATHWAY_TRANSITION_STATES } from '../../custom-types';
import { AmtMemberDetails } from './amt-member-details';
import { AmtMemberHeader } from './amt-member-header';
import { AmtMemberTransitionAccordion } from './amt-member-transition-accordion';
import { AmtMemberTransitionNotesAccordion } from './amt-member-transition-notes-accordion';
import { AmtTaskAccordion } from './tasks-accordion';

type AmtMemberLayoutProps = {
  onSubmit?: () => void;
  onComplete: () => void;
};

export const AmtMemberLayout = ({
  onSubmit,
  onComplete,
}: AmtMemberLayoutProps): JSX.Element => {
  const { workflow, reload } = useGetWorkflowContext();

  useEffect(() => {
    if (
      workflow?.state ===
      AMT_PATHWAY_TRANSITION_STATES.CHECK_FOR_DUPLICATE_ACCOUNT
    ) {
      reload();
    }
  }, [workflow?.state, reload]);

  useEffect(() => {
    if (workflow?.lastCompletedAt) {
      onComplete();
    }
  }, [workflow?.lastCompletedAt, onComplete]);

  return (
    <HHBox>
      <AmtMemberHeader />
      <AmtMemberDetails />
      <AmtTaskAccordion />
      <AmtMemberTransitionAccordion onSubmit={onSubmit} />
      <AmtMemberTransitionNotesAccordion />
    </HHBox>
  );
};
