import {
  HHCircularProgress,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { MaterialCircularProgressProps } from '@hinge-health/react-component-library/dist/components/atoms/hh-circular-progress';
import { PropsWithChildren } from 'react';

const LoadingComponent = ({
  center = false,
  size = 30,
  helperText,
}: PropsWithChildren<{
  center?: boolean;
  size?: MaterialCircularProgressProps['size'];
  helperText?: string;
}>): JSX.Element => (
  <HHStack
    sx={
      center
        ? {
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }
        : {}
    }
    direction="column"
    flex={1}
  >
    <HHCircularProgress size={size} data-testid="LoopIcon" styleAs="primary" />
    {helperText && <HHTypography hhVariant="muted">{helperText}</HHTypography>}
  </HHStack>
);

export default LoadingComponent;
