import {
  HHBox,
  HHButton,
  HHCircularProgress,
  HHGrid,
  HHLabelSection,
  HHTable,
  HHTableBody,
  HHTableCell,
  HHTableContainer,
  HHTableHead,
  HHTableRow,
  HHTypography,
} from '@hinge-health/react-component-library';
import { isAfter, subHours } from 'date-fns';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  buttonLabels,
  notFoundErrorMarker,
  tabRoutes,
} from '../../constants/hinge-connect-constants.constant';
import { omitTypename } from '../../utils/omit-typename';
import { printDateAndTime } from '../../utils/print-date-and-time';
import {
  assessmentTasksRoutes,
  assessmentTasksTabContent,
  AssessmentTaskStatus,
  assessmentTasksTextFields,
} from '../constants/assessment-tasks.constant';
import { GetAssessmentTaskQuery, useGetAssessmentTaskQuery } from '../types';
import { AssessmentTaskLoadError } from './assessment-task-load-error';
import { AssessmentTaskNotFound } from './assessment-task-not-found';
import { AssessmentTaskProgressBar } from './assessment-task-progress-bar';

interface AssessmentTaskDetailsProps {
  id: string;
  assessmentTask: GetAssessmentTaskQuery['getAssessmentTask'];
}

interface AssessmentTaskDetailItemProps {
  label: string;
  content: string;
}

interface AssessmentTaskDetailViewProps {
  pollInterval?: number;
}

interface AssessmentTaskDetailViewContainerProps {
  pollInterval: number;
}

const DEFAULT_POLL_INTERVAL = 5000;

const IN_PROGRESS_STATUSES: string[] = [
  AssessmentTaskStatus.IN_PROGRESS,
  AssessmentTaskStatus.QUEUED,
];

const AssessmentTaskDetailItem = ({
  label,
  content,
}: AssessmentTaskDetailItemProps): JSX.Element => (
  <HHGrid item xs={12} sm={6}>
    <HHLabelSection hhVariant="text" label={label} content={content} />
  </HHGrid>
);

const AssessmentTaskDetailItemSmall = ({
  label,
  content,
}: AssessmentTaskDetailItemProps): JSX.Element => (
  <HHGrid item xs={6}>
    <HHLabelSection hhVariant="text" label={label} content={content} />
  </HHGrid>
);

const buildAssessmentResultResults = (
  completed: number,
  errored: number,
  actionsExecuted: number,
  assertionPassed: number,
): string => {
  const resultList = [];

  if (completed > 0) {
    resultList.push(
      assessmentTasksTextFields.completed.label.toLocaleLowerCase(),
    );
  }

  if (errored > 0) {
    resultList.push(
      assessmentTasksTextFields.errored.label.toLocaleLowerCase(),
    );
  }

  if (actionsExecuted > 0) {
    resultList.push(
      assessmentTasksTextFields.actionsExecuted.label.toLocaleLowerCase(),
    );
  }

  if (assertionPassed > 0) {
    resultList.push(
      assessmentTasksTextFields.assertionsPassed.label.toLocaleLowerCase(),
    );
  }

  return resultList.join(', ');
};

const AssessmentTaskDetails = ({
  assessmentTask,
}: AssessmentTaskDetailsProps): JSX.Element => (
  <HHBox maxWidth="50rem">
    <HHGrid container spacing={2}>
      <HHGrid item xs={12} sm={6}>
        <HHTypography hhVariant="h1">
          {assessmentTasksTabContent.viewTask.title}
        </HHTypography>
      </HHGrid>
      <HHGrid item xs={12} sm={6} margin="auto" textAlign="right">
        <HHButton
          hhVariant="variant-bypass"
          variant="contained"
          href={`/${tabRoutes.baseRoute}/${tabRoutes.assessmentTasks}/${assessmentTasksRoutes.list}`}
        >
          {buttonLabels.backToList}
        </HHButton>
      </HHGrid>
    </HHGrid>

    <HHGrid container spacing={2} marginTop="1rem">
      <AssessmentTaskDetailItem
        label={assessmentTasksTextFields.id.label}
        content={assessmentTask.id}
      />
      <AssessmentTaskDetailItem
        label={assessmentTasksTextFields.status.label}
        content={assessmentTask.status}
      />
      <HHGrid item xs={12} md={6} marginTop="2rem">
        <HHTypography hhVariant="input-label">
          {assessmentTasksTextFields.arguments.label}
        </HHTypography>
        <HHTypography hhVariant="body2" color="textSecondary">
          {JSON.stringify(omitTypename(assessmentTask.arguments), null, 2)
            .split('\n')
            .map((line, index) => {
              const lineNumber = `line-${index + 1}`;
              return <pre key={lineNumber}>{line}</pre>;
            })}
        </HHTypography>
      </HHGrid>
      <HHGrid item xs={12} md={6} marginTop="2rem">
        <HHGrid container spacing={2}>
          <HHGrid item xs={12}>
            <HHTypography hhVariant="input-label">
              {assessmentTasksTextFields.progress.label}
            </HHTypography>
            <HHBox width="100%">
              <AssessmentTaskProgressBar
                totalAssessments={assessmentTask.totalAssessments}
                remaining={assessmentTask.remaining}
              />
            </HHBox>
          </HHGrid>
          <AssessmentTaskDetailItemSmall
            label={assessmentTasksTextFields.completed.label}
            content={assessmentTask.completed.toString()}
          />
          <AssessmentTaskDetailItemSmall
            label={assessmentTasksTextFields.errored.label}
            content={assessmentTask.errored.toString()}
          />
          <AssessmentTaskDetailItemSmall
            label={assessmentTasksTextFields.actionsExecuted.label}
            content={assessmentTask.totalAssessments.toString()}
          />
          <AssessmentTaskDetailItemSmall
            label={assessmentTasksTextFields.assertionsPassed.label}
            content={assessmentTask.assertionPassed.toString()}
          />
        </HHGrid>
      </HHGrid>
      <AssessmentTaskDetailItem
        label={assessmentTasksTextFields.createdAt.label}
        content={printDateAndTime(assessmentTask.createdAt)}
      />
      <AssessmentTaskDetailItem
        label={assessmentTasksTextFields.updatedAt.label}
        content={printDateAndTime(assessmentTask.updatedAt)}
      />
      <HHGrid container spacing={2} marginTop="3rem">
        <HHTypography hhVariant="h2">
          {assessmentTasksTextFields.assessmentResults.label}
        </HHTypography>
      </HHGrid>
      <HHGrid container spacing={2}>
        <HHTableContainer>
          <HHTable>
            <colgroup>
              <col style={{ width: '50%' }} />
              <col style={{ width: '50%' }} />
            </colgroup>
            <HHTableHead>
              <HHTableRow>
                <HHTableCell>{assessmentTasksTextFields.id.label}</HHTableCell>
                <HHTableCell>
                  {assessmentTasksTextFields.assessmentResults.label}
                </HHTableCell>
              </HHTableRow>
            </HHTableHead>
            <HHTableBody>
              {assessmentTask.assessmentResults.map(assessmentResult => (
                <HHTableRow key={assessmentResult.id}>
                  <HHTableCell>{assessmentResult.id}</HHTableCell>
                  <HHTableCell>
                    {buildAssessmentResultResults(
                      assessmentResult.completed,
                      assessmentResult.errored,
                      assessmentResult.actionsExecuted,
                      assessmentResult.assertionPassed,
                    )}
                  </HHTableCell>
                </HHTableRow>
              ))}
            </HHTableBody>
          </HHTable>
        </HHTableContainer>
      </HHGrid>
    </HHGrid>
  </HHBox>
);

const AssessmentTaskDetailViewContainer = ({
  pollInterval,
}: AssessmentTaskDetailViewContainerProps): JSX.Element => {
  const params = useParams();

  const isPolling = useRef<boolean>(false);

  const { data, loading, error, refetch, startPolling, stopPolling } =
    useGetAssessmentTaskQuery({
      variables: { id: params.id as string },
    });

  useEffect(() => {
    if (data) {
      const twelveHoursAgo = subHours(new Date(), 12);
      const updatedRecently = isAfter(
        new Date(data.getAssessmentTask.updatedAt),
        twelveHoursAgo,
      );
      const isRefreshableTask =
        updatedRecently &&
        IN_PROGRESS_STATUSES.includes(data.getAssessmentTask.status);

      if (isRefreshableTask && !isPolling.current) {
        startPolling(pollInterval);
        isPolling.current = true;
      }
      if (!isRefreshableTask && isPolling.current) {
        stopPolling();
        isPolling.current = false;
      }
    }
  }, [data, pollInterval, startPolling, stopPolling]);

  if (loading) return <HHCircularProgress />;
  if (data)
    return (
      <AssessmentTaskDetails
        id={params.id as string}
        assessmentTask={data.getAssessmentTask}
      />
    );
  if (error?.graphQLErrors[0]?.message.includes(notFoundErrorMarker))
    return <AssessmentTaskNotFound />;
  return <AssessmentTaskLoadError handleRetry={refetch} />;
};

export const AssessmentTaskDetailView = ({
  pollInterval = DEFAULT_POLL_INTERVAL,
}: AssessmentTaskDetailViewProps): JSX.Element => (
  <HHBox paddingY="1rem" display="flex" justifyContent="center">
    <AssessmentTaskDetailViewContainer pollInterval={pollInterval} />
  </HHBox>
);
