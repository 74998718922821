export enum OktaRole {
  COACH = 'Role: Health Coach',
  NURSE_PRACTITIONER = 'Role: Nurse Practitioner',
  PHYSICAL_THERAPIST = 'Role: Physical Therapist',
  DELIVERY_OPS = 'Role: Delivery Ops',
  REVENUE_CYCLE_MANAGER = 'Role: Revenue Cycle Manager',
  PHI_MONITORING = 'Role: Production Monitoring Phi',
  TARGETED_INTERVENTIONS = 'Role: Targeted Interventions',
  PHI_PRODUCT_MANAGER = 'Role: Product Manager Phi',
  ACCOUNTING = 'Role: Accounting',
  STEADY_MD_ACCESS = 'Role: SteadyMD-Access',
  STEADY_MD_DEVELOPERS = 'Role: SteadyMD-Developers',
  HEALTH_ECONOMIST = 'Role: Health Economists',
  ELIGIBILITY_SELF_SERVICE = 'APP: CTT-Shell eligibility-self-service-access',
  TECHNICAL_SUPPORT = 'Role: Tech Support',
  MEMBER_SUPPORT = 'Role: Member Support',
}
