import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useTargetedInterventionsPageStyles = makeStyles(
  createStyles({
    containerOuter: {
      display: 'flex',
      minHeight: '100%',
      padding: '1em',
    },
    containerInner: {
      backgroundColor: '#FFF',
      flex: '1 1 100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '0 2em',
    },
    pageTitle: { margin: '2em 0', fontFamily: 'Lato', color: '#000' },
  }),
);
