import { useNavigate } from 'react-router-dom';

export const useConfigsTable = (): { handleAddClick: () => void } => {
  const navigate = useNavigate();

  const handleAddClick = (): void => {
    navigate('/member-data-services/file-configurations/add');
  };

  return {
    handleAddClick,
  };
};
