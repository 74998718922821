import { ApolloError } from '@apollo/client';
import {
  ListRuleAssessmentsQuery,
  useListRuleAssessmentsQuery,
} from '../types';

export type UseListRuleAssessmentsHook = {
  data?: ListRuleAssessmentsQuery;
  loading: boolean;
  error?: ApolloError;
};

export type UseListRuleAssessmentsHookProps = {
  resourceId?: string;
};

export function useListRuleAssessmentsHook(
  props: UseListRuleAssessmentsHookProps,
): UseListRuleAssessmentsHook {
  const { data, loading, error } = useListRuleAssessmentsQuery({
    skip: !props.resourceId,
    variables: {
      listRuleAssessmentsInput: { resourceId: props.resourceId },
    },
  });

  return { data, loading, error };
}
