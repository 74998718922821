import { HHTypography } from '@hinge-health/react-component-library';
import { Button } from '@mui/material';

export interface WorkRequestNotificationProps {
  submissionCount: number;
}

export const WorkRequestNotification = ({
  submissionCount,
}: WorkRequestNotificationProps): JSX.Element => {
  const workRequestLink = process.env.REACT_APP_MINT_WORK_REQUEST_FORM ?? '';
  return (
    <HHTypography hhVariant="body2">
      {`This contract has ${submissionCount} submissions associated,
          please submit a work request to billing-eng
          `}
      {
        <a href={workRequestLink} target={'_blank'}>
          <Button color="error" variant="text" role="button">
            Work Request Link
          </Button>
        </a>
      }
    </HHTypography>
  );
};
