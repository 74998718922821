import { HHTypography } from '@hinge-health/react-component-library';
import { IDiscount, IDiscountUsage } from '../../../utils/discount-utils';

export interface InnovationCreditsUsageProps {
  discountData: IDiscount;
}

const getUsageDisplay = (discountUsage: IDiscountUsage): string => {
  if (discountUsage.loading || discountUsage.error) {
    return '--';
  }

  return String(discountUsage.totalCount ?? 0);
};

const InnovationCreditsUsage = ({
  discountData,
}: InnovationCreditsUsageProps): JSX.Element => {
  const tier = discountData.discountDetails[0]?.subscriptionTier[1];

  return (
    <HHTypography hhVariant="inherit">
      {getUsageDisplay(discountData.discountDetails[0].discountUsage)}/
      {tier ? tier - 1 : null} Free Subscriptions Assigned
    </HHTypography>
  );
};

export default InnovationCreditsUsage;
