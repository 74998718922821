import {
  HHChip,
  HHIconButton,
  HHStack,
} from '@hinge-health/react-component-library';
import { FilterList } from '@mui/icons-material';
import dayjs from 'dayjs';
import {
  BILL_DATE_FORMAT,
  NonReleasableHoldReasons,
  ReleasableHoldReasons,
} from './bill-holds-types';
import { HoldsFiltersProps } from './filters-toolbar';
import { defaultBillHoldsGridFilterModel } from './grid';

export interface QuickFiltersProps extends HoldsFiltersProps {
  filterToggle: () => void;
  holdTypes: string[];
}

export const QuickFilters = ({
  filterToggle,
  setFilterParams,
  filterParams,
  holdTypes,
}: QuickFiltersProps): JSX.Element => {
  const setQuickHoldFilterParams = (quickFilterInput: string): void => {
    const { holdReasons } = filterParams;

    let newHoldReasons: string[] = [];
    if (filterParams.holdReasons.length !== 0) {
      newHoldReasons = [quickFilterInput];
      setFilterParams({ ...filterParams, holdReasons: newHoldReasons });
    } else {
      newHoldReasons = holdReasons.filter(
        reason => reason !== quickFilterInput,
      );
      setFilterParams({ ...filterParams, holdReasons: newHoldReasons });
    }
  };

  const setQuickDateFilterParams = (
    quickFilterInput: Record<string, string>,
  ): void => {
    const { startDate } = filterParams;
    if (!dayjs(startDate).isSame(dayjs(quickFilterInput.startDate))) {
      setFilterParams({
        ...filterParams,
        startDate: quickFilterInput.startDate,
        endDate: quickFilterInput.endDate,
      });
    } else {
      setFilterParams({
        ...filterParams,
        startDate: null,
        endDate: null,
      });
    }
  };

  const holdQuickFilters = [
    {
      displayName: 'Carrier change',
      value: ReleasableHoldReasons.CARRIER_CHANGE,
    },
    {
      displayName: 'Client change',
      value: ReleasableHoldReasons.CLIENT_CHANGE,
    },
    {
      displayName: 'Loss of coverage',
      value: NonReleasableHoldReasons.LOSS_OF_COVERAGE,
    },
  ];

  const dateQuickFilters = [
    {
      displayName: 'In past 30 days',
      value: {
        startDate: dayjs().subtract(30, 'day').format(BILL_DATE_FORMAT),
        endDate: dayjs().format(BILL_DATE_FORMAT),
      },
    },
    {
      displayName: 'In past 6 months',
      value: {
        startDate: dayjs().subtract(6, 'months').format(BILL_DATE_FORMAT),
        endDate: dayjs().format(BILL_DATE_FORMAT),
      },
    },
  ];

  const customFiltersApplied =
    filterParams.claimIds.length > 0 ||
    filterParams.userIds.length > 0 ||
    filterParams.subscriptionIds.length > 0 ||
    filterParams.clientIds.length > 0 ||
    filterParams.insurerIds.length > 0 ||
    filterParams.billingTypes.length > 0 ||
    filterParams.partnershipIds.length > 0 ||
    holdQuickFilters.some(
      f =>
        !filterParams.holdReasons.includes(f.value) &&
        filterParams.holdReasons.length !== holdTypes.length,
    ) ||
    (dateQuickFilters.every(
      dateQuickFilter =>
        dateQuickFilter.value.startDate !== filterParams.startDate,
    ) &&
      filterParams.startDate !== defaultBillHoldsGridFilterModel.startDate) ||
    (dateQuickFilters.every(
      dateQuickFilter => dateQuickFilter.value.endDate !== filterParams.endDate,
    ) &&
      filterParams.endDate !== defaultBillHoldsGridFilterModel.endDate);

  return (
    <HHStack direction="row" alignItems="center" spacing={2}>
      <HHChip
        key="all"
        clickable
        label="All"
        size="small"
        hhVariant={
          filterParams.holdReasons.length === holdTypes.length &&
          filterParams.startDate === null
            ? 'filled'
            : 'outlined'
        }
        color={
          filterParams.holdReasons.length === holdTypes.length &&
          filterParams.startDate === null
            ? 'neutral'
            : 'default'
        }
        onClick={(): void =>
          setFilterParams({
            ...filterParams,
            holdReasons: holdTypes,
            startDate: null,
            endDate: null,
          })
        }
      />
      {holdQuickFilters.map(filter => (
        <HHChip
          key={filter.displayName}
          clickable
          label={filter.displayName}
          size="small"
          hhVariant={
            filterParams.holdReasons.includes(filter.value) &&
            filterParams.holdReasons.length !== holdTypes.length
              ? 'filled'
              : 'outlined'
          }
          color={
            filterParams.holdReasons.includes(filter.value) &&
            filterParams.holdReasons.length !== holdTypes.length
              ? 'neutral'
              : 'default'
          }
          onClick={(): void => setQuickHoldFilterParams(filter.value)}
        />
      ))}
      {dateQuickFilters.map(filter => (
        <HHChip
          clickable
          key={filter.displayName}
          label={filter.displayName}
          size="small"
          hhVariant={
            dayjs(filterParams?.startDate).isSame(
              dayjs(filter.value.startDate),
              'day',
            )
              ? 'filled'
              : 'outlined'
          }
          color={
            dayjs(filterParams?.startDate).isSame(
              dayjs(filter.value.startDate),
              'day',
            )
              ? 'neutral'
              : 'default'
          }
          onClick={(): void => setQuickDateFilterParams(filter.value)}
        />
      ))}
      {customFiltersApplied ? (
        <HHChip
          key="custom-filters"
          clickable
          label="Custom filter applied"
          size="small"
          hhVariant="filled"
          color="neutral"
          onClick={filterToggle}
        />
      ) : null}
      <HHIconButton
        hhVariant="variant-bypass"
        size="medium"
        color="default"
        aria-label="filterToggle"
        onClick={filterToggle}
      >
        <FilterList />
      </HHIconButton>
    </HHStack>
  );
};
