import { HHIconButton } from '@hinge-health/react-component-library';
import { ContentCopyRounded } from '@mui/icons-material';

export const CopyToClipboard = (props: { data: string }): JSX.Element => {
  const { data } = props;
  const handleClick = (): void => {
    navigator.clipboard.writeText(data);
  };
  return (
    <HHIconButton
      hhVariant="variant-bypass"
      data-testid="copy-to-clipboard"
      centerRipple={true}
      onClick={handleClick}
      children={<ContentCopyRounded />}
    />
  );
};
