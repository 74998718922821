import {
  HHBox,
  HHDialog,
  HHDialogContent,
  HHDialogTitle,
  HHIconButton,
  HHStack,
} from '@hinge-health/react-component-library';
import { Close } from '@mui/icons-material';

interface HCModalProps {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  component: JSX.Element;
  sx?: Record<string, unknown>;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
}

export const HCModal = ({
  title,
  isOpen,
  handleClose,
  component,
  sx,
  maxWidth,
  fullWidth,
}: HCModalProps): JSX.Element => (
  <HHDialog
    open={isOpen}
    onClose={handleClose}
    maxWidth={maxWidth ?? 'lg'}
    fullWidth={fullWidth}
    sx={sx}
  >
    <HHBox padding="1rem">
      <HHStack
        direction="row"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <HHDialogTitle>{title}</HHDialogTitle>
        <HHIconButton
          size="small"
          color="primary"
          aria-label="close modal"
          hhVariant="variant-bypass"
          onClick={handleClose}
        >
          <Close />
        </HHIconButton>
      </HHStack>
      <HHDialogContent>{component}</HHDialogContent>
    </HHBox>
  </HHDialog>
);
