import { HHAlert, HHTypography } from '@hinge-health/react-component-library';
import { ReactNode } from 'react';

export interface ContractFormAlertProps {
  message: string | ReactNode;
}

const ContractFormAlert = ({
  message,
}: ContractFormAlertProps): JSX.Element => (
  <HHAlert
    hhVariant="variant-bypass"
    variant="outlined"
    severity="warning"
    sx={{ alignItems: 'center', marginBottom: '.5em' }}
    children={<HHTypography hhVariant="overline">{message}</HHTypography>}
  ></HHAlert>
);

export default ContractFormAlert;
