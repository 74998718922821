import { HHBox } from '@hinge-health/react-component-library';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AMT_HEADER } from '../constants/strings/application-management-tool';
import { AMT_THEME } from '../constants/theme';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      fontFamily: AMT_THEME.FONT_FAMILY,
    },
    title: {
      fontSize: 24,
      marginTop: 32,
    },
    subTitleContainer: {
      marginTop: 8,
      marginBottom: 32,
    },
    subTitle: {
      display: 'flex',
      fontSize: 16,
    },
    subTitleDue: {
      marginLeft: 24,
    },
  }),
);

export const ApplicationManagementToolBodyHeader = (): JSX.Element => {
  const classes = useStyles();

  return (
    <HHBox className={classes.container}>
      <HHBox className={classes.title}>{AMT_HEADER.HEADER.TITLE}</HHBox>
    </HHBox>
  );
};
