export const userTagsGridColumnHeaders = {
  id: 'ID',
  userId: 'User ID',
  createdAt: 'Created At',
  tagId: 'Tag ID',
  tagName: 'Tag Name',
  source: 'Source',
  resourceId: 'Resource ID',
  occurredAt: 'Occurred At',
};

export const userTagsGridFieldNames = {
  id: 'id',
  userId: 'userId',
  createdAt: 'createdAt',
  tagId: 'tagId',
  tagName: 'tagName',
  source: 'source',
  resourceId: 'resourceId',
  occurredAt: 'occurredAt',
};

export const userTagsButtonLabels = {
  search: 'Search',
};

export const userTagsInputLabels = {
  searchUserId: 'Search by User ID (UUID)',
  userId: 'User ID',
  createdAt: 'Created At',
  tagName: 'Tag Name',
  source: 'Source',
  resourceId: 'Resource ID',
  occurredAt: 'Occurred At',
};

export const userTagsInfoMessages = {
  noUserTags: 'No User Tags',
  oops: 'Oops! Something Went Wrong',
  trySearchingAgain: 'Try searching again later',
  trySearching: 'Try searching with a user ID in the search box above',
};

export const userTagTitles = {
  createUserTag: 'Create User Tag',
};

export const resourceDetailLabels = {
  ...userTagsGridColumnHeaders,
  resourceId: 'Resource ID',
  data: 'Data',
  title: 'Resource Details',
  na: 'N/A',
};

export const resourceInfoMessages = {
  error: (resourceId: string): string =>
    `No health record found for ID: ${resourceId}`,
};
