import { AppBar, HHTypography } from '@hinge-health/react-component-library';
import Stack from '@mui/material/Stack';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Route, Routes } from 'react-router-dom';
import { routes } from '../../constants/strings/routes';
import BillingBatchContractHeader from './billing-tool-batch-create-header';
import ClientHeader from './client-header';

const useStyles = makeStyles(() =>
  createStyles({
    billingAppBar: {
      flexDirection: 'row',
    },
  }),
);
export const BILLING_TOOL_APP_BAR = 'billing-tool-app-tool-bar';

const UserHeader = (): JSX.Element => (
  <Stack alignItems="center" margin="1em" flex={1}>
    <HHTypography hhVariant="section-title">User Billing Journey</HHTypography>
  </Stack>
);

const BillingToolHeader = (): JSX.Element => {
  const classes = useStyles();

  return (
    <AppBar
      elevation={3}
      variant="elevation"
      color="inherit"
      position="sticky"
      className={classes.billingAppBar}
      data-testid={BILLING_TOOL_APP_BAR}
    >
      <Routes>
        <Route path={routes.billing.clientInsurerPath}>
          <Route index element={null} />
          <Route path={routes.billing.clientInsurerId} />
          <Route path={routes.billing.fullNewContractPath} />
          <Route path={routes.billing.fullEditContractPath} />
          <Route
            path={routes.billing.batchContractCreate}
            element={<BillingBatchContractHeader />}
          />
          <Route
            path={routes.billing.fullBatchContractCreateStatus}
            element={<BillingBatchContractHeader suppressHeader={true} />}
          />
        </Route>
        <Route path={routes.billing.clientPath}>
          <Route path={routes.billing.clientId} element={<ClientHeader />} />
        </Route>
        <Route path={routes.billing.userPath}>
          <Route path={routes.billing.userId} element={<UserHeader />} />
        </Route>
        {/* I think we can move toward deprecating these.. */}
        <Route path={routes.billing.billsHolds} element={null} />
        <Route path={routes.billing.discountCalculator} element={null} />
        <Route path="*" element={null} />
      </Routes>
    </AppBar>
  );
};
export default BillingToolHeader;
