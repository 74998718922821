import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BillableActivityArrayOrObject: any;
  JSON: any;
  JSONObject: any;
};

export type ActionDefinition = {
  __typename?: 'ActionDefinition';
  actions?: Maybe<Array<Scalars['JSONObject']>>;
  metadata?: Maybe<Scalars['JSONObject']>;
  type: Scalars['String'];
};

export type ActionDefinitionInput = {
  actions?: Maybe<Array<Scalars['JSONObject']>>;
  metadata?: Maybe<Scalars['JSONObject']>;
  type: Scalars['String'];
};

export type ActionResult = {
  __typename?: 'ActionResult';
  log?: Maybe<Scalars['String']>;
  output?: Maybe<Scalars['JSON']>;
  status: ActionStatus;
  type: Scalars['String'];
};

export enum ActionStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export type Admin = {
  __typename?: 'Admin';
  /** The url for the admin's avatar */
  avatarUrl: Scalars['String'];
  /** The email address for the admin */
  email: Scalars['String'];
  /** The first name of the admin */
  firstName: Scalars['String'];
  /** The id of the admin */
  id: Scalars['ID'];
  /** The last name of the admin */
  lastName: Scalars['String'];
  /** The role of the admin */
  role: Scalars['String'];
  /** The uuid of the admin */
  uuid: Scalars['ID'];
};

export type AssessedRule = {
  __typename?: 'AssessedRule';
  actionLog?: Maybe<Array<ActionResult>>;
  assertionLog?: Maybe<Scalars['JSON']>;
  status: AssessmentStatus;
};

export type AssessmentResult = {
  __typename?: 'AssessmentResult';
  actionsExecuted: Scalars['Float'];
  assertionPassed: Scalars['Float'];
  completed: Scalars['Float'];
  createdAt: Scalars['String'];
  errored: Scalars['Float'];
  id: Scalars['String'];
  ruleAssessmentResultId?: Maybe<Scalars['String']>;
};

export enum AssessmentStatus {
  ActionsExecuted = 'ACTIONS_EXECUTED',
  AssertionPassed = 'ASSERTION_PASSED',
  Completed = 'COMPLETED',
  Errored = 'ERRORED',
  TagCreated = 'TAG_CREATED'
}

export type AssessmentTask = {
  __typename?: 'AssessmentTask';
  actionsExecuted: Scalars['Float'];
  arguments: Scalars['JSON'];
  assertionPassed: Scalars['Float'];
  assessmentResults: Array<AssessmentResult>;
  completed: Scalars['Float'];
  createdAt: Scalars['String'];
  errored: Scalars['Float'];
  id: Scalars['ID'];
  remaining: Scalars['Float'];
  status: Scalars['String'];
  totalAssessments: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export type AssessmentTaskList = {
  __typename?: 'AssessmentTaskList';
  assessmentTasks: Array<AssessmentTaskListItem>;
  limit: Scalars['Float'];
  total: Scalars['Float'];
};

export type AssessmentTaskListItem = {
  __typename?: 'AssessmentTaskListItem';
  actionsExecuted: Scalars['Float'];
  arguments: Scalars['JSON'];
  assertionPassed: Scalars['Float'];
  completed: Scalars['Float'];
  createdAt: Scalars['String'];
  errored: Scalars['Float'];
  id: Scalars['ID'];
  remaining: Scalars['Float'];
  status: Scalars['String'];
  totalAssessments: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export type Association = {
  __typename?: 'Association';
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
  typeId: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type BaseUser = {
  __typename?: 'BaseUser';
  /** The member's bio */
  bio?: Maybe<Scalars['String']>;
  /** The email address for the member */
  email: Scalars['String'];
  /** The first name of the member */
  firstName: Scalars['String'];
  /** The ID of the member */
  id: Scalars['ID'];
  /** The last name of the member */
  lastName: Scalars['String'];
  /** Program indications from the pathway */
  pathwayProgramIndications?: Maybe<Array<Scalars['String']>>;
  /** The phone number for the member */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The postal code of the member */
  postalCode?: Maybe<Scalars['String']>;
  /** The state of residence for the member */
  stateOfResidence?: Maybe<Scalars['String']>;
  /** The UUID of the member */
  uuid: Scalars['ID'];
};

export type BatchClientInsurerType = {
  __typename?: 'BatchClientInsurerType';
  billingType?: Maybe<Scalars['String']>;
  client: ClientType;
  id: Scalars['Int'];
  insurer: BatchInsurerType;
  isActive: Scalars['Boolean'];
  partnerships: Array<PartnershipType>;
  termDate?: Maybe<Scalars['String']>;
};

export type BatchContractClientInsurerInput = {
  /** Id of the client */
  clientId: Scalars['Float'];
  /** Id of the client insurer */
  id: Scalars['Float'];
  /** Id of the insurer */
  insurerId: Scalars['Float'];
};

export type BatchContractResponse = {
  __typename?: 'BatchContractResponse';
  /** Id of the client insurer */
  clientInsurerId: Scalars['Int'];
  /** Error that happened, if no error will be empty string */
  error: Scalars['String'];
  /** New Contract details */
  newContract?: Maybe<ContractType>;
  /** Old Contract details */
  oldContract?: Maybe<ContractType>;
  /** boolean if request was sucess or not */
  success: Scalars['Boolean'];
};

export type BatchInsurerType = {
  __typename?: 'BatchInsurerType';
  eligibilityRef: Scalars['String'];
  /** The id of the InsurerType item */
  id: Scalars['Int'];
  /** The identifier of the InsurerType item */
  identifier: Scalars['String'];
  /** The name of the InsurerType item */
  name: Scalars['String'];
};

export enum BillStatusEnum {
  Archived = 'ARCHIVED',
  NonSubmittable = 'NON_SUBMITTABLE',
  Submittable = 'SUBMITTABLE',
  SubmittableWithBypass = 'SUBMITTABLE_WITH_BYPASS'
}

export type BillSubmissionsType = {
  __typename?: 'BillSubmissionsType';
  baseCharge: Scalars['Float'];
  billAmount: Scalars['Float'];
  billingMonth: Scalars['String'];
  claimIdentifier: Scalars['String'];
  clientsInsurerId: Scalars['Float'];
  coverageId?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  fromDate: Scalars['String'];
  id: Scalars['Float'];
  internalHolds: Array<InternalHoldBaseType>;
  pathwayId: Scalars['Float'];
  paymentType: Scalars['String'];
  source: Scalars['String'];
  sourceId: Scalars['Float'];
  submissions: Array<SubmissionRemitsType>;
  subscriptionId: Scalars['Float'];
  toDate: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type BillableActivityType = {
  __typename?: 'BillableActivityType';
  /** The created date time value of the EngagementType item */
  createdAt: Scalars['String'];
  /** details of billable activity */
  details?: Maybe<Scalars['JSONObject']>;
  /** The id of the EngagementType item */
  id: Scalars['Int'];
  /** Active status of the item */
  isActive: Scalars['Boolean'];
  /** The name of the EngagementType item */
  name: Scalars['String'];
  /** The updated date time value of the EngagementType item */
  updatedAt: Scalars['String'];
};

export type BillingModelType = {
  __typename?: 'BillingModelType';
  createdAt: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum BillingTypes {
  BcbsalInvoiceAuto = 'BCBSAL_INVOICE_AUTO',
  BcbsalInvoiceManual = 'BCBSAL_INVOICE_MANUAL',
  BcbsalInvoiceOff = 'BCBSAL_INVOICE_OFF',
  BcbsmaInvoiceAuto = 'BCBSMA_INVOICE_AUTO',
  BcbsmaInvoiceManual = 'BCBSMA_INVOICE_MANUAL',
  BcbsmaInvoiceOff = 'BCBSMA_INVOICE_OFF',
  BcbsmiWaystarClaimsAuto = 'BCBSMI_WAYSTAR_CLAIMS_AUTO',
  BcbsmiWaystarClaimsManual = 'BCBSMI_WAYSTAR_CLAIMS_MANUAL',
  BcbsmiWaystarClaimsOff = 'BCBSMI_WAYSTAR_CLAIMS_OFF',
  CvsInvoiceAuto = 'CVS_INVOICE_AUTO',
  CvsInvoiceManual = 'CVS_INVOICE_MANUAL',
  CvsInvoiceOff = 'CVS_INVOICE_OFF',
  EsiInvoiceAuto = 'ESI_INVOICE_AUTO',
  EsiInvoiceManual = 'ESI_INVOICE_MANUAL',
  EsiInvoiceOff = 'ESI_INVOICE_OFF',
  InvoiceAuto = 'INVOICE_AUTO',
  InvoiceManual = 'INVOICE_MANUAL',
  InvoiceOff = 'INVOICE_OFF',
  NetsuiteInvoiceAuto = 'NETSUITE_INVOICE_AUTO',
  WaystarClaimsAuto = 'WAYSTAR_CLAIMS_AUTO',
  WaystarClaimsManual = 'WAYSTAR_CLAIMS_MANUAL',
  WaystarClaimsOff = 'WAYSTAR_CLAIMS_OFF'
}

export type BillsPaginatedType = {
  __typename?: 'BillsPaginatedType';
  items: Array<PaginatedBillType>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  size: Scalars['Float'];
  total: Scalars['Float'];
};

export type BulkUpdateBillsInput = {
  billIds: Array<Scalars['Float']>;
  clientInsurerId?: Maybe<Scalars['Float']>;
  note: Scalars['String'];
  statusChange: BillStatusEnum;
};

export type BulkUpdateBillsResponse = {
  __typename?: 'BulkUpdateBillsResponse';
  details: Scalars['String'];
};

export type ClearHoldsInput = {
  billIds: Array<Scalars['Float']>;
  holdTypes?: Array<ClearableHoldTypes>;
};

export type ClearHoldsResponse = {
  __typename?: 'ClearHoldsResponse';
  details: Scalars['String'];
};

export enum ClearableHoldTypes {
  ManualHold = 'MANUAL_HOLD'
}

export type ClientInsurerByClientType = {
  __typename?: 'ClientInsurerByClientType';
  clientsInsurers: Array<ClientInsurerDetailsType>;
  /** The id of the ClientType item */
  id: Scalars['Int'];
  /** The identifier of the ClientType item */
  identifier: Scalars['String'];
  /** The name of the ClientType item */
  name: Scalars['String'];
};

export type ClientInsurerDetailsType = {
  __typename?: 'ClientInsurerDetailsType';
  billingType?: Maybe<Scalars['String']>;
  client: ClientType;
  id: Scalars['Int'];
  insurer: InsurerBasicType;
  isActive: Scalars['Boolean'];
  partnerships: Array<PartnershipType>;
  termDate?: Maybe<Scalars['String']>;
};

export type ClientInsurerType = {
  __typename?: 'ClientInsurerType';
  acuteBasePrice: Scalars['Float'];
  acuteStartDate?: Maybe<Scalars['String']>;
  apiCode?: Maybe<Scalars['String']>;
  apiOn: Scalars['Boolean'];
  apiRequired: Scalars['Boolean'];
  athena: Scalars['Boolean'];
  autoEligibilityEnabled: Scalars['Boolean'];
  billingType?: Maybe<Scalars['String']>;
  chronicBasePrice: Scalars['Float'];
  client: ClientType;
  coreContractRule: Scalars['String'];
  disableEligibilityAlerts?: Maybe<Scalars['Boolean']>;
  efileRequired: Scalars['Boolean'];
  groupNumbers: Array<GroupNumberType>;
  id: Scalars['Int'];
  ignorePrimary: Scalars['Boolean'];
  insurer: InsurerType;
  isActive: Scalars['Boolean'];
  partnerships: Array<PartnershipType>;
  primaryRequired: Scalars['Boolean'];
  programExceptions: Array<ProgramExceptionsType>;
  termDate?: Maybe<Scalars['String']>;
  validGroupNumbers: Array<ValidGroupNumbersType>;
};

export type ClientType = {
  __typename?: 'ClientType';
  /** The id of the ClientType item */
  id: Scalars['Int'];
  /** The identifier of the ClientType item */
  identifier: Scalars['String'];
  /** The name of the ClientType item */
  name: Scalars['String'];
};

export type ConsultResultType = {
  __typename?: 'ConsultResultType';
  consultMessage?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  hhUuid: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type ContractPackagesInput = {
  /** Engagement type values to be set as billable activities */
  billableActivityTypes?: Maybe<Scalars['BillableActivityArrayOrObject']>;
  billingEnabled?: Maybe<Scalars['Boolean']>;
  billingModelId?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['Float']>;
  contractTemplateId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  packageId: Scalars['Float'];
  price?: Maybe<Scalars['Float']>;
  rules?: Maybe<PackageRulesInput>;
  startDate?: Maybe<Scalars['String']>;
  void?: Maybe<Scalars['Boolean']>;
};

export type ContractPackagesType = {
  __typename?: 'ContractPackagesType';
  billableActivityTypes: Array<BillableActivityType>;
  billingEnabled: Scalars['Boolean'];
  billingModel: BillingModelType;
  billingModelId: Scalars['Int'];
  contractId: Scalars['Int'];
  contractTemplateId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  disabledProgramIndications: Array<DisabledProgramIndication>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastModifiedBy: Scalars['String'];
  package: PackageType;
  packageId: Scalars['Int'];
  price: Scalars['Float'];
  rules?: Maybe<RulesType>;
  startDate?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  void: Scalars['Boolean'];
};

export type ContractSubscriptionType = {
  __typename?: 'ContractSubscriptionType';
  /** bills of the ContractSubscriptionType item */
  billCount: Scalars['Float'];
  /** fee for service event count of the ContractSubscriptionType item */
  feeForServiceEventCount: Scalars['Float'];
  /** submissions of the ContractSubscriptionType item */
  submissionCount: Scalars['Float'];
  /** subscription count of the ContractSubscriptionType item */
  subscriptionCount: Scalars['Float'];
  /** total discounts of the ContractSubscriptionType item */
  totalDiscountCount: Scalars['Float'];
  /** subscription discounts of the ContractSubscriptionType item */
  voidCount: Scalars['Float'];
};

export type ContractTemplateType = {
  __typename?: 'ContractTemplateType';
  /** The contract value of the ContractType item */
  contract: Array<ContractTypeUnion>;
  contractType?: Maybe<Scalars['String']>;
  /** The created date time value of the EngagementType item */
  createdAt: Scalars['String'];
  /** The id of the ContractTemplateType item */
  id: Scalars['Int'];
  /** Active status of the ContractType item */
  isActive: Scalars['Boolean'];
  /** Name of the ContractType item */
  name: Scalars['String'];
  /** Partnership id of the ContractType item */
  partnershipId?: Maybe<Scalars['Float']>;
  /** The updated date time value of the EngagementType item */
  updatedAt: Scalars['String'];
};

export type ContractType = {
  __typename?: 'ContractType';
  /** The acute price of the ContractType item */
  acutePrice?: Maybe<Scalars['Float']>;
  /** The collection of billable activity types for the ContractType item */
  billableActivityTypes: Array<BillableActivityType>;
  /** The chronic price of the ContractType item */
  chronicPrice?: Maybe<Scalars['Float']>;
  /** The client id of the ContractType item */
  clientId: Scalars['Float'];
  /** The client insurer id of the ContractType item */
  clientsInsurerId: Scalars['Float'];
  /** The contract value of the ContractType item */
  contract?: Maybe<Array<ContractTypeUnion>>;
  contractPackages: Array<ContractPackagesType>;
  /** The contract template id of the ContractType item */
  contractTemplateId?: Maybe<Scalars['Float']>;
  contractType?: Maybe<Scalars['String']>;
  /** The created date time value of the EngagementType item */
  createdAt: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  /** The collection of disabled program indications for the ContractType item */
  disabledProgramIndication: Array<DisabledProgramIndication>;
  /** The updated date time value of the ContractType item */
  endDate?: Maybe<Scalars['String']>;
  /** The id of the ContractType item */
  id: Scalars['Int'];
  /** The insurer id of the ContractType item */
  insurerId: Scalars['Float'];
  ironcladId?: Maybe<Scalars['String']>;
  packageEnabled: Scalars['Boolean'];
  partnershipId?: Maybe<Scalars['Float']>;
  /** The collection of procedure codes for the ContractType item */
  procedureCodes: Array<ProcedureCodesType>;
  /** The start date of the ContractType item */
  startDate: Scalars['String'];
  /** The updated date time value of the EngagementType item */
  updatedAt: Scalars['String'];
  userAnnualCap?: Maybe<Scalars['Float']>;
  /** The void value of the ContractType item */
  void: Scalars['Boolean'];
};

export type ContractTypeUnion = EngagementContractType | IndividualMilestonesType | MilestonesType;

export type CptCodes = {
  __typename?: 'CptCodes';
  /** The list of Acute CPT codes for a member client insurer */
  acuteCptCodes?: Maybe<Array<Scalars['String']>>;
};

export type CreateAssessmentTaskInput = {
  /** The end date used when selecting resources to run for this task */
  endDate?: Maybe<Scalars['String']>;
  /** The limit of assessment tasks to run. */
  limit?: Maybe<Scalars['Float']>;
  /** Resource type of resources to assess */
  resourceType?: Maybe<Scalars['String']>;
  /** Specific rule to be evaluated */
  ruleId?: Maybe<Scalars['String']>;
  /** Source of resources to assess */
  source: Scalars['String'];
  /** The start date used when selecting resources to run for this task */
  startDate?: Maybe<Scalars['String']>;
};

export type CreateAssociationInput = {
  /** The association type */
  type: Scalars['String'];
  /** The association type ID */
  typeId: Scalars['String'];
};

export type CreateBatchContractInput = {
  /** The acute price of the ContractType item */
  acutePrice: Scalars['Float'];
  billableActivityTypes: Scalars['BillableActivityArrayOrObject'];
  /** The chronic price of the ContractType item */
  chronicPrice: Scalars['Float'];
  /** List of client insurers for batch contract create */
  clientInsurers: Array<BatchContractClientInsurerInput>;
  /** Contract packages to be saved */
  contractPackages?: Maybe<Array<ContractPackagesInput>>;
  /** The contract template id of the ContractType item */
  contractTemplateId: Scalars['Float'];
  /** disabled program indications as string to be set as disabled program indications */
  disabledProgramIndication?: Maybe<Array<Scalars['String']>>;
  /** The updated date time value of the ContractType item */
  endDate?: Maybe<Scalars['String']>;
  /** The start date of the ContractType item */
  startDate: Scalars['String'];
};

export type CreateContractInput = {
  /** The acute price of the ContractType item */
  acutePrice?: Maybe<Scalars['Float']>;
  billableActivityTypes?: Maybe<Scalars['BillableActivityArrayOrObject']>;
  /** The chronic price of the ContractType item */
  chronicPrice?: Maybe<Scalars['Float']>;
  /** The client id of the ContractType item */
  clientId: Scalars['Float'];
  /** The client insurer id of the ContractType item */
  clientsInsurerId: Scalars['Float'];
  /** The contract value of the ContractType item */
  contract?: Maybe<Array<Scalars['JSONObject']>>;
  /** Contract packages to be saved */
  contractPackages?: Maybe<Array<ContractPackagesInput>>;
  /** The contract template id of the ContractType item */
  contractTemplateId?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  /** disabled program indications as string to be set as disabled program indications */
  disabledProgramIndication?: Maybe<Array<Scalars['String']>>;
  /** The updated date time value of the ContractType item */
  endDate?: Maybe<Scalars['String']>;
  /** The insurer id of the ContractType item */
  insurerId: Scalars['Float'];
  ironcladId?: Maybe<Scalars['String']>;
  packageEnabled?: Maybe<Scalars['Boolean']>;
  partnershipId?: Maybe<Scalars['Float']>;
  /** Procedure codes to be saved */
  procedureCodes: Array<ProcedureCodeInput>;
  /** The start date of the ContractType item */
  startDate: Scalars['String'];
  userAnnualCap?: Maybe<Scalars['Float']>;
};

export type CreateDiscountInput = {
  clientId: Scalars['Float'];
  discount: Scalars['Float'];
  discountType: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['Float']>;
  programId?: Maybe<Scalars['Float']>;
  startDate: Scalars['String'];
  suppressEmptyCharges: Scalars['Boolean'];
};

export type CreateFacetInput = {
  /** Facet associations */
  associations?: Maybe<Array<CreateAssociationInput>>;
  /** The facet description */
  description: Scalars['String'];
  /** The facet name */
  name: Scalars['String'];
  /** The facet value */
  value: Scalars['String'];
};

export type CreateRecommendationInput = {
  /** Create contract recommendation with doc id */
  docId: Scalars['String'];
};

export type CreateRuleAssessmentInput = {
  resource: Scalars['String'];
  resourceId: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  ruleIds?: Maybe<Array<Scalars['String']>>;
  source: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateRuleInput = {
  /** List of rule actions */
  actions?: Maybe<Array<ActionDefinitionInput>>;
  /** The rule definition */
  definition: RuleDefinitionInput;
  /** Supported resource types for rule */
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  /** Source of resources to be evaluated by this rule */
  source: Scalars['String'];
};

export type CreateTagInput = {
  /** Description of the tag */
  description: Scalars['String'];
  /** Name for the tag in kebab case slug syntax. Must be all lowercase and dash separated. */
  name: Scalars['String'];
};

export type CreateUserTagInput = {
  /** ISO 8601 timestamp */
  occurredAt: Scalars['String'];
  /** Resource associated with this userTag */
  resourceId: Scalars['String'];
  /** Source of resource */
  source: Scalars['String'];
  /** Name of the tag being applied to the user */
  tagName: Scalars['String'];
  /** Unique identifier of the user */
  userId: Scalars['String'];
};

export type CurrencyCodeType = {
  __typename?: 'CurrencyCodeType';
  value: Scalars['String'];
};

export type CvsResponseType = {
  __typename?: 'CvsResponseType';
  errors?: Maybe<Scalars['JSONObject']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  upstreamResponseContent: Scalars['JSONObject'];
};

export type DagRunRequestInput = {
  conf: Scalars['JSONObject'];
  note?: Maybe<Scalars['String']>;
};

export type DagRunResponseType = {
  __typename?: 'DagRunResponseType';
  dagId: Scalars['String'];
  dagRunId: Scalars['String'];
  logicalDate: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

export type DisabledProgramIndication = {
  __typename?: 'DisabledProgramIndication';
  contractId?: Maybe<Scalars['Float']>;
  contractPackageId?: Maybe<Scalars['Float']>;
  /** The id of the DisabledProgramIndication item */
  id: Scalars['Int'];
  /** The identifier name of the DisabledProgramIndication item */
  programIndicationIdentifier: Scalars['String'];
};

export type DisabledProgramIndicationInput = {
  contractId?: Maybe<Scalars['Float']>;
  contractPackageId?: Maybe<Scalars['Float']>;
  programIndicationIdentifiers: Array<Scalars['String']>;
};

export type DiscountDetail = {
  __typename?: 'DiscountDetail';
  clientId?: Maybe<Scalars['Int']>;
  discount: Scalars['Int'];
  discountDefinitionId: Scalars['Int'];
  discountDetailId: Scalars['Int'];
  discountType: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  insurerId?: Maybe<Scalars['Int']>;
  milestoneDiscount?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['Float']>;
  partnershipId?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['Float']>;
  startDate: Scalars['String'];
  subscriptionTier: Array<Maybe<Scalars['Int']>>;
  suppressEmptyCharges: Scalars['Boolean'];
  tier: Scalars['Int'];
};

export type DiscountUsage = {
  __typename?: 'DiscountUsage';
  discountDetailId?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
  discountSum?: Maybe<Scalars['Float']>;
  totalCount: Scalars['Float'];
};

export type EngagementBaseType = {
  __typename?: 'EngagementBaseType';
  billableCount: Scalars['Float'];
  createdAt: Scalars['String'];
  details: Scalars['JSONObject'];
  eventId?: Maybe<Scalars['Float']>;
  eventType: Scalars['String'];
  eventUuid?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  occurredAt: Scalars['String'];
  programId: Scalars['Float'];
  programIndication: Scalars['String'];
  queuedAt: Scalars['String'];
  sourceType: Scalars['String'];
  userUuid: Scalars['String'];
};

export type EngagementContractType = {
  __typename?: 'EngagementContractType';
  /** The activity types of the EngagementContractType item */
  activityTypes: Array<Scalars['String']>;
  /** The educational types of the EngagementContractType item */
  educationalTypes: Array<Scalars['String']>;
  /** The different engagement rules based on EngagementConditionType of the EngagementContractType item */
  engagementRules: Array<Scalars['JSONObject']>;
  /** The match type of the EngagementContractType item */
  matchType: Scalars['String'];
};

export type EngagementType = {
  __typename?: 'EngagementType';
  /** The created date time value of the EngagementType item */
  createdAt: Scalars['String'];
  /** The id of the EngagementType item */
  id: Scalars['Int'];
  /** The name of the EngagementType item */
  name: Scalars['String'];
  /** The updated date time value of the EngagementType item */
  updatedAt: Scalars['String'];
};

export type EngagementsPaginationType = {
  __typename?: 'EngagementsPaginationType';
  items: Array<EngagementBaseType>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  size: Scalars['Float'];
  total: Scalars['Float'];
};

export type EnrollUsersInput = {
  /** List of user uuids to enroll in a targeted interventions workflow */
  patientUuids: Array<Scalars['ID']>;
  /** Type of workflow */
  workflowType: Scalars['String'];
};

export type EnrollUsersModel = {
  __typename?: 'EnrollUsersModel';
  /** The list of user uuids that have been enrolled */
  enrolledUuids: Array<Scalars['String']>;
  /** The list of user uuids that could not be enrolled due to invalidity or an error */
  failedUuids: Array<Scalars['String']>;
};

export type Facet = {
  __typename?: 'Facet';
  associations?: Maybe<Array<Association>>;
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
  value: Scalars['String'];
};

export type FacetsList = {
  __typename?: 'FacetsList';
  count: Scalars['Float'];
  facets: Array<Facet>;
};

export type FileConfigurationInput = {
  /** The sftp bucket that the file was dropped into */
  bucket: Scalars['String'];
  /** The column mappings for the file */
  columnMappings: Scalars['JSONObject'];
  /** The description of the file configuration */
  description: Scalars['String'];
  /** Should the file encoding be detected */
  encodingDetection: Scalars['Boolean'];
  /** The entity tags of the file */
  entityTags: Scalars['JSONObject'];
  /** The file operations to be performed on the file */
  fileOperations?: Maybe<Array<Scalars['JSONObject']>>;
  /** The group operations to be performed on the file */
  groupOperations?: Maybe<Array<Scalars['JSONObject']>>;
  /** The individual file operations */
  individualOperations: Array<Scalars['JSONObject']>;
  /** The name of the file configuration */
  name: Scalars['String'];
  /** The file parser */
  parser: Scalars['JSONObject'];
  /** Is the file pgp encrypted */
  pgpEncrypted?: Maybe<Scalars['Boolean']>;
  /** The provider of the file */
  provider: Scalars['String'];
  /** The regex to match the file name */
  regex: Scalars['String'];
};

export type GetCvsEligibilityInput = {
  /** The code of the client */
  clientCode: Scalars['String'];
  /** The date of birth of the member */
  dateOfBirth: Scalars['String'];
  /** The external id of the member */
  externalId?: Maybe<Scalars['String']>;
  /** The first name of the member */
  firstName: Scalars['String'];
  /** The gender of the member */
  gender: Scalars['String'];
  /** The date of inquiry of the member */
  inquiryDate?: Maybe<Scalars['String']>;
  /** The last name of the member */
  lastName: Scalars['String'];
  /** The postal code of the member */
  postalCode?: Maybe<Scalars['String']>;
  /** The user id of the member */
  userId?: Maybe<Scalars['Float']>;
};

export type GetFileConfigurationFrontendResponse = {
  __typename?: 'GetFileConfigurationFrontendResponse';
  bucket: Scalars['String'];
  columnMappings: Scalars['JSONObject'];
  description: Scalars['String'];
  encodingDetection: Scalars['Boolean'];
  entityTags: Scalars['JSONObject'];
  fileOperations: Array<Scalars['JSONObject']>;
  groupOperations: Array<Scalars['JSONObject']>;
  individualOperations: Array<Scalars['JSONObject']>;
  name: Scalars['String'];
  parser: Scalars['JSONObject'];
  provider: Scalars['String'];
  regex: Scalars['String'];
};

export type GetFileConfigurationsResponseType = {
  __typename?: 'GetFileConfigurationsResponseType';
  items: Array<Scalars['JSONObject']>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  size: Scalars['Float'];
  total: Scalars['Float'];
};

export type GetIndividualOperationsInput = {
  /** The name of the individual operation to get. */
  name?: Maybe<Scalars['String']>;
};

export type GroupNumberType = {
  __typename?: 'GroupNumberType';
  /** The name of the GroupNumbersType item */
  eligible: Scalars['Boolean'];
  /** The identifier of the GroupNumbersType item */
  flagType: Scalars['String'];
  /** The id of the GroupNumbersType item */
  groupNumber: Scalars['String'];
};

export type HealthRecord = {
  __typename?: 'HealthRecord';
  createdAt: Scalars['String'];
  data: Scalars['JSONObject'];
  id: Scalars['ID'];
  origin?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  resourceType: Scalars['String'];
  source: Scalars['String'];
  userId: Scalars['String'];
};

export type HealthRecordLinks = {
  __typename?: 'HealthRecordLinks';
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
};

export type HealthRecordMetadata = {
  __typename?: 'HealthRecordMetadata';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  origin?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  resourceType: Scalars['String'];
  source: Scalars['String'];
  userId: Scalars['String'];
};

export type HealthRecordsList = {
  __typename?: 'HealthRecordsList';
  count: Scalars['Float'];
  healthRecords: Array<HealthRecordMetadata>;
  links: HealthRecordLinks;
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
};

export type HoldDetailsType = {
  __typename?: 'HoldDetailsType';
  clientId?: Maybe<Scalars['Float']>;
  holdType: Scalars['String'];
  insurerId?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
};

export type IndividualMilestonesType = {
  __typename?: 'IndividualMilestonesType';
  /** The billable activity types of the IndividualMilestonesType item */
  billableActivityTypes: Array<Scalars['String']>;
  /** The different milestone conditions of the IndividualMilestonesType item */
  conditions: Array<MilestoneConditionType>;
  /** The name of the IndividualMilestonesType item */
  name: Scalars['String'];
  /** The id of the IndividualMilestonesType item */
  payment: Scalars['Float'];
};

export type InsurerBasicType = {
  __typename?: 'InsurerBasicType';
  /** The name of the InsurerType item */
  eligibilityRef: Scalars['String'];
  /** The id of the InsurerType item */
  id: Scalars['Int'];
  /** The identifier of the InsurerType item */
  identifier: Scalars['String'];
  /** The name of the InsurerType item */
  name: Scalars['String'];
};

export type InsurerType = {
  __typename?: 'InsurerType';
  /** The name of the InsurerType item */
  eligibilityRef: Scalars['String'];
  /** The id of the InsurerType item */
  id: Scalars['Int'];
  /** The identifier of the InsurerType item */
  identifier: Scalars['String'];
  /** The name of the InsurerType item */
  memberIdPrefix?: Maybe<Scalars['String']>;
  /** The name of the InsurerType item */
  memberIdRequired: Scalars['Boolean'];
  /** The name of the InsurerType item */
  name: Scalars['String'];
};

export type InternalHoldBaseType = {
  __typename?: 'InternalHoldBaseType';
  billId: Scalars['Float'];
  clearedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  holdType: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type InternalHoldType = {
  __typename?: 'InternalHoldType';
  value: Scalars['String'];
};

export type IronCladFields = {
  __typename?: 'IronCladFields';
  acuteCharge?: Maybe<Scalars['Float']>;
  billableActivityTypes: Array<Scalars['String']>;
  chronicCoreCharge?: Maybe<Scalars['Float']>;
  contractType: Scalars['String'];
  counterpartyName: Scalars['String'];
  paymentStructure: Scalars['String'];
  salesforceAccountId: Scalars['String'];
  salesforceOpportunityId: Scalars['String'];
  sowEffectiveDate?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type IroncladPdf = {
  __typename?: 'IroncladPdf';
  docId: Scalars['String'];
  signedPdf?: Maybe<Scalars['String']>;
};

export type LlmResponse = {
  __typename?: 'LLMResponse';
  acutePrice?: Maybe<Scalars['Float']>;
  billableActivityTypes: Array<Scalars['String']>;
  chronicPrice?: Maybe<Scalars['Float']>;
  contract?: Maybe<Array<MilestonesType>>;
  contractTemplateId?: Maybe<Scalars['Float']>;
};

export type ListAssessmentTasksInput = {
  /** The end date used when selecting resources to run for this task */
  endDate?: Maybe<Scalars['String']>;
  /** The limit of assessment tasks to run. */
  limit?: Maybe<Scalars['Float']>;
  /** The start date used when selecting resources to run for this task */
  startDate?: Maybe<Scalars['String']>;
};

export type ListFacetsInput = {
  /** The facet name */
  name?: Maybe<Scalars['String']>;
  /** The type of association to filter by */
  type?: Maybe<Scalars['String']>;
  /** The ID of the associated record to filter by */
  typeId?: Maybe<Scalars['String']>;
};

export type ListHealthRecordsInput = {
  endDate?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
};

export type ListRuleAssessmentsInput = {
  limit?: Maybe<Scalars['Float']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type ListRulesInput = {
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<RuleStatus>;
};

export type ListTagsInput = {
  deleted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ListUserTagsInput = {
  /** Facet name */
  facetName?: Maybe<Scalars['String']>;
  /** ISO 8601 date string */
  occurredFrom?: Maybe<Scalars['String']>;
  /** ISO 8601 date string */
  occurredTo?: Maybe<Scalars['String']>;
  /** Resource ID */
  resourceId?: Maybe<Scalars['String']>;
  /** User ID */
  user?: Maybe<Scalars['String']>;
};

export type MappingType = {
  __typename?: 'MappingType';
  options: Array<Scalars['JSONObject']>;
  values: Scalars['JSONObject'];
};

export type MilestoneConditionType = {
  __typename?: 'MilestoneConditionType';
  /** The function inside the DAG to be called of the MilestoneConditionType item */
  function: Scalars['String'];
  /** The threshold to be passed to the function as an argument of the MilestoneConditionType item */
  threshold?: Maybe<Scalars['Float']>;
};

export type MilestonesType = {
  __typename?: 'MilestonesType';
  /** The billable activity types of the MilestonesType item */
  billableActivityTypes: Array<Scalars['String']>;
  /** The different milestone conditions of the MilestonesType item */
  conditions: Array<MilestoneConditionType>;
  /** The date of service offset in days of the MilestonesType item */
  dateOfServiceOffset: Scalars['Float'];
  /** The day range of the MilestonesType item */
  dayRange: Array<Scalars['Float']>;
  /** The name of the MilestonesType item */
  name: Scalars['String'];
  /** The payment of the MilestonesType item */
  payment: Scalars['Float'];
};

export type ModuleType = {
  __typename?: 'ModuleType';
  description: Scalars['String'];
  id: Scalars['Float'];
  identifier: Scalars['String'];
  name: Scalars['String'];
  schema: Scalars['JSONObject'];
  version: Scalars['Float'];
};

export type ModulesResponse = {
  __typename?: 'ModulesResponse';
  items: Array<ModuleType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkUpdateBills: BulkUpdateBillsResponse;
  clearHolds: ClearHoldsResponse;
  createAssessmentTask: AssessmentTask;
  createBatchContract: Array<BatchContractResponse>;
  createContract: ContractType;
  createContractPackage: ContractPackagesType;
  createDisabledProgramIndication: Array<DisabledProgramIndication>;
  createDiscount: Array<DiscountDetail>;
  createFacet: Facet;
  createFileConfiguration: UpsertFileConfigurationResponseType;
  createPackageContract: ContractType;
  createRecommendation: Recommendation;
  createRule: Rule;
  createRuleAssessment: RuleAssessment;
  createTag: Tag;
  createUserTag: UserTag;
  deleteRule: Rule;
  deleteTag: Tag;
  deleteUserTag: UserTag;
  enrollUsers: EnrollUsersModel;
  runClaimsManualWebDag: DagRunResponseType;
  setPhoneForUser: Scalars['Boolean'];
  updateAssessmentTask: AssessmentTask;
  updateContract: ContractType;
  updateContractPackage: ContractPackagesType;
  updateDisabledProgramIndication: Array<DisabledProgramIndication>;
  updateDiscount: Array<DiscountDetail>;
  updateFileConfiguration: UpsertFileConfigurationResponseType;
  updateRecommendation: Recommendation;
  updateRule: Rule;
};


export type MutationBulkUpdateBillsArgs = {
  bulkUpdateBillsInput: BulkUpdateBillsInput;
};


export type MutationClearHoldsArgs = {
  clearHoldsInput: ClearHoldsInput;
};


export type MutationCreateAssessmentTaskArgs = {
  createAssessmentTaskInput: CreateAssessmentTaskInput;
};


export type MutationCreateBatchContractArgs = {
  batchContractInput: CreateBatchContractInput;
};


export type MutationCreateContractArgs = {
  contractInput: CreateContractInput;
};


export type MutationCreateContractPackageArgs = {
  contractPackageInput: ContractPackagesInput;
};


export type MutationCreateDisabledProgramIndicationArgs = {
  disabledProgramIndicationInput: DisabledProgramIndicationInput;
};


export type MutationCreateDiscountArgs = {
  discountInput: CreateDiscountInput;
};


export type MutationCreateFacetArgs = {
  createFacetInput: CreateFacetInput;
};


export type MutationCreateFileConfigurationArgs = {
  createFileConfigurationInput: FileConfigurationInput;
};


export type MutationCreatePackageContractArgs = {
  contractInput: CreateContractInput;
};


export type MutationCreateRecommendationArgs = {
  createRecommendationInput: CreateRecommendationInput;
};


export type MutationCreateRuleArgs = {
  createRuleInput: CreateRuleInput;
};


export type MutationCreateRuleAssessmentArgs = {
  createRuleAssessmentInput: CreateRuleAssessmentInput;
};


export type MutationCreateTagArgs = {
  createTagInput: CreateTagInput;
};


export type MutationCreateUserTagArgs = {
  createUserTagInput: CreateUserTagInput;
};


export type MutationDeleteRuleArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserTagArgs = {
  id: Scalars['String'];
};


export type MutationEnrollUsersArgs = {
  input: EnrollUsersInput;
};


export type MutationRunClaimsManualWebDagArgs = {
  dagRunRequestInput: DagRunRequestInput;
};


export type MutationSetPhoneForUserArgs = {
  hhUuid: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationUpdateAssessmentTaskArgs = {
  id: Scalars['String'];
  updateAssessmentTaskInput: UpdateAssessmentTaskInput;
};


export type MutationUpdateContractArgs = {
  contractId: Scalars['Float'];
  contractInput: UpdateContractInput;
};


export type MutationUpdateContractPackageArgs = {
  contractPackageId: Scalars['Float'];
  contractPackageInput: ContractPackagesInput;
};


export type MutationUpdateDisabledProgramIndicationArgs = {
  disabledProgramIndicationInput: DisabledProgramIndicationInput;
};


export type MutationUpdateDiscountArgs = {
  discountInput: UpdateDiscountInput;
};


export type MutationUpdateFileConfigurationArgs = {
  id: Scalars['Float'];
  updateFileConfigurationInput: FileConfigurationInput;
};


export type MutationUpdateRecommendationArgs = {
  updateRecommendationInput: UpdateRecommendationInput;
};


export type MutationUpdateRuleArgs = {
  id: Scalars['String'];
  updateRuleInput: UpdateRuleInput;
};

export type PackageOfferingsType = {
  __typename?: 'PackageOfferingsType';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  lastModifiedBy: Scalars['String'];
  packageId: Scalars['Int'];
  packageOffering: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  programIndication?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type PackageRulesInput = {
  activationFee?: Maybe<Scalars['Float']>;
  activityFee?: Maybe<Scalars['Float']>;
};

export type PackageType = {
  __typename?: 'PackageType';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  lastModifiedBy: Scalars['String'];
  name: Scalars['String'];
  packageOfferings: Array<PackageOfferingsType>;
  updatedAt: Scalars['String'];
};

export type PaginatedBillType = {
  __typename?: 'PaginatedBillType';
  billAmount: Scalars['Float'];
  billId: Scalars['Float'];
  claimId: Scalars['String'];
  clientsInsurerId: Scalars['Float'];
  dateOfService: Scalars['String'];
  holdDetails?: Maybe<HoldDetailsType>;
  status: Scalars['String'];
  subscriptionId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type PaginationInput = {
  /** The page number */
  page?: Maybe<Scalars['Float']>;
  /** The size of the page */
  size?: Maybe<Scalars['Float']>;
};

export type PartnershipType = {
  __typename?: 'PartnershipType';
  /** The allowCohortExpansion of the Partnership item */
  allowCohortExpansion: Scalars['Boolean'];
  /** The id of the Partnership item */
  id: Scalars['Int'];
  /** The name of the Partnership item */
  name: Scalars['String'];
  /** The Partnership type */
  partnershipType: Scalars['String'];
  /** The requiresStateCredentials of the Partnership item */
  requiresStateCredentials: Scalars['Boolean'];
};

export type Pathway = {
  __typename?: 'Pathway';
  /** The name of the pathway indication, e.g. knee */
  indication: Scalars['String'];
  /** The name of the pathway program, e.g. acute */
  program: Scalars['String'];
  /** The screening of the pathway */
  screening: Screening;
  /** The total points completed in the pathway */
  totalPoints: Scalars['Float'];
};

export type PaymentType = {
  __typename?: 'PaymentType';
  /** The code of the PaymentType item */
  code: Scalars['String'];
  /** The created date time value of the PaymentType item */
  createdAt: Scalars['String'];
  /** The id of the PaymentType item */
  id: Scalars['Int'];
  /** The name of the PaymentType item */
  name: Scalars['String'];
  /** The updated date time value of the PaymentType item */
  updatedAt: Scalars['String'];
};

export type ProcedureCodeInput = {
  /** The contract id of the ProcedureCodesType item */
  contractId?: Maybe<Scalars['Float']>;
  /** The CPT code of the ProcedureCodesType item */
  cptCode: Scalars['String'];
  /** The modifier of the ProcedureCodesType item */
  modifier1?: Maybe<Scalars['String']>;
  /** The modifier of the ProcedureCodesType item */
  modifier2?: Maybe<Scalars['String']>;
  /** The payment type id of the ProcedureCodesType item */
  paymentTypeId: Scalars['Float'];
};

export type ProcedureCodesType = {
  __typename?: 'ProcedureCodesType';
  /** The contract id of the ProcedureCodesType item */
  contractId: Scalars['Float'];
  /** The CPT code of the ProcedureCodesType item */
  cptCode: Scalars['String'];
  /** The created date time value of the ProcedureCodesType item */
  createdAt: Scalars['String'];
  /** The id of the ProcedureCodesType item */
  id: Scalars['Int'];
  /** The modifier of the ProcedureCodesType item */
  modifier1?: Maybe<Scalars['String']>;
  /** The modifier of the ProcedureCodesType item */
  modifier2?: Maybe<Scalars['String']>;
  /** The payment type id of the ProcedureCodesType item */
  paymentTypeId: Scalars['Float'];
  /** The payment type of the ProcedureCodesType item */
  paymentTypes: PaymentType;
  /** The updated date time value of the ProcedureCodesType item */
  updatedAt: Scalars['String'];
};

export type ProgramExceptionsType = {
  __typename?: 'ProgramExceptionsType';
  /** The identifier of the ProgramExceptionsType item */
  programName: Scalars['String'];
};

export type ProgramIndicationType = {
  __typename?: 'ProgramIndicationType';
  /** The id of ProgramIndicationType item */
  id: Scalars['Int'];
  /** The identifier of ProgramIndicationType item */
  identifier: Scalars['String'];
  /** The indicationName of ProgramIndicationType item */
  indicationName: Scalars['String'];
  /** The programId of the ProgramIndicationType item */
  programId: Scalars['Int'];
  /** The programName of ProgramIndicationType item */
  programName: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getAllClients: Array<ClientType>;
  getAllEngagementTypes: Array<EngagementType>;
  getAllInsurers: Array<InsurerType>;
  getAllInternalHoldTypes: Array<InternalHoldType>;
  getAllPackages: Array<PackageType>;
  getAssessmentTask: AssessmentTask;
  getBillingModels: Array<BillingModelType>;
  getBills: BillsPaginatedType;
  getBillsBySubscriptionId: Array<BillSubmissionsType>;
  getClaimsManualWebDagRunStatus: DagRunResponseType;
  getClientInsurerById: ClientInsurerType;
  getClientInsurersByClient: ClientInsurerByClientType;
  getClientInsurersByIds: Array<BatchClientInsurerType>;
  getColumnMappings: MappingType;
  getConsultResultById: ConsultResultType;
  getContractSubscriptions: ContractSubscriptionType;
  getContractTemplates: Array<ContractTemplateType>;
  getContracts: Array<ContractType>;
  getCurrencyCodes: Array<CurrencyCodeType>;
  getCvsEligibility: CvsResponseType;
  getDisabledProgramIndications: Array<DisabledProgramIndication>;
  getDiscountUsage: Array<DiscountUsage>;
  getDiscounts: Array<DiscountDetail>;
  getEngagementsByUserUuid: EngagementsPaginationType;
  getFacet: Facet;
  getFileConfiguration: GetFileConfigurationFrontendResponse;
  getFileConfigurations: GetFileConfigurationsResponseType;
  getHealthRecord: HealthRecord;
  getIndividualOperations: Array<ModuleType>;
  getIroncladPdf: IroncladPdf;
  getParserModules: ModulesResponse;
  getPartnerships: Array<PartnershipType>;
  getPaymentTypes: Array<PaymentType>;
  getPhoneStatusForUser: UserPhoneStatus;
  getProgramIndicationsById: Array<ProgramIndicationType>;
  getRecommendation: Recommendation;
  getRule: Rule;
  getRuleAssessment: RuleAssessment;
  getSubscriptionEngagements: EngagementsPaginationType;
  getSubscriptionHoldsByUser: Array<SubscriptionHoldType>;
  getSubscriptions: SubscriptionsPaginationType;
  getUnbilledBills: UnbilledBillsPaginatedType;
  getUserTag: UserTag;
  getUserUuidById: UserLimitedType;
  getWorkflowsByFilter: WorkflowListPayload;
  listAssessmentTasks: AssessmentTaskList;
  listFacets: FacetsList;
  listHealthRecords: HealthRecordsList;
  listHealthRecordsAtCursor: HealthRecordsList;
  listRuleAssessments: RuleAssessmentsList;
  listRules: RulesList;
  listTags: TagsList;
  listUserTags: UserTagsList;
};


export type QueryGetAssessmentTaskArgs = {
  id: Scalars['String'];
};


export type QueryGetBillsArgs = {
  billAmount?: Maybe<Scalars['Float']>;
  billStatuses?: Maybe<Array<BillStatusEnum>>;
  claimIds?: Maybe<Array<Scalars['String']>>;
  clientInsurerIds?: Maybe<Array<Scalars['Float']>>;
  endDate?: Maybe<Scalars['String']>;
  held?: Maybe<Scalars['Boolean']>;
  internalHoldTypes?: Maybe<Array<Scalars['String']>>;
  page?: Maybe<Scalars['Float']>;
  paymentTypes?: Maybe<Array<Scalars['String']>>;
  size?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['Boolean']>;
  subscriptionIds?: Maybe<Array<Scalars['Float']>>;
  userIds?: Maybe<Array<Scalars['Float']>>;
};


export type QueryGetBillsBySubscriptionIdArgs = {
  subscriptionId: Scalars['Float'];
};


export type QueryGetClaimsManualWebDagRunStatusArgs = {
  dagRunId: Scalars['String'];
};


export type QueryGetClientInsurerByIdArgs = {
  id: Scalars['Float'];
};


export type QueryGetClientInsurersByClientArgs = {
  id: Scalars['String'];
};


export type QueryGetClientInsurersByIdsArgs = {
  billingType?: Maybe<BillingTypes>;
  billingTypes?: Maybe<Array<BillingTypes>>;
  clientIdentifier?: Maybe<Scalars['String']>;
  clientIds?: Maybe<Array<Scalars['Int']>>;
  ids?: Maybe<Array<Scalars['Int']>>;
  insurerIds?: Maybe<Array<Scalars['Int']>>;
  partnershipIds?: Maybe<Array<Scalars['Int']>>;
  scope?: Maybe<ScopeType>;
};


export type QueryGetConsultResultByIdArgs = {
  consultId: Scalars['String'];
};


export type QueryGetContractSubscriptionsArgs = {
  clientsInsurerId?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['Float']>;
  contractPackageIds?: Maybe<Array<Scalars['Int']>>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};


export type QueryGetContractTemplatesArgs = {
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetContractsArgs = {
  billableActivityDisabled?: Maybe<Scalars['Boolean']>;
  clientId?: Maybe<Scalars['Float']>;
  clientsInsurerId?: Maybe<Scalars['Float']>;
  includeVoided?: Maybe<Scalars['Boolean']>;
  insurerId?: Maybe<Scalars['Float']>;
};


export type QueryGetCvsEligibilityArgs = {
  getCvsEligibilityInput: GetCvsEligibilityInput;
};


export type QueryGetDisabledProgramIndicationsArgs = {
  contractId?: Maybe<Scalars['Float']>;
  contractPackageId?: Maybe<Scalars['Float']>;
};


export type QueryGetDiscountUsageArgs = {
  clientId?: Maybe<Scalars['Float']>;
  discountDetailIds?: Maybe<Array<Scalars['Int']>>;
  partnershipId?: Maybe<Scalars['Float']>;
};


export type QueryGetDiscountsArgs = {
  clientId?: Maybe<Scalars['Float']>;
  discountDefinitionId?: Maybe<Scalars['Float']>;
  discountDetailId?: Maybe<Scalars['Float']>;
  discountType?: Maybe<Scalars['String']>;
  inquiryDate?: Maybe<Scalars['String']>;
  insurerId?: Maybe<Scalars['Float']>;
  packageId?: Maybe<Scalars['Float']>;
  partnershipId?: Maybe<Scalars['Float']>;
  partnershipIds?: Maybe<Array<Scalars['Int']>>;
  programId?: Maybe<Scalars['Float']>;
  scopeType?: Maybe<ScopeType>;
};


export type QueryGetEngagementsByUserUuidArgs = {
  endDate?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  startDate: Scalars['String'];
  uuid: Scalars['String'];
};


export type QueryGetFacetArgs = {
  id: Scalars['String'];
};


export type QueryGetFileConfigurationArgs = {
  id: Scalars['Float'];
};


export type QueryGetFileConfigurationsArgs = {
  paginationInput: PaginationInput;
};


export type QueryGetHealthRecordArgs = {
  id: Scalars['String'];
};


export type QueryGetIndividualOperationsArgs = {
  getIndividualOperationsInput: GetIndividualOperationsInput;
};


export type QueryGetIroncladPdfArgs = {
  docId: Scalars['String'];
};


export type QueryGetPartnershipsArgs = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};


export type QueryGetPhoneStatusForUserArgs = {
  hhUuid: Scalars['String'];
};


export type QueryGetProgramIndicationsByIdArgs = {
  programIds?: Maybe<Array<Scalars['Int']>>;
};


export type QueryGetRecommendationArgs = {
  id: Scalars['Float'];
};


export type QueryGetRuleArgs = {
  id: Scalars['String'];
};


export type QueryGetRuleAssessmentArgs = {
  id: Scalars['String'];
};


export type QueryGetSubscriptionEngagementsArgs = {
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  subscriptionId: Scalars['Float'];
};


export type QueryGetSubscriptionHoldsByUserArgs = {
  userId: Scalars['Float'];
};


export type QueryGetSubscriptionsArgs = {
  includeVoid?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  userId: Scalars['Float'];
};


export type QueryGetUnbilledBillsArgs = {
  clientsInsurerIds?: Maybe<Array<Scalars['Float']>>;
  endDate?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
};


export type QueryGetUserTagArgs = {
  id: Scalars['String'];
};


export type QueryGetUserUuidByIdArgs = {
  id: Scalars['Float'];
};


export type QueryGetWorkflowsByFilterArgs = {
  workflowFiltersInputDto: WorkflowListFiltersInputDto;
  workflowType?: Maybe<Scalars['String']>;
};


export type QueryListAssessmentTasksArgs = {
  listAssessmentTasksInput?: Maybe<ListAssessmentTasksInput>;
};


export type QueryListFacetsArgs = {
  listFacetsInput?: Maybe<ListFacetsInput>;
};


export type QueryListHealthRecordsArgs = {
  listHealthRecordsInput: ListHealthRecordsInput;
};


export type QueryListHealthRecordsAtCursorArgs = {
  cursorPath: Scalars['String'];
};


export type QueryListRuleAssessmentsArgs = {
  listRuleAssessmentsInput?: Maybe<ListRuleAssessmentsInput>;
};


export type QueryListRulesArgs = {
  listRulesInput?: Maybe<ListRulesInput>;
};


export type QueryListTagsArgs = {
  listTagsInput?: Maybe<ListTagsInput>;
};


export type QueryListUserTagsArgs = {
  listUserTagsInput: ListUserTagsInput;
};

export type Recommendation = {
  __typename?: 'Recommendation';
  id: Scalars['Float'];
  ironcladFields?: Maybe<IronCladFields>;
  ironcladId?: Maybe<Scalars['String']>;
  llmResponse?: Maybe<LlmResponse>;
  sfdcFields?: Maybe<SfdcFields>;
  source?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type RemitBaseType = {
  __typename?: 'RemitBaseType';
  createdAt: Scalars['String'];
  icn?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  patientPaymentAmount: Scalars['Float'];
  payer: Scalars['String'];
  paymentAmount: Scalars['Float'];
  paymentDate?: Maybe<Scalars['String']>;
  paymentNumber?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  responseDate: Scalars['String'];
  sourceOfRecord: Scalars['String'];
};

export type Rule = {
  __typename?: 'Rule';
  actions?: Maybe<Array<ActionDefinition>>;
  createdAt: Scalars['String'];
  definition: RuleDefinition;
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  source: Scalars['String'];
  status: RuleStatus;
  updatedAt: Scalars['String'];
  version: Scalars['Float'];
};

export type RuleAssessment = {
  __typename?: 'RuleAssessment';
  assessedRules: Array<AssessedRule>;
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  resourceId: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  updatedAt: Scalars['String'];
  userId: Scalars['String'];
};

export type RuleAssessmentsList = {
  __typename?: 'RuleAssessmentsList';
  ruleAssessments: Array<RuleAssessment>;
  total: Scalars['Float'];
};

export type RuleDefinition = {
  __typename?: 'RuleDefinition';
  criteria: Array<Scalars['JSONObject']>;
  name: Scalars['String'];
  operator: Scalars['String'];
};

export type RuleDefinitionInput = {
  criteria: Array<Scalars['JSONObject']>;
  name: Scalars['String'];
  operator: Scalars['String'];
};

export enum RuleStatus {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type RulesList = {
  __typename?: 'RulesList';
  rules: Array<Rule>;
  total: Scalars['Float'];
};

export type RulesType = {
  __typename?: 'RulesType';
  activationFee?: Maybe<Scalars['Float']>;
  activityFee?: Maybe<Scalars['Float']>;
};

export type SfdcFields = {
  __typename?: 'SFDCFields';
  acutePrice?: Maybe<Scalars['Float']>;
  billableActivityTypes: Array<Scalars['String']>;
  chronicPrice?: Maybe<Scalars['Float']>;
  contract?: Maybe<Array<MilestonesType>>;
  contractTemplateId?: Maybe<Scalars['Float']>;
};

export enum ScopeType {
  Exclusive = 'EXCLUSIVE',
  Inclusive = 'INCLUSIVE'
}

export type Screening = {
  __typename?: 'Screening';
  /** The screening ID */
  id: Scalars['String'];
};

export type SubmissionRemitsType = {
  __typename?: 'SubmissionRemitsType';
  billId: Scalars['Float'];
  coverageId?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  id: Scalars['Float'];
  netsuiteId?: Maybe<Scalars['String']>;
  remits: Array<RemitBaseType>;
  status: Scalars['String'];
  submissionAmount: Scalars['Float'];
  submissionDate: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type SubscriptionHoldType = {
  __typename?: 'SubscriptionHoldType';
  clearedAt?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['JSONObject']>;
  firstSeenAt: Scalars['String'];
  hitCount: Scalars['Float'];
  id: Scalars['Float'];
  lastSeenAt: Scalars['String'];
  programId?: Maybe<Scalars['Float']>;
  reason: Scalars['String'];
  userId: Scalars['Float'];
  userUuid: Scalars['String'];
};

export type SubscriptionType = {
  __typename?: 'SubscriptionType';
  /** client id of the SubscriptionType item */
  clientId: Scalars['Float'];
  /** clients insurer id of the SubscriptionType item */
  clientsInsurerId: Scalars['Float'];
  /** contract id of the SubscriptionType item */
  contractId: Scalars['Float'];
  /** created at of the SubscriptionType item */
  createdAt: Scalars['String'];
  /** ends at of the SubscriptionType item */
  endsAt: Scalars['String'];
  /** id of the SubscriptionType item */
  id: Scalars['Float'];
  /** pathway id of the SubscriptionType item */
  pathwayId: Scalars['Float'];
  /** pathway uuid of the SubscriptionType item */
  pathwayUuid: Scalars['String'];
  /** program id of the SubscriptionType item */
  programId?: Maybe<Scalars['Float']>;
  /** starts at of the SubscriptionType item */
  startsAt: Scalars['String'];
  /** term date of the SubscriptionType item */
  termDate?: Maybe<Scalars['String']>;
  /**  user id of the SubscriptionType item */
  userId: Scalars['Float'];
  /** user uuid of the SubscriptionType item */
  userUuid: Scalars['String'];
  /** void value of the SubscriptionType item */
  void: Scalars['Boolean'];
  /** year count of the SubscriptionType item */
  yearCount: Scalars['Float'];
};

export type SubscriptionsPaginationType = {
  __typename?: 'SubscriptionsPaginationType';
  items: Array<SubscriptionType>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  size: Scalars['Float'];
  total: Scalars['Float'];
};

export type Tag = {
  __typename?: 'Tag';
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type TagsList = {
  __typename?: 'TagsList';
  count: Scalars['Float'];
  tags: Array<Tag>;
};

export type Task = {
  __typename?: 'Task';
  /** Administrator identification number */
  adminUuid?: Maybe<Scalars['ID']>;
  /** Date and time when the task was completed */
  completedAt?: Maybe<Scalars['String']>;
  /** Date and time when the task was created */
  createdAt: Scalars['String'];
  /** Date when task is due */
  dueDate?: Maybe<Scalars['String']>;
  /** Id of the task */
  id: Scalars['Float'];
  /** Name of the task */
  name?: Maybe<Scalars['String']>;
  /** Selection data for multiple outcome tasks */
  selection?: Maybe<Scalars['String']>;
  /** The workflow state associated with the task */
  state: Scalars['String'];
  /** Type of the task */
  type: Scalars['String'];
  /** Date and time when the task was updated */
  updatedAt: Scalars['String'];
};

export type UnbilledBillType = {
  __typename?: 'UnbilledBillType';
  billsCount: Scalars['Float'];
  clientsInsurerId: Scalars['Float'];
  unbilled?: Maybe<Scalars['Float']>;
};

export type UnbilledBillsPaginatedType = {
  __typename?: 'UnbilledBillsPaginatedType';
  items: Array<UnbilledBillType>;
  page: Scalars['Float'];
  pages: Scalars['Float'];
  size: Scalars['Float'];
  total: Scalars['Float'];
};

export type UpdateAssessmentTaskInput = {
  /** Asssessment task status */
  status: Scalars['String'];
};

export type UpdateContractInput = {
  /** The acute price of the ContractType item */
  acutePrice?: Maybe<Scalars['Float']>;
  billableActivityTypes?: Maybe<Scalars['BillableActivityArrayOrObject']>;
  /** The chronic price of the ContractType item */
  chronicPrice?: Maybe<Scalars['Float']>;
  /** The client id of the ContractType item */
  clientId?: Maybe<Scalars['Float']>;
  /** The client insurer id of the ContractType item */
  clientsInsurerId?: Maybe<Scalars['Float']>;
  /** The contract value of the ContractType item */
  contract?: Maybe<Array<Scalars['JSONObject']>>;
  /** Contract packages to be saved */
  contractPackages?: Maybe<Array<ContractPackagesInput>>;
  /** The contract template id of the ContractType item */
  contractTemplateId?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  /** disabled program indications as string to be set as disabled program indications */
  disabledProgramIndication?: Maybe<Array<Scalars['String']>>;
  /** The updated date time value of the ContractType item */
  endDate?: Maybe<Scalars['String']>;
  /** The insurer id of the ContractType item */
  insurerId?: Maybe<Scalars['Float']>;
  ironcladId?: Maybe<Scalars['String']>;
  packageEnabled?: Maybe<Scalars['Boolean']>;
  partnershipId?: Maybe<Scalars['Float']>;
  /** Procedure codes to be saved */
  procedureCodes?: Maybe<Array<ProcedureCodeInput>>;
  /** The start date of the ContractType item */
  startDate?: Maybe<Scalars['String']>;
  userAnnualCap?: Maybe<Scalars['Float']>;
  /** The void value of the ContractType item */
  void?: Maybe<Scalars['Boolean']>;
};

export type UpdateDiscountInput = {
  clientId?: Maybe<Scalars['Float']>;
  discount: Scalars['Float'];
  discountDefinitionId: Scalars['Float'];
  discountType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  packageId?: Maybe<Scalars['Float']>;
  programId?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  suppressEmptyCharges?: Maybe<Scalars['Boolean']>;
};

export type UpdateRecommendationInput = {
  id: Scalars['Float'];
  status: Scalars['String'];
};

export type UpdateRuleInput = {
  /** List of rule actions */
  actions?: Maybe<Array<ActionDefinitionInput>>;
  /** The rule definition */
  definition?: Maybe<RuleDefinitionInput>;
  /** Supported resource types for rule */
  resourceTypes?: Maybe<Array<Scalars['String']>>;
  /** Source of resources to be evaluated by this rule */
  source?: Maybe<Scalars['String']>;
  /** Rule status */
  status?: Maybe<RuleStatus>;
};

export type UpsertFileConfigurationResponseType = {
  __typename?: 'UpsertFileConfigurationResponseType';
  created_at: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  updated_at: Scalars['String'];
};

export type UserLimitedType = {
  __typename?: 'UserLimitedType';
  /** The UUID of the member */
  uuid?: Maybe<Scalars['ID']>;
};

export type UserPhoneStatus = {
  __typename?: 'UserPhoneStatus';
  auth0: Array<Scalars['String']>;
  basilisk: Array<Scalars['String']>;
  hhUuid: Scalars['String'];
  userService: Array<Scalars['String']>;
};

export type UserTag = {
  __typename?: 'UserTag';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  occurredAt: Scalars['String'];
  resourceId: Scalars['String'];
  source: Scalars['String'];
  tagId: Scalars['Float'];
  tagName: Scalars['String'];
  userId: Scalars['String'];
};

export type UserTagsList = {
  __typename?: 'UserTagsList';
  count: Scalars['Float'];
  userTags: Array<UserTag>;
};

export type ValidGroupNumbersType = {
  __typename?: 'ValidGroupNumbersType';
  /** The groupNumber of the ValidGroupNumbersType item */
  groupNumber: Scalars['String'];
};

export type WorkflowError = {
  __typename?: 'WorkflowError';
  /** The error related to the entered workflow value */
  error: Scalars['String'];
  /** The name of the bad field input */
  fieldName: Scalars['String'];
  /** The incorrectly entered workflow value */
  value?: Maybe<Scalars['String']>;
};

export type WorkflowListFiltersInputDto = {
  /** UUIDs of administrators to filter by */
  adminUuids?: Maybe<Array<Scalars['ID']>>;
  /** Workflow states to exclude */
  excludeStates?: Maybe<Array<Scalars['String']>>;
  /** Order of sorting 'DESC' or 'ASC' (defaults to 'DESC' ) */
  order?: Maybe<Scalars['String']>;
  /** Column to order workflows by */
  orderBy?: Maybe<Scalars['String']>;
  /** Page number (defaults to 1) */
  pageNumber?: Maybe<Scalars['Float']>;
  /** Number of workflow payloads this page (defaults to 10) */
  pageSize?: Maybe<Scalars['Float']>;
  /** UUIDs of patients to filter by */
  patientUuids?: Maybe<Array<Scalars['ID']>>;
  /** Whether to use Basilisk coverage to filter results */
  useCoverage?: Maybe<Scalars['Boolean']>;
};

export type WorkflowListPayload = {
  __typename?: 'WorkflowListPayload';
  errors: Array<WorkflowError>;
  /** The identifier of this list request, for pagination */
  id: Scalars['String'];
  /** Page number */
  pageNumber: Scalars['Float'];
  /** Page size */
  pageSize: Scalars['Float'];
  workflows: Array<WorkflowPayload>;
};

export type WorkflowPayload = {
  __typename?: 'WorkflowPayload';
  /** UUIDs of administrators associated with the workflow */
  adminUuids?: Maybe<Array<Scalars['ID']>>;
  /** Administrators who have coverage of the workflow */
  coveringAdmins?: Maybe<Array<Admin>>;
  /** User insurer CPT codes */
  cptCodes?: Maybe<CptCodes>;
  /** Date and time when the workflow was created */
  createdAt: Scalars['String'];
  /** Custom fields associated with the workflow */
  customFields?: Maybe<Scalars['JSONObject']>;
  /** Workflow identification number */
  id: Scalars['Float'];
  /** workflow has past due tasks */
  isPastDue?: Maybe<Scalars['Boolean']>;
  /** complete workflow date */
  lastCompletedAt?: Maybe<Scalars['String']>;
  /** latest task timestamp with time zone */
  latestTaskDate?: Maybe<Scalars['String']>;
  /** latest task ID */
  latestTaskId?: Maybe<Scalars['Float']>;
  /** Application pathway details */
  pathway?: Maybe<Pathway>;
  /** User associated with the workflow  */
  patient?: Maybe<BaseUser>;
  /** Patient identification number */
  patientUuid: Scalars['String'];
  /** Priority of workflow */
  priority?: Maybe<Scalars['Int']>;
  /** an array with number of completed tasks at index zero and total task count at index one */
  progress?: Maybe<Array<Scalars['Float']>>;
  /** The ID of the event associated with this workflow */
  referenceEventId?: Maybe<Scalars['String']>;
  /** Current state of the workflow */
  state: Scalars['String'];
  tasks?: Maybe<Array<Task>>;
  /** Type of workflow */
  type: Scalars['String'];
  /** Date and time when the workflow was updated */
  updatedAt: Scalars['String'];
  /** Flag to indicate if the workflow is viewed */
  viewed: Scalars['Boolean'];
};

export type GetPhoneStatusForUserQueryVariables = Exact<{
  hhUuid: Scalars['String'];
}>;


export type GetPhoneStatusForUserQuery = { __typename?: 'Query', getPhoneStatusForUser: { __typename?: 'UserPhoneStatus', auth0: Array<string>, basilisk: Array<string>, hhUuid: string, userService: Array<string> } };

export type SetPhoneForUserMutationVariables = Exact<{
  hhUuid: Scalars['String'];
  phone: Scalars['String'];
}>;


export type SetPhoneForUserMutation = { __typename?: 'Mutation', setPhoneForUser: boolean };


export const GetPhoneStatusForUserDocument = gql`
    query GetPhoneStatusForUser($hhUuid: String!) {
  getPhoneStatusForUser(hhUuid: $hhUuid) {
    auth0
    basilisk
    hhUuid
    userService
  }
}
    `;

/**
 * __useGetPhoneStatusForUserQuery__
 *
 * To run a query within a React component, call `useGetPhoneStatusForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhoneStatusForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhoneStatusForUserQuery({
 *   variables: {
 *      hhUuid: // value for 'hhUuid'
 *   },
 * });
 */
export function useGetPhoneStatusForUserQuery(baseOptions: Apollo.QueryHookOptions<GetPhoneStatusForUserQuery, GetPhoneStatusForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPhoneStatusForUserQuery, GetPhoneStatusForUserQueryVariables>(GetPhoneStatusForUserDocument, options);
      }
export function useGetPhoneStatusForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhoneStatusForUserQuery, GetPhoneStatusForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPhoneStatusForUserQuery, GetPhoneStatusForUserQueryVariables>(GetPhoneStatusForUserDocument, options);
        }
export type GetPhoneStatusForUserQueryHookResult = ReturnType<typeof useGetPhoneStatusForUserQuery>;
export type GetPhoneStatusForUserLazyQueryHookResult = ReturnType<typeof useGetPhoneStatusForUserLazyQuery>;
export type GetPhoneStatusForUserQueryResult = Apollo.QueryResult<GetPhoneStatusForUserQuery, GetPhoneStatusForUserQueryVariables>;
export const SetPhoneForUserDocument = gql`
    mutation SetPhoneForUser($hhUuid: String!, $phone: String!) {
  setPhoneForUser(hhUuid: $hhUuid, phone: $phone)
}
    `;
export type SetPhoneForUserMutationFn = Apollo.MutationFunction<SetPhoneForUserMutation, SetPhoneForUserMutationVariables>;

/**
 * __useSetPhoneForUserMutation__
 *
 * To run a mutation, you first call `useSetPhoneForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPhoneForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPhoneForUserMutation, { data, loading, error }] = useSetPhoneForUserMutation({
 *   variables: {
 *      hhUuid: // value for 'hhUuid'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useSetPhoneForUserMutation(baseOptions?: Apollo.MutationHookOptions<SetPhoneForUserMutation, SetPhoneForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPhoneForUserMutation, SetPhoneForUserMutationVariables>(SetPhoneForUserDocument, options);
      }
export type SetPhoneForUserMutationHookResult = ReturnType<typeof useSetPhoneForUserMutation>;
export type SetPhoneForUserMutationResult = Apollo.MutationResult<SetPhoneForUserMutation>;
export type SetPhoneForUserMutationOptions = Apollo.BaseMutationOptions<SetPhoneForUserMutation, SetPhoneForUserMutationVariables>;