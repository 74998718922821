import {
  HHBox,
  HHButton,
  HHStack,
  HHTextField,
} from '@hinge-health/react-component-library';
import { useEffect, useState } from 'react';
import { useDynamicDrawerContext } from '../../../../context/dynamic-drawer.context';
import {
  BillStatusEnum,
  ClearableHoldTypes,
  ClientType,
  GetBillsDocument,
  useBulkUpdateBillsMutation,
  useClearHoldsMutation,
} from '../../../../types';
import {
  BillTypeDataList,
  GridSnackBarState,
  InsurersType,
  ReleasableHoldReasons,
} from '../bill-holds-types';
import { BillPanelTitle } from './bill-panel-title';
import { ClientInsurerSelect } from './client-insurer-select';
import { PanelAlert } from './panel-alert';
import { SelectedBillsGrid } from './selected-grid';

export enum BillPanelMode {
  Archive,
  Release,
  None,
}

export interface BillPanelProps {
  /**
   * The models containing row data
   */
  selectedRows: BillTypeDataList;
  /**
   * The mode of the drawer
   */
  drawerMode?: BillPanelMode;
  setSnackBarState: React.Dispatch<React.SetStateAction<GridSnackBarState>>;
  clients: ClientType[];
  insurers: InsurersType[];
}

export const doesClientInsurerChangeMatch = (
  hasSameHoldType: boolean,
  selectedRows: BillTypeDataList,
): boolean => {
  if (hasSameHoldType && selectedRows.length > 0) {
    if (
      selectedRows[0].holdDetails?.holdType ===
      ReleasableHoldReasons.CLIENT_CHANGE
    ) {
      return selectedRows.every(
        row =>
          row.holdDetails?.clientId === selectedRows[0].holdDetails?.clientId,
      );
    } else {
      return selectedRows.every(
        row =>
          row.holdDetails?.insurerId === selectedRows[0].holdDetails?.insurerId,
      );
    }
  }
  return false;
};

export const BillPanel = ({
  selectedRows,
  drawerMode = BillPanelMode.None,
  setSnackBarState,
  clients,
  insurers,
}: BillPanelProps): JSX.Element => {
  const drawer = useDynamicDrawerContext();
  const [panelSelectedRows, setPanelSelectedRows] =
    useState<BillTypeDataList>(selectedRows);
  const [note, setNote] = useState<string>('');
  const [bulkUpdateBills, { data, error }] = useBulkUpdateBillsMutation();
  const [clearHolds, { data: clearHoldsData, error: clearHoldsError }] =
    useClearHoldsMutation();
  const [releasedToClientInsurerId, setReleasedToClientInsurerId] = useState<
    number | undefined
  >();

  const hasSameHoldType = panelSelectedRows.every(
    row =>
      row.holdDetails?.holdType === panelSelectedRows[0].holdDetails?.holdType,
  );

  const selectedHoldType =
    hasSameHoldType && panelSelectedRows.length > 0
      ? panelSelectedRows[0].holdDetails?.holdType
      : 'invalid';

  const noteTextValid = note.length < 500;
  const clientInsurerChangesMatch = doesClientInsurerChangeMatch(
    hasSameHoldType,
    panelSelectedRows,
  );

  const releaseInvalid =
    (drawerMode === BillPanelMode.Release &&
      (!hasSameHoldType || !clientInsurerChangesMatch) &&
      !noteTextValid) ||
    note.length === 0 ||
    (selectedHoldType !== ReleasableHoldReasons.MANUAL_HOLD &&
      typeof releasedToClientInsurerId !== 'number');

  const archiveInvalid =
    (drawerMode === BillPanelMode.Archive && !noteTextValid) ||
    note.length === 0;

  const handleAction = async (): Promise<void> => {
    try {
      if (drawerMode === BillPanelMode.Archive) {
        await bulkUpdateBills({
          variables: {
            bulkUpdateBillsInput: {
              billIds: panelSelectedRows.map(row => row.billId),
              statusChange: BillStatusEnum.Archived,
              note,
            },
          },
          refetchQueries: [GetBillsDocument],
        });
      }
      if (drawerMode === BillPanelMode.Release) {
        if (
          !releasedToClientInsurerId &&
          selectedHoldType === ReleasableHoldReasons.MANUAL_HOLD
        ) {
          await clearHolds({
            variables: {
              clearHoldsInput: {
                billIds: panelSelectedRows.map(row => row.billId),
                holdTypes: [ClearableHoldTypes.ManualHold],
              },
            },
            refetchQueries: [GetBillsDocument],
          });
        } else if (releasedToClientInsurerId) {
          await bulkUpdateBills({
            variables: {
              bulkUpdateBillsInput: {
                billIds: panelSelectedRows.map(row => row.billId),
                statusChange: BillStatusEnum.SubmittableWithBypass,
                note,
                clientInsurerId: releasedToClientInsurerId,
              },
            },
            refetchQueries: [GetBillsDocument],
          });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        drawer.setIsOpen(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (data || clearHoldsData) {
      const successMessage = data
        ? data.bulkUpdateBills?.details
        : clearHoldsData?.clearHolds?.details;

      setSnackBarState({
        open: true,
        message: successMessage ?? '',
        severity: 'success',
      });
    } else if (error || clearHoldsError) {
      const errorMessage = error ? error.message : clearHoldsError?.message;

      setSnackBarState({
        open: true,
        message: errorMessage ?? '',
        severity: 'error',
      });
    }
  }, [data, error, setSnackBarState, drawer, clearHoldsData, clearHoldsError]);

  const handleDeleteRow = (selectedGridRows: BillTypeDataList): void => {
    setPanelSelectedRows(selectedGridRows);
    drawer.setTitle(
      <BillPanelTitle
        message={`${BillPanelMode[drawerMode]} ${selectedGridRows.length} ${
          selectedGridRows.length > 1 ? 'bills' : 'bill'
        }`}
      />,
    );
  };

  return (
    <HHStack overflow="hidden" flex={1}>
      <HHBox flex={1} sx={{ overflowX: 'scroll' }}>
        <SelectedBillsGrid
          selectedRows={panelSelectedRows}
          setPanelSelectedRows={handleDeleteRow}
          clients={clients}
          insurers={insurers}
        />
      </HHBox>
      <HHStack
        spacing={6}
        padding={6}
        direction="column"
        borderTop="1px  solid rgba(0,0,0, 0.12)"
      >
        <PanelAlert
          drawerMode={drawerMode}
          hasSameHoldType={hasSameHoldType}
          clientInsurerChangesMatch={clientInsurerChangesMatch}
        />
        <ClientInsurerSelect
          disabled={
            drawerMode === BillPanelMode.Archive ||
            !hasSameHoldType ||
            !clientInsurerChangesMatch ||
            selectedHoldType === ReleasableHoldReasons.MANUAL_HOLD
          }
          selectedRows={panelSelectedRows}
          onSelectCallback={setReleasedToClientInsurerId}
        />
        <HHTextField
          multiline
          label={`Why is this being ${BillPanelMode[drawerMode]}d? *`}
          hhVariant="variant-bypass"
          variant="outlined"
          value={note}
          onChange={(e): void => setNote(e.target.value)}
          fullWidth
          rows={5}
          helperText={
            noteTextValid ? '' : 'Note must be less than 500 characters'
          }
          error={!noteTextValid}
        />
        <HHStack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <HHButton
            variant="outlined"
            color="primary"
            hhVariant="outlined"
            onClick={(): void => drawer.setIsOpen(false)}
          >
            Cancel
          </HHButton>
          <HHButton
            variant="contained"
            color="primary"
            hhVariant="contained"
            onClick={(): Promise<void> => handleAction()}
            disabled={
              drawerMode === BillPanelMode.Release
                ? releaseInvalid
                : archiveInvalid
            }
          >
            {BillPanelMode[drawerMode]}
          </HHButton>
        </HHStack>
      </HHStack>
    </HHStack>
  );
};
