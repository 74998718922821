import {
  HHStack,
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDynamicDrawerContext } from '../../context/dynamic-drawer.context';
import { useGetEngagementsByUserUuidLazyQuery } from '../../types';
import EngagementsData from './engagements-data';

const DEFAULT_START_DATE = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
const DEFAULT_END_DATE = dayjs().format('YYYY-MM-DD');

const UserEngagements = ({ userUuid }: { userUuid: string }): JSX.Element => {
  const { onClose } = useDynamicDrawerContext();
  const [searchParams, setSearchParams] = useSearchParams({
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
  });
  const [page, setPage] = useState(1);
  const [getEngagementsByUserUuid, { data, loading, error }] =
    useGetEngagementsByUserUuidLazyQuery();
  const startDateStr = dayjs(searchParams.get('startDate')).isValid()
    ? searchParams.get('startDate')
    : DEFAULT_START_DATE;
  const endDateStr = dayjs(searchParams.get('endDate')).isValid()
    ? searchParams.get('endDate')
    : DEFAULT_END_DATE;

  useEffect(() => {
    getEngagementsByUserUuid({
      variables: {
        uuid: userUuid,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        startDate: startDateStr,
        endDate: endDateStr,
        page,
        size: 15,
      },
    });
  }, [page, userUuid, getEngagementsByUserUuid, startDateStr, endDateStr]);

  useEffect(
    () => () => {
      onClose();
    },
    [onClose],
  );

  return (
    <HHStack spacing={2} padding={1} paddingTop={4}>
      <HHStack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <HHTypography hhVariant="subtitle1">Billable engagements</HHTypography>
        <HHStack direction="row" spacing={4} alignItems="center">
          <HHTypography hhVariant="body1">Filters:</HHTypography>
          <DateRangePicker
            onChange={(range): void => {
              setSearchParams({
                startDate: dayjs(range[0]).format('YYYY-MM-DD'),
                endDate: dayjs(range[1]).format('YYYY-MM-DD'),
              });
            }}
            value={[dayjs(startDateStr).toDate(), dayjs(endDateStr).toDate()]}
            disableFuture
            renderInput={(startProps, endProps): JSX.Element => (
              <HHStack direction="row" spacing={2} maxWidth={200}>
                <HHTextField
                  hhVariant="variant-bypass"
                  variant="standard"
                  type="string"
                  InputLabelProps={{ sx: { color: 'gray' } }}
                  {...startProps}
                />
                <HHTextField
                  hhVariant="variant-bypass"
                  variant="standard"
                  type="string"
                  InputLabelProps={{ sx: { color: 'gray' } }}
                  {...endProps}
                />
              </HHStack>
            )}
          />
        </HHStack>
      </HHStack>
      <EngagementsData
        loading={loading}
        error={Boolean(error)}
        page={page}
        engagementsData={data?.getEngagementsByUserUuid}
        setPage={setPage}
        total={data?.getEngagementsByUserUuid.total ?? 0}
        pages={data?.getEngagementsByUserUuid.pages ?? 1}
      />
    </HHStack>
  );
};

export default UserEngagements;
