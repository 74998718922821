import { ApolloError } from '@apollo/client';
import { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useGetAllEngagementTypesQuery,
  useGetAllPackagesQuery,
  useGetBillingModelsQuery,
} from '../../../types';
import { computeStartDateDefault } from '../../../utils/contract-utils';
import { cleanTypeNameGraphql } from '../../../utils/graphql-utils';
import {
  CleanBillableActivities,
  UsableContractType,
} from '../form/custom-types';
import {
  PackageWithOfferings,
  SimpleBillingModelType,
} from './custom-package-types';

interface Data {
  defaultStartDateCalenderDate: Dayjs;
  billableActivities: CleanBillableActivities[];
  availablePackages: PackageWithOfferings[];
  billingModels: SimpleBillingModelType[];
  defaultInitialPackage: PackageWithOfferings;
  preSelectedBillingModel: string | undefined;
}

interface UseGetDefaultReturn {
  data: Data | undefined;
  isLoading: boolean;
  error: ApolloError | undefined;
}

export function useGetDefaultPackageFormValues(
  currentContracts: UsableContractType[],
): UseGetDefaultReturn {
  const location = useLocation();

  const defaultStartDateCalenderDate = useMemo(
    () => computeStartDateDefault(currentContracts),
    [currentContracts],
  );
  const defaultInitialPackage = useMemo(
    () => ({ id: 0, name: '', packageOfferings: [] }),
    [],
  );

  const {
    data: billableActivities,
    loading: billableActivitiesLoading,
    error: billableActivityError,
  } = useGetAllEngagementTypesQuery();
  const {
    data: availablePackages,
    loading: packagesLoading,
    error: packagesError,
  } = useGetAllPackagesQuery();
  const {
    data: billingModels,
    loading: billingModelsLoading,
    error: billingModelsError,
  } = useGetBillingModelsQuery();

  const combinedError =
    billableActivityError || packagesError || billingModelsError;

  const isLoading =
    billableActivitiesLoading || packagesLoading || billingModelsLoading;

  const processedData = useMemo(() => {
    if (
      isLoading ||
      combinedError ||
      !billableActivities?.getAllEngagementTypes ||
      !availablePackages?.getAllPackages ||
      !billingModels?.getBillingModels
    ) {
      return undefined;
    }

    return {
      defaultStartDateCalenderDate,
      billableActivities: cleanTypeNameGraphql(
        billableActivities.getAllEngagementTypes,
      ),
      availablePackages: cleanTypeNameGraphql(availablePackages.getAllPackages),
      billingModels: cleanTypeNameGraphql(billingModels.getBillingModels),
      defaultInitialPackage,
      preSelectedBillingModel: location.state?.billingModel,
    };
  }, [
    billableActivities?.getAllEngagementTypes,
    availablePackages?.getAllPackages,
    billingModels?.getBillingModels,
    isLoading,
    combinedError,
    defaultStartDateCalenderDate,
    defaultInitialPackage,
    location.state?.billingModel,
  ]);

  return {
    data: processedData,
    isLoading,
    error: combinedError,
  };
}
