import { HHTypography } from '@hinge-health/react-component-library';
import { IDiscount, IDiscountUsage } from '../../../utils/discount-utils';

export interface ProgramAccessUsageProps {
  discountData: IDiscount;
}

const getUsageDisplay = (discountUsage: IDiscountUsage): string => {
  if (discountUsage.loading || discountUsage.error) {
    return '--';
  }

  return String(discountUsage.totalCount ?? 0);
};

const ProgramAccessUsage = ({
  discountData,
}: ProgramAccessUsageProps): JSX.Element => (
  <HHTypography hhVariant="inherit">
    {discountData.discountDetails[0].discount}% off (
    {getUsageDisplay(discountData.discountDetails[0].discountUsage)}{' '}
    Subscriptions Assigned)
  </HHTypography>
);

export default ProgramAccessUsage;
