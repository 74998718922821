import { HHGrid } from '@hinge-health/react-component-library';
import { createStyles, makeStyles } from '@mui/styles';
import { WorkflowPayload } from '../types';
import { ApplicationManagementToolMyWorkflowTableBody } from './application-management-tool-my-workflows-table-body';
import { ApplicationManagementToolMyWorkflowTableHeader } from './application-management-tool-my-workflows-table-header';

const getStyles = makeStyles(() =>
  createStyles({
    grid: {
      display: 'inline-table',
    },
  }),
);

export const myWorkflowTableRowSizes = {
  firstSpacer: 1,
  number: 1,
  userUuid: 1,
  firstName: 2,
  lastName: 2,
  state: 2,
  lastUpdated: 1,
  unassign: 1,
};

type ApplicationManagementToolMyWorkflowTableProps = {
  data?: WorkflowPayload[];
  onClick?: (workflow: WorkflowPayload) => void;
};

export const ApplicationManagementToolMyWorkflowTable = ({
  data = [],
  onClick,
}: ApplicationManagementToolMyWorkflowTableProps): JSX.Element => {
  const styles = getStyles();
  return (
    <HHGrid container className={styles.grid}>
      <ApplicationManagementToolMyWorkflowTableHeader />
      <ApplicationManagementToolMyWorkflowTableBody
        data={data}
        onClick={onClick}
      />
    </HHGrid>
  );
};
