import { HHGrid } from '@hinge-health/react-component-library';
import { useState } from 'react';
import { HingeConnectAutocomplete } from '../../../components/hinge-connect-autocomplete';
import { useListTagNames } from '../../../user-tags/hooks/list-tag-names-hook';

export const CreateUserTagAction = (props: {
  onChange: (data: object) => void;
  onIsValid: (isValid: boolean) => void;
}): JSX.Element => {
  const ONE_MINUTE = 60 * 1000;
  const [tagName, setTagName] = useState('');
  const tagNames = useListTagNames({
    cacheValidationDuration: ONE_MINUTE,
  }).map(tag => ({
    label: tag,
    value: tag,
  }));
  const handleOnChange = (value: string): void => {
    setTagName(value);
    props.onChange({ tagName: value });
    props.onIsValid(!!value);
  };

  return (
    <HHGrid item xs={6}>
      <HingeConnectAutocomplete
        label={'Tag Name'}
        dropdownOptions={tagNames}
        required={true}
        value={tagName}
        clearOnBlur={true}
        onChange={(value): void => handleOnChange(value ?? '')}
      />
    </HHGrid>
  );
};
