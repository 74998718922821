import { Autocomplete, TextField } from '@mui/material';
import { SyntheticEvent } from 'react';

export type AutocompleteOption = {
  label: string;
  value: string;
};

export const HingeConnectAutocomplete = (props: {
  label: string;
  onChange: (value?: string) => void;
  dropdownOptions: Array<{ label: string; value: string }>;
  value: string;
  size?: 'small' | 'medium';
  dataTestId?: string;
  required?: boolean;
  clearOnBlur?: boolean;
  error?: boolean;
  helperText?: string;
}): JSX.Element => {
  const handleChange = (
    _e: SyntheticEvent,
    option: string | AutocompleteOption | null,
  ): void => {
    if (!option) {
      return;
    }
    if (typeof option === 'string') {
      if (!props.clearOnBlur) {
        props.onChange(option);
      }
      return;
    }
    props.onChange(option.value);
  };
  return (
    <Autocomplete
      value={props.value}
      freeSolo={true}
      clearOnBlur={props.clearOnBlur ?? false}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          helperText={props.helperText ?? ''}
          label={props.label}
          required={props.required ?? false}
          error={props.error}
          inputProps={{
            ...params.inputProps,
            'aria-label': props.label,
            'data-testid': props.dataTestId ?? props.label,
          }}
        />
      )}
      options={props.dropdownOptions}
      onChange={(e, option): void => handleChange(e, option)}
      onInputChange={(e, value): void => handleChange(e, value)}
      size={props.size ?? 'small'}
    />
  );
};
