import {
  HHChip,
  HHCollapsibleTable,
  HHLink,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import dayjs from 'dayjs';
import { useState } from 'react';
import { routes } from '../../constants/strings/routes';
import { useDynamicDrawerContext } from '../../context/dynamic-drawer.context';
import { SubscriptionsPaginationType } from '../../types';
import {
  calculateChipColor,
  computeActiveStatus,
} from '../../utils/contract-utils';
import { ProgramTypeDisplay } from '../../utils/discount-utils';
import LoadingComponent from '../utils/loading';
import MoreDetailsButton from '../utils/more-details-button';
import SubscriptionData from './subscription-data';
import SubscriptionDetails from './subscription-details';

export interface SubscriptionListProps {
  subscriptionsData?: SubscriptionsPaginationType;
  loading: boolean;
}

const SubscriptionList = ({
  subscriptionsData,
  loading,
}: SubscriptionListProps): JSX.Element => {
  const [openRowId, setOpenRowId] = useState<number | null>(null);
  const { setDrawerContent } = useDynamicDrawerContext();

  const handleOpenRow =
    (id: number): React.MouseEventHandler<HTMLButtonElement> =>
    (): void => {
      if (openRowId !== id) {
        setOpenRowId(id);
      } else {
        setOpenRowId(null);
      }
    };

  if (loading) {
    return <LoadingComponent center />;
  }

  if (!subscriptionsData) {
    return (
      <HHTypography hhVariant="body">User subscriptions not found</HHTypography>
    );
  }

  return (
    <HHCollapsibleTable
      headers={[
        {
          align: 'left',
          headerTitle: 'Id',
        },
        {
          align: 'left',
          headerTitle: 'Start Date',
        },
        {
          align: 'left',
          headerTitle: 'Program',
        },
        {
          align: 'left',
          headerTitle: 'Year Count',
        },
        {
          align: 'left',
          headerTitle: 'Client Insurer Id',
        },
        {
          align: 'left',
          headerTitle: 'Status',
        },
        {
          align: 'left',
          headerTitle: '',
        },
      ]}
      emptyRowContent={
        <HHStack direction="row" padding={4} justifyContent="center">
          <HHTypography hhVariant="muted">No subscriptions</HHTypography>
        </HHStack>
      }
      rows={
        subscriptionsData.items
          ? subscriptionsData.items.map(subscription => {
              const activeStatus = computeActiveStatus(
                subscription.void,
                subscription.startsAt,
                subscription.endsAt,
              );

              return {
                open: openRowId === subscription.id,
                setOpen: handleOpenRow(subscription.id),
                rowId: subscription.id,
                collapsibleRowContent: (
                  <SubscriptionData subscriptionData={subscription} />
                ),
                columnStyles: {
                  textTransform: 'capitalize',
                },
                columnValues: [
                  {
                    columnItem: subscription.id,
                  },
                  {
                    columnItem: dayjs(subscription.startsAt).format('L'),
                  },
                  {
                    columnItem:
                      ProgramTypeDisplay[subscription.programId ?? -1] || '--',
                  },
                  {
                    columnItem: subscription.yearCount,
                  },
                  {
                    columnItem: (
                      <HHLink
                        href={`${routes.billing.main}/${subscription.clientsInsurerId}`}
                        target="_blank"
                      >
                        {subscription.clientsInsurerId}
                      </HHLink>
                    ),
                  },
                  {
                    columnItem: (
                      <HHChip
                        hhVariant="variant-bypass"
                        color={calculateChipColor(activeStatus)}
                        size="small"
                        label={activeStatus}
                      />
                    ),
                  },
                  {
                    columnItem: (
                      <MoreDetailsButton
                        onClick={(): void =>
                          setDrawerContent(
                            <SubscriptionDetails
                              subscriptionData={subscription}
                              key={subscription.id}
                            />,
                            <HHTypography hhVariant="section-title">
                              Subscription
                            </HHTypography>,
                          )
                        }
                      />
                    ),
                  },
                ],
              };
            })
          : []
      }
    />
  );
};

export default SubscriptionList;
