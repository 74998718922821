import { ApolloError } from '@apollo/client';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  AMT_PATHWAY_TRANSITION_STATES,
  ApplicationManagementToolWorkflowTypes,
} from '../custom-types';
import {
  GetUnassignedWorkflowsByFilterQueryVariables,
  useGetUnassignedWorkflowsByFilterLazyQuery,
  WorkflowPayload,
} from '../types';

const PAGE_SIZE = 1000;
export type WorkflowPayloadProperty = keyof WorkflowPayload;
type UseState<T> = [T, Dispatch<SetStateAction<T>>];

export type FilterStates = {
  orderBy: UseState<WorkflowPayloadProperty>;
  patientUuid: UseState<string | undefined>;
  patientId: UseState<string | undefined>;
  filterState: UseState<AMT_PATHWAY_TRANSITION_STATES | undefined>;
  order: UseState<Order>;
};
export interface UnassignedWorkflowContextType {
  children?: React.ReactNode;
  loading?: boolean;
  workflows?: WorkflowPayload[];
  error?: ApolloError;
  fetchMore?: {
    hasMore: boolean;
    fetchMoreWorkflows: () => Promise<void>;
  };
  filterControls?: FilterStates;
}

const initialContext: UnassignedWorkflowContextType = {
  loading: false,
  workflows: [],
  error: undefined,
  fetchMore: {
    hasMore: false,
    fetchMoreWorkflows: async () => undefined,
  },
};

const excludeStates = [
  'expired',
  'provisioned',
  'rejected',
  'canceled',
  'check-for-duplicate-account',
  'wrong-employer-check',
];

export type Order = 'ASC' | 'DESC';

export const UnassignedWorkflowContext =
  createContext<UnassignedWorkflowContextType>(initialContext);

type getWorkflowFilterQueryProps = {
  pageNumber: number;
  pageSize?: number;
  order: Order;
  excludeStates?: string[];
  orderBy: keyof WorkflowPayload;
  state?: AMT_PATHWAY_TRANSITION_STATES;
  patientUuid?: string;
};

const getWorkflowFilterQueryDefaultProps: getWorkflowFilterQueryProps = {
  pageNumber: 1,
  pageSize: PAGE_SIZE,
  excludeStates,
  orderBy: 'updatedAt',
  order: 'ASC',
};

const getUnassignedWorkflowFilterQuery = ({
  ...props
} = getWorkflowFilterQueryDefaultProps): GetUnassignedWorkflowsByFilterQueryVariables => ({
  workflowFiltersInput: {
    workflowTypes: [ApplicationManagementToolWorkflowTypes.PathwayTransition],
    pageSize: PAGE_SIZE,
    ...props,
    adminUuids: [],
  },
});

export const UnassignedWorkflowProvider = ({
  children,
}: UnassignedWorkflowContextType): JSX.Element => {
  const [pageNumber, setPageNumber] = useState(1);
  const [orderBy, setOrderBy] = useState<keyof WorkflowPayload>('updatedAt');
  const [filterState, setFilterState] = useState<
    AMT_PATHWAY_TRANSITION_STATES | undefined
  >();
  const [patientUuid, setPatientUuid] = useState<string | undefined>();
  const [patientId, setPatientId] = useState<string | undefined>();
  const [order, setOrder] = useState<Order>('ASC');
  const [getWorkflowsByFilter, { data, loading, error, fetchMore }] =
    useGetUnassignedWorkflowsByFilterLazyQuery();

  const filterData = (workflow: WorkflowPayload): boolean => {
    if (!!workflow?.adminUuids?.length) {
      return false;
    }
    if (!!patientId) {
      return (
        typeof workflow.patient?.id === 'string' &&
        workflow.patient.id.startsWith(patientId)
      );
    }
    return true;
  };

  useEffect(() => {
    getWorkflowsByFilter({
      variables: getUnassignedWorkflowFilterQuery({
        pageNumber,
        excludeStates,
        orderBy,
        state: filterState,
        patientUuid,
        order,
      }),
      errorPolicy: 'all',
      returnPartialData: true,
      fetchPolicy: 'no-cache',
    });
  }, [
    getWorkflowsByFilter,
    pageNumber,
    order,
    orderBy,
    patientUuid,
    filterState,
  ]);

  return (
    <UnassignedWorkflowContext.Provider
      value={{
        error,
        loading,
        workflows: (data?.getWorkflowsByFilter?.workflows.filter(filterData) ||
          []) as WorkflowPayload[],
        fetchMore: {
          hasMore: false,
          fetchMoreWorkflows: async (): Promise<void> => {
            const newPageNumber = pageNumber + 1;
            await fetchMore({
              variables: {
                ...getUnassignedWorkflowFilterQuery({
                  pageNumber: newPageNumber,
                  excludeStates,
                  orderBy,
                  state: filterState,
                  patientUuid,
                  order,
                  pageSize: PAGE_SIZE,
                }),
              },
            });
            setPageNumber(newPageNumber);
          },
        },
        filterControls: {
          filterState: [filterState, setFilterState],
          orderBy: [orderBy, setOrderBy],
          patientUuid: [patientUuid, setPatientUuid],
          order: [order, setOrder],
          patientId: [patientId, setPatientId],
        },
      }}
    >
      {children}
    </UnassignedWorkflowContext.Provider>
  );
};

export const useUnassignedWorkflowContext = (): UnassignedWorkflowContextType =>
  useContext(UnassignedWorkflowContext);
