import { HHDivider, HHStack } from '@hinge-health/react-component-library';
import dayjs from 'dayjs';
import { ActiveContractLabels } from '../../constants/strings/billing-label-enums';
import { ContractPackagesType, ContractTemplateType } from '../../types';
import { formatCurrency } from '../../utils/currency-helpers';
import { computeEffectiveRange } from '../../utils/date-helper';
import { TextGrouping } from '../utils/text-grouping';
import { UsableContractType } from './form/custom-types';
import { Milestones } from './milestones';

interface LegacyContractDetailViewProps {
  data: UsableContractType;
  templates: ContractTemplateType[];
  showJson?: boolean;
  inPersonVisitContractPackage: ContractPackagesType | undefined;
  slimDisplay?: boolean;
}

const LegacyDetailView = ({
  data,
  templates,
  inPersonVisitContractPackage,
  slimDisplay = false,
}: LegacyContractDetailViewProps): JSX.Element => (
  <HHStack
    direction={slimDisplay ? 'column' : { xs: 'column', md: 'row' }}
    spacing={2}
  >
    <HHStack direction="column" flex={1} spacing={4}>
      <TextGrouping
        label={ActiveContractLabels.EFFECT_DATE_LABEL}
        text={computeEffectiveRange(data.startDate, data.endDate)}
      />
      <TextGrouping
        label={ActiveContractLabels.CONTRACT_TEMPLATE_LABEL}
        text={
          templates.find(({ id }) => id === data.contractTemplateId)?.name ??
          'No matching template id'
        }
      />
      <TextGrouping
        label={ActiveContractLabels.CURRENCY}
        text={data.currency ?? 'Currency not found'}
      />
    </HHStack>
    <HHStack direction="row" flex={2}>
      <HHStack direction="column" flex={1} spacing={4}>
        <TextGrouping
          label={ActiveContractLabels.CHRONIC_CORE_CHARGE_LABEL}
          text={formatCurrency(data.chronicPrice ?? 0, data?.currency)}
        />
        {data.acutePrice ? (
          <TextGrouping
            label={ActiveContractLabels.ACUTE_CHARGE_LABEL}
            text={formatCurrency(data.acutePrice, data?.currency)}
          />
        ) : null}
      </HHStack>
      {inPersonVisitContractPackage ? (
        <HHStack direction="column" flex={1} spacing={4}>
          <TextGrouping
            label={ActiveContractLabels.IN_PERSON_VISIT_CHARGE_LABEL}
            text={formatCurrency(
              inPersonVisitContractPackage.price,
              data?.currency,
            )}
          />
          <TextGrouping
            label={ActiveContractLabels.IN_PERSON_VISIT_EFFECTIVE_DATE_LABEL}
            text={`${dayjs(inPersonVisitContractPackage?.startDate).format(
              'L',
            )}`}
          />
        </HHStack>
      ) : null}
    </HHStack>
  </HHStack>
);

const LegacyContractDetailView = ({
  data,
  templates,
  inPersonVisitContractPackage,
  slimDisplay = false,
}: LegacyContractDetailViewProps): JSX.Element => (
  <HHStack
    direction="column"
    spacing={4}
    padding={4}
    sx={{ backgroundColor: 'background.default' }}
  >
    <LegacyDetailView
      data={data}
      templates={templates}
      inPersonVisitContractPackage={inPersonVisitContractPackage}
      slimDisplay={slimDisplay}
    />
    <HHDivider />
    <Milestones
      contractDetails={data.contract ?? []}
      templateName={
        templates.find(({ id }) => id === data.contractTemplateId)?.name ??
        'No matching template id'
      }
      allBillableActivities={data.billableActivityTypes ?? []}
      slimDisplay={slimDisplay}
      currency={data.currency ?? 'USD'}
    />
  </HHStack>
);

export default LegacyContractDetailView;
