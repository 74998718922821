import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Grid, TextField } from '@mui/material';

type ColumnMappingsSectionProps = {
  firstName: string;
  setFirstName: (value: string) => void;
  lastName: string;
  setLastName: (value: string) => void;
  dob: string;
  setDob: (value: string) => void;
  externalId: string;
  setExternalId: (value: string) => void;
  errors: { [key: string]: string };
};

export const ColumnMappingsSection = (
  props: ColumnMappingsSectionProps,
): JSX.Element => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    dob,
    setDob,
    externalId,
    setExternalId,
    errors,
  } = props;

  return (
    <>
      <Grid container spacing={2} mt={2} alignItems="center">
        <Grid item xs={12} sm={2}>
          <TextField
            label="First Name"
            value="first_name"
            InputProps={{
              readOnly: true,
              style: {
                height: '50px',
              },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={1} textAlign="center">
          <ArrowForwardIcon />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="First Name"
            placeholder="Enter First Name"
            value={firstName}
            onChange={(e): void => setFirstName(e.target.value)}
            fullWidth
            error={!!errors.firstName}
            helperText={errors.firstName}
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2} alignItems="center">
        <Grid item xs={12} sm={2}>
          <TextField
            label="Last Name"
            value="last_name"
            InputProps={{
              readOnly: true,
              style: {
                height: '50px',
              },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={1} textAlign="center">
          <ArrowForwardIcon />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Last Name"
            placeholder="Enter Last Name"
            value={lastName}
            onChange={(e): void => setLastName(e.target.value)}
            fullWidth
            error={!!errors.lastName}
            helperText={errors.lastName}
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2} alignItems="center">
        <Grid item xs={12} sm={2}>
          <TextField
            label="Date of Birth"
            value="date_of_birth"
            InputProps={{
              readOnly: true,
              style: {
                height: '50px',
              },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={1} textAlign="center">
          <ArrowForwardIcon />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Date of Birth"
            placeholder="Enter Date of Birth"
            value={dob}
            onChange={(e): void => setDob(e.target.value)}
            fullWidth
            error={!!errors.dob}
            helperText={errors.dob}
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2} alignItems="center">
        <Grid item xs={12} sm={2}>
          <TextField
            label="External Identifier"
            value="external_id"
            InputProps={{
              readOnly: true,
              style: {
                height: '50px',
              },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={1} textAlign="center">
          <ArrowForwardIcon />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="External Identifier"
            placeholder="Enter External Identifier"
            value={externalId}
            onChange={(e): void => setExternalId(e.target.value)}
            fullWidth
            error={!!errors.externalId}
            helperText={errors.externalId}
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
