import dayjs from 'dayjs';
import {
  CleanBillableActivities,
  UsableContractType,
} from '../components/contract/form/custom-types';
import {
  ContractInnerPackageFormValues,
  ContractPackageFormValues,
  NewBillableActivityTypeObject,
  SimpleOfferingType,
} from '../components/contract/package/custom-package-types';
import {
  billableActivitiesMap,
  billableActivitiesMapv2,
} from '../constants/strings/contract/form-constants';
import {
  BillableActivityType,
  ContractPackagesType,
  ContractType,
} from '../types';
import {
  capitalizeFirstLetterAndSpaceCamelCaseString,
  capitalizeFirstLetterAndSpaceSnakeString,
} from './bills-utils';

export const isBillableActivitiesWithDetails = (
  bat: BillableActivityType[],
): boolean =>
  bat.some(activity => activity.details && activity.details !== null);

export const isBillableActivityType = (
  bat: number[] | BillableActivityType[],
): bat is BillableActivityType[] =>
  (bat as BillableActivityType[]).every(
    (ba: BillableActivityType) => ba.id !== undefined,
  );

export const normalizeBillableActivityTypesForComparison = (
  bat: number[] | NewBillableActivityTypeObject | BillableActivityType[],
): NewBillableActivityTypeObject | number[] => {
  if (!Array.isArray(bat)) {
    return bat;
  }

  if (!isBillableActivityType(bat)) {
    return bat.toSorted((a, b) => a - b);
  }

  if (isBillableActivitiesWithDetails(bat)) {
    return bat.reduce((acc: NewBillableActivityTypeObject, activity) => {
      acc[activity.id] = activity.details;
      return acc;
    }, {});
  }
  return bat.map(activity => activity.id).sort((a, b) => a - b);
};

export const normalizePackagesAndFormForComparison = (
  item: ContractInnerPackageFormValues | ContractPackagesType,
): ContractInnerPackageFormValues => ({
  billingModelId: item.billingModelId,
  packageId: item.packageId,
  price: item.price,
  billableActivityTypes:
    // Can be 3 different shapes
    // 1. [1, 2, 3] -- old style (ui create/edit)
    // 2. [{id: 1, details: {dailyCap: 1}, ...},  {id: 3, details: null, ...}] -- new db shape
    // 3. {1: {dailyCap: 1}, 2: null} -- new ui shape (for daily cap feature)
    normalizeBillableActivityTypesForComparison(item.billableActivityTypes),
  rules: {
    activationFee: item.rules?.activationFee ?? 0,
    activityFee: item.rules?.activityFee ?? 0,
  },
  startDate: dayjs(item.startDate).format('YYYY-MM-DD'),
});

export const normalizeContractAndFormForComparison = (
  item: ContractType | ContractPackageFormValues,
): Pick<
  ContractPackageFormValues,
  | 'endDate'
  | 'startDate'
  | 'userAnnualCap'
  | 'void'
  | 'currency'
  | 'partnershipId'
> => ({
  startDate: dayjs(item.startDate).format('YYYY-MM-DD'),
  endDate: item.endDate ? dayjs(item.endDate).format('YYYY-MM-DD') : null,
  void: item.void,
  userAnnualCap: item?.userAnnualCap ?? 0,
  currency: item?.currency ?? 'USD',
  partnershipId: item?.partnershipId ?? null,
});

export const currentPackageAndFormValuesMatch = (
  currentPackages: ContractPackagesType[],
  formValues: ContractInnerPackageFormValues[],
): boolean => {
  if (currentPackages.length !== formValues.length) return false;

  const normalizedCurrent = currentPackages.map(
    normalizePackagesAndFormForComparison,
  );

  const normalizedFormValues = formValues.map(
    normalizePackagesAndFormForComparison,
  );

  return (
    JSON.stringify(normalizedCurrent) === JSON.stringify(normalizedFormValues)
  );
};

export const currentContractAndFormValuesMatch = (
  currentContract: UsableContractType,
  formValues: ContractPackageFormValues,
): boolean => {
  const normalizedCurrent =
    normalizeContractAndFormForComparison(currentContract);
  const normalizedFormValues =
    normalizeContractAndFormForComparison(formValues);

  return (
    JSON.stringify(normalizedCurrent) === JSON.stringify(normalizedFormValues)
  );
};

/**
 * @param programIndications
 *split into x number of arrays based on first word in indication,
 *return object of arrays:
 * @returns Record<string, PackageOfferingType>[]
 */
export const categorizePackageIndications = (
  programIndications: Record<string, string>[],
): Record<string, SimpleOfferingType[]> =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  programIndications.reduce((acc, curr) => {
    const splitPi = curr.programIndication?.split('_') ?? [];
    if (splitPi.length === 0) {
      return acc;
    }

    const program = splitPi[0];
    return {
      ...acc,
      [program]: [...(acc[program] ?? []), curr] as SimpleOfferingType[],
    };
  }, {});

/**
 * @param indicationList
 * i.e [{programIndication: 'chronic_pelvic_pain'}, {programIndication: 'chronic_back'}]
 * @returns string
 * i.e 'Pelvic Pain, Back'
 **/
export const listifyIndications = (
  indicationList: SimpleOfferingType[],
): string =>
  indicationList
    .map(val =>
      capitalizeFirstLetterAndSpaceSnakeString(
        val.programIndication?.split('_').splice(1).join('_') ?? '',
      ),
    )
    .join(', ');

/**
 *
 * @param ba string | BillableActivityType | CleanBillableActivities
 * @returns string
 * take in a string 'enso_session' or {id: 1, name: 'enso_session', details: {dailyCap: 1}}
 * returns 'Enso session' or 'Enso session: Daily Cap 1'
 * TODO: remove featureEnabled when new billing activities are fully enabled
 */
export const getBillableActivityDisplayString = (
  ba: string | BillableActivityType | CleanBillableActivities,
  featureEnabled: boolean,
): string => {
  if (featureEnabled && typeof ba !== 'string') {
    const activityName = billableActivitiesMap[ba.name];
    const detail = ba.details
      ? `: ${capitalizeFirstLetterAndSpaceCamelCaseString(Object.keys(ba.details)[0])} ${ba.details[Object.keys(ba.details)[0]]}`
      : '';
    return ba.details && ba.details !== null
      ? `${activityName}${detail}`
      : billableActivitiesMapv2[ba.name];
  }
  if (typeof ba === 'string') {
    return featureEnabled
      ? billableActivitiesMapv2[ba]
      : billableActivitiesMap[ba];
  }

  return billableActivitiesMap[ba.name];
};
