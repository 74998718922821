import { Grid, TextField } from '@mui/material';

type ClientInformationSectionProps = {
  partnership: string;
  setPartnership: (value: string) => void;
  client: string;
  setClient: (value: string) => void;
  insurer: string;
  setInsurer: (value: string) => void;
  errors: { [key: string]: string };
};

export const ClientInformationSection = (
  props: ClientInformationSectionProps,
): JSX.Element => {
  const {
    partnership,
    setPartnership,
    client,
    setClient,
    insurer,
    setInsurer,
    errors,
  } = props;

  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Partnership"
            placeholder="Partnership"
            value={partnership}
            onChange={(e): void => setPartnership(e.target.value)}
            fullWidth
            error={!!errors.partnership}
            helperText={errors.partnership}
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Client"
            placeholder="Client"
            value={client}
            onChange={(e): void => setClient(e.target.value)}
            fullWidth
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Insurer"
            placeholder="Insurer"
            value={insurer}
            onChange={(e): void => setInsurer(e.target.value)}
            fullWidth
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
