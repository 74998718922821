import { buttonLabels } from '../../constants/hinge-connect-constants.constant';
import { assessmentTasksTabContent } from '../constants/assessment-tasks.constant';
import { AssessmentTaskMessage } from './assessment-task-message';

interface AssessmentTaskLoadErrorProps {
  handleRetry: () => void;
}
export const AssessmentTaskLoadError = ({
  handleRetry,
}: AssessmentTaskLoadErrorProps): JSX.Element => (
  <AssessmentTaskMessage
    title={assessmentTasksTabContent.taskLoadError.title}
    body={assessmentTasksTabContent.taskLoadError.body}
    buttonText={buttonLabels.tryAgain}
    buttonProps={{
      hhVariant: 'contained',
      onClick: () => handleRetry(),
    }}
  />
);
