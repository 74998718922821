import { HHBox, HHButton, HHGrid } from '@hinge-health/react-component-library';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DATE_FORMAT } from '../constants/dates';
import { AMT_STRINGS } from '../constants/strings/application-management-tool';
import { useShellContext } from '../contexts/shell.context';
import { PathwayTransitionDecoratedWorkflow } from '../custom-types';
import {
  GetUnassignedWorkflowsByFilterDocument,
  useUpdateWorkflowMutation,
} from '../types';
import { statesMap } from './amt-sidebar/states';
import { ApplicationManagementToolCardDate } from './application-management-tool-card-date';
import { ApplicationManagementToolCardSpacer } from './application-management-tool-card-spacer';
import { useCardStyles } from './styles/card-styles';

dayjs.extend(relativeTime);

export interface ApplicationManagementToolInboxCardProps {
  workflow: PathwayTransitionDecoratedWorkflow;
  rowNumber: number;
}

export const ApplicationManagementToolInboxCard = (
  props: ApplicationManagementToolInboxCardProps,
): JSX.Element => {
  const { workflow, rowNumber } = props;
  const { patient, latestTaskDate, dueState, updatedAt } = workflow;
  const classes = useCardStyles();
  const [updateWorkflowMutation] = useUpdateWorkflowMutation();
  const { adminProfile } = useShellContext();
  // This should show a list of workflows with fields:
  // user_id, (screening_id if we have it, ignore if not), workflow state, updated_at, due_date, Assign to Me
  return (
    <HHBox
      className={`${classes.dividerBox} ${
        !workflow.viewed ? classes.bold : ''
      }`}
    >
      <HHGrid container spacing={0} className={classes.cardRow}>
        <HHGrid item xs={1} className={classes.spacer} />
        <HHGrid item xs={1} className={classes.cardGridItem}>
          {rowNumber}
        </HHGrid>
        <HHGrid item xs={1} className={classes.cardGridItem}>
          {patient?.id}
        </HHGrid>
        <HHGrid item xs={2} className={classes.cardGridItem}>
          {statesMap.get(workflow.state)}
        </HHGrid>
        <HHGrid item xs={1} className={classes.centeredColumn}>
          {dayjs(updatedAt).format(DATE_FORMAT)}
        </HHGrid>
        <HHGrid item xs={3} className={classes.centeredColumn}>
          <ApplicationManagementToolCardDate
            dueState={dueState}
            dueDate={dayjs(latestTaskDate)}
          />
        </HHGrid>
        <HHGrid item xs={2} className={classes.cardGridItem}>
          <HHButton
            hhVariant="contained"
            onClick={(e): void => {
              e.stopPropagation();
              if (adminProfile?.uuid) {
                updateWorkflowMutation({
                  variables: {
                    updateWorkflowInputDto: {
                      workflowId: workflow.id,
                      viewed: true,
                      adminUuids: [adminProfile.uuid],
                    },
                    workflowType: workflow.type,
                  },
                  refetchQueries: [GetUnassignedWorkflowsByFilterDocument],
                });
              } else {
                console.error('your profile is not loaded');
              }
            }}
          >
            {AMT_STRINGS.ASSIGN_TO_ME_BUTTON}
          </HHButton>
        </HHGrid>
      </HHGrid>
      <ApplicationManagementToolCardSpacer />
    </HHBox>
  );
};
