import {
  HHBox,
  HHCircularProgress,
  HHGrid,
  HHSnackbarAlert,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useParams } from 'react-router-dom';
import {
  buttonLabels,
  notFoundErrorMarker,
} from '../../constants/hinge-connect-constants.constant';
import { useSnackbar } from '../../hooks/use-snackbar';
import { ruleSnackbars, rulesTabContent } from '../constants/rules.constant';
import { useUpdateRule } from '../hooks/use-update-rule';
import { UpdateRuleInput, useGetRuleQuery } from '../types';
import { RuleForm } from './rule-form';
import { RuleLoadError } from './rule-load-error';
import { RuleNotFound } from './rule-not-found';

const UpdateRuleViewContainer = (): JSX.Element => {
  const params = useParams();

  const {
    data: ruleData,
    loading: ruleLoading,
    error: ruleError,
    refetch,
  } = useGetRuleQuery({ variables: { id: params.id as string } });

  const { updateRule, data, loading, error, errorMessages } = useUpdateRule();

  const { snackbar, handleClose } = useSnackbar({
    data,
    error,
    errorMessages,
    successMessage: ruleSnackbars.update,
  });

  const onSubmit = (updateRuleInput: UpdateRuleInput): void => {
    updateRule({ variables: { id: params.id as string, updateRuleInput } });
  };

  if (ruleLoading) return <HHCircularProgress />;
  if (ruleData)
    return (
      <HHGrid container>
        <HHSnackbarAlert
          message={snackbar.message}
          severity={snackbar.severity}
          verticalAlignment="top"
          horizontalAlignment="center"
          hhVariant="standard"
          open={snackbar.open}
          onClose={handleClose}
        />
        <HHGrid item xs={8} marginBottom={'1rem'}>
          <HHTypography hhVariant="h2">
            {rulesTabContent.updateRule.title}
          </HHTypography>
        </HHGrid>
        <HHGrid item xs={12}>
          <RuleForm
            title={rulesTabContent.updateRule.title}
            loading={loading}
            onSubmit={onSubmit}
            buttonText={buttonLabels.update}
            rule={ruleData.getRule}
          />
        </HHGrid>
      </HHGrid>
    );
  if (ruleError?.graphQLErrors[0]?.message.includes(notFoundErrorMarker))
    return <RuleNotFound />;
  return <RuleLoadError handleRetry={refetch} />;
};

export const UpdateRuleView = (): JSX.Element => (
  <HHBox paddingY="1rem" display="flex" justifyContent="center">
    <UpdateRuleViewContainer />
  </HHBox>
);
