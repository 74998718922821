import { HHTypography } from '@hinge-health/react-component-library';
import ContactPageOutlined from '@mui/icons-material/ContactPageOutlined';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      padding: '0.5em 0.5em',
    },
    avatar: {
      borderRadius: '10%',
    },
  }),
);

export const BILLING_CONTRACT_BATCH_HEADER_TEST_ID =
  'contract-batch-header-test-id';

const BillingBatchContractHeader = ({
  suppressHeader,
}: {
  suppressHeader?: boolean;
}): JSX.Element => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();

  const clientInsurerIds = searchParams.get('ids')?.split(',');

  if (!clientInsurerIds) {
    return suppressHeader ? (
      <Stack display="none"></Stack>
    ) : (
      <HHTypography hhVariant="body">Client insurers not found</HHTypography>
    );
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      className={classes.root}
      spacing={4}
      data-testid={BILLING_CONTRACT_BATCH_HEADER_TEST_ID}
    >
      <Avatar variant="square" className={classes.avatar}>
        <ContactPageOutlined />
      </Avatar>
      <HHTypography hhVariant="body">{'Batch client insurers'}</HHTypography>
    </Stack>
  );
};

export default BillingBatchContractHeader;
