import {
  HHButton,
  HHDateRangePicker,
  HHStack,
  HHTextField,
} from '@hinge-health/react-component-library';
import { Autocomplete } from '@mui/material';
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  SyntheticEvent,
} from 'react';
import { DateRangePickerValue } from '../../../components/date-range-picker';
import { datePicker } from '../../../constants/hinge-connect-constants.constant';
import {
  healthRecordsFilterLabels,
  healthRecordsSourceAutoCompleteOptions,
} from '../../constants/health-records.constant';

interface HealthRecordsToolbarProps {
  source: string;
  setSource: Dispatch<SetStateAction<string>>;
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
  resourceType: string;
  setResourceType: Dispatch<SetStateAction<string>>;
  dateRange: [DateRangePickerValue, DateRangePickerValue];
  setDateRange: Dispatch<
    SetStateAction<[DateRangePickerValue, DateRangePickerValue]>
  >;
  handleSearch: () => void;
}

export const HealthRecordsToolbar = ({
  source,
  setSource,
  limit,
  setLimit,
  resourceType,
  setResourceType,
  dateRange,
  setDateRange,
  handleSearch,
}: HealthRecordsToolbarProps): JSX.Element => {
  const handleSourceChange = (
    _e: SyntheticEvent,
    option: string | { label: string; value: string } | null,
  ): void => {
    if (typeof option === 'string') {
      setSource(option);
      return;
    }
    setSource(option?.value ?? '');
  };

  const handleLimitChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newLimit = Number(e.target.value);
    if (newLimit < 1) {
      setLimit(1);
      return;
    }
    if (newLimit > 100) {
      setLimit(100);
      return;
    }
    setLimit(newLimit);
  };

  const handleClear = (): void => {
    setSource('');
    setResourceType('');
    setDateRange([null, null]);
    setLimit(100);
  };

  const isClearDisabled =
    source === '' &&
    resourceType === '' &&
    dateRange[0] === null &&
    dateRange[1] === null &&
    limit === 100;

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    handleSearch();
  };

  return (
    <form onSubmit={handleSubmit}>
      <HHStack direction="row" spacing={6} padding="1rem">
        <Autocomplete
          value={source}
          freeSolo={true}
          renderInput={(params): JSX.Element => (
            <HHTextField
              {...params}
              hhVariant="variant-bypass"
              label={healthRecordsFilterLabels.source}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'source-select',
                'aria-label': healthRecordsFilterLabels.source,
              }}
              sx={{ width: '14rem' }}
              required
            />
          )}
          options={healthRecordsSourceAutoCompleteOptions}
          onChange={handleSourceChange}
          onInputChange={handleSourceChange}
        />
        <HHDateRangePicker
          localeText={{
            start: datePicker.earliest,
            end: datePicker.latest,
          }}
          onChange={(data): void => {
            setDateRange(data);
          }}
          value={dateRange}
          renderInput={(startProps, endProps): JSX.Element => (
            <HHStack direction="row" spacing={6}>
              <HHTextField hhVariant="variant-bypass" {...startProps} />
              <HHTextField hhVariant="variant-bypass" {...endProps} />
            </HHStack>
          )}
        />
        <HHTextField
          hhVariant="variant-bypass"
          inputProps={{ 'data-testid': 'resource-type-filter' }}
          value={resourceType}
          onChange={(e): void => setResourceType(e.target.value)}
          label={healthRecordsFilterLabels.resourceType}
          placeholder={healthRecordsFilterLabels.resourceType}
        />
        <HHTextField
          hhVariant="variant-bypass"
          type="number"
          value={limit}
          inputProps={{
            min: 1,
            max: 100,
            'data-testid': 'limit-filter',
          }}
          label={healthRecordsFilterLabels.limit}
          placeholder={healthRecordsFilterLabels.limit}
          onChange={handleLimitChange}
        />
        <HHButton
          hhVariant="variant-bypass"
          type="button"
          onClick={handleClear}
          disabled={isClearDisabled}
          data-testid="clear-button"
        >
          {healthRecordsFilterLabels.clear}
        </HHButton>
        <HHButton
          hhVariant="variant-bypass"
          type="submit"
          variant="contained"
          data-testid="search-button"
        >
          {healthRecordsFilterLabels.search}
        </HHButton>
      </HHStack>
    </form>
  );
};
