import { HHTooltip } from '@hinge-health/react-component-library';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useModal } from '../../../hooks/use-modal';
import { CopyRuleModal } from './copy-rule-modal';

export const CopyActionButton = ({
  ruleId,
  refetchListRules,
  copyRuleLoading,
}: {
  ruleId: string;
  ruleName: string;
  refetchListRules: () => void;
  copyRuleLoading: boolean;
}): JSX.Element => {
  const { isOpen, handleOpen, handleClose } = useModal();

  return (
    <>
      <CopyRuleModal
        ruleId={ruleId}
        isOpen={isOpen}
        handleClose={handleClose}
        refetchListRules={refetchListRules}
        copyRuleLoading={copyRuleLoading}
      />
      <HHTooltip hhVariant="bottom" title="Copy">
        <GridActionsCellItem
          id="copy-rule-button"
          data-testid="copy-rule-button"
          label="Copy"
          icon={<CopyAllIcon />}
          color="primary"
          onClick={handleOpen}
          disabled={copyRuleLoading}
        />
      </HHTooltip>
    </>
  );
};
