import { HHButton } from '@hinge-health/react-component-library';
import InfoIcon from '@mui/icons-material/Info';
import { ListItemIcon, Tooltip } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { CleanClient, CleanInsurer } from '../contract/form/custom-types';

export interface ClientInsurerBatchCardProps {
  id: number;
  client: CleanClient | undefined;
  insurer: CleanInsurer | undefined;
  showDivider?: boolean;
  showButton?: boolean;
  showToolTip?: boolean;
  primaryTypographyProps?: Record<string, string>;
  onRemove?: (id: number) => void;
  toolTipInfo?: string;
}

export const BATCH_CLIENT_INSURER_REMOVE_TEST_ID =
  'batch-client-insurer-remove-test-id';

export const BATCH_CLIENT_INSURER_CARD_DIVIDER_TEST_ID =
  'batch-client-insurer-divider-test-id';

export const FAILED_CONTRACT_ERROR_TOOLTIP_TEST_ID =
  'failed-contract-error-tooltip-test-id';
export const ClientInsurerBatchCard = ({
  id,
  client,
  insurer,
  showDivider = true,
  showButton = true,
  showToolTip = false,
  primaryTypographyProps = {},
  onRemove,
  toolTipInfo,
}: ClientInsurerBatchCardProps): JSX.Element => {
  const removeClientInsurer = (): void => {
    if (onRemove) {
      onRemove(id);
    }
  };

  return (
    <>
      <Stack flexDirection={'row'}>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={client?.name}
            primaryTypographyProps={primaryTypographyProps}
            secondary={insurer?.name}
            secondaryTypographyProps={{ color: 'gray' }}
          />

          {showToolTip && toolTipInfo && (
            <ListItemIcon>
              <Tooltip title={toolTipInfo}>
                <InfoIcon
                  color="error"
                  data-testid={FAILED_CONTRACT_ERROR_TOOLTIP_TEST_ID}
                ></InfoIcon>
              </Tooltip>
            </ListItemIcon>
          )}
        </ListItem>
        {showButton ? (
          <HHButton
            hhVariant="variant-bypass"
            variant="text"
            size="small"
            color="primary"
            data-testid={BATCH_CLIENT_INSURER_REMOVE_TEST_ID}
            onClick={removeClientInsurer}
          >
            Remove
          </HHButton>
        ) : null}
      </Stack>
      {showDivider ? (
        <Divider
          variant="middle"
          component="li"
          data-testid={BATCH_CLIENT_INSURER_CARD_DIVIDER_TEST_ID}
        />
      ) : null}
    </>
  );
};
