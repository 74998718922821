import { buttonLabels } from '../../constants/hinge-connect-constants.constant';
import { rulesTabContent } from '../constants/rules.constant';
import { RuleMessage } from './rule-message';

interface RuleLoadErrorProps {
  handleRetry: () => void;
}
export const RuleLoadError = ({
  handleRetry,
}: RuleLoadErrorProps): JSX.Element => (
  <RuleMessage
    title={rulesTabContent.ruleLoadError.title}
    body={rulesTabContent.ruleLoadError.body}
    buttonText={buttonLabels.tryAgain}
    buttonProps={{
      hhVariant: 'contained',
      onClick: () => handleRetry(),
    }}
  />
);
