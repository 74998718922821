const set = <D>(key: string, data: D): void => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (err) {
    console.log('error setting item in local storage', err);
  }
};

const get = (key: string): unknown => {
  try {
    const response = localStorage.getItem(key);
    return response ? JSON.parse(response) : null;
  } catch (err) {
    console.log('error getting item from local storage', err);
  }
};

const remove = (key: string): void => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.log('error removing item from local storage', err);
  }
};

const clear = (): void => localStorage.clear();

export const LocalStorage = {
  get,
  set,
  remove,
  clear,
};
