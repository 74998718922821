import {
  HHButton,
  HHCheckbox,
  HHFormControlLabel,
  HHFormGroup,
  HHStack,
  HHTabs,
  HHTypography,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { GetSplitTreatmentOnOrOff } from '../../../../../components/splits';
import {
  CONTRACT_CANCEL_BUTTON_TEST_ID,
  CONTRACT_FORM_IN_PERSON_VISIT_CHECKBOX_ID,
  CONTRACT_FORM_TEST_ID,
  CONTRACT_SUBMIT_BUTTON_TEST_ID,
} from '../../../constants/strings/contract/form-constants';
import { contractPartnershipIdFlag } from '../../../constants/strings/split';
import { ActiveStatus, parseFormValues } from '../../../utils/contract-utils';
import LoadingComponent from '../../utils/loading';
import { AcuteInputs } from './components/acute-group';
import ContractFormAlert from './components/alert-banner';
import { BillableActivities } from './components/billable-activities';
import { ChronicCoreChargeInput } from './components/chronic-charge-input';
import { CurrencySelect } from './components/currency-select';
import { ContractDatePickers } from './components/date-pickers';
import { FeeForServiceConfig } from './components/fee-for-service';
import { MilestoneContractDetails } from './components/milestone-details';
import { ContractNotificationModal } from './components/notification-dialog';
import { PartnershipSelect } from './components/partnership-select';
import { ContractTemplateSelect } from './components/template-input';
import { VoidToggle } from './components/void';
import { WorkRequestNotification } from './components/work-request-notification';
import {
  ContractFormProps,
  RawFormValues,
  UsableContractType,
} from './custom-types';
import { useGetDefaultValues } from './use-get-default-values';
import { ContractValidationSchema } from './validation-schema';

export const ContractForm = ({
  formTitle,
  onSubmitCallback,
  onCancel,
  submitTitle = 'Submit Contract',
  currentContracts,
  startDateDisablePast = false,
  disableModalSubmit,
  submissionCount = 0,
  hideVoid = false,
  partnershipIds,
  wholeFormDisabled = false,
  activeStatus = ActiveStatus.Unknown,
  feeForServiceFormDisabled = false,
  disableSubmit = false,
  contractPackageIds = [],
  contractEditId,
}: ContractFormProps): JSX.Element => {
  const {
    templates,
    defaultStartDateCalenderDate,
    billableActivities,
    availablePackages,
    billingModels,
    networkErrors,
    defaultValues,
    contractPackageValues,
  } = useGetDefaultValues(currentContracts, partnershipIds, contractEditId);

  const methods = useForm({
    mode: 'onChange',
    values: defaultValues,
    context: {
      startDateDisablePast,
      submissionCount,
      contractEditId,
      currentContracts,
      templates,
    },
    resolver: yupResolver(ContractValidationSchema),
    criteriaMode: 'all',
  });

  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const adminId = hingeHealthAuthState?.accessToken?.claims.uid.toString();

  const contractPartnershipIdFlagEnabled = GetSplitTreatmentOnOrOff(
    contractPartnershipIdFlag,
    adminId,
  );

  const [isModalOpen, setModalOpen] = useState(false);

  const { formState, trigger, handleSubmit, getValues, reset, watch, control } =
    methods;
  const {
    touchedFields,
    isDirty,
    isValid,
    isSubmitted,
    isSubmitting,
    submitCount,
  } = formState;

  const { isVoid, startDate, endDate, enableInPersonVisit } = getValues();
  const watchedVoid = watch('isVoid');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contractPackages',
  });

  const submitWrapper = (data: RawFormValues): void => {
    if (isModalOpen) {
      setModalOpen(false);
    }
    onSubmitCallback(parseFormValues(data));
  };

  const onFormSubmit = (e: BaseSyntheticEvent<object> | undefined): void => {
    if (disableModalSubmit === true) {
      handleSubmit(submitWrapper)(e);
    } else {
      setModalOpen(true);
    }
  };

  const onModalCancel = (): void => {
    reset(getValues(), { keepValues: true, keepDirty: true });
    setModalOpen(false);
  };

  useEffect(() => {
    if (touchedFields.selectedBillableActivities) {
      trigger(); // FYI: Needed to validate 'null' end date
    }
  }, [formState, touchedFields, trigger]);

  return (
    <HHStack direction="column" data-testid={CONTRACT_FORM_TEST_ID}>
      {isModalOpen && !disableModalSubmit ? (
        <ContractNotificationModal
          open={isModalOpen}
          onConfirm={handleSubmit(submitWrapper)}
          onCancel={onModalCancel}
          onClose={onModalCancel}
          title={isVoid ? 'Void Contract ?' : 'Submit Changes ?'}
          confirmText={isVoid ? 'Void' : 'Submit Changes'}
          startDate={dayjs(startDate)}
          endDate={dayjs(endDate)}
          disableSubmit={
            disableSubmit ||
            (submitCount >= 1 && isSubmitted) ||
            !isDirty ||
            !isValid ||
            isSubmitting ||
            isSubmitted
          }
          isVoid={isVoid}
          contractPackageIds={contractPackageIds}
        />
      ) : null}
      {submissionCount >= 1 ? (
        <ContractFormAlert
          message={
            <WorkRequestNotification submissionCount={submissionCount} />
          }
        />
      ) : null}
      <FormProvider {...methods}>
        <form>
          <HHStack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <HHTypography hhVariant="section-title">{formTitle}</HHTypography>
          </HHStack>
          {networkErrors.templateError ||
          networkErrors.billableActivityError ||
          isSubmitting ? (
            <LoadingComponent center />
          ) : (
            <HHStack>
              <HHTabs
                tabsData={[
                  {
                    tabContent: (
                      <HHStack spacing={4}>
                        <ContractTemplateSelect
                          templates={templates}
                          wholeFormDisabled={wholeFormDisabled}
                        />
                        <CurrencySelect wholeFormDisabled={wholeFormDisabled} />
                        {contractPartnershipIdFlagEnabled && (
                          <PartnershipSelect
                            wholeFormDisabled={wholeFormDisabled}
                          />
                        )}
                        <ContractDatePickers
                          startDateDisablePast={startDateDisablePast}
                          defaultStartDateCalenderDate={
                            defaultStartDateCalenderDate
                          }
                          wholeFormDisabled={wholeFormDisabled}
                          currentContracts={currentContracts}
                          contractEditId={contractEditId}
                          submissionCount={submissionCount}
                          activeStatus={activeStatus}
                        />
                        <HHTypography hhVariant="h2">
                          Base Products
                        </HHTypography>
                        <HHStack width={'fit-content'}>
                          <ChronicCoreChargeInput
                            wholeFormDisabled={wholeFormDisabled}
                          />
                        </HHStack>
                        <VoidToggle
                          submissionCount={submissionCount}
                          hideVoid={hideVoid}
                        />
                        <BillableActivities
                          wholeFormDisabled={wholeFormDisabled}
                          billableActivities={billableActivities}
                          existingContract={
                            currentContracts.find(
                              c => c.id === contractEditId,
                            ) as UsableContractType
                          }
                        />
                        <MilestoneContractDetails
                          allBillableActivities={billableActivities}
                        />
                        <AcuteInputs wholeFormDisabled={wholeFormDisabled} />
                        <HHTypography hhVariant="body1" color={'GrayText'}>
                          Fee For Service Products
                        </HHTypography>
                        <HHFormGroup
                          data-testid={
                            CONTRACT_FORM_IN_PERSON_VISIT_CHECKBOX_ID
                          }
                        >
                          <HHFormControlLabel
                            label="Contract includes in person visits"
                            control={
                              <Controller
                                control={methods.control}
                                name="enableInPersonVisit"
                                render={({
                                  field: { onChange, value },
                                }): JSX.Element => (
                                  <HHCheckbox
                                    sx={{ color: 'gray' }}
                                    checked={value}
                                    onChange={(): void => {
                                      if (enableInPersonVisit) remove(0);
                                      else append(contractPackageValues);
                                      onChange(!enableInPersonVisit);
                                    }}
                                    disabled={
                                      feeForServiceFormDisabled || watchedVoid
                                    }
                                    hhVariant="primary"
                                  />
                                )}
                              />
                            }
                          />
                        </HHFormGroup>
                        {fields.map((field, index) => (
                          <FeeForServiceConfig
                            key={field.id}
                            index={index}
                            availablePackages={availablePackages}
                            billingModels={billingModels}
                            billableActivities={billableActivities}
                            feeForServiceFormDisabled={
                              feeForServiceFormDisabled
                            }
                          />
                        ))}
                      </HHStack>
                    ),
                    tabContentLayoutStyles: {
                      padding: 4,
                    },
                    tabLabel: 'Pricing',
                  },
                ]}
              />
              <HHStack direction="row" spacing={2} justifyContent="flex-end">
                <HHButton
                  data-testid={CONTRACT_CANCEL_BUTTON_TEST_ID}
                  hhVariant="variant-bypass"
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={onCancel}
                >
                  Cancel
                </HHButton>
                <HHButton
                  data-testid={CONTRACT_SUBMIT_BUTTON_TEST_ID}
                  hhVariant="variant-bypass"
                  variant="contained"
                  size="medium"
                  color="primary"
                  disabled={
                    disableSubmit ||
                    !isDirty ||
                    !isValid ||
                    isSubmitting ||
                    isSubmitted
                  }
                  onClick={onFormSubmit}
                >
                  {submitTitle}
                </HHButton>
              </HHStack>
            </HHStack>
          )}
        </form>
      </FormProvider>
    </HHStack>
  );
};
