import {
  HHAlert,
  HHBaseTable,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useBillingSetting } from '../../context/billing.context';
import { useDynamicDrawerContext } from '../../context/dynamic-drawer.context';
import { useGetSubscriptionHoldsByUserQuery } from '../../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../utils/bills-utils';
import { displayDateTime } from '../../utils/date-helper';
import MoreDetailsButton from '../utils/more-details-button';
import SubscriptionHoldDetails from './subscription-hold-details';

const SubscriptionHolds = ({ userId }: { userId: number }): JSX.Element => {
  const { value: displayLocal } = useBillingSetting('displayDateTimeLocal');
  const { setDrawerContent } = useDynamicDrawerContext();
  const { data, loading, error } = useGetSubscriptionHoldsByUserQuery({
    variables: { userId },
  });

  if (error && !loading) {
    return (
      <HHAlert
        hhVariant="outlined"
        severity="error"
        children={
          <HHTypography hhVariant="body">
            There was an error loading user holds
          </HHTypography>
        }
      ></HHAlert>
    );
  }

  return (
    <HHStack flex={1} spacing={4} direction="column" padding={1}>
      {!data || data?.getSubscriptionHoldsByUser.length === 0 ? (
        <HHTypography hhVariant="muted">No holds</HHTypography>
      ) : (
        <HHBaseTable
          size="medium"
          headerRowBottomBorder="1px solid rgba(0, 0, 0, 0.12)"
          bodyRowBorder={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
          bodyTypographyVariant="inherit"
          tableHeaders={[
            { value: 'Hold Reason', align: 'left' },
            { value: 'First Seen', align: 'left' },
            { value: 'Last Seen', align: 'left' },
            { value: 'Hit Count', align: 'left' },
            {
              value: '',
              align: 'left',
            },
          ]}
          bodyContent={data.getSubscriptionHoldsByUser.map(hold => ({
            eventType: {
              value: capitalizeFirstLetterAndSpaceSnakeString(hold.reason),
            },
            firstSeenAt: {
              value: displayDateTime(hold.firstSeenAt, {
                displayLocal,
              }),
            },
            lastSeenAt: {
              value: displayDateTime(hold.lastSeenAt, {
                displayLocal,
              }),
            },
            hitCount: {
              value: hold.hitCount,
            },
            details: {
              value: (
                <MoreDetailsButton
                  onClick={(): void =>
                    setDrawerContent(
                      <SubscriptionHoldDetails holdData={hold} />,
                      <HHTypography hhVariant="section-title">
                        Subscription Hold
                      </HHTypography>,
                    )
                  }
                />
              ),
            },
          }))}
        />
      )}
    </HHStack>
  );
};

export default SubscriptionHolds;
