import {
  HHAlert,
  HHStack,
  HHTypography,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { useParams } from 'react-router-dom';
import { GetSplitTreatmentOnOrOff } from '../../../components/splits';
import ClientDiscountsList from '../components/clients/client-discounts';
import ClientInsurerList from '../components/clients/client-insurer-list';
import LoadingComponent from '../components/utils/loading';
import { contractPartnershipIdFlag } from '../constants/strings/split';
import {
  useGetClientInsurersByIdsQuery,
  useGetContractsByClientIdQuery,
} from '../types';

export const CLIENT_BY_ID_CONTAINER_TEST_ID = 'client-by-id-container';

const ClientByIdPage = (): JSX.Element => {
  const params = useParams<{ id: string }>();
  const clientId = parseInt(params.id ?? '0', 10);
  const { data, loading, error } = useGetClientInsurersByIdsQuery({
    variables: { clientIds: [clientId] },
  });
  const {
    data: contractData,
    loading: contractLoading,
    error: contractError,
  } = useGetContractsByClientIdQuery({
    variables: {
      id: clientId,
    },
  });

  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const uid = hingeHealthAuthState?.accessToken?.claims?.uid.toString();
  const partnershipContractEnabled = GetSplitTreatmentOnOrOff(
    contractPartnershipIdFlag,
    uid,
  );

  if (loading || contractLoading) {
    return <LoadingComponent center />;
  }

  if (error || contractError) {
    return (
      <HHAlert hhVariant="outlined" severity="error">
        <HHTypography hhVariant="body">
          There was an error loading client insurer list
        </HHTypography>
      </HHAlert>
    );
  }

  if (!data?.getClientInsurersByIds || !contractData) {
    return (
      <HHStack spacing={2} overflow="hidden">
        <HHTypography hhVariant="body">Client info not found</HHTypography>
      </HHStack>
    );
  }

  let partnershipIds: Set<number> | undefined;
  if (partnershipContractEnabled) {
    partnershipIds = contractData?.getContracts?.reduce<Set<number>>(
      (acc, { partnershipId }) => {
        if (partnershipId) {
          acc.add(partnershipId);
        }
        return acc;
      },
      new Set<number>(),
    );
  } else {
    partnershipIds = data?.getClientInsurersByIds?.reduce<Set<number>>(
      (acc, { partnerships }) => {
        if (partnerships) {
          partnerships.forEach(partnership => {
            acc.add(partnership.id);
          });
        }
        return acc;
      },
      new Set<number>(),
    );
  }

  return (
    <HHStack
      data-testid={CLIENT_BY_ID_CONTAINER_TEST_ID}
      spacing={12}
      direction="column"
      padding={6}
    >
      <ClientInsurerList data={data?.getClientInsurersByIds} />
      <ClientDiscountsList
        clientId={clientId}
        partnershipIds={partnershipIds ? Array.from(partnershipIds) : undefined}
      />
    </HHStack>
  );
};

export default ClientByIdPage;
