import { buttonLabels } from '../../../constants/hinge-connect-constants.constant';
import { ruleAssessmentsTabContent } from '../../constants/rule-assessments.constant';
import { RuleAssessmentMessage } from './rule-assessment-message';

interface RuleAssessmentLoadErrorProps {
  handleRetry: () => void;
}

export const RuleAssessmentLoadError = ({
  handleRetry,
}: RuleAssessmentLoadErrorProps): JSX.Element => (
  <RuleAssessmentMessage
    title={ruleAssessmentsTabContent.loadErrorTitle}
    body={ruleAssessmentsTabContent.loadErrorBody}
    buttonText={buttonLabels.tryAgain}
    buttonProps={{
      hhVariant: 'contained',
      onClick: () => handleRetry(),
    }}
  />
);
