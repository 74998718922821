import { GridValueGetterParams } from '@mui/x-data-grid';
import { AssessmentTaskStatus } from '../constants/assessment-tasks.constant';

export const getAssessmentTaskCreatedAt = (
  params: GridValueGetterParams,
): string => params?.row?.createdAt || '';

export const getAssessmentTaskStatus = (
  params: GridValueGetterParams,
): AssessmentTaskStatus => params?.row?.status || '';

export const getAssessmentTaskSource = (
  params: GridValueGetterParams,
): string => params?.row?.arguments?.source || '';

export const getAssessmentTaskTotal = (params: GridValueGetterParams): number =>
  params?.row?.totalAssessments || 0;

export const getAssessmentTaskCompleted = (
  params: GridValueGetterParams,
): number => params?.row?.completed || 0;

export const getAssessmentTaskErrored = (
  params: GridValueGetterParams,
): number => params?.row?.errored || 0;

export const getAssessmentTaskActionsExecuted = (
  params: GridValueGetterParams,
): number => params?.row?.actionsExecuted || 0;

export const getAssessmentTaskAssertionsPassed = (
  params: GridValueGetterParams,
): number => params?.row?.assertionsPassed || 0;

export const getAssessmentTaskId = (params: GridValueGetterParams): string =>
  params?.row?.id || '';
