import { routes } from './routes';
import { acutePhysicalTherapist } from './strings/acute-physical-therapist';
import { billing } from './strings/billing';
import { eligibility } from './strings/eligibility';
import { hingeConnect } from './strings/hinge-connect/hinge-connect';
import { login } from './strings/login';
import { memberData } from './strings/member-data';
import { memberDataServices } from './strings/member-data-services';
import { patients } from './strings/patients';
import {
  targetedInterventions,
  targetedInterventionsEnrollment,
} from './strings/targeted-interventions';

const text = {
  login,
  patients,
  targetedInterventions,
  targetedInterventionsEnrollment,
  acutePhysicalTherapist,
  billing,
  hingeConnect,
  eligibility,
  memberData,
  memberDataServices,
};

export { text, routes };
