import {
  HHStack,
  HHTypography,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { GetSplitTreatmentOnOrOff } from '../../../../../../components/splits';
import {
  CleanBillableActivities,
  CleanBillingModel,
  UsableContractType,
} from '../../../../components/contract/form/custom-types';
import { newBillingActivitiesAndSubTypesEnabled } from '../../../../constants/strings/split';
import { getBillableActivitiesFromContractPackageOrDefault } from '../../../../utils/contract-utils';
import { PackageFormData } from '../../package/custom-package-types';
import LegacyBillableActivities from './billable-activities-legacy';
import BillableActivitiesWithSubTypes from './billable-activities-with-sub-types';
import { mapBillableActivitiesToRules } from './billable-activity-rules';

export interface BillableActivitiesProps {
  wholeFormDisabled: boolean | undefined;
  billableActivities: CleanBillableActivities[];
  direction?: string;
  isPackageForm?: boolean;
  billingModels?: CleanBillingModel[];
  index?: number;
  existingContract?: UsableContractType;
}

export const BillableActivities = ({
  wholeFormDisabled,
  billableActivities,
  direction,
  billingModels,
  isPackageForm = false,
  index = 0,
  existingContract,
}: BillableActivitiesProps): JSX.Element => {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<PackageFormData>();
  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const adminId = hingeHealthAuthState?.accessToken?.claims.uid.toString();

  // TODO remove with https://hingehealth.atlassian.net/browse/MINT-8511
  const newBillableActivitiesEnabled = GetSplitTreatmentOnOrOff(
    newBillingActivitiesAndSubTypesEnabled,
    adminId,
  );

  const watchedBillingModelId = watch(
    `selectedPackages.${index}.billingModelId`,
  );

  const billingModel = billingModels?.find(
    bm => bm.id === watchedBillingModelId,
  );

  const billingModelName = newBillableActivitiesEnabled
    ? (billingModel?.name ?? (isPackageForm ? 'engagement' : 'legacy'))
    : 'legacy';

  const billableActivitiesWithRules = mapBillableActivitiesToRules(
    billingModelName,
    billableActivities,
  );

  useEffect(() => {
    if (isPackageForm) {
      setValue(
        `selectedPackages.${index}.selectedBillableActivities`,
        getBillableActivitiesFromContractPackageOrDefault(
          billingModelName,
          billableActivitiesWithRules,
          index,
          existingContract,
        ),
        { shouldValidate: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedBillingModelId]);

  if (billableActivities.length === 0) {
    return (
      <HHTypography hhVariant="body" sx={{ color: 'red' }}>
        billable activities unable to load
      </HHTypography>
    );
  }

  return (
    <HHStack>
      <HHTypography hhVariant="caption" color={'GrayText'}>
        Billable Activities
      </HHTypography>
      {errors?.selectedPackages?.[index]?.selectedBillableActivities && (
        <HHTypography
          hhVariant="variant-bypass"
          sx={{ color: (theme): string => theme.palette.error.main }}
          variant="caption"
        >
          {
            errors?.selectedPackages?.[index]?.selectedBillableActivities
              ?.message
          }
        </HHTypography>
      )}
      {newBillableActivitiesEnabled && isPackageForm ? (
        <BillableActivitiesWithSubTypes
          wholeFormDisabled={wholeFormDisabled}
          billableActivities={billableActivitiesWithRules}
          direction={direction}
          isPackageForm={isPackageForm}
          index={index}
        />
      ) : (
        // TODO: remove with https://hingehealth.atlassian.net/browse/MINT-8511
        <LegacyBillableActivities
          wholeFormDisabled={wholeFormDisabled}
          billableActivities={billableActivitiesWithRules}
          direction={direction}
          isPackageForm={isPackageForm}
          index={index}
        />
      )}
    </HHStack>
  );
};
