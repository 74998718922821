import { WorkflowPayload } from './types';

type Note = {
  timestamp: Date;
  name: string;
  comment: string;
};

export type PathwayTransitionDecoratedWorkflow = WorkflowPayload & {
  dueState?: DueStates;
  indication?: string;
  customFields?: {
    applicationId: string;
    notes: Note[];
  };
};

export enum ApplicationManagementToolWorkflowTypes {
  PathwayTransition = 'pathway-transition',
}

export const encounterWorkflows: ApplicationManagementToolWorkflowTypes[] = [
  ApplicationManagementToolWorkflowTypes.PathwayTransition,
];

export const AMT_WORKFLOW_LIST = Object.values(
  ApplicationManagementToolWorkflowTypes,
);

export enum AMT_PATHWAY_TRANSITION_STATES {
  WRONG_EMPLOYER_CHECK = 'wrong-employer-check',
  PATIENT_WITH_WRONG_EMPLOYER = 'patient-with-wrong-employer',
  WAITING_FOR_EMAIL_INSURANCE_FORM_RESPONSE = 'waiting-for-email-insurance-form-response',
  RECEIVED_EMAIL_INSURANCE_FORM_RESPONSE = 'received-email-insurance-form-response',
  INELIGIBLE_INSURANCE = 'ineligible-insurance',
  CHECK_FOR_DUPLICATE_ACCOUNT = 'check-for-duplicate-account',
  IS_DUPLICATE_ACCOUNT = 'is-duplicate-account',
  WAITING_FOR_ASSIGNMENT = 'waiting-for-assignment',
  ADD_COACH_NOTE = 'add-coach-note',
  PROVISIONED = 'provisioned',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
}

export const AMT_STATES = {
  ...AMT_PATHWAY_TRANSITION_STATES,
};

export enum ApplicationManagementToolGroupType {
  Inbox = 'inbox',
  MyWorkflows = 'MyWorkflows',
}

export enum DueStates {
  Overdue = 'overdue',
  DueSoon = 'dueSoon',
  Default = 'default',
}

export enum ClosingActions {
  AcceptNewPathway,
  RejectNewPathway,
}

export const CLOSING_ACTIONS_LIST = [
  ClosingActions.AcceptNewPathway,
  ClosingActions.RejectNewPathway,
];
