import {
  HHButton,
  HHGrid,
  HHLoadingButton,
  HHSelect,
  HHSelectChangeEvent,
  HHStack,
} from '@hinge-health/react-component-library';
import { Autocomplete, TextField } from '@mui/material';
import {
  ChangeEvent,
  FormEvent,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  buttonLabels,
  sourceAutoCompleteOptions,
  statusOptions,
  tabRoutes,
} from '../../constants/hinge-connect-constants.constant';
import { csvToArray } from '../../utils/csv-to-array';
import { omitTypename } from '../../utils/omit-typename';
import { isValidJson } from '../components/json-utils';
import {
  ruleLocalStorageKeys,
  rulesRoutes,
  rulesTabContent,
  ruleTextFields,
} from '../constants/rules.constant';
import { RuleFormProps } from '../constants/types';
import { ActionDefinitionInput, RuleDefinition, RuleStatus } from '../types';
import { AceEditorComponent } from './ace-editor-component';

export const RuleForm = ({
  title,
  loading,
  onSubmit,
  buttonText,
  rule,
}: RuleFormProps): JSX.Element => {
  const navigate = useNavigate();
  const [source, setSource] = useState(rule?.source ?? '');
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [resourceTypes, setResourceTypes] = useState(
    rule?.resourceTypes?.join(', ') ?? '',
  );
  const [definition, setDefinition] = useState(
    rule?.definition
      ? JSON.stringify(omitTypename(rule.definition), null, 2)
      : '',
  );
  const [actions, setActions] = useState(
    rule?.actions
      ? JSON.stringify(rule.actions.map(omitTypename), null, 2)
      : '',
  );
  const [status, setStatus] = useState<RuleStatus | undefined>(rule?.status);

  const handleStatusChange = (e: HHSelectChangeEvent): void => {
    setStatus(e.target.value as RuleStatus);
  };

  const handleSourceChange = (
    _e: SyntheticEvent,
    option: string | { label: string; value: string } | null,
  ): void => {
    if (typeof option === 'string') {
      setSource(option);
      return;
    }
    setSource(option?.value ?? '');
  };

  const handleCancelClick = (): void => {
    localStorage.removeItem(ruleLocalStorageKeys.rule);
    navigate(`/${tabRoutes.baseRoute}/${tabRoutes.rules}/${rulesRoutes.list}`);
  };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    const ruleDto = {
      source,
      resourceTypes:
        !resourceTypes || resourceTypes?.trim() === ''
          ? []
          : csvToArray(resourceTypes),
      actions:
        actions && actions.trim() !== ''
          ? (omitTypename(JSON.parse(actions) ?? []) as ActionDefinitionInput[])
          : ([] as ActionDefinitionInput[]),
      definition:
        definition && definition.trim() !== ''
          ? (omitTypename(JSON.parse(definition) ?? {}) as RuleDefinition)
          : ({} as RuleDefinition),
      status: title === rulesTabContent.createRule.title ? undefined : status,
    };

    onSubmit(ruleDto);
  };

  useEffect(() => {
    if (!rule) {
      const localRule = JSON.parse(localStorage.getItem('rule') ?? '{}');
      setSource(localRule.source);
      setStatus(localRule.status);
      setResourceTypes(localRule.resourceTypes);
      if (localRule.definition) {
        setDefinition(JSON.stringify(localRule.definition, null, 2));
      }
      if (localRule.actions) {
        setActions(JSON.stringify(localRule.actions, null, 2));
      }
    }
  }, [rule]);

  useEffect(() => {
    localStorage.setItem(
      ruleLocalStorageKeys.rule,
      JSON.stringify({
        source,
        status,
        resourceTypes,
        definition:
          definition && isValidJson(definition)
            ? JSON.parse(definition)
            : definition,
        actions:
          actions && isValidJson(actions) ? JSON.parse(actions) : actions,
      }),
    );
    const readyToSubmit =
      !!source &&
      !!actions &&
      isValidJson(actions) &&
      !!definition &&
      isValidJson(definition);
    setSubmitEnabled(readyToSubmit);
  }, [source, actions, definition, status, resourceTypes]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    callback: React.Dispatch<React.SetStateAction<string>>,
  ): void => {
    callback(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit} data-testid="create-rule-form">
      <HHStack>
        <HHGrid container spacing={2}>
          <HHGrid item xs={4}>
            <Autocomplete
              value={source}
              freeSolo={true}
              renderInput={(params): JSX.Element => (
                <TextField
                  {...params}
                  label={ruleTextFields.source.label}
                  inputProps={{
                    ...params.inputProps,
                    'aria-label': ruleTextFields.source.label,
                    'data-testid': 'source-select',
                  }}
                  variant="outlined"
                />
              )}
              options={sourceAutoCompleteOptions}
              onChange={(e, option): void => handleSourceChange(e, option)}
              onInputChange={(e, option): void => handleSourceChange(e, option)}
            />
          </HHGrid>
          <HHGrid item xs={4}>
            <TextField
              fullWidth
              label={ruleTextFields.resourceTypes.label}
              inputProps={{
                'aria-label': ruleTextFields.resourceTypes.label,
              }}
              helperText={ruleTextFields.resourceTypes.helperText}
              value={resourceTypes}
              onChange={(e): void => handleInputChange(e, setResourceTypes)}
              variant="outlined"
            />
          </HHGrid>
          <HHGrid item xs={4}>
            <HHSelect
              sx={{ width: '100%' }}
              disabled={!status}
              initialSelectValue={status || 'DRAFT'}
              selectOptions={statusOptions}
              label={ruleTextFields.status.label}
              onChange={handleStatusChange}
              data-testid="status-select"
              variant="outlined"
              size="medium"
            />
          </HHGrid>
          <HHGrid item xs={6}>
            <AceEditorComponent
              label={ruleTextFields.definition.label}
              value={definition}
              helperText={ruleTextFields.definition.helperText}
              onChange={setDefinition}
              dataTestId="definition-editor"
              data-testid={ruleTextFields.definition.label}
            />
          </HHGrid>
          <HHGrid item xs={6}>
            <AceEditorComponent
              label={ruleTextFields.actions.label}
              value={actions}
              helperText={ruleTextFields.actions.helperText}
              onChange={setActions}
              dataTestId="actions-editor"
              data-testid={ruleTextFields.actions.label}
            />
          </HHGrid>
        </HHGrid>
        <HHStack direction="row" spacing={4} justifyContent={'right'}>
          <HHButton
            data-testid="cancel-button"
            hhVariant="text"
            sx={{ width: 'fit-content' }}
            onClick={handleCancelClick}
          >
            {buttonLabels.cancel}
          </HHButton>
          <HHLoadingButton
            hhVariant="variant-bypass"
            variant="contained"
            sx={{ width: 'fit-content' }}
            type="submit"
            data-testid="create-rule-button"
            disabled={!submitEnabled}
            loading={loading}
          >
            {buttonText}
          </HHLoadingButton>
        </HHStack>
      </HHStack>
    </form>
  );
};
