import { PartnershipType } from '../../../../../types';
import { ClientType, InsurerType, PaginatedBillType } from '../../../types';

export enum ReleasableHoldReasons {
  CARRIER_CHANGE = 'carrier_change',
  CLIENT_CHANGE = 'client_change',
  MANUAL_HOLD = 'manual_hold',
}

export enum NonReleasableHoldReasons {
  PARTNERSHIP_CHANGE = 'partnership_change',
  EXCEEDED_CLIENT_CAP = 'exceeded_client_cap',
  EXCEEDED_USER_CAP = 'exceeded_user_cap',
  LOSS_OF_COVERAGE = 'loss_of_coverage',
  MISSING_DATA = 'missing_data',
  MISSING_PRICE_INFO = 'missing_price_info',
  MISSING_PRIMARY_SUBSCRIBER = 'missing_primary_subscriber',
  DISABLED_PROGRAM_INDICATION = 'disabled_program_indication',
  REMOVED_FROM_PROGRAM = 'removed_from_program',
  SEQUENTIAL_SUBMISSION_HOLD = 'sequential_submission_hold',
  MISSING_CPT_CODE = 'missing_cpt_code',
  UNEXPECTED_PRICE = 'unexpected_price',
}

export const allHoldTypeVals = (): string[] => {
  const releaseVals = Object.values(ReleasableHoldReasons);
  const archiveVals = Object.values(NonReleasableHoldReasons);
  const allHoldTypeVals = [...releaseVals, ...archiveVals];
  return allHoldTypeVals;
};

export interface BillTypeData extends PaginatedBillType {
  client: {
    id: number | null;
    name: string | null;
    loading: boolean;
    isErrored: boolean;
  };
  insurer: {
    id: number | null;
    name: string | null;
    loading: boolean;
    isErrored: boolean;
  };
  partnership: {
    id: number | null;
    name: string | null;
    loading: boolean;
    isErrored: boolean;
  };
  billingType: {
    value: string | null;
    loading: boolean;
    isErrored: boolean;
  };
}
export type BillTypeDataList = BillTypeData[];

/**
 *  maps to grid header display names
 * */
export enum GridColumnHeaderNames {
  CLAIM_ID = 'Claim ID',
  HOLD_REASON = 'Hold Reason',
  AMOUNT = 'Amount',
  USER_ID = 'User ID',
  SUBSCRIPTION_ID = 'Subscription ID',
  DATE_OF_SERVICE = 'Date of Service',
  CLIENT = 'Client',
  INSURER = 'Insurer',
  PARTNERSHIP = 'Partnership',
  BILL_TYPE = 'Bill Type',
}

/**
 * maps to data field that grid uses to populate grid data
 * When adding new fields,
 * make sure to add them to the `billHoldsColumns` array
 */
export enum GridColumnFields {
  CLAIM_ID = 'claimId',
  HOLD_REASON = 'holdDetails',
  AMOUNT = 'billAmount',
  USER_ID = 'userId',
  SUBSCRIPTION_ID = 'subscriptionId',
  DATE_OF_SERVICE = 'dateOfService',
  CLIENT = 'client',
  INSURER = 'insurer',
  PARTNERSHIP = 'partnership',
  BILLING_TYPE = 'billingType',
  ACTIONS = 'actions',
}

export interface BillHoldsItemsType {
  billId: number;
  claimId: string;
  billAmount: string;
  userId: number;
  subscriptionId: number;
  dateOfService: string;
  clientsInsurerId: number;
  holdDetails: {
    note: string | null;
    holdType: string;
    clientId: number;
    insurerId: number;
  };
}

export interface BillHoldsApiDataType {
  items: BillHoldsItemsType[];
  total: number;
  page: number;
  size: number;
  pages: number;
}

export interface GridSnackBarState {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
  hideTime?: number;
}

/**
 * UI filtering for bill holds grid
 * Shape of filters in the ui
 */
export interface BillHoldsGridFilterModelType {
  holdReasons: string[];
  startDate: string | null;
  endDate: string | null;
  claimIds: string[];
  userIds: number[];
  subscriptionIds: number[];
  clientIds: number[];
  insurerIds: number[];
  partnershipIds: number[];
  billingTypes: string[];
}

export interface RawAdvancedFiltersValues {
  claimIds: string[];
  holdReasons: AutocompleteSelectOption[] | [];
  userIds: string[] | [];
  subscriptionIds: string[];
  dateRange: (Date | null)[];
  clients: ClientType[] | [];
  insurers: InsurersType[] | [];
  partnerships: PartnershipType[] | [];
  billingTypes: AutocompleteSelectOption[] | [];
}

/**
 * API filtering for bill holds grid
 * will need to make seperate calls to get clients insurers
 * before sending these params to billing service
 */
export interface BillingApiFilterModelType {
  claimIds: string[];
  holdReasons: string[];
  userIds: number[];
  subscriptionIds: number[];
  startDate: string;
  endDate: string;
  clientInsurerIds: number[];
}

export type InsurersType = Pick<InsurerType, 'id' | 'name' | 'identifier'>;

export const BILL_DATE_FORMAT = 'YYYY-MM-DD';

export type AutocompleteSelectOption = {
  value: string;
  label: string;
};
