import { HHGrid, HHTypography } from '@hinge-health/react-component-library';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { AMT_MENU_COLORS } from '../constants/colors';
import { ApplicationManagementToolWorkflowTypes } from '../custom-types';
import { workflowIcons } from '../utils/icons';

export interface ApplicationManagementToolTabContainerProps {
  selectedTab: number;
  title: string;
  children: JSX.Element;
}

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      color: AMT_MENU_COLORS.ICON_BLUE,
      margin: '0 10px',
    },
    titleRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    typeCategory: {
      marginTop: 40,
      marginBottom: 16,
    },
  }),
);

export const ApplicationManagementToolTabContainer = (
  props: ApplicationManagementToolTabContainerProps,
): JSX.Element => {
  const { children, title } = props;
  const classes = useStyles();

  const { PathwayTransition: pathwayTransitionType } =
    ApplicationManagementToolWorkflowTypes;
  const Icon = workflowIcons[pathwayTransitionType];

  return (
    <HHGrid container spacing={0} className={classes.typeCategory}>
      <HHGrid item xs={7} className={classes.titleRow}>
        <Icon className={classes.icon} />
        <HHTypography hhVariant="variant-bypass" sx={{ fontSize: 16 }}>
          {title}
        </HHTypography>
      </HHGrid>
      {children}
    </HHGrid>
  );
};
