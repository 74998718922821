import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { targetedInterventions } from '../constants/strings/targeted-interventions';
import { WorkflowGrid } from '../modules/targeted-interventions';

export const TargetedInterventions = (): JSX.Element => (
  <Sentry.ErrorBoundary fallback={<Box>{targetedInterventions.error}</Box>}>
    <WorkflowGrid />
  </Sentry.ErrorBoundary>
);
