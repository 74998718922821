import { useState } from 'react';

export interface UseModalHook {
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

export const useModal = (): UseModalHook => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = (): void => setIsOpen(true);
  const handleClose = (): void => setIsOpen(false);

  return { isOpen, handleOpen, handleClose };
};
