import {
  HHButton,
  HHLoadingButton,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { FormEvent } from 'react';
import { HCModal } from '../../components/hinge-connect-modal';
import { buttonLabels } from '../../constants/hinge-connect-constants.constant';
import { rulesTabContent } from '../constants/rules.constant';

const BoldedConfirmMessage = ({
  ruleName,
}: {
  ruleName: string;
}): JSX.Element => (
  <>
    {rulesTabContent.deleteRule.bodyPrefix}
    <strong>{ruleName}</strong>
    {rulesTabContent.deleteRule.bodySuffix}
  </>
);

const DeleteRuleForm = ({
  ruleName,
  handleClose,
  onSubmit,
  loading,
}: {
  ruleName: string;
  handleClose: () => void;
  onSubmit: () => void;
  loading: boolean;
}): JSX.Element => {
  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    onSubmit();
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit}>
      <HHStack width="40vw" maxWidth="500px">
        <HHTypography hhVariant="body" color="textSecondary">
          <BoldedConfirmMessage ruleName={ruleName} />
        </HHTypography>
        <HHStack
          direction="row"
          spacing={4}
          justifyContent="right"
          marginTop="16px"
        >
          <HHLoadingButton
            hhVariant="variant-bypass"
            variant="contained"
            type="submit"
            loading={loading}
            data-testid="delete-rule-button"
          >
            {buttonLabels.delete}
          </HHLoadingButton>
          <HHButton
            hhVariant="text"
            type="button"
            sx={{ margin: '1rem' }}
            onClick={handleClose}
          >
            {buttonLabels.cancel}
          </HHButton>
        </HHStack>
      </HHStack>
    </form>
  );
};

export const DeleteRuleModal = ({
  ruleName,
  ruleId,
  isOpen,
  handleClose,
  deleteRule,
  deleteRuleLoading,
}: {
  ruleName: string;
  ruleId: string;
  isOpen: boolean;
  handleClose: () => void;
  deleteRule: ({ variables }: { variables: { id: string } }) => void;
  deleteRuleLoading: boolean;
}): JSX.Element => {
  const onSubmit = (): void => {
    deleteRule({ variables: { id: ruleId } });
  };

  return (
    <HCModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={rulesTabContent.deleteRule.title}
      component={
        <DeleteRuleForm
          {...{ ruleName, handleClose, onSubmit, loading: deleteRuleLoading }}
        />
      }
    />
  );
};
