import {
  HHChip,
  HHStack,
  HHTypography,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { MilestoneLabels } from '../../constants/strings/billing-label-enums';

import { GetSplitTreatmentOnOrOff } from '../../../../components/splits';
import { newBillingActivitiesAndSubTypesEnabled } from '../../constants/strings/split';
import { determineContractType } from '../../utils/contract-utils';
import { formatCurrency } from '../../utils/currency-helpers';
import { getBillableActivityDisplayString } from '../../utils/package-utils';
import { TextGrouping } from '../utils/text-grouping';
import MilestoneJson from './contract-milestone-json';
import {
  CleanBillableActivities,
  ContractTypeExtension,
} from './form/custom-types';
import { BillableActivitiesWithDetails } from './package/custom-package-types';

export interface MilestoneProps {
  contractDetails: ContractTypeExtension[];
  templateName?: string;
  contractType?: string;
  selectedBillableActivities?: BillableActivitiesWithDetails;
  allBillableActivities: CleanBillableActivities[];
  slimDisplay?: boolean;
  currency?: string;
}

export const conditionsMap: Record<string, string> = {
  enrollment_check: 'Enrollment',
  engagement_threshold_check: 'Engagement threshold check',
  pain_reduction_check: 'Pain reduction check',
};

interface ConfigurableDisplayProps {
  milestone: ContractTypeExtension;
  selectedBillActivities: BillableActivitiesWithDetails | undefined; //selected billable activities in for;
  allBillActivities: CleanBillableActivities[];
}

export const BillableActivityDisplay = ({
  billableActivities,
}: {
  billableActivities: string[];
}): JSX.Element => (
  <HHStack direction="row" flexWrap="wrap">
    {billableActivities.map(ba => (
      <HHChip
        key={ba}
        hhVariant="variant-bypass"
        size="small"
        label={ba}
        sx={{ marginRight: 2, marginBottom: 2 }}
      />
    ))}
  </HHStack>
);

export const ConfigurableActivityDisplay = ({
  milestone,
  selectedBillActivities,
  allBillActivities,
}: ConfigurableDisplayProps): JSX.Element => {
  const { hingeHealthAuthState } = useHingeHealthSecurityContext();
  const adminId = hingeHealthAuthState?.accessToken?.claims.uid.toString();
  // TODO remove with https://hingehealth.atlassian.net/browse/MINT-8511
  const newBillableActivitiesEnabled = GetSplitTreatmentOnOrOff(
    newBillingActivitiesAndSubTypesEnabled,
    adminId,
  );

  let billableActivities = ['configurable'];
  if (
    milestone.billableActivityTypes &&
    (milestone.billableActivityTypes[0] === 'configurable' ||
      milestone.billableActivityTypes.length === 0) &&
    !selectedBillActivities
  ) {
    billableActivities = allBillActivities.map(ba =>
      getBillableActivityDisplayString(ba, newBillableActivitiesEnabled),
    );
  } else if (
    milestone.billableActivityTypes &&
    milestone.billableActivityTypes.length > 0 &&
    milestone.billableActivityTypes[0] !== 'configurable'
  ) {
    billableActivities = milestone.billableActivityTypes.map(ba =>
      getBillableActivityDisplayString(ba, newBillableActivitiesEnabled),
    );
  } else if (
    milestone.billableActivityTypes &&
    (milestone.billableActivityTypes[0] === 'configurable' ||
      milestone.billableActivityTypes.length === 0) &&
    selectedBillActivities &&
    Object.keys(selectedBillActivities).length > 0
  ) {
    billableActivities = allBillActivities
      .filter(ba => ba.id in selectedBillActivities)
      .map(ba =>
        getBillableActivityDisplayString(ba, newBillableActivitiesEnabled),
      );
  }
  return <BillableActivityDisplay billableActivities={billableActivities} />;
};

const MilestoneDisplay = ({
  contractDetails,
  selectedBillableActivities,
  allBillableActivities, //all from api or contract defined
  contractType,
  slimDisplay = false,
  currency,
}: MilestoneProps): JSX.Element => (
  <HHStack
    direction={slimDisplay ? 'column' : { xs: 'column', md: 'row' }}
    flexWrap="wrap"
    sx={{
      '& > *:not(:first-child)': {
        borderLeft: slimDisplay
          ? 'none'
          : {
              xs: 'none',
              md: '1px solid #E0E0E0',
            },
        borderTop: slimDisplay
          ? '1px solid #E0E0E0'
          : {
              xs: '1px solid #E0E0E0',
              md: 'none',
            },
      },
    }}
    spacing={1}
  >
    {contractDetails.map(milestone => (
      <HHStack
        flexDirection="column"
        spacing={4}
        key={milestone.name}
        flex={1}
        padding={2}
      >
        <TextGrouping
          label={
            contractType === 'Standard'
              ? MilestoneLabels.STANDARD_MILESTONE_LABEL
              : 'M' + milestone.name.replace('C', '')
          }
          text={formatCurrency(milestone.payment, currency)}
        />
        <HHTypography hhVariant="body" flexWrap="wrap">
          <ConfigurableActivityDisplay
            milestone={milestone}
            selectedBillActivities={selectedBillableActivities}
            allBillActivities={allBillableActivities}
          />
        </HHTypography>
        <HHStack direction="row">
          <HHStack direction="column" flex={1}>
            <HHTypography hhVariant="muted">
              {MilestoneLabels.DOS_OFFSET}
            </HHTypography>
            <HHTypography hhVariant="body2">
              {milestone.dateOfServiceOffset
                ? `${milestone.dateOfServiceOffset}`
                : 'None'}
            </HHTypography>
          </HHStack>
          <HHStack
            direction="column"
            flex={contractType === 'Standard' ? 2 : 1}
          >
            <HHTypography hhVariant="muted">
              {MilestoneLabels.DAY_RANGE}
            </HHTypography>
            <HHTypography hhVariant="body2">
              {milestone.dayRange && milestone.dayRange.length >= 1
                ? `${milestone.dayRange[0]} to ${milestone.dayRange[1]} days`
                : 'Day range not configured'}
            </HHTypography>
          </HHStack>
        </HHStack>
        <HHStack>
          <HHTypography hhVariant="muted">
            {MilestoneLabels.BILLING_CONDITIONS}
          </HHTypography>
          {milestone.conditions?.map(c => (
            <HHTypography hhVariant="body2" key={c.function}>
              {conditionsMap[c.function]} -{' '}
              {conditionsMap[c.function] === conditionsMap.pain_reduction_check
                ? c.threshold + '%'
                : c.threshold + ' billable activity'}
            </HHTypography>
          ))}
        </HHStack>
      </HHStack>
    ))}
  </HHStack>
);

// reusable outside of form
export const Milestones = ({
  contractDetails,
  templateName,
  selectedBillableActivities,
  allBillableActivities,
  slimDisplay = false,
  currency,
}: MilestoneProps): JSX.Element => {
  const contractType = determineContractType(templateName ?? '');
  return contractType === 'Milestone' ||
    contractType === 'Standard' ||
    contractType === 'Individual Milestone' ? (
    <MilestoneDisplay
      contractDetails={contractDetails}
      selectedBillableActivities={selectedBillableActivities}
      allBillableActivities={allBillableActivities}
      contractType={contractType}
      slimDisplay={slimDisplay}
      currency={currency}
    />
  ) : (
    <MilestoneJson contract={contractDetails} />
  );
};
