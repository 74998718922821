import { UserMenu } from '@hinge-health/react-component-library';
import React, { createContext, useContext } from 'react';
import { Admin } from '../types';

const EMPTY_ADMIN = {
  email: '',
  firstName: '',
  lastName: '',
  uuid: '',
};

export interface ShellContextType {
  children?: React.ReactNode;
  adminProfile?: Admin;
  UserHeaderMenu?: () => JSX.Element;
}

const initialContext: ShellContextType = {
  UserHeaderMenu: () => <UserMenu menuItems={[]} userProfile={EMPTY_ADMIN} />,
};

export const ShellContext = createContext<ShellContextType>(initialContext);

export const ShellProvider = ({
  children,
  adminProfile,
  UserHeaderMenu,
}: ShellContextType): JSX.Element => (
  <ShellContext.Provider
    value={{
      UserHeaderMenu,
      adminProfile,
    }}
  >
    {children}
  </ShellContext.Provider>
);

export const useShellContext = (): ShellContextType => useContext(ShellContext);
