import { HHTextField } from '@hinge-health/react-component-library';
import { InputAdornment } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getCurrencySymbol } from '../../../../utils/currency-helpers';
import { ContractPackageFormLabelText } from '../form';

export interface UserAnnualCapProps {
  wholeFormDisabled: boolean | undefined;
}
export const USER_ANNUAL_CAP_TEST_ID = 'user-annual-cap-test-id';

export const UserAnnualCap = ({
  wholeFormDisabled,
}: UserAnnualCapProps): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const watchedCurrency = useWatch({ name: 'currency' });
  return (
    <Controller
      control={control}
      name="userAnnualCap"
      render={({ field: { onChange, value } }): JSX.Element => (
        <HHTextField
          data-testid={USER_ANNUAL_CAP_TEST_ID}
          hhVariant="variant-bypass"
          label={ContractPackageFormLabelText.USER_ANNUAL_CAP}
          fullWidth
          disabled={wholeFormDisabled}
          inputProps={{
            inputMode: 'decimal',
            pattern: '[0-9]*',
            step: '0.01',
            min: '0',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                disableTypography
                sx={{ color: 'gray' }}
              >
                {getCurrencySymbol(watchedCurrency?.value)}
              </InputAdornment>
            ),
          }}
          error={Object.keys(errors?.userAnnualCap ?? {}).length !== 0}
          helperText={errors.userAnnualCap?.message}
          value={value}
          onChange={onChange}
          type="number"
          InputLabelProps={{ sx: { color: 'gray' } }}
        />
      )}
    />
  );
};
