import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';

const parseDate = (_: Dayjs, originalValue: Dayjs | null): Date | null =>
  dayjs(originalValue).isValid() ? dayjs(originalValue).toDate() : null;

export const AdvancedFiltersValidationSchema = yup.object({
  claimIds: yup.array().defined().max(500),
  holdReasons: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          value: yup.string().defined(),
          label: yup.string().defined(),
        })
        .defined(),
    )
    .min(1) //must have at least one hold reason otherwise it brings back all bills
    .defined(),
  userIds: yup.array().defined().max(500),
  subscriptionIds: yup.array().defined().max(500),
  dateRange: yup
    .array()
    .of(yup.date().transform(parseDate).nullable().defined())
    .min(2)
    .max(2)
    .defined(),
  clients: yup.array().defined().max(500),
  insurers: yup.array().defined().max(500),
  partnerships: yup.array().defined().max(500),
  billingTypes: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          value: yup.string().defined(),
          label: yup.string().defined(),
        })
        .defined(),
    )
    .defined(),
});
