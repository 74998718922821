import { HHTooltip } from '@hinge-health/react-component-library';
import { DeleteRounded, Edit, PlayCircleRounded } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { DataGrid, GridActionsCellItem, GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useGetFileConfigurationsQuery } from '../../../member-data/types';

const columns: GridColumns = [
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'createdAt', headerName: 'Created At', width: 200 },
  { field: 'updatedAt', headerName: 'Updated At', width: 200 },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    renderCell: () => (
      <>
        <HHTooltip hhVariant="bottom" title="Run">
          <GridActionsCellItem
            icon={<PlayCircleRounded />}
            color="primary"
            aria-label="Run"
          />
        </HHTooltip>
        <HHTooltip hhVariant="bottom" title="Edit">
          <GridActionsCellItem
            icon={<Edit style={{ color: 'green' }} />}
            color="primary"
            aria-label="Edit"
          />
        </HHTooltip>
        <HHTooltip hhVariant="bottom" title="Delete">
          <GridActionsCellItem
            icon={<DeleteRounded />}
            color="error"
            aria-label="Delete"
          />
        </HHTooltip>
      </>
    ),
  },
];

const NoRowsOverlayStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  fontSize: 16,
  color: theme.palette.text.secondary,
}));

const NoRowsOverlay = ({ message }: { message: string }): JSX.Element => (
  <NoRowsOverlayStyle>{message}</NoRowsOverlayStyle>
);

export const ConfigsTable = (): JSX.Element => {
  const { data, loading, error } = useGetFileConfigurationsQuery();
  interface FileConfig {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  }

  const [rows, setRows] = useState<FileConfig[]>([]);

  useEffect(() => {
    if (data) {
      const formattedRows = data.getFileConfigurations.items.map(
        (item: {
          id: string;
          name: string;
          created_at: string;
          updated_at: string;
        }) => ({
          id: item.id,
          name: item.name,
          createdAt: new Date(item.created_at).toLocaleString(),
          updatedAt: new Date(item.updated_at).toLocaleString(),
        }),
      );
      setRows(formattedRows);
    }
  }, [data]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading file configurations</div>;

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      autoHeight
      disableSelectionOnClick
      hideFooter
      components={{
        NoRowsOverlay: () => <NoRowsOverlay message="No File Configurations" />,
      }}
    />
  );
};
