import { atom } from 'jotai';
import {
  ApplicationManagementToolGroupType,
  ApplicationManagementToolWorkflowTypes,
} from '../custom-types';

export const showAmtSidebarAtom = atom(false);
export const selectedWorkflowId = atom<number | null>(null);
export const selectedGroupType = atom<
  ApplicationManagementToolGroupType | undefined
>(undefined);
export const selectedWorkflowType = atom<
  ApplicationManagementToolWorkflowTypes | undefined
>(undefined);
export const showWorkflowComplete = atom(false);
