/* eslint-disable react-hooks/exhaustive-deps */
import {
  HHAlert,
  HHLoadingButton,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClientInformationSection from '../components/client-information-section';
import ColumnMappingsSection from '../components/column-mappings-section';
import FileInformationSection from '../components/file-information-section';
import ParserSection from '../components/parser-section';
import { FileConfigurationFormActions } from '../constants/file-configuration-constants';
import { routes } from '../constants/strings/routes';
import {
  FileConfigurationInput,
  useCreateFileConfigurationMutation,
  useUpdateFileConfigurationMutation,
} from '../types';

interface FileConfigurationFormProps {
  pageTitle: string;
  formData?: { [key: string]: unknown };
  action: FileConfigurationFormActions;
  fileConfigurationId?: number;
}

const FileConfigurationForm = (
  props: FileConfigurationFormProps,
): JSX.Element => {
  const FORM_DATA = {
    individualOperations: [],
    fileOperations: [],
    groupOperations: [],
  };
  const navigate = useNavigate();
  const [createFileConfiguration, { ...createFileConfigurationResponse }] =
    useCreateFileConfigurationMutation();
  const [updateFileConfiguration, { ...updateConfigurationResponse }] =
    useUpdateFileConfigurationMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [formData, setFormData] = useState<{ [key: string]: unknown }>(
    props.formData || FORM_DATA,
  );

  const onFormChange = (data: { [key: string]: unknown }): void => {
    setFormData({ ...formData, ...data });
  };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    // clear error messages
    setErrorMessages([]);

    if (props.action === FileConfigurationFormActions.CREATE) {
      createFileConfiguration({
        variables: { data: formData as FileConfigurationInput },
      });
    } else {
      const updatedData = { ...formData };
      delete updatedData.__typename;
      updateFileConfiguration({
        variables: {
          id: props.fileConfigurationId as number,
          data: updatedData as FileConfigurationInput,
        },
      });
    }
  };

  const handleAlertClose = (index: number): void => {
    const newErrorMessages = [...errorMessages];
    newErrorMessages.splice(index, 1);
    setErrorMessages(newErrorMessages);
  };

  useEffect(() => {
    // set if submitting data
    if (
      createFileConfigurationResponse.loading ||
      updateConfigurationResponse.loading
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    createFileConfigurationResponse.data,
    createFileConfigurationResponse.error,
    createFileConfigurationResponse.loading,
    updateConfigurationResponse.loading,
    updateConfigurationResponse.error,
    updateConfigurationResponse.data,
  ]);

  useEffect(() => {
    if (updateConfigurationResponse.error) {
      setErrorMessages([
        ...errorMessages,
        updateConfigurationResponse.error?.message,
      ]);
    } else if (createFileConfigurationResponse.error) {
      setErrorMessages([
        ...errorMessages,
        createFileConfigurationResponse.error?.message,
      ]);
    }
  }, [
    createFileConfigurationResponse.error,
    updateConfigurationResponse.error,
  ]);

  useEffect(() => {
    // Navigate to main page on success
    if (
      createFileConfigurationResponse.data &&
      !createFileConfigurationResponse.error
    ) {
      navigate(routes.memberData.home, {
        replace: true,
        state: { newFileConfigurationSuccess: true },
      });
    } else if (
      updateConfigurationResponse.data &&
      !updateConfigurationResponse.error
    ) {
      navigate(routes.memberData.home, {
        replace: true,
        state: { editFileConfigurationSuccess: true },
      });
    }
  }, [
    createFileConfigurationResponse.data,
    createFileConfigurationResponse.error,
    updateConfigurationResponse.data,
    updateConfigurationResponse.error,
  ]);

  return (
    <HHStack direction="column" padding={'20px'} spacing={'20px'} width={'50%'}>
      <HHTypography hhVariant={'h1'}>{props.pageTitle}</HHTypography>
      {errorMessages.length !== 0 &&
        errorMessages.map((message, index) => (
          <HHAlert
            key={index}
            severity="error"
            hhVariant={'standard'}
            onClose={(): void => handleAlertClose(index)}
          >
            {message}
          </HHAlert>
        ))}
      <FileInformationSection onChange={onFormChange} formData={formData} />
      <ClientInformationSection onChange={onFormChange} formData={formData} />
      <ParserSection onChange={onFormChange} formData={formData} />
      <ColumnMappingsSection formData={formData} onChange={onFormChange} />
      <HHLoadingButton
        hhVariant={'contained'}
        onClick={handleSubmit}
        loading={isLoading}
      >
        {`${
          props.action === FileConfigurationFormActions.CREATE
            ? 'Create'
            : 'Update'
        } Configuration`}
      </HHLoadingButton>
    </HHStack>
  );
};
export default FileConfigurationForm;
