import { Grid, MenuItem, TextField } from '@mui/material';

type FileInformationSectionProps = {
  fileType: string;
  setFileType: (value: string) => void;
  contentType: string;
  setContentType: (value: string) => void;
  ftpFolder: string;
  setFtpFolder: (value: string) => void;
  fileName: string;
  setFileName: (value: string) => void;
  errors: { [key: string]: string };
};

export const FileInformationSection = (
  props: FileInformationSectionProps,
): JSX.Element => {
  const {
    fileType,
    setFileType,
    contentType,
    setContentType,
    ftpFolder,
    setFtpFolder,
    fileName,
    setFileName,
    errors,
  } = props;

  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={2}>
          <TextField
            select
            label="File Type"
            value={fileType}
            onChange={(e): void => setFileType(e.target.value)}
            fullWidth
            error={!!errors.fileType}
            helperText={errors.fileType}
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          >
            <MenuItem value="E-File">E-File</MenuItem>
            <MenuItem value="Supplemental">Supplemental</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            select
            label="Content Type"
            value={contentType}
            onChange={(e): void => setContentType(e.target.value)}
            fullWidth
            error={!!errors.contentType}
            helperText={errors.contentType}
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          >
            <MenuItem value="Standard">Standard</MenuItem>
            <MenuItem value="Multi Client Multi Insurer (MCMI)">
              Multi Client Multi Insurer (MCMI)
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="FTP Folder"
            value={ftpFolder}
            onChange={(e): void => setFtpFolder(e.target.value)}
            fullWidth
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="File Name Example"
            value={fileName}
            onChange={(e): void => setFileName(e.target.value)}
            fullWidth
            InputProps={{
              style: {
                height: '50px',
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
