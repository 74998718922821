import { HHBox, HHGrid } from '@hinge-health/react-component-library';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AMT_MENU_COLORS } from '../constants/colors';

export const useCardStyles = makeStyles(() =>
  createStyles({
    divider: {
      flex: 1,
      border: `1px solid ${AMT_MENU_COLORS.DIVIDER_COLOR}`,
    },
    divideSpacer: {
      flex: '12px 0',
    },
  }),
);

export const DASHBOARD_CARD_SPACER_TEST_ID = 'dashboardCardSpacer';

export const ApplicationManagementToolCardSpacer = (): JSX.Element => {
  const classes = useCardStyles();

  return (
    <HHGrid container spacing={0} data-testid={DASHBOARD_CARD_SPACER_TEST_ID}>
      <HHGrid item xs={1} className={classes.divideSpacer} />
      <HHBox className={classes.divider} />
    </HHGrid>
  );
};
