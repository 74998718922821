import dayjs from 'dayjs';
import { DueStates, PathwayTransitionDecoratedWorkflow } from '../custom-types';
import { WorkflowPayload } from '../types';

const overdueMetric = [2, 'hour'] as const;

export const appendDueState = (
  workflow: WorkflowPayload,
): PathwayTransitionDecoratedWorkflow => {
  const workflowWithDueState: PathwayTransitionDecoratedWorkflow = {
    ...workflow,
    dueState: DueStates.Default,
  };
  const dateValue = workflow?.latestTaskDate || workflow?.createdAt;
  if (!dateValue) {
    workflowWithDueState.dueState = DueStates.Default;
  } else {
    const now = dayjs();
    const then = dayjs(dateValue);

    if (now.isAfter(then)) {
      workflowWithDueState.dueState = DueStates.Overdue;
    } else {
      const nowPlusOverdue = now.add(...overdueMetric);
      workflowWithDueState.dueState = nowPlusOverdue.isAfter(then)
        ? DueStates.DueSoon
        : DueStates.Default;
    }
  }
  return workflowWithDueState;
};
