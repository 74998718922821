import { HHStack } from '@hinge-health/react-component-library';
import FileConfigurationForm from '../components/file-configuration-form';
import { FileConfigurationFormActions } from '../constants/file-configuration-constants';

const NewFileConfigurationPage = (): JSX.Element => (
  <HHStack direction="column">
    <FileConfigurationForm
      pageTitle="New File Configuration"
      action={FileConfigurationFormActions.CREATE}
    />
  </HHStack>
);
export default NewFileConfigurationPage;
