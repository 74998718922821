import {
  HHButton,
  HHGrid,
  HHLoadingButton,
  HHSnackbarAlert,
  HHStack,
  HHTextField,
  HHTypography,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import { ExpandMoreSharp } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { HingeConnectAutocomplete } from '../../../components/hinge-connect-autocomplete';
import {
  buttonLabels,
  sourceAutoCompleteOptions,
} from '../../../constants/hinge-connect-constants.constant';
import { userTagsInputLabels } from '../../constants/user-tags.constant';
import { useListTagNames } from '../../hooks/list-tag-names-hook';
import { useCreateUserTag } from '../../hooks/use-create-user-tag';

const ONE_MINUTE = 60 * 1000;

export const CreateUserTagForm = (props: {
  providedUserId?: string;
  handleSuccess: (userId: string) => void;
  onCancel: () => void;
}): JSX.Element => {
  const { hingeHealthAuthState } = useHingeHealthSecurityContext();

  const [userId, setUserId] = useState(props.providedUserId ?? '');
  const [resourceId, setResourceId] = useState(
    hingeHealthAuthState?.accessToken?.claims?.uid ?? '',
  );
  const [occurredAt, setOccurredAt] = useState<Dayjs | null>(dayjs());
  const [source, setSource] = useState('UI');
  const [tagName, setTagName] = useState('');
  const [formIsComplete, setFormIsComplete] = useState(false);
  const [userIdError, setUserIdError] = useState(false);
  const [expanded, setExpanded] = useState(
    userId === '' || userId === undefined,
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const tagNames = useListTagNames({
    cacheValidationDuration: ONE_MINUTE,
  }).map(tag => ({
    label: tag,
    value: tag,
  }));

  const { createUserTag, loading, error } = useCreateUserTag(() =>
    props.handleSuccess(userId),
  );

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    const input = {
      userId,
      resourceId,
      occurredAt: occurredAt?.toISOString() ?? '',
      source,
      tagName,
    };
    createUserTag({
      variables: {
        createUserTagInput: input,
      },
    });
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    callback: React.Dispatch<React.SetStateAction<string>>,
  ): void => {
    callback(e.target.value);
  };

  const handleDateSelection = (date: Dayjs | null): void => {
    setOccurredAt(date);
  };

  useEffect(() => {
    const complete = [userId, resourceId, occurredAt, source, tagName].every(
      field => !!field,
    );
    setFormIsComplete(complete);
  }, [userId, resourceId, occurredAt, source, tagName]);

  useEffect(() => {
    if (!userId) {
      setUserIdError(false);
    } else {
      setUserIdError(
        !/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
          userId,
        ),
      );
    }
  }, [userId]);

  useEffect(() => {
    if (error) {
      setSnackbarOpen(true);
    }
  }, [error]);

  return (
    <>
      <HHSnackbarAlert
        hhVariant="standard"
        horizontalAlignment="center"
        verticalAlignment="top"
        severity="error"
        message={'Failed to create user tag'}
        open={snackbarOpen}
        autoHideTime={3000}
        onClose={(): void => setSnackbarOpen(false)}
      />
      <form onSubmit={handleSubmit}>
        <HHStack width="40vw" maxWidth="500px">
          <HHGrid container spacing={4} paddingLeft="8px" paddingRight="8px">
            <HHGrid item xs={12} marginTop="10px">
              <HingeConnectAutocomplete
                label={userTagsInputLabels.tagName}
                dropdownOptions={tagNames}
                required={true}
                value={tagName}
                clearOnBlur={true}
                onChange={(value): void => setTagName(value ?? '')}
              />
            </HHGrid>
          </HHGrid>
          <Accordion
            variant="elevation"
            expanded={expanded}
            elevation={0}
            sx={{
              marginTop: '10px',
              position: 'relative',
              '&:before': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreSharp />}
              onClick={(_e): void => setExpanded(!expanded)}
              style={{ minHeight: '0px' }}
              sx={{
                height: '10px',
                padding: '0px',
                flexDirection: 'row-reverse',
              }}
              data-testid="accordion-summary"
            >
              <HHGrid container>
                <HHGrid item xs={12} borderBottom="1px lightgray solid">
                  <HHTypography hhVariant="input-label">
                    Additional Details
                  </HHTypography>
                </HHGrid>
              </HHGrid>
            </AccordionSummary>

            <AccordionDetails>
              <HHGrid container spacing={4}>
                <HHGrid item xs={12}>
                  <HHTextField
                    hhVariant="variant-bypass"
                    margin="normal"
                    sx={{ width: '100%' }}
                    label={userTagsInputLabels.userId}
                    inputProps={{ 'aria-label': userTagsInputLabels.userId }}
                    value={userId}
                    required={true}
                    error={userIdError}
                    onChange={(e): void => handleInputChange(e, setUserId)}
                  />
                </HHGrid>
                <HHGrid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      value={occurredAt}
                      onChange={(e): void => handleDateSelection(e)}
                      label={userTagsInputLabels.occurredAt}
                      renderInput={(params): JSX.Element => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </HHGrid>
                <HHGrid item xs={6}>
                  <HingeConnectAutocomplete
                    label={userTagsInputLabels.source}
                    value={source}
                    dropdownOptions={sourceAutoCompleteOptions}
                    size="medium"
                    onChange={(value): void => setSource(value ?? '')}
                  />
                </HHGrid>
                <HHGrid item xs={12}>
                  <HHTextField
                    label={userTagsInputLabels.resourceId}
                    inputProps={{
                      'aria-label': userTagsInputLabels.resourceId,
                    }}
                    hhVariant="variant-bypass"
                    sx={{ width: '100%' }}
                    size="small"
                    value={resourceId}
                    required={true}
                    onChange={(e): void => handleInputChange(e, setResourceId)}
                  />
                </HHGrid>
              </HHGrid>
            </AccordionDetails>
          </Accordion>
          <HHStack
            direction="row"
            spacing={2}
            justifyContent="right"
            marginTop="10px"
          >
            <HHButton
              data-testid="cancel-button"
              hhVariant="text"
              sx={{ width: 'fit-content' }}
              onClick={props.onCancel}
            >
              {buttonLabels.cancel}
            </HHButton>
            <HHLoadingButton
              hhVariant="variant-bypass"
              variant="contained"
              sx={{ width: 'fit-content' }}
              type="submit"
              loading={loading}
              disabled={!formIsComplete}
              data-testid="submit-button"
            >
              {buttonLabels.create}
            </HHLoadingButton>
          </HHStack>
        </HHStack>
      </form>
    </>
  );
};
