import { Admin, Maybe } from '../types';

export interface Patient {
  firstName: string;
  lastName: string;
}

export const createFullName = (patient?: Maybe<Patient>): string => {
  if (!patient) return '';
  const firstName = patient.firstName;
  const lastName = patient.lastName;
  return `${firstName} ${lastName}`;
};

export const createFirstNameLastInitial = (
  patient?: Maybe<Patient>,
): string => {
  if (!patient) return '';
  return firstNameLastInitial(patient);
};

export const adminFirstNameLastInitial = (adminProfile: Admin): string =>
  firstNameLastInitial(adminProfile);

type firstNameLastInitialProps = {
  firstName?: string;
  lastName?: string;
};

export const firstNameLastInitial = ({
  firstName,
  lastName,
}: firstNameLastInitialProps): string =>
  `${firstName || ''} ${lastName?.[0] || ''}`;

export const fullNameToFirstNameAndLastInitial = (fullname: string): string => {
  const [firstName, lastName] = fullname.split(' ');
  return firstNameLastInitial({ firstName, lastName });
};
