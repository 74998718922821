import { ApolloError } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import {
  ContextClientInsurerCollectionType,
  ContextClientInsurerType,
  ContextPartnershipType,
  useBillingContext,
} from '../context/billing.context';
import {
  ClientInsurerByClientType,
  ClientInsurerType,
  useGetClientInsurerByIdQuery,
  useGetClientInsurersByClientQuery,
  useGetClientInsurersByIdsLazyQuery,
} from '../types';

export interface ClientDetailsType extends ClientInsurerByClientType {
  partnerships: ContextPartnershipType;
}

const extractPartnerships = (
  data: ClientInsurerByClientType,
): ContextPartnershipType =>
  data.clientsInsurers.reduce<ContextPartnershipType>(
    (acc, { partnerships }) => {
      if (partnerships) {
        partnerships.forEach(partnership => {
          acc[partnership.id] = partnership;
        });
      }
      return acc;
    },
    {},
  );

export const useClientInsurersByClient = (
  clientId: number,
): {
  data: ClientDetailsType | null;
  loading: boolean;
  error?: ApolloError;
} => {
  const { addPartnerships } = useBillingContext();
  const { data, loading, error } = useGetClientInsurersByClientQuery({
    variables: {
      id: String(clientId),
    },
  });
  const [details, setDetails] = useState<ClientDetailsType | null>(null);

  useEffect(() => {
    if (data?.getClientInsurersByClient) {
      const partnerships = extractPartnerships(data.getClientInsurersByClient);
      setDetails({
        ...data.getClientInsurersByClient,
        partnerships,
      });
      addPartnerships(partnerships);
    }
  }, [data, setDetails, addPartnerships]);

  return {
    data: details,
    loading,
    error,
  };
};

export const useClientInsurersById = (
  clientInsurerId: number,
): {
  data: ClientInsurerType | null;
  loading: boolean;
  error?: ApolloError;
} => {
  const { addPartnerships } = useBillingContext();
  const { data, loading, error } = useGetClientInsurerByIdQuery({
    variables: {
      id: clientInsurerId,
    },
  });

  const [details, setDetails] = useState<ClientInsurerType | null>(null);

  useEffect(() => {
    if (data?.getClientInsurerById) {
      const partnerships =
        data.getClientInsurerById.partnerships.reduce<ContextPartnershipType>(
          (acc, partnership) => {
            acc[partnership.id] = partnership;
            return acc;
          },
          {},
        );
      addPartnerships(partnerships);
      setDetails({
        ...data.getClientInsurerById,
      });
    }
  }, [data, addPartnerships, setDetails]);

  return {
    data: details,
    loading,
    error,
  };
};

export const useClientInsurersByIdsLazy = (): {
  data: ContextClientInsurerCollectionType;
  searchClientInsurerIds: (ids: Set<number>) => void;
} => {
  const { clientInsurers, addClientInsurers } = useBillingContext();
  const [query, { data, error }] = useGetClientInsurersByIdsLazyQuery();
  const details = useRef<ContextClientInsurerCollectionType>({});
  const [clientInsurerIds, searchClientInsurerIds] = useState<Set<number>>(
    new Set([]),
  );

  useEffect(() => {
    details.current = Array.from(
      clientInsurerIds,
    ).reduce<ContextClientInsurerCollectionType>((acc, id) => {
      acc[id] = clientInsurers[id] ?? { loading: true };
      return acc;
    }, {});
    const pendingIds = Object.entries<ContextClientInsurerType>(details.current)
      .filter(([_, clientInsurer]) => clientInsurer.loading)
      .map(([id]) => Number(id));

    if (pendingIds.length) {
      query({
        variables: {
          ids: pendingIds,
        },
      });
    }
  }, [clientInsurerIds, clientInsurers, query]);

  useEffect(() => {
    if (data?.getClientInsurersByIds) {
      details.current =
        data.getClientInsurersByIds.reduce<ContextClientInsurerCollectionType>(
          (acc, clientInsurer) => {
            acc[clientInsurer.id] = {
              loading: false,
              error: null,
              ...clientInsurer,
            };
            return acc;
          },
          {},
        );
      addClientInsurers(details.current);
    }
  }, [data, addClientInsurers]);

  useEffect(() => {
    if (error) {
      details.current = Object.entries<ContextClientInsurerType>(
        details.current,
      ).reduce<ContextClientInsurerCollectionType>(
        (acc, [id, clientInsurer]) => {
          if (clientInsurer.loading) {
            acc[id] = {
              loading: false,
              error,
            };
          }
          return acc;
        },
        {},
      );
      addClientInsurers(details.current);
    }
  }, [error, addClientInsurers]);

  return {
    data: clientInsurers,
    searchClientInsurerIds,
  };
};
