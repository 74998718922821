import { HHGrid } from '@hinge-health/react-component-library';
import { AMT_STRINGS } from '../constants/strings/application-management-tool';
import { useCardStyles } from './styles/card-styles';

export const ApplicationManagementToolInboxHeader = (): JSX.Element => {
  const classes = useCardStyles();

  return (
    <HHGrid container spacing={0} className={classes.titleText}>
      <HHGrid item xs={1} className={classes.spacer} />
      <HHGrid item xs={1}>
        #
      </HHGrid>
      <HHGrid item xs={1}>
        User ID
      </HHGrid>
      <HHGrid item xs={2}>
        Workflow State
      </HHGrid>
      <HHGrid item xs={1}>
        Updated At
      </HHGrid>
      <HHGrid item xs={3} className={classes.centeredColumn}>
        {AMT_STRINGS.DUE}
      </HHGrid>
      <HHGrid item xs={2}>
        Assign
      </HHGrid>
    </HHGrid>
  );
};
