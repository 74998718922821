import { ApolloError } from '@apollo/client';
import { useEffect, useState } from 'react';
import { UUIDv4 } from 'uuid-v4-validator';
import { EnrollUsersInput } from '../../../types';
import { defaultErrorHandler } from '../../../utils/errors';
import { defaultWorkflowType, workflowTypeOptions } from '../constants';
import { useEnrollUsersMutation } from '../types';

export type EnrollmentFormControllerSuccessResult = {
  count: number;
};

export type EnrollmentFormControllerErrorResult = {
  mutationError?: string;
  failedUuids?: string[];
};

export interface EnrollmentFormController {
  value: Record<keyof EnrollUsersInput, string>;
  setValue(incomingValue: string, key: string): void;
  validate(): boolean;
  submit(): Promise<void>;
  isValid: boolean | null;
  loading: boolean;
  error: null | EnrollmentFormControllerErrorResult;
  success: null | EnrollmentFormControllerSuccessResult;
}

/**
 * Controls a form with a single textarea for User UUIDs.
 */
export function useEnrollmentForm(): EnrollmentFormController {
  const [value, setValueState] = useState<
    Record<keyof EnrollUsersInput, string>
  >({ patientUuids: '', workflowType: '' });
  const [transformedValue, setTransformedValue] = useState<string[]>([]);
  const [isValid, setValid] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] =
    useState<null | EnrollmentFormControllerErrorResult>(null);
  const [success, setSuccess] = useState<null | { count: number }>(null);
  const [enrollUsersMutation, mutationResult] = useEnrollUsersMutation({
    onError: (error: ApolloError) => {
      defaultErrorHandler(
        `useEnrollmentForm - mutation error: ${error.message}`,
      );

      setError({
        mutationError: error.message,
      });
    },
  });

  const resetState = (): void => {
    if (error) {
      setError(null);
    }

    if (success) {
      setSuccess(null);
      setTransformedValue([]);
    }
  };

  const setValue = (incomingValue: string, key: string): void => {
    setValid(null);
    resetState();
    setValueState({ ...value, [key]: incomingValue });
  };

  const validate = (): boolean => {
    resetState();

    if (validateUuids() && validateWorkflowType()) {
      setValid(true);
      return true;
    }
    setValid(false);
    return false;
  };

  const validateUuids = (): boolean => {
    const parts = value.patientUuids
      .split('\n')
      .filter(part => part.length > 0)
      .map(part => part.trim());

    if (value && parts.length && parts.every(UUIDv4.validate)) {
      setTransformedValue(Array.from(new Set(parts)));
      return true;
    } else {
      setTransformedValue([]);
      return false;
    }
  };

  const validateWorkflowType = (): boolean => {
    const trimmedValue = value.workflowType.trim();

    if (trimmedValue.length && workflowTypeOptions.includes(trimmedValue)) {
      return true;
    }
    return false;
  };

  const submit = async (): Promise<void> => {
    if (!isValid) {
      return;
    }

    await enrollUsersMutation({
      variables: {
        enrollUsersInput: {
          patientUuids: transformedValue,
          workflowType: value.workflowType,
        },
      },
    });
  };

  /** track the mutation loading state */
  useEffect(() => {
    setLoading(mutationResult.loading);
  }, [mutationResult.loading]);

  useEffect(() => {
    const mutationData = mutationResult.data?.enrollUsers;

    if (mutationData) {
      if (mutationData?.enrolledUuids) {
        setSuccess({
          count: mutationData?.enrolledUuids.length,
        });
      }

      if (mutationData?.failedUuids.length) {
        defaultErrorHandler(
          `useEnrollmentForm - failed to enroll users: ${mutationData?.failedUuids.join(
            '\n',
          )}`,
        );

        setError({
          failedUuids: mutationData?.failedUuids,
        });
      }

      setValueState({ patientUuids: '', workflowType: defaultWorkflowType });
      setTransformedValue([]);
    }
  }, [mutationResult.data]);

  return {
    value,
    setValue,
    validate,
    submit,
    isValid,
    loading,
    success,
    error,
  };
}
