import {
  HHCircularProgress,
  HHTextField,
} from '@hinge-health/react-component-library';
import { Autocomplete } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CONTRACT_FORM_TEMPLATE_SELECT_ID } from '../../../../constants/strings/contract/form-constants';

import { ContractTemplateOption } from '../custom-types';

export interface ContractTemplateSelectProps {
  templates: ContractTemplateOption[];
  wholeFormDisabled: boolean | undefined;
}

export const ContractTemplateSelect = ({
  templates,
  wholeFormDisabled,
}: ContractTemplateSelectProps): JSX.Element => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const watchedTemplate = watch('currentTemplate');
  // updating contract when template selected
  setValue('contract', watchedTemplate.contract);

  return templates.length ? (
    <Controller
      control={control}
      name="currentTemplate"
      render={({ field: { onChange, value, ref } }): JSX.Element => (
        <Autocomplete
          ref={ref}
          key="currentTemplate"
          data-testid={CONTRACT_FORM_TEMPLATE_SELECT_ID}
          disabled={wholeFormDisabled}
          options={templates}
          getOptionLabel={(option): string => option.name}
          isOptionEqualToValue={(option, value): boolean =>
            option.contractTemplateId === value.contractTemplateId
          }
          disableClearable
          onChange={(_, newValue): void => onChange(newValue)}
          value={value}
          renderInput={(params): JSX.Element => (
            <HHTextField
              {...params}
              label="Contract template"
              hhVariant="variant-bypass"
              variant="outlined"
              error={Object.keys(errors?.currentTemplate ?? {}).length !== 0}
              name="currentTemplate"
              helperText={errors.currentTemplate?.message}
              sx={{ '& .MuiInputLabel-root': { color: 'gray' } }}
            />
          )}
        />
      )}
    />
  ) : (
    <HHCircularProgress />
  );
};
