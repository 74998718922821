import { HHStack } from '@hinge-health/react-component-library';
import { useFormContext } from 'react-hook-form';
import { CleanBillableActivities } from '../../../../components/contract/form/custom-types';
import { Milestones } from '../../milestones';

export interface MilestoneContractProps {
  allBillableActivities: CleanBillableActivities[];
}

export const MilestoneContractDetails = ({
  allBillableActivities,
}: MilestoneContractProps): JSX.Element => {
  const { watch } = useFormContext();

  const watchedTemplate = watch('currentTemplate');
  const watchedselectedBillableActivities = watch('selectedBillableActivities');

  return (
    <HHStack
      padding={2}
      borderRadius={1}
      sx={{ backgroundColor: 'background.default' }}
    >
      <Milestones
        contractDetails={watchedTemplate.contract}
        templateName={watchedTemplate.name}
        selectedBillableActivities={watchedselectedBillableActivities}
        allBillableActivities={allBillableActivities}
        currency={watch('currency').value}
      />
    </HHStack>
  );
};
