import {
  DAY_JS_LOCALES,
  WORKFLOW_DAY,
} from '../constants/strings/day-js-locales';

export const getFormattedHourTime = (timeInHours: string): string =>
  DAY_JS_LOCALES.relativeTime.future.replace(
    '%s',
    DAY_JS_LOCALES.relativeTime.hh.replace('%d', timeInHours),
  );

export const reformatTime = (timeInHours: string): string =>
  `-${DAY_JS_LOCALES.relativeTime.hh.replace('%d', timeInHours)}`;

export const isFormattedDayAgo = (dateString: string): boolean =>
  dateString ===
  DAY_JS_LOCALES.relativeTime.past.replace('%s', DAY_JS_LOCALES.relativeTime.d);

export const isFormattedTomorrow = (dateString: string): boolean =>
  dateString ===
  DAY_JS_LOCALES.relativeTime.future.replace(
    '%s',
    DAY_JS_LOCALES.relativeTime.d,
  );

export const FORMAT_DAY_AGO = DAY_JS_LOCALES.relativeTime.past.replace(
  '%s',
  WORKFLOW_DAY,
);
