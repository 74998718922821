import {
  HHAlert,
  HHStack,
  TextArea,
} from '@hinge-health/react-component-library';
import { useEffect, useState } from 'react';
import {
  GetCvsEligibilityQueryVariables,
  useGetCvsEligibilityQuery,
} from '../types';

export interface CvsEligibilityResponseProps {
  request: GetCvsEligibilityQueryVariables | null;
  onLoading: (arg1: boolean) => void;
}

export const CvsEligibilityResponse = ({
  request,
  onLoading,
}: CvsEligibilityResponseProps): JSX.Element => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { data, loading, error } = useGetCvsEligibilityQuery({
    variables: {
      dateOfBirth: request?.dateOfBirth || '',
      clientCode: request?.clientCode || '',
      firstName: request?.firstName || '',
      lastName: request?.lastName || '',
      gender: request?.gender || 'U',
      externalId: request?.externalId,
      postalCode: request?.postalCode,
      userId: request?.userId,
      inquiryDate: request?.inquiryDate,
    },
    skip: request == null,
  });

  useEffect(() => {
    onLoading(loading);
  }, [loading, onLoading]);

  useEffect(() => {
    if (!loading && error) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
    }
  }, [loading, error]);

  return (
    <HHStack direction={'column'} width={'30%'} height={'50%'}>
      {showErrorMessage && (
        <HHAlert
          severity="error"
          hhVariant={'standard'}
          layoutStyles={{ marginBottom: 5 }}
          onClose={(): void => setShowErrorMessage(false)}
        >
          {`An error occurred while fetching the data: ${error?.message}. Please try again.`}
        </HHAlert>
      )}
      <TextArea
        label="Response"
        disabled={true}
        sx={{
          overflowY: 'scroll',
        }}
        value={JSON.stringify(data, null, '\t')}
        InputLabelProps={{ sx: { marginTop: 1.5 } }}
      />
    </HHStack>
  );
};
