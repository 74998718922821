import {
  HHAlert,
  HHTypography,
} from '@hinge-health/react-component-library/dist/components';
import Box from '@mui/material/Box';

export const ContractLoadingError = (): JSX.Element => (
  <Box sx={{ padding: '1em', width: '100%', overflowY: 'auto' }}>
    <HHAlert
      hhVariant="outlined"
      severity="error"
      children={
        <HHTypography hhVariant="body">
          There was an error loading the contracts.
        </HHTypography>
      }
    ></HHAlert>
  </Box>
);
