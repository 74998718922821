import {
  ApolloError,
  FetchResult,
  MutationFunctionOptions,
} from '@apollo/client';
import { useState } from 'react';
import { getApolloErrorMessages } from '../../utils/get-apollo-error-messages';
import {
  CancelAssessmentTaskMutation,
  Exact,
  ListAssessmentTasksDocument,
  useCancelAssessmentTaskMutation,
} from '../types';

interface UseCancelAssessmentTaskHook {
  cancelAssessmentTask: (
    options?: MutationFunctionOptions<
      CancelAssessmentTaskMutation,
      Exact<{ id: string }>
    >,
  ) => Promise<FetchResult>;
  data: CancelAssessmentTaskMutation | null | undefined;
  error: ApolloError | undefined;
  errorMessages: string;
}

export const useCancelAssessmentTask = (
  completeCallback: () => void,
): UseCancelAssessmentTaskHook => {
  const [errorMessages, setErrorMessages] = useState('');
  const [cancelAssessmentTask, { data, error }] =
    useCancelAssessmentTaskMutation({
      onCompleted: completeCallback,
      refetchQueries: [ListAssessmentTasksDocument],
      onError: (error: ApolloError) => {
        setErrorMessages(getApolloErrorMessages(error));
      },
    });

  return {
    cancelAssessmentTask,
    data,
    error,
    errorMessages,
  };
};
