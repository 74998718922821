import {
  AppBar,
  HHBox,
  HHLogo,
  HHTypography,
} from '@hinge-health/react-component-library';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { HEADER_COLOR } from '../constants/colors';
import { dashBoardTitle } from '../constants/strings/application-management-tool';
import { useShellContext } from '../contexts/shell.context';

const useStyles = makeStyles(() =>
  createStyles({
    acuteHeader: {
      backgroundColor: HEADER_COLOR,
    },
  }),
);

export const ApplicationManagementToolDashboardHeader = (): JSX.Element => {
  const { adminProfile, UserHeaderMenu } = useShellContext();
  const classes = useStyles();

  return (
    <AppBar
      position="sticky"
      className={classes.acuteHeader}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <HHBox
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <HHLogo
          sx={{
            height: 50,
            padding: 5,
          }}
        />
        <HHTypography variant="h5" hhVariant="variant-bypass">
          {adminProfile && dashBoardTitle(adminProfile.firstName)}
        </HHTypography>
      </HHBox>
      <HHBox
        sx={{
          marginLeft: 'auto',
        }}
      >
        {UserHeaderMenu && <UserHeaderMenu />}
      </HHBox>
    </AppBar>
  );
};
