import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LocalStorage } from '../../../../utils/local-storage';
import { ListRulesQuery, RuleStatus, useListRulesQuery } from '../types';

export const LOCAL_STORAGE_KEYS = {
  selectedSource: 'hingeConnect.rules.list.selectedSource',
  selectedStatus: 'hingeConnect.rules.list.selectedStatus',
  selectedResourceTypes: 'hingeConnect.rules.list.selectedResourceTypes',
};

export type ListRulesContextType = {
  selectedSource: string | undefined;
  setSelectedSource: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedStatus: RuleStatus | undefined;
  setSelectedStatus: React.Dispatch<
    React.SetStateAction<RuleStatus | undefined>
  >;
  selectedResourceTypes: string[] | undefined;
  setSelectedResourceTypes: React.Dispatch<
    React.SetStateAction<string[] | undefined>
  >;
  listRulesData: ListRulesQuery | undefined;
  listRulesLoading: boolean;
  listRulesError: Error | undefined;
  refetchListRules: () => void;
};

const ListRulesContext = createContext<ListRulesContextType>({
  selectedSource: undefined,
  setSelectedSource: () => {
    // do nothing
  },
  selectedStatus: undefined,
  setSelectedStatus: () => {
    // do nothing
  },
  selectedResourceTypes: undefined,
  setSelectedResourceTypes: () => {
    // do nothing
  },
  listRulesError: undefined,
  listRulesData: undefined,
  listRulesLoading: true,
  refetchListRules: () => {
    // do nothing
  },
});

export const useListRulesContext = (): ListRulesContextType =>
  useContext(ListRulesContext);

type ListRulesContextProviderProps = {
  children: React.ReactNode;
};

export const ListRulesContextProvider = ({
  children,
}: ListRulesContextProviderProps): JSX.Element => {
  const [selectedSource, setSelectedSource] = useState<string | undefined>(
    () => {
      const storedValue = LocalStorage.get(LOCAL_STORAGE_KEYS.selectedSource);
      return (storedValue as string) || undefined;
    },
  );

  const [selectedStatus, setSelectedStatus] = useState<RuleStatus | undefined>(
    () => {
      const storedValue = LocalStorage.get(LOCAL_STORAGE_KEYS.selectedStatus);
      return Object.values(RuleStatus).includes(storedValue as RuleStatus)
        ? (storedValue as RuleStatus)
        : undefined;
    },
  );

  const [selectedResourceTypes, setSelectedResourceTypes] = useState<
    string[] | undefined
  >(() => {
    const storedValue = LocalStorage.get(
      LOCAL_STORAGE_KEYS.selectedResourceTypes,
    );
    return (storedValue as string[]) || undefined;
  });

  useEffect(() => {
    LocalStorage.set(LOCAL_STORAGE_KEYS.selectedSource, selectedSource || '');
    LocalStorage.set(LOCAL_STORAGE_KEYS.selectedStatus, selectedStatus || '');
    LocalStorage.set(
      LOCAL_STORAGE_KEYS.selectedResourceTypes,
      selectedResourceTypes || [],
    );
  }, [selectedSource, selectedStatus, selectedResourceTypes]);

  const {
    data: listRulesData,
    loading: listRulesLoading,
    refetch: refetchListRules,
    error: listRulesError,
  } = useListRulesQuery({
    variables: {
      listRulesInput: {
        source: selectedSource,
        status: selectedStatus,
        resourceTypes:
          selectedResourceTypes && selectedResourceTypes.length > 0
            ? selectedResourceTypes
            : undefined,
      },
    },
  });

  const providerValue = useMemo(
    () => ({
      selectedSource,
      setSelectedSource,
      selectedStatus,
      setSelectedStatus,
      selectedResourceTypes,
      setSelectedResourceTypes,
      listRulesData,
      listRulesLoading,
      refetchListRules,
      listRulesError,
    }),
    [
      selectedSource,
      selectedStatus,
      selectedResourceTypes,
      listRulesData,
      listRulesLoading,
      refetchListRules,
      listRulesError,
    ],
  );

  return (
    <ListRulesContext.Provider value={providerValue}>
      {children}
    </ListRulesContext.Provider>
  );
};
