import { CardMedia, ListItem, ListItemIcon } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Location } from 'history';
import { Link, useLocation } from 'react-router-dom';
import { menuItem } from '../constants/colors/menu-item';
import { RouteDetails } from '../routes';

interface InputMenuItemProps {
  route: RouteDetails;
}

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      width: '21px',
      height: '21px',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    listItem: {
      padding: '10px 0',
    },
    selectedItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: menuItem.active,
    },
  }),
);

export const isMenuItemActive = (location: Location, route: string): boolean =>
  location?.pathname === route;

const MenuItem = (props: InputMenuItemProps): JSX.Element => {
  const classes = useStyles();
  const { route } = props;
  const SvgIcon = route.icon?.svg;
  const pngIcon = route.icon?.png;
  const location = useLocation();

  return (
    <Link key={route.label} to={route.menuPath ?? route.path}>
      <ListItem
        className={classes.listItem}
        selected={isMenuItemActive(location, route.path)}
        button
      >
        <ListItemIcon
          className={
            isMenuItemActive(location, route.path)
              ? classes.selectedItem
              : classes.iconContainer
          }
        >
          {SvgIcon ? (
            <SvgIcon />
          ) : (
            <CardMedia className={classes.icon} image={pngIcon} />
          )}
        </ListItemIcon>
      </ListItem>
    </Link>
  );
};

export default MenuItem;
