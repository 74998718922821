import {
  HHButton,
  HHLogo,
  HHTypography,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, ListItem } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { OktaLocalStorageKeys } from '../constants/okta';
import { RouteDetails } from '../routes';
import { getRoleProperties } from '../utils/roles';
import { MenuItem } from './index';
import { menuTheme } from './menu.theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .operations-hub-MuiDrawer-paperAnchorDockedLeft': {
        borderRight: 'none',
      },
      width: '56px',
      flexShrink: 0,
      '& .MuiList-padding': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiList-root': {
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        height: '90%',
      },
      '& .MuiPaper-root': {
        overflowX: 'hidden',
        width: '56px',
        backgroundColor: menuTheme.colors.menu,
      },
      '& .MuiListItemIcon-root': {
        color: `${menuTheme.colors.menuIcon}`,
        padding: '12px 0',
      },
      '& .MuiListItem-root': {
        padding: 0,
        '& .Mui-selected': {
          backgroundColor: `${menuTheme.colors.menuSelected}`,
          '&:hover': {
            backgroundColor: `${menuTheme.colors.menuSelectedHover}`,
          },
        },
      },
      '& .MuiListItem-gutters': {
        paddingLeft: 0,
      },
    },
    logo: {
      height: '35px',
      width: '35px',
      margin: '16px auto 0 auto',
    },
    productName: {
      color: menuTheme.colors.menuFont,
      fontSize: menuTheme.font.menuSize,
      fontFamily: menuTheme.font.menuFamily,
    },
  }),
);

const NavMenu = (): JSX.Element | null => {
  const classes = useStyles();
  const { hingeHealthAuthState, hingeHealthAuthClient } =
    useHingeHealthSecurityContext();
  const adminRoles = hingeHealthAuthState?.accessToken?.claims.roles;
  const { routes, showMenu } = getRoleProperties(adminRoles);
  const allOktaKeys = Object.values(OktaLocalStorageKeys);

  const handleLogout = async (): Promise<void> => {
    try {
      await hingeHealthAuthClient.signOut();
    } finally {
      allOktaKeys.forEach((oktaKey: string) =>
        localStorage.removeItem(oktaKey),
      );
    }
  };

  const navMenu = (
    <Box className={classes.root}>
      <Drawer variant="permanent">
        <HHLogo className={classes.logo} />
        <List>
          <ListItem
            style={{
              color: `${menuTheme.colors.menuFont}`,
              paddingLeft: '1em',
            }}
          >
            <HHTypography hhVariant="overline" fontSize={'6'}>
              Ops Hub
            </HHTypography>
          </ListItem>
          {routes.map((route: RouteDetails) => (
            <MenuItem key={route.path} route={route} />
          ))}
          <ListItem style={{ flexGrow: '3', alignItems: 'end' }}>
            <HHButton
              hhVariant="text"
              onClick={(): Promise<void> => handleLogout()}
            >
              <LogoutIcon titleAccess="Log Out" sx={{ color: 'white' }} />
            </HHButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );

  return showMenu ? navMenu : null;
};

export default NavMenu;
