import {
  HHAlert,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';

export interface NoRowsProps {
  error: boolean;
}

export function NoRowsOverlay({ error }: NoRowsProps): JSX.Element {
  return (
    <HHStack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      bgcolor={(theme): string => theme.palette.background.default}
    >
      {!error ? (
        <HHTypography hhVariant="body1" role="contentinfo">
          No results found.
        </HHTypography>
      ) : (
        <HHAlert
          hhVariant="outlined"
          severity="info"
          role="alert"
          children={
            <HHTypography hhVariant="body">
              There was an error fetching data.
            </HHTypography>
          }
        />
      )}
    </HHStack>
  );
}
