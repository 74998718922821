export interface IDiscountPrices {
  m1: number;
  m2: number;
  m3: number;
}

export interface IDiscountGroupState {
  R1: IDiscountPrices;
  R2: IDiscountPrices;
  R3: IDiscountPrices;
}

export interface IDiscountCalculatorState {
  chronicCoreCharge: number;
  groupState: IDiscountGroupState;
  revSharePercent: number;
}

const precision = (num: string, level = 2): number =>
  parseFloat(parseFloat(num).toPrecision(level));

const fixed = (num: string, level = 2): number =>
  parseFloat(parseFloat(num).toFixed(level));

const roundCorrection = (weights: IDiscountPrices): IDiscountPrices => {
  const diff = 100 - (weights.m1 + weights.m2 + weights.m3);
  return {
    ...weights,
    m1: weights.m1 + diff,
  };
};

const divideBy100 = (weights: IDiscountPrices): IDiscountPrices => ({
  m1: precision(String(weights.m1 / 100)),
  m2: precision(String(weights.m2 / 100)),
  m3: precision(String(weights.m3 / 100)),
});

export const totalDiscount = (
  chronicCoreCharge: number,
  groupState: IDiscountPrices,
): number =>
  chronicCoreCharge - (groupState.m2 + groupState.m3 + groupState.m1);

export const calculateWeights = (
  chronicCoreCharge: number,
  groupState: IDiscountPrices,
): IDiscountPrices => {
  if (!groupState) {
    return {
      m1: 0,
      m2: 0,
      m3: 0,
    };
  }
  const finalChronicPrice =
    chronicCoreCharge - totalDiscount(chronicCoreCharge, groupState);
  return divideBy100(
    roundCorrection({
      m1: precision(String((groupState.m1 / finalChronicPrice) * 100)),
      m2: precision(String((groupState.m2 / finalChronicPrice) * 100)),
      m3: precision(String((groupState.m3 / finalChronicPrice) * 100)),
    }),
  );
};

export const calculateWeightsByDiscounts = (
  chronicCoreCharge: number,
  groupState: IDiscountPrices,
  vbdDiscount: IDiscountPrices,
): IDiscountPrices => {
  if (!groupState) {
    return {
      m1: 0,
      m2: 0,
      m3: 0,
    };
  }
  const discount = totalDiscount(chronicCoreCharge, groupState);
  if (discount <= 0) {
    return calculateWeights(chronicCoreCharge, groupState);
  }

  return divideBy100(
    roundCorrection({
      m1: precision(String((vbdDiscount.m1 / discount) * 100)),
      m2: precision(String((vbdDiscount.m2 / discount) * 100)),
      m3: precision(String((vbdDiscount.m2 / discount) * 100)),
    }),
  );
};

export const calculateVbdDiscount = (
  groupState: IDiscountPrices,
  defaultPrices: IDiscountPrices,
): IDiscountPrices => {
  if (!groupState) {
    return {
      m1: 0,
      m2: 0,
      m3: 0,
    };
  }
  return {
    m1: parseFloat(String(defaultPrices.m1 - groupState.m1)),
    m2: parseFloat(String(defaultPrices.m2 - groupState.m2)),
    m3: parseFloat(String(defaultPrices.m3 - groupState.m3)),
  };
};

const calculateRevShare = (value: number, revSharePercent: number): number =>
  revSharePercent === 0
    ? value
    : fixed(String(value - value * (revSharePercent / 100)), 4);

export const calculateSubmission = (
  groupState: IDiscountPrices,
  revSharePercent: number,
): IDiscountPrices => {
  if (!groupState) {
    return {
      m1: 0,
      m2: 0,
      m3: 0,
    };
  }
  return {
    m1: calculateRevShare(groupState.m1, revSharePercent),
    m2: calculateRevShare(groupState.m2, revSharePercent),
    m3: calculateRevShare(groupState.m3, revSharePercent),
  };
};
