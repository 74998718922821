import { HHStack } from '@hinge-health/react-component-library';
import {
  IDiscount,
  PackageTypeDisplay,
  ProgramTypeDisplay,
} from '../../../utils/discount-utils';
import { SimplePackageType } from '../../contract/package/custom-package-types';
import { TextGrouping } from '../../utils/text-grouping';

export interface ProgramPackageDisplayProps {
  discountData: IDiscount;
  availablePackages: SimplePackageType[];
}

export const ProgramPackageDisplay = ({
  discountData,
  availablePackages,
}: ProgramPackageDisplayProps): JSX.Element => (
  <>
    {typeof discountData.programId === 'number' &&
    typeof discountData.packageId === 'number' ? (
      <HHStack direction="row" spacing={6}>
        <TextGrouping
          label="Program"
          text={ProgramTypeDisplay[discountData.programId]}
        />
        <TextGrouping
          label="Package"
          text={PackageTypeDisplay(discountData.packageId, availablePackages)}
        />
      </HHStack>
    ) : (
      <TextGrouping
        label={
          typeof discountData.programId === 'number' ? 'Program' : 'Package'
        }
        text={
          typeof discountData.programId === 'number'
            ? ProgramTypeDisplay[discountData.programId]
            : PackageTypeDisplay(
                discountData.packageId ?? -1,
                availablePackages,
              )
        }
      />
    )}
  </>
);
