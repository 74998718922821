export const routes = {
  billing: {
    home: '/billing-tool',
    main: '/billing-tool/client-insurer',
    clientInsurerPath: 'client-insurer',
    clientInsurerId: ':id',
    fullNewContractPath: '/client-insurer/:id/contract/new',
    newContract: 'contract/new',
    fullEditContractPath: '/client-insurer/:id/contract/:contractId/edit',
    editContract: 'contract/:contractId/edit',
    // package
    fullNewContractPackagePath: '/client-insurer/:id/contract/package/new',
    newContractPackage: 'contract/package/new',
    fullEditContractPackagePath:
      '/client-insurer/:id/contract/package/:contractId/edit',
    editContractPackageContract: 'contract/package/:contractId/edit',
    fullBatchContractCreateStatus:
      '/client-insurer/batch-create-contract/status',
    batchContractCreateStatus: 'batch-create-contract/status',
    batchContractCreate: '/client-insurer/batch-create-contract',
    discountCalculator: 'discount-calculator',
    clientPath: 'client',
    clientId: ':id',
    userPath: 'user',
    userId: ':id',
    billsPath: '/bills',
    billsHolds: '/bills/holds',
  },
};
