import { APP_ENV } from '../constants/env';

export interface PatientsBaseUrls {
  ADMIN_PANEL_USER_PROFILE: string;
  COACH_TOOLS_USER_PROFILE: string;
}

export function getPatientsBaseURLs(): PatientsBaseUrls {
  const activeAdminURL = process.env.REACT_APP_ADMIN_PANEL_URL || '';
  const coachToolsURL = process.env.REACT_APP_COACH_TOOLS_URL || '';
  return {
    ADMIN_PANEL_USER_PROFILE: activeAdminURL,
    COACH_TOOLS_USER_PROFILE: coachToolsURL,
  };
}

export const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: process.env.SPLIT_CTT_SHELL_AUTH_KEY || '',
    key: 'random key',
  },
};

export const getBasePath = (): string =>
  process.env.APP_ENV === APP_ENV.DEV_PREVIEW
    ? `/${process.env.REACT_APP_BASE_NAME}`
    : '';

/**
 * Add Split.io treatment names to this object to reference in components.
 * To add the treatment condition, refer to the TypeScript and hook examples here:
 * https://help.split.io/hc/en-us/articles/360038825091-React-SDK
 */
// export const splitTreatmentNames = {}
