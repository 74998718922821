import { HHStack, HHTypography } from '@hinge-health/react-component-library';

const DiscountInfo = (): JSX.Element => (
  <HHStack spacing={2}>
    <HHStack spacing={2}>
      <HHTypography hhVariant="overline">Program</HHTypography>
      <HHTypography hhVariant="body2">
        Program discounts are applied on programs and classic type contracts
        with chronic/acute subscriptions. They are not applied to new engagement
        based billing models
      </HHTypography>
    </HHStack>
    <HHStack spacing={2}>
      <HHTypography hhVariant="overline">Package</HHTypography>
      <HHTypography hhVariant="body2">
        Package based discounts will be applied to new engagement based billing
        models and other packages going forward.
      </HHTypography>
    </HHStack>
  </HHStack>
);

export default DiscountInfo;
