export const targetedInterventions = {
  menuLabel: 'Targeted Interventions',
  text: 'Targeted Interventions - High-Risk Outreach Workflows',
  error: 'Unable to load Targeted Interventions',
};

export const targetedInterventionsEnrollment = {
  menuLabel: 'Targeted Interventions - User Enrollment',
  text: 'Targeted Interventions - User Enrollment',
  error: 'Unable to load Targeted Interventions - User Enrollment',
};
