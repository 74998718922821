import { ApolloError } from '@apollo/client';
import {
  HHBox,
  HHIconButton,
  HHTooltip,
  HHTypography,
} from '@hinge-health/react-component-library';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useState } from 'react';
import { useModal } from '../../../hooks/use-modal';
import {
  healthRecordsGridColumnHeaders,
  healthRecordsInfoMessages,
  healthRecordsPagination,
} from '../../constants/health-records.constant';
import {
  ListHealthRecordsAtCursorQuery,
  ListHealthRecordsQuery,
} from '../../types';
import { HealthRecordDetailModal } from '../detail/health-record-detail-modal';

const NoHealthRecordsMessage = ({
  error,
}: {
  error?: ApolloError;
}): JSX.Element => (
  <HHBox
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    padding="1rem"
  >
    <HHTypography hhVariant="h3">
      {error
        ? healthRecordsInfoMessages.oops
        : healthRecordsInfoMessages.noHealthRecords}
    </HHTypography>
    <HHTypography hhVariant="body">
      {error
        ? error.message + ' ' + healthRecordsInfoMessages.trySearchingAgain
        : healthRecordsInfoMessages.trySource}
    </HHTypography>
  </HHBox>
);

const HealthRecordsPageNavigation = ({
  next,
  previous,
  handlePageTurnClick,
}: {
  next: string;
  previous: string;
  handlePageTurnClick: (cursorPath: string) => void;
}): JSX.Element => {
  const handleNextClick = (): void => {
    if (next) handlePageTurnClick(next);
  };

  const handlePreviousClick = (): void => {
    if (previous) handlePageTurnClick(previous);
  };

  return (
    <HHBox
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="1rem"
      width="100%"
    >
      <HHTooltip title={healthRecordsPagination.previous} hhVariant="bottom">
        <span>
          <HHIconButton
            size="small"
            color="primary"
            aria-label="previous page"
            hhVariant="variant-bypass"
            data-testid="previous-page-button"
            onClick={handlePreviousClick}
            disabled={!previous}
          >
            <ArrowBack />
          </HHIconButton>
        </span>
      </HHTooltip>
      <HHTooltip title={healthRecordsPagination.next} hhVariant="bottom">
        <span>
          <HHIconButton
            size="small"
            color="primary"
            aria-label="next page"
            hhVariant="variant-bypass"
            data-testid="next-page-button"
            onClick={handleNextClick}
            disabled={!next}
          >
            <ArrowForward />
          </HHIconButton>
        </span>
      </HHTooltip>
    </HHBox>
  );
};

interface ListHealthRecordsGridProps {
  data?: ListHealthRecordsQuery | ListHealthRecordsAtCursorQuery;
  loading: boolean;
  error?: ApolloError;
  handlePageTurnClick: (cursorPath: string) => void;
}

export const ListHealthRecordsGrid = ({
  data,
  loading,
  error,
  handlePageTurnClick,
}: ListHealthRecordsGridProps): JSX.Element => {
  const { isOpen, handleOpen, handleClose } = useModal();
  const [selectedId, setSelectedId] = useState<string>('');

  function isListHealthRecordsQuery(
    data: ListHealthRecordsQuery | ListHealthRecordsAtCursorQuery,
  ): data is ListHealthRecordsQuery {
    return (data as ListHealthRecordsQuery).listHealthRecords !== undefined;
  }

  const ListHealthRecordsColumns: GridColumns = [
    {
      field: 'createdAt',
      headerName: healthRecordsGridColumnHeaders.createdAt,
      flex: 0.75,
      renderCell: (params): string => {
        const date = new Date(params.value as string);
        return date.toLocaleString();
      },
    },
    {
      field: 'id',
      headerName: healthRecordsGridColumnHeaders.id,
      flex: 0.5,
    },
    {
      field: 'source',
      headerName: healthRecordsGridColumnHeaders.source,
      flex: 1,
    },
    {
      field: 'resourceType',
      headerName: healthRecordsGridColumnHeaders.resourceType,
      flex: 1,
    },
    {
      field: 'userId',
      headerName: healthRecordsGridColumnHeaders.userId,
      flex: 1,
    },
    {
      field: 'origin',
      headerName: healthRecordsGridColumnHeaders.origin,
      flex: 1,
    },
  ];

  return (
    <>
      <HealthRecordDetailModal id={selectedId} {...{ isOpen, handleClose }} />
      <DataGrid
        components={{
          NoRowsOverlay: NoHealthRecordsMessage,
          LoadingOverlay: LinearProgress,
          Pagination: HealthRecordsPageNavigation,
        }}
        componentsProps={{
          noRowsOverlay: { error },
          pagination: {
            next:
              data && isListHealthRecordsQuery(data)
                ? data.listHealthRecords?.links.next || ''
                : data?.listHealthRecordsAtCursor?.links.next || '',
            previous:
              data && isListHealthRecordsQuery(data)
                ? data.listHealthRecords?.links.previous || ''
                : data?.listHealthRecordsAtCursor?.links.previous || '',
            handlePageTurnClick,
          },
        }}
        sx={{
          '& .MuiDataGrid-cell:hover': {
            cursor: 'default',
          },
        }}
        columnBuffer={10}
        disableColumnMenu
        autoHeight
        columns={ListHealthRecordsColumns}
        rows={
          data && isListHealthRecordsQuery(data)
            ? data.listHealthRecords?.healthRecords
            : data?.listHealthRecordsAtCursor?.healthRecords || []
        }
        isRowSelectable={(): boolean => false}
        onRowClick={(params): void => {
          const { id } = params.row;
          setSelectedId(id);
          handleOpen();
        }}
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'createdAt',
                sort: 'desc',
              },
            ],
          },
        }}
      />
    </>
  );
};
