import {
  HHButton,
  HHLoadingButton,
  HHSnackbarAlert,
  HHStack,
  HHTextField,
} from '@hinge-health/react-component-library';
import { Autocomplete, TextField } from '@mui/material';
import { SyntheticEvent } from 'react';
import { DateRangePicker } from '../../components/date-range-picker';
import { HCModal } from '../../components/hinge-connect-modal';
import {
  buttonLabels,
  sourceAutoCompleteOptions,
} from '../../constants/hinge-connect-constants.constant';
import { useSnackbar } from '../../hooks/use-snackbar';
import { ruleTextFields } from '../../rules/constants/rules.constant';
import {
  assessmentTasksSnackbars,
  assessmentTasksTabContent,
  assessmentTasksTextFields,
} from '../constants/assessment-tasks.constant';
import { useAssessmentTaskForm } from '../hooks/use-assessment-task-form';
import { useCreateAssessmentTask } from '../hooks/use-create-assessment-task';
import { CreateAssessmentTaskInput } from '../types';

type CreateAssessmentTaskFormProps = {
  handleClose: () => void;
  onSubmit: (createAssessmentTaskInput: CreateAssessmentTaskInput) => void;
  loading: boolean;
};

const CreateAssessmentTaskForm = ({
  handleClose,
  onSubmit,
  loading,
}: CreateAssessmentTaskFormProps): JSX.Element => {
  const {
    source,
    setSource,
    resourceType,
    setResourceType,
    ruleId,
    setRuleId,
    assessmentWindow,
    setAssessmentWindow,
    limit,
    setLimit,
    validateLimit,
    submitEnabled,
    handleSubmit,
  } = useAssessmentTaskForm(onSubmit);

  const handleSourceChange = (
    _e: SyntheticEvent,
    option: string | { label: string; value: string } | null,
  ): void => {
    if (typeof option === 'string') {
      setSource(option);
      return;
    }
    setSource(option?.value ?? '');
  };

  return (
    <form
      onSubmit={(e): void => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <HHStack width="40vw" maxWidth="500px" paddingTop="7px">
        <Autocomplete
          value={source}
          freeSolo={true}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              label={ruleTextFields.source.label}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'source-select',
                'aria-label': ruleTextFields.source.label,
              }}
              required
            />
          )}
          options={sourceAutoCompleteOptions}
          onChange={(e, option): void => handleSourceChange(e, option)}
          onInputChange={(e, option): void => handleSourceChange(e, option)}
          size="small"
        />
        <HHTextField
          hhVariant="variant-bypass"
          margin="normal"
          label={assessmentTasksTextFields.resourceType.label}
          inputProps={{
            'aria-label': assessmentTasksTextFields.resourceType.label,
          }}
          helperText={assessmentTasksTextFields.resourceType.helperText}
          value={resourceType}
          onChange={(e): void => setResourceType(e.target.value)}
        />
        <HHTextField
          hhVariant="variant-bypass"
          margin="normal"
          label={assessmentTasksTextFields.ruleId.label}
          inputProps={{
            'aria-label': assessmentTasksTextFields.ruleId.label,
          }}
          helperText={assessmentTasksTextFields.ruleId.helperText}
          value={ruleId}
          onChange={(e): void => setRuleId(e.target.value)}
        />

        <DateRangePicker
          label={assessmentTasksTextFields.assessmentWindow.label}
          helperText={assessmentTasksTextFields.assessmentWindow.helperText}
          dateRange={assessmentWindow}
          setDateRange={setAssessmentWindow}
        />

        <HHTextField
          hhVariant="variant-bypass"
          margin="normal"
          label={assessmentTasksTextFields.limit.label}
          type="number"
          inputProps={{
            'aria-label': assessmentTasksTextFields.limit.label,
            min: 1,
            step: 1,
          }}
          helperText={assessmentTasksTextFields.limit.helperText}
          error={!validateLimit}
          value={limit}
          onChange={(e): void => setLimit(e.target.value)}
        />
      </HHStack>

      <HHStack direction="row" spacing={4} justifyContent="right">
        <HHButton
          hhVariant="text"
          type="button"
          sx={{ margin: '1rem' }}
          onClick={handleClose}
        >
          {buttonLabels.cancel}
        </HHButton>
        <HHLoadingButton
          hhVariant="variant-bypass"
          variant="contained"
          type="submit"
          disabled={!submitEnabled}
          loading={loading}
          data-testid="submit-button"
        >
          {buttonLabels.create}
        </HHLoadingButton>
      </HHStack>
    </form>
  );
};

interface CreateAssessmentTaskModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const CreateAssessmentTaskModal = ({
  isOpen,
  handleClose,
}: CreateAssessmentTaskModalProps): JSX.Element => {
  const { createAssessmentTask, data, loading, error, errorMessages } =
    useCreateAssessmentTask(handleClose);
  const { snackbar, handleClose: handleSnackbarClose } = useSnackbar({
    data,
    error,
    errorMessages,
    successMessage: assessmentTasksSnackbars.create,
  });

  const onSubmit = (
    createAssessmentTaskInput: CreateAssessmentTaskInput,
  ): void => {
    createAssessmentTask({ variables: { createAssessmentTaskInput } });
  };

  return (
    <>
      <HHSnackbarAlert
        message={snackbar.message}
        severity={snackbar.severity}
        verticalAlignment="top"
        horizontalAlignment="center"
        hhVariant="standard"
        open={snackbar.open}
        onClose={handleSnackbarClose}
        autoHideTime={snackbar.severity === 'success' ? 3000 : undefined}
      />
      <HCModal
        title={assessmentTasksTabContent.createView.title}
        isOpen={isOpen}
        handleClose={handleClose}
        component={
          <CreateAssessmentTaskForm {...{ handleClose, onSubmit, loading }} />
        }
      />
    </>
  );
};
