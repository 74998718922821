import { HHIconButton } from '@hinge-health/react-component-library';
import { Delete } from '@mui/icons-material';
import { useModal } from '../../hooks/use-modal';
import { DeleteTagModal } from './delete-tag-modal';

export const ListTagsGridDeleteButton = ({
  id,
  tagName,
  deleteTag,
  deleteTagLoading,
}: {
  id: string;
  tagName: string;
  deleteTag: ({ variables }: { variables: { id: string } }) => void;
  deleteTagLoading: boolean;
}): JSX.Element => {
  const { isOpen, handleOpen, handleClose } = useModal();

  return (
    <>
      <DeleteTagModal
        tagId={id}
        tagName={tagName}
        isOpen={isOpen}
        handleClose={handleClose}
        deleteTag={deleteTag}
        deleteTagLoading={deleteTagLoading}
      />
      <HHIconButton
        hhVariant="variant-bypass"
        aria-label="delete"
        data-testid={`delete-tag-button-${id}`}
        onClick={handleOpen}
      >
        <Delete />
      </HHIconButton>
    </>
  );
};
