import { HHTypography } from '@hinge-health/react-component-library';
import InsertDriveFileRounded from '@mui/icons-material/InsertDriveFileRounded';
import Stack from '@mui/material/Stack';
import { ContractTemplateType } from '../../types';
import ContractDetailView from './contract-detail-view';
import { UsableContractType } from './form/custom-types';

interface ActiveContractDetailsProps {
  data: UsableContractType | undefined;
  templates: ContractTemplateType[];
  clientInsurerName: string;
}

const ActiveContractDetails = ({
  data,
  templates,
  clientInsurerName,
}: ActiveContractDetailsProps): JSX.Element => {
  if (!data) {
    return (
      <Stack
        direction="column"
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '200px',
          fontSize: '3.5rem',
          backgroundColor: '#f8f9fa',
          borderRadius: '5px',
        }}
      >
        <InsertDriveFileRounded color="disabled" fontSize="inherit" />
        <HHTypography hhVariant="body">
          {`No active contracts for ${clientInsurerName}`}
        </HHTypography>
      </Stack>
    );
  }

  return <ContractDetailView data={data} templates={templates} />;
};

export default ActiveContractDetails;
