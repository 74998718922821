import {
  HHButton,
  HHSnackbarAlert,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Box, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateFileConfigurationMutation } from '../../../../member-data/types';
import { ClientInformationSection } from './client-information-section';
import { ColumnMappingsSection } from './column-mappings-section';
import { FileInformationSection } from './file-information-section';
import { ParserSection } from './parser-section';

const validateFileType = (
  fileType: string,
  firstName: string,
  lastName: string,
  dob: string,
  externalId: string,
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};
  if (fileType === 'E-File') {
    if (!firstName) errors.firstName = 'First Name is required for E-File';
    if (!lastName) errors.lastName = 'Last Name is required for E-File';
    if (!dob) errors.dob = 'Date of Birth is required for E-File';
  } else if (fileType === 'Supplemental') {
    const isPersonalInfoProvided = firstName && lastName && dob;
    if (!isPersonalInfoProvided && !externalId) {
      errors.firstName =
        'First Name, Last Name, and Date of Birth are required or External Identifier';
      errors.externalId =
        'External Identifier or First Name, Last Name, and Date of Birth is required';
    }
  }
  return errors;
};

const validateContentType = (
  contentType: string,
  partnership: string,
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};
  if (contentType === 'Multi Client Multi Insurer (MCMI)' && !partnership) {
    errors.partnership = 'Partnership is required for MCMI';
  }
  return errors;
};

const validateParserType = (
  parserType: string,
  delimiterChar: string,
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};
  if (parserType === 'Delimiter' && !delimiterChar) {
    errors.delimiterChar =
      'Delimiter Character is required for Delimiter Parser';
  }
  return errors;
};

const validateRequiredFields = (
  ftpFolder: string,
  fileName: string,
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};
  if (!ftpFolder) errors.ftpFolder = 'FTP Folder is required';
  if (!fileName) errors.fileName = 'File Name is required';
  return errors;
};

export const AddFileIngestionConfig = (): JSX.Element => {
  const navigate = useNavigate();
  const [createFileConfiguration] = useCreateFileConfigurationMutation();
  const [fileType, setFileType] = useState('');
  const [contentType, setContentType] = useState('');
  const [ftpFolder, setFtpFolder] = useState('');
  const [fileName, setFileName] = useState('');
  const [partnership, setPartnership] = useState('');
  const [client, setClient] = useState('');
  const [insurer, setInsurer] = useState('');
  const [parserType, setParserType] = useState('');
  const [encrypted, setEncrypted] = useState(false);
  const [delimiterChar, setDelimiterChar] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [externalId, setExternalId] = useState('');
  const [provider] = useState('defaultProvider');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [alert, setAlert] = useState<{
    type: 'success' | 'error';
    message: string;
  } | null>(null);

  const handleCancel = (): void => {
    navigate('/member-data-services/file-configurations/list');
  };

  useEffect(() => {
    let newErrors: { [key: string]: string } = {};

    newErrors = {
      ...newErrors,
      ...validateFileType(fileType, firstName, lastName, dob, externalId),
    };
    newErrors = {
      ...newErrors,
      ...validateContentType(contentType, partnership),
    };
    newErrors = {
      ...newErrors,
      ...validateParserType(parserType, delimiterChar),
    };
    newErrors = {
      ...newErrors,
      ...validateRequiredFields(ftpFolder, fileName),
    };

    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
  }, [
    fileType,
    contentType,
    firstName,
    lastName,
    dob,
    externalId,
    partnership,
    parserType,
    delimiterChar,
    ftpFolder,
    fileName,
  ]);

  const handleAdd = async (): Promise<void> => {
    const payload = {
      name: fileName,
      description: '',
      bucket: ftpFolder,
      regex: fileName,
      encodingDetection: true,
      pgpEncrypted: encrypted,
      parser: {
        name: 'DelimitedFileParser',
        delimiter: delimiterChar,
        skip_header: 'false',
        skip_footer: 'false',
        trim_header_fields_whitespace: 'false',
        trim_record_fields_whitespace: 'false',
        set_header_fields_case: 'none',
        set_record_fields_case: 'none',
      },
      columnMappings: {},
      provider,
      entityTags: {
        client,
        insurer,
        provider,
      },
      individualOperations: [],
      fileOperations: [],
      groupOperations: [],
    };

    try {
      await createFileConfiguration({ variables: { data: payload } });
      setAlert({
        type: 'success',
        message: 'File configuration created successfully',
      });
      setTimeout(() => {
        setAlert(null);
        navigate('/member-data-services/file-configurations/list', {
          state: { refresh: true },
        });
      }, 3000);
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Error creating file configuration';
      setAlert({
        type: 'error',
        message: errorMessage,
      });
      setTimeout(() => setAlert(null), 3000);
      console.error('Error creating file configuration:', error);
    }
  };

  return (
    <Box p={3}>
      <HHTypography hhVariant="h2">Add New File Ingestion Config</HHTypography>
      <br />
      <HHTypography hhVariant="h1">File Information</HHTypography>
      <FileInformationSection
        fileType={fileType}
        setFileType={setFileType}
        contentType={contentType}
        setContentType={setContentType}
        ftpFolder={ftpFolder}
        setFtpFolder={setFtpFolder}
        fileName={fileName}
        setFileName={setFileName}
        errors={errors}
      />
      <br />
      <HHTypography hhVariant="h1" mt={2}>
        Client Information
      </HHTypography>
      <ClientInformationSection
        partnership={partnership}
        setPartnership={setPartnership}
        client={client}
        setClient={setClient}
        insurer={insurer}
        setInsurer={setInsurer}
        errors={errors}
      />
      <br />
      <HHTypography hhVariant="h1" mt={2}>
        Parser
      </HHTypography>
      <ParserSection
        parserType={parserType}
        setParserType={setParserType}
        encrypted={encrypted}
        setEncrypted={setEncrypted}
        delimiterChar={delimiterChar}
        setDelimiterChar={setDelimiterChar}
        errors={errors}
      />
      <br />
      <Divider sx={{ my: 4 }} />
      <HHTypography hhVariant="h1" mt={2}>
        Column Mappings
      </HHTypography>
      <ColumnMappingsSection
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        dob={dob}
        setDob={setDob}
        externalId={externalId}
        setExternalId={setExternalId}
        errors={errors}
      />
      <br />
      <Box mt={4} display="flex" justifyContent="flex-end">
        <HHButton onClick={handleCancel} hhVariant={'text'}>
          Cancel
        </HHButton>
        <Box ml={2}>
          <HHButton
            disabled={!isFormValid}
            hhVariant="contained"
            onClick={handleAdd}
          >
            Add
          </HHButton>
        </Box>
      </Box>
      {alert && (
        <HHSnackbarAlert
          open={true}
          onClose={(): void => setAlert(null)}
          severity={alert.type}
          autoHideDuration={3000}
          verticalAlignment="top"
          horizontalAlignment="center"
          sx={{ width: '300px' }}
          hhVariant={'standard'}
          message={alert.message}
        ></HHSnackbarAlert>
      )}
    </Box>
  );
};
