import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { MemberData } from '../modules/member-data';
import { MemberDataError } from '../modules/member-data/constants/strings/errors';

export const MemberDataPage = (): JSX.Element => (
  <Sentry.ErrorBoundary fallback={<Box>{MemberDataError}</Box>}>
    <MemberData />
  </Sentry.ErrorBoundary>
);
