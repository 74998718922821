import {
  HHTextField,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Autocomplete } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useGetPartnershipsQuery } from '../../../../types';

export const PARTNERSHIP_SELECT_ID = 'partnership-select';

export interface PartnershipSelectProps {
  wholeFormDisabled: boolean | undefined;
}

export const PartnershipSelect = ({
  wholeFormDisabled,
}: PartnershipSelectProps): JSX.Element => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { data, error } = useGetPartnershipsQuery({
    variables: {
      type: 'billing',
    },
  });

  if (error) {
    return (
      <HHTypography hhVariant="body1" color="error">
        Partnerships unavailable
      </HHTypography>
    );
  }

  const formatPartnershipOptions =
    data?.getPartnerships.map(partnership => ({
      label: partnership.name,
      value: partnership.id,
    })) ?? [];

  return (
    <Controller
      control={control}
      name="partnershipId"
      render={({ field: { onChange, value, ref } }): JSX.Element => (
        <Autocomplete
          ref={ref}
          key="partnershipId"
          fullWidth
          data-testid={PARTNERSHIP_SELECT_ID}
          disabled={wholeFormDisabled}
          options={formatPartnershipOptions}
          onChange={(_, newValue): void => {
            onChange(newValue?.value ?? null);
          }}
          value={
            formatPartnershipOptions.find(option => option.value === value) ||
            null
          }
          renderInput={(params): JSX.Element => (
            <HHTextField
              {...params}
              label="Partnership"
              hhVariant="variant-bypass"
              name="partnershipId"
              error={!!errors.partnershipId}
              helperText={errors.partnershipId?.message}
            />
          )}
        />
      )}
    />
  );
};
