import { Sidebar } from '@hinge-health/react-component-library';
import { useAtom } from 'jotai';
import type { MouseEvent } from 'react';
import { showAmtSidebarAtom } from '../../jotai-atoms/atoms';
import { AmtMemberLayout } from './amt-member-layout';

export const AmtSidebarLayout = (): JSX.Element => {
  const [, setShowSidebar] = useAtom(showAmtSidebarAtom);

  const handleClose = (): void => {
    setShowSidebar(false);
  };

  const handleBackArrowClick = (e: MouseEvent): void => {
    e.preventDefault();
    handleClose();
  };

  return (
    <Sidebar
      onSidebarClose={handleClose}
      onSidebarToggled={handleClose}
      onBackArrowClick={handleBackArrowClick}
      initialOpenState
    >
      <AmtMemberLayout onComplete={handleClose} />
    </Sidebar>
  );
};
