import { HHTypography } from '@hinge-health/react-component-library';
import { IDiscount, IDiscountUsage } from '../../../utils/discount-utils';

export interface FreeBillableActivitiesUsageProps {
  discountData: IDiscount;
}

const getUsageDisplay = (discountUsage: IDiscountUsage): string => {
  if (discountUsage.loading || discountUsage.error) {
    return '--';
  }

  return String(discountUsage.totalCount ?? 0);
};

const FreeBillableActivitiesUsage = ({
  discountData,
}: FreeBillableActivitiesUsageProps): JSX.Element => {
  const totalFreeCareSessions = discountData.discountDetails[0]?.discount;
  return (
    <HHTypography hhVariant="inherit">
      {getUsageDisplay(discountData.discountDetails[0].discountUsage)}/
      {totalFreeCareSessions ? totalFreeCareSessions : null} Free Billable
      Activities Used
    </HHTypography>
  );
};

export default FreeBillableActivitiesUsage;
