import {
  HHBaseTable,
  HHLink,
  HHPagination,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { routes } from '../../constants/strings/routes';
import { UnbilledBillsPaginatedType } from '../../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../utils/bills-utils';
import { formatCurrency } from '../../utils/currency-helpers';
import LoadingComponent from '../utils/loading';
import { UnbilledTypeDataList } from './utils';

export enum GridColumnHeaderNames {
  CLIENT = 'Client',
  INSURER = 'Insurer',
  BILLING_TYPE = 'Billing type',
  TOTAL_BILLS = 'Total Bills',
  TOTAL_UNBILLED_AMOUNT = 'Total Unbilled Amount',
}

export interface UnbilledGridProps {
  paginationData: UnbilledBillsPaginatedType | undefined;
  loading: boolean;
  hasError: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rows: UnbilledTypeDataList;
}

export const UnbilledGrid = ({
  paginationData,
  loading,
  hasError,
  page,
  setPage,
  rows,
}: UnbilledGridProps): JSX.Element => {
  if (hasError) {
    return (
      <HHTypography
        hhVariant="subtitle1"
        color="error"
        sx={{ alignSelf: 'center' }}
      >
        Error fetching unbilled
      </HHTypography>
    );
  }

  if (loading) {
    return <LoadingComponent center />;
  }

  const showPagination = paginationData?.pages && rows.length > 0;

  return (
    <HHStack spacing={4}>
      {rows.length === 0 ? (
        <HHTypography hhVariant="muted">No unbilled results</HHTypography>
      ) : (
        <HHBaseTable
          headerRowBottomBorder="1px solid rgba(0, 0, 0, 0.12)"
          bodyRowBorder={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
          bodyTypographyVariant="inherit"
          tableHeaders={[
            { value: GridColumnHeaderNames.CLIENT, align: 'left' },
            { value: GridColumnHeaderNames.INSURER, align: 'left' },
            { value: GridColumnHeaderNames.BILLING_TYPE, align: 'left' },
            { value: GridColumnHeaderNames.TOTAL_BILLS, align: 'left' },
            {
              value: GridColumnHeaderNames.TOTAL_UNBILLED_AMOUNT,
              align: 'left',
            },
          ]}
          bodyContent={rows.map(row => ({
            client: {
              value: row.client.name ? (
                <HHLink
                  color="primary"
                  href={`${routes.billing.home}/${routes.billing.clientPath}/${row.client.id}`}
                  target="_blank"
                  sx={{ textDecoration: 'none' }}
                >
                  {row.client.name}
                </HHLink>
              ) : (
                <div>Loading...</div>
              ),
            },
            insurer: {
              value: row.insurer.name ?? 'Loading...',
            },
            billingType: {
              value:
                capitalizeFirstLetterAndSpaceSnakeString(
                  row.billingType.value ?? 'unavailable',
                ) ?? 'Loading...',
            },
            billsCount: {
              value: row.billsCount ?? 'Loading...',
            },
            totalUnbilledAmount: {
              value: formatCurrency(row.unbilled ?? 0) ?? 'Loading...',
            },
          }))}
        />
      )}
      {showPagination ? (
        <HHStack direction="row" justifyContent="end">
          <HHPagination
            page={page}
            count={paginationData.pages ?? 1}
            onChange={(_, value): void => setPage(value)}
          />
        </HHStack>
      ) : null}
    </HHStack>
  );
};
