import { HHChip, HHChipProps } from '@hinge-health/react-component-library';
import { RuleStatus } from '../types';

export const ListRulesGridStatusChip = ({
  status,
}: {
  status: string;
}): JSX.Element => {
  const getStatusColor = (status: string): HHChipProps['color'] => {
    switch (status) {
      case RuleStatus.Published:
        return 'primary';
      case RuleStatus.Unpublished:
        return 'warning';
      case RuleStatus.Draft:
      default:
        return 'neutral';
    }
  };

  const statusLabel =
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  return (
    <HHChip
      hhVariant="variant-bypass"
      color={getStatusColor(status)}
      label={statusLabel}
    />
  );
};
