import { HHTextField } from '@hinge-health/react-component-library';
import { Autocomplete } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { capitalizeFirstLetterAndSpaceSnakeString } from '../../../../utils/bills-utils';
import { CleanAvailablePackages } from '../../form/custom-types';
import { PackageFormData } from '../custom-package-types';
import { ContractPackageFormLabelText, fieldArrayName } from '../form';

export interface PackageSelectProps {
  packages: CleanAvailablePackages[];
  wholeFormDisabled: boolean;
  index: number;
}

export const PackageSelect = ({
  packages,
  index,
  wholeFormDisabled,
}: PackageSelectProps): JSX.Element => {
  const {
    control,
    watch,
    formState: { errors },
    trigger,
  } = useFormContext<PackageFormData>();
  const watchedPackage = watch(`selectedPackages.${index}.package`);

  useEffect(() => {
    if (watchedPackage?.id > 0) {
      trigger('selectedPackages');
    }
  }, [trigger, watchedPackage]);

  return (
    <Controller
      control={control}
      name={`${fieldArrayName}.${index}.package`}
      defaultValue={watchedPackage} // fix with https://hingehealth.atlassian.net/browse/MINT-8629
      render={({ field: { onChange, value } }): JSX.Element => (
        <Autocomplete
          disabled={wholeFormDisabled}
          options={packages}
          getOptionLabel={(option): string =>
            capitalizeFirstLetterAndSpaceSnakeString(option.name)
          }
          isOptionEqualToValue={(option, value): boolean =>
            option.id === value.id
          }
          fullWidth
          onChange={(_, newValue): void => onChange(newValue)}
          value={value}
          renderInput={(params): JSX.Element => (
            <HHTextField
              {...params}
              label={ContractPackageFormLabelText.PACKAGE}
              hhVariant="variant-bypass"
              variant="outlined"
              fullWidth
              error={
                Object.keys(errors?.selectedPackages?.[index]?.package ?? {})
                  .length !== 0
              }
              name="package"
              helperText={errors?.selectedPackages?.[index]?.package?.message}
              sx={{ '& .MuiInputLabel-root': { color: 'gray' } }}
            />
          )}
        />
      )}
    />
  );
};
