import {
  HHBox,
  HHButton,
  HHGrid,
  HHSelect,
  HHSelectChangeEvent,
  HHTextField,
} from '@hinge-health/react-component-library';
import { createStyles, makeStyles } from '@mui/styles';
import { ChangeEvent } from 'react';
import type {
  FilterStates,
  WorkflowPayloadProperty,
} from '../../contexts/unassigned-workflows.context';
import { AMT_PATHWAY_TRANSITION_STATES } from '../../custom-types';

type Order = 'ASC' | 'DESC';

type WorkflowField = {
  displayName: string;
  value: WorkflowPayloadProperty | '';
};

const workflowFields: WorkflowField[] = [
  { displayName: 'Viewed', value: 'viewed' },
  { displayName: 'Id', value: 'id' },
  { displayName: 'Created At', value: 'createdAt' },
  { displayName: 'Updated At', value: 'updatedAt' },
  { displayName: 'Is Past Due', value: 'isPastDue' },
  { displayName: 'State', value: 'state' },
];

type StateField = {
  displayName: string;
  value: AMT_PATHWAY_TRANSITION_STATES | '';
};

const statesFields: StateField[] = [
  {
    displayName: 'No Filter',
    value: '',
  },
  {
    displayName: 'Returning from Chronic to Chronic',
    value: AMT_PATHWAY_TRANSITION_STATES.RETURNING_CHRONIC_TO_CHRONIC_REQUESTED,
  },
  {
    displayName: 'Waiting for Response',
    value: AMT_PATHWAY_TRANSITION_STATES.WAITING_FOR_RESPONSE,
  },
  {
    displayName: 'Response Received',
    value: AMT_PATHWAY_TRANSITION_STATES.RESPONSE_RECEIVED,
  },
  {
    displayName: 'Waiting for Provisioning',
    value: AMT_PATHWAY_TRANSITION_STATES.WAITING_FOR_PROVISIONING,
  },
];

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      width: '100%',
      paddingTop: '0.5em',
    },
    container: {
      padding: '0 0.5em 0 0.5em',
      justifyContent: 'space-between',
      width: '100%',
    },
    subcontainer: {
      width: 'auto',
    },
    item: {
      margin: '0 0.5em 0 0.5em',
      '& label': {
        color: '#000',
      },
    },
  }),
);

export const AmtFilter = ({
  filterState,
  patientId,
  patientUuid,
  order,
  orderBy,
}: FilterStates): JSX.Element => {
  const [filterStateValue, setFilterState] = filterState;
  const [patientIdValue, setPatientId] = patientId;
  const [patientUuidValue, setPatientUuid] = patientUuid;
  const [orderValue, setOrder] = order;
  const [orderByValue, setOrderBy] = orderBy;

  const { container, item, box, subcontainer } = useStyles();

  const handleStatesChange = (e: HHSelectChangeEvent): void => {
    if (!e.target.value) {
      setFilterState(undefined);
      return;
    }
    const state = e.target.value as AMT_PATHWAY_TRANSITION_STATES;
    setFilterState(state);
  };

  const handlePatientIdChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.value) {
      return setPatientId(undefined);
    }
    setPatientId(e.target.value);
  };

  const handlePatientUuidChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.value) {
      return setPatientUuid(undefined);
    }
    setPatientUuid(e.target.value);
  };

  const handleResetClick = (): void => {
    setFilterState(undefined);
    setPatientId(undefined);
    setPatientUuid(undefined);
  };

  const handleOrderByChange = (e: HHSelectChangeEvent): void => {
    const value = e.target.value as string;
    if (!value) {
      setOrderBy('updatedAt');
      return;
    }
    setOrderBy(value as WorkflowPayloadProperty);
  };

  const handleOrderChange = (e: HHSelectChangeEvent): void => {
    setOrder(e.target.value as Order);
  };

  return (
    <HHBox className={box}>
      <HHGrid className={container} container>
        <HHGrid container className={subcontainer} flexDirection="row">
          <HHGrid className={item} item>
            <HHSelect
              label="States"
              onChange={handleStatesChange}
              selectOptions={statesFields}
              initialSelectValue={filterStateValue || ''}
            />
          </HHGrid>
          <HHGrid className={item} sx={{ alignSelf: 'end' }} item>
            <HHTextField
              placeholder="patientId"
              onChange={handlePatientIdChange}
              hhVariant="standard"
              value={patientIdValue || ''}
            />
          </HHGrid>
          <HHGrid className={item} sx={{ alignSelf: 'end' }} item>
            <HHTextField
              placeholder="patientUuid"
              onChange={handlePatientUuidChange}
              hhVariant="standard"
              value={patientUuidValue || ''}
            />
          </HHGrid>
          <HHGrid className={item} item>
            <HHButton hhVariant="contained" onClick={handleResetClick}>
              Reset Filters
            </HHButton>
          </HHGrid>
        </HHGrid>
        <HHGrid container className={subcontainer} flexDirection="row">
          <HHGrid className={item} item>
            <HHSelect
              initialSelectValue={orderByValue}
              label="Order By Field"
              onChange={handleOrderByChange}
              selectOptions={workflowFields}
            />
          </HHGrid>
          <HHGrid className={item} item>
            <HHSelect
              label="Order"
              initialSelectValue={orderValue}
              onChange={handleOrderChange}
              selectOptions={[
                { displayName: 'ASC', value: 'ASC' },
                { displayName: 'DESC', value: 'DESC' },
              ]}
            />
          </HHGrid>
        </HHGrid>
      </HHGrid>
    </HHBox>
  );
};
