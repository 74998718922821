import {
  HHButton,
  HHDivider,
  HHGrid,
  HHLoadingButton,
  HHSelect,
  HHSelectChangeEvent,
} from '@hinge-health/react-component-library';
import { useState } from 'react';
import { buttonLabels } from '../../constants/hinge-connect-constants.constant';
import { actionSelectOptions, selectAction } from './actions/action-selector';

import { NoRowsMessage } from '../../components/no-rows';

export const CreateActionForm = (props: {
  onSubmit: (data: { type: string; metadata: object }) => void;
  onCancel: () => void;
}): JSX.Element => {
  const [action, setAction] = useState('');
  const [metadata, setMetaData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    props.onSubmit({ type: action, metadata });
  };

  const handleActionSelect = (e: HHSelectChangeEvent): void => {
    setAction(e.target.value as string);
  };

  return (
    <form onSubmit={handleSubmit}>
      <HHGrid container spacing={2} marginTop={'1rem'}>
        <HHGrid item xs={3}>
          <HHSelect
            fullWidth
            selectOptions={actionSelectOptions}
            initialSelectValue={action}
            label="Action"
            onChange={(e): void => handleActionSelect(e)}
          ></HHSelect>
        </HHGrid>
        <HHGrid item xs={8}></HHGrid>
        <HHGrid item xs={12}>
          <HHDivider orientation="horizontal" />
        </HHGrid>
        <HHGrid item xs={12} paddingTop="1rem">
          {selectAction(action, setMetaData, setIsFormValid)}
        </HHGrid>
        <HHGrid item xs={12}>
          {!action && (
            <NoRowsMessage
              title={'Select an Action'}
              body={'Select an action from the dropdown to continue.'}
            />
          )}
        </HHGrid>
      </HHGrid>
      <HHGrid
        container
        display={'flex'}
        spacing={2}
        justifyContent={'end'}
        marginTop={'1rem'}
      >
        <HHGrid item xs={8} />
        <HHGrid item xs={0}>
          <HHButton
            data-testid="cancel-action-button"
            hhVariant="text"
            sx={{ width: 'fit-content' }}
            onClick={props.onCancel}
          >
            {buttonLabels.cancel}
          </HHButton>
        </HHGrid>
        <HHGrid item xs={0}>
          <HHLoadingButton
            hhVariant="variant-bypass"
            variant="contained"
            sx={{ width: 'fit-content' }}
            type="submit"
            loading={false}
            disabled={!isFormValid}
            data-testid="submit-button"
          >
            Add
          </HHLoadingButton>
        </HHGrid>
      </HHGrid>
    </form>
  );
};
