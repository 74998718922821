import {
  HHBaseTable,
  HHButton,
  HHCircularProgress,
  HHFormControlLabel,
  HHStack,
  HHSwitch,
  HHTypography,
} from '@hinge-health/react-component-library';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import { useEffect, useState } from 'react';
import { useBillingSetting } from '../../context/billing.context';
import { useGetClaimsManualWebDagRunStatusLazyQuery } from '../../types';
import { displayDateTime } from '../../utils/date-helper';

export enum DagRunStatus {
  QUEUED = 'queued',
  RUNNING = 'running',
  SUCCESS = 'success',
  FAILED = 'failed',
}

const dagRunStatusIcon: { [key in DagRunStatus]: JSX.Element } = {
  [DagRunStatus.QUEUED]: (
    <PendingIcon
      color="action"
      titleAccess="Run is in queue to run next"
      sx={{ verticalAlign: 'middle' }}
    />
  ),
  [DagRunStatus.RUNNING]: (
    <HHCircularProgress
      styleAs="secondary"
      title="Run in progress"
      sx={{ verticalAlign: 'middle' }}
      size={20}
    />
  ),
  [DagRunStatus.SUCCESS]: (
    <CheckBoxIcon
      color="primary"
      titleAccess="Run has successfully completed"
      sx={{ verticalAlign: 'middle' }}
    />
  ),
  [DagRunStatus.FAILED]: (
    <ErrorIcon
      color="error"
      titleAccess="Run has failed"
      sx={{ verticalAlign: 'middle' }}
    />
  ),
};

export interface RunStatusType {
  dagId: string;
  dagRunId: string;
  note?: string | null;
  state: DagRunStatus;
  logicalDate: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  conf: { [key: string]: any };
}

const ToolRunStatus = (): JSX.Element => {
  const { value: displayLocal } = useBillingSetting('displayDateTimeLocal');
  const [runStatus, setRunStatus] = useState<RunStatusType[]>([]);
  const [getClaimsDagRunStatus, { loading }] =
    useGetClaimsManualWebDagRunStatusLazyQuery();
  const [showParams, setShowParams] = useState<boolean>(false);

  useEffect(() => {
    setRunStatus(JSON.parse(localStorage.getItem('runStatus') || '[]'));
  }, [setRunStatus]);

  const fetchRunStatus = (runId: string): void => {
    getClaimsDagRunStatus({
      variables: {
        dagRunId: runId,
      },
      fetchPolicy: 'no-cache',
    })
      .then(({ data }) => {
        if (!data?.getClaimsManualWebDagRunStatus) {
          throw new Error('Failed to fetch run status');
        }
        const rs = runStatus.find(status => status.dagRunId === runId);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        rs!.state = data.getClaimsManualWebDagRunStatus.state as DagRunStatus;
        setRunStatus([...runStatus]);
        localStorage.setItem('runStatus', JSON.stringify(runStatus));
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <HHStack direction="column">
      <HHFormControlLabel
        label="Show params"
        sx={{ justifyContent: 'flex-end' }}
        control={
          <HHSwitch
            hhVariant="primary"
            checked={showParams}
            onChange={(e): void => {
              setShowParams(e.target.checked);
            }}
          />
        }
      />
      {runStatus.length > 0 ? (
        <HHBaseTable
          tableCellStyles={{ verticalAlign: 'middle' }}
          headerRowBottomBorder="1px solid rgba(0, 0, 0, 0.12)"
          bodyRowBorder={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
          bodyTypographyVariant="body2"
          tableHeaders={[
            {
              value: 'Run Id',
              align: 'left',
            },
            { value: 'Date', align: 'left' },
            { value: 'Status', align: 'left' },
            ...(showParams ? [{ value: 'Params' }] : []),
            { value: '', align: 'right' },
          ]}
          bodyContent={runStatus.map(status => ({
            runId: {
              value: status.dagRunId,
            },
            runDate: {
              value: displayDateTime(status.logicalDate, {
                displayLocal,
              }),
            },
            runStatus: {
              value: dagRunStatusIcon[status.state],
            },
            ...(showParams
              ? {
                  runParams: {
                    value: <pre>{JSON.stringify(status.conf, null, 2)}</pre>,
                  },
                }
              : {}),
            actions: {
              value: (
                <HHButton
                  hhVariant="variant-bypass"
                  variant="outlined"
                  size="small"
                  color="primary"
                  disabled={loading}
                  onClick={(): void => {
                    fetchRunStatus(status.dagRunId);
                  }}
                >
                  check
                </HHButton>
              ),
            },
          }))}
        />
      ) : (
        <HHTypography hhVariant="muted">No runs found</HHTypography>
      )}
    </HHStack>
  );
};

export default ToolRunStatus;
