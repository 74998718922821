import {
  HHInfoGrid,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import dayjs from 'dayjs';
import { useBillingSetting } from '../../context/billing.context';
import {
  SubscriptionType,
  useGetContractsByClientInsurerQuery,
  useGetContractTemplatesQuery,
} from '../../types';
import { displayDateTime } from '../../utils/date-helper';
import { ProgramTypeDisplay } from '../../utils/discount-utils';
import ContractDetailView from '../contract/contract-detail-view';
import { UsableContractType } from '../contract/form/custom-types';
import LoadingComponent from '../utils/loading';

interface SubscriptionDetailsProps {
  subscriptionData: SubscriptionType;
}

const SubscriptionDetails = ({
  subscriptionData,
}: SubscriptionDetailsProps): JSX.Element => {
  const { value: displayLocal } = useBillingSetting('displayDateTimeLocal');
  const contractsByClientInsurerQuery = useGetContractsByClientInsurerQuery({
    variables: { id: subscriptionData.clientsInsurerId, includeVoided: true },
  });
  const contractTemplatesQuery = useGetContractTemplatesQuery();

  if (contractsByClientInsurerQuery.loading || contractTemplatesQuery.loading) {
    return <LoadingComponent center />;
  }

  const contract = contractsByClientInsurerQuery.data?.getContracts.find(
    ({ id }) => id === subscriptionData.contractId,
  );

  if (!contract || !contractTemplatesQuery.data?.getContractTemplates) {
    return (
      <HHTypography hhVariant="body">
        Contract/templates not found for this subscription
      </HHTypography>
    );
  }

  return (
    <HHStack spacing={4} padding={1}>
      <HHInfoGrid
        data={[
          {
            content: String(subscriptionData.id),
            heading: 'Id',
            copyable: true,
          },
          {
            content: String(subscriptionData.clientsInsurerId),
            heading: 'Client Insurer Id',
            copyable: true,
          },
          {
            content: String(subscriptionData.clientId),
            heading: 'Client Id',
            copyable: true,
          },
          {
            content: String(subscriptionData.contractId),
            heading: 'Contract Id',
            copyable: true,
          },
          {
            content: String(subscriptionData.pathwayId),
            heading: 'Pathway Id',
            copyable: true,
          },
          {
            content: String(subscriptionData.yearCount),
            heading: 'Year count',
            copyable: false,
          },
          {
            content: dayjs(subscriptionData.startsAt).format('L'),
            heading: 'Start date',
            copyable: false,
          },
          {
            content: String(subscriptionData.void),
            heading: 'Void',
            copyable: false,
          },
          {
            content:
              ProgramTypeDisplay[subscriptionData.programId ?? -1] || '--',
            heading: 'Program',
            copyable: false,
          },
          {
            content: displayDateTime(subscriptionData.createdAt, {
              displayLocal,
            }),
            heading: 'Created At',
            copyable: false,
          },
        ]}
        divided={false}
        justifyBetween={true}
      />
      <HHTypography hhVariant="subtitle1">Contract</HHTypography>
      <ContractDetailView
        key={subscriptionData.contractId}
        data={contract as UsableContractType}
        templates={contractTemplatesQuery.data?.getContractTemplates}
        slimDisplay
      />
    </HHStack>
  );
};

export default SubscriptionDetails;
