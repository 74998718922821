import {
  HHAccordion,
  HHAccordionDetails,
  HHAccordionSummary,
  HHTypography,
} from '@hinge-health/react-component-library';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createStyles, makeStyles } from '@mui/styles';
import { useGetWorkflowContext } from '../../../contexts/get-workflow.context';
import { MarkdownTextLink } from '../../markdown-text-link/markdown-text-link';
import { appendLocalTask, taskMapping } from './instructions';

const getStyles = makeStyles(() =>
  createStyles({
    list: { paddingInlineStart: '1.5rem' },
    listItem: { padding: '.5rem 0' },
  }),
);

export const AmtTaskAccordion = (): JSX.Element | null => {
  const { workflow } = useGetWorkflowContext();
  const uniqueTasks = new Set<string>(
    (
      workflow?.tasks?.filter(
        task => taskMapping.has(task.type) && task.state === workflow.state,
      ) || []
    ).map(x => x.type),
  );
  const styles = getStyles();

  const allTasks = appendLocalTask([...uniqueTasks.keys()], workflow?.state);

  return allTasks.length > 0 ? (
    <HHAccordion defaultExpanded>
      <HHAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <HHTypography hhVariant="h2">Tasks</HHTypography>
      </HHAccordionSummary>
      <HHAccordionDetails data-testid="tasks-accordion">
        <ol className={styles.list}>
          {allTasks.map(
            (key): JSX.Element => (
              <li data-testid={key} className={styles.listItem} key={key}>
                <MarkdownTextLink markdownString={taskMapping.get(key) || ''} />
              </li>
            ),
          )}
        </ol>
      </HHAccordionDetails>
    </HHAccordion>
  ) : null;
};
