import { ApolloError } from '@apollo/client';
import { HHStack } from '@hinge-health/react-component-library';
import { useState } from 'react';
import { PartnershipType } from '../../../../../types';
import { ClientType, useGetAllInternalHoldTypesQuery } from '../../../types';
import { AdvancedFilters } from './advanced-filters/advanced-filters';
import { BillHoldsGridFilterModelType, InsurersType } from './bill-holds-types';
import { QuickFilters } from './quick-filters';

export interface HoldsFiltersProps {
  filterParams: BillHoldsGridFilterModelType;
  setFilterParams: React.Dispatch<
    React.SetStateAction<BillHoldsGridFilterModelType>
  >;
}

export interface HoldsFiltersPropsWithClientsAndInsurersAndPartnerships
  extends HoldsFiltersProps {
  clients: ClientType[];
  clientsError?: ApolloError;
  clientsLoading?: boolean;
  insurers: InsurersType[];
  insurersError?: ApolloError;
  insurersLoading?: boolean;
  partnerships: PartnershipType[];
  partnershipsError?: ApolloError;
  partnershipsLoading?: boolean;
}

const HoldsFilters = ({
  clients,
  clientsError,
  clientsLoading,
  insurers,
  insurersError,
  insurersLoading,
  partnerships,
  partnershipsError,
  partnershipsLoading,
  filterParams,
  setFilterParams,
}: HoldsFiltersPropsWithClientsAndInsurersAndPartnerships): JSX.Element => {
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);

  const {
    data: allHoldTypes,
    error: allHoldTypesError,
    loading: holdsLoading,
  } = useGetAllInternalHoldTypesQuery();

  const holdTypes =
    allHoldTypes?.getAllInternalHoldTypes.map(type => type.value) ??
    filterParams.holdReasons;

  const dataLoading =
    clientsLoading || holdsLoading || insurersLoading || partnershipsLoading;

  return (
    <HHStack paddingLeft={6}>
      {advancedFiltersOpen && !dataLoading ? (
        <AdvancedFilters
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          clients={clients}
          clientsError={clientsError}
          insurers={insurers}
          insurersError={insurersError}
          partnerships={partnerships}
          partnershipsError={partnershipsError}
          holdTypes={holdTypes}
          holdTypesError={allHoldTypesError}
          filterToggle={(): void =>
            setAdvancedFiltersOpen(!advancedFiltersOpen)
          }
        />
      ) : (
        <QuickFilters
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          holdTypes={holdTypes}
          filterToggle={(): void =>
            setAdvancedFiltersOpen(!advancedFiltersOpen)
          }
        />
      )}
    </HHStack>
  );
};

export default HoldsFilters;
