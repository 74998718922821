import {
  AppBar,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Route, Routes } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    memberDataToolBar: {
      flexDirection: 'row',
    },
  }),
);
export const MEMBER_DATA_TEST_ID = 'memmber-data-header';

const WelcomeMessage = (): JSX.Element => (
  <HHStack alignItems="center" margin="1em" flex={1}>
    <HHTypography hhVariant="section-title">Member Data Ingestion</HHTypography>
  </HHStack>
);

const MemberDataHeader = (): JSX.Element => {
  const classes = useStyles();

  return (
    <AppBar
      elevation={3}
      variant="elevation"
      color="inherit"
      position="sticky"
      className={classes.memberDataToolBar}
      data-testid={MEMBER_DATA_TEST_ID}
    >
      <Routes>
        <Route path="*" element={<WelcomeMessage />} />
      </Routes>
    </AppBar>
  );
};
export default MemberDataHeader;
