import dayjs from 'dayjs';
import { ContractSubscriptionType } from '../../types';

export const getContractNotificationMessage = (
  contractId: number | null,
  isVoid: boolean,
  contractSubscriptiondData: ContractSubscriptionType | undefined,
  endDate: dayjs.Dayjs | null,
): string => {
  if (!contractId) {
    const activeSubscriptions = contractSubscriptiondData
      ? contractSubscriptiondData?.subscriptionCount
      : 0;
    return activeSubscriptions > 0
      ? `There are ${contractSubscriptiondData?.subscriptionCount} subscriptions that have not been voided. Please submit a work request.`
      : 'No conflicting data found for new contract.';
  }
  if (isVoid) {
    return `There are ${contractSubscriptiondData?.subscriptionCount} subscriptions, ${contractSubscriptiondData?.billCount} bills, and ${contractSubscriptiondData?.totalDiscountCount} discounts to void.`;
  } else if (endDate?.isAfter(dayjs(), 'day') || !endDate) {
    return `Changes will not affect any subscriptions (${
      contractSubscriptiondData?.voidCount || 0
    } previously voided), bills, or submissions`;
  } else {
    return `${contractSubscriptiondData?.subscriptionCount} Subscriptions (${
      contractSubscriptiondData?.voidCount || 0
    } previously voided), bills and subscription discounts starting after ${endDate?.format(
      'MM/DD/YYYY',
    )} will be voided.`;
  }
};
