import { HHLinearProgress } from '@hinge-health/react-component-library/dist/components/atoms/hh-linear-progress';
import { DataGrid, GridColumns, GridEventListener } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { dateSortComparator } from '../../../../utils/datagrid-utils';
import { NoRowsMessage } from '../../components/no-rows';
import { tabRoutes } from '../../constants/hinge-connect-constants.constant';
import {
  listRulesGridContent,
  rulesRoutes,
  rulesTabContent,
} from '../constants/rules.constant';
import { ListRulesQuery } from '../types';
import {
  getRuleName,
  getRuleResourceTypes,
  getRuleSource,
  getRuleStatus,
  getRuleUpdatedAt,
} from '../utils/list-rules-grid-utils';
import { CopyActionButton } from './copy-rule/copy-action-button';
import {
  DeleteActionButton,
  EditActionButton,
  RunActionButton,
} from './list-rules-grid-action-buttons';
import { ListRulesGridStatusChip } from './list-rules-grid-status-chip';

type ListRulesGridProps = {
  listRulesData: ListRulesQuery | undefined;
  listRulesLoading: boolean;
  refetchListRules: () => void;
  deleteRule: ({ variables }: { variables: { id: string } }) => void;
  copyRuleLoading: boolean;
  deleteRuleLoading: boolean;
  listRulesError: Error | undefined;
};

export const ListRulesGrid = (props: ListRulesGridProps): JSX.Element => {
  const {
    listRulesData,
    listRulesLoading,
    listRulesError,
    deleteRule,
    deleteRuleLoading,
    copyRuleLoading,
  } = props;

  enum ColumnHeaders {
    ruleName = 'ruleName',
    source = 'source',
    resourceTypes = 'resourceTypes',
    status = 'status',
    updatedAt = 'updatedAt',
    id = 'id',
  }

  const ListRulesColumns: GridColumns = [
    {
      field: ColumnHeaders.ruleName,
      headerName: listRulesGridContent.ruleName,
      width: 200,
      valueGetter: getRuleName,
    },
    {
      field: ColumnHeaders.source,
      headerName: listRulesGridContent.source,
      width: 200,
      valueGetter: getRuleSource,
    },
    {
      field: ColumnHeaders.resourceTypes,
      headerName: listRulesGridContent.resourceTypes,
      width: 300,
      sortable: false,
      valueGetter: getRuleResourceTypes,
    },
    {
      field: ColumnHeaders.status,
      headerName: listRulesGridContent.status,
      width: 200,
      valueGetter: getRuleStatus,
      renderCell: (params): JSX.Element => (
        <ListRulesGridStatusChip status={params.value as string} />
      ),
    },
    {
      field: ColumnHeaders.updatedAt,
      headerName: listRulesGridContent.updatedAt,
      type: 'date',
      width: 200,
      valueGetter: getRuleUpdatedAt,
      renderCell: (params): string => params.value.toLocaleString(),
      sortComparator: dateSortComparator,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'left',
      headerName: listRulesGridContent.actions,
      headerAlign: 'left',
      width: 200,
      sortable: false,
      getActions: (params): JSX.Element[] => [
        <RunActionButton />,
        <EditActionButton ruleId={params.id as string} />,
        <DeleteActionButton
          ruleName={params.row.ruleName as string}
          ruleId={params.id as string}
          deleteRule={deleteRule}
          deleteRuleLoading={deleteRuleLoading}
        />,
        <CopyActionButton
          ruleId={params.id as string}
          ruleName={params.row.ruleName as string}
          refetchListRules={props.refetchListRules}
          copyRuleLoading={copyRuleLoading}
        />,
      ],
    },
  ];

  const navigate = useNavigate();

  const handleNavigate = (href: string): void => {
    navigate(href);
  };

  const handleRowClick: GridEventListener<'rowClick'> = params => {
    handleNavigate(
      `/${tabRoutes.baseRoute}/${tabRoutes.rules}/${params.id}/${rulesRoutes.view}`,
    );
  };

  return (
    <DataGrid
      columnBuffer={10}
      sx={{
        boxShadow: '#f4f4f4 0px 2px 6px 1px',
        borderRadius: '4px',
        marginTop: '1rem',
      }}
      autoHeight
      hideFooter
      components={{
        LoadingOverlay: HHLinearProgress,
        NoRowsOverlay: NoRowsMessage,
        ErrorOverlay: NoRowsMessage,
      }}
      componentsProps={{
        noRowsOverlay: {
          title: rulesTabContent.noRules.title,
          body: rulesTabContent.noRules.body,
        },
        errorOverlay: {
          title: rulesTabContent.rulesLoadError.title,
          body: rulesTabContent.rulesLoadError.body,
        },
      }}
      error={listRulesError}
      columns={ListRulesColumns}
      rows={listRulesData?.listRules?.rules || []}
      loading={listRulesLoading}
      isRowSelectable={(): boolean => false}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: ColumnHeaders.updatedAt,
              sort: 'desc',
            },
          ],
        },
      }}
      onRowClick={handleRowClick}
    />
  );
};
