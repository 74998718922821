import { ApolloError } from '@apollo/client';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { BatchClientInsurerType, PartnershipType } from '../types';

export interface ContextPartnershipType {
  [key: number]: PartnershipType;
}

type BatchClientInsurerTypeTemp = Omit<BatchClientInsurerType, 'insurer'> & {
  insurer: Omit<BatchClientInsurerType['insurer'], 'eligibilityRef'>;
};

type ContextClientInsurerTypeA = {
  loading: true;
  error: null;
};
type ContextClientInsurerTypeB = BatchClientInsurerTypeTemp & {
  loading: false;
  error: null;
};
type ContextClientInsurerTypeC = {
  loading: false;
  error: ApolloError;
};

interface BillingAppSettings {
  displayDateTimeLocal: boolean;
}

export type ContextClientInsurerType =
  | ContextClientInsurerTypeA
  | ContextClientInsurerTypeB
  | ContextClientInsurerTypeC;

export interface ContextClientInsurerCollectionType {
  [key: string]: ContextClientInsurerType;
}

export interface BillingContextType {
  partnerships: ContextPartnershipType;
  clientInsurers: ContextClientInsurerCollectionType;
  addPartnerships: (partnerships: ContextPartnershipType) => void;
  addClientInsurers: (
    clientInsurers: ContextClientInsurerCollectionType,
  ) => void;
  billingAppSettings: BillingAppSettings;
  setBillingAppSetting: <T extends keyof BillingAppSettings>(
    settingKey: T,
    value: BillingAppSettings[T],
  ) => void;
}

const initialContext: BillingContextType = {
  partnerships: {},
  clientInsurers: {},
  addPartnerships: () => undefined,
  addClientInsurers: () => undefined,
  billingAppSettings: {
    displayDateTimeLocal: true,
  },
  setBillingAppSetting: () => undefined,
};

export const BillingContext = createContext<BillingContextType>(initialContext);

export const BillingProvider = ({
  children,
  value = {},
}: PropsWithChildren<{
  value?: Partial<BillingContextType>;
}>): JSX.Element => {
  const [billingAppSettings, setBillingAppSettings] =
    useState<BillingAppSettings>(() => {
      // fetch from local storage
      const localSettings = JSON.parse(
        localStorage.getItem('billingAppSettings') || '{}',
      );
      return {
        ...initialContext.billingAppSettings,
        ...localSettings,
        ...value.billingAppSettings,
      };
    });
  const [partnerships, setPartnerships] = useState<ContextPartnershipType>(
    () => ({
      ...initialContext.partnerships,
      ...value.partnerships,
    }),
  );
  const [clientInsurers, setClientInsurers] =
    useState<ContextClientInsurerCollectionType>(() => ({
      ...initialContext.clientInsurers,
      ...value.clientInsurers,
    }));
  const addPartnerships = useCallback(
    (newPartnerships: ContextPartnershipType): void => {
      setPartnerships(currentPartnerships => ({
        ...currentPartnerships,
        ...newPartnerships,
      }));
    },
    [setPartnerships],
  );
  const addClientInsurers = useCallback(
    (newClientInsurers: ContextClientInsurerCollectionType): void => {
      setClientInsurers(currentClientInsurers => ({
        ...currentClientInsurers,
        ...newClientInsurers,
      }));
    },
    [setClientInsurers],
  );
  const contextValue = useMemo(
    () => ({
      partnerships,
      addPartnerships,
      clientInsurers,
      addClientInsurers,
      billingAppSettings,
      setBillingAppSetting: <T extends keyof BillingAppSettings>(
        settingKey: T,
        value: BillingAppSettings[T],
      ): void => {
        setBillingAppSettings(setting => {
          const newSettings = { ...setting, [settingKey]: value };
          localStorage.setItem(
            'billingAppSettings',
            JSON.stringify(newSettings),
          );
          return newSettings;
        });
      },
    }),
    [
      partnerships,
      addPartnerships,
      clientInsurers,
      addClientInsurers,
      billingAppSettings,
      setBillingAppSettings,
    ],
  );

  return (
    <BillingContext.Provider value={contextValue}>
      {children}
    </BillingContext.Provider>
  );
};

export const useBillingContext = (): BillingContextType =>
  useContext(BillingContext);

export const useBillingSetting = <T extends keyof BillingAppSettings>(
  settingKey: T,
): {
  value: BillingAppSettings[T];
  setBillingAppSetting: (value: BillingAppSettings[T]) => void;
} => {
  const { billingAppSettings, setBillingAppSetting } = useBillingContext();
  return {
    value: billingAppSettings[settingKey],
    setBillingAppSetting: value => setBillingAppSetting(settingKey, value),
  };
};
