import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { SimplePackageType } from '../components/contract/package/custom-package-types';
import { DiscountFormValues } from '../components/discounts/form/form-constants';
import { DiscountUsage } from '../types';
import { capitalizeFirstLetterAndSpaceSnakeString } from './bills-utils';
import { ActiveStatus } from './contract-utils';
import { GqlNetworkStatus } from './graphql-utils';

dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

export enum DiscountType {
  billingCaps = 'billing_caps',
  innovationCredits = 'innovation_credits',
  volumeBasedDiscounts = 'volume_based_discounts',
  programAccess = 'program_access',
  freeBillableActivities = 'free_billable_activities',
}

export enum InvalidProgramDiscounts {
  'free_billable_activities',
}

export enum InvalidPackagesForDiscounts {
  'in_person_visit',
}

export interface DiscountTypeOption {
  value: keyof DiscountType;
  displayName: string;
}

export const DiscountTypeSelectOptions = (
  Object.keys(DiscountType) as (keyof typeof DiscountType)[]
)
  .map(key => ({
    value: DiscountType[key],
    displayName: DiscountType[key].replaceAll('_', ' '),
  }))
  .filter(x => !['volume_based_discounts'].includes(x.value));

export const getEditDiscountAmount = (discountToEdit: IDiscount): number => {
  switch (discountToEdit.discountType) {
    case DiscountType.billingCaps:
      return discountToEdit.discountDetails[1].discount;
    case DiscountType.programAccess:
      return discountToEdit.discountDetails[0].discount;
    case DiscountType.freeBillableActivities:
      return discountToEdit.discountDetails[0].discount;
    case DiscountType.innovationCredits:
      const subscriptionTier =
        discountToEdit.discountDetails[0].subscriptionTier;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return subscriptionTier[1] - 1;
    /* istanbul ignore next */
    default:
      return discountToEdit.discountDetails[1].discount;
  }
};

export const getEditDefaultValues = (
  discountToEdit: IDiscount,
): DiscountFormValues => {
  const discountAmount = getEditDiscountAmount(discountToEdit);
  return {
    clientId: discountToEdit.clientId ?? 0,
    discountType: discountToEdit.discountType,
    startDate: dayjs(discountToEdit.startDate).toDate(),
    endDate: dayjs(discountToEdit.endDate).isValid()
      ? dayjs(discountToEdit.endDate).toDate()
      : null,
    program: discountToEdit.programId?.toString() ?? '',
    packageId: discountToEdit.packageId?.toString() ?? '',
    discount: discountAmount,
    suppressEmptyCharges: discountToEdit.suppressEmptyCharges,
  };
};

export enum ProgramTypeDisplay {
  'Chronic & Acute',
  'Chronic',
  'Acute',
}

export const ProgramSelectionOptions = (
  Object.keys(ProgramTypeDisplay) as (keyof typeof ProgramTypeDisplay)[]
)
  .filter(v => !isNaN(Number(v)))
  .map((key, index) => ({
    value: key,
    displayName: ProgramTypeDisplay[index],
  }));

export const PackageTypeDisplay = (
  packageId: number,
  availablePackages: SimplePackageType[],
): string => {
  if (packageId === 0) {
    return 'All Packages';
  }
  const selectedPackage = availablePackages.find(({ id }) => id === packageId);
  return capitalizeFirstLetterAndSpaceSnakeString(selectedPackage?.name ?? '');
};

export interface PackageDiscountDisplay {
  value: string;
  displayName: string;
}

export const PackageSelectionOptions = (
  availablePackages: SimplePackageType[],
): PackageDiscountDisplay[] => [
  {
    value: '0',
    displayName: 'All Packages',
  },
  ...availablePackages.map(({ id, name }) => ({
    value: id.toString(),
    displayName: capitalizeFirstLetterAndSpaceSnakeString(name),
  })),
];

export enum ProgramType {
  'global',
  'chronic',
  'acute',
}

export interface IDiscountUsage
  extends Partial<DiscountUsage>,
    GqlNetworkStatus {}

export interface IDiscountDetail {
  discount: number;
  discountDetailId: number;
  milestoneDiscount: object;
  subscriptionTier: (number | null | undefined)[];
  tier: number;
  discountUsage: IDiscountUsage;
}
export interface IDiscount {
  clientId?: number | null;
  discountDefinitionId: number;
  discountType: string;
  endDate?: string | null;
  insurerId?: number | null;
  name?: string | null;
  partnershipId?: number | null;
  programId?: number | null;
  packageId?: number | null;
  startDate: string;
  discountDetails: IDiscountDetail[];
  suppressEmptyCharges: boolean;
  status: ActiveStatus;
}

export interface DiscountOverlapReturn {
  isOverlap: boolean;
  discountDefinitionId: number;
}

const programsOverlap = (
  existingProgramId: number | null,
  selectedProgramId: number | null,
): boolean => {
  if (existingProgramId === null || selectedProgramId === null) {
    return false;
  }
  if (existingProgramId === selectedProgramId) {
    return true;
  }
  if (existingProgramId === 0 || selectedProgramId === 0) {
    return true;
  } else return false;
};

const packageOverlap = (
  existingPackageId: number | null,
  selectedPackageId: number | null,
): boolean =>
  existingPackageId === 0 ||
  selectedPackageId === 0 ||
  (existingPackageId !== null &&
    selectedPackageId !== null &&
    existingPackageId === selectedPackageId);

export const selectedDiscountOverlaps = (
  selectedDate: string,
  selectedDiscountType: string,
  selectedProgramId: number | null,
  selectedPackageId: number | null,
  discountDefintionIdToEdit: number | null,
  currentDiscounts: IDiscount[],
): DiscountOverlapReturn => {
  let discountDefinitionId = 0;
  // Check if selected date overlaps with any of the current discounts date ranges
  const isOverlap =
    currentDiscounts.length > 0
      ? currentDiscounts.some(discount => {
          discountDefinitionId = discount.discountDefinitionId;
          const isDiscountTypeOverlap =
            discount.discountType === selectedDiscountType;
          const isProgramOverlap = programsOverlap(
            discount.programId ?? null,
            selectedProgramId,
          );
          const isPackageOverlap = packageOverlap(
            discount.packageId ?? null,
            selectedPackageId,
          );
          let datesOverlap;
          if (
            !discount.endDate &&
            discount.discountDefinitionId !== discountDefintionIdToEdit &&
            selectedDate !== 'Invalid Date'
          ) {
            /**
             * If creating new discount,
             * it overlaps if it's after the start date of
             * any discount with same discount type
             * and program
             */
            datesOverlap = dayjs(selectedDate).isSameOrAfter(
              discount.startDate,
              'date',
            );

            return (
              (isDiscountTypeOverlap && isProgramOverlap && datesOverlap) ||
              (isDiscountTypeOverlap && isPackageOverlap && datesOverlap)
            );
          } else if (
            discount.discountDefinitionId !== discountDefintionIdToEdit &&
            selectedDate !== 'Invalid Date'
          ) {
            /**
             * If adding new date, and not editing date range
             * selected date overlaps if between any current range
             * any discount with same discount type
             * and program
             */
            datesOverlap = dayjs(selectedDate).isBetween(
              discount.startDate,
              discount.endDate,
              'date',
              '[]',
            );
            return (
              (isDiscountTypeOverlap && isProgramOverlap && datesOverlap) ||
              (isDiscountTypeOverlap && isPackageOverlap && datesOverlap)
            );
          } else if (
            !discount.endDate &&
            selectedDate === 'Invalid Date' &&
            discountDefintionIdToEdit !== discountDefinitionId
          ) {
            /**
             * discount has end date of null and selected is null,
             * and it is not the discount we are editing
             * it overlaps, regardless -> two indefinite times overlap
             * any discount with same discount type
             * and program
             */
            datesOverlap = true;
            return (
              (isDiscountTypeOverlap && isProgramOverlap && datesOverlap) ||
              (isDiscountTypeOverlap && isPackageOverlap && datesOverlap)
            );
          }
        })
      : false;
  if (!isOverlap) {
    discountDefinitionId = 0;
  }

  return {
    isOverlap,
    discountDefinitionId,
  };
};
