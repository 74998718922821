export enum ActiveContractLabels {
  CHRONIC_CORE_CHARGE_LABEL = 'Chronic',
  EFFECT_DATE_LABEL = 'Effective dates',
  ACUTE_CHARGE_LABEL = 'Acute',
  MILESTONE_LABEL = 'Milestones',
  ACTIVE_CONTRACT_LABEL = 'Active Contract',
  CONTRACTS_LABEL = 'All Contracts',
  EDIT_CONTRACT_LABEL = 'Edit Contract',
  IN_PERSON_VISIT_CHARGE_LABEL = 'In person visit charge',
  IN_PERSON_VISIT_EFFECTIVE_DATE_LABEL = 'In person visit effective date',
  ENGAGEMENT_INITIAL_FEE = 'Initial fee',
  ENGAGEMENT_ACTIVITY_FEE = 'Activity fee',
  INDICATIONS_INCLUDED = 'Indications included',
  USER_ANNUAL_CAP = 'User annual spending cap',
  CONTRACT_TEMPLATE_LABEL = 'Template',
  CURRENCY = 'Currency',
}

export enum MilestoneLabels {
  STANDARD_MILESTONE_LABEL = 'M1',
  DOS_OFFSET = 'DOS offset',
  BILLING_CONDITIONS = 'Conditions',
  DAY_RANGE = 'Day range',
}

export enum AllContractsRowLabels {
  LEGACY_CONTRACT = 'Legacy',
  PRODUCT_PACKAGE_CONTRACT = 'Product package',
}
