import { GridValueGetterParams } from '@mui/x-data-grid';

export const getTagName = (params: GridValueGetterParams): string =>
  params?.row?.name || '';

export const getTagDescription = (params: GridValueGetterParams): string =>
  params?.row?.description || '';

export const getTagCreatedAt = (params: GridValueGetterParams): string =>
  params?.row?.createdAt || '';

export const getTagUpdatedAt = (params: GridValueGetterParams): string =>
  params?.row?.updatedAt || '';

export const getTagDeletedAt = (params: GridValueGetterParams): string =>
  params?.row?.deletedAt || '';
