import {
  HHAlert,
  HHButton,
  HHStack,
} from '@hinge-health/react-component-library';
import { FormEvent, MouseEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FileConfigurationTable from '../components/file-configuration-table';
import { routes } from '../constants/strings/routes';

const MemberDataHomePage = (): JSX.Element => {
  const navigation = useNavigate();
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState<string>('');

  const clearSuccessMessage = (): void => {
    setSuccessMessage('');
    window.history.replaceState({}, '');
  };

  useEffect(() => {
    // clear the state on page reload
    window.history.replaceState({}, '');
  }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.newFileConfigurationSuccess) {
        setSuccessMessage('New file configuration created successfully');
      } else if (location.state.editFileConfigurationSuccess) {
        setSuccessMessage('File configuration updated successfully');
      }
    }
  }, [location.state]);

  /* istanbul ignore next */
  const goToNewFileConfigurationPage = (
    e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault();
    navigation(
      `${routes.memberData.home}/${routes.memberData.newFileConfiguration}`,
    );
  };

  return (
    <HHStack direction="column" padding={'20px'} spacing={'20px'}>
      {successMessage && (
        <HHAlert
          hhVariant={'variant-bypass'}
          severity="success"
          children={successMessage}
          onClose={clearSuccessMessage}
        />
      )}
      <HHStack direction="row" spacing={'10px'}>
        <HHButton
          hhVariant={'contained'}
          onClick={goToNewFileConfigurationPage}
          size="large"
          fullWidth={false}
        >
          New File Configuration
        </HHButton>
      </HHStack>
      <FileConfigurationTable navigate={navigation} />
    </HHStack>
  );
};

export default MemberDataHomePage;
