import { ApolloError } from '@apollo/client';
import { HHBox, HHTypography } from '@hinge-health/react-component-library';
import { userTagsInfoMessages } from '../../constants/user-tags.constant';

export const NoUserTagsMessage = (props?: {
  error: ApolloError;
}): JSX.Element => {
  if (props?.error) {
    return (
      <HHBox textAlign="center" paddingTop={'3rem'}>
        <HHTypography hhVariant="h3">{userTagsInfoMessages.oops}</HHTypography>
        <HHTypography hhVariant="body">
          {props.error.message + ' ' + userTagsInfoMessages.trySearchingAgain}
        </HHTypography>
      </HHBox>
    );
  }
  return (
    <HHBox textAlign="center" paddingTop={'3rem'}>
      <HHTypography hhVariant="h3">
        {userTagsInfoMessages.noUserTags}
      </HHTypography>
      <HHTypography hhVariant="body">
        {userTagsInfoMessages.trySearching}
      </HHTypography>
    </HHBox>
  );
};
