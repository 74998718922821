import { HHTypography } from '@hinge-health/react-component-library';
import { formatCurrency } from '../../../utils/currency-helpers';
import { IDiscount, IDiscountUsage } from '../../../utils/discount-utils';

export interface BillingCapsUsageProps {
  discountData: IDiscount;
  currency: string | null;
}

const getUsageDisplay = (
  discountUsage: IDiscountUsage,
  currency: string | null,
): string => {
  if (discountUsage.loading || discountUsage.error) {
    return '--';
  }

  return formatCurrency(discountUsage?.discountSum || 0, currency);
};

const BillingCapsUsage = ({
  discountData,
  currency,
}: BillingCapsUsageProps): JSX.Element => {
  const capValue =
    discountData.discountDetails.length === 2
      ? discountData.discountDetails[1].discount
      : discountData.discountDetails[0].discount;

  return (
    <HHTypography hhVariant="inherit">
      {getUsageDisplay(discountData.discountDetails[0].discountUsage, currency)}
      /{formatCurrency(capValue, currency)} Cap Assigned
    </HHTypography>
  );
};
export default BillingCapsUsage;
