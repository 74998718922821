import { GridValueGetterParams } from '@mui/x-data-grid';

export const getRuleName = (params: GridValueGetterParams): string =>
  params?.row?.definition?.name || '';

export const getRuleSource = (params: GridValueGetterParams): string =>
  params?.row?.source || '';

export const getRuleResourceTypes = (params: GridValueGetterParams): string =>
  params?.row?.resourceTypes?.join(', ') || '';

export const getRuleStatus = (params: GridValueGetterParams): string =>
  params?.row?.status || '';

export const getRuleUpdatedAt = (params: GridValueGetterParams): string =>
  params?.row?.updatedAt || '';
