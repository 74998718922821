import {
  HHAlert,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { useGetDiscounts } from '../../hooks/discount-hooks';
import DiscountComponent from '../discounts/discount-component';

export interface ClientDiscountsListProps {
  clientId: number;
  partnershipIds?: number[];
  disableAddNew?: boolean;
}

const ClientDiscountsList = ({
  clientId,
  partnershipIds,
  disableAddNew = false,
}: ClientDiscountsListProps): JSX.Element => {
  const {
    data: discountsData,
    loading,
    error,
    usageStatus,
  } = useGetDiscounts(clientId, partnershipIds);
  if (!loading && error) {
    return (
      <HHAlert
        hhVariant="outlined"
        severity="error"
        children={
          <HHTypography hhVariant="body">
            There was an error loading client discount list
          </HHTypography>
        }
      ></HHAlert>
    );
  }

  if (!loading && !discountsData) {
    return (
      <HHStack direction="row" spacing={4}>
        <HHTypography hhVariant="body">Client discounts not found</HHTypography>
      </HHStack>
    );
  }

  return (
    <DiscountComponent
      loading={loading}
      discountsData={discountsData}
      partnershipIds={partnershipIds}
      clientId={clientId}
      usageStatus={usageStatus}
      disableAddNew={disableAddNew}
    />
  );
};

export default ClientDiscountsList;
