import { datadogRum } from '@datadog/browser-rum';
import {
  HingeHealthSecurity,
  useHingeHealthSecurityContext,
} from '@hinge-health/react-component-library';
import makeStyles from '@mui/styles/makeStyles';
import { LicenseInfo } from '@mui/x-license-pro';
import { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Apollo, AuthenticatedPage } from './components';
import * as routeConsts from './constants';
import { OtherRoles } from './pages/other-roles';
import { authRoutes, RouteDetails } from './routes';
import { getRoleProperties } from './utils/roles';

LicenseInfo.setLicenseKey(process.env.MUI_X_PRO_LICENSE_KEY as string);

const useStyles = makeStyles({
  '@global': {
    body: {
      margin: 0,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: '#263744',
  },
});

const AuthenticatedComponent = ({
  route,
}: {
  route: RouteDetails;
}): JSX.Element => (
  <AuthenticatedPage>
    <route.component />
  </AuthenticatedPage>
);

const AssembleRoutes = (): JSX.Element => {
  const { hingeHealthAuthState, hingeHealthAuthClient } =
    useHingeHealthSecurityContext();
  const adminRoles = hingeHealthAuthState?.accessToken?.claims.roles;
  const isAuthenticated = hingeHealthAuthState?.isAuthenticated;
  const { routes } = getRoleProperties(adminRoles);
  const location = useLocation();
  const navigation = useNavigate();

  useEffect(() => {
    if (
      hingeHealthAuthState?.isAuthenticated &&
      hingeHealthAuthState?.accessToken?.claims
    ) {
      const {
        email,
        uid: id,
        roles,
      } = hingeHealthAuthState?.accessToken?.claims;
      datadogRum.setUser({
        id: id as string,
        email,
        roles,
      });
    }
    return () => {
      datadogRum.clearUser();
    };
  }, [hingeHealthAuthState]);

  useEffect(() => {
    const handleVisibilityChange = async (): Promise<void> => {
      if (!document.hidden) {
        const accessToken = await hingeHealthAuthClient.getOrRenewAccessToken();
        if (!accessToken) {
          // this means even the refresh token has expired so now we have to force the login page
          navigation(routeConsts.routes.login, {
            state: { referrer: `${location.pathname}${location.search}` },
          });
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [hingeHealthAuthClient, navigation, location]);

  return (
    <Routes>
      {authRoutes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          element={<route.component />}
        />
      ))}
      <Route
        index
        key={'index'}
        element={<AuthenticatedComponent route={routes[0]} />}
      />
      {routes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          element={<AuthenticatedComponent route={route} />}
        />
      ))}
      <Route
        path="*"
        element={
          isAuthenticated === false ? (
            <Navigate
              to={routeConsts.routes.login}
              state={{ referrer: `${location.pathname}${location.search}` }}
            />
          ) : (
            <OtherRoles />
          )
        }
      />
    </Routes>
  );
};

export default function App(): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();

  const restoreOriginalUri = async (originalUri: string): Promise<void> => {
    navigate(originalUri || '/');
  };

  const options = {
    oktaConfig: {
      clientId: process.env.OKTA_CLIENT_ID || '',
      issuer: process.env.OKTA_ISSUER || '',
      redirectUri: `${window.location.origin}/okta/callback`,
      restoreOriginalUri,
    },
    capabilitiesConfig: {
      enabled: false,
    },
  };

  return (
    <div className={classes.container}>
      <HingeHealthSecurity options={options}>
        <Apollo>
          <AssembleRoutes />
        </Apollo>
      </HingeHealthSecurity>
    </div>
  );
}
