import { useClient } from '@splitsoftware/splitio-react';

export const GetSplitTreatmentOnOrOff = (
  treatment: string,
  oktaUid?: string,
): boolean => {
  const splitClient = useClient();

  return splitClient?.getTreatment(treatment, { uid: oktaUid ?? '' }) === 'on';
};
