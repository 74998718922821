import {
  HHButton,
  HHPaper,
  HHStack,
  HHTypography,
} from '@hinge-health/react-component-library';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Link } from 'react-router-dom';
import { routes } from '../../constants/strings/routes';
import { BatchClientInsurerType } from '../../types';

export interface ClientInsurerListProps {
  data: BatchClientInsurerType[];
}

const ClientInsurerList = ({ data }: ClientInsurerListProps): JSX.Element => (
  <HHStack direction="column" spacing={4}>
    <HHTypography hhVariant="section-title">
      Associated Client Insurers
    </HHTypography>
    {data.length === 0 && (
      <HHTypography hhVariant="muted">No client insurers found</HHTypography>
    )}
    <HHStack direction="row" spacing={4}>
      {data.map(clientInsurer => (
        <HHPaper
          variant="elevation"
          elevation={2}
          sx={(theme): SystemStyleObject<Theme> => ({
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(4),
          })}
          key={clientInsurer.id}
        >
          <HHStack direction="row" spacing={2} alignItems="center">
            <HHTypography hhVariant="h2">
              {clientInsurer.insurer.name}
            </HHTypography>
            <Link to={`${routes.billing.main}/${clientInsurer.id}`}>
              <HHButton
                hhVariant="variant-bypass"
                aria-label="View"
                variant="text"
                size="small"
              >
                View
              </HHButton>
            </Link>
          </HHStack>
        </HHPaper>
      ))}
    </HHStack>
  </HHStack>
);

export default ClientInsurerList;
