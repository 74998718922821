import { ContextClientInsurerCollectionType } from '../../context/billing.context';
import { UnbilledBillType } from '../../types';

export interface UnbilledData extends UnbilledBillType {
  client: {
    id: number | null;
    name: string | null;
    loading: boolean;
    isErrored: boolean;
  };
  insurer: {
    id: number | null;
    name: string | null;
    loading: boolean;
    isErrored: boolean;
  };
  billingType: {
    value: string | null;
    loading: boolean;
    isErrored: boolean;
  };
}

export type UnbilledTypeDataList = UnbilledData[];

export const generateUnbilledData = (
  items: UnbilledBillType[],
  ciData: ContextClientInsurerCollectionType,
): UnbilledTypeDataList =>
  items.map(item => {
    const ci = ciData[item.clientsInsurerId] ?? {
      client: null,
      insurer: null,
      billingType: null,
      loading: true,
      error: null,
    };

    return {
      ...item,
      client: {
        name: !ci.loading && !ci.error ? ci.client.name : null,
        id: !ci.loading && !ci.error ? ci.client.id : null,
        isErrored: Boolean(ci.error),
        loading: false,
      },
      insurer: {
        name: !ci.loading && !ci.error ? ci.insurer.name : null,
        id: !ci.loading && !ci.error ? ci.insurer.id : null,
        isErrored: Boolean(ci.error),
        loading: false,
      },
      billingType: {
        value:
          !ci.loading && !ci.error && ci.billingType ? ci.billingType : null,
        isErrored: Boolean(ci.error),
        loading: false,
      },
    };
  });
